import React, {useState} from 'react';
import {ContextualSaveBar} from '@shopify/polaris';
import {useSaveChangesStore} from '../app/store';
import {DiscardChangesModal} from './modal';
const SaveBar = ({saveButtonText = 'Save', discardButtonText, isModalDisabled, onBack, onNext}) => {
  const onSubmit = useSaveChangesStore(state => state.onSubmit);
  const button = useSaveChangesStore(state => state.button);
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  return (
    <>
      <ContextualSaveBar
        fullWidth
        message="Unsaved changes"
        saveAction={{
          onAction: saveButtonText !== 'Save' ? onNext : onSubmit,
          loading: false,
          disabled: !button,
          content: saveButtonText || 'Save',
        }}
        discardAction={{
          content: discardButtonText || 'Discard',
          onAction: () => {
            if (isModalDisabled) {
              onBack();
            } else {
              setOpenDiscardModal(true);
            }
          },
        }}
      />
      {openDiscardModal && (
        <DiscardChangesModal currentState={openDiscardModal} onClose={setOpenDiscardModal} />
      )}
    </>
  );
};
export default SaveBar;
