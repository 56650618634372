function assignColorLightenValue(data, minValue, maxValue, durationFilter) {
  let initial = durationFilter === 'weekly' ? 'w' : durationFilter === 'monthly' ? 'm' : 'q';
  const monthKeys = Object.keys(data).filter(key => key.startsWith(initial));
  const monetaryValues = monthKeys.map(key => parseInt(data[key], 10));
  const shades = monetaryValues.map(value => {
    if (maxValue - minValue === 0) {
      return 200;
    }
    const normalizedValue = ((value - minValue) / (maxValue - minValue)) * 75;
    const lightenValue = (100 - normalizedValue) * 2;
    return Math.round(lightenValue);
  });
  const result = {};
  monthKeys.forEach((key, index) => {
    result[key] = shades[index];
  });

  return result;
}

export {assignColorLightenValue};
