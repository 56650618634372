import axios from './axios';
import config from '../config';

const {BASE_URL} = config;

export async function productCost(params) {
  const response = await axios.get(`${BASE_URL}/cogs/productCost`, params);
  return response;
}

export async function fixedCost(params) {
  const response = await axios.get(`${BASE_URL}/cogs/fixedCost`, params);
  return response;
}

export async function updateCost(params) {
  const response = await axios.put(`${BASE_URL}/cogs/settings`, params);
  return response;
}

export async function getCost() {
  const response = await axios.get(`${BASE_URL}/cogs/settings`);
  return response;
}

export async function updateFixedCost(params) {
  const response = await axios.put(`${BASE_URL}/cogs/settings/fixedCost`, params);
  return response;
}
