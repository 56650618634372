import {Badge, useBreakpoints, ButtonGroup} from '@shopify/polaris';
import {useState, useCallback, useEffect, useContext} from 'react';
import {
  getDefaultNpsTemplate,
  getNpsSetting,
  getNpsTemplate,
  sendTestEmailTemplate,
  sendTestWhatsappMessage,
  setProductNpsSetting,
  updateNpsTemplate,
} from '../../../../api/reports/settings';
import {AuthAPI} from '../../../../api/auth';
import {AuthContext} from '../../../../context/auth';
import {LocalStorageService} from '../../../../service/localStorage';
import Toggle from '../../../../components/Toggle';
import '../../../../components/Toggle/style.css';
import Inline from '../../../../lib/Inline';
import {getConnectionTemplate} from '../../../../api/connection';
import NoConnectionModal from '../../../../components/NoConnectionModal';
import Alert from '../../../../components/alert';
import SendEmailModal from '../../../../components/Modals/SendEmailModal';
import SendWhatsappMessageModal from '../../../../components/Modals/SendWhatsappMessageModal';

import Button from '../../../../lib/Button';
import Stack from '../../../../lib/Stack';
import Text from '../../../../lib/Text';
import Card from '../../../../lib/Card';
import Box from '../../../../lib/Box';
import EmailTemplatePreviewEdit from '../../../../components/EmailTemplatePreviewEdit';
import WhatsappTemplatePreviewEdit from '../../../../components/WhatsappTemplatePreviewEdit';
export function WithPrimitiveComponents() {
  const [enabled, setEnabled] = useState(false);
  const [isWhatsappEnabled, setIsWhatsappEnabled] = useState(false);
  const [openConnectModal, setOpenConnectModal] = useState(false);
  const [openSendEmailModal, setOpenSendEmailModal] = useState(false);
  const [openSendWhatsappMessageModal, setOpenSendWhatsappMessageModal] = useState(false);
  const {updateUserDetails} = useContext(AuthContext);
  const [template, setTemplate] = useState({body: '', subject: ''});
  const [whatsappTemplate, setWhatsappTemplate] = useState({body: ''});
  const [showAlert, setShowAlert] = useState('');
  const [isTwilioConnectionConnected, setIsTwillioConnectionConnected] = useState(false);
  const [emailTemplateEditMode, setEmailTemplateEditMode] = useState(false);
  const [whatsappTemplateEditMode, setWhatsappTemplateEditMode] = useState(false);
  let accessToken;
  useEffect(() => {
    getNpsSetting()
      .then(response => {
        if (response.status) {
          response.data.reportSettings.nps.product === true ? setEnabled(true) : setEnabled(false);
          response.data?.reportSettings?.nps.isWhatsappEnabled === true
            ? setIsWhatsappEnabled(true)
            : setIsWhatsappEnabled(false);
        }
      })
      .catch(err => {
        console.log(err);
      });
    getEmailTemplate();
    getWhatsappTemplate();
    isTwilioConnected();
  }, []);

  const isTwilioConnected = async () => {
    let params = {params: {type: 'connected'}};
    let connected = false;
    let res = await getConnectionTemplate(params);
    res.data.forEach(element => {
      if (element?.type === 'twilio' && element?.data?.apiKey) {
        connected = true;
      }
    });
    setIsTwillioConnectionConnected(connected);
  };
  const sendTestEmail = async email => {
    setShowAlert('');
    await sendTestEmailTemplate({email, template: template.body, subject: template.subject});
    setShowAlert('Test email sent successfully');
  };
  const sendTestMessage = async number => {
    setShowAlert('');
    await sendTestWhatsappMessage({number, template: whatsappTemplate.body});
    setShowAlert('Test message sent successfully');
  };
  const updateTemplate = async type => {
    setShowAlert('');
    if (type === 'email') {
      await updateNpsTemplate({...template, type});
      setShowAlert('Email template has been updated');
      setEmailTemplateEditMode(false);
    } else if (type === 'whatsapp') {
      await updateNpsTemplate({...whatsappTemplate, type});
      setShowAlert('Whatsapp template has been updated');
      setWhatsappTemplateEditMode(false);
    }
  };

  const getEmailTemplate = async () => {
    let template = await getNpsTemplate({type: 'email'});
    if (template.data) {
      setTemplate(template.data);
    }
  };
  const getWhatsappTemplate = async () => {
    let template = await getNpsTemplate({type: 'whatsapp'});
    if (template.data) {
      setWhatsappTemplate(template.data);
    }
  };
  const handleToggle = useCallback(() => {
    setProductNpsSetting({set: !enabled})
      .then(response => {
        if (response.status) {
          setEnabled(enabled => !enabled);
          const userDetails = LocalStorageService.getAccountDetails();
          accessToken = userDetails.access_token;
          AuthAPI.userInfo({params: {accessToken}}).then(response => {
            updateUserDetails(response.data);
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, [enabled]);

  const toggleId = 'setting-toggle-uuid';
  const descriptionId = 'setting-toggle-description-uuid';

  const {mdDown} = useBreakpoints();

  const badgeStatus = enabled ? 'success' : undefined;

  const badgeContent = enabled ? 'On' : 'Off';

  const title = 'Product NPS';
  // const description =
  //   "Product NPS, is a measurement of recommendation for your brand's product on a scale between -100 to 100. Positive and higher scores indicate a positive affect for the product, while negative and lower scores indicate negative affect.";
  const description =
    'NPS measures how your likely your customers are to recommend your brand. It is calculated on a scale of -100 to 100. Higher scores indicate affinity for the brand increasing the likelihood of repeat purchases, while negative and lower scores indicate a high potential of customers abandoning the brand.';

  const settingStatusMarkup = (
    <Badge status={badgeStatus} statusAndProgressLabelOverride={`Setting is ${badgeContent}`}>
      {badgeContent}
    </Badge>
  );

  const settingTitle = title ? (
    <Stack>
      <Stack>
        <label htmlFor={toggleId}>
          <Text variant="headingMd" as="h6">
            {title}
          </Text>
        </label>
        <Stack>{settingStatusMarkup}</Stack>
      </Stack>
    </Stack>
  ) : null;

  const actionMarkup = (
    <Toggle checked={enabled} onChange={handleToggle} icons={{unchecked: null}} />
  );

  const headerMarkup = (
    <Box width="100%">
      <Stack>
        {!mdDown ? (
          <Box minWidth="fit-content">
            <Stack>{actionMarkup}</Stack>
          </Box>
        ) : null}
        {settingTitle}
      </Stack>
    </Box>
  );

  const descriptionMarkup = (
    <Stack>
      <Text id={descriptionId} variant="bodyMd" as="p" color="subdued">
        {description}
      </Text>
      {mdDown ? (
        <Box width="100%">
          <Stack>{actionMarkup}</Stack>
        </Box>
      ) : null}
    </Stack>
  );

  const toggleWhatsappEnable = async () => {
    if (!isWhatsappEnabled && !isTwilioConnectionConnected) {
      setOpenConnectModal(true);
    } else {
      let response = await setProductNpsSetting({
        set: enabled,
        isWhatsappEnabled: !isWhatsappEnabled,
      });
      if (response.status) {
        setIsWhatsappEnabled(!isWhatsappEnabled);
        const userDetails = LocalStorageService.getAccountDetails();
        accessToken = userDetails.access_token;
        AuthAPI.userInfo({params: {accessToken}}).then(response => {
          updateUserDetails(response.data);
        });
      }
    }
  };
  const setDefaultTemplates = async type => {
    let response = await getDefaultNpsTemplate({type});
    if (response.status) {
      if (type === 'email') {
        let {body, subject} = response.data;
        setTemplate({...template, body, subject});
      } else if (type === 'whatsapp') {
        let {body} = response.data;
        setWhatsappTemplate({...whatsappTemplate, body});
      }
    }
  };
  return (
    <>
      <Box width="100%" paddingInlineStart={'4'} paddingBlockEnd={'4'} as="p">
        <Stack vertical>
          <Text color="subdued" variant="headingSm">
            Understand what your customers truly feel
          </Text>
        </Stack>
      </Box>
      <Card sectioned>
        <Stack vertical>
          <Box width="100%">
            <Stack vertical>
              {headerMarkup}
              {descriptionMarkup}
            </Stack>
          </Box>
          <Box>
            <Inline blockAlign="start">
              <Toggle
                disabled={!enabled}
                checked={isWhatsappEnabled}
                onChange={toggleWhatsappEnable}
                icons={{unchecked: null}}
              />
              <Stack spacing="extraTight" vertical>
                <Text variant="headingSm">Send NPS on WhatsApp/Text</Text>
                <Text variant="bodySm">Additional provider charges may apply*</Text>
              </Stack>
            </Inline>
            {isWhatsappEnabled && (
              <Box paddingBlockStart={'3'}>
                <Text color="subdued" variant="bodySm">
                  Please note while we don't charge anything for sending whatsapp messages, your
                  provider will likely charge for sending whatsapp messages.
                </Text>
              </Box>
            )}
          </Box>

          <Text variant="bodyMd" as="p"></Text>
        </Stack>
      </Card>
      <Card>
        <Card.Section>
          <Inline align="space-between">
            <Text variant="headingMd">Email template</Text>
            <ButtonGroup>
              <Button
                primary
                onClick={
                  emailTemplateEditMode
                    ? () => updateTemplate('email')
                    : () => setEmailTemplateEditMode(true)
                }
              >
                {emailTemplateEditMode ? 'Save' : 'Edit'}
              </Button>
              <Button onClick={() => setOpenSendEmailModal(true)}>Sent Test Email</Button>
              {emailTemplateEditMode && (
                <Button
                  onClick={() => {
                    setDefaultTemplates('email');
                  }}
                >
                  Default template
                </Button>
              )}
            </ButtonGroup>
          </Inline>
        </Card.Section>
        <Card.Section>
          <EmailTemplatePreviewEdit
            editMode={emailTemplateEditMode}
            template={template}
            setTemplate={setTemplate}
          />
        </Card.Section>
      </Card>
      {isTwilioConnectionConnected && (
        <Card>
          <Card.Section>
            <Inline align="space-between">
              <Text variant="headingMd">Whatsapp template</Text>
              <ButtonGroup>
                <Button
                  primary
                  onClick={
                    whatsappTemplateEditMode
                      ? () => updateTemplate('whatsapp')
                      : () => setWhatsappTemplateEditMode(true)
                  }
                >
                  {whatsappTemplateEditMode ? 'Save' : 'Edit'}
                </Button>
                <Button onClick={() => setOpenSendWhatsappMessageModal(true)}>
                  Send test message
                </Button>
                {whatsappTemplateEditMode && (
                  <Button
                    onClick={() => {
                      setDefaultTemplates('whatsapp');
                    }}
                  >
                    Default template
                  </Button>
                )}
              </ButtonGroup>
            </Inline>
          </Card.Section>
          <Card.Section>
            <WhatsappTemplatePreviewEdit
              editMode={whatsappTemplateEditMode}
              template={whatsappTemplate}
              setTemplate={setWhatsappTemplate}
            />
          </Card.Section>
        </Card>
      )}
      {openConnectModal && (
        <NoConnectionModal
          currentState={openConnectModal}
          connectionRequired={'twilio'}
          onClose={() => {
            setOpenConnectModal(false);
          }}
        />
      )}

      {openSendEmailModal && (
        <SendEmailModal
          isOpen={openSendEmailModal}
          toggle={() => {
            setOpenSendEmailModal(!openSendEmailModal);
          }}
          handleProceed={sendTestEmail}
        />
      )}
      {openSendWhatsappMessageModal && (
        <SendWhatsappMessageModal
          isOpen={openSendWhatsappMessageModal}
          toggle={() => {
            setOpenSendWhatsappMessageModal(!openSendWhatsappMessageModal);
          }}
          handleProceed={sendTestMessage}
        />
      )}

      {showAlert && (
        <Alert
          contents={showAlert}
          init={true}
          error={showAlert === 'Something Went Wrong'}
          duration={10000}
        />
      )}
    </>
  );
}
