import {Card} from '@shopify/polaris';
import React from 'react';

function MyCard({children, ...props}) {
  return (
    <Card sectioned {...props}>
      {children}
    </Card>
  );
}

export default MyCard;
