import {Button, OptionList, Popover, Text} from '@shopify/polaris';
import React, {useCallback, useEffect, useState} from 'react';

const By = ({options, dispatch, indexes, value, user}) => {
  const [popoverActive, setPopoverActive] = useState(false);
  const [selected, setSelected] = useState([]);
  const [name, setName] = useState('');
  const togglePopoverActive = useCallback(
    () => setPopoverActive(popoverActive => !popoverActive),
    []
  );

  const activator = (
    <Button
      textAlign="left"
      disclosure={popoverActive ? 'up' : 'down'}
      onClick={togglePopoverActive}
      fullWidth
      disabled={user === 'Retainwise'}
    >
      <Text color="subdued">{selected.length ? name : 'By'}</Text>
    </Button>
  );
  useEffect(() => {
    if (!value) {
      setSelected([]);
    } else {
      setSelected([value]);
    }
  }, [value]);
  useEffect(() => {
    let n = '';
    options.forEach(a => {
      if (a.value === selected[0]) {
        n = a.label;
      }
    });

    setName(n);
  }, [selected]);
  return (
    <>
      <Popover
        active={popoverActive}
        activator={activator}
        autofocusTarget="first-node"
        onClose={togglePopoverActive}
        preferredPosition="below"
        fullWidth
      >
        <Popover.Pane>
          <OptionList
            onChange={e => {
              setSelected(e);
              dispatch({type: 'by', indexes: indexes, value: e[0]});
              togglePopoverActive();
            }}
            options={options}
            selected={selected}
          />
        </Popover.Pane>
      </Popover>
    </>
  );
};

export default By;
