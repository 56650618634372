import React, {useEffect, useState} from 'react';
import Page from '../../../components/page';
import {Box, Layout, TextField, Button, Text, Inline} from '@shopify/polaris';
import {track} from '../../../features/track';
import {getCost, updateCost} from '../../../api/cost';
import Alert from '../../../components/alert';
import {getCurrency} from '../transaction/currencySymbol';
import {LocalStorageService} from '../../../service/localStorage';

const ShippingCost = () => {
  const [shippingCost, setShippingCost] = useState('');
  const [returnCost, setReturnCost] = useState('');
  const [showAlert, setShowAlert] = useState('');
  const currency = LocalStorageService.getUserOrg().currency;

  useEffect(() => {
    getCost().then(response => {
      if (response.status === 200) {
        setShippingCost(response.data.data?.shippingCost_value);
        setReturnCost(response.data.data?.shippingCost_returnValue);
      } else {
        setShippingCost(0);
        setReturnCost(0);
      }
    });
  }, []);

  const handleSaveChanges = () => {
    // Add logic to save changes in valueType
    const params = {
      shippingCost_valueType: 'flat',
      shippingCost_value: shippingCost,
      shippingCost_returnValue: returnCost,
    };
    updateCost(params)
      .then(() => {
        setShowAlert('Settings updated successfully.');
      })
      .catch(err => {
        setShowAlert('Error updating the settings. Please try again later.');
      });
  };

  useEffect(() => {
    track('Page View', {
      name: 'ShippingCost',
    });
  }, []);

  return (
    <>
      <Page title={'Shipping costs'}>
        <Box paddingBlockStart={'4'}>
          <Layout>
            <Layout.Section>
              <Text as="p" color="subdued">
                Shipping costs refer to payments made to 3PL partners (for both forward shipping and
                return pickups) and warehousing related activities.
              </Text>
            </Layout.Section>
            <Layout.Section>
              <Inline>
                <div style={{width: '20%'}}>
                  <Text variant="bodyXs" as="p" color="subdued">
                    Shipping Cost per order (Forward)
                  </Text>
                </div>

                <Box width="20%">
                  <TextField
                    type="number"
                    placeholder="Enter value"
                    suffix={getCurrency(currency)}
                    value={shippingCost}
                    onChange={value => setShippingCost(value)}
                  />
                </Box>
              </Inline>
            </Layout.Section>
            <Layout.Section>
              <Inline>
                <div style={{width: '20%'}}>
                  <Text variant="bodyXs" as="p" color="subdued">
                    Shipping Cost per order (Return)
                  </Text>
                </div>

                <Box width="20%">
                  <TextField
                    type="number"
                    placeholder="Enter value"
                    suffix={getCurrency(currency)}
                    value={returnCost}
                    onChange={value => setReturnCost(value)}
                  />
                </Box>
              </Inline>
            </Layout.Section>
            <Layout.Section>
              <Button primary onClick={handleSaveChanges}>
                Save changes
              </Button>
            </Layout.Section>
          </Layout>
        </Box>
        {showAlert && (
          <Alert
            contents={showAlert}
            init={true}
            error={showAlert === 'Something Went Wrong'}
            duration={10000}
          />
        )}
      </Page>
    </>
  );
};

export {ShippingCost};
