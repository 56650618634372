import React, {useEffect, useMemo, useState, useCallback} from 'react';
import {AdminApi} from '../../../api/admin';
import {adminSummaryLogsColumns} from '../../metrics/columns';
import Page from '../../../components/page';
import DefaultIndexTable from '../../../components/defaultIndexTable';
import MultiSelect from '../../../components/multiSelect';
import Inline from '../../../lib/Inline';
import useApiCall from '../../../hooks/useApiCall';

import DateRangeComponent from '../../../components/dateRangeComponent';
import {
  thirtyDaysBefore,
  todayDate,
  yesterDayDate,
  ninetyDaysBefore,
  sevenDaysBefore,
  dateToNormalString,
  toClickHouseDate,
  oneYearBefore,
} from '../../../features/convertDates';
import {formatDate} from '../../../features/format';
import Box from '../../../lib/Box';
import Button from '../../../lib/Button';
import HtmlPreviewModal from '../../../components/Modals/HtmlPreviewModal';

const dateRangeObj = {
  yesterday: yesterDayDate,
  today: todayDate,
  lastThirtyDays: thirtyDaysBefore,
  lastSevenDays: sevenDaysBefore,
  lastNinetyDays: ninetyDaysBefore,
  lastOneYear: oneYearBefore,
};
const dateRangeValueToLable = {
  today: 'Today',
  yesterday: 'Yesterday',
  lastSevenDays: 'Last 7 Days',
  lastThirtyDays: 'Last 30 Days',
  lastNinetyDays: 'Last 90 Days',
  lastOneYear: 'Last 1 Year',
};
const SummaryLogs = () => {
  const [sortBy, setSortBy] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('desc');
  const [orgsOptions, setOrgsOptions] = useState([]);
  const [orgs, setOrgs] = useState('');
  const [status, setStatus] = useState('');
  const [dataToPreview, setDataToPreview] = useState(null);
  const [organizationIdToNameMap, setOrganizationIdToNameMap] = useState(null);
  const [page, setPage] = useState(1);
  const [popoverActive, setPopoverActive] = useState(false);
  const [dateRange, setDateRange] = useState('lastSevenDays');
  const [selectedDates, setSelectedDates] = useState(
    dateRange
      ? {
          start: new Date(dateRangeObj[dateRange]()[0]),
          end: new Date(dateRangeObj[dateRange]()[1]),
        }
      : {
          start: new Date(),
          end: new Date(),
        }
  );
  const getDates = (dates, dateOption) => {
    setSelectedDates(dates);
    setDateRange(dateOption);
  };
  const fetchSummaryLogs = async () => {
    let sdate = toClickHouseDate(selectedDates.start, 'start');
    let edate = toClickHouseDate(selectedDates.end, 'end');
    if (Boolean(organizationIdToNameMap)) {
      let res = await AdminApi.getSummaryLogs({
        sortBy: sortBy,
        sortOrder: sortOrder,
        page,
        orgId: orgs,
        startDate: sdate,
        endDate: edate,
        status,
      });
      return res;
    }
  };
  const summaryLogsApiDependencies = [
    sortBy,
    sortOrder,
    page,
    orgs,
    selectedDates,
    organizationIdToNameMap,
    status,
  ];
  const _summaryLogs = useApiCall(fetchSummaryLogs, summaryLogsApiDependencies);
  const summaryLogs = useMemo(() => {
    if (_summaryLogs.state === 'Success') {
      return {state: _summaryLogs.state, ..._summaryLogs.data};
    }
    return {logs: [], state: _summaryLogs.state, ..._summaryLogs};
  }, [_summaryLogs]);

  const fetchOrganizations = async () => {
    try {
      let res = await AdminApi.getOrgs();
      let arr = [{label: 'All', value: ''}];
      let map = {};
      if (res?.status) {
        res.data.forEach(r => {
          arr.push({label: r.name, value: r._id});
          map[r._id] = r.name;
        });
        setOrganizationIdToNameMap({...map});
        setOrgsOptions(arr);
      } else {
        setOrgsOptions([]);
      }
    } catch (err) {
      setOrgsOptions([]);
    }
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const togglePopoverActive = useCallback(
    () => setPopoverActive(popoverActive => !popoverActive),
    []
  );
  const activator = (
    <div style={{width: '100%'}}>
      <Button onClick={togglePopoverActive} disclosure>
        {dateRange ? dateRangeValueToLable[dateRange] : dateToNormalString(selectedDates)}
      </Button>
    </div>
  );

  return (
    <>
      {' '}
      <Page title="Summary logs">
        <DefaultIndexTable
          filters={[
            <MultiSelect
              title="Organization"
              options={orgsOptions}
              selected={orgs}
              setSelected={setOrgs}
              disableMultiple={true}
              setPage={setPage}
            />,
            <MultiSelect
              title="Status"
              options={[
                {label: 'Success', value: 'success'},
                {label: 'Failed', value: 'failed'},
                {label: 'Pending', value: 'pending'},
              ]}
              selected={status}
              setSelected={setStatus}
              disableMultiple={true}
              setPage={setPage}
            />,
            <Box>
              <DateRangeComponent
                currentDates={selectedDates}
                getDates={getDates}
                activator={activator}
                popoverActive={popoverActive}
                togglePopoverActive={togglePopoverActive}
                currentDateOption={dateRange}
              />
            </Box>,
          ]}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortExceptions={[]}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          select={false}
          page={page}
          setPage={setPage}
          resourceName={{
            singular: 'Summary log',
            plural: 'Summary logs',
          }}
          columns={adminSummaryLogsColumns}
          selectedFilters={[]}
          durationFilter={'daily'}
          totalRows={summaryLogs.logs.length ? summaryLogs.totalRows : 0}
          tableData={summaryLogs.logs}
          param={{}}
          state={summaryLogs.state}
          valueTypes={{
            recipients: (data, col) => {
              return <Inline>{data['recipients'].join(', ')}</Inline>;
            },
            showHtml: (data, col) => {
              return (
                <Button
                  key={data._id}
                  plain
                  onClick={() => {
                    setDataToPreview(data?.body);
                  }}
                >
                  Show content
                </Button>
              );
            },
            organization: (data, col) => {
              return (
                <div key={`${col.value} ${data[col.value]}`}>
                  {organizationIdToNameMap[data[col.value]]}
                </div>
              );
            },
            date: (data, col) => {
              return (
                <Inline key={`${col.value} ${data[col.value]}`} align="end">
                  {formatDate({
                    value: new Date(data[col.value]),
                    removeTime: false,
                    seconds: true,
                  })}
                </Inline>
              );
            },
          }}
        />
        {Boolean(dataToPreview) && (
          <HtmlPreviewModal
            isOpen={Boolean(dataToPreview)}
            toggle={() => setDataToPreview(null)}
            htmlContent={dataToPreview}
          />
        )}
      </Page>
    </>
  );
};

export default SummaryLogs;
