import React from 'react';
import {useEffect} from 'react';
import {useCallback} from 'react';
import {useState} from 'react';
import DateRangeComponent from './../../../components/dateRangeComponent';
import Page from './../../../components/page';
import {useParams, useSearchParams} from 'react-router-dom';
import SelectRFMComponent from './../../../components/selectRfmComponent';
import {saveAs} from 'file-saver';
import {
  Box,
  Card,
  Button,
  Spinner,
  ButtonGroup,
  Tooltip,
  FooterHelp,
  Checkbox,
} from '@shopify/polaris';
import Inline from '../../../lib/Inline';

import {
  thirtyDaysBefore,
  todayDate,
  yesterDayDate,
  ninetyDaysBefore,
  sevenDaysBefore,
  dateToNormalString,
  toClickHouseDate,
  convertToOrgTimezone,
  oneYearBefore,
} from './../../../features/convertDates';
import {Loading} from '@shopify/polaris';
import {ErrorBoundary} from './../../../components/ErrorBoundary';
import DefaultIndexTable from './../../../components/defaultIndexTable';
import {useStoreInfo} from './../../../app/store';
import DefaultSelect from './../../../components/defaultSelect';
import {getStore} from './../../../api/store';
import {track} from './../../../features/track';
import {LocalStorageService} from './../../../service/localStorage';
import {
  formatForDownload,
  formatLtvAmount,
  formatLtvNumber,
  formatPercentage,
} from '../../../features/format';
import MultiSelect from './../../../components/multiSelect';
import SelectCohortComponent from './../selectCohortComponent';
import {setPercentageValues} from './../setPercentageValues';
import {relableMonthKeys} from './../relableMonthKeys';
import {getCsv} from '../../../api/convertToCsv';
import {getProductGroups} from '../../../api/ltvFilters';
import MultiSelectSearch from '../../../components/multiSelectSearch';
import {getLabelFromValue} from './../utils';
import config from '../../../config';
import apiToCall from '../../../features/apiToCall';
import DefinationPopover from '../../../components/definationPopover';

const npsOptions = [
  {label: 'All Products', value: 'All Products'},
  {label: 'Promoters', value: 'promoters'},
  {label: 'Neutrals', value: 'neutrals'},
  {label: 'Detractors', value: 'detractors'},
];

const dateRangeObj = {
  yesterday: yesterDayDate,
  today: todayDate,
  lastThirtyDays: thirtyDaysBefore,
  lastSevenDays: sevenDaysBefore,
  lastNinetyDays: ninetyDaysBefore,
  lastOneYear: oneYearBefore,
};

const dateRangeValueToLable = {
  today: 'Today',
  yesterday: 'Yesterday',
  lastSevenDays: 'Last 7 Days',
  lastThirtyDays: 'Last 30 Days',
  lastNinetyDays: 'Last 90 Days',
  lastOneYear: 'Last 1 Year',
};

const CompareCohort = () => {
  const param = useParams();
  const isCohortReport = true;
  const reportName = 'compare-cohort';
  const [sortOrder, setSortOrder] = useState(apiToCall[reportName].sortOrder);
  const [queryValue, setQueryValue] = useState('');
  const [appliedFilters, setAppliedFilters] = useState({
    orderTags: '',
    customerTags: '',
    productPurchased: '',
    discount: '',
  });
  const [isFirstButtonActive, setIsFirstButtonActive] = useState(true);
  const [checked, setChecked] = useState(false);
  const [shouldUpdateTable, setShouldUpdateTable] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [selectedSourceName, setSelectedSourceName] = useState(
    searchParams.get('sourceName') ? searchParams.get('sourceName') : 'All Sources'
  );
  const [initRender, setInitRender] = useState(true);
  const {sourceNames, setSourceNames} = useStoreInfo(state => state);
  const [productGroup, setProductGroup] = useState([]);
  const [selectedProductGroup, setSelectedProductGroup] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [durationFilter, setDurationFilter] = useState(
    searchParams.get('durationFilter') || apiToCall[reportName].durationFilter || 'monthly'
  );
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState(apiToCall[reportName].sortBy);
  const [dateRangePopoverActive, setDateRangePopoverActive] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState(
    searchParams.get('selectedDateRange')
      ? searchParams.get('selectedDateRange') === 'undefined' ||
        searchParams.get('selectedDateRange') === 'null'
        ? null
        : searchParams.get('selectedDateRange')
      : searchParams.get('startDate')
      ? null
      : 'lastOneYear'
  );
  const [download, setDownload] = useState({state: 'Success'});
  const [selectedDateRangeDates, setSelectedDateRangeDates] = useState(
    searchParams.get('startDate') && searchParams.get('endDate')
      ? {start: new Date(searchParams.get('startDate')), end: new Date(searchParams.get('endDate'))}
      : selectedDateRange
      ? {
          start: dateRangeObj[selectedDateRange]()[0],
          end: dateRangeObj[selectedDateRange]()[1],
        }
      : {
          start: new Date(),
          end: new Date(),
        }
  );
  const [data, setData] = useState({state: 'Success', data: []});
  const [resultType, setResultType] = useState('relative');
  const [rfmSegment, setRfmSegment] = useState('All Customers');
  const [npsOn, setNpsOn] = useState(false);
  const [selectedNps, setSelectedNps] = useState('');
  const [cohorts, setCohorts] = useState([]);
  const [popoverActive, setPopoverActive] = useState(false);
  const togglePopoverActive = () => setPopoverActive(!popoverActive);

  const onChangeHandler = updatedFilters => {
    setAppliedFilters(prevFilters => {
      const mergedFilters = {...prevFilters};
      Object.keys(updatedFilters).forEach(key => {
        mergedFilters[key] = updatedFilters[key];
      });
      return mergedFilters;
    });
  };

  const formatFunction = (...args) => {
    if (resultType === 'relative') {
      return formatPercentage(...args);
    } else {
      if (reportName === 'customer-cohort') {
        return formatLtvNumber(...args);
      } else {
        return formatLtvAmount(...args);
      }
    }
  };

  useEffect(() => {
    track('Page View', {
      //Mixpanel
      name: 'Report',
    });
    const userDetails = LocalStorageService.getUserOrg();
    if (userDetails?.reportSettings?.nps?.product === true) {
      //gets nps setting of org
      setNpsOn(true);
    }
    if (!sourceNames.length) {
      //gets all sourcenames of org
      getStore().then(res => {
        setSourceNames(res.data.data.sourceName);
      });
    }
    if (!productGroup.length) {
      getProductGroups().then(res => {
        if (res.data.length > 0) {
          setProductGroup(res.data);
        }
      });
    }
  }, []);

  const handleFirstButtonClick = useCallback(() => {
    setResultType('relative');
    if (isFirstButtonActive) return;
    setIsFirstButtonActive(true);
  }, [isFirstButtonActive]);

  const handleSecondButtonClick = useCallback(() => {
    setResultType('absolute');
    if (!isFirstButtonActive) return;
    setIsFirstButtonActive(false);
  }, [isFirstButtonActive]);

  const firstButtonTooltip = 'Percentage view';
  const secondButtonTooltip = 'Numbers view';
  const getDateRangeDates = (dates, selectedDateRange) => {
    setSelectedDateRangeDates(dates);
    setSelectedDateRange(selectedDateRange);
  };
  const dateRangeTogglePopoverActive = useCallback(
    () => setDateRangePopoverActive(dateRangePopoverActive => !dateRangePopoverActive),
    []
  );
  const dateRangeActivator = (
    <div style={{width: '100%'}}>
      <Button onClick={dateRangeTogglePopoverActive} disclosure>
        {selectedDateRange
          ? dateRangeValueToLable[selectedDateRange]
          : dateToNormalString(selectedDateRangeDates)}
      </Button>
    </div>
  );

  //setting search params
  useEffect(() => {
    let isApiCalled = false;
    setTimeout(() => {
      if (!isApiCalled) {
        if (!initRender) {
          let params = {};
          if (selectedDateRange) {
            params = {selectedDateRange};
          } else {
            params = {
              startDate: toClickHouseDate(selectedDateRangeDates.start, 'start'),
              endDate: toClickHouseDate(selectedDateRangeDates.end, 'end'),
            };
          }

          params = {...params, durationFilter};
          if (selectedSourceName) {
            params = {...params, sourceName: selectedSourceName};
          }
          if (apiToCall[reportName].rfmSegmentation) {
            if (rfmSegment) {
              params = {...params, rfmSegment};
            }
          }
          let flag = false;
          for (let key in params) {
            if (searchParams.get(key) !== params[key]) {
              flag = true;
            }
          }
          if (flag) {
            setSearchParams({...params});
          }
        } else {
          setInitRender(false);
        }
      }
    }, config.DEBOUNCING_DELAY);

    return () => {
      isApiCalled = true;
    };
  }, [selectedDateRangeDates, durationFilter, selectedSourceName]);

  //receiving search params
  useEffect(() => {
    if (searchParams.get('selectedDateRange')) {
      setSelectedDateRange(searchParams.get('selectedDateRange'));
    } else {
      if (searchParams.get('startDate')) {
        setSelectedDateRangeDates({
          start: new Date(searchParams.get('startDate')),
          end: new Date(searchParams.get('endDate')),
        });
      } else {
        setSelectedDateRange('lastOneYear');
        setDurationFilter('monthly');
        setSelectedSourceName('All Sources');
      }
    }
    if (searchParams.get('durationFilter')) {
      setDurationFilter(searchParams.get('durationFilter'));
    }
    if (searchParams.get('sourceName')) {
      setSelectedSourceName(searchParams.get('sourceName'));
    }
    if (searchParams.get('rfmSegment')) {
      setRfmSegment(searchParams.get('rfmSegment'));
    }
  }, [searchParams]);

  const fetchLtvData = async ({getLastCohortReport}) => {
    let startDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.start, 'start'));
    let endDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.end, 'end'));
    let postData = {
      startDate,
      endDate,
      page,
      search: queryValue,
      sortBy,
      sortOrder,
      type: 'table',
      durationFilter,
      reportType: apiToCall[reportName].cohort,
      resultType,
      rfmSegment,
      sourceName: selectedSourceName,
      nps: selectedNps[0],
      discountCode: appliedFilters['discount'],
      productPurchased: appliedFilters['productPurchased'],
      orderTags: appliedFilters['orderTags'],
      customerTags: appliedFilters['customerTags'],
      cumulative: checked,
      productGroup: selectedProductGroup,
      cohorts: cohorts,
      getLastCohortReport,
    };
    let res = await apiToCall[reportName].api(postData);
    return res.data;
  };

  const ltvDataDependencies = [
    selectedDateRangeDates,
    page,
    queryValue,
    sortBy,
    sortOrder,
    durationFilter,
    // resultType,
    rfmSegment,
    selectedSourceName,
    selectedNps,
    reportName,
    checked,
    appliedFilters,
    selectedProductGroup,
  ];

  useEffect(() => {
    if (cohorts.length > 0) {
      setData({state: 'Loading', data: []});
      fetchLtvData({getLastCohortReport: false})
        .then(response => {
          if (response.status) {
            setData({state: 'Success', data: [...response.data]});
          } else {
            setData({data: [], state: 'Error', error: response.message});
          }
        })
        .catch(error => {
          setData({data: [], state: 'Error', error: error.message});
        });
    }
  }, [...ltvDataDependencies]);

  useEffect(() => {
    if (data.data.length < cohorts.length) {
      const prevData = [...data.data];
      setData({state: 'Loading', data: prevData});
      fetchLtvData({getLastCohortReport: true})
        .then(response => {
          if (response.status) {
            setData({state: 'Success', data: [...prevData, ...response.data]});
          } else {
            setData({data: [], state: 'Error', error: response.message});
          }
        })
        .catch(error => {
          setData({data: [], state: 'Error', error: error.message});
        });
    }
  }, [cohorts]);

  //setting table data to be sent to indexTable based upon data and result type(relative/absolute)
  useEffect(() => {
    if (resultType === 'relative' && data.data.length > 0) {
      const updatedResponseData = setPercentageValues(data.data);

      setTableData([...updatedResponseData]);
      setShouldUpdateTable(true);
    } else {
      setTableData([...data.data]);
      setShouldUpdateTable(true);
    }
  }, [resultType]);

  useEffect(() => {
    if (resultType === 'relative' && data.data.length > 0) {
      const updatedResponseData = setPercentageValues(data.data);

      setTableData([...updatedResponseData]);
      setShouldUpdateTable(true);
    } else {
      setTableData([...data.data]);
      setShouldUpdateTable(true);
    }
  }, [data]);

  useEffect(() => {
    if (durationFilter !== 'monthly' && shouldUpdateTable) {
      const updatedResponseData = relableMonthKeys(tableData, durationFilter);
      setTableData(updatedResponseData);
      setShouldUpdateTable(false);
    }
  }, [tableData, durationFilter, shouldUpdateTable]);
  const handleDownload = () => {
    let downloadData = JSON.parse(JSON.stringify(tableData));
    setDownload({state: 'Loading'});
    if (reportName !== 'customer-cohort') {
      if (resultType !== 'relative') {
        downloadData = formatForDownload(downloadData);
      }
    }
    const params = {
      data: downloadData,
      reportType: reportName,
    };
    getCsv({params})
      .then(response => {
        setDownload({state: 'Success'});
        if (response.data.length) {
          saveAs(
            new Blob([response.data], {
              type: response.headers['content-type'],
            }),
            response.headers.filename
          );
        } else {
          setDownload({state: 'Error'});
        }
      })
      .catch(err => {
        setDownload({state: 'Error'});
      });
  };

  const checkboxTooltipContent = (
    <span>
      Checking this will show summation of the particular metric till that month, rather than the
      individual performance in that month.
    </span>
  );

  const handleChange = useCallback(newChecked => setChecked(newChecked), []);

  return (
    <>
      <Page
        secondaryActions={[
          {
            content: download.state === 'Loading' ? <Spinner size="small" /> : 'Download',
            destructive: false,
            onAction: handleDownload,
          },
        ]}
        title={'LTV'}
      >
        {reportName === 'compare-cohort' ? !data.state && <Loading /> : ''}
        <Box paddingBlockEnd={'4'}>
          <Inline align="space-between">
            <Inline>
              <DateRangeComponent
                currentDates={selectedDateRangeDates}
                activator={dateRangeActivator}
                togglePopoverActive={dateRangeTogglePopoverActive}
                popoverActive={dateRangePopoverActive}
                getDates={getDateRangeDates}
                currentDateOption={selectedDateRange}
              />

              <SelectCohortComponent
                setDurationFilter={setDurationFilter}
                durationFilter={durationFilter}
                setPage={setPage}
                setQueryValue={setQueryValue}
              />

              <SelectRFMComponent
                setRfmSegment={setRfmSegment}
                rfmSegment={rfmSegment}
                setPage={setPage}
                setQueryValue={setQueryValue}
              />

              <DefaultSelect
                value={selectedSourceName}
                setValue={setSelectedSourceName}
                list={sourceNames}
                setPage={setPage}
                setQueryValue={setQueryValue}
              />

              {productGroup.length > 0 && (
                <MultiSelectSearch
                  title="Product Group"
                  options={productGroup.map(element => ({
                    label: element,
                    value: element,
                  }))}
                  selected={selectedProductGroup}
                  setSelected={setSelectedProductGroup}
                  setChecked={setChecked}
                  setPage={setPage}
                />
              )}

              {npsOn === true && (
                <MultiSelect
                  selected={selectedNps}
                  setSelected={setSelectedNps}
                  options={npsOptions}
                  title={
                    selectedNps.length
                      ? getLabelFromValue(selectedNps[0], npsOptions)
                      : 'Product NPS'
                  }
                  disableMultiple
                  setPage={setPage}
                />
              )}
            </Inline>
            <Inline>
              <ButtonGroup segmented>
                <Tooltip content={firstButtonTooltip}>
                  <Button pressed={isFirstButtonActive} onClick={handleFirstButtonClick}>
                    %
                  </Button>
                </Tooltip>
                <Tooltip content={secondButtonTooltip}>
                  <Button pressed={!isFirstButtonActive} onClick={handleSecondButtonClick}>
                    #
                  </Button>
                </Tooltip>
              </ButtonGroup>
              <DefinationPopover
                popoverActive={popoverActive}
                togglePopoverActive={togglePopoverActive}
                activator={
                  <span onMouseEnter={togglePopoverActive} onMouseLeave={togglePopoverActive}>
                    Cumulative
                  </span>
                }
                text={checkboxTooltipContent}
              />
              <Checkbox
                checked={checked}
                onChange={handleChange}
                onMouseEnter={togglePopoverActive}
                onMouseLeave={togglePopoverActive}
              />
            </Inline>
          </Inline>
        </Box>
        <Box paddingBlockEnd={'4'}>
          <ErrorBoundary
            fallback={
              <Card>
                <Card.Section>
                  <div>An error has occured</div>
                </Card.Section>
              </Card>
            }
          >
            <DefaultIndexTable
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortExceptions={[...apiToCall[reportName].sortExecptions]}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              select={false}
              queryValue={queryValue}
              setQueryValue={setQueryValue}
              page={page}
              setPage={setPage}
              resourceName={{
                singular: 'metrics',
                plural: 'metrics',
              }}
              columns={apiToCall[reportName].columns}
              selectedFilters={[]}
              totalRows={data.data.length ? data.data[0].totalRows : 0}
              tableData={tableData}
              durationFilter={durationFilter}
              isCohortReport={isCohortReport}
              isCompareCohort={true}
              state={data.state}
              param={param}
              cumulative={checked}
              onChange={onChangeHandler}
              formatFunction={formatFunction}
              cohorts={cohorts}
              setCohorts={setCohorts}
              setTableData={setTableData}
              setData={setData}
              data={data}
            />
          </ErrorBoundary>
        </Box>
        <FooterHelp>
          {['ltv-cohort', 'compare-cohort'].includes(reportName) && (
            <div
              style={{
                textAlign: 'center',
                backgroundColor: 'rgba(105, 205, 255,0.2)',
                padding: '10px',
              }}
            >
              <span>
                Currently, the LTV is calculated based on revenue value only. Ideally, the LTV
                should adjust for various variable expenses such as COGS, shipping, transaction and
                fulfilment costs. We will be adding this feature soon.
              </span>
            </div>
          )}
        </FooterHelp>
      </Page>
    </>
  );
};

export {CompareCohort};
