import React from 'react';
import Inline from '../../../lib/Inline';
import Stack from '../../../lib/Stack';
import Card from '../../../lib/Card';
import Text from '../../../lib/Text';
import {Layout, RadioButton} from '@shopify/polaris';
import Button from '../../../lib/Button';
import {connectionAuthInitiate} from '../../../api/connection';
import TextContainer from '../../../lib/TextContainer';
const FacebookData = ({facebook, setFacebook, isFacebookConnected}) => {
  return (
    <Card sectioned full>
      <Stack vertical alignment="baseline">
        <Layout>
          <Layout.Section>
            <Inline align="space-between">
              <Text variant="headingMd">Facebook </Text>
            </Inline>
          </Layout.Section>

          <Layout.Section>
            <Stack vertical alignment="baseline">
              <Text variant="headingSm">Do you want to connect your Facebook account? </Text>

              <Stack>
                <RadioButton
                  label="Yes"
                  checked={facebook === true}
                  id="fb_enable"
                  name="fb"
                  onChange={() => setFacebook(true)}
                />
                <RadioButton
                  label="Skip"
                  id="fb_skip"
                  name="fb"
                  checked={facebook === false}
                  onChange={() => setFacebook(false)}
                />
              </Stack>
            </Stack>
          </Layout.Section>

          <Layout.Section>
            {facebook && (
              <Stack vertical alignment="baseline">
                {isFacebookConnected ? (
                  <TextContainer>
                    <Text alignment="start" variant="headingMd">
                      Facebook connection connected
                    </Text>
                    <Text alignment="start">
                      All set to go! Once we have completed the data-sync you will get an email. It
                      should take less than 24 hours. Keep an eye out on your spam box too
                    </Text>
                  </TextContainer>
                ) : (
                  <Inline>
                    <Text color="warning" variant="headingSm">
                      Connection to facebook required !
                    </Text>
                    <Button
                      onClick={() => {
                        connectionAuthInitiate('facebookAds').then(res => {
                          window.location.href = res;
                        });
                      }}
                      primary
                      plain
                    >
                      Connect
                    </Button>
                  </Inline>
                )}
              </Stack>
            )}
          </Layout.Section>
        </Layout>
      </Stack>
    </Card>
  );
};

export default FacebookData;
