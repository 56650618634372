import {FooterHelp, Tooltip} from '@shopify/polaris';
import React, {useEffect, useState} from 'react';
import {getOrg} from '../api/getOrg';
import {formatDate} from '../features/format';

const Footer = () => {
  const [orderSyncedAt, setOrderSyncedAt] = useState('');
  const [customerSyncedAt, setCustomerSyncedAt] = useState('');
  const [productSyncedAt, setProductSyncedAt] = useState('');

  useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname.indexOf('/admin/') === -1) {
      getOrg().then(res => {
        setOrderSyncedAt(res.data.sync?.orders?.updatedAt);
        setCustomerSyncedAt(res.data.sync?.customers?.updatedAt);
        setProductSyncedAt(res.data.sync?.products?.updatedAt);
      });
      //todo: remove chaining operator once updatedAt is added in order data for all organization
    }
  }, []);

  const explanationContent = (
    <div style={{margin: '5px 0'}}>
      <p>
        <strong>Orders</strong>:
      </p>
      <p> {orderSyncedAt ? formatDate({value: new Date(orderSyncedAt)}) : 'N/A'}</p>
      <p style={{marginTop: '10px'}}>
        <strong>Customers</strong>:
      </p>
      <p>{customerSyncedAt ? formatDate({value: new Date(customerSyncedAt)}) : 'N/A'}</p>
      <p style={{marginTop: '10px'}}>
        <strong>Products</strong>:
      </p>
      <p>{productSyncedAt ? formatDate({value: new Date(productSyncedAt)}) : 'N/A'}</p>
    </div>
  );

  return (
    <FooterHelp>
      {!!orderSyncedAt && (
        <Tooltip content={explanationContent}>
          <span>Last synced at {formatDate({value: new Date(orderSyncedAt)})}</span>
        </Tooltip>
      )}
    </FooterHelp>
  );
};

export {Footer};
