import {FormLayout, TextField} from '@shopify/polaris';
import {useState} from 'react';
import Inline from '../../../../lib/Inline';
import Button from '../../../../lib/Button';

function isValidDate(dateString) {
  if (!dateString) return false;
  const date = new Date(dateString);
  return date instanceof Date && !isNaN(date);
}

const DatesToAction = ({onAction, title, allButtonRequried, orgId, loading}) => {
  const [dates, setDates] = useState({startDate: '', endDate: ''});
  return (
    <>
      <FormLayout sectioned>
        <Inline>
          <TextField
            label="Start date"
            placeholder="YYYY-MM-DD"
            value={dates.startDate}
            onChange={e => {
              setDates({...dates, startDate: e});
            }}
            disabled={loading}
          />
          <TextField
            label="End date"
            placeholder="YYYY-MM-DD"
            value={dates.endDate}
            onChange={e => {
              setDates({...dates, endDate: e});
            }}
            disabled={loading}
          />
        </Inline>
        <Inline align="end">
          <Button
            onClick={() => {
              onAction({startDate: dates.startDate, endDate: dates.endDate, orgId});
            }}
            primary
            disabled={!isValidDate(dates.endDate) || !isValidDate(dates.startDate) || loading}
          >
            {title.split(' ')[0]}
          </Button>
          {allButtonRequried && (
            <Button
              disabled={dates.endDate || dates.startDate || loading}
              onClick={() => {
                onAction({allTime: true, orgId});
              }}
              primary
            >
              {title.split(' ')[0] + ' All'}
            </Button>
          )}
        </Inline>
      </FormLayout>
    </>
  );
};

export default DatesToAction;
