import React, {useState, useRef, useContext, useEffect} from 'react';
import {AdminApi} from '../../../api/admin';
import Page from '../../../components/page';
import DefaultIndexTable from '../../../components/defaultIndexTable';
import {adminOrganizationsColumns} from '../../metrics/columns';
import {Link, useNavigate} from 'react-router-dom';
import {Text, Icon, Box} from '@shopify/polaris';
import {formatDate, formatNumber, toTitleCase} from '../../../features/format';
import useApiCall from '../../../hooks/useApiCall';
import {AuthContext} from '../../../context/auth';
import ConfirmationModal from '../../../components/Modals/ConfirmationModal';
import {useSnapshotStore} from '../../../app/store';
import Inline from '../../../lib/Inline';

import {DiamondAlertMajor} from '@shopify/polaris-icons';
import DefinationPopover from '../../../components/definationPopover';
import Button from '../../../lib/Button';

const generateProgressText = data => {
  const required = ['products', 'customers', 'orders'];
  return (
    <table style={{borderSpacing: '10px 5px', borderCollapse: 'separate'}}>
      <thead>
        <tr>
          <th>Type</th>
          <th>Expected</th>
          <th>Clickhouse</th>
          <th>Diffrence</th>
        </tr>
      </thead>
      <tbody>
        {required.map(type => {
          return (
            <tr key={type}>
              <td>{toTitleCase(type)}</td>
              <td>{formatNumber({value: data.expected[type] || 0})}</td>
              <td>{formatNumber({value: data.clickhouse[type] || 0})}</td>
              <td>
                <Text
                  color={data.expected[type] - data.clickhouse[type] > 0 ? 'critical' : 'success'}
                >
                  {formatNumber({value: data.expected[type] - data.clickhouse[type]})}
                </Text>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
const Organizations = () => {
  const {resetAll} = useSnapshotStore(state => state);
  const {adminOrganizationSignIn} = useContext(AuthContext);
  const navigate = useNavigate();
  const [openConfirmModal, setOpenConfirmModal] = useState(null);
  const [sortBy, setSortBy] = useState('c');
  const [sortOrder, setSortOrder] = useState('desc');
  const [page, setPage] = useState(1);
  const [orgs, setOrgs] = useState({state: 'Loading', data: []});
  const [diffrencePopover, setDiffrencePopover] = useState(null);
  const controllerRef = useRef(null);
  const fetchOrganizations = async () => {
    let res = await AdminApi.getOrgs({sortBy, sortOrder, page});
    return res;
  };
  const fetchOrganizationExpectedToClickhouseDiffrence = async (orgId, signal) => {
    let [expected, clickhouse] = await Promise.all([
      await AdminApi.getOrgsExpected({orgId, signal}),
      AdminApi.getOrgsClickhouseStats({orgId, signal}),
    ]);
    if (clickhouse.status && expected.status) {
      let diffrence = 0;
      for (let key in clickhouse.data) {
        diffrence += expected.data[key] - clickhouse.data[key];
      }
      return {status: true, clickhouse: clickhouse.data, expected: expected.data, diffrence};
    } else {
      return {status: false};
    }
  };

  const generateDiffrenceStats = async _orgs => {
    let organizations = _orgs?.data?.data || [];
    controllerRef.current = new AbortController();

    for (let organization of organizations) {
      organization.expectedToClickhouse = await fetchOrganizationExpectedToClickhouseDiffrence(
        organization._id,
        controllerRef.current.signal
      );
      setOrgs(prevOrgs => ({...prevOrgs, ..._orgs}));
    }
  };

  const organizationsApiDependencies = [sortBy, sortOrder];
  const _orgs = useApiCall(fetchOrganizations, organizationsApiDependencies);

  useEffect(() => {
    if (_orgs.state === 'Success') {
      generateDiffrenceStats(_orgs);
      setOrgs(_orgs);
    } else {
      setOrgs({status: _orgs.state, data: {}});
    }
    return () => {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, [_orgs]);
  const logInOrganization = async orgId => {
    try {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
      let res = await AdminApi.signInOrganization({orgId});
      if (res.status) {
        adminOrganizationSignIn(res.data);
        resetAll();
        navigate('/');
      }
    } catch (err) {}
  };
  return (
    <>
      <Page title="Organizations">
        <DefaultIndexTable
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortExceptions={[]}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          select={false}
          page={page}
          setPage={setPage}
          resourceName={{
            singular: 'Organization',
            plural: 'Organizations',
          }}
          columns={adminOrganizationsColumns}
          selectedFilters={[]}
          durationFilter={'daily'}
          totalRows={orgs.data?.data?.length ? orgs.data?.totalRows : 0}
          tableData={orgs.data?.data || []}
          param={{}}
          state={orgs.state}
          valueTypes={{
            organization: (data, col) => {
              return (
                <Link
                  style={{textDecoration: 'none'}}
                  to={`/admin/organizations/${data._id + '+' + data.name} `}
                >
                  {data[col.value]}
                </Link>
              );
            },
            userEmail: (data, col) => {
              return data.users?.email || '';
            },
            brandImage: (data, col) => {
              return (
                <Box>
                  <img
                    alt={'Not found'}
                    width={'60px'}
                    height={'60px'}
                    src={
                      data.store?.logo ||
                      'https://user-images.githubusercontent.com/133949320/244652984-236bc15c-cc91-4ac0-ba33-1333c0d3ae5c.png'
                    }
                  />
                </Box>
              );
            },
            storeLocation: (data, col) => {
              return data.store?.meta ? (
                <Text>
                  {data.store.meta.city} {data.store.meta.province} {data.store.meta.country}
                </Text>
              ) : (
                ''
              );
            },
            date: (data, col) => {
              return (
                <>
                  {col.value === 'lastLoggedIn' ? (
                    <>
                      {data.users?.lastLoggedIn ? (
                        <Inline align="end">
                          {formatDate({
                            value: new Date(data.users?.lastLoggedIn),
                            removeTime: true,
                          })}
                        </Inline>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    <Inline align="end">
                      {formatDate({value: new Date(data[col.value]), removeTime: true})}
                    </Inline>
                  )}
                </>
              );
            },
            logIn: (data, col) => {
              return (
                <Button
                  plain
                  onClick={() => {
                    setOpenConfirmModal({
                      method: logInOrganization,
                      label: 'log in this organization',
                      params: [data[col.value]],
                    });
                  }}
                >
                  Login
                </Button>
              );
            },
            expectedToClickhouse: (data, col) => {
              let object = data[col.value];
              if (!object) return <>...</>;
              return object.status ? (
                <>
                  <DefinationPopover
                    togglePopoverActive={true}
                    maxWidth="30rem"
                    popoverActive={diffrencePopover === data._id}
                    activator={
                      <div
                        key={data._id}
                        style={{width: '100%', cursor: 'pointer'}}
                        onMouseEnter={() => {
                          setDiffrencePopover(data._id);
                        }}
                        onMouseLeave={() => {
                          setDiffrencePopover(null);
                        }}
                      >
                        <Text color={object?.diffrence > 0 ? 'critical' : 'success'}>
                          {formatNumber({value: object?.diffrence})}
                        </Text>
                      </div>
                    }
                    text={generateProgressText(object)}
                  />
                </>
              ) : (
                <Icon source={DiamondAlertMajor} color="base" />
              );
            },
            website: (data, col) => {
              return (
                <a href={`https://${data?.store?.url}`} target="_blank">
                  visit
                </a>
              );
            },
            empty: () => {
              return <></>;
            },
          }}
        />
      </Page>
      {Boolean(openConfirmModal) && (
        <ConfirmationModal
          isOpen={Boolean(openConfirmModal)}
          toggle={() => setOpenConfirmModal(null)}
          onProceed={() => {
            openConfirmModal.method(...openConfirmModal.params);
          }}
          text={`Are you sure you want to ${openConfirmModal.label}?`}
          danger={openConfirmModal.danger}
        />
      )}
    </>
  );
};

export default Organizations;
