import React, {useState, useReducer, useContext, useEffect} from 'react';
import * as Sentry from '@sentry/react';
import {
  Text,
  Form,
  FormLayout,
  Link,
  TextField,
  Frame,
  Grid,
  Loading,
  Spinner,
} from '@shopify/polaris';
import Box from '../../../lib/Box';
import Inline from '../../../lib/Inline';
import {AuthAPI} from '../../../api/auth';
import {AuthContext} from '../../../context/auth';
import Alert from '../../../components/alert';
// import {useNavigate} from 'react-router-dom';
import {emailValidator} from '../../../features/validations';
import {useLoadingStore} from '../../../app/store';
import {track} from '../../../features/track';
import Button from '../../../lib/Button';
const initialState = {
  email: '',
  password: '',
};
const reducer = (state, action) => {
  switch (action.type) {
    case 'email':
      return {...state, email: action.value};
    case 'password':
      return {...state, password: action.value};

    default:
      throw new Error();
  }
};
const SignIn = () => {
  const loading = useLoadingStore(state => state.loading);

  const {signin} = useContext(AuthContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [error, setError] = useState({});
  const [alertError, setAlertError] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const setLoading = useLoadingStore(state => state.setLoading);

  const navigate = link => {
    window.location.href = link;
  };

  useEffect(() => {
    track('Page View', {
      name: 'Sign-In',
    });
  }, []);

  const handleSubmit = async () => {
    setAlertError('');
    let flag = true;
    if (!emailValidator(state.email)) {
      error.validEmail = true;
    }
    for (let key in state) {
      if (!state[key] || state[key] === '') {
        flag = false;
        error[key] = true;
        setError({...error});
      } else {
        error[key] = false;

        setError({...error});
      }
    }
    if (flag) {
      setLoading(true);
      try {
        const response = await AuthAPI.signin(state);
        if (response.status) {
          setLoading(false);
          signin(response.data);
        } else {
          setLoading(false);
          setAlertError(response.message);
        }
      } catch (err) {
        setLoading(false);
        setAlertError('Something went wrong');
        Sentry.captureException(err);
      }
    }
  };
  useEffect(() => {
    if (state.email && state.password) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [state]);

  return (
    <Frame>
      <div className="bg1">
        {loading && <Loading />}
        <Grid>
          <Grid.Cell columnSpan={{xs: 6, sm: 6, md: 6, lg: 6, xl: 6}}>
            <Box minHeight="100vh" background="surface">
              <Inline align="space-between" blockAlign="center">
                <Box padding="8">
                  <img alt="#" width={'180px'} src="/images/logo.svg" />
                </Box>
                <Box padding="8" paddingBlockStart={'10'}>
                  <Inline>
                    <Text alignment="end" fontWeight="semibold">
                      <Link
                        url={'/auth/signup'}
                        onClick={event => {
                          event.preventDefault();
                          navigate('https://apps.shopify.com/retainwise');
                        }}
                      >
                        New to retainwise? Sign up
                      </Link>
                    </Text>
                  </Inline>
                </Box>
              </Inline>

              <>
                <Box paddingBlockStart={'12'}>
                  <Text variant="heading3xl" as="h2" alignment="center">
                    Sign in
                  </Text>
                  <Inline align="center">
                    <Box
                      className="form-container"
                      width="50%"
                      paddingBlockStart={'8'}
                      paddingBlockEnd={'4'}
                    >
                      <Form onSubmit={handleSubmit}>
                        <FormLayout>
                          <TextField
                            type="text"
                            placeholder="Email"
                            value={state.email}
                            error={
                              error.email
                                ? 'Email should not be empty'
                                : error.validEmail
                                ? 'Enter a valid email'
                                : error.emailLength
                                ? 'Max length of 150 characters reached'
                                : ''
                            }
                            label="Email"
                            onChange={e => {
                              if (e.length < 150) {
                                dispatch({type: 'email', value: e});
                                error.emailLength = false;
                                setError(error);
                              } else if (e.length === 150) {
                                dispatch({type: 'email', value: e});
                                error.emailLength = true;
                                setError(error);
                              }
                            }}
                            autoComplete="email"
                          />
                          <TextField
                            type="password"
                            placeholder="Password"
                            value={state.password}
                            onChange={e => {
                              if (e.length < 150) {
                                dispatch({type: 'password', value: e});
                                error.passLength = false;
                                setError(error);
                              } else if (e.length === 150) {
                                dispatch({type: 'password', value: e});
                                error.passLength = true;
                                setError(error);
                              }
                            }}
                            error={
                              error.password
                                ? 'Password  should not be empty'
                                : error.passLength
                                ? 'Max length of 150 characters reached'
                                : ''
                            }
                            label="Password"
                          />
                          <Text alignment="end" fontWeight="semibold">
                            <Link
                              url={'/auth/reset/request'}
                              onClick={event => {
                                event.preventDefault();
                                navigate('/auth/reset/request');
                              }}
                            >
                              Forgot Password?
                            </Link>
                          </Text>
                          <Button disabled={buttonDisabled} primary fullWidth submit>
                            {loading ? <Spinner size="small" /> : 'Sign in'}
                          </Button>
                        </FormLayout>
                      </Form>
                    </Box>
                  </Inline>
                  {alertError && <Alert contents={alertError} init={true} error={true} />}
                </Box>
              </>
            </Box>
          </Grid.Cell>
          <Grid.Cell columnSpan={{xs: 5, sm: 2, md: 2, lg: 5, xl: 6}}>
            <Box minHeight="90vh"></Box>
          </Grid.Cell>
        </Grid>
      </div>
    </Frame>
  );
};

export default SignIn;
