import {DataTable, Text} from '@shopify/polaris';
import React from 'react';
import './style.css';
import {SimpleNormalizedChart} from '@shopify/polaris-viz';

const ExportSyncStatus = ({
  totalCustomers,
  customersWithoutContactInfo,
  validCustomers,
  syncedCustomers,
  failedCustomers,
  failedLogs,
}) => {
  const rows = failedLogs.length
    ? failedLogs.map(x => [`${x.firstName} ${x.lastName}`, x.email, x.phone, x.reason])
    : null;

  return (
    <>
      <Text variant="headingSm">Total customers: {totalCustomers}</Text>
      <div className="chart-container">
        <SimpleNormalizedChart
          theme="Light"
          data={[
            {name: 'Valid', data: [{key: '', value: validCustomers}], color: 'lime'},
            {
              name: 'No contact info',
              data: [{key: '', value: customersWithoutContactInfo}],
              color: 'orange',
            },
          ]}
        />
      </div>
      <Text variant="headingSm">Sync status</Text>
      <div className="chart-container">
        <SimpleNormalizedChart
          theme="Light"
          data={[
            {
              name: 'Synced',
              data: [{key: '', value: syncedCustomers}],
              color: 'lime',
            },
            {
              name: 'Pending',
              data: [{key: '', value: validCustomers - (syncedCustomers - failedCustomers)}],
              color: 'orange',
            },
            {
              name: 'Failed',
              data: [{key: '', value: failedCustomers}],
              color: 'red',
            },
          ]}
        />
      </div>
      {failedCustomers > 0 && rows && (
        <div className="failed-customers">
          <Text variant="headingSm">Failed logs</Text>
          <DataTable
            hideScrollIndicator={true}
            increasedTableDensity={true}
            stickyHeader={true}
            fixedFirstColumns={1}
            columnContentTypes={['text', 'text', 'text', 'text']}
            headings={['Name', 'Email', 'Phone', 'Reason']}
            rows={rows}
          />
        </div>
      )}
    </>
  );
};

export default ExportSyncStatus;
