import React, {useState} from 'react';
import DefinationPopover from './definationPopover';
import {SortDescendingMajor, SortAscendingMajor} from '@shopify/polaris-icons';
import {generateInfoText} from '../features/generateText';
import Text from '../lib/Text';
import Icon from '../lib/Icon';
const dateColumnSelector = {
  hourly: 'Hour',
  daily: 'Day',
  weekly: 'Start of week',
  monthly: 'Month',
  quarterly: 'Quarter',
};

const ColumnHeading = ({
  col,
  sortBy,
  sortOrder,
  durationFilter,
  setSortOrder,
  setSortBy,
  _key,
  disableSort,
}) => {
  const [infoPopoverActive, setInfoPopoverActive] = useState(null);
  const handelClick = value => {
    if (disableSort) return;
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    setSortBy(value);
  };
  const infoMarkup = (
    <>
      {col.infoRequired ? (
        <DefinationPopover
          text={
            generateInfoText(col.value) || 'This metric is calculated based on date range selected'
          }
          togglePopoverActive={() => {
            setInfoPopoverActive(null);
          }}
          popoverActive={infoPopoverActive}
          activator={
            <div
              onMouseEnter={() => {
                setInfoPopoverActive(true);
              }}
              onMouseLeave={() => {
                setInfoPopoverActive(null);
              }}
            >
              <div>
                <span style={{textDecoration: 'underline'}}>{col.title}</span>
              </div>
            </div>
          }
        />
      ) : (
        <>{col.title}</>
      )}
    </>
  );

  return col.type === 'amount' ||
    col.type === 'decimal' ||
    col.type === 'number' ||
    col.type === 'percent' ||
    col.type === 'datetime' ? (
    <div
      className="tableHead"
      style={{cursor: disableSort ? '' : 'pointer'}}
      onClick={() => handelClick(col.value)}
      key={_key}
    >
      <Text as="span" alignment="end">
        <div className="innerCell">
          {infoMarkup}{' '}
          {col.value === sortBy ? (
            sortOrder === 'desc' ? (
              <Icon source={SortDescendingMajor} color="base" />
            ) : (
              <Icon source={SortAscendingMajor} color="base" />
            )
          ) : (
            ''
          )}
        </div>
      </Text>
    </div>
  ) : col.value === 'date' ? (
    <Text as="span" alignment="start" key={_key}>
      <div className="innerCell" style={{cursor: 'pointer'}} onClick={() => handelClick(col.value)}>
        {durationFilter ? dateColumnSelector[durationFilter] : 'Date'}{' '}
        {col.value === sortBy ? (
          sortOrder === 'desc' ? (
            <Icon source={SortDescendingMajor} color="base" />
          ) : (
            <Icon source={SortAscendingMajor} color="base" />
          )
        ) : (
          ''
        )}
      </div>
    </Text>
  ) : col.type === 'date' ? (
    <Text as="span" alignment="end" key={_key}>
      <div className="innerCell" style={{cursor: 'pointer'}} onClick={() => handelClick(col.value)}>
        {infoMarkup}{' '}
        {col.value === sortBy ? (
          sortOrder === 'desc' ? (
            <Icon source={SortDescendingMajor} color="base" />
          ) : (
            <Icon source={SortAscendingMajor} color="base" />
          )
        ) : (
          ''
        )}
      </div>
    </Text>
  ) : (
    <Text as="span" alignment="start" key={_key}>
      <div className="innerCell">
        {infoMarkup}{' '}
        {col.value === sortBy ? (
          sortOrder === 'desc' ? (
            <Icon source={SortDescendingMajor} color="base" />
          ) : (
            <Icon source={SortAscendingMajor} color="base" />
          )
        ) : (
          ''
        )}
      </div>{' '}
    </Text>
  );
};

export default ColumnHeading;
