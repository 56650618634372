const {LocalStorageService} = require('../service/localStorage');

const getUserDetails = () => {
  return LocalStorageService.getAccountDetails()?.user?.profile;
};
const verifyInventory = data => {
  if (!data.rateOfSale && data.inventoryQuantity) return false;
  return true;
};
export {getUserDetails, verifyInventory};
