import {Popover, OptionList} from '@shopify/polaris';
import {useState, useCallback} from 'react';
import Button from '../../lib/Button';

const SelectCohortComponent = ({setDurationFilter, durationFilter, setPage, setQueryValue}) => {
  const [popoverActive, setPopoverActive] = useState(false);
  const togglePopoverActive = useCallback(
    () => setPopoverActive(popoverActive => !popoverActive),
    []
  );
  const buttonLabelSelector = {
    weekly: 'Weekly',
    monthly: 'Monthly',
    quarterly: 'Quarterly',
  };
  const activator = (
    <Button onClick={togglePopoverActive} disclosure>
      {buttonLabelSelector[durationFilter]}
    </Button>
  );
  return (
    <Popover active={popoverActive} activator={activator} onClose={togglePopoverActive}>
      <OptionList
        selected={durationFilter}
        options={[
          {label: 'Weekly', value: 'weekly', active: false},
          {label: 'Monthly', value: 'monthly', active: false},
          {label: 'Quarterly', value: 'quarterly', active: false},
        ]}
        onChange={value => {
          setDurationFilter(value[0]);
          if (setPage) {
            setPage(1);
            setQueryValue(null);
          }

          togglePopoverActive();
        }}
      />
    </Popover>
  );
};
export default SelectCohortComponent;
