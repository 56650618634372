import React from 'react';
import {Route} from 'react-router-dom';
import PrivateRoute from '../private/PrivateRoute';
import {routeMap} from './routeMap';
import {FrameWrapper} from './frameWrapper';
import {Footer} from '../../components/footer';
import {AxiosInterceptor} from '../../api/axios';

const routeIds = Object.keys(routeMap);
const DashboardRoutes = routeIds.map((id, i) => (
  <Route
    key={i}
    path={routeMap[id].path}
    element={
      <PrivateRoute id={id} route={routeMap[id]}>
        <AxiosInterceptor>
          <FrameWrapper id={id} />
          {!routeMap[id].removeFooter && <Footer />}
        </AxiosInterceptor>
      </PrivateRoute>
    }
  />
));

export default DashboardRoutes;
