import {Icon, Navigation} from '@shopify/polaris';
import Box from '../../lib/Box';
import React, {useState} from 'react';
import {
  JobsMajor,
  OrganizationMajor,
  DomainsMajor,
  DataVisualizationMajor,
  AnalyticsMajor,
} from '@shopify/polaris-icons';
import {useLocation, useNavigate, Link} from 'react-router-dom';
import {usePreventNavigateStore} from '../../app/store';
import {LeavePageModal} from '../modal';

function AdminNavigation() {
  const navigate = useNavigate();
  const location = useLocation();
  const protect = usePreventNavigateStore(state => state.protect);
  const [openLeavePageModal, setOpenLeavePageModal] = useState(false);
  const [toNavigate, setToNavigate] = useState('');

  const navigationMenu = [
    {
      title: 'Organizations',
      path: '/admin/organizations',
      condition: location.pathname.includes('/admin/organizations'),
      icon: OrganizationMajor,
    },
    {
      title: 'Jobs',
      path: '/admin/jobs',
      condition: location.pathname.includes('/admin/jobs'),
      icon: JobsMajor,
    },
    {
      title: 'Webhook logs',
      path: '/admin/webhook-logs',
      condition: location.pathname === '/admin/webhook-logs',
      icon: DomainsMajor,
    },
    {
      title: 'Async jobs',
      path: '/admin/async-jobs',
      condition: location.pathname === '/admin/async-jobs',
      icon: DataVisualizationMajor,
    },
    {
      title: 'Summary logs',
      path: '/admin/summary-logs',
      condition: location.pathname === '/admin/summary-logs',
      icon: AnalyticsMajor,
    },
  ];
  return (
    <>
      <Navigation location={location.pathname}>
        {navigationMenu.map((item, i) => (
          <Link
            key={i}
            as="div"
            to={item.path}
            className={item.condition ? 'navigationMenuOnClicked' : 'navigationMenu'}
            onClick={event => {
              if (protect) {
                setOpenLeavePageModal(true);
                event.preventDefault();
                setToNavigate(item.path);
              } else {
                event.preventDefault();
                navigate(item.path);
              }
            }}
          >
            <Box as="div" className="navigationMenuImage">
              <Icon source={item.icon} color={item.condition ? 'primary' : 'base'} />
            </Box>
            <Box as="div" className="navigationMenuText">
              {item.title}
            </Box>
          </Link>
        ))}
      </Navigation>
      {openLeavePageModal && (
        <LeavePageModal
          currentState={openLeavePageModal}
          toNavigate={toNavigate}
          onClose={setOpenLeavePageModal}
        />
      )}
    </>
  );
}

export {AdminNavigation};
