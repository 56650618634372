import axios from '../axios';
import config from '../../config';
const {BASE_URL} = config;
/**Api to get day wise  expected to present in database diffrence counts
 * @param  {ObjectId} orgId : organization identifier
 * @param  {Date} startDate : Start date
 * @param  {Date} endDate : End Date
 * @param {Date} sinceDate : date till list should be skipped
 * @param {String} diffType: type of diffrence we want
 * @param {String} type: type of data we want diffrence
 * @return {Date} startDate : start date of stats showing
 * @return {Date} endDate : end date of stats showing
 * @returns {Number} expectedCount: expected count should be in that date range
 * @return {Number} presentCount: present count in database in that date range
 * @returns {Number} diffrence: diffrence in present data and expected data in that date range
 */
const getOrgsDiffrenceCounts = async ({orgId, startDate, endDate, sinceDate, diffType, type}) => {
  try {
    const response = await axios.get(`${BASE_URL}/admin/organizations/${orgId}/diffrence-counts`, {
      params: {startDate, endDate, sinceDate, diffType, type},
    });
    return response.data;
  } catch (err) {
    return err;
  }
};
/**Api to get diffrence list that are should be present in database but not present
 * @param  {ObjectId} orgId : organization identifier
 * @param  {Date} startDate : Start date
 * @param  {Date} endDate : End Date
 * @param {Number} sinceId : id till list should be skipped
 * @param {String} diffType: type of diffrence we want
 * @param {String} type: type of data we want diffrence
 * @return {String} id: data id
 * @return {Date} created_at : date of data created
 */
const getOrgsDiffrence = async ({orgId, startDate, endDate, sinceId, diffType, type}) => {
  try {
    const response = await axios.get(`${BASE_URL}/admin/organizations/${orgId}/diffrence`, {
      params: {startDate, endDate, sinceId, diffType, type},
    });
    return response.data;
  } catch (err) {
    return err;
  }
};

export {getOrgsDiffrence, getOrgsDiffrenceCounts};
