import {Text} from '@shopify/polaris';
import {BarChart} from '@shopify/polaris-viz';
import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import config from '../../config';
import apiToCall from '../../features/apiToCall';

const DefaultBarGraph = ({
  data,
  height,
  xAxisValue,
  yAxisValue,
  name,
  param,
  state,
  yAxisOptions,
  xAxisOptions = {},
  tooltipOptions = {},
}) => {
  return (
    <>
      {state === 'Success' && !data.length ? (
        <Text>No data found</Text>
      ) : (
        <div
          style={{
            height: height ? height : 400,
          }}
        >
          <BarChart
            theme="Light"
            state={state ? state : 'Loading'}
            data={[
              {
                data: data.length
                  ? data.map(obj => {
                      return {
                        key: obj[xAxisValue],
                        value: obj[yAxisValue],
                      };
                    })
                  : [],
                name: name,
                color: config.GRAPH_COLOR,
              },
            ]}
            yAxisOptions={
              (param && apiToCall[param.name].yAxisOptions) ||
              yAxisOptions || {
                labelFormatter: function formatLinearYAxisLabel(value) {
                  return new Intl.NumberFormat().format(value);
                },
              }
            }
            tooltipOptions={(param && apiToCall[param.name].tooltipOptions) || {...tooltipOptions}}
            xAxisOptions={(param && apiToCall[param.name].xAxisOptions) || xAxisOptions}
          />
        </div>
      )}
    </>
  );
};

export default DefaultBarGraph;
