import axios from '../../axios';
import config from '../../../config';

const {BASE_URL} = config;

export async function saveReportsSetting(data) {
  try {
    const response = await axios.post(`${BASE_URL}/reports/set-settings`, data);
    return response;
  } catch (err) {
    return err.response.data;
  }
}
export async function getReportsSetting(params) {
  try {
    const response = await axios.get(`${BASE_URL}/reports/get-settings`, params);
    return response;
  } catch (err) {
    return err.response.data;
  }
}
export async function clearExcludedSettings(data) {
  try {
    const response = await axios.post(`${BASE_URL}/reports/clear-excluded-data`, data);
    return response;
  } catch (err) {
    return err.response.data;
  }
}
export async function getNpsSetting() {
  try {
    const response = await axios.get(`${BASE_URL}/reports/nps-setting`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function setProductNpsSetting(data) {
  try {
    const response = await axios.post(`${BASE_URL}/reports/nps-setting`, data);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function getNpsTemplate(params) {
  try {
    const response = await axios.get(`${BASE_URL}/internal/nps/template`, {params});
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}

export async function updateNpsTemplate(data) {
  try {
    const response = await axios.put(`${BASE_URL}/internal/nps/template`, data);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}

export async function sendTestEmailTemplate(data) {
  try {
    const response = await axios.post(`${BASE_URL}/internal/nps/email-template`, data);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function sendTestWhatsappMessage(data) {
  try {
    const response = await axios.post(`${BASE_URL}/internal/nps/whatsapp-template`, data);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}

export async function getDefaultNpsTemplate(params) {
  try {
    const response = await axios.get(`${BASE_URL}/internal/nps/default-template`, {params});
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
