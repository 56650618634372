import {Collapsible} from '@shopify/polaris';
import {useState, useCallback} from 'react';
import Button from '../lib/Button';
import Stack from '../lib/Stack';
import TextContainer from '../lib/TextContainer';
const Collapse = ({isOpen, toggle, text, buttonText = 'Toggle'}) => {
  return (
    <Stack vertical>
      <Button
        plain
        disclosure={isOpen ? 'up' : 'down'}
        onClick={toggle}
        ariaExpanded={isOpen}
        ariaControls="basic-collapsible"
      >
        {buttonText}
      </Button>
      <Collapsible
        open={isOpen}
        id="basic-collapsible"
        transition={{duration: '500ms', timingFunction: 'ease-in-out'}}
        expandOnPrint
      >
        <TextContainer>
          <p>{text}</p>
        </TextContainer>
      </Collapsible>
    </Stack>
  );
};

export default Collapse;
