import {Frame} from '@shopify/polaris';
import React, {useCallback, useEffect, useState} from 'react';
import Dashboard from './dashboard';

import MyNavigation from '../../components/navigation';
import {useSaveChangesStore} from '../../app/store';
import SaveBar from '../../components/saveBar';
import DefaultTopBar from '../../components/topBar';
import {track} from '../../features/track';
import CustomHeader from '../../components/customHeader';
const Metrics = () => {
  const isUnchanged = useSaveChangesStore(state => state.isUnchanged);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const toggleMobileNavigationActive = useCallback(
    () => setMobileNavigationActive(mobileNavigationActive => !mobileNavigationActive),
    []
  );
  useEffect(() => {
    track('Page View', {
      name: 'Dashboard',
    });
  }, []);

  return (
    <Frame
      logo={{
        width: 124,
        contextualSaveBarSource: '/images/logo.svg',
        topBarSource: '/images/logo.svg',
      }}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      navigation={<MyNavigation />}
      topBar={
        isUnchanged ? (
          <SaveBar />
        ) : (
          <DefaultTopBar
            mobileNavigationActive={mobileNavigationActive}
            setMobileNavigationActive={setMobileNavigationActive}
          />
        )
      }
    >
      <CustomHeader />
      <Dashboard />
    </Frame>
  );
};

export default Metrics;
