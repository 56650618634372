import {TextField, Button, Modal, TextContainer} from '@shopify/polaris';
import {useState} from 'react';
import Inline from '../../lib/Inline';
import Box from '../../lib/Box';

const SendWhatsappMessageModal = ({isOpen, handleProceed, toggle}) => {
  const [loading, setLoading] = useState(false);
  const [mobileNumber, setMobileNumber] = useState();
  const [countryCode, setCountryCode] = useState('+1');

  const activator = <div></div>;

  return (
    <>
      <Modal activator={activator} open={isOpen} onClose={toggle} title="Send whatsapp message">
        <Modal.Section>
          <TextContainer>
            <TextField
              connectedLeft={
                <Box maxWidth="5rem">
                  <TextField maxLength={4} onChange={setCountryCode} value={countryCode} />
                </Box>
              }
              value={mobileNumber}
              onChange={setMobileNumber}
              label="Whatsapp number"
            />
            <Inline align="end">
              <Button
                disabled={loading}
                onClick={async () => {
                  setLoading(true);
                  await handleProceed(countryCode.toString() + mobileNumber.toString());
                  setLoading(false);
                  toggle();
                }}
                primary
              >
                Send
              </Button>
            </Inline>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </>
  );
};

export default SendWhatsappMessageModal;
