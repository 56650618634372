import React, {useState, useCallback, useMemo, useEffect} from 'react';
import {
  Popover,
  OptionList,
  Button,
  Box,
  Inline,
  Scrollable,
  TextField,
  Icon,
} from '@shopify/polaris';

const MultiSelectSearch = ({
  title,
  options,
  selected,
  setSelected,
  disableMultiple,
  setChecked,
  setPage = () => {},
}) => {
  const togglePopoverActive = useCallback(
    () => setPopoverActive(popoverActive => !popoverActive),
    []
  );
  const [allSelected, setAllSelected] = useState(false);
  const [filterOptions, setFilterOptions] = useState(options);
  const [picked, setPicked] = useState([]);
  const [query, setQuery] = useState('');
  const valueToLabelMap = useMemo(() => {
    let _map = {};
    for (let option of filterOptions) {
      _map[option.value] = option.label;
    }
    return _map;
  }, [filterOptions]);

  const label = useMemo(() => {
    if (Boolean(picked) && disableMultiple) {
      return valueToLabelMap[picked];
    }
    return title;
  }, [picked, valueToLabelMap]);
  const orgActivator = (
    <>
      <Button onClick={togglePopoverActive} disclosure>
        {label}
      </Button>
    </>
  );

  const handleApply = () => {
    setSelected(picked);
  };

  const handleClear = () => {
    setPicked([]);
    setSelected([]);
  };

  const handleClearText = () => {
    setQuery('');
  };

  const filterArrayByText = (inputText, array) => {
    const regexPattern = new RegExp(inputText, 'i'); // 'i' flag for case-insensitive matching
    return array.filter(item => regexPattern.test(item.value));
  };

  useEffect(() => {
    setFilterOptions(filterArrayByText(query, options));
  }, [query]);

  const handleTextFieldChange = useCallback(value => setQuery(value), []);

  const [popoverActive, setPopoverActive] = useState(false);
  return (
    <>
      <Popover
        active={popoverActive}
        activator={orgActivator}
        onClose={togglePopoverActive}
        fluidContent
      >
        <Popover.Pane fixed>
          <div
            style={{
              margin: '5px',
            }}
          >
            <TextField
              clearButton
              labelHidden
              placeholder="Enter product group"
              autoComplete="off"
              value={query}
              onChange={handleTextFieldChange}
              onClearButtonClick={handleClearText}
              // suffix={
              //   query ? (
              //     <div style={{cursor: 'pointer'}} onClick={handleAddClick}>
              //       <Icon source={PlusMinor} />
              //     </div>
              //   ) : (
              //     ''
              //   )
              // }
            />
          </div>
        </Popover.Pane>
        <Popover.Pane>
          <Scrollable>
            <OptionList
              onChange={e => {
                if (e.includes('all') && !allSelected) {
                  let s = filterOptions.map(d => d.value);
                  setPicked([...s]);
                  setAllSelected(true);
                } else if (e.includes('all') && allSelected && e.length !== filterOptions.length) {
                  let i = e.indexOf('all');
                  e.splice(i, 1);
                  setPicked([...e]);
                  setAllSelected(false);
                } else if (!e.includes('all') && allSelected) {
                  setPicked([...[]]);
                  setAllSelected(false);
                } else {
                  if (disableMultiple) {
                    togglePopoverActive();
                    setPicked(e[0]);
                    setChecked(false);
                  } else {
                    setPicked(e);
                  }
                }
                setPage(1);
              }}
              options={filterOptions}
              selected={disableMultiple ? [picked] : picked}
              allowMultiple={!disableMultiple}
            />
          </Scrollable>
        </Popover.Pane>
        <Popover.Pane fixed>
          <Box padding={'3'}>
            <Inline align="end">
              <Button onClick={handleClear}>Clear all</Button>
              <Button onClick={handleApply} primary>
                Apply
              </Button>
            </Inline>
          </Box>
        </Popover.Pane>
      </Popover>
    </>
  );
};

export default MultiSelectSearch;
