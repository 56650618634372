import React, {useEffect, useState} from 'react';
import {Columns} from '@shopify/polaris';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import Box from '../../../../lib/Box';

import DashboardCard from './draggableCard';
import {
  usePreventNavigateStore,
  useSaveChangesStore,
  useSnapshotStore,
} from '../../../../app/store';
import useUnsavedChangesWarning from '../../../../hooks/useUnsavedChangesWarning';
import {updateSnapshot} from '../../../../api/snapshot';
import {ErrorBoundary} from '../../../../components/ErrorBoundary';
import Stack from '../../../../lib/Stack';

const DraggableDashboard = ({
  setCustomizeMode,
  columnsFromBackend,
  selectedDates,
  selectedCompareDates,
  durationFilter,
  isUpdated,
}) => {
  const [columns, setColumns] = useState(columnsFromBackend);
  const isUnchanged = useSaveChangesStore(state => state.setIsUnchanged);
  const setOnSubmit = useSaveChangesStore(state => state.setOnSubmit);
  const setOnDiscard = useSaveChangesStore(state => state.setOnDiscard);
  const setProtect = usePreventNavigateStore(state => state.setProtect);
  // eslint-disable-next-line no-unused-vars
  const [prompt, setDirty, setPristine] = useUnsavedChangesWarning();
  const widgetData = useSnapshotStore(state => state.widgetData);

  const setButtonEnabled = useSaveChangesStore(state => state.setButtonEnabled);

  const onCustomized = () => {
    updateSnapshot({reports: columns}).then(res => {
      isUpdated(res.status);
    });
    isUnchanged(false);
    setProtect(false);
    setCustomizeMode(false);
  };
  const onDiscard = () => {
    setCustomizeMode(false);
  };

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const {source, destination} = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn];
      const destItems = [...destColumn];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: [...sourceItems],
        [destination.droppableId]: [...destItems],
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: [...copiedItems],
      });
    }
    setButtonEnabled(true);
  };

  useEffect(() => {
    setDirty();
    isUnchanged(true);
    setProtect(true);
    setOnDiscard(onDiscard);
    setOnSubmit(onCustomized);
  }, [columns]);

  return (
    <DragDropContext onDragEnd={result => onDragEnd(result, columns, setColumns)}>
      <Box paddingBlockEnd={'4'}>
        <Columns columns={{xs: 1, sm: 1, md: 1, lg: 2, xl: 3}}>
          {Object.entries(columns).map(([columnId, column], index) => {
            return (
              <Droppable key={columnId} droppableId={columnId}>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <Box>
                      <Stack vertical>
                        <div>
                          {column.map((item, index) => (
                            <ErrorBoundary
                              fallback={
                                <DashboardCard
                                  isGraph={true}
                                  key={item}
                                  item={item}
                                  index={index}
                                  slag={item.slag}
                                  selectedDates={selectedDates}
                                  selectedCompareDates={selectedCompareDates}
                                  durationFilter={durationFilter}
                                  columnId={columnId}
                                  setColumns={setColumns}
                                  columns={columns}
                                  setButtonEnabled={setButtonEnabled}
                                  data={{data: [], state: 'Error'}}
                                  error={true}
                                />
                              }
                            >
                              <DashboardCard
                                isGraph={true}
                                key={item}
                                item={item}
                                index={index}
                                slag={item.slag}
                                selectedDates={selectedDates}
                                selectedCompareDates={selectedCompareDates}
                                durationFilter={durationFilter}
                                columnId={columnId}
                                setColumns={setColumns}
                                columns={columns}
                                setButtonEnabled={setButtonEnabled}
                                data={widgetData[item.slag] ? widgetData[item.slag] : {data: []}}
                              />
                            </ErrorBoundary>
                          ))}
                        </div>
                      </Stack>
                      {provided.placeholder}
                    </Box>
                  </div>
                )}
              </Droppable>
            );
          })}
        </Columns>
      </Box>
    </DragDropContext>
  );
};

export default DraggableDashboard;
