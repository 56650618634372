import React from 'react';
import {ProgressBar, Checkbox} from '@shopify/polaris';
import Box from '../../../lib/Box';
import Card from '../../../lib/Card';
import Inline from '../../../lib/Inline';
import {useState, useCallback, useEffect} from 'react';
import {AdminApi} from '../../../api/admin';
import Page from '../../../components/page';
import MultiSelect from '../../../components/multiSelect';
// import {formatDate} from '../../../features/format';
// import {track} from '../../../features/track';
import DefaultTable from '../../../components/defaultIndexTable';
import {ErrorBoundary} from '../../../components/ErrorBoundary';
import {adminJobsColumns} from '../../metrics/columns';
import {
  thirtyDaysBefore,
  todayDate,
  yesterDayDate,
  ninetyDaysBefore,
  sevenDaysBefore,
  dateToNormalString,
  toClickHouseDate,
  oneYearBefore,
} from '../../../features/convertDates';
import DateRangeComponent from '../../../components/dateRangeComponent';
import {formatDate, formatDecimalNumber, formatNumber, toTitleCase} from '../../../features/format';
import JobErrorsModal from '../../../components/Modals/JobErrorsModal';
import DefinationPopover from '../../../components/definationPopover';
import Button from '../../../lib/Button';

const dateRangeObj = {
  yesterday: yesterDayDate,
  today: todayDate,
  lastThirtyDays: thirtyDaysBefore,
  lastSevenDays: sevenDaysBefore,
  lastNinetyDays: ninetyDaysBefore,
  lastOneYear: oneYearBefore,
};
const dateRangeValueToLable = {
  today: 'Today',
  yesterday: 'Yesterday',
  lastSevenDays: 'Last 7 Days',
  lastThirtyDays: 'Last 30 Days',
  lastNinetyDays: 'Last 90 Days',
  lastOneYear: 'Last 1 Year',
};

const calculateProgressPercentage = data => {
  let totalCount =
    (data.totalCount?.customer || 0) +
    (data.totalCount?.product || 0) +
    (data.totalCount?.order || 0);
  let completedCount =
    (data.completedCount?.customer || 0) +
    (data.completedCount?.product || 0) +
    (data.completedCount?.order || 0);
  if (completedCount === totalCount) return 100;
  return (completedCount / totalCount) * 100;
};
const generateProgressText = data => {
  const required = ['product', 'customer', 'order'];
  return (
    <table style={{borderSpacing: '10px 5px', borderCollapse: 'separate'}}>
      <thead>
        <tr>
          <th>Type</th>
          <th>Completed count</th>
          <th>Total count</th>
        </tr>
      </thead>
      <tbody>
        {required.map(type => {
          return (
            <tr key={type}>
              <td>{toTitleCase(type)}</td>
              <td>{formatNumber({value: data.completedCount[type] || 0})}</td>
              <td>{formatNumber({value: data.totalCount[type] || 0})}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
const Jobs = () => {
  const [errorModalData, setErrorModalData] = useState(null);
  const [orgs, setOrgs] = useState('');
  const [dateRange, setDateRange] = useState('lastSevenDays');
  const [popoverActive, setPopoverActive] = useState(false);
  const [jobs, setJobs] = useState({data: [], state: 'Loading', totalRows: 0});
  const [orgsOptions, setOrgsOptions] = useState([]);
  const [sortBy, setSortBy] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('desc');
  const [queryValue, setQueryValue] = useState('');
  const [status, setStatus] = useState('');
  const [type, setType] = useState('');
  const [page, setPage] = useState(1);
  const [organizationIdToNameMap, setOrganizationIdToNameMap] = useState(null);
  const [hasErrors, setHasErrors] = useState(false);
  const [progressPopoverActive, setProgressPopoverActive] = useState(null);

  const handleProgressPopover = id => {
    setProgressPopoverActive(id);
  };

  const [selectedDates, setSelectedDates] = useState(
    dateRange
      ? {
          start: new Date(dateRangeObj[dateRange]()[0]),
          end: new Date(dateRangeObj[dateRange]()[1]),
        }
      : {
          start: new Date(),
          end: new Date(),
        }
  );
  const getDates = (dates, dateOption) => {
    setSelectedDates(dates);
    setDateRange(dateOption);
  };

  const fetchJobs = async () => {
    try {
      setJobs({data: [], state: 'Loading', totalRows: null});
      let sdate = toClickHouseDate(selectedDates.start, 'start');
      let edate = toClickHouseDate(selectedDates.end, 'end');
      let res = await AdminApi.getJobs({
        orgId: orgs,
        sortBy,
        sortOrder,
        sdate,
        edate,
        page,
        status,
        type,
        search: queryValue,
        hasErrors,
      });
      if (res.status) {
        res.data.jobs.forEach(job => {
          job.organizationName = organizationIdToNameMap[job.orgId] || 'Not set';
        });
        setJobs({data: res.data.jobs, state: 'Success', totalRows: res.data.totalRows});
      } else {
        setJobs({data: [], state: 'Error', totalRows: 0});
      }
    } catch (err) {
      setJobs({data: [], state: 'Error', totalRows: 0});
    }
  };

  const fetchOrganizations = async () => {
    try {
      let res = await AdminApi.getOrgs();
      let arr = [{label: 'All', value: ''}];
      let map = {};
      res.data.forEach(r => {
        arr.push({label: r.name, value: r._id});
        map[r._id] = r.name;
      });
      setOrganizationIdToNameMap({...map});
      setOrgsOptions(arr);
    } catch (err) {
      setOrgsOptions([]);
    }
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  useEffect(() => {
    if (Boolean(organizationIdToNameMap)) {
      fetchJobs();
    }
  }, [
    orgs,
    selectedDates,
    dateRange,
    organizationIdToNameMap,
    status,
    type,
    sortBy,
    sortOrder,
    queryValue,
    hasErrors,
    page,
  ]);

  const togglePopoverActive = useCallback(
    () => setPopoverActive(popoverActive => !popoverActive),
    []
  );
  const activator = (
    <div style={{width: '100%'}}>
      <Button onClick={togglePopoverActive} disclosure>
        {dateRange ? dateRangeValueToLable[dateRange] : dateToNormalString(selectedDates)}
      </Button>
    </div>
  );

  return (
    <>
      {/* {!!loading && <Loading />} */}
      <Page title="Jobs">
        <ErrorBoundary
          fallback={
            <Card>
              <Card.Section>
                <div>An error has occured</div>
              </Card.Section>
            </Card>
          }
        >
          {!!jobs.data && (
            <DefaultTable
              isSearchEnabled
              queryPlaceholder="search by jobId"
              filters={[
                <MultiSelect
                  title="Organization"
                  options={orgsOptions}
                  selected={orgs}
                  setSelected={setOrgs}
                  disableMultiple={true}
                  setPage={setPage}
                />,
                <MultiSelect
                  title="Job Type"
                  options={[
                    {label: 'All', value: ''},
                    {label: 'Daily', value: 'daily'},
                    {label: 'Historic', value: 'historic'},
                    {label: 'Webhooks', value: 'webhooks'},
                  ]}
                  selected={type}
                  setSelected={setType}
                  disableMultiple={true}
                  setPage={setPage}
                />,
                <MultiSelect
                  title="Job Status"
                  options={[
                    {label: 'All', value: ''},
                    {label: 'Completed', value: 'completed'},
                    {label: 'Failed', value: 'failed'},
                    {label: 'Running', value: 'running'},
                    {label: 'Paused', value: 'paused'},
                    {label: 'Pending', value: 'pending'},
                  ]}
                  selected={status}
                  setSelected={setStatus}
                  disableMultiple={true}
                  setPage={setPage}
                />,

                <Box>
                  <DateRangeComponent
                    currentDates={selectedDates}
                    getDates={getDates}
                    activator={activator}
                    popoverActive={popoverActive}
                    togglePopoverActive={togglePopoverActive}
                    currentDateOption={dateRange}
                  />
                </Box>,
                <Checkbox
                  label="Has errors only"
                  checked={hasErrors}
                  onChange={() => {
                    setHasErrors(!hasErrors);
                  }}
                />,
              ]}
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortExceptions={[]}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              select={false}
              queryValue={queryValue}
              setQueryValue={setQueryValue}
              page={page}
              setPage={setPage}
              resourceName={{
                singular: 'job',
                plural: 'jobs',
              }}
              columns={adminJobsColumns}
              selectedFilters={[]}
              durationFilter={'daily'}
              totalRows={jobs.data.length ? jobs.totalRows : 0}
              tableData={jobs.data}
              param={{}}
              valueTypes={{
                showErrors: (data, col) => {
                  return (
                    <Button
                      key={data._id}
                      plain
                      onClick={() => {
                        setErrorModalData(data);
                      }}
                    >
                      Show Errors
                    </Button>
                  );
                },
                date: (data, col) => {
                  return (
                    <Inline align="end">
                      {formatDate({
                        value: new Date(data[col.value]),
                        removeTime: false,
                        seconds: true,
                      })}
                    </Inline>
                  );
                },
                progress: (data, col) => {
                  return (
                    <Inline align="end">
                      <div style={{width: '100%'}}>
                        <DefinationPopover
                          togglePopoverActive={true}
                          popoverActive={progressPopoverActive === data._id}
                          activator={
                            <div
                              key={data._id}
                              style={{width: '100%', cursor: 'pointer'}}
                              onMouseEnter={() => {
                                handleProgressPopover(data._id);
                              }}
                              onMouseLeave={() => {
                                handleProgressPopover(null);
                              }}
                            >
                              <ProgressBar
                                progress={calculateProgressPercentage(data)}
                                color="success"
                                size="small"
                              />
                              <span>
                                {formatDecimalNumber({value: calculateProgressPercentage(data)})} %
                              </span>
                            </div>
                          }
                          text={generateProgressText(data)}
                        />
                      </div>
                    </Inline>
                  );
                },
              }}
              state={jobs.state}
            />
          )}
        </ErrorBoundary>
        {Boolean(errorModalData) && (
          <JobErrorsModal
            isOpen={Boolean(errorModalData)}
            toggle={() => setErrorModalData(null)}
            data={errorModalData}
          />
        )}
      </Page>
    </>
  );
};

export {Jobs};
