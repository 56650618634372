import {Text, TextContainer} from '@shopify/polaris';

const typeToHeadingText = {
  lifeTime: 'This metric is calculated on lifetime data from start till now',
  lastThirtyDays: 'This metric based on past 30 days sale',
};

const infoTextMap = {
  'Repeat/Loyal customers': {text: 'High frequency, regular buyers', heading: 'High frequency'},
  Royals: {
    text: 'Bought recently, keep buying and have high AOVs',
    heading: 'High recency, frequency and monetary value',
  },
  Loyals: {text: 'High frequency, regular buyers', heading: 'High frequency'},
  Aspirants: {text: 'Frequent buyers, but low AOV', heading: 'High frequency, low monetary value'},
  'In-Betweeners': {
    text: 'Medium on recency and frequency - high scope to increase LTV',
    heading: 'High-medium recency, Low-medium frequency',
  },
  'In-betweeners': {
    text: 'Medium on recency and frequency - high scope to increase LTV',
    heading: 'High-medium recency, Low-medium frequency',
  },
  'Blue-Moons': {text: 'Buy less frequently, but have high AOVs', heading: ''},
  Newcomers: {text: 'New-to-brand customers', heading: 'High recency'},
  'At-Risk': {
    text: 'Not made any recent purchases, might exit completely if not contacted',
    heading: 'Low recency',
  },
  'Window-Shoppers': {
    text: 'Customer accounts created on Shopify but no orders yet',
    heading: 'Customers who have not purchased yet',
  },
  rateOfSale: {
    text: typeToHeadingText['lastThirtyDays'],
  },
  revenuePerDay: {
    text: typeToHeadingText['lastThirtyDays'],
  },
  weeksOfInventory: {
    text: 'Inventory available/Rate of sale',
  },
  rfmCategory: {text: typeToHeadingText['lifeTime']},
  inventoryQuantity: {text: typeToHeadingText['lifeTime']},
  lastOrderDate: {text: typeToHeadingText['lifeTime']},
  lastSellingPrice: {text: typeToHeadingText['lifeTime']},
  averageSellingPrice: {text: typeToHeadingText['lifeTime']},
  firstOrderDate: {text: typeToHeadingText['lifeTime']},
  averageDaysBetweenOrders: {text: typeToHeadingText['lifeTime']},
  firstPurchaseValue: {text: typeToHeadingText['lifeTime']},
  countOfCouponCodesRedeemed: {text: typeToHeadingText['lifeTime']},
  LTV: {text: typeToHeadingText['lifeTime']},
  '12MonthsLTV': {text: 'This is based on last 12-months data'},
  AOV: {text: typeToHeadingText['lifeTime']},
  conversionRate: {
    text: 'This metric is calculated based on date range  or available web pixel data',
  },
};

const generateInfoText = key => {
  return infoTextMap[key] ? (
    <TextContainer spacing="tight">
      <Text variant="headingSm">{infoTextMap[key].heading || ''}</Text>
      <Text>{infoTextMap[key].text}</Text>
    </TextContainer>
  ) : null;
};

const generateSKUText = data => {
  let _data = {...data};
  if (_data.sku === 'Not set') {
    _data.sku = null;
  }
  if (_data.skuTitle === 'Not set') {
    _data.skuTitle = null;
  }
  return _data.sku || _data.skuTitle || 'Not set';
};

const generateVariantTitle = data => {
  if (!data || !data.title || !data.variantTitle) return '';
  return `${data.title} - ${data.variantTitle}`;
};

export {generateInfoText, generateSKUText, generateVariantTitle};
