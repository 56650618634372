import {Popover, ActionList, Spinner} from '@shopify/polaris';
import {useState, useCallback, useMemo} from 'react';
import Button from '../lib/Button';

const DefaultActionList = ({items}) => {
  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => setActive(active => !active), []);

  const activator = (
    <Button onClick={toggleActive} disclosure>
      Actions
    </Button>
  );

  const _items = useMemo(() => {
    return items.map(item => {
      item.content = item.loading ? <Spinner size="small" /> : item.content;
      return item;
    });
  }, [items]);

  return (
    <Popover
      active={active}
      activator={activator}
      autofocusTarget="first-node"
      onClose={toggleActive}
      preventCloseOnChildOverlayClick
    >
      <ActionList actionRole="menuitem" items={_items} />
    </Popover>
  );
};

export default DefaultActionList;
