import React, {useEffect, useState} from 'react';
import './ratingStyle.css';

const ScoreButton = ({i, setReview, setScore}) => {
  const [toggle, setToggle] = useState(false);

  const handleClick = i => {
    setScore(i);
    setReview({rating: i, review: []});
    setToggle(!toggle);
  };
  return (
    <button
      style={toggle ? {transform: 'scale(1.2)', boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.07)'} : {}}
      onClick={() => handleClick(i + 1)}
    >
      {i + 1}
    </button>
  );
};

const ReasonButton = ({reason, review, setReview}) => {
  const [toggle, setToggle] = useState(false);
  useEffect(() => {
    let reasons = review.review;
    if (toggle) {
      reasons.push(reason);
    } else {
      reasons = reasons.filter(x => x !== reason);
    }
    setReview({...review, review: reasons});
  }, [toggle]);
  const handleClick = () => {
    setToggle(!toggle);
  };
  return (
    <button
      onClick={() => handleClick()}
      style={toggle ? {backgroundColor: '#007bff', color: 'white'} : {}}
    >
      {reason}
    </button>
  );
};

const ProductRating = ({setFeedback, productData, feedback}) => {
  const [score, setScore] = useState(0);
  const [review, setReview] = useState({rating: 0, review: []});
  useEffect(() => {
    setFeedback({...feedback, [productData.lineItemId]: review});
  }, [review]);

  const reasons = [
    'Very disappointing experience',
    'Would not recommend it at all',
    'Terrible product/service',
    'Waste of time and money',
    'Product quality can improve',
    'It does the job',
    'Neither amazing nor disappointing',
    'Just met my expectations',
    'Amazing! Highly recommended',
    'Great product/service',
    'Excellent experience',
    'Worth every penny',
  ];
  return (
    <div className="product-section" id="product1" style={{textAlign: 'center'}}>
      <center>
        <img src={productData.imageSrc} alt="Product Name" className="product-image" />
        <div className="product-info">
          <h3>{productData.title}</h3>
          <p>
            Order: {productData.orderName} - Purchased on: {productData.orderedAt}{' '}
          </p>
        </div>
      </center>

      <div id="nps-score-buttons">
        {Array.from({length: 10}).map((x, i) => (
          <ScoreButton key={i} i={i} setReview={setReview} setScore={setScore} />
        ))}
      </div>

      <div id="follow-up-question">
        {score > 0 && <h3 className="prompt">Please tell us the reason for your score.</h3>}

        {score >= 1 && score <= 5 && (
          <div id="reasons-1-5" className="reasons">
            {reasons.slice(0, 4).map((reason, i) => (
              <ReasonButton key={i} reason={reason} setReview={setReview} review={review} />
            ))}
          </div>
        )}
        {score >= 6 && score <= 7 && (
          <div id="reasons-6-7" className="reasons">
            {reasons.slice(4, 8).map((reason, i) => (
              <ReasonButton key={i} reason={reason} setReview={setReview} review={review} />
            ))}
          </div>
        )}
        {score >= 8 && (
          <div id="reasons-8-10" className="reasons">
            {reasons.slice(8).map((reason, i) => (
              <ReasonButton key={i} reason={reason} setReview={setReview} review={review} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductRating;
