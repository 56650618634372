import React from 'react';
import {Stack as PolarisStack} from '@shopify/polaris';

const Stack = React.forwardRef(({children, ...props}, ref) => {
  return (
    <PolarisStack ref={ref} {...props}>
      {children}
    </PolarisStack>
  );
});
Stack.Item = ({children, ...props}) => {
  return <PolarisStack.Item {...props}>{children}</PolarisStack.Item>;
};
export default Stack;
