import React, {useState} from 'react';
import {Text, Form, FormLayout, Link, TextField, Page, Spinner} from '@shopify/polaris';
import {useLoadingStore} from '../../../../app/store';
import Button from '../../../../lib/Button';
import Box from '../../../../lib/Box';
import Inline from '../../../../lib/Inline';
const ResetPasswordInput = ({dispatch, state, handleSubmit, error, disabled}) => {
  const loading = useLoadingStore(state => state.loading);

  const [lengthError, setLengthError] = useState({});
  return (
    <>
      <Inline align="center">
        <Box className="form-container" width="50%" paddingBlockEnd={'4'} paddingBlockStart={'8'}>
          <Form onSubmit={handleSubmit}>
            <FormLayout>
              <TextField
                type="password"
                label="New Password"
                helpText={
                  error !== 'Min 8 characters with letters, numbers and special characters'
                    ? 'Min 8 characters with letters, numbers and special characters'
                    : ''
                }
                error={
                  error !== ''
                    ? error
                    : lengthError.passLength
                    ? 'Max length of 150 characters reached'
                    : ''
                }
                value={state.password}
                onChange={e => {
                  if (e.length < 150) {
                    dispatch({type: 'password', value: e});
                    lengthError.passLength = false;
                    setLengthError(lengthError);
                  } else if (e.length === 150) {
                    dispatch({type: 'password', value: e});
                    lengthError.passLength = true;
                    setLengthError(lengthError);
                  }
                }}
              />

              <TextField
                type="password"
                label="Confirm New Password"
                error={
                  error !== ''
                    ? error
                    : lengthError.cpassLength
                    ? 'Max length of 150 characters reached'
                    : ''
                }
                value={state.confirmPassword}
                onChange={e => {
                  if (e.length < 150) {
                    dispatch({type: 'confirmPassword', value: e});
                    lengthError.cpassLength = false;
                    setLengthError(lengthError);
                  } else if (e.length === 150) {
                    dispatch({type: 'confirmPassword', value: e});
                    lengthError.cpassLength = true;
                    setLengthError(lengthError);
                  }
                }}
              />
              <Box paddingBlockStart={'4'}>
                <Button disabled={disabled} primary fullWidth submit>
                  {loading ? <Spinner size="small" /> : 'Reset Password'}
                </Button>
              </Box>
            </FormLayout>
          </Form>
        </Box>
      </Inline>
    </>
  );
};

export default ResetPasswordInput;
