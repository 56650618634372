import {sendUserActivity} from '../api/track';
import {LocalStorageService} from '../service/localStorage';

const sendUserEvents = async () => {
  let userDetails = LocalStorageService.getUserProfile();
  if (userDetails?.userType !== 'admin' && process.env.REACT_APP_ENV === 'production') {
    let orgDetails = LocalStorageService.getUserOrg();
    let orgName = orgDetails?.name;
    let orgId = orgDetails?.id;
    const activity = {
      event: 'Page View',
      email: userDetails.email,
      orgName,
      orgId,
      pathname: window.location.pathname,
      href: window.location.href,
    };
    sendUserActivity(activity);
  }
};

export {sendUserEvents};
