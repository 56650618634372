import {Text, TextContainer} from '@shopify/polaris';

const NUMBER = 'number';
const AMOUNT = 'amount';
const DECIMAL = 'decimal';
const PERCENTAGE = 'percent';
const DATE = 'date';
const DATETIME = 'datetime';
const ARRAY = 'array';

const bestSellersColumns = [
  {title: 'SKU', value: 'sku', type: 'variantID'},
  {title: 'Product image', value: 'productImage', type: 'image'},
  {title: 'Product title', value: 'productTitle', type: 'variantID'},
  {title: 'Gross items sold', value: 'grossItemsSold', type: NUMBER, sortable: true},
  {title: 'Gross revenue', value: 'grossRevenue', type: AMOUNT, sortable: true},
  {title: 'Net revenue', value: 'netRevenue', type: AMOUNT, sortable: true},
  {title: 'Net items sold', value: 'netItemsSold', type: NUMBER, sortable: true},
  {title: 'Items returned', value: 'itemsReturned', type: NUMBER},
  {title: 'Product return rate %', value: 'productReturnRate', type: PERCENTAGE},
  {title: 'Category', value: 'category'},
  {title: 'Brand', value: 'brand'},
];

const cogsColumns = [
  {title: 'Date', value: 'date', sortable: true},
  {title: 'Gross orders', value: 'grossOrders', type: NUMBER, sortable: true},
  {title: 'Net orders', value: 'netOrders', type: NUMBER, sortable: true},
  // {title: 'Gross COGS', value: 'grossCOGS', type: AMOUNT, sortable: true},
  {title: 'COGS', value: 'netCOGS', type: AMOUNT, sortable: true},
  {title: 'Retainwise COGS', value: 'retainwiseCogs', type: AMOUNT, sortable: true},
];

const cityWiseColumns = [
  {title: 'City', value: 'city'},
  {title: 'Province', value: 'province'},
  {title: 'Country', value: 'country'},
  {title: 'Gross order count', value: 'grossOrderCount', type: NUMBER, sortable: true},
  {title: 'Net order count', value: 'netOrderCount', type: NUMBER, sortable: true},
  {title: 'Customer count', value: 'customerCount', type: NUMBER, sortable: true},
  {title: 'Gross revenue', value: 'grossRevenue', type: AMOUNT, sortable: true},
  {title: 'Net revenue', value: 'netRevenue', type: AMOUNT, sortable: true},
  {title: 'Return rate %', value: 'returnRate', type: PERCENTAGE, sortable: true},
  {title: 'AOV', value: 'AOV', type: AMOUNT, sortable: true},
  {title: 'LTV', value: 'LTV', type: AMOUNT, sortable: true},
  {title: 'Average items per order', value: 'averageItemsPerOrder', type: DECIMAL},
];
const pincodeWiseColumns = [
  {title: 'Pincode', value: 'pincode'},
  {title: 'City', value: 'city'},
  {title: 'Province', value: 'province'},
  {title: 'Country', value: 'country'},
  {title: 'Gross order count', value: 'grossOrderCount', type: NUMBER, sortable: true},
  {title: 'Net order count', value: 'netOrderCount', type: NUMBER, sortable: true},
  {title: 'Customer count', value: 'customerCount', type: NUMBER, sortable: true},
  {title: 'Gross revenue', value: 'grossRevenue', type: AMOUNT, sortable: true},
  {title: 'Net revenue', value: 'netRevenue', type: AMOUNT, sortable: true},
  {title: 'Return rate %', value: 'returnRate', type: PERCENTAGE, sortable: true},
  {title: 'AOV', value: 'AOV', type: AMOUNT, sortable: true},
  {title: 'LTV', value: 'LTV', type: AMOUNT, sortable: true},
  {title: 'Average items per order', value: 'averageItemsPerOrder', type: DECIMAL},
];
const provinceWiseColumns = [
  {title: 'Province', value: 'province'},
  {title: 'Country', value: 'country'},
  {title: 'Gross order count', value: 'grossOrderCount', type: NUMBER, sortable: true},
  {title: 'Net order count', value: 'netOrderCount', type: NUMBER, sortable: true},
  {title: 'Customer count', value: 'customerCount', type: NUMBER, sortable: true},
  {title: 'Gross revenue', value: 'grossRevenue', type: AMOUNT, sortable: true},
  {title: 'Net revenue', value: 'netRevenue', type: AMOUNT, sortable: true},
  {title: 'Return rate %', value: 'returnRate', type: PERCENTAGE, sortable: true},
  {title: 'AOV', value: 'AOV', type: AMOUNT, sortable: true},
  {title: 'LTV', value: 'LTV', type: AMOUNT, sortable: true},
  {title: 'Average items per order', value: 'averageItemsPerOrder', type: DECIMAL},
];

const countryWiseColumns = [
  {title: 'Country', value: 'country'},
  {title: 'Gross order count', value: 'grossOrderCount', type: NUMBER, sortable: true},
  {title: 'Net order count', value: 'netOrderCount', type: NUMBER, sortable: true},
  {title: 'Customer count', value: 'customerCount', type: NUMBER, sortable: true},
  {title: 'Gross revenue', value: 'grossRevenue', type: AMOUNT, sortable: true},
  {title: 'Net revenue', value: 'netRevenue', type: AMOUNT, sortable: true},
  {title: 'Return rate %', value: 'returnRate', type: PERCENTAGE, sortable: true},
  {title: 'AOV', value: 'AOV', type: AMOUNT, sortable: true},
  {title: 'LTV', value: 'LTV', type: AMOUNT, sortable: true},
  {title: 'Average items per order', value: 'averageItemsPerOrder', type: DECIMAL},
];

const grossRevenueColumns = [
  {title: 'Date', value: 'date', sortable: true},
  {title: 'Gross orders', value: 'grossOrders', type: NUMBER, sortable: true},
  {title: 'Gross revenue', value: 'grossRevenue', type: AMOUNT, sortable: true},
  {title: 'Gross AOV', value: 'grossAOV', type: AMOUNT, sortable: true},
  {title: 'Repeat customers', value: 'repeatedCustomers', type: NUMBER, sortable: true},
  {
    title: 'Gross orders from repeat customers',
    value: 'grossOrdersFromRepeatedCustomers',
    type: NUMBER,
    sortable: true,
  },
  {
    title: 'Gross revenue from repeat customers',
    value: 'grossRevenueFromRepeatedCustomers',
    type: AMOUNT,
    sortable: true,
  },
  {
    title: 'Gross AOV from repeat customers',
    value: 'grossAOVFromRepeatedCustomers',
    type: AMOUNT,
    sortable: true,
  },
  {title: 'New customers', value: 'newCustomers', type: NUMBER, sortable: true},

  {
    title: 'Gross orders from new customers',
    value: 'grossOrdersFromNewCustomers',
    type: NUMBER,
    sortable: true,
  },
  {
    title: 'Gross revenue from new customers',
    value: 'grossRevenueFromNewCustomers',
    type: AMOUNT,
    sortable: true,
  },
  {
    title: 'Gross AOV from new customers',
    value: 'grossAOVFromNewCustomers',
    type: AMOUNT,
    sortable: true,
  },
];
const netRevenueColumns = [
  {title: 'Date', value: 'date', sortable: true},
  {title: 'Net orders', value: 'netOrders', type: NUMBER, sortable: true},
  {title: 'Net revenue', value: 'netRevenue', type: AMOUNT, sortable: true},
  {title: 'Net AOV', value: 'netAOV', type: AMOUNT, sortable: true},
  {title: 'Repeat customers', value: 'repeatedCustomers', type: NUMBER, sortable: true},
  {
    title: 'Net orders from repeat customers',
    value: 'netOrdersFromRepeatedCustomers',
    type: NUMBER,
    sortable: true,
  },
  {
    title: 'Net revenue from repeat customers',
    value: 'netRevenueFromRepeatedCustomers',
    type: AMOUNT,
    sortable: true,
  },
  {
    title: 'Net AOV from repeat customers',
    value: 'netAOVFromRepeatedCustomers',
    type: AMOUNT,
    sortable: true,
  },
  {title: 'New customers', value: 'newCustomers', type: NUMBER, sortable: true},
  {
    title: 'Net orders from new customers',
    value: 'netOrdersFromNewCustomers',
    type: NUMBER,
    sortable: true,
  },
  {
    title: 'Net revenue from new customers',
    value: 'netRevenueFromNewCustomers',
    type: AMOUNT,
    sortable: true,
  },
  {
    title: 'Net AOV from new customers',
    value: 'netAOVFromNewCustomers',
    type: AMOUNT,
    sortable: true,
  },
];

const discountCodeUsageColumns = [
  {title: 'Discount code', value: 'discountCode'},
  {title: 'Orders count', value: 'ordersCount', type: NUMBER, sortable: true},
  {title: 'Total discount', value: 'totalDiscount', type: AMOUNT, sortable: true},
  {title: 'Discount rate %', value: 'discountRate', type: PERCENTAGE, sortable: true},
  {title: 'Net revenue', value: 'netRevenue', type: AMOUNT, sortable: true},
  {title: 'Repeat customers', value: 'repeatedCustomers', type: NUMBER, sortable: true},
  {title: 'New customers', value: 'newCustomers', type: NUMBER, sortable: true},
];
const discountsColumns = [
  {title: 'Date', value: 'date', sortable: true},
  {title: 'Net orders', value: 'netOrders', type: NUMBER, sortable: true},
  {title: 'Net revenue', value: 'netRevenue', type: AMOUNT, sortable: true},
  {title: 'Orders with discount', value: 'ordersWithDiscount', type: NUMBER, sortable: true},
  {title: 'Total discount value used', value: 'totalDiscount', type: AMOUNT, sortable: true},
  {title: 'Average discount %', value: 'averageDiscount', type: PERCENTAGE, sortable: true},
];
const firstOrderValueOfRepeatCustomersColumns = [
  {title: 'Date', value: 'date', sortable: true},
  {
    title: 'First order value of repeat customers',
    value: 'firstOrderValueOfRepeatCustomers',
    type: AMOUNT,
    sortable: true,
  },
  {title: 'Repeat customers count', value: 'repeatCustomerCount', type: NUMBER, sortable: true},

  {
    title: 'Revenue from repeat customers',
    value: 'repeatCustomerRevenue',
    type: AMOUNT,
    sortable: true,
  },
];

const averageItemsPerOrderColumns = [
  {title: 'Date', value: 'date', sortable: true},
  {
    title: 'Average items per order',
    value: 'averageItemsPerOrder',
    type: DECIMAL,
    sortable: true,
  },
  {title: 'Average item price', value: 'averageItemPrice', type: AMOUNT, sortable: true},
  {title: 'AOV', value: 'AOV', type: AMOUNT, sortable: true},

  {
    title: 'Number of orders',
    value: 'numberOfOrders',
    type: NUMBER,
    sortable: true,
  },
];

const lifeTimeValueColumns = [
  {title: 'Date', value: 'date', sortable: true},
  {title: 'Lifetime value', value: 'lifetimeValue', type: AMOUNT, sortable: true},

  {
    title: 'Customers count',
    value: 'customersCount',
    type: NUMBER,
    sortable: true,
  },
  {title: 'Net revenue', value: 'netRevenue', type: AMOUNT, sortable: true},
];

const rfmAnalysisColumns = [
  {title: 'RFM category', value: 'rfmCategory', type: 'rfmCategory'},
  {
    title: 'Customers count',
    value: 'customersCount',
    type: NUMBER,
    sortable: true,
  },
  {title: 'Percentage', value: 'percentage', type: PERCENTAGE, sortable: true},
];
const averageOrderValueColumns = [
  {title: 'Date', value: 'date', sortable: true},
  {title: 'Net orders', value: 'netOrders', type: NUMBER, sortable: true},
  {title: 'Net revenue', value: 'netRevenue', type: AMOUNT, sortable: true},
  {title: 'AOV', value: 'AOV', type: AMOUNT, sortable: true},

  {title: 'Repeat customers', value: 'repeatedCustomers', type: NUMBER, sortable: true},
  {
    title: 'Net orders from repeat customers',
    value: 'netOrdersFromRepeatedCustomers',
    type: NUMBER,
    sortable: true,
  },
  {
    title: 'Net revenue from repeat customers',
    value: 'netRevenueFromRepeatedCustomers',
    type: AMOUNT,
    sortable: true,
  },
  {
    title: 'Net AOV from repeat customers',
    value: 'netAOVFromRepeatedCustomers',
    type: AMOUNT,
    sortable: true,
  },
  {title: 'New customers', value: 'newCustomers', type: NUMBER, sortable: true},

  {
    title: 'Net orders from new customers',
    value: 'netOrdersFromNewCustomers',
    type: NUMBER,
    sortable: true,
  },
  {
    title: 'Net revenue from new customers',
    value: 'netRevenueFromNewCustomers',
    type: AMOUNT,
    sortable: true,
  },
  {
    title: 'Net AOV from new customers',
    value: 'netAOVFromNewCustomers',
    type: AMOUNT,
    sortable: true,
  },
];

const repeatCustomersColumns = [
  {title: 'Date', value: 'date', sortable: true},
  {title: 'Total orders', value: 'totalOrders', type: NUMBER, sortable: true},

  {title: 'Repeat customers', value: 'repeatedCustomers', type: NUMBER, sortable: true},
  {
    title: 'Orders from repeat customers',
    value: 'ordersFromRepeatedCustomers',
    type: NUMBER,
    sortable: true,
  },
  {
    title: 'Revenue from repeat customers',
    value: 'revenueFromRepeatedCustomers',
    type: AMOUNT,
    sortable: true,
  },
  {
    title: 'Revenue percentage from repeat customers',
    value: 'revenuePercentageFromRepeatCustomers',
    type: PERCENTAGE,
    sortable: true,
  },
  {title: 'New customers', value: 'newCustomers', type: NUMBER, sortable: true},

  {
    title: 'Orders from new customers',
    value: 'ordersFromNewCustomers',
    type: NUMBER,
    sortable: true,
  },
  {
    title: 'Revenue from new customers',
    value: 'revenueFromNewCustomers',
    type: AMOUNT,
    sortable: true,
  },
];
const returnsColumns = [
  {title: 'Date', value: 'date', sortable: true},
  {title: 'Gross orders', value: 'grossOrders', type: NUMBER, sortable: true},
  {title: 'Net orders', value: 'netOrders', type: NUMBER, sortable: true},
  {title: 'Return orders', value: 'returnOrders', type: NUMBER, sortable: true},
  {title: 'Return revenue', value: 'returnRevenue', type: AMOUNT, sortable: true},

  {title: 'Return rate', value: 'returnRate', type: PERCENTAGE, sortable: true},

  {
    title: 'Return count from new customers',
    value: 'returnCountFromNewCustomers',
    type: NUMBER,
    sortable: true,
  },
  {
    title: 'Return amount from new customers',
    value: 'returnAmountFromNewCustomers',
    type: AMOUNT,
    sortable: true,
  },
  {
    title: 'Return rate from new customers',
    value: 'returnRateFromNewCustomers',
    type: PERCENTAGE,
    sortable: true,
  },

  {
    title: 'Return count from repeat customers',
    value: 'returnCountFromRepeatedCustomers',
    type: NUMBER,
    sortable: true,
  },
  {
    title: 'Return amount from repeat customers',
    value: 'returnAmountFromRepeatedCustomers',
    type: AMOUNT,
    sortable: true,
  },
  {
    title: 'Return rate from repeat customers',
    value: 'returnRateFromRepeatedCustomers',
    type: PERCENTAGE,
    sortable: true,
  },
];
const revenueBySourceColumns = [
  {title: 'Source name', value: 'sourceName'},
  {title: 'Gross orders', value: 'grossOrders', type: NUMBER, sortable: true},
  {title: 'Gross revenue', value: 'grossRevenue', type: AMOUNT, sortable: true},
  {title: 'Net orders', value: 'netOrders', type: NUMBER, sortable: true},
  {title: 'Net revenue', value: 'netRevenue', type: AMOUNT, sortable: true},
  {title: 'Repeat customers', value: 'repeatedCustomers', type: NUMBER, sortable: true},
  {
    title: 'Net revenue from repeat customers',
    value: 'netRevenueFromRepeatedCustomers',
    type: AMOUNT,
    sortable: true,
  },
  {title: 'New customers', value: 'newCustomers', type: NUMBER, sortable: true},
  {
    title: 'Net revenue from new customers',
    value: 'netRevenueFromNewCustomers',
    type: AMOUNT,
    sortable: true,
  },
  {title: 'AOV', value: 'netAOV', type: AMOUNT, sortable: true},
];
const abandonedCheckoutsColumns = [
  // {title: 'Checkout ID', value: 'checkoutID'},
  {title: 'Checkout', value: 'checkoutName'},
  {title: 'Date', value: 'date', sortable: true},
  // {title: 'Customer ID', value: 'customerID', type: 'customerID'},
  {title: 'Customer', value: 'customerName', type: 'customerID'},
  {title: 'Email', value: 'email'},
  {title: 'Mobile number', value: 'mobileNumber'},
  {title: 'RFM category', value: 'rfmCategory'},
  {title: 'RFM score', value: 'rfmScore'},
  {title: 'Source name', value: 'sourceName'},
  {title: 'SKU', value: 'skus', type: 'variantIDs'},
  {title: 'Product name', value: 'productName'},
  // {title: 'Variant IDs', value: 'variantIDs', type: 'variantIDs'},
  {title: 'Variant name', value: 'variantName'},
  {title: 'Vendor name', value: 'vendorName'},
  {title: 'Discount amount', value: 'discountAmount', type: AMOUNT},
  {title: 'Discount code', value: 'discountCode'},
  {title: 'Checkout URL', value: 'checkoutURL', type: 'checkoutURL'},
  {title: 'Line items price', value: 'lineItemsPrice', type: AMOUNT},
  {title: 'Cart value', value: 'cartValue', type: AMOUNT},
  {title: 'Tax', value: 'tax', type: AMOUNT},
  {title: 'Shipping cost', value: 'shippingCost', type: AMOUNT},
  {title: 'Shipping method', value: 'shippingMethod'},
  {title: 'Shipping city', value: 'shippingCity'},
  {title: 'Shipping province', value: 'shippingProvince'},
  {title: 'Shipping zip', value: 'shippingZip'},
  {title: 'Shipping country', value: 'shippingCountry'},
  {title: 'Accepts marketing', value: 'acceptsMarketing'},
  {title: 'Currency', value: 'currency'},
];

const infoCols = {
  current: 'Current',
  '1monthAgo': '1 month ago',
  '3monthAgo': '3 months ago',
  '6monthAgo': '6 months ago',
  '12monthAgo': '12 months ago',
  '24monthAgo': '24 months ago',
};
const rfmCategoryColumns = [
  // {title: 'Customer ID', value: 'customerID', type: 'customerID'},
  {title: 'Customer', value: 'customerName', type: 'customerID'},
  {title: 'RFM score', value: 'rfmScore'},
  {title: 'Gross revenue', value: 'grossRevenue', type: AMOUNT, sortable: true},
  {title: 'Gross orders', value: 'grossOrders', type: NUMBER, sortable: true},
  {title: 'Net revenue', value: 'netRevenue', type: AMOUNT, sortable: true},
  {title: 'Net orders', value: 'netOrders', type: NUMBER, sortable: true},
  {title: 'AOV', value: 'AOV', type: AMOUNT, sortable: true},
];

const customersColumns = [
  {title: 'Customer', value: 'customerName', type: 'customerID'},
  {title: 'RFM category', value: 'rfmCategory', infoRequired: true},
  {title: 'Lifetime revenue', value: 'LTV', type: AMOUNT, sortable: true, infoRequired: true},
  {
    title: 'Trailing 12-month LTV',
    value: '12MonthsLTV',
    type: AMOUNT,
    sortable: true,
    infoRequired: true,
  },
  {title: 'AOV', value: 'AOV', type: AMOUNT, infoRequired: true},
  {title: 'Email', value: 'email'},
  {title: 'Mobile number', value: 'mobileNumber'},
  {
    title: 'First order date',
    value: 'firstOrderDate',
    sortable: true,
    type: DATE,
    infoRequired: true,
  },
  {
    title: 'Last order date',
    value: 'lastOrderDate',
    sortable: true,
    type: DATE,
    infoRequired: true,
  },
  {
    title: 'Net order count',
    value: 'netOrdersCount',
    type: NUMBER,
    sortable: true,
    infoRequired: true,
  },
  {title: 'Net revenue', value: 'netRevenue', type: AMOUNT, sortable: true, infoRequired: true},
  {title: 'Total items', value: 'totalItems', type: NUMBER, sortable: true, infoRequired: true},
  {title: 'Items per order', value: 'avgItemsPerOrder', type: DECIMAL, infoRequired: true},

  {
    title: 'Product return rate %',
    value: 'productReturnRate',
    type: PERCENTAGE,
    sortable: true,
    infoRequired: true,
  },
  {
    title: 'Average days between orders',
    value: 'averageDaysBetweenOrders',
    type: DECIMAL,
    infoRequired: true,
  },
  {title: 'First purchase value', value: 'firstPurchaseValue', type: AMOUNT, infoRequired: true},
  {
    title: 'Count of coupon codes redemeed',
    value: 'countOfCouponCodesRedeemed',
    type: NUMBER,
    infoRequired: true,
  },
  {title: 'Customer tags', value: 'customerTags'},
];
const segmentColumns = [
  // {title: 'Customer ID', value: 'customerID', type: 'customerID'},
  {title: 'Customer', value: 'customerName', type: 'customerID'},
  {title: 'RFM category', value: 'rfmCategory'},
  {title: 'RFM score', value: 'rfmScore', type: NUMBER},
  {title: 'LTV', value: 'LTV', type: AMOUNT, sortable: true},

  {title: 'AOV', value: 'AOV', type: AMOUNT, sortable: true},
  {title: 'Email', value: 'email'},
  {title: 'Mobile number', value: 'mobileNumber'},
  {title: 'Pincode', value: 'pincode'},
  {title: 'City', value: 'city'},
  {title: 'Province', value: 'province'},
  {title: 'Country', value: 'country'},
  {title: 'First order date', value: 'firstOrderDate', type: DATE},
  {title: 'Last order date', value: 'lastOrderDate', type: DATE},
  {title: 'Lifetime in months', value: 'lifetimeInMonths', type: DECIMAL},
  {title: 'Net revenue', value: 'netRevenue', type: AMOUNT, sortable: true},
  {title: 'Total items', value: 'totalItems', type: NUMBER, sortable: true},
  {title: 'Product return rate %', value: 'productReturnRate', type: PERCENTAGE, sortable: true},
  {title: 'Gross margin', value: 'grossMargin', type: AMOUNT, sortable: true},
  {title: 'Average days between orders', value: 'averageDaysBetweenOrders', type: DECIMAL},

  {title: 'Customer tags', value: 'customerTags'},
  {title: 'Order tags of first purchase', value: 'orderTagsOfFirstPurchase'},
];

const productsColumns = [
  {title: 'Title', value: 'title', type: 'productLink', sortable: true},
  {title: 'Variant title', value: 'variantTitle', type: 'productLink'},
  {title: 'SKU', value: 'sku', sortable: true},
  {title: 'Status', value: 'status'},
  {title: 'Category', value: 'productType'},
  {title: 'Rate of sale', value: 'rateOfSale', type: NUMBER, sortable: true, infoRequired: true},
  {
    title: 'Revenue per day',
    value: 'revenuePerDay',
    type: AMOUNT,
    sortable: true,
    infoRequired: true,
  },
  {
    title: 'Weeks of inventory',
    value: 'weeksOfInventory',
    type: 'weeksOfInventory',
    sortable: true,
    infoRequired: true,
  },
  {
    title: 'Net items sold',
    value: 'netItemsSold',
    type: NUMBER,
    sortable: true,
    infoRequired: true,
  },
  {
    title: 'Conversion rate',
    value: 'conversionRate',
    type: PERCENTAGE,
    sortable: true,
    infoRequired: true,
  },
  {title: 'Net revenue', value: 'netRevenue', type: AMOUNT, infoRequired: true},
  // {
  //   title: 'Gross margin %',
  //   value: 'grossMarginPercentage',
  //   type: PERCENTAGE,
  //   sortable: true,
  //   infoRequired: true,
  // },
  {title: 'Gross margin', value: 'grossMargin', type: AMOUNT, sortable: true},
  {title: 'Profit margin', value: 'profitMargin', type: AMOUNT, sortable: true},
  {title: 'Inventory available', value: 'inventoryQuantity', type: NUMBER, infoRequired: true},
  {
    title: 'Abandoned quantity',
    value: 'abandonedQuantity',
    type: NUMBER,
    sortable: true,
    infoRequired: true,
  },

  {title: 'Last selling price', value: 'lastSellingPrice', type: AMOUNT, infoRequired: true},
  {title: 'Average selling price', value: 'averageSellingPrice', type: AMOUNT, infoRequired: true},
  {title: 'COGS', value: 'cogs', type: AMOUNT, infoRequired: true},
  {title: 'Return rate', value: 'returnRate', type: PERCENTAGE, sortable: true, infoRequired: true},
  {
    title: 'Last order date',
    value: 'lastOrderDate',
    sortable: true,
    type: DATE,
    infoRequired: true,
  },
];

const allOrdersColumns = [
  {title: 'Order', value: 'orderName'},
  {title: 'Date', value: 'date', sortable: true},
  {title: 'Customer', value: 'customerName', type: 'customerID'},
  {title: 'City', value: 'city'},
  {title: 'Gross amount', value: 'grossAmount', type: AMOUNT, sortable: true},
  {title: 'Net amount', value: 'netAmount', type: AMOUNT, sortable: true},
  {title: 'Items', value: 'items'},
  {title: 'Payment status', value: 'financialStatus', type: 'orderBadge'},
  {title: 'Fulfillment status', value: 'fulfillmentStatus'},
  // {title: 'Gateway', value: 'gateway'},
  {title: 'Total discount', value: 'totalDiscount', type: AMOUNT, sortable: true},
  {title: 'Order cancelled', value: 'isCancelled'},
  {title: 'Order tags', value: 'tags'},
];

const adminJobsColumns = [
  {title: 'JobId', value: '_id', type: 'jobID'},
  {title: 'Organization', value: 'organizationName'},
  {title: 'From', value: 'from', type: DATE},
  {title: 'To', value: 'to', type: DATE},
  {title: 'Created At', value: 'createdAt', type: DATE, sortable: true},
  {title: 'Job Type', value: 'type'},
  {title: 'Progress', value: 'progress', type: 'progress'},
  {title: 'Job Status', value: 'status'},
  {title: 'Errors', value: 'showErrors', type: 'showErrors'},
];

const adminAsyncJobsColumns = [
  {title: 'Async job id', value: '_id'},
  {title: 'Organization', value: 'orgId', type: 'orgId'},
  {title: 'Created At', value: 'createdAt', type: DATE, sortable: true},
  {title: 'Type', value: 'type'},
  {title: 'Source', value: 'source'},
  {title: 'Status', value: 'status'},
  {title: 'Show data', value: 'data', type: 'showJson'},
  {title: 'Show logs', value: 'logs', type: 'showJson'},
  {title: 'Show errors', value: 'errors', type: 'showJson'},
];

const adminSplitsColumns = [
  {title: 'SplitId', value: '_id'},
  {title: 'OrgId', value: 'orgId'},
  {title: 'Object Id', value: 'objectId'},
  {title: 'From', value: 'params.start', type: 'nestedDate'},
  {title: 'To', value: 'params.end', type: 'nestedDate'},
  {title: 'Created At', value: 'createdAt', type: 'date', sortable: true},
  {title: 'Split Type', value: 'splitType'},
  {title: 'Split Status', value: 'status'},
  {title: 'Queue Status', value: 'queue.status', type: 'nestedValue'},
  {title: 'Attempts', value: 'queue.attempts', type: 'nestedValue'},
  {title: 'Queue Name', value: 'queue.queueName', type: 'nestedValue'},
  {title: 'Priority', value: 'queue.priority', type: 'nestedValue'},
  {title: 'Action', value: 'action', type: 'retrySplit'},
  {title: 'Logs', value: 'logs', type: 'showJson'},
  {title: 'Errors', value: 'showErrors', type: 'showErrors'},
];

const adminOrganizationsColumns = [
  {title: 'Logo', value: 'logo', type: 'brandImage'},
  {title: 'Id', value: '_id'},
  {title: 'Name', value: 'name', type: 'organization'},
  {title: 'Email', value: 'email', type: 'userEmail'},
  {title: 'Created At', value: 'c', type: DATE, sortable: true},
  {title: 'Location', value: 'location', type: 'storeLocation'},
  {title: 'Last logged in at', value: 'lastLoggedIn', type: DATE},
  {title: 'Billing plan', value: 'billingPlan', type: 'empty'},
  {
    title: 'Expected to clickhouse diffrence',
    value: 'expectedToClickhouse',
    type: 'expectedToClickhouse',
  },
  {title: 'Website link', value: 'store.url', type: 'website'},

  {title: 'Actions', value: '_id', type: 'logIn'},
];
const adminOrganizationsDetailsColumns = [
  {title: 'Name', value: 'name'},
  {title: 'Customers', value: 'customers', type: NUMBER},
  {title: 'Orders', value: 'orders', type: NUMBER},
  {title: 'Products', value: 'products', type: NUMBER},
];
const adminWebhookLogsColumns = [
  {title: 'Id', value: '_id'},
  {title: 'Organization', value: 'orgId', type: 'organization'},
  {title: 'Split id', value: 'splitId'},
  {title: 'Created at', value: 'c', type: DATE, sortable: true},
  {title: 'Topic', value: 'topic'},
  {title: 'Data id', value: 'id', type: 'data'},
  {title: 'Data', value: 'data', type: 'showJson'},
];

const npsProductColumns = [
  {title: 'Order', value: 'orderName'},
  {title: 'Date', value: 'date', sortable: true, type: 'date'},
  {title: 'Customer', value: 'customerName', type: 'customerID'},
  {title: 'SKU', value: 'sku', type: 'variantID'},
  {title: 'NPS score', value: 'npsScore'},
  {title: 'Net quantity', value: 'netQuantity', type: NUMBER, sortable: true},
  {title: 'Net value', value: 'netPrice', type: AMOUNT, sortable: true},
  {title: 'Phone number', value: 'phone'},
  {title: 'Email', value: 'email'},
  {title: 'Product review', value: 'npsReview'},
];

const adminOrganizationRunningWebhooksColumns = [
  {title: 'Id', value: 'id'},
  {title: 'Address', value: 'address'},
  {title: 'Topic', value: 'topic'},
];

const productCostColumns = [
  {title: 'Title', value: 'title', type: 'productLink'},
  {title: 'SKU', value: 'sku', type: 'variantID'},
  {title: 'Category', value: 'productType'},
  {title: 'Variant Title', value: 'variantTitle'},
  {title: 'Price', value: 'price', type: AMOUNT, sortable: true},
  {title: 'Shopify Cost', value: 'cost', type: AMOUNT},
  {title: 'Retainwise Cost', value: 'retainwiseCost', type: AMOUNT},
];

const fixedCostColumns = [
  {title: 'Title', value: 'title'},
  {title: 'Category', value: 'category'},
  {title: 'Cost', value: 'value', type: AMOUNT},
  {title: 'Start date', value: 'startDate', type: 'date'},
  {title: 'End date', value: 'endDate', type: 'date'},
  {title: 'Recurring', value: 'recurring'},
  {title: 'Ad spend', value: 'isAdSpend'},
];

const pAndLColumns = [
  // {title: 'Month', value: 'month', sortable: true},
  {title: 'Gross Sales', value: 'grossRevenue', type: AMOUNT},
  {title: 'Discounts', value: 'totalDiscount', type: AMOUNT},
  {title: 'Returns', value: 'refundedAmount', type: AMOUNT},
  {title: 'Cancellations', value: 'cancelledRevenue', type: AMOUNT},
  {title: 'Net Sales', value: 'netRevenue', type: AMOUNT},
  {title: 'Cost of Goods Sold', value: 'cogsAmount', type: AMOUNT},
  {title: 'Shipping cost (Forward)', value: 'shippingAmount', type: AMOUNT},
  {title: 'Shipping cost (Return)', value: 'shippingAmountReturns', type: AMOUNT},
  {title: 'Handling cost', value: 'handlingAmount', type: AMOUNT},
  {title: 'Transaction cost', value: 'transactionAmount', type: AMOUNT},
  {title: 'Contribution Margin 1', value: 'cm1', type: AMOUNT},
  {title: 'Marketing', value: 'marketingCost', type: AMOUNT},
  {title: 'Contribution Margin 2', value: 'cm2', type: AMOUNT},
  {title: 'Custom cost', value: 'customCost', type: AMOUNT},
  {title: 'Total cost', value: 'totalCost', type: AMOUNT},
  {title: 'Gross profit', value: 'grossProfit', type: AMOUNT},
];

const marketingDataColumns = [
  {title: 'Date', value: 'date', sortable: true},
  {title: '{insightLevel}', value: 'insightLevelName', sortable: true},
  {title: 'Currency', value: 'accountCurrency'},
  {title: 'Total spend', value: 'totalSpend', type: NUMBER, sortable: true},
  {title: 'ROAS', value: 'roas', type: NUMBER, sortable: true},
  {title: 'Conversion value', value: 'conversionValue', type: NUMBER, sortable: true},
  {title: 'CTR', value: 'ctr', type: NUMBER, sortable: true},
  // {title: 'CPA', value: 'cpa', type: NUMBER, sortable: true},
  // {title: 'CVR', value: 'cvr', type: NUMBER, sortable: true},
  {title: 'Impressions', value: 'impressions', type: NUMBER, sortable: true},
  {title: 'Clicks', value: 'clicks', type: NUMBER, sortable: true},
  {title: 'Reach', value: 'reach', type: NUMBER, sortable: true},
  {title: 'Conversions', value: 'conversions', type: NUMBER, sortable: true},
];
const productGroupColumns = [
  {title: 'SKU', value: 'sku'},
  {title: 'Title', value: 'title', sortable: true},
  {title: 'Variant title', value: 'variantTitle'},
  {title: 'Category', value: 'productType'},
  {title: 'Tags', value: 'tags'},
];
const adminSummaryLogsColumns = [
  {title: 'Id', value: '_id'},
  {title: 'Created at', value: 'createdAt', type: DATE, sortable: true},
  {title: 'Status', value: 'status'},
  {title: 'Organization', value: 'orgId', type: 'organization'},
  {title: 'Recipients', value: 'recipients', type: 'recipients'},
  {title: 'Html content', value: 'body', type: 'showHtml'},
];
const profitablityColumns = [
  {title: 'Product name', value: 'title', type: 'productLink'},
  {title: 'Variant title', value: 'variantTitle', type: 'productLink'},
  {title: 'SKU', value: 'sku'},
  {title: 'Status', value: 'status'},
  {title: 'Category', value: 'productType'},
  {title: 'Profit margin', value: 'profitMargin', type: AMOUNT},
  {title: 'Net revenue', value: 'netRevenue', type: AMOUNT},
  {title: 'Daily sales', value: 'dailySales', type: NUMBER},
  {title: 'Daily revenue', value: 'dailyRevenue', type: AMOUNT},
  {title: 'Weeks to stock out', value: 'weeksToStockOut', type: NUMBER},
];
const outOfStockColumns = [
  {title: 'Product name', value: 'title', type: 'productLink'},
  {title: 'Variant title', value: 'variantTitle', type: 'productLink'},
  {title: 'SKU', value: 'sku'},
  {title: 'Stock out date', value: 'stockOutDate', type: DATE},
  {title: 'Category', value: 'productType'},
  {title: 'Lost sales', value: 'lostSales', type: NUMBER},
  {title: 'Return rate', value: 'returnRate', type: PERCENTAGE},
  {title: 'Gross margin', value: 'grossMargin', type: AMOUNT},
  {title: 'Profit margin', value: 'profitMargin', type: AMOUNT},
];

const atRiskColumns = [
  {title: 'Product name', value: 'title', type: 'productLink'},
  {title: 'Variant title', value: 'variantTitle', type: 'productLink'},
  {title: 'SKU', value: 'sku'},
  {title: 'Status', value: 'status'},
  {title: 'Category', value: 'productType'},
  {title: 'Inventory', value: 'inventoryQuantity', type: NUMBER},
  {title: 'Expected stock out date', value: 'expectedStockOut', type: DATE},
  {title: 'Net sales', value: 'netSales', type: NUMBER},
  {title: 'Daily sales', value: 'dailySales', type: NUMBER},
];
const risingStarsColumns = [
  {title: 'Product name', value: 'title', type: 'productLink'},
  {title: 'Variant title', value: 'variantTitle', type: 'productLink'},
  {title: 'SKU', value: 'sku'},
  {title: 'Status', value: 'status'},
  {title: 'Category', value: 'productType'},
  {title: 'Net revenue', value: 'netRevenue', type: AMOUNT},
  {title: 'Daily revenue', value: 'dailyRevenue', type: AMOUNT},
  {title: 'Conversion rate', value: 'conversionRate', type: PERCENTAGE},
  {title: 'Weeks to stock out', value: 'weeksToStockOut', type: NUMBER},
  {title: 'Return rate', value: 'returnRate', type: PERCENTAGE},
  {title: 'Profit margin', value: 'profitMargin', type: AMOUNT},
];
const fixNowColumns = [
  {title: 'Product name', value: 'title', type: 'productLink'},
  {title: 'Variant title', value: 'variantTitle', type: 'productLink'},
  {title: 'SKU', value: 'sku'},
  {title: 'Status', value: 'status'},
  {title: 'Category', value: 'productType'},
  {title: 'Net revenue', value: 'netRevenue', type: AMOUNT},
  {title: 'Daily revenue', value: 'dailyRevenue', type: AMOUNT},
  {title: 'Conversion rate', value: 'conversionRate', type: PERCENTAGE},
  {title: 'Weeks to stock out', value: 'weeksToStockOut', type: NUMBER},
  {title: 'Return rate', value: 'returnRate', type: PERCENTAGE},
  {title: 'Profit margin', value: 'profitMargin', type: AMOUNT},
];

const droppedSalesColumns = [
  {title: 'Name', value: 'customerName', sortable: true},
  {title: 'Email', value: 'email', sortable: true},
  {title: 'Phone', value: 'phone'},
  {title: 'Cancelled order date', value: 'cancelledOrderDate', type: DATETIME, sortable: true},
  {title: 'Cancelled order value', value: 'cancelledOrderValue', type: AMOUNT, sortable: true},
  {title: 'Last Order date', value: 'lastNetOrderDate', type: DATETIME, sortable: true},
  {title: 'Lifetime revenue', value: 'netRevenue', type: AMOUNT, sortable: true},
  {title: 'NPS', value: 'nps', sortable: true},
];
const cartRecoveryColumns = [
  {title: 'Name', value: 'customerName', sortable: true},
  {title: 'Email', value: 'email', sortable: true},
  {title: 'Phone', value: 'phone'},
  {title: 'Abandoned cart date', value: 'abandonedCartDate', type: DATETIME, sortable: true},
  {title: 'Abandoned cart value', value: 'abandonedCartValue', type: AMOUNT, sortable: true},
  {title: 'Last Order date', value: 'lastNetOrderDate', type: DATETIME, sortable: true},
  {title: 'Lifetime revenue', value: 'netRevenue', type: AMOUNT, sortable: true},
  {title: 'NPS', value: 'nps', sortable: true},
];

export {
  bestSellersColumns,
  cogsColumns,
  cityWiseColumns,
  provinceWiseColumns,
  countryWiseColumns,
  grossRevenueColumns,
  netRevenueColumns,
  discountCodeUsageColumns,
  discountsColumns,
  firstOrderValueOfRepeatCustomersColumns,
  averageItemsPerOrderColumns,
  lifeTimeValueColumns,
  rfmAnalysisColumns,
  averageOrderValueColumns,
  repeatCustomersColumns,
  returnsColumns,
  revenueBySourceColumns,
  abandonedCheckoutsColumns,
  infoCols,
  rfmCategoryColumns,
  segmentColumns,
  customersColumns,
  productsColumns,
  allOrdersColumns,
  adminJobsColumns,
  adminSplitsColumns,
  npsProductColumns,
  adminOrganizationsColumns,
  adminOrganizationsDetailsColumns,
  adminWebhookLogsColumns,
  adminAsyncJobsColumns,
  adminOrganizationRunningWebhooksColumns,
  productCostColumns,
  fixedCostColumns,
  pincodeWiseColumns,
  pAndLColumns,
  marketingDataColumns,
  productGroupColumns,
  adminSummaryLogsColumns,
  profitablityColumns,
  outOfStockColumns,
  atRiskColumns,
  fixNowColumns,
  risingStarsColumns,
  droppedSalesColumns,
  cartRecoveryColumns,
};
