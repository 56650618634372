import React from 'react';
import {
  TextField,
  Popover,
  Button,
  Text,
  Scrollable,
  Box,
  EmptySearchResult,
  OptionList,
  Inline,
} from '@shopify/polaris';
import {useState, useCallback} from 'react';
import {getTransformedProductTitle} from '../features/getTransformedProductTitle';

const listMap = {
  variants: ({title, sku, variantTitle, variantId}) => {
    const tranformedTitle = getTransformedProductTitle({sku, variantTitle, title});
    return {value: variantId, label: tranformedTitle};
  },
  products: ({title, productId}) => {
    return {value: productId, label: title};
  },
};
const placeholderMap = {
  products: 'Enter product title',
  productTags: 'Enter product tag name',
  variants: 'Enter product title or SKU',
  productCategory: 'Enter product category',
  discountCodes: 'Enter discount code',
  billingCity: 'Enter billing city',
  billingProvince: 'Enter billing province/state',
  billingCountry: 'Enter billing country',
  defaultCity: 'Enter default city',
  defaultProvince: 'Enter default province/state',
  defaultCountry: 'Enter default country',
  shippingCity: 'Enter shipping city',
  shippingProvince: 'Enter shipping province/state',
  shippingCountry: 'Enter shipping country',
};

const itemTypeMap = {
  products: 'products',
  productTags: 'tags',
  productCategory: 'product category',
  discountCodes: 'discount codes',
  billingCity: 'billing city',
  billingProvince: 'billing province/state',
  billingCountry: 'billing country',
  defaultCity: 'default city',
  defaultProvince: 'default province/state',
  defaultCountry: 'default country',
  shippingCity: 'shipping city',
  shippingProvince: 'shipping province/state',
  shippingCountry: 'shipping country',
};
const itemSelectionMap = {
  products: 'productId',
  variants: 'variantId',
  productTags: 'tag',
  productCategory: 'productCategory',
  discountCodes: 'discountCode',
  billingCity: 'billingCity',
  billingProvince: 'billingProvince',
  billingCountry: 'billingCountry',
  defaultCity: 'defaultCity',
  defaultProvince: 'defaultProvince',
  defaultCountry: 'defaultCountry',
  shippingCity: 'shippingCity',
  shippingProvince: 'shippingProvince',
  shippingCountry: 'shippingCountry',
};
function FilterDropDownMultiSelect({
  selectedItems,
  setSelectedItems,
  itemList,
  query,
  setQuery,
  itemType,
  label,
  setIsOpen,
  user,
  // from,
}) {
  const handleTextFieldChange = useCallback(value => setQuery(value), []);
  const [pickerOpen, setPickerOpen] = useState(false);

  const handleOpenPicker = () => {
    const flag = pickerOpen;
    setPickerOpen(!flag);
    setIsOpen(!flag);
  };

  const activator = (
    <div>
      <Button
        fullWidth
        textAlign="left"
        disclosure={pickerOpen ? 'up' : 'down'}
        onClick={handleOpenPicker}
        disabled={user === 'Retainwise'}
      >
        <Text color="subdued">
          {label}
          {selectedItems.length > 0 ? `(${selectedItems.length})` : ''}
        </Text>
      </Button>
    </div>
  );

  const handleClear = () => {
    setQuery('');
  };

  const handleClosePicker = () => {
    setPickerOpen(false);
    setIsOpen(false);
    handleClear();
  };

  const handleAddClick = values => {
    setSelectedItems(values);
  };

  const textFieldMarkup = (
    <div style={{padding: '12px'}}>
      <TextField
        clearButton
        labelHidden
        placeholder={placeholderMap[itemType]}
        autoComplete="off"
        value={query}
        onChange={handleTextFieldChange}
        onClearButtonClick={handleClear}
      />
    </div>
  );

  const ItemOptions = itemList;

  const Items =
    ItemOptions.length > 0
      ? ItemOptions.map(
          listMap[itemType]
            ? listMap[itemType]
            : (x, i) => {
                return {
                  label: x[itemSelectionMap[itemType]],
                  value: x[itemSelectionMap[itemType]],
                };
              }
        )
      : [];
  const noResultsMarkup =
    ItemOptions.length === 0 ? (
      <EmptySearchResult title="" description={`No ${itemTypeMap[itemType]} found`} />
    ) : null;

  const verticalContentMarkup = (
    <OptionList allowMultiple options={Items} selected={selectedItems} onChange={handleAddClick} />
  );
  return (
    <Popover
      active={pickerOpen}
      activator={activator}
      ariaHaspopup="listbox"
      preferredAlignment="left"
      preferredPosition="below"
      autofocusTarget="first-node"
      onClose={handleClosePicker}
      fluidContent
    >
      <Popover.Pane>
        <div
          style={{
            alignItems: 'stretch',
            borderTop: '1px solid #DFE3E8',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'stretch',
            position: 'relative',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          {textFieldMarkup}
          <Scrollable
            shadow
            style={{
              position: 'relative',
              height: '292px',
              padding: 'var(--p-space-200) 0',
              borderBottomLeftRadius: 'var(--p-border-radius-200)',
              borderBottomRightRadius: 'var(--p-border-radius-200)',
            }}
          >
            {verticalContentMarkup}
            {noResultsMarkup}
          </Scrollable>
        </div>
      </Popover.Pane>
      <Popover.Pane fixed>
        <Inline align="end">
          <Box padding={'3'}>
            <Button
              onClick={() => {
                setSelectedItems([]);
              }}
            >
              Clear all
            </Button>
          </Box>
        </Inline>
      </Popover.Pane>
    </Popover>
  );
}

export default FilterDropDownMultiSelect;
