import {Modal} from '@shopify/polaris';
import React from 'react';
import DefaultIndexTable from '../defaultIndexTable';

const ModalTable = ({
  isOpen,
  toggle,
  title,
  filters = [],
  actions = [],
  disableSort = false,
  page,
  setPage,
  resourceName = {
    singular: 'data',
    plural: 'data',
  },
  columns,
  selectedFilters = [],
  durationFilter = 'daily',
  totalRows,
  tableData,
  state,
  valueTypes = {},
  select = false,
}) => {
  const activator = <div></div>;

  return (
    <Modal
      large
      activator={activator}
      open={isOpen}
      onClose={toggle}
      title={title ? title : 'Data'}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: toggle,
        },
      ]}
    >
      <DefaultIndexTable
        filters={filters}
        actions={actions}
        disableSort={disableSort}
        page={page}
        setPage={setPage}
        resourceName={resourceName}
        columns={columns}
        selectedFilters={selectedFilters}
        durationFilter={durationFilter}
        totalRows={totalRows}
        tableData={tableData}
        state={state}
        valueTypes={valueTypes}
        select={select}
      />
    </Modal>
  );
};

export default ModalTable;
