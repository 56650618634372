import React, {useEffect, useReducer, useState, useRef} from 'react';
import {
  Layout,
  FormLayout,
  TextField,
  Select,
  Checkbox,
  Tag,
  SkeletonBodyText,
  Button,
  Icon,
} from '@shopify/polaris';
import Stack from '../../../lib/Stack';
import Card from '../../../lib/Card';
import Text from '../../../lib/Text';
import Box from '../../../lib/Box';
import Inline from '../../../lib/Inline';
import {CancelMajor} from '@shopify/polaris-icons';

import Page from '../../../components/page';
import {daysOfWeekInit, specificHourInit} from './scheduleCheckbox';
import {usePreventNavigateStore, useSaveChangesStore} from '../../../app/store';
import {emailValidator} from '../../../features/validations';
import {useNavigate} from 'react-router-dom';
import useUnsavedChangesWarning from '../../../hooks/useUnsavedChangesWarning';
import SelectComponent from './select';
import MultiSelectComponent from './multiSelect';
import Alert from '../../../components/alert';
import {ErrorBoundary} from '../../../components/ErrorBoundary';
import {track} from '../../../features/track';
import {createAlert, getAlert, updateAlert} from '../../../api/alerts';
import Property from '../../segments/components/property';
import {groups, options} from './options';
import Operator from '../../segments/components/operator';
import FilterDropDown from '../../../components/FilterDropdown';
import FilterDropDownMultiSelect from '../../../components/FilterDropDownMultiSelect';
import {ValueOption} from '../../segments/components/value';
import Threshold from './Threshold';
import By from './By';
import {LocalStorageService} from '../../../service/localStorage';
import {getVariantList} from '../../../api/filterLists';

const allowedReports = ['net-revenue', 'customers', 'orders', 'returns', 'repeat-customers'];

const Values = ({parentIndex, index, dispatch, prop, options, state, seg, user}) => {
  const [numericError, setNumericError] = useState('');
  const [itemList, setItemList] = useState([]);
  const [query, setQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const listMap = {
    // variants: [],
    variants: getVariantList,
    any: 'any',
  };
  useEffect(() => {
    const params = {listName: options[prop.property]?.value[prop.operator]?.itemType};
    if (query) {
      params.search = query;
    }
    if (isOpen) {
      listMap[options[prop.property].value[prop.operator].itemType]({params})
        .then(res => {
          setItemList(res.data.result);
        })
        .catch(err => console.log(err));
    }
  }, [query, isOpen]);
  const targetElement = useRef();
  useEffect(() => {
    targetElement.current.scrollIntoView({
      behavior: 'smooth',
    });
  }, []);
  return (
    <Box key={`${parentIndex}-${index}`} ref={targetElement}>
      {index ? (
        <Box paddingBlockEnd={'4'}>
          <Text alignment="center" variant="headingMd" color="subdued">
            OR
          </Text>
        </Box>
      ) : (
        <></>
      )}
      <Box paddingBlockEnd={'8'}>
        <Inline align="space-between" blockAlign="start">
          <Box width="90%">
            <Inline blockAlign="center">
              <Box width="17%">
                <Property
                  dispatch={dispatch}
                  value={prop.property}
                  indexes={[parentIndex, index]}
                  options={groups}
                  user={user}
                />
              </Box>
              {prop.property !== '' && (
                <Box width="17%">
                  <Operator
                    value={prop.operator}
                    indexes={[parentIndex, index]}
                    dispatch={dispatch}
                    operatorOptions={options[prop.property].operator}
                    user={state.user}
                  />
                </Box>
              )}

              {prop.operator !== '' && !['isOneOf', 'any'].includes(prop.operator) && (
                <Box width="17%">
                  <Threshold
                    dispatch={dispatch}
                    value={prop.threshold}
                    indexes={[parentIndex, index]}
                    valueOptions={options[prop.property].threshold[prop.operator].options}
                    user={state.user}
                  />
                </Box>
              )}
              {options[prop.property]?.value[prop.operator]?.type ===
                'filterDropdownMultiSelect' && (
                <>
                  <FilterDropDownMultiSelect
                    itemList={itemList.length > 0 ? itemList : []}
                    selectedItems={
                      prop.value
                        ? prop.value.split(',').filter(x => x !== undefined && x !== null)
                        : []
                    }
                    setSelectedItems={values => {
                      dispatch({
                        type: 'value',
                        indexes: [parentIndex, index],
                        value: values.join(','),
                      });
                    }}
                    setQuery={setQuery}
                    query={query}
                    itemType={options[prop.property].value[prop.operator].itemType}
                    label={options[prop.property].value[prop.operator].label}
                    setIsOpen={setIsOpen}
                    from={'newSegments'}
                    user={state.user}
                  />
                </>
              )}
              {prop.threshold && options[prop.property].value[prop.threshold].required && (
                <Box width="17%">
                  {options[prop.property].value[prop.threshold].type === 'option' && (
                    <ValueOption
                      dispatch={dispatch}
                      value={prop.value}
                      indexes={[parentIndex, index]}
                      valueOptions={options[prop.property].value[prop.threshold].options}
                      user={state.user}
                    />
                  )}
                  {options[prop.property].value[prop.threshold].type === 'number' && (
                    <TextField
                      type="number"
                      placeholder="Enter Value"
                      value={prop.value}
                      min={1}
                      inputMode="numeric"
                      error={numericError}
                      disabled={state.user === 'Retainwise'}
                      onChange={e => {
                        if (e < 1) {
                          setNumericError('Value must be greater than 0');
                        } else {
                          setNumericError('');
                        }
                        dispatch({
                          type: 'value',
                          indexes: [parentIndex, index],
                          value: e,
                        });
                      }}
                      autoComplete="off"
                    />
                  )}
                </Box>
              )}
              {prop.value !== null &&
                prop.value !== undefined &&
                prop.threshold &&
                options[prop.property].by.required && (
                  <>
                    <Text variant="headingSm">BY</Text>
                    <Box width="17%">
                      <By
                        dispatch={dispatch}
                        value={prop.by}
                        indexes={[parentIndex, index]}
                        options={options[prop.property].by.options}
                        user={state.user}
                      />
                    </Box>
                  </>
                )}
            </Inline>
          </Box>

          {state.alerts.members.length > 1 || seg.members.length > 1 ? (
            <Box>
              <Button
                onClick={() => {
                  dispatch({
                    type: 'delete',
                    parentValue: parentIndex,
                    value: index,
                  });
                }}
                destructive
                outline
                disabled={state.user === 'Retainwise'}
              >
                <Icon source={CancelMajor} />
              </Button>
            </Box>
          ) : (
            <></>
          )}
        </Inline>
      </Box>
    </Box>
  );
};
const initialState = {
  emailSubject: '',
  description: '',
  recipients: [],
  schedule: {
    frequency: '',
    days: [],
    hoursType: '',
    hours: [],
  },
  alerts: {
    type: 'group',
    operator: 'and',
    members: [
      {
        type: 'group',
        operator: 'or',
        members: [
          {
            type: 'rule',
            property: '',
            operator: '',
            threshold: '',
            value: null,
            by: '',
          },
        ],
      },
    ],
  },
};

const removeFromArray = (array, item) => {
  let filtered = array.filter(e => e !== item);
  return filtered;
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'emailSubject':
      return {...state, emailSubject: action.value};
    case 'description':
      return {...state, description: action.value};
    case 'recipients':
      return {...state, recipients: [...state.recipients, action.value]};
    case 'removeFromRecipients':
      return {
        ...state,
        recipients: removeFromArray(state.recipients, action.value),
      };
    case 'property':
      state.alerts.members[action.indexes[0]].members[action.indexes[1]].property = action.value;
      state.alerts.members[action.indexes[0]].members[action.indexes[1]].operator = '';
      state.alerts.members[action.indexes[0]].members[action.indexes[1]].value = null;
      state.alerts.members[action.indexes[0]].members[action.indexes[1]].threshold = null;
      state.alerts.members[action.indexes[0]].members[action.indexes[1]].by = null;

      return {...state, alerts: {...state.alerts}};
    case 'operator':
      state.alerts.members[action.indexes[0]].members[action.indexes[1]].operator = action.value;
      state.alerts.members[action.indexes[0]].members[action.indexes[1]].value = null;
      state.alerts.members[action.indexes[0]].members[action.indexes[1]].threshold = null;
      state.alerts.members[action.indexes[0]].members[action.indexes[1]].by = null;

      return {...state, alerts: {...state.alerts}};
    case 'threshold':
      state.alerts.members[action.indexes[0]].members[action.indexes[1]].threshold = action.value;
      state.alerts.members[action.indexes[0]].members[action.indexes[1]].value = null;
      state.alerts.members[action.indexes[0]].members[action.indexes[1]].by = null;

      return {...state, alerts: {...state.alerts}};
    case 'value':
      state.alerts.members[action.indexes[0]].members[action.indexes[1]].value = action.value;
      state.alerts.members[action.indexes[0]].members[action.indexes[1]].by = null;
      return {...state, alerts: {...state.alerts}};
    case 'by':
      state.alerts.members[action.indexes[0]].members[action.indexes[1]].by = action.value;
      return {...state, alerts: {...state.alerts}};
    case 'or':
      state.alerts.members[action.value].members.push({
        type: 'rule',
        property: '',
        operator: '',
        threshold: '',
        value: null,
        by: '',
      });
      return {...state, alerts: {...state.alerts}};
    case 'and':
      state.alerts.members.splice(action.value + 1, 0, {
        type: 'group',
        operator: 'or',
        members: [
          {
            type: 'rule',
            property: '',
            operator: '',
            threshold: '',
            value: null,
            by: '',
          },
        ],
      });
      return {
        ...state,
        alerts: {...state.alerts},
      };
    case 'delete':
      if (state.alerts.members[action.parentValue].members.length === 1) {
        state.alerts.members.splice(action.parentValue, 1);
      } else {
        state.alerts.members[action.parentValue].members.splice(action.value, 1);
      }

      return {...state, alerts: {...state.alerts}};
    case 'update':
      return {...action.value};
    case 'frequency':
      return {
        ...state,
        schedule: {
          ...state.schedule,
          days: [],
          frequency: action.value,
          hoursType: '',
          hours: [],
        },
      };
    case 'everyDay':
      return {
        ...state,
        schedule: {
          ...state.schedule,
          days: [],
          frequency: 'everyDay',
        },
      };
    case 'specificDaysOfWeek':
      if (state.schedule.frequency === 'daysOfWeek') {
        return {
          ...state,
          schedule: {
            ...state.schedule,

            days: [...action.value],
            frequency: 'daysOfWeek',
          },
        };
      } else {
        return {
          ...state,
          schedule: {
            ...state.schedule,

            days: [action.value],
            frequency: 'daysOfWeek',
          },
        };
      }

    case 'removeFromSpecificDaysOfWeek':
      return {
        ...state,
        schedule: {
          ...state.schedule,

          ...state.schedule,
          days: removeFromArray(state.schedule.days, action.value),
        },
      };
    case 'specificDaysOfMonth':
      if (state.schedule.frequency === 'daysOfMonth') {
        return {
          ...state,
          schedule: {
            ...state.schedule,

            days: [...action.value],
            frequency: 'daysOfMonth',
          },
        };
      } else {
        return {
          ...state,
          schedule: {
            ...state.schedule,

            days: [action.value],
            frequency: 'daysOfMonth',
            hoursType: '',
            hours: [],
          },
        };
      }
    case 'removeFromSpecificDaysOfMonth':
      return {
        ...state,
        schedule: {
          ...state.schedule,
          days: removeFromArray(state.schedule.days, action.value),
        },
      };
    case 'everyHour':
      return {
        ...state,
        schedule: {
          ...state.schedule,
          hours: [],
          hoursType: 'everyHour',
        },
      };
    case 'specificHour':
      return {
        ...state,
        schedule: {
          ...state.schedule,

          hours: [...action.value],
          hoursType: 'specificHour',
        },
      };
    case 'removeFromSpecificHour':
      return {
        ...state,
        schedule: {
          ...state.schedule,
          hours: removeFromArray(state.schedule.hours, action.value),
        },
      };
    case 'reports':
      return {
        ...state,
        reports: [...state.reports, action.value],
      };
    case 'removeFromReports':
      return {
        ...state,
        reports: removeFromArray(state.reports, action.value),
      };
    case 'updateState':
      let nstate = action.value;
      return {
        ...nstate,
      };
    default:
      throw new Error();
  }
};

const NewAlert = ({params}) => {
  const isUnchanged = useSaveChangesStore(state => state.setIsUnchanged);
  const setOnSubmit = useSaveChangesStore(state => state.setOnSubmit);
  const setOnDiscard = useSaveChangesStore(state => state.setOnDiscard);
  const setProtect = usePreventNavigateStore(state => state.setProtect);
  const setButtonEnabled = useSaveChangesStore(state => state.setButtonEnabled);
  const navigate = useNavigate();
  const discard = () => {
    navigate('/alerts-and-reports?_=1');
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  // eslint-disable-next-line no-unused-vars
  const [prompt, setDirty, setPristine] = useUnsavedChangesWarning();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [lengthError, setLengthError] = useState({});
  // const [reports, setReports] = useState({state: 'Loading', data: []});
  const [toBeUpdated, setToBeUpdated] = useState(false);
  const [showAlert, setShowAlert] = useState('');
  const scrollToRef = useRef();
  // const userOrgDetails = LocalStorageService.getUserOrg();
  // const trialPeriodStatus = userOrgDetails.trialPeriodStatus;
  // const currentPlan = userOrgDetails.planName;

  useEffect(() => {
    const options = {name: 'New alert'};
    if (params.reportId) {
      options.reportId = params.reportId;
    }
    track('Page View', options);
  }, []);

  const onSave = async () => {
    setShowAlert('');
    let response;
    if (toBeUpdated) {
      response = await updateAlert(state);
    } else {
      response = await createAlert(state);
    }
    if (!response.status) {
      setShowAlert('Something Went Wrong');
    } else {
      setProtect(false);
      isUnchanged(false);
      setButtonEnabled(false);
      navigate('/alerts-and-reports?_=1');
    }
  };
  // const handleCheckBox = id => {
  //   reports.data.forEach(report => {
  //     report.reports.forEach(reqReport => {
  //       if (reqReport._id === id) {
  //         reqReport.isChecked = !reqReport.isChecked;
  //         if (reqReport.isChecked) {
  //           dispatch({type: 'reports', value: reqReport.slag});
  //         } else {
  //           dispatch({type: 'removeFromReports', value: reqReport.slag});
  //         }
  //       }
  //     });
  //   });
  //   setReports({state: 'Success', data: [...reports]});
  // };

  const fetchAlert = async () => {
    let alert = await getAlert(params.id);
    dispatch({type: 'updateState', value: {...alert}});
    setToBeUpdated(true);
  };
  useEffect(() => {
    if (params.id) {
      fetchAlert();
    }
    setPristine();
  }, []);

  useEffect(() => {
    isUnchanged(true);
    setOnDiscard(discard);
    setOnSubmit(onSave);
    setProtect(true);
    if (
      state.emailSubject &&
      state.recipients.length &&
      (((state.schedule.frequency === 'everyDay' || state.schedule.days.length) &&
        (state.schedule.hoursType === 'everyHour' || state.schedule.hours.length)) ||
        state.schedule.frequency === 'whenTriggered') &&
      state.alerts.members
        .map(m => m.members.map(x => x.value?.trim()))
        .flat()
        .filter(x => x === '0' || x === '' || !x).length === 0
    ) {
      setButtonEnabled(true);
    } else {
      setButtonEnabled(false);
    }
    if (state.emailSubject || state.recipients.length) {
      setDirty();
    }
  }, [state]);
  console.log(state);
  return (
    <>
      <Page title="New Alert">
        <Box paddingBlockEnd={'4'}>
          <Layout>
            <Layout.Section>
              <Card sectioned>
                <FormLayout>
                  <FormLayout.Group condensed>
                    <TextField
                      placeholder={'Enter a suitable name for alert'}
                      onChange={e => {
                        if (e.length < 150) {
                          dispatch({type: 'emailSubject', value: e});
                          lengthError.sub = false;
                          setLengthError(lengthError);
                        } else if (e.length === 150) {
                          dispatch({type: 'emailSubject', value: e});
                          lengthError.sub = true;
                          setLengthError(lengthError);
                        }
                      }}
                      disabled={state.user === 'Retainwise'}
                      label="Alert name"
                      type="text"
                      autoComplete="email"
                      error={lengthError.sub && 'Max length of 150 characters reached'}
                      value={state.emailSubject}
                    />
                    <TextField
                      placeholder={'Enter a suitable description for alert'}
                      onChange={e => {
                        if (e.length < 500) {
                          dispatch({type: 'description', value: e});
                          lengthError.des = false;
                          setLengthError(lengthError);
                        } else if (e.length === 500) {
                          dispatch({type: 'description', value: e});
                          lengthError.des = true;
                          setLengthError(lengthError);
                        }
                      }}
                      disabled={state.user === 'Retainwise'}
                      label="Description(optional)"
                      type="text"
                      autoComplete="email"
                      error={lengthError.des && 'Max length of 150 characters reached'}
                      value={state.description}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Card>
              <Card title="Recipients" sectioned>
                <FormLayout>
                  <TextField
                    placeholder={'example@example.com'}
                    onChange={e => {
                      if (e.length < 150) {
                        setEmail(e);
                        lengthError.email = false;
                        setLengthError(lengthError);
                      } else if (e.length === 150) {
                        setEmail(e);
                        lengthError.email = true;
                        setLengthError(lengthError);
                      }
                    }}
                    value={email}
                    label="Email"
                    type="email"
                    autoComplete="email"
                    onBlur={e => {
                      if (state.recipients.includes(email)) {
                        setEmailError('This email id already exists');
                      } else if (emailValidator(email)) {
                        setEmailError('');
                        setEmail('');
                        dispatch({type: 'recipients', value: email});
                      } else {
                        setEmailError('Please enter a valid email address');
                      }
                    }}
                    error={
                      emailError
                        ? emailError
                        : lengthError.email
                        ? 'Max length of 150 characters reached'
                        : ''
                    }
                  />
                  <Inline>
                    {state.recipients.map(recipient => {
                      return (
                        <Tag
                          key={recipient}
                          onRemove={() => {
                            dispatch({
                              type: 'removeFromRecipients',
                              value: recipient,
                            });
                          }}
                        >
                          {recipient}
                        </Tag>
                      );
                    })}
                  </Inline>
                </FormLayout>
              </Card>
              <Card title="Schedule" sectioned>
                <Box>
                  <Inline>
                    <Box width="15%">
                      <ErrorBoundary
                        fallback={
                          <Card>
                            <Card.Section>
                              <div>An error has occured</div>
                            </Card.Section>
                          </Card>
                        }
                      >
                        <SelectComponent
                          label="Frequency"
                          value={state.schedule.frequency}
                          dispatch={dispatch}
                          type="frequency"
                          options={[
                            {label: 'Daily', value: 'everyDay'},
                            // {label: 'Every 6 hours', value: 'every6Hours'},
                            {label: 'Weekly', value: 'daysOfWeek'},
                            // {label: 'When triggered', value: 'whenTriggered'},
                          ]}
                        />
                      </ErrorBoundary>
                    </Box>
                    {state.schedule.frequency === 'daysOfWeek' && (
                      <Box width="15%">
                        <ErrorBoundary
                          fallback={
                            <Card>
                              <Card.Section>
                                <div>An error has occured</div>
                              </Card.Section>
                            </Card>
                          }
                        >
                          <MultiSelectComponent
                            label="Days of the week"
                            value={state.schedule.days}
                            dispatch={dispatch}
                            type="specificDaysOfWeek"
                            options={daysOfWeekInit}
                          />
                        </ErrorBoundary>
                      </Box>
                    )}

                    {state.schedule.frequency === 'everyDay' || state.schedule.days.length ? (
                      <Box width="15%">
                        <ErrorBoundary
                          fallback={
                            <Card>
                              <Card.Section>
                                <div>An error has occured</div>
                              </Card.Section>
                            </Card>
                          }
                        >
                          <MultiSelectComponent
                            label="Time"
                            value={state.schedule.hours}
                            dispatch={dispatch}
                            type="hour"
                            options={specificHourInit}
                            everyHour={state.schedule.hoursType}
                          />
                        </ErrorBoundary>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Inline>
                </Box>
              </Card>
              <Box paddingBlockStart={'4'}>
                {state.alerts.members.map((seg, parentIndex) => {
                  return (
                    <div ref={scrollToRef} key={parentIndex}>
                      <Box>
                        <ErrorBoundary
                          fallback={
                            <Card>
                              <Card.Section>
                                <div>An error has occured</div>
                              </Card.Section>
                            </Card>
                          }
                        >
                          <Card>
                            <Card.Section>
                              <Box paddingBlockStart={'4'} paddingBlockEnd={'4'} width="99%">
                                {seg.members.map((prop, index) => {
                                  return (
                                    <Values
                                      key={index}
                                      parentIndex={parentIndex}
                                      index={index}
                                      state={state}
                                      dispatch={dispatch}
                                      prop={prop}
                                      options={options}
                                      seg={seg}
                                      user={state.user}
                                    />
                                  );
                                })}
                                <Box width="100%">
                                  <Inline align="end" blockAlign="end">
                                    <Button
                                      onClick={() => {
                                        dispatch({type: 'or', value: parentIndex});
                                      }}
                                      disabled={state.user === 'Retainwise'}
                                    >
                                      +OR
                                    </Button>
                                  </Inline>
                                </Box>
                              </Box>
                            </Card.Section>
                          </Card>
                          <Box paddingBlockStart={'8'} paddingBlockEnd={'8'}>
                            <Button
                              primary
                              onClick={() => {
                                dispatch({type: 'and', value: parentIndex});
                              }}
                              disabled={state.user === 'Retainwise'}
                            >
                              +AND
                            </Button>
                          </Box>
                        </ErrorBoundary>
                      </Box>
                    </div>
                  );
                })}
              </Box>
            </Layout.Section>
          </Layout>
        </Box>
        {showAlert ? (
          <Alert contents={showAlert} init={true} error={showAlert === 'Something Went Wrong'} />
        ) : (
          <></>
        )}
      </Page>
    </>
  );
};
export default NewAlert;
