import React, {useEffect, useState} from 'react';
import Page from '../../../components/page';
import {Box, Layout, TextField, Button, Text, Inline} from '@shopify/polaris';
import {track} from '../../../features/track';
import {getCost, updateCost} from '../../../api/cost';
import Alert from '../../../components/alert';
import {getCurrency} from './currencySymbol';
import {LocalStorageService} from '../../../service/localStorage';

const TransactionCost = () => {
  const [transactionCost, setTransactionCost] = useState('');
  const [transactionFee, setTransactionFee] = useState('');
  const [showAlert, setShowAlert] = useState('');
  const currency = LocalStorageService.getUserOrg().currency;

  useEffect(() => {
    getCost().then(response => {
      if (response.status === 200) {
        setTransactionCost(response.data.data?.transactionCost_cost);
        setTransactionFee(response.data.data?.transactionCost_fee);
      } else {
        setTransactionCost(0);
        setTransactionFee(0);
      }
    });
  }, []);

  const handleSaveChanges = () => {
    // Add logic to save changes in valueType
    const params = {transactionCost_cost: transactionCost, transactionCost_fee: transactionFee};
    updateCost(params)
      .then(() => {
        setShowAlert('Settings updated successfully.');
      })
      .catch(err => {
        setShowAlert('Error updating the settings. Please try again later.');
      });
  };

  useEffect(() => {
    track('Page View', {
      name: 'TransactionCosts',
    });
  }, []);

  return (
    <>
      <Page title={'Transaction costs'}>
        <Box paddingBlockStart={'4'}>
          <Layout>
            <Layout.Section>
              <Text as="p" color="subdued">
                Transaction costs are processing fees paid to payment providers. Please specify your
                average payment gateway costs below.
              </Text>
            </Layout.Section>
            <Layout.Section>
              <Inline>
                <div style={{width: '20%'}}>
                  <Text variant="bodyXs" as="p" color="subdued">
                    Transaction Cost per order (fixed)
                  </Text>
                </div>
                <Box width="20%">
                  <TextField
                    type="number"
                    placeholder="Enter value"
                    suffix="%"
                    value={transactionCost}
                    onChange={value => setTransactionCost(value)}
                  />
                </Box>
              </Inline>
            </Layout.Section>
            <Layout.Section>
              <Inline>
                <div style={{width: '20%'}}>
                  <Text variant="bodyXs" as="p" color="subdued">
                    Transaction Cost per order (variable)
                  </Text>
                </div>

                <Box width="20%">
                  <TextField
                    type="number"
                    placeholder="Enter value"
                    suffix={getCurrency(currency)}
                    value={transactionFee}
                    onChange={value => setTransactionFee(value)}
                  />
                </Box>
              </Inline>
            </Layout.Section>
            <Layout.Section>
              <Button primary onClick={handleSaveChanges}>
                Save changes
              </Button>
            </Layout.Section>
          </Layout>
        </Box>
        {showAlert && (
          <Alert
            contents={showAlert}
            init={true}
            error={showAlert === 'Something Went Wrong'}
            duration={10000}
          />
        )}
      </Page>
    </>
  );
};

export {TransactionCost};
