import {Frame} from '@shopify/polaris';
import React, {useEffect, useState} from 'react';
import NewProductGroup from './newProductGroup';
import MyNavigation from '../../components/navigation';
import SaveBar from '../../components/saveBar';
import {useSaveChangesStore} from '../../app/store';
import {useParams, useSearchParams} from 'react-router-dom';
const CreateProductGroup = ({edit}) => {
  const isUnchanged = useSaveChangesStore(state => state.isUnchanged);
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [stage, setStage] = useState(+searchParams.get('stage') || 1);
  const saveButtonText = stage > 1 ? 'Save' : 'Next';
  const discardButtonText = stage > 1 ? 'Back' : 'Discard';
  const isModalDisabled = discardButtonText === 'Back';
  const onBack = () => {
    setStage(stage - 1);
  };
  const onNext = () => {
    setStage(stage + 1);
  };

  useEffect(() => {
    setSearchParams({stage});
  }, [stage]);

  return (
    <>
      <Frame
        logo={{
          width: 124,
          contextualSaveBarSource: '/images/logo.svg',
        }}
        topBar={
          isUnchanged ? (
            <SaveBar
              saveButtonText={saveButtonText}
              discardButtonText={discardButtonText}
              isModalDisabled={isModalDisabled}
              onBack={onBack}
              onNext={onNext}
            />
          ) : null
        }
        navigation={<MyNavigation />}
      >
        <NewProductGroup params={params} edit={edit} />
      </Frame>
    </>
  );
};

export default CreateProductGroup;
