import React from 'react';
import Inline from '../../../lib/Inline';
import Card from '../../../lib/Card';
import Text from '../../../lib/Text';
import {Layout, TextField} from '@shopify/polaris';

const OptionalData = ({hearText, setHearText}) => {
  return (
    <Card sectioned full>
      <Layout>
        <Layout.Section>
          <Inline align="space-between">
            <Text variant="headingMd">Optional </Text>
          </Inline>
        </Layout.Section>

        <Layout.Section>
          <div
            style={{
              width: '100%',
              textAlign: 'justify',
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
            }}
          >
            <Text variant="headingSm">How did you hear about us?</Text>
            <TextField onChange={setHearText} value={hearText} multiline={4} fullWidth />
          </div>
        </Layout.Section>
      </Layout>
    </Card>
  );
};

export default OptionalData;
