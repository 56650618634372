import React from 'react';
import Inline from '../../../lib/Inline';
import Stack from '../../../lib/Stack';
import Card from '../../../lib/Card';
import Text from '../../../lib/Text';
import {Checkbox, Grid, Layout} from '@shopify/polaris';

const EmailSummaryData = ({emailSummaryReports, setEmailSummaryReports}) => {
  const valueToLabelMap = {
    'abandoned-cart-value': 'Abandoned Cart Value',
    'net-revenue': 'Net Revenue',
    orders: 'Orders',
    'repeat-customers': 'Repeat Customers',
    customers: 'Customers',
  };
  return (
    <Card sectioned full>
      <Stack vertical alignment="baseline">
        <Layout>
          <Layout.Section>
            <Inline align="space-between">
              <Text variant="headingMd">Email Summary </Text>
            </Inline>
          </Layout.Section>

          <Layout.Section>
            <Stack vertical alignment="baseline">
              <Text variant="headingSm">What weekly summary emails do you need?</Text>

              <Grid>
                {Object.keys(emailSummaryReports).map(key => {
                  return (
                    <Grid.Cell key={key} columnSpan={{xs: 6, sm: 6, md: 6, lg: 4, xl: 4}}>
                      <div style={{display: 'flex', textAlign: 'left'}}>
                        <Checkbox
                          label={valueToLabelMap[key]}
                          checked={emailSummaryReports[key]}
                          onChange={() => {
                            setEmailSummaryReports({
                              ...emailSummaryReports,
                              [key]: !emailSummaryReports[key],
                            });
                          }}
                        />
                      </div>
                    </Grid.Cell>
                  );
                })}
              </Grid>
            </Stack>
          </Layout.Section>
        </Layout>
      </Stack>
    </Card>
  );
};

export default EmailSummaryData;
