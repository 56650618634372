const config = {
  BASE_URL: process.env.REACT_APP_ENV === 'production' ? '/rest' : 'http://localhost:8000/rest',
  START_OF_WEEK: 1,
  DEBOUNCING_DELAY: 50,
  GRAPH_COLOR: '#3E55FF',
  ALERT_TIMEOUT: 2000,
  SEARCH_DELAY: 350,
  TAWK_PROPERTY_ID: '650d73e4b1aaa13b7a784e1c',
  TAWK_WIDGET_ID: '1hau8pe1t',
};

export default config;
