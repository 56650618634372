import {Text, Icon} from '@shopify/polaris';
import Card from '../../../../../lib/Card';
import Inline from '../../../../../lib/Inline';
import React, {useCallback, useEffect, useState} from 'react';
import DefinationPopover from '../../../../../components/definationPopover';
import {Draggable} from 'react-beautiful-dnd';
import {CircleDownMajor, CircleUpMajor} from '@shopify/polaris-icons';
import apiToCall from '../../../../../features/apiToCall';
import DefaultLineGraph from '../../../../../components/graphs/defaultLineGraph';
import DashboardTable from '../../dashboardTable';
import CohortTable from '../../cohortTable';
import {
  formatAmount,
  formatDate,
  formatDecimalNumber,
  formatNumber,
} from '../../../../../features/format';
import {toClickHouseDate} from '../../../../../features/convertDates';
import {tooltipText} from '../../tooltipText';
import Box from '../../../../../lib/Box';
import Stack from '../../../../../lib/Stack';
const DraggableCard = ({
  item,
  index,
  selectedDates,
  selectedCompareDates,
  durationFilter,
  slag,
  columns,
  setColumns,
  columnId,
  setButtonEnabled,
  data,
  error,
}) => {
  const [headerPopoverActive, setHeaderPopoverActive] = useState(false);
  const [midPopoverActive, setMidPopoverActive] = useState(false);
  const headerTogglePopoverActive = useCallback(
    () => setHeaderPopoverActive(headerPopoverActive => !headerPopoverActive),
    []
  );
  const midToggelPopoverActive = useCallback(
    () => setMidPopoverActive(midPopoverActive => !midPopoverActive),
    []
  );

  const activatorHeader = (
    <Box>
      <Text variant="bodyMd">
        <span
          onMouseEnter={headerTogglePopoverActive}
          onMouseLeave={() => {
            setHeaderPopoverActive(false);
          }}
          as="span"
          style={{borderBottom: '2px dotted #c4cdd5', cursor: 'default'}}
        >
          {apiToCall[slag].isSnapshotSpecial
            ? apiToCall[slag].titleSnapshot
            : apiToCall[slag].title}
        </span>
      </Text>
    </Box>
  );
  const activatorCohort = (
    <Box paddingBlockEnd={'2'}>
      <Text variant="headingSm">
        <span
          onMouseEnter={midToggelPopoverActive}
          onMouseLeave={() => {
            setMidPopoverActive(false);
          }}
          style={{borderBottom: '2px dotted #c4cdd5', cursor: 'default'}}
        >
          Last 12 months
        </span>
      </Text>
    </Box>
  );
  const activatorMid = (
    <Box paddingBlockEnd={'1'}>
      <Text variant="bodySm">
        <span
          onMouseEnter={midToggelPopoverActive}
          onMouseLeave={() => {
            setMidPopoverActive(false);
          }}
          style={{borderBottom: '2px dotted #c4cdd5', cursor: 'default'}}
        >
          {apiToCall[slag].taskText}
        </span>
      </Text>
    </Box>
  );

  return (
    <Draggable key={item._id} draggableId={item._id} index={index}>
      {provided => (
        <div
          style={{display: 'flex'}}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div style={{marginBottom: '1rem'}}>
            <Card>
              <Card.Section
                actions={[
                  {
                    content: 'Delete',
                    destructive: true,
                    onAction: () => {
                      let deletedIndex;
                      columns[columnId].forEach((a, index) => {
                        if (a._id === item._id) {
                          deletedIndex = index;
                        }
                      });
                      if (deletedIndex !== undefined) {
                        columns[columnId].splice(deletedIndex, 1);
                        setColumns({...columns});
                        setButtonEnabled(true);
                      }
                    },
                  },
                ]}
              >
                <Stack vertical spacing="tight">
                  <Stack.Item>
                    <Inline align="space-between">
                      <DefinationPopover
                        togglePopoverActive={headerTogglePopoverActive}
                        popoverActive={tooltipText[slag] ? headerPopoverActive : false}
                        activator={activatorHeader}
                        text={tooltipText[slag]}
                      />
                    </Inline>
                  </Stack.Item>
                  {data.blank ? (
                    <Stack.Item>
                      <Inline align="center">
                        <Text color="subdued">There was no data found for this date range.</Text>
                      </Inline>
                    </Stack.Item>
                  ) : error ? (
                    <Stack.Item>
                      <Inline align="center">
                        <Text color="subdued">Some error occured</Text>
                      </Inline>
                    </Stack.Item>
                  ) : data.state === 'Error' ? (
                    <Stack.Item>
                      <Inline align="center">
                        <Text color="subdued">Not able to load data. Please try again later</Text>
                      </Inline>
                    </Stack.Item>
                  ) : (
                    <Stack.Item>
                      <Stack vertical>
                        {apiToCall[slag].graph === 'line' &&
                          slag !== 'customer-cohort' &&
                          slag !== 'revenue-cohort' &&
                          (typeof apiToCall[slag].snapshotSpecialApi === 'object' ||
                            !apiToCall[slag].isSnapshotSpecial) &&
                          !error && (
                            <Stack.Item>
                              <Inline align="space-between">
                                <Text variant="headingLg">
                                  {apiToCall[slag].symbol
                                    ? formatAmount({value: data.total})
                                    : slag === 'items-per-order' || slag === 'returns'
                                    ? formatDecimalNumber({value: data.total || 0})
                                    : formatNumber({value: data.total})}
                                  {slag === 'returns' ? '%' : ''}
                                </Text>
                                {!!selectedCompareDates.start && (
                                  <Inline align="end" gap="1">
                                    <Icon
                                      color={data.indicatorColor}
                                      source={
                                        data.indicatorColor === 'success'
                                          ? CircleUpMajor
                                          : CircleDownMajor
                                      }
                                    />

                                    <Text color={data.indicatorColor} variant="headingMd">
                                      {formatNumber({value: data.indicatorPercentage})}%
                                    </Text>
                                  </Inline>
                                )}
                              </Inline>
                            </Stack.Item>
                          )}

                        {apiToCall[slag].graph === 'line' &&
                          slag !== 'customer-cohort' &&
                          slag !== 'revenue-cohort' &&
                          !apiToCall[slag].isSnapshotSpecial && (
                            <Stack.Item>
                              <DefinationPopover
                                togglePopoverActive={midToggelPopoverActive}
                                popoverActive={midPopoverActive}
                                activator={activatorMid}
                                text={`This chart shows total ${
                                  apiToCall[slag].taskText
                                } from ${formatDate({
                                  value: new Date(selectedDates.start),
                                  removeTime: true,
                                })} to ${formatDate({
                                  value: new Date(selectedDates.end),
                                  removeTime: true,
                                })} ${
                                  selectedCompareDates.start
                                    ? `compared to ${formatDate({
                                        value: new Date(selectedCompareDates.start),
                                        removeTime: true,
                                      })} to ${formatDate({
                                        value: new Date(selectedCompareDates.end),
                                        removeTime: true,
                                      })}`
                                    : ''
                                }.`}
                              />
                            </Stack.Item>
                          )}
                        <Stack.Item>
                          {slag === 'customer-cohort' || slag === 'revenue-cohort' ? (
                            <>
                              <CohortTable data={data.data} />
                              {data.state !== 'Error' && (
                                <Box paddingBlockStart={'4'}>
                                  <Inline align="space-between" blockAlign="start">
                                    <DefinationPopover
                                      togglePopoverActive={midToggelPopoverActive}
                                      popoverActive={midPopoverActive}
                                      activator={activatorCohort}
                                      text={`Changing the time period will not affect this chart. Cohort analysis requires a minimum time period.
                                `}
                                    />
                                    <Text>
                                      {new Date().toLocaleString('default', {
                                        month: 'long',
                                      })}
                                      ,{new Date().getFullYear() - 1} -{' '}
                                      {new Date().toLocaleString('default', {
                                        month: 'long',
                                      })}
                                      ,{new Date().getFullYear()}
                                    </Text>
                                  </Inline>
                                </Box>
                              )}
                            </>
                          ) : apiToCall[slag].isSnapshotSpecial ? (
                            <>
                              <DashboardTable
                                tabData={data.data}
                                param={{name: slag}}
                                symbol={apiToCall[slag].symbol}
                              />
                            </>
                          ) : apiToCall[slag].graph === 'line' ? (
                            <DefaultLineGraph
                              param={{name: slag}}
                              height={200}
                              data={data.data.length ? data.data : []}
                              startDate={selectedDates.start}
                              endDate={selectedDates.end}
                              compareStartDate={
                                selectedCompareDates.start !== 'Invalid Date'
                                  ? toClickHouseDate(selectedCompareDates.start, 'start')
                                  : null
                              }
                              compareEndDate={
                                selectedCompareDates.start !== 'Invalid Date'
                                  ? toClickHouseDate(selectedCompareDates.end, 'end')
                                  : null
                              }
                              symbol={apiToCall[slag].symbol}
                              durationFilter={durationFilter}
                              state={data.state}
                            />
                          ) : (
                            <DashboardTable
                              tabData={data.data}
                              param={{name: slag}}
                              symbol={apiToCall[slag].symbol}
                            />
                          )}
                        </Stack.Item>
                      </Stack>
                    </Stack.Item>
                  )}
                </Stack>
              </Card.Section>
            </Card>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default DraggableCard;
