import React, {useCallback, useEffect, useState} from 'react';
import Page from '../../components/page';
import Box from '../../lib/Box';
import {Spinner, Tabs} from '@shopify/polaris';
import {track} from '../../features/track';
import {products} from '../../api/productProfile';
import {
  atRiskColumns,
  bestSellersColumns,
  fixNowColumns,
  outOfStockColumns,
  productsColumns,
  profitablityColumns,
  risingStarsColumns,
} from '../metrics/columns';
import {bestSellers} from '../../api/reports';
import RenderData from './RenderData';
import {atRisk, fixNow, outOfStock, profitMargin, risingStars} from '../../api/productModule';
import {useSearchParams} from 'react-router-dom';

const tabs = [
  {
    id: 'listing',
    content: 'Listing',
    accessibilityLabel: 'listing',
    panelID: 'listing-content-1',
  },
  {
    id: 'bestsellers',
    content: 'Bestsellers',
    accessibilityLabel: 'bestsellers',
    panelID: 'bestsellers-content-1',
  },
  {
    id: 'outOfStockAndLostSales',
    content: 'Out of Stock and Lost Sales',
    accessibilityLabel: 'outOfStockAndLostSales',
    panelID: 'outOfStockAndLostSales-content-1',
  },
  {
    id: 'risingStars',
    content: 'Rising Stars',
    accessibilityLabel: 'risingStars',
    panelID: 'risingStars-content-1',
  },
  {
    id: 'profitability',
    content: 'Profitability',
    accessibilityLabel: 'profitability',
    panelID: 'profitability-content-1',
  },
  {
    id: 'atRisk',
    content: 'At Risk',
    accessibilityLabel: 'atRisk',
    panelID: 'atRisk-content-1',
  },
  {
    id: 'fixNow',
    content: 'Fix Now!',
    accessibilityLabel: 'fixNow',
    panelID: 'fixNow-content-1',
  },
];

const tabMap = {
  listing: {
    columns: productsColumns,
    api: products,
    defaultSortBy: 'netItemsSold',
    defaultSortOrder: 'desc',
    dateRequired: true,
    searchRequired: true,
    sortRequired: true,
    paginationRequired: true,
    index: 0,
  },
  bestsellers: {
    columns: bestSellersColumns,
    api: bestSellers,
    defaultSortBy: 'netRevenue',
    defaultSortOrder: 'desc',
    dateRequired: true,
    searchRequired: true,
    sortRequired: true,
    paginationRequired: true,
    index: 1,
  },
  risingStars: {
    columns: risingStarsColumns,
    api: risingStars,
    defaultSortBy: 'conversionRate',
    defaultSortOrder: 'desc',
    text: 'Displays 30 products with more than 500 views in the last 7 days (or based on available web pixel data). Excludes the top 30 bestsellers, where the average conversion rate of these bestsellers is lower than that of the rising stars.',
    index: 3,
  },
  fixNow: {
    columns: fixNowColumns,
    api: fixNow,
    defaultSortBy: 'conversionRate',
    defaultSortOrder: 'asc',
    text: '30 products with 500+ views, sorted by lowest conversion rates, highlighting those needing immediate attention.',
    index: 6,
  },
  profitability: {
    columns: profitablityColumns,
    api: profitMargin,
    defaultSortBy: 'profitMargin',
    defaultSortOrder: 'desc',
    paginationRequired: true,
    text: 'Products sorted by descending profit margin, displaying the most profitable items.',
    index: 4,
  },
  atRisk: {
    columns: atRiskColumns,
    api: atRisk,
    defaultSortBy: 'expectedStockOut',
    defaultSortOrder: 'asc',
    paginationRequired: true,
    text: 'Products nearing out-of-stock status based on daily sales and inventory.',
    index: 5,
  },
  outOfStockAndLostSales: {
    columns: outOfStockColumns,
    api: outOfStock,
    defaultSortBy: 'lostSales',
    defaultSortOrder: 'desc',
    paginationRequired: true,
    text: 'Products that sold more than 1 unit daily in the last 7 days of the previous month but are now out of stock, showing potential lost sales.',
    index: 2,
  },
};
const ProductModule = () => {
  const [sortBy, setSortBy] = useState('netItemsSold');
  const [sortOrder, setSortOrder] = useState('desc');
  const [queryValue, setQueryValue] = useState('');
  const [page, setPage] = useState(1);
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab') ? tabMap[searchParams.get('tab')].index : 0;
  const [selected, setSelected] = useState(tab || 0);

  useEffect(() => {
    track('Page View', {
      name: 'Product Module',
    });
  }, []);

  const handleTabChange = useCallback(selectedTabIndex => {
    // setLoading(true);
    setSelected(selectedTabIndex);
    setPage(1);
    setSortBy(tabMap[tabs[selectedTabIndex].id].defaultSortBy);
    setSortOrder(tabMap[tabs[selectedTabIndex].id].defaultSortOrder);
  }, []);

  return (
    <Page title="Product Module">
      <Box>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          <Box paddingBlockStart={'8'}>
            <RenderData
              page={page}
              sortBy={sortBy}
              sortOrder={sortOrder}
              queryValue={queryValue}
              tabMap={tabMap}
              setQueryValue={setQueryValue}
              setPage={setPage}
              selectedTabId={tabs[selected].id}
              setSortBy={setSortBy}
              setSortOrder={setSortOrder}
            />
          </Box>
        </Tabs>
      </Box>
    </Page>
  );
};

export default ProductModule;
