import React, {useEffect, useState} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import {getProductNps, submitProductNps} from '../../../api/npsDetails';
import './style.css';
import ProductRating from '../../../components/rating';
import {LocalStorageService} from '../../../service/localStorage';
import {Stack} from '@shopify/polaris';

const ProductNPS = ({fromTestEmail}) => {
  let params = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  // setSearchParams({submission: '1'});
  console.log(searchParams.get('submission'), fromTestEmail);
  const npsId = params.npsId;
  const [productData, setProductData] = useState([]);
  const [feedback, setFeedback] = useState({});
  const [responseReceived, setResponseReceived] = useState(
    fromTestEmail && searchParams.get('submission') === '1' ? true : false
  );

  useEffect(() => {
    if (fromTestEmail) {
      const userOrgDetails = LocalStorageService.getUserOrg();
      const testProductData = [
        {
          title: 'Noise Cancelling Wireless Headphones',
          imageSrc:
            'https://github.com/Karan0039/host_img/blob/main/levitating-music-headphones-display.jpg?raw=true',
          price: 299.99,
          orderedAt: '01/01/2024',
          productId: '0011223344556',
          lineItemId: 'a1b2c3d4e5f6g7h8i9j0k1l2',
          orgName: 'Shop Local',
          orderName: '#4321',
          storeLogo: 'https://github.com/Karan0039/host_img/blob/main/7660092.jpg?raw=true',
        },
        {
          title: 'Smartwatch Series 5',
          imageSrc: 'https://github.com/Karan0039/host_img/blob/main/3794066.jpg?raw=true',
          price: 199.99,
          orderedAt: '01/01/2024',
          productId: '9988776655443',
          lineItemId: 'l2k1j0i9h8g7f6e5d4c3b2a1',
          orgName: 'Shop Local',
          orderName: '#8765',
          storeLogo: 'https://github.com/Karan0039/host_img/blob/main/7660092.jpg?raw=true',
        },
      ];

      setProductData(testProductData);
    } else {
      getProductNps(npsId)
        .then(response => {
          if (response.responseReceived) {
            setResponseReceived(true);
          } else if (response.status) {
            setProductData(response.data);
            console.log(response.data);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, []);

  const handleSubmit = () => {
    if (fromTestEmail) {
      setSearchParams({submission: '1'});
      setResponseReceived(true);
    } else {
      submitProductNps(feedback, npsId)
        .then(response => {
          if (response.status) {
            setResponseReceived(true);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <div style={{backgroundColor: '#ffffff', height: '100vh'}}>
        {!responseReceived && productData.length > 0 && (
          <header
            style={{textAlign: 'center', padding: '20px 0', fontWeight: 'bold', fontSize: '50px'}}
          >
            <img
              src={productData[0].storeLogo}
              alt={productData[0].orgName}
              style={{maxWidth: '150px', maxHeight: '80px', height: 'auto', width: 'auto'}}
            />
          </header>
        )}
        <div>
          {!responseReceived && productData.length > 0 && (
            <div id="survey-container">
              <h2
                style={{
                  paddingBottom: '10px',
                  textAlign: 'center',
                  fontSize: '20px',
                  fontWeight: 'bold',
                }}
              >
                How likely are you to recommend {productData[0].orgName} to a friend or colleague?
              </h2>

              {productData.map((x, i) => (
                <ProductRating
                  key={i}
                  setFeedback={setFeedback}
                  feedback={feedback}
                  productData={productData[i]}
                />
              ))}
            </div>
          )}

          {!responseReceived && productData.length > 0 && (
            <div style={{textAlign: 'center'}}>
              <button className="cta-button" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          )}

          {!!responseReceived && (
            <div id="success-message" style={{textAlign: 'center', padding: '20px'}}>
              <h2>Thank You for Your Feedback!</h2>
              <p>Your response has been recorded.</p>
            </div>
          )}

          <footer
            style={{
              textAlign: 'center',
              padding: '20px 0',
              fontSize: '12px',
              color: '#a0a0a0',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            powered by{' '}
            <a
              href="https://www.retainwise.io"
              target="_blank"
              rel="noreferrer"
              style={{
                textAlign: 'center',
                fontSize: '12px',
                color: '#a0a0a0',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <img
                src="https://app.retainwise.io/images/logo.svg"
                height="16px"
                className="footerlogo"
                alt="retainwise"
              />
            </a>
          </footer>
          {fromTestEmail ? (
            <div
              style={{
                textAlign: 'center',
                fontSize: '12px',
                color: '#a0a0a0',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              This page contains sample data for demonstration purposes only.
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export {ProductNPS};
