import React, {useEffect} from 'react';
import {
  TextField,
  Popover,
  Button,
  Text,
  Scrollable,
  Box,
  EmptySearchResult,
  Inline,
  OptionList,
} from '@shopify/polaris';
import {useState, useCallback} from 'react';
import {getTransformedProductTitle} from '../../../features/getTransformedProductTitle';

function ExcludeVariants({selectedVariants, setSelectedVariants, productList, query, setQuery}) {
  const handleTextFieldChange = useCallback(value => setQuery(value), []);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [selected, setSelected] = useState(selectedVariants.length > 0 ? selectedVariants : []);

  useEffect(() => {
    setSelected(selectedVariants);
  }, [pickerOpen]);
  const handleOpenPicker = () => {
    setPickerOpen(!pickerOpen);
  };
  const activator = (
    <div>
      <Button
        fullWidth
        textAlign="left"
        disclosure={pickerOpen ? 'up' : 'down'}
        onClick={handleOpenPicker}
      >
        Exclude Variants{selectedVariants.length > 0 ? `(${selectedVariants.length})` : ''}
      </Button>
    </div>
  );

  const handleClear = () => {
    setQuery('');
  };

  const handleClosePicker = () => {
    setPickerOpen(false);
    setQuery('');
  };

  const handleApply = () => {
    setSelectedVariants(selected);
    handleClosePicker();
  };

  const clearAll = () => {
    setSelected([]);
    setSelectedVariants([]);
    setPickerOpen(!pickerOpen);
  };

  const textFieldMarkup = (
    <div style={{padding: '12px'}}>
      <TextField
        clearButton
        labelHidden
        placeholder="Search sku/variant"
        autoComplete="off"
        value={query}
        onChange={handleTextFieldChange}
        onClearButtonClick={handleClear}
      />
    </div>
  );

  const productOptions = productList;

  const productItems = productOptions.map(({title, sku, variantTitle, variantId}) => {
    const tranformedTitle = getTransformedProductTitle({sku, variantTitle, title});
    return {label: tranformedTitle, value: variantId};
  });

  const noResultsMarkup =
    productOptions.length === 0 ? (
      <EmptySearchResult title="" description={`No products found`} />
    ) : null;
  const verticalContentMarkup = (
    <OptionList allowMultiple options={productItems} selected={selected} onChange={setSelected} />
  );
  return (
    <Popover
      active={pickerOpen}
      activator={activator}
      ariaHaspopup="listbox"
      preferredAlignment="left"
      preferredPosition="below"
      autofocusTarget="first-node"
      onClose={handleClosePicker}
      fluidContent
    >
      <Popover.Pane>
        <div
          style={{
            alignItems: 'stretch',
            borderTop: '1px solid #DFE3E8',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'stretch',
            position: 'relative',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          {textFieldMarkup}
          <Scrollable
            shadow
            style={{
              position: 'relative',
              height: '292px',
              padding: 'var(--p-space-200) 0',
              borderBottomLeftRadius: 'var(--p-border-radius-200)',
              borderBottomRightRadius: 'var(--p-border-radius-200)',
            }}
          >
            {verticalContentMarkup}
            {noResultsMarkup}
          </Scrollable>
        </div>
      </Popover.Pane>
      <Popover.Pane fixed>
        <Box padding={'3'}>
          <Inline align="end">
            <Button onClick={clearAll}>Clear all</Button>
            <Button onClick={handleApply} primary>
              Apply
            </Button>
          </Inline>
        </Box>
      </Popover.Pane>
    </Popover>
  );
}

export default ExcludeVariants;
