import React from 'react';
import Page from '../../components/page';
import ShopifyData from './components/ShopifyData';
import OnboardingWizard from './components/Wizard';
import Box from '../../lib/Box';
import {track} from '../../features/track';
const Dash = () => {
  track('Page View', {
    name: 'Fetch Dashboard',
  });
  return (
    <>
      <Page narrowWidth>
        <Box paddingBlockEnd="4">
          <ShopifyData />
        </Box>

        <Box paddingBlockEnd="4">
          <OnboardingWizard />
        </Box>
      </Page>
    </>
  );
};
export {Dash};
