import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import DefaultTable from '../../../../components/defaultIndexTable';
import apiToCall from '../../../../features/apiToCall';
import useApiCall from '../../../../hooks/useApiCall';

const Variants = ({setSelected}) => {
  const [sortBy, setSortBy] = useState('netItemsSold');
  const [sortOrder, setSortOrder] = useState('desc');
  const [queryValue, setQueryValue] = useState('');
  const [page, setPage] = useState(1);

  const params = useParams();
  const fetchVariants = async () => {
    let _params = {
      variantId: params.variantId,
      sortBy,
      sortOrder,
      page,
      search: queryValue,
      type: 'table',
    };
    let res = await apiToCall['products'].api({params: _params});
    return res.data;
  };
  const variantsApiDependencies = [sortBy, sortOrder, page, queryValue];
  const variants = useApiCall(fetchVariants, variantsApiDependencies);

  return (
    <>
      <DefaultTable
        sortBy={sortBy}
        setSortBy={setSortBy}
        sortExceptions={[...apiToCall['products'].sortExecptions]}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        select={false}
        queryValue={queryValue}
        setQueryValue={setQueryValue}
        page={page}
        setPage={setPage}
        resourceName={{
          singular: 'metrics',
          plural: 'metrics',
        }}
        columns={apiToCall['products'].columns}
        selectedFilters={[]}
        totalRows={variants.data.length ? +variants.data[0].totalRows : 0}
        tableData={variants.data.length > 0 ? variants.data : []}
        state={variants.state}
        param={{name: 'products'}}
        queryPlaceholder="Filter sku , title , category or brand of product"
        setSelected={setSelected}
      />
    </>
  );
};

export default Variants;
