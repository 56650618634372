import React, {useEffect} from 'react';
import {Popover, ActionList, Text, ChoiceList, OptionList} from '@shopify/polaris';
import {useState, useCallback} from 'react';
import Button from '../../../../lib/Button';
const options = [
  {label: 'SKU', value: 'sku'},
  // {label: 'Variant Id', value: 'variantId'},
  // {label: 'CustomerId', value: 'customerId'},
];
const ExcludeSelect = ({dispatch, indexes, value, disabled, handle}) => {
  const [popoverActive, setPopoverActive] = useState(false);
  const [selected, setSelected] = useState(value ? [value] : []);
  const [name, setName] = useState('');

  const togglePopoverActive = useCallback(
    () => setPopoverActive(popoverActive => !popoverActive),
    []
  );

  const activator = (
    <Button
      disabled={disabled}
      fullWidth
      textAlign="left"
      disclosure={popoverActive ? 'up' : 'down'}
      onClick={togglePopoverActive}
    >
      <Text color="subdued">{selected.length ? name : 'Select Field'}</Text>
    </Button>
  );

  useEffect(() => {
    if (!value) {
      setName('Select Field');
    }
  }, [value]);
  useEffect(() => {
    let n = '';
    options.forEach(a => {
      if (a.value === selected[0]) {
        n = a.label;
      }
    });
    setName(n);

    handle(selected);
  }, [selected]);
  return (
    <div>
      <Popover
        active={popoverActive}
        activator={activator}
        autofocusTarget="first-node"
        onClose={togglePopoverActive}
        preferredPosition="below"
        fullWidth
      >
        <Popover.Pane>
          <OptionList
            onChange={e => {
              setSelected(e);
              togglePopoverActive();
            }}
            options={options}
            selected={selected}
          />
        </Popover.Pane>
      </Popover>
    </div>
  );
};

export default ExcludeSelect;
