import React from 'react';
import Inline from '../../../../lib/Inline';
import './index.css';
import {formatAmount} from '../../../../features/format';
import Box from '../../../../lib/Box';
import Text from '../../../../lib/Text';
import Stack from '../../../../lib/Stack';
const OrderedProduct = ({img, quantity, name, sku, price}) => {
  return (
    <>
      <Box paddingBlockStart={'5'}>
        <Inline align="space-between">
          <Inline>
            <div className="base">
              <img
                alt="#"
                width={'80px'}
                src={
                  img ||
                  'https://user-images.githubusercontent.com/133949320/244652984-236bc15c-cc91-4ac0-ba33-1333c0d3ae5c.png'
                }
              />
              <div className="indicator">
                <div className="noti_count">{quantity}</div>
              </div>
            </div>

            <Stack vertical spacing="extraTight">
              <Text variant="bodyLg">{name}</Text>
              <Text variant="bodySm" color="subdued">
                {sku}
              </Text>
            </Stack>
          </Inline>
          <Text variant="bodyLg" color="subdued">
            {formatAmount({value: price})}
          </Text>
        </Inline>
      </Box>
    </>
  );
};

export default OrderedProduct;
