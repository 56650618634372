import React, {useEffect, useState} from 'react';
import Page from '../../../components/page';
import {Box, Layout, TextField, Checkbox, Button, Text} from '@shopify/polaris';
import {track} from '../../../features/track';
import {getCost, updateCost} from '../../../api/cost';
import Alert from '../../../components/alert';
import {PlusMinor, DeleteMinor} from '@shopify/polaris-icons';
import {getProductCategoryList} from '../../../api/filterLists';
import FilterDropDown from '../../../components/FilterDropdown';
import MultiSelect from '../../../components/multiSelect';
import CategoryList from '../../../components/categorylist';

const DefaultCogsMargin = () => {
  const [defaultMargin, setDefaultMargin] = useState('');
  const [useShopifyCost, setUseShopifyCost] = useState(false);
  const [showAlert, setShowAlert] = useState('');
  const [useDefaultCostOnly, setUseDefaultCostOnly] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [fields, setFields] = useState([{category: '', value: 0}]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    getCost().then(response => {
      if (response.status === 200) {
        setDefaultMargin(response.data.data?.cogs_value);
        setUseShopifyCost(response.data.data?.cogs_useShopifyCost);
        setUseDefaultCostOnly(response.data.data?.cogs_useDefaultCostOnly);
        setFields(response.data.data?.cogs_categoryCost);
      } else {
        setDefaultMargin(30);
        setUseShopifyCost(false);
      }
    });
  }, []);

  useEffect(() => {
    let params = {format: true};
    if (search !== '') {
      params.search = search;
    }
    getProductCategoryList({params}).then(res => {
      setCategoryList(res.data.result);
    });
  }, [search]);

  const handleSaveChanges = () => {
    // Add logic to save changes in valueType
    const params = {
      cogs_valueType: 'percent',
      cogs_value: defaultMargin,
      // cogs_isApplicableOnDiscountPrice: useShopifyCost,
      cogs_useShopifyCost: useShopifyCost,
      cogs_useDefaultCostOnly: useDefaultCostOnly,
      cogs_categoryCost: fields,
    };
    updateCost(params)
      .then(() => {
        setShowAlert('Settings updated successfully.');
      })
      .catch(err => {
        setShowAlert('Error updating the settings. Please try again later.');
      });
  };

  useEffect(() => {
    track('Page View', {
      name: 'DefaultCogsMargin',
    });
  }, []);

  const addCategory = () => {
    setFields([...fields, {category: '', value: ''}]);
  };

  const removeCategory = index => {
    setFields(fields.filter((_, i) => i !== index));
  };

  const handleCategoryChange = (index, newValue) => {
    const newFields = [...fields];
    newFields[index].category = newValue;
    setFields(newFields);
  };

  const handleValueChange = (index, newValue) => {
    const newFields = [...fields];
    newFields[index].value = newValue;
    setFields(newFields);
  };

  const getFilteredOptions = index => {
    const selectedCategories = fields
      .map((field, i) => (i !== index ? field.category : null))
      .filter(Boolean);
    return categoryList.filter(category => !selectedCategories.includes(category.value));
  };

  return (
    <>
      <Page title={'COGS'}>
        <Box paddingBlockStart={'4'}>
          <Layout>
            <Layout.Section>
              <Text as="p" color="subdued">
                For COGS, you can opt for the values recorded in Shopify or specify a default cost
                across products or categories. Default cost will apply automatically if product cost
                in Shopify isn't specified or is zero. Default cost is a percentage of the
                non-discounted selling price. The discount considered are checkout discounts and not
                the price variance between the selling price and "compare at" price.
              </Text>
            </Layout.Section>
            <Layout.Section>
              <div style={{display: 'flex'}}>
                <div style={{paddingTop: '7px', paddingRight: '15px', width: '10%'}}>
                  <Text variant="bodyXs" as="p" color="subdued">
                    Default Cost
                  </Text>
                </div>
                <Box width="20%">
                  <TextField
                    type="number"
                    placeholder="Enter value"
                    suffix="%"
                    value={defaultMargin}
                    onChange={value => setDefaultMargin(value)}
                  />
                </Box>
                <div style={{paddingTop: '7px', paddingLeft: '15px'}}>
                  <Text variant="bodyXs" as="p" color="subdued">
                    Applies to all products
                  </Text>
                </div>
              </div>
            </Layout.Section>
            <Layout.Section>
              {fields.map((field, index) => (
                <div style={{display: 'flex', marginBottom: '15px'}} key={index}>
                  <div style={{paddingTop: '7px', paddingRight: '15px', width: '10%'}}>
                    {index === 0 && (
                      <Text variant="bodyXs" as="p" color="subdued">
                        Category Cost
                      </Text>
                    )}
                  </div>
                  <div style={{paddingRight: '15px', width: '20%'}}>
                    <Box>
                      <CategoryList
                        title="Select Category"
                        options={getFilteredOptions(index)}
                        selected={field.category}
                        setSelected={value => handleCategoryChange(index, value)}
                        disableMultiple
                      />
                    </Box>
                  </div>
                  <div>
                    <Box>
                      <TextField
                        type="number"
                        placeholder="Enter value"
                        suffix="%"
                        value={field.value}
                        onChange={value => handleValueChange(index, value)}
                        disabled={useDefaultCostOnly}
                      />
                    </Box>
                  </div>
                  {index === 0 && (
                    <div style={{paddingTop: '7px', paddingLeft: '15px'}}>
                      <Text variant="bodyXs" as="p" color="subdued">
                        Lowest category cost shall apply to categories not specified
                      </Text>
                    </div>
                  )}
                  {index !== 0 && (
                    <div style={{paddingLeft: '15px', paddingTop: '7px'}}>
                      <Button
                        icon={DeleteMinor}
                        plain
                        accessibilityLabel="Delete Category"
                        onClick={() => removeCategory(index)}
                        disabled={useDefaultCostOnly}
                      ></Button>
                    </div>
                  )}
                  {index === 0 && (
                    <div style={{paddingLeft: '25px'}}>
                      <Button
                        icon={PlusMinor}
                        primary
                        accessibilityLabel="Add Category"
                        onClick={addCategory}
                        disabled={useDefaultCostOnly}
                      >
                        Add category
                      </Button>
                    </div>
                  )}
                </div>
              ))}
            </Layout.Section>
            <Layout.Section>
              <Checkbox
                label={
                  <Text variant="bodyMd" as="p">
                    Only use Default Cost
                  </Text>
                }
                checked={useDefaultCostOnly}
                onChange={() => setUseDefaultCostOnly(!useDefaultCostOnly)}
              />
            </Layout.Section>
            <Layout.Section>
              <Checkbox
                label={
                  <Text variant="bodyMd" as="p">
                    Use Shopify Cost where specified (only applies to non-zero costs)
                  </Text>
                }
                checked={useShopifyCost}
                onChange={() => setUseShopifyCost(!useShopifyCost)}
              />
            </Layout.Section>
            <Layout.Section>
              <Button primary onClick={handleSaveChanges}>
                Save changes
              </Button>
            </Layout.Section>
          </Layout>
        </Box>
        {showAlert && (
          <Alert
            contents={showAlert}
            init={true}
            error={showAlert === 'Something Went Wrong'}
            duration={10000}
          />
        )}
      </Page>
    </>
  );
};

export {DefaultCogsMargin};
