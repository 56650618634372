import React from 'react';
import {Inline, Page} from '@shopify/polaris';
import OTPInput from 'otp-input-react';
import styles from './index.module.css';
const VerifyInput = ({setOtp, otp, error}) => {
  const border = error ? '1px solid red' : '1px solid blue';
  return (
    <>
      <Page>
        <Inline align="center">
          <OTPInput
            value={otp}
            inputStyles={{
              border: border,
              width: '40px',
              height: '40px',
            }}
            inputClassName={styles.otpInput}
            onChange={setOtp}
            autoFocus
            OTPLength={6}
            otpType="number"
            disabled={false}
          />
        </Inline>
      </Page>
    </>
  );
};

export default VerifyInput;
