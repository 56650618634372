function getMinAndMax(dataArray, durationFilter) {
  let minM = Infinity;
  let maxM = -Infinity;
  let initial = durationFilter === 'weekly' ? 'w' : durationFilter === 'monthly' ? 'm' : 'q';
  dataArray.forEach(data => {
    for (let j = 1; j <= Object.keys(data).length; j++) {
      let key;
      key = `${initial}${j - 1}`;
      const valueString = data[key];
      if (valueString === undefined) {
        continue;
      }
      if (!isNaN(valueString)) {
        minM = Math.min(minM, valueString);
        maxM = Math.max(maxM, valueString);
      }
    }
  });
  return {minimum: minM, maximum: maxM};
}

export {getMinAndMax};
