import {Badge, Spinner, Tabs} from '@shopify/polaris';
import Box from '../../../lib/Box';
import {saveAs} from 'file-saver';
import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {profileDataOfProduct} from '../../../api/productProfile';
import Page from '../../../components/page';
import Customers from './customers';
import Orders from './orders';
import Overview from './overview';
import Variants from './variants';
import {track} from '../../../features/track';
import apiToCall from '../../../features/apiToCall';
import Alert from '../../../components/alert';
import DownloadModal from '../../../components/Modals/downloadModal';
import {generateVariantTitle} from '../../../features/generateText';
const generateSubtitle = profileData => {
  return `${profileData?.data?.SKU || ''} ${
    profileData?.data?.SKU && profileData?.data?.createdAt ? ' •' : ''
  }  ${profileData?.data?.createdAt ? ' Added ' + profileData?.data?.createdAt + '  ago' : ''}`;
};

const ProductProfile = () => {
  const params = useParams();
  const [selected, setSelected] = useState(0);
  const handleTabChange = useCallback(selectedTabIndex => setSelected(selectedTabIndex), []);
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({data: {}, state: 'Loading'});
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [downloadState, setDownloadState] = useState('Success');
  const [showAlert, setShowAlert] = useState(false);
  const tabs = [
    {
      id: 'overview',
      content: 'Overview',
      accessibilityLabel: 'overview',
      panelID: 'overview-content-1',
    },
    {
      id: 'customers',
      content: 'Customers',
      accessibilityLabel: 'customers',
      panelID: 'customers-content-1',
    },
    {
      id: 'orders',
      content: 'Orders',
      accessibilityLabel: 'orders',
      panelID: 'orders-content-1',
    },
    {
      id: 'variants',
      content: 'Variants',
      accessibilityLabel: 'variants',
      panelID: 'variants-content-1',
    },
  ];
  const downloadApi = {
    orders: apiToCall['orders'].api,
    variants: apiToCall['products'].api,
    customers: apiToCall['customers'].api,
  };

  useEffect(() => {
    const tabs = [
      {
        id: 'overview',
        content: 'Overview',
        accessibilityLabel: 'overview',
        panelID: 'overview-content-1',
      },
      {
        id: 'customers',
        content: 'Customers',
        accessibilityLabel: 'customers',
        panelID: 'customers-content-1',
      },
      {
        id: 'orders',
        content: 'Orders',
        accessibilityLabel: 'orders',
        panelID: 'orders-content-1',
      },
      {
        id: 'variants',
        content: 'Variants',
        accessibilityLabel: 'variants',
        panelID: 'variants-content-1',
      },
    ];
    track('Page View', {
      name: 'Product Profile',
    });
    setProfileData({data: {}, state: 'Loading'});
    profileDataOfProduct({variantId: params.variantId})
      .then(res => {
        if (res?.data.length) {
          setProfileData({data: res?.data[0], state: 'Success'});
        } else {
          setProfileData({data: {}, state: 'Error'});
        }
      })
      .catch(err => {
        setProfileData({data: {}, state: 'Error'});
      });
  }, [params]);

  const handleDownload = async email => {
    setShowAlert(null);
    setDownloadState('Loading');
    let _params = {
      variantId: params.variantId,
      type: 'download',
    };
    _params.email = email;
    let response = await downloadApi[tabs[selected].id]({params: _params});
    if (response.data?.data) {
      setShowAlert(`Data will be mailed to you shortly on ${email}`);
      setDownloadState('Success');
    }
  };

  return (
    <>
      <Page
        title={generateVariantTitle(profileData.data) || ''}
        titleMetadata={
          profileData?.data?.status && <Badge status="success">{profileData.data.status}</Badge>
        }
        breadcrumbs={[
          {
            content: 'Products',
            onAction: () => {
              navigate(-1);
            },
          },
        ]}
        subtitle={profileData.state === 'Success' && generateSubtitle(profileData)}
        secondaryActions={
          tabs[selected].id !== 'overview' && [
            {
              content: downloadState === 'Loading' ? <Spinner size="small" /> : 'Download',
              onAction: () => setOpenDownloadModal(true),
            },
          ]
        }
      >
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          <Box paddingBlockStart={'8'}>
            {tabs[selected].id === 'overview' ? (
              <Overview />
            ) : tabs[selected].id === 'customers' ? (
              <Customers />
            ) : tabs[selected].id === 'orders' ? (
              <Orders />
            ) : tabs[selected].id === 'variants' ? (
              <Variants setSelected={setSelected} />
            ) : (
              <></>
            )}
          </Box>
        </Tabs>
        {showAlert && (
          <Alert
            contents={showAlert}
            init={true}
            error={showAlert === 'Something Went Wrong'}
            duration={10000}
          />
        )}
      </Page>
      <DownloadModal
        isOpen={openDownloadModal}
        toggle={() => setOpenDownloadModal(!openDownloadModal)}
        handleDownload={handleDownload}
      />
    </>
  );
};

export default ProductProfile;
