import {Grid, Spinner} from '@shopify/polaris';
import {formatNumber} from '../../../../features/format';
import {CircleDisableMinor} from '@shopify/polaris-icons';
import Inline from '../../../../lib/Inline';
import Card from '../../../../lib/Card';
import Stack from '../../../../lib/Stack';
import Icon from '../../../../lib/Icon';
import Text from '../../../../lib/Text';
const OrganizationStats = ({stats}) => {
  return (
    <Grid>
      <Grid.Cell columnSpan={{xs: 4, sm: 2, md: 2, lg: 4, xl: 4}}>
        <Card sectioned>
          <Inline align="center">
            <Stack vertical spacing="tight" alignment="center">
              <Text variant="bodyMd" color="subdued">
                Customers
              </Text>
              <Text variant="headingLg">
                {stats.state === 'Success' ? (
                  formatNumber({value: stats?.data?.customers || 0})
                ) : stats.state === 'Loading' ? (
                  <Spinner size="small" />
                ) : (
                  <Icon source={CircleDisableMinor} color="base" />
                )}
              </Text>{' '}
              <Text color="subdued">Expected</Text>
            </Stack>
          </Inline>
        </Card>
      </Grid.Cell>
      <Grid.Cell columnSpan={{xs: 4, sm: 2, md: 2, lg: 4, xl: 4}}>
        <Card sectioned>
          <Inline align="center">
            <Stack vertical spacing="tight" alignment="center">
              <Text variant="bodyMd" color="subdued">
                Orders
              </Text>
              <Text variant="headingLg">
                {stats.state === 'Success' ? (
                  formatNumber({value: stats?.data?.orders || 0})
                ) : stats.state === 'Loading' ? (
                  <Spinner size="small" />
                ) : (
                  <Icon source={CircleDisableMinor} color="base" />
                )}{' '}
              </Text>{' '}
              <Text color="subdued">Expected</Text>
            </Stack>
          </Inline>
        </Card>
      </Grid.Cell>
      <Grid.Cell columnSpan={{xs: 4, sm: 2, md: 2, lg: 4, xl: 4}}>
        <Card sectioned>
          <Inline align="center">
            <Stack vertical spacing="tight" alignment="center">
              <Text variant="bodyMd" color="subdued">
                Products
              </Text>
              <Text variant="headingLg">
                {stats.state === 'Success' ? (
                  formatNumber({value: stats?.data?.products || 0})
                ) : stats.state === 'Loading' ? (
                  <Spinner size="small" />
                ) : (
                  <Icon source={CircleDisableMinor} color="base" />
                )}{' '}
              </Text>{' '}
              <Text color="subdued">Expected</Text>
            </Stack>
          </Inline>
        </Card>
      </Grid.Cell>
    </Grid>
  );
};

export default OrganizationStats;
