import React, {useState, useCallback, useRef} from 'react';
import DateRangeComponent from '../../../../components/dateRangeComponent';
import Page from '../../../../components/page';
import {
  dateToNormalString,
  yesterDayDate,
  todayDate,
  thirtyDaysBefore,
  sevenDaysBefore,
  ninetyDaysBefore,
  oneYearBefore,
  toClickHouseDate,
  daysBetweenTwoDates,
} from '../../../../features/convertDates';
import {Text} from '@shopify/polaris';

import Card from '../../../../lib/Card';
import Box from '../../../../lib/Box';
import Inline from '../../../../lib/Inline';
import {ErrorBoundary} from '../../../../components/ErrorBoundary';
import DefaultIndexTable from '../../../../components/defaultIndexTable';
import {useParams, Link, useLocation} from 'react-router-dom';
import useApiCall from '../../../../hooks/useApiCall';
import {formatDate, formatNumber} from '../../../../features/format';
import {getOrgsDiffrenceCounts} from '../../../../api/admin/compareData';
import Button from '../../../../lib/Button';
const dateRangeObj = {
  yesterday: yesterDayDate,
  today: todayDate,
  lastThirtyDays: thirtyDaysBefore,
  lastSevenDays: sevenDaysBefore,
  lastNinetyDays: ninetyDaysBefore,
  lastOneYear: oneYearBefore,
};
const dateRangeValueToLable = {
  today: 'Today',
  yesterday: 'Yesterday',
  lastSevenDays: 'Last 7 Days',
  lastThirtyDays: 'Last 30 Days',
  lastNinetyDays: 'Last 90 Days',
  lastOneYear: 'Last 1 Year',
};
const diffCountColumns = [
  {title: 'Start date', value: 'startDate', type: 'date'},
  {title: 'End date', value: 'endDate', type: 'date'},
  {title: 'Expected count', value: 'expectedCount', type: 'number'},
  {title: 'Present count', value: 'presentCount', type: 'number'},
  {title: 'Diffrence', value: 'diffrence', type: 'diffCount'},
];
const DiffrencesCounts = () => {
  const params = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [popoverActive, setPopoverActive] = useState(false);
  const [dateRange, setDateRange] = useState('lastSevenDays');
  const [page, setPage] = useState(1);
  const sinceDates = useRef([]);
  const orgId = params.organizationId;
  const [selectedDates, setSelectedDates] = useState(
    dateRange
      ? {
          start: new Date(dateRangeObj[dateRange]()[0]),
          end: new Date(dateRangeObj[dateRange]()[1]),
        }
      : {
          start: new Date(),
          end: new Date(),
        }
  );
  const getDates = (dates, dateOption) => {
    setSelectedDates(dates);
    setDateRange(dateOption);
  };
  const togglePopoverActive = useCallback(
    () => setPopoverActive(popoverActive => !popoverActive),
    []
  );
  const activator = (
    <div style={{width: '100%'}}>
      <Button onClick={togglePopoverActive} disclosure>
        {dateRange ? dateRangeValueToLable[dateRange] : dateToNormalString(selectedDates)}
      </Button>
    </div>
  );

  const fetchDiffrenceCounts = async () => {
    let sinceDate = sinceDates.current.length
      ? sinceDates.current[sinceDates.current.length - 1]
      : '';
    let sdate = toClickHouseDate(selectedDates.start, 'start');
    let edate = toClickHouseDate(selectedDates.end, 'end');
    let res = await getOrgsDiffrenceCounts({
      orgId,
      startDate: sdate,
      endDate: edate,
      sinceDate,
      diffType: searchParams.get('diffType'),
      type: searchParams.get('type'),
    });
    return res;
  };
  const diffData = useApiCall(fetchDiffrenceCounts, [selectedDates, page]);
  return (
    <>
      <Page title="Diffrence counts">
        <Box paddingBlockEnd={'4'}>
          <Inline>
            <DateRangeComponent
              currentDates={selectedDates}
              getDates={getDates}
              activator={activator}
              popoverActive={popoverActive}
              togglePopoverActive={togglePopoverActive}
              currentDateOption={dateRange}
            />
          </Inline>
        </Box>
        <Box paddingBlockEnd={'4'}>
          <ErrorBoundary
            fallback={
              <Card>
                <Card.Section>
                  <div>An error has occured</div>
                </Card.Section>
              </Card>
            }
          >
            <DefaultIndexTable
              disableSort
              select={false}
              page={page}
              setPage={val => {
                if (page.number < val) {
                  sinceDates.current.push(diffData?.data[diffData?.data?.length - 1]?.endDate);
                } else if (page.number > val) {
                  sinceDates.current.pop();
                }
                setPage(val);
              }}
              resourceName={{
                singular: 'metrics',
                plural: 'metrics',
              }}
              columns={diffCountColumns}
              selectedFilters={[]}
              totalRows={
                diffData.data.length > 0
                  ? daysBetweenTwoDates(
                      toClickHouseDate(selectedDates.start, 'start'),
                      toClickHouseDate(selectedDates.end, 'end')
                    )
                  : 0
              }
              tableData={diffData.data.length > 0 ? diffData.data : []}
              state={diffData.state}
              param={{}}
              valueTypes={{
                date: (data, col) => {
                  return (
                    <Inline align="start">
                      {formatDate({
                        value: new Date(data[col.value]),
                        removeTime: false,
                        seconds: true,
                      })}
                    </Inline>
                  );
                },
                diffCount: (data, col) => {
                  return (
                    <>
                      {data[col.value] > 0 ? (
                        <Link
                          to={{
                            pathname: `/admin/organizations/${orgId}/diffrences`,
                            search: `?startDate=${encodeURIComponent(
                              data.startDate
                            )}&endDate=${encodeURIComponent(data.endDate)}&diffCount=${
                              data.diffrence
                            }&diffType=${searchParams.get('diffType')}&type=${searchParams.get(
                              'type'
                            )}`,
                          }}
                        >
                          <Text color="critical">{formatNumber({value: data[col.value]})}</Text>
                        </Link>
                      ) : (
                        <Text color="success">{formatNumber({value: data[col.value]})}</Text>
                      )}
                    </>
                  );
                },
              }}
            />
          </ErrorBoundary>
        </Box>
      </Page>
    </>
  );
};

export default DiffrencesCounts;
