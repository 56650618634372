import {Popover, ActionList} from '@shopify/polaris';
import React from 'react';

const Select = ({popoverActive, activator, togglePopoverActive, options}) => {
  return (
    <>
      <Popover
        active={popoverActive}
        activator={activator}
        autofocusTarget="first-node"
        onClose={togglePopoverActive}
        fullWidth
      >
        <Popover.Pane>
          <ActionList actionRole="menuitem" items={options} />
        </Popover.Pane>
      </Popover>
    </>
  );
};
export default Select;
