import axios from './axios';
import config from '../config';
import {LocalStorageService} from '../service/localStorage';
const {BASE_URL} = config;

export class RequestApi {
  static async requestedReports(obj) {
    try {
      let params = {params: obj};
      const response = await axios.get(`${BASE_URL}/reports/requested-reports`, params);
      return response.data;
    } catch (err) {
      return err.response;
    }
  }
  static async updateLastViewed({slag}) {
    try {
      const userDetails = LocalStorageService.getUserProfile();
      let email = userDetails?.email;

      const response = await axios.get(
        `${BASE_URL}/reports/request-report-lasViewed?slag=${slag}&email=${email}`
      );
      return response.data;
    } catch (err) {
      return err.response;
    }
  }
}
