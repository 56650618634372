import React, {useEffect, useState, useRef} from 'react';
import Page from '../../../components/page';
import {useNavigate, useParams, Link} from 'react-router-dom';
import {formatNumber, toTitleCase} from '../../../features/format';
import {AdminApi} from '../../../api/admin';
import {ProgressBar, Spinner, Text} from '@shopify/polaris';
import Box from '../../../lib/Box';
import Inline from '../../../lib/Inline';
import OrganizationStats from './Components/Stats';
import Alert from '../../../components/alert';
import DefaultIndexTable from '../../../components/defaultIndexTable';
import {
  adminOrganizationsDetailsColumns,
  adminOrganizationRunningWebhooksColumns,
} from '../../metrics/columns';
import DefaultActionList from '../../../components/DefaultActionList';
import FetchOrganizationModal from '../../../components/Modals/FetchOrganizationModal';
import ConfirmationModal from '../../../components/Modals/ConfirmationModal';
import ModalTable from '../../../components/Modals/ModalTable';
import Button from '../../../lib/Button';
const calculatePercentage = (val1, val2) => {
  if (!val2) return 0;
  return ((val1 / val2) * 100).toFixed(2);
};

const OrganizationDetailed = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [showAlert, setShowAlert] = useState('');
  const orgId = params?.organizationId?.split('+')[0];
  const [orgData, setOrgData] = useState({state: 'Loading', data: []});
  const [expectedCounts, setExpectedCounts] = useState({state: 'Loading', data: {}});
  const [actionsStates, setActionsStates] = useState({
    dailyFetch: 'Success',
    reCalculateAggregateFields: 'Success',
    createOrgWebhooks: 'Success',
    clearOrgData: 'Success',
    deleteOrgWebhooks: 'Success',
  });
  const [runningWebhooks, setRunningWebhooks] = useState({state: 'Loading', data: []});
  const [openTableModal, setOpenTableModal] = useState(null);
  const hasGeneratedRef = useRef(false);
  const generateDiffBetweenMongoRawAndExpectedCounts = () => {
    let _orgData = {...orgData};
    let mongoRawData = {};
    _orgData.data.forEach(row => {
      if (row.name === 'Mongo raw') {
        mongoRawData = row;
      }
    });
    _orgData.data.forEach(row => {
      if (row.name === 'Diffrence between mongo raw and expected') {
        Object.keys(expectedCounts?.data || {}).forEach(key => {
          row[key] = expectedCounts.data[key] - mongoRawData[key];
        });
      }
    });
    setOrgData({..._orgData});
    hasGeneratedRef.current = true;
  };
  const fetchExpectedCounts = async orgId => {
    setExpectedCounts({data: {}, state: 'Loading'});
    let res = await AdminApi.getOrgsExpected({orgId});
    if (res.status) {
      setExpectedCounts({data: res.data, state: 'Success'});
    } else {
      setExpectedCounts({data: {}, state: 'Error'});
    }
  };

  const fetchRunningWebhooks = async orgId => {
    setRunningWebhooks({state: 'Loading', data: []});
    try {
      let res = await AdminApi.getOrgsRunningWebhooks({orgId});
      if (res.status) {
        setRunningWebhooks({state: 'Success', data: res.data});
      } else {
        setRunningWebhooks({state: 'Error', data: []});
      }
    } catch (err) {
      setRunningWebhooks({state: 'Error', data: []});
    }
  };

  const [openConfirmModal, setOpenConfirmModal] = useState(null);

  const [openFetchModal, setOpenFetchModal] = useState(false);
  const generateStats = async orgId => {
    setOrgData({data: [], state: 'Loading'});
    try {
      let data = [];
      let [mongo, clickhouse, custom, pendingSync, mongoRaw] = await Promise.all([
        AdminApi.getOrgsMongoStats({orgId}),
        AdminApi.getOrgsClickhouseStats({orgId}),
        AdminApi.getOrgsCustomStats({orgId}),
        AdminApi.getOrgsPendingSync({orgId}),
        AdminApi.getOrgsMongoRawStats({orgId}),
      ]);
      data.push({name: 'Mongo raw', progress: true, ...mongoRaw.data});

      data.push({name: 'Mongo', progress: true, ...mongo.data});
      data.push({name: 'Clickhouse', progress: true, ...clickhouse.data});
      let mongoClickhouseDiff = {};
      Object.keys(mongo.data).forEach(key => {
        mongoClickhouseDiff[key] = mongo.data[key] - clickhouse.data[key];
      });
      let mongoDiff = {};
      Object.keys(mongo.data).forEach(key => {
        mongoDiff[key] = mongoRaw.data[key] - mongo.data[key];
      });
      data.push({
        name: 'Diffrence between mongo raw and expected',
        focus: true,
        value: 'expectedToRaw',
      });
      data.push({
        name: 'Diffrence between mongo raw and mongo',
        focus: true,
        value: 'rawToTransformed',
        ...mongoDiff,
      });
      data.push({
        name: 'Diffrence between mongo and clickhouse',
        focus: true,
        value: 'transformedToClickhouse',
        ...mongoClickhouseDiff,
      });
      data.push({name: 'Pending sync', focus: true, ...pendingSync.data});
      data.push({name: 'Custom', ...custom.data});

      setOrgData({data: [...data], state: 'Success'});
    } catch (err) {
      setOrgData({data: [], state: 'Error'});
    }
  };
  useEffect(() => {
    generateStats(orgId);
    fetchExpectedCounts(orgId);
  }, [orgId]);

  useEffect(() => {
    if (
      expectedCounts.state === 'Success' &&
      orgData.state === 'Success' &&
      !hasGeneratedRef.current
    ) {
      generateDiffBetweenMongoRawAndExpectedCounts();
    }
  }, [expectedCounts, orgData]);
  const fetchRecords = async ({startDate, endDate, orgId, allTime}) => {
    try {
      setShowAlert('');
      let res = await AdminApi.fetchRecords({startDate, endDate, orgId, allTime});
      if (res.status) {
        setShowAlert('Fetch started');
      } else {
        setShowAlert('Something Went Wrong');
      }
    } catch (err) {
      setShowAlert('Something Went Wrong');
    }
  };

  const dailyFetch = async () => {
    try {
      setShowAlert('');
      setActionsStates({...actionsStates, dailyFetch: 'Loading'});
      let res = await AdminApi.triggerDailyFetch({orgId});
      if (res.status) {
        setShowAlert('Daily fetch job created.. fetch will start soon');
      } else {
        setShowAlert('Something Went Wrong');
      }
      setActionsStates({...actionsStates, dailyFetch: 'Success'});
    } catch (err) {
      setActionsStates({...actionsStates, dailyFetch: 'Success'});
      setShowAlert('Something Went Wrong');
    }
  };

  const reCalculateAggregateFields = async () => {
    try {
      setShowAlert('');
      setActionsStates({...actionsStates, reCalculateAggregateFields: 'Loading'});
      let res = await AdminApi.reCalculateAggregateFields({orgId});
      if (res.status) {
        setShowAlert('Recalculation started started');
      } else {
        setShowAlert('Something Went Wrong');
      }
      setActionsStates({...actionsStates, reCalculateAggregateFields: 'Success'});
    } catch (err) {
      setShowAlert('Something Went Wrong');
      setActionsStates({...actionsStates, reCalculateAggregateFields: 'Success'});
    }
  };
  const clearOrgData = async () => {
    try {
      setShowAlert('');
      setActionsStates({...actionsStates, clearOrgData: 'Loading'});
      let res = await AdminApi.clearOrgData({orgId});
      if (res.status) {
        setShowAlert('data cleared successfully');
      } else {
        setShowAlert('Something Went Wrong');
      }
      setActionsStates({...actionsStates, clearOrgData: 'Success'});
    } catch (err) {
      setActionsStates({...actionsStates, clearOrgData: 'Success'});
      setShowAlert('Something Went Wrong');
    }
  };
  const deleteOrgWebhooks = async () => {
    try {
      setShowAlert('');
      setActionsStates({...actionsStates, deleteOrgWebhooks: 'Loading'});
      let res = await AdminApi.deleteOrgWebhooks({orgId});
      if (res.status) {
        setShowAlert('webhooks deleted successfully');
      } else {
        setShowAlert('Something Went Wrong');
      }
      setActionsStates({...actionsStates, deleteOrgWebhooks: 'Success'});
    } catch (err) {
      setActionsStates({...actionsStates, deleteOrgWebhooks: 'Success'});
      setShowAlert('Something Went Wrong');
    }
  };
  const createOrgWebhooks = async () => {
    try {
      setShowAlert('');
      setActionsStates({...actionsStates, createOrgWebhooks: 'Loading'});
      let res = await AdminApi.createOrgWebhooks({orgId});
      if (res.status) {
        setShowAlert('webhooks created successfully');
      } else {
        setShowAlert('Something Went Wrong');
      }
      setActionsStates({...actionsStates, createOrgWebhooks: 'Success'});
    } catch (err) {
      setShowAlert('Something Went Wrong');
      setActionsStates({...actionsStates, createOrgWebhooks: 'Success'});
    }
  };
  return (
    <>
      <Page
        breadcrumbs={[
          {
            content: 'jobs',
            onAction: () => {
              navigate(-1);
            },
          },
        ]}
        title={toTitleCase(params?.organizationId?.split('+')[1])}
      >
        <Box paddingBlockEnd={'6'}>
          <OrganizationStats stats={expectedCounts} />
        </Box>

        {!!orgData && (
          <DefaultIndexTable
            filters={[
              <Button
                onClick={() => {
                  setOpenTableModal(true);
                  fetchRunningWebhooks(orgId);
                }}
                plain
              >
                Show running webhooks
              </Button>,
              <Button
                onClick={() => {
                  navigate(`status-tracking`);
                }}
                plain
              >
                Data pull dashboard
              </Button>,
            ]}
            actions={
              <DefaultActionList
                items={[
                  {
                    content: 'Refetch historic data',
                    destructive: false,
                    onAction: () => {
                      setOpenFetchModal(!openFetchModal);
                    },
                  },
                  {
                    content: 'Trigger daily fetch',
                    destructive: false,
                    onAction: () => {
                      setOpenConfirmModal({
                        method: dailyFetch,
                        label: 'trigger daily fetch',
                      });
                    },
                    loading: actionsStates.dailyFetch === 'Loading',
                  },
                  {
                    content: 'Recalculate aggregated fields',
                    destructive: false,
                    onAction: () => {
                      setOpenConfirmModal({
                        method: reCalculateAggregateFields,
                        label: 're calculate aggregated fields',
                      });
                    },
                    loading: actionsStates.reCalculateAggregateFields === 'Loading',
                  },
                  {
                    content: 'Create webhooks',
                    destructive: false,
                    onAction: () => {
                      setOpenConfirmModal({
                        method: createOrgWebhooks,
                        label: 'create all webhooks for this organization',
                      });
                    },
                    loading: actionsStates.createOrgWebhooks === 'Loading',
                  },
                  {
                    content: 'Clear data',
                    destructive: true,
                    onAction: () => {
                      setOpenConfirmModal({
                        method: clearOrgData,
                        label: 'delete all data for this organization',
                        danger: true,
                      });
                    },
                    loading: actionsStates.clearOrgData === 'Loading',
                  },
                  {
                    content: 'Delete webhooks',
                    destructive: true,
                    onAction: () => {
                      setOpenConfirmModal({
                        method: deleteOrgWebhooks,
                        label: 'delete all webhooks for this organization',
                        danger: true,
                      });
                    },
                    loading: actionsStates.deleteOrgWebhooks === 'Loading',
                  },
                ]}
              />
            }
            disableSort
            select={false}
            page={1}
            setPage={() => {}}
            resourceName={{
              singular: 'organization stats',
              plural: 'organization stats',
            }}
            columns={adminOrganizationsDetailsColumns}
            selectedFilters={[]}
            durationFilter={'daily'}
            totalRows={null}
            tableData={orgData.data}
            param={{}}
            state={orgData.state}
            valueTypes={{
              number: (data, col) => {
                return (
                  <Inline align="end">
                    {data[col.value] === undefined ? (
                      <Spinner size="small" />
                    ) : (
                      <Inline align="center" gap={'2'}>
                        {data.progress && expectedCounts.state === 'Success' && (
                          <div style={{width: 100}}>
                            <ProgressBar
                              progress={calculatePercentage(
                                data[col.value],
                                expectedCounts.data[col.value]
                              )}
                              color="success"
                              size="small"
                            />
                            <Text>
                              {calculatePercentage(data[col.value], expectedCounts.data[col.value])}
                              %
                            </Text>
                          </div>
                        )}
                        <Text
                          color={data.focus ? (data[col.value] != 0 ? 'critical' : 'success') : ''}
                        >
                          {(data.value === 'expectedToRaw' ||
                            data.value === 'rawToTransformed' ||
                            data.value === 'transformedToClickhouse') &&
                          data.focus &&
                          data[col.value] != 0 ? (
                            <Link
                              to={{
                                pathname: `/admin/organizations/${orgId}/diffrence-counts`,
                                search: `?diffType=${data.value}&type=${col.value}`,
                              }}
                            >
                              <Text color="critical">{formatNumber({value: data[col.value]})}</Text>
                            </Link>
                          ) : (
                            <>{formatNumber({value: data[col.value]})}</>
                          )}

                          {data.progress && expectedCounts.state === 'Success' && (
                            <Text>({expectedCounts.data[col.value] - data[col.value]}) </Text>
                          )}
                        </Text>
                      </Inline>
                    )}
                  </Inline>
                );
              },
            }}
          />
        )}
        {showAlert && (
          <Alert
            contents={showAlert}
            init={true}
            error={showAlert === 'Something Went Wrong'}
            duration={10000}
          />
        )}
        {openFetchModal && (
          <FetchOrganizationModal
            isOpen={openFetchModal}
            fetchRecords={fetchRecords}
            orgId={orgId}
            toggle={() => setOpenFetchModal(!openFetchModal)}
          />
        )}
      </Page>
      {Boolean(openConfirmModal) && (
        <ConfirmationModal
          isOpen={Boolean(openConfirmModal)}
          toggle={() => setOpenConfirmModal(null)}
          onProceed={() => {
            openConfirmModal.method();
          }}
          text={`Are you sure you want to ${openConfirmModal.label}?`}
          danger={openConfirmModal.danger}
        />
      )}
      {Boolean(openTableModal) && (
        <ModalTable
          isOpen={Boolean(openTableModal)}
          toggle={() => setOpenTableModal(null)}
          title="Running webhooks"
          page={1}
          setPage={() => {}}
          columns={adminOrganizationRunningWebhooksColumns}
          tableData={runningWebhooks.data}
          state={runningWebhooks.state}
          disableSort
        />
      )}
    </>
  );
};

export default OrganizationDetailed;
