import React, {useState, useCallback, useMemo} from 'react';
import {Popover, OptionList, Text} from '@shopify/polaris';
import {Button} from '@shopify/polaris';

const CategoryList = ({title, options, selected, setSelected, disableMultiple}) => {
  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(
    () => setPopoverActive(popoverActive => !popoverActive),
    []
  );

  const valueToLabelMap = useMemo(() => {
    let _map = {};
    for (let option of options) {
      _map[option.value] = option.label;
    }
    return _map;
  }, [options]);

  const label = useMemo(() => {
    if (selected && disableMultiple) {
      return valueToLabelMap[selected] || title;
    }
    return title;
  }, [selected, valueToLabelMap, title, disableMultiple]);

  const handleSelectionChange = useCallback(
    value => {
      if (disableMultiple) {
        setSelected(value[0]);
        togglePopoverActive();
      } else {
        setSelected(value);
      }
    },
    [disableMultiple, setSelected, togglePopoverActive]
  );

  const activator = (
    <Button onClick={togglePopoverActive} disclosure fullWidth>
      <div
        style={{width: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
      >
        {label}
      </div>
    </Button>
  );

  return (
    <Popover active={popoverActive} activator={activator} onClose={togglePopoverActive}>
      <div style={{width: '200px'}}>
        {/* Matching width for the Popover */}
        <OptionList
          onChange={handleSelectionChange}
          options={options}
          selected={disableMultiple ? [selected] : selected}
          allowMultiple={!disableMultiple}
        />
      </div>
    </Popover>
  );
};

export default CategoryList;
