import React, {useCallback, useEffect, useState} from 'react';
import MyPage from '../../../components/page';
import {Loading} from '@shopify/polaris';
import Inline from '../../../lib/Inline';
import Box from '../../../lib/Box';
import Button from '../../../lib/Button';
import {dateToNormalString, previousPeriod, previousYear} from '../../../features/convertDates';
import DateRangeComponent from '../../../components/dateRangeComponent';
import DateCompareComponent from '../../../components/dateCompareComponent';
import {AddReportModal} from '../../../components/modal';
import DraggableDashboard from './draggable';
import NonDraggableDashboard from './nonDraggable/index,';
import {getSnapshot, updateSnapshot} from '../../../api/snapshot';
import Alert from '../../../components/alert';
import SelectComponent from '../../../components/selectComponent';
import {RequestApi} from '../../../api/requestReports';
import {useDateRangeStore, useSnapshotStore} from '../../../app/store';
import ColdStart from '../../coldStart';
import useApiCall from '../../../hooks/useApiCall';
import useSearchParamStoreSync from '../../../hooks/useSearchParamsStoreSync';

const dateCompareObj = {
  previousPeriod: previousPeriod,
  previousYear: previousYear,
  noComparison: () => {
    return ['', ''];
  },
};
const dateRangeValueToLable = {
  today: 'Today',
  yesterday: 'Yesterday',
  lastSevenDays: 'Last 7 Days',
  lastThirtyDays: 'Last 30 Days',
  lastNinetyDays: 'Last 90 Days',
  lastOneYear: 'Last 1 Year',
};
const dateCompareValueToLable = {
  previousPeriod: 'Previous Period',
  previousYear: 'Previous Year',
  noComparison: 'No Comparison',
};
const Dashboard = () => {
  const [dateRangePopoverActive, setDateRangePopoverActive] = useState(false);

  const {
    data,
    setData,
    state,
    setState,
    // error,
    setError,
  } = useSnapshotStore(state => state);
  const {
    selectedDateRange,
    setSelectedDateRange,
    selectedCompare,
    setSelectedCompare,
    durationFilter,
    setDurationFilter,
    selectedDateRangeDates,
    setSelectedDateRangeDates,
    setSelectedCompareDates,
    selectedCompareDates,
  } = useDateRangeStore(state => state);
  useSearchParamStoreSync(['durationFilter', 'selectedDateRangeDates']);

  const [customizeMode, setCustomizeMode] = useState(false);
  const [popoverActive, setPopoverActive] = useState(false);
  const togglePopoverActive = useCallback(
    () => setPopoverActive(popoverActive => !popoverActive),
    []
  );

  const [openAddReportModal, setOpenAddReportModal] = useState(false);
  const [showAlert, setShowAlert] = useState('');

  const getSnapshotData = async () => {
    try {
      setState('Loading');
      let res = await getSnapshot();
      setData({...res.data.reports});
      setState('Success');
    } catch (err) {
      setState('Error');
      setError(err);
    }
  };

  const isUpdated = async status => {
    setShowAlert('');
    if (status) {
      await getSnapshotData();
      setShowAlert('Dashboard Updated Successfully');
    } else {
      setShowAlert('Something Went Wrong');
    }
  };

  const addReportsToSnapshot = async reports => {
    setShowAlert('');
    data.c1.push(...reports);
    let res = await updateSnapshot({reports: data});
    if (res.error) {
      setShowAlert('Something Went Wrong');
    } else {
      await getSnapshotData();
      setShowAlert('Dashboard Updated Successfully');
    }
  };
  const activator = (
    <div style={{width: '100%'}}>
      <Button onClick={togglePopoverActive} disclosure>
        Compare:{' '}
        {selectedCompare
          ? dateCompareValueToLable[selectedCompare]
          : dateToNormalString(selectedCompareDates)}
      </Button>
    </div>
  );
  const getDates = (dates, compare) => {
    setSelectedCompareDates(dates);
    setSelectedCompare(compare[0]);
  };
  const getDateRangeDates = (dates, selectedDateRange) => {
    setSelectedDateRangeDates(dates);
    setSelectedDateRange(selectedDateRange);
  };
  const dateRangeTogglePopoverActive = useCallback(
    () => setDateRangePopoverActive(dateRangePopoverActive => !dateRangePopoverActive),
    []
  );

  const dateRangeActivator = (
    <div style={{width: '100%'}}>
      <Button onClick={dateRangeTogglePopoverActive} disclosure>
        {selectedDateRange
          ? dateRangeValueToLable[selectedDateRange]
          : dateToNormalString(selectedDateRangeDates)}
      </Button>
    </div>
  );

  useEffect(() => {
    // compare Dates auto change on selected date changed

    if (selectedCompare) {
      let [sdate, edate] = dateCompareObj[selectedCompare](selectedDateRangeDates);
      setSelectedCompareDates({start: sdate, end: edate});
    }
  }, [selectedDateRangeDates]);

  const fetchReports = async () => {
    let response = await RequestApi.requestedReports({categorize: true});
    return response;
  };

  const reports = useApiCall(fetchReports, []);

  useEffect(() => {
    if (!data.c1.length && !data.c2.length && !data.c3.length) {
      getSnapshotData();
    }
  }, []);

  return (
    <>
      <MyPage
        title="Dashboard"
        primaryAction={
          !customizeMode && {
            content: 'Add report',
            onAction: () => {
              setOpenAddReportModal(!openAddReportModal);
            },
          }
        }
        secondaryActions={
          !customizeMode && [
            {
              content: 'Customize',
              onAction: () => {
                setCustomizeMode(true);
              },
            },
          ]
        }
      >
        {state === 'Loading' && <Loading />}
        {!customizeMode && (
          <Box paddingBlockEnd={'4'}>
            <Inline>
              <DateRangeComponent
                currentDates={selectedDateRangeDates}
                activator={dateRangeActivator}
                togglePopoverActive={dateRangeTogglePopoverActive}
                popoverActive={dateRangePopoverActive}
                getDates={getDateRangeDates}
                currentDateOption={selectedDateRange}
              />
              <DateCompareComponent
                currentDates={selectedCompareDates}
                activator={activator}
                togglePopoverActive={togglePopoverActive}
                popoverActive={popoverActive}
                selectedCompare={selectedCompare}
                getDates={getDates}
                selectedDateRangeDates={selectedDateRangeDates}
              />
              <SelectComponent
                setDurationFilter={setDurationFilter}
                durationFilter={durationFilter}
              />
            </Inline>
          </Box>
        )}
        {customizeMode ? (
          <DraggableDashboard
            setCustomizeMode={setCustomizeMode}
            columnsFromBackend={data}
            selectedDates={selectedDateRangeDates}
            selectedCompareDates={selectedCompareDates}
            durationFilter={durationFilter}
            isUpdated={isUpdated}
          />
        ) : (
          <NonDraggableDashboard
            selectedDates={selectedDateRangeDates}
            selectedCompareDates={selectedCompareDates}
            durationFilter={durationFilter}
          />
        )}

        {openAddReportModal && (
          <AddReportModal
            currentState={openAddReportModal}
            onClose={setOpenAddReportModal}
            data={[...reports.data]}
            addReportsToSnapshot={addReportsToSnapshot}
            snapshotData={data}
            state={reports.state}
          />
        )}
        {showAlert && (
          <Alert contents={showAlert} init={true} error={showAlert === 'Something Went Wrong'} />
        )}
        {state === 'Error' && <ColdStart page={'page8'} />}
      </MyPage>
    </>
  );
};

export default Dashboard;
