import {useNavigate} from 'react-router-dom';
import {useContext, useEffect} from 'react';
import {AuthContext} from '../../context/auth';
import {LocalStorageService} from '../../service/localStorage';
import {usePostHog} from 'posthog-js/react';
import {updatePosthogIdentity} from '../../features/updatePosthogIdentity';
import {sendUserEvents} from '../../features/sendUserActivity';

export default function PublicRoute(props) {
  const {isSignedIn} = useContext(AuthContext);
  const navigate = useNavigate();
  const userType = LocalStorageService.getUserProfile()?.userType;
  const posthog = usePostHog();
  let isAdmin = LocalStorageService.isAdminSignedIn();
  if (!isAdmin) {
    sendUserEvents();
  }
  sendUserEvents();
  useEffect(() => {
    if (isSignedIn()) {
      updatePosthogIdentity(posthog);
      process.env.REACT_APP_ENV === 'production'
        ? userType === 'admin'
          ? navigate('/admin/organizations')
          : navigate('/fetch-status')
        : navigate('/dashboard');
    }
  }, [isSignedIn, navigate]);

  return props.children;
}
