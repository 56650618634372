import {Button, Card, DataTable, Link, Loading, Tooltip} from '@shopify/polaris';
import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Alert from '../../../components/alert';
import ColdStart from '../../coldStart';
import {track} from '../../../features/track';
import {deleteAlert, getAllAlerts, triggerAlert, updateAlert} from '../../../api/alerts';
import Box from '../../../lib/Box';
import getDataTableLoading from '../../../features/dataTableLoadingRows';
import {formatDate} from '../../../features/format';
import Icon from '../../../lib/Icon';
import {
  EditMajor,
  DeleteMajor,
  SendMajor,
  PauseCircleMajor,
  PlayCircleMajor,
} from '@shopify/polaris-icons';
import InputWithSearch from '../../../components/InputWithSearch';
import Inline from '../../../lib/Inline';
import Select from '../../metrics/metricsPage/components/select';

const frequencyMap = {
  daily: 'daily',
  weekly: 'weekly',
  whenTriggered: 'on trigger',
};
const ActiveAlert = () => {
  const [popoverActive, setPopoverActive] = useState(false);
  const [alerts, setAlerts] = useState({data: [], state: 'Loading'});
  const [trigger, setTrigger] = useState({state: 'Success'});
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [sortOrder, setSortOrder] = useState(1);
  const [sortBy, setSortBy] = useState('reportName');
  const [showAlert, setShowAlert] = useState('');
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');

  const togglePopoverActive = useCallback(
    () => setPopoverActive(popoverActive => !popoverActive),
    []
  );

  const activator = (
    <Button textAlign="left" fullWidth onClick={togglePopoverActive} disclosure>
      {selectedCategory}
    </Button>
  );

  const triggerAlertFn = async id => {
    setShowAlert('');
    setTrigger({state: 'Loading'});
    try {
      let response = await triggerAlert(id);
      if (response.status) {
        setTrigger({state: 'Success'});
        setShowAlert('You will receive email shortly');
      } else {
        setTrigger({state: 'Success'});
        setShowAlert('Something Went Wrong');
      }
    } catch (err) {
      setTrigger({state: 'Success'});
      setShowAlert('Something Went Wrong');
    }
  };

  const editAlert = id => {
    navigate(`/alert/edit/${id}`);
  };
  const pauseAlert = async _id => {
    setShowAlert('');
    await updateAlert({_id, isActive: false});
    setShowAlert('Alert paused successfully');
    fetchAlerts();
  };
  const unPauseAlert = async _id => {
    setShowAlert('');
    await updateAlert({_id, isActive: true});
    setShowAlert('Alert resumed successfully');
    fetchAlerts();
  };

  const deleteFun = async id => {
    setShowAlert('');
    await deleteAlert(id);
    setShowAlert('Alert deleted successfully');
    fetchAlerts();
  };
  const fetchAlerts = async () => {
    try {
      let response = await getAllAlerts();
      if (response.status) {
        console.log(response.data);
        setAlerts({state: 'Success', data: response.data});
      } else {
        setAlerts({state: 'Error', data: [], error: response.message});
      }
    } catch (err) {
      setAlerts({state: 'Error', data: [], error: err.message});
    }
  };

  useEffect(() => {
    track('Page View', {
      name: 'Active alerts',
    });
    fetchAlerts();
  }, []);

  useEffect(() => {
    if (alerts.state === 'Success') {
      let categoryFlag = false;
      let searchFlag = false;

      let alert = alerts.data
        .filter(alert => {
          if (selectedCategory === 'All') {
            categoryFlag = true;
          } else if (selectedCategory === 'Pre-built') {
            categoryFlag = alert.user === 'Retainwise';
          } else if (selectedCategory === 'Custom') {
            categoryFlag = alert.user !== 'Retainwise';
          }
          if (
            `${alert.emailSubject.toLowerCase()} ${alert.description.toLowerCase}`.includes(
              search.toLowerCase()
            )
          ) {
            searchFlag = true;
          } else {
            searchFlag = false;
          }
          return categoryFlag && searchFlag;
        })
        .map(alert => [
          <>{alert.emailSubject}</>,
          <>{alert.description}</>,
          <>{alert.user}</>,
          <>{frequencyMap[alert.schedule.frequency] || 'daily'}</>,
          // <>{alert.lastViewed && formatDate({value: new Date(alert.lastViewed)})}</>,
          // <>{alert.lastSentAt && formatDate({value: new Date(alert.lastSentAt)})}</>,
          <div style={{display: 'flex', alignItems: 'center', alignContent: 'space-between'}}>
            <div
              style={{marginRight: '7px', cursor: 'pointer'}}
              onClick={() => triggerAlertFn(alert._id)}
            >
              <Tooltip content="Trigger now">
                <Icon source={SendMajor} color="base" />
              </Tooltip>
            </div>
            <div
              style={{marginRight: '7px', marginLeft: '7px', cursor: 'pointer'}}
              onClick={() => editAlert(alert._id)}
            >
              <Tooltip content="Edit">
                <Icon source={EditMajor} color="base" />
              </Tooltip>
            </div>
            {alert.isActive && (
              <div
                style={{marginLeft: '7px', marginRight: '7px', cursor: 'pointer'}}
                onClick={() => pauseAlert(alert._id)}
              >
                <Tooltip content="Pause">
                  <Icon source={PauseCircleMajor} color={'base'} />
                </Tooltip>
              </div>
            )}
            {!alert.isActive && (
              <div
                style={{marginLeft: '7px', marginRight: '7px', cursor: 'pointer'}}
                onClick={() => unPauseAlert(alert._id)}
              >
                <Tooltip content="Resume">
                  <Icon source={PlayCircleMajor} color={'base'} />
                </Tooltip>
              </div>
            )}
            {alert.user !== 'Retainwise' && (
              <div
                style={{marginLeft: '7px', cursor: 'pointer'}}
                onClick={() => deleteFun(alert._id)}
              >
                <Tooltip content="Delete">
                  <Icon source={DeleteMajor} color="base" />
                </Tooltip>
              </div>
            )}
          </div>,
        ]);
      setRows([...alert]);
    }
  }, [search, selectedCategory, alerts]);

  return (
    <>
      {alerts.state === 'Loading' && <Loading />}
      {alerts.state === 'Error' ? (
        <ColdStart page="page8" />
      ) : alerts.state === 'Success' && !alerts.data.length && rows.length ? (
        <>
          <ColdStart page={'page6'} />
        </>
      ) : (
        <Card>
          <Box paddingBlockStart={'1'}>
            {/* <Box width="75%" padding={'3'}>
              <InputWithSearch queryValue={search} setQueryValue={setSearch} />
            </Box> */}
            <Inline align="space-between">
              <Box width="75%" padding={'3'}>
                <InputWithSearch queryValue={search} setQueryValue={setSearch} />
              </Box>
              <Box width="21%">
                <Inline align="end">
                  <Box width="90%" paddingInlineEnd={'3'}>
                    <Select
                      options={[
                        {
                          content: 'All',
                          active: selectedCategory === 'All',
                          onAction: () => {
                            setSelectedCategory('All');
                            togglePopoverActive();
                          },
                        },
                        {
                          content: 'Pre-built',
                          active: selectedCategory === 'Pre-built',

                          onAction: () => {
                            setSelectedCategory('Pre-built');
                            togglePopoverActive();
                          },
                        },
                        {
                          content: 'Custom',
                          active: selectedCategory === 'Custom',
                          onAction: () => {
                            setSelectedCategory('Custom');
                            togglePopoverActive();
                          },
                        },
                      ]}
                      popoverActive={popoverActive}
                      togglePopoverActive={togglePopoverActive}
                      activator={activator}
                    />
                  </Box>
                </Inline>
              </Box>
            </Inline>
            <DataTable
              sortable={[true, false, true, false, true, false]}
              columnContentTypes={['text', 'text', 'text', 'text', 'text']}
              headings={[
                'Name',
                'Description',
                'Created By',
                'Communication',
                // 'Last sent',
                'Actions',
              ]}
              rows={loading ? getDataTableLoading(4) : rows}
              defaultSortDirection="ascending"
              initialSortColumnIndex={0}
              onSort={(e, i) => {
                console.log(e, i);
                let order;
                let column;
                if (e === 0) {
                  column = 'emailSubject';
                }
                if (e === 2) {
                  column = 'user';
                }
                if (e === 4) {
                  column = 'lastSentAt';
                }
                if (i === 'ascending') {
                  order = -1;
                } else {
                  order = 1;
                }
                setSortOrder(order);
                setSortBy(column);
              }}
            />
          </Box>
          {/* {alerts.data.map(alert => {
            return (
              <ActiveAlertCard
                key={alert._id}
                alert={alert}
                fetchAlerts={fetchAlerts}
                triggerAlert={triggerAlertFn}
                trigger={trigger}
                setShowAlert={setShowAlert}
              />
            );
          })} */}
        </Card>
      )}
      {showAlert ? (
        <Alert contents={showAlert} init={true} error={showAlert === 'Something Went Wrong'} />
      ) : (
        <></>
      )}
    </>
  );
};

export default ActiveAlert;
