import {Spinner} from '@shopify/polaris';
import React, {Suspense} from 'react';
const LoadingFallback = () => <Spinner size="small" />;
const ReactJson = React.lazy(() => import('react-json-view'));
const JsonViewer = ({data}) => {
  return (
    <>
      <Suspense fallback={<LoadingFallback />}>
        <ReactJson src={data} />
      </Suspense>
    </>
  );
};

export default JsonViewer;
