import {Spinner} from '@shopify/polaris';
import Card from '../../../lib/Card';
import Box from '../../../lib/Box';
import {toClickHouseDate, convertToOrgTimezone} from '../../../features/convertDates';
import React from 'react';
import {useEffect} from 'react';
import {useState} from 'react';
import Page from '../../../components/page';
import DefaultTable from '../../../components/defaultIndexTable';
import apiToCall from '../../../features/apiToCall';
import {ErrorBoundary} from '../../../components/ErrorBoundary';
import config from '../../../config';
import {track} from '../../../features/track';
import useApiCall from '../../../hooks/useApiCall';
import DownloadModal from '../../../components/Modals/downloadModal';
import Alert from '../../../components/alert';

const ProductPage = ({startDate, endDate}) => {
  const [sortOrder, setSortOrder] = useState(apiToCall['products'].sortOrder);
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [showAlert, setShowAlert] = useState('');
  const [queryValue, setQueryValue] = useState('');
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState(apiToCall['products'].sortBy);
  const [download, setDownload] = useState({state: 'Success'});

  useEffect(() => {
    track('Page View', {
      name: 'Products',
    });
  }, []);

  const fetchProducts = async () => {
    let start = convertToOrgTimezone(toClickHouseDate(startDate, 'start'));
    let end = convertToOrgTimezone(toClickHouseDate(endDate, 'end'));
    let params = {
      startDate: start,
      endDate: end,
      page,
      search: queryValue,
      sortBy,
      sortOrder,
      type: 'table',
    };

    let response = await apiToCall['products'].api({params});
    return response.data;
  };

  const productsApiDependencies = [startDate, endDate, page, queryValue, sortBy, sortOrder];

  const productData = useApiCall(fetchProducts, productsApiDependencies, config.SEARCH_DELAY);

  const handleDownload = async email => {
    setShowAlert('');
    let start = convertToOrgTimezone(toClickHouseDate(startDate, 'start'));
    let end = convertToOrgTimezone(toClickHouseDate(endDate, 'end'));
    setDownload({state: 'Loading'});
    const params = {
      type: 'download',
      startDate: start,
      endDate: end,
      search: queryValue,
      sortBy,
      sortOrder,
      page,
      email,
    };
    setDownload({state: 'Loading'});
    try {
      await apiToCall['products'].api({params});
      setShowAlert(`Products data will be mailed to you shortly on ${email}`);
      setDownload({state: 'Success'});
    } catch (e) {
      setDownload({state: 'Error'});
    }
  };
  return (
    <>
      {/* <Page
        secondaryActions={[
          // {
          //   content: 'Create signal',
          //   destructive: false,
          //   onAction: () => {
          //     navigate(`/signal-new/${'products'}`);
          //   },
          // },
          {
            content: download.state === 'Loading' ? <Spinner size="small" /> : 'Download',
            destructive: false,
            onAction: () => {
              setOpenDownloadModal(true);
            },
          },
        ]}
        title={apiToCall['products'].title}
      > */}
      <Box paddingBlockEnd={'4'}>
        <ErrorBoundary
          fallback={
            <Card>
              <Card.Section>
                <div>An error has occured</div>
              </Card.Section>
            </Card>
          }
        >
          <DefaultTable
            sortBy={sortBy}
            setSortBy={setSortBy}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            select={false}
            queryValue={queryValue}
            setQueryValue={setQueryValue}
            page={page}
            setPage={setPage}
            resourceName={{
              singular: 'metrics',
              plural: 'metrics',
            }}
            columns={apiToCall['products'].columns}
            selectedFilters={[]}
            totalRows={productData.data.length ? +productData.data[0].totalCount : 0}
            tableData={productData.data.length > 1 ? productData.data[1] : []}
            durationFilter={'daily'}
            param={{name: 'products'}}
            state={productData.state}
            queryPlaceholder="Filter sku , title , category or brand of product"
          />
        </ErrorBoundary>
      </Box>
      {/* </Page> */}
      {showAlert && (
        <Alert
          contents={showAlert}
          init={true}
          error={showAlert === 'Something Went Wrong'}
          duration={10000}
        />
      )}
      <DownloadModal
        isOpen={openDownloadModal}
        toggle={() => setOpenDownloadModal(!openDownloadModal)}
        handleDownload={handleDownload}
      />
    </>
  );
};

export default ProductPage;
