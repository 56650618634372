import React, {useMemo, useState} from 'react';
import {replaceVariables} from './Modals/utils/functions';
import {getDefaultNpsTemplate} from '../api/reports/settings';
import Card from '../lib/Card';
import {Button, ButtonGroup, TextField} from '@shopify/polaris';
import RichTextEditor from './RichTextEditor';

const EmailTemplatePreviewEdit = ({template, editMode, setTemplate}) => {
  const htmlString = useMemo(() => {
    return replaceVariables(template.body);
  }, [template]);
  const subjectString = useMemo(() => {
    return replaceVariables(template.subject);
  }, [template]);
  function removeFooterFromString(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    const footerDiv = doc.querySelector('.footer');
    if (footerDiv) {
      footerDiv.parentNode.removeChild(footerDiv);
    }

    return doc.documentElement.outerHTML;
  }

  function addFooterFromString(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const bodyElement = doc.querySelector('body');

    const footerHTML = `
        <div class="footer">
          Powered by retainwise<br>
          <a href="unsubscribe_link_here" class="unsubscribe">Unsubscribe</a><br>
          42, WeWork Galaxy, Residency Road, Bangalore, India
        </div>
       `;

    if (bodyElement) {
      bodyElement.insertAdjacentHTML('beforeend', footerHTML);
    }

    return doc.documentElement.outerHTML;
  }

  return (
    <>
      <Card.Section>
        <TextField
          label="Subject"
          readOnly={!editMode}
          onChange={e => {
            setTemplate({...template, subject: e});
          }}
          type="text"
          value={editMode ? template.subject : subjectString}
        />
      </Card.Section>
      <Card.Section>
        {editMode ? (
          <RichTextEditor
            template={removeFooterFromString(template.body)}
            setTemplate={e => {
              setTemplate({...template, body: addFooterFromString(e)});
            }}
          />
        ) : (
          <iframe
            style={{height: '80vh', pointerEvents: 'none'}}
            srcDoc={htmlString}
            title="Email Preview"
            width="100%"
            height="500px"
          ></iframe>
        )}
      </Card.Section>
    </>
  );
};

export default EmailTemplatePreviewEdit;
