import {Box, Loading} from '@shopify/polaris';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Page from '../../../components/page';
import ColdStart from '../../coldStart';
import AlertHistoryCard from './AlertHistoryCard';
import {track} from '../../../features/track';
import useApiCall from '../../../hooks/useApiCall';
import {alertHistory} from '../../../api/alerts';
const AlertHistory = () => {
  const navigate = useNavigate();
  const fetchExportHistory = async () => {
    const response = await alertHistory();
    return response;
  };
  const exportHistories = useApiCall(fetchExportHistory, []);

  useEffect(() => {
    track('Page View', {
      name: 'Alert history',
    });
  }, []);
  return (
    <>
      <Page
        title="Alert History"
        subtitle="View all past alerts"
        breadcrumbs={[
          {
            content: 'Active alerts',
            onAction: () => {
              navigate(-1);
            },
          },
        ]}
        primaryAction={{
          content: 'New alert',
          onAction: () => {
            navigate('/alert-new');
          },
        }}
      >
        {exportHistories.state === 'Loading' && <Loading />}
        <Box paddingBlockEnd={'4'}>
          {exportHistories.state === 'Error' ? (
            <ColdStart page="page8" />
          ) : exportHistories.state === 'Success' && !exportHistories.data.length ? (
            <>
              <ColdStart page="page7" />
            </>
          ) : (
            <>
              {exportHistories.data.map(history => {
                return <AlertHistoryCard key={history._id} data={history} />;
              })}
            </>
          )}
        </Box>
      </Page>
    </>
  );
};
export default AlertHistory;
