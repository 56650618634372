import {Link, Popover} from '@shopify/polaris';
import {useCallback, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import Button from '../lib/Button';

const ItemsPopover = ({items}) => {
  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(
    () => setPopoverActive(popoverActive => !popoverActive),
    []
  );

  const activator = (
    <Button onClick={togglePopoverActive} plain monochrome removeUnderline disclosure>
      {items.length === 0
        ? '0 item \u00A0\u00A0'
        : items.length === 1
        ? '1 item \u00A0\u00A0'
        : items.length + ' items\u00A0'}
    </Button>
  );

  return (
    <Popover
      active={popoverActive}
      activator={activator}
      autofocusTarget="first-node"
      onClose={togglePopoverActive}
    >
      <Popover.Pane fixed>
        {items.map((item, i) => (
          <Popover.Section key={i}>
            <p>Name: {item.name}</p>
            <p>
              SKU:
              {
                <Link removeUnderline>
                  <RouterLink style={{textDecoration: 'none'}} to={`/product/${item['variantId']}`}>
                    {'\u00A0' + item.sku}
                  </RouterLink>
                </Link>
              }
            </p>
            <p>Quantity: {item.quantity}</p>
          </Popover.Section>
        ))}
      </Popover.Pane>
    </Popover>
  );
};

export {ItemsPopover};
