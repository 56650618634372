import React, {useEffect} from 'react';
import {Button, Popover, Text, OptionList} from '@shopify/polaris';
import {useState, useCallback} from 'react';
const SelectComponent = ({options, dispatch, type, value, label}) => {
  const [popoverActive, setPopoverActive] = useState(false);
  const [selected, setSelected] = useState([]);
  const [name, setName] = useState('');

  const togglePopoverActive = useCallback(
    () => setPopoverActive(popoverActive => !popoverActive),
    []
  );

  const activator = (
    <Button
      fullWidth
      textAlign="left"
      disclosure={popoverActive ? 'up' : 'down'}
      onClick={togglePopoverActive}
    >
      <Text color="subdued">{selected.length ? name : label}</Text>
    </Button>
  );

  useEffect(() => {
    if (value === '') {
      setSelected([]);
    } else {
      setSelected([value]);
    }
  }, [value]);
  useEffect(() => {
    let n = '';
    options.forEach(a => {
      if (a.value === selected[0]) {
        n = a.label;
      }
    });

    setName(n);
  }, [selected]);
  return (
    <div>
      <Popover
        active={popoverActive}
        activator={activator}
        autofocusTarget="first-node"
        onClose={togglePopoverActive}
        preferredPosition="below"
        fullWidth
      >
        <Popover.Pane>
          <OptionList
            onChange={e => {
              setSelected(e);
              dispatch({type, value: e[0]});
              togglePopoverActive();
            }}
            options={options}
            selected={selected}
          />
        </Popover.Pane>
      </Popover>
    </div>
  );
};

export default SelectComponent;
