import axios from './axios';
import config from '../config';
import {LocalStorageService} from '../service/localStorage';
const {BASE_URL} = config;

export async function createAlert(input) {
  try {
    const userDetails = LocalStorageService.getUserProfile();
    let email = userDetails?.email;
    input.email = email;
    const response = await axios.post(`${BASE_URL}/alert`, input);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function getAllAlerts() {
  try {
    const response = await axios.get(`${BASE_URL}/alert/all`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function alertHistory() {
  try {
    const response = await axios.get(`${BASE_URL}/alert/history`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function getAlert(id) {
  try {
    const response = await axios.get(`${BASE_URL}/alert?id=${id}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function deleteAlert(id) {
  try {
    const response = await axios.delete(`${BASE_URL}/alert?id=${id}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function updateAlert(input) {
  try {
    const userDetails = LocalStorageService.getUserProfile();
    let email = userDetails?.email;
    input.email = email;
    const response = await axios.patch(`${BASE_URL}/alert?id=${input._id}`, input);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function triggerAlert(id) {
  try {
    const response = await axios.get(`${BASE_URL}/alert/trigger?id=${id}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
