import {Box, Layout, Link, Modal, Text} from '@shopify/polaris';
import {useState, useCallback} from 'react';
const NoConnectionModal = ({currentState, connectionRequired, onClose}) => {
  const [active, setActive] = useState(currentState);
  const handleChange = useCallback(() => {
    onClose();
    setActive(!active);
  }, [active]);

  const title = `No active ${connectionRequired} connection`;
  const activator = <div></div>;
  return (
    <>
      <Modal
        activator={activator}
        open={active}
        onClose={handleChange}
        title={title}
        footer={
          <Box>
            Go to{' '}
            <Link removeUnderline={true} url="/settings">
              Settings
            </Link>{' '}
            to add new {connectionRequired} connection.
          </Box>
        }
      >
        <Modal.Section>
          <Layout>
            <Layout.Section>
              <Text fontWeight="semibold">
                You dont have any active {connectionRequired} connection.
              </Text>
            </Layout.Section>
          </Layout>
        </Modal.Section>
      </Modal>
    </>
  );
};

export default NoConnectionModal;
