const currencies = {
  USD: '$',
  EUR: '€',
  JPY: '¥',
  GBP: '£',
  AUD: 'A$',
  CAD: 'C$',
  CHF: 'Fr',
  CNY: '¥',
  SEK: 'kr',
  NZD: 'NZ$',
  NOK: 'kr',
  KRW: '₩',
  TRY: '₺',
  RUB: '₽',
  INR: '₹',
  BRL: 'R$',
  ZAR: 'R',
  MXN: 'Mex$',
  SGD: 'S$',
  HKD: 'HK$',
  PLN: 'zł',
  THB: '฿',
  IDR: 'Rp',
  MYR: 'RM',
  HUF: 'Ft',
  CZK: 'Kč',
  ILS: '₪',
  CLP: 'CLP$',
  PHP: '₱',
  AED: 'د.إ',
  DKK: 'kr',
  ARS: '$',
  TWD: 'NT$',
  SAR: '﷼',
  EGP: 'EGP',
  QAR: '﷼',
  KWD: 'د.ك',
  PKR: '₨',
  MYR: 'RM',
  VND: '₫',
  BDT: '৳',
  COP: 'COL$',
  LKR: '₨',
  NGN: '₦',
  UGX: 'UGX',
  KES: 'Ksh',
  GHS: '₵',
  RON: 'lei',
  CUP: 'CUP',
  CRC: '₡',
  PEN: 'S/.',
  DZD: 'د.ج',
  JOD: 'د.ا',
  NAD: 'N$',
  HNL: 'L',
  GTQ: 'Q',
  MMK: 'K',
  BYN: 'Br',
  AZN: '₼',
  IQD: 'ع.د',
  DOP: 'RD$',
  LBP: 'ل.ل',
};

function getCurrency(code) {
  if (!code) {
    return currencies['INR'];
  }
  if (!currencies[code]) {
    return '';
  }
  return currencies[code];
}

export {getCurrency};
