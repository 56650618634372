import {SkeletonBodyText} from '@shopify/polaris';
import Card from '../../../../lib/Card';
import React, {useMemo} from 'react';
import {useState} from 'react';
import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {customerProfileData, timelineOfCustomer} from '../../../../api/customerProfile';
import OrderCard from './orderCard';
import {formatDate} from '../../../../features/format';
import useApiCall from '../../../../hooks/useApiCall';
import {calcDate} from '../../../../features/convertDates';
import Box from '../../../../lib/Box';
import Stack from '../../../../lib/Stack';
import Text from '../../../../lib/Text';
const TimeLine = () => {
  const params = useParams();

  const fetchOrders = async () => {
    return await timelineOfCustomer({customerId: params.customerId});
  };
  const fetchProfileData = async () => {
    return await customerProfileData({customerId: params.customerId});
  };

  const orders = useApiCall(fetchOrders, []);
  const _profileData = useApiCall(fetchProfileData, []);
  const profileData = useMemo(() => {
    return {data: (_profileData.data = _profileData.data[0] || {}), state: _profileData.state};
  }, [_profileData]);
  return (
    <>
      <Box>
        {profileData.state === 'Success' && (
          <Card>
            <Card.Section>
              {Boolean(profileData?.data?._lastOrderAt) ? (
                <Text variant="headingMd">
                  {calcDate(profileData.data._lastOrderAt, profileData.data.today) +
                    ' since last order on ' +
                    formatDate({value: new Date(profileData.data._lastOrderAt), removeTime: true})}
                </Text>
              ) : (
                <Text variant="headingMd">Not placed an order yet</Text>
              )}
            </Card.Section>
          </Card>
        )}
        {orders.state === 'Loading' ? (
          <Card>
            <SkeletonBodyText />
            <SkeletonBodyText />
            <SkeletonBodyText />
            <SkeletonBodyText />
            <SkeletonBodyText />
            <SkeletonBodyText />
            <SkeletonBodyText />
            <SkeletonBodyText />
          </Card>
        ) : orders.state === 'Error' ? (
          <Text>Not able to load data. please try again later</Text>
        ) : (
          <></>
        )}
        {orders.data.map(order => {
          return <OrderCard key={order._id} order={order} />;
        })}
        <Card>
          <Card.Section>
            <Stack vertical spacing="tight">
              <Text variant="headingMd">
                {profileData.data.firstName ? profileData.data.firstName : ''}{' '}
                {profileData.data.lastName ? profileData.data.lastName : ''}
              </Text>
              <Text variant="bodyMd" color="subdued">
                {profileData.state === 'Success'
                  ? profileData.data.signup
                    ? 'Signed up on ' + formatDate({value: new Date(profileData.data.signup)})
                    : ''
                  : ''}{' '}
              </Text>
            </Stack>
          </Card.Section>
        </Card>
      </Box>
    </>
  );
};

export default TimeLine;
