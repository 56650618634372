import {Collapsible, TextContainer, Link, ProgressBar, Icon} from '@shopify/polaris';
import {useState, useCallback, useEffect} from 'react';
import Card from '../../../lib/Card';
import Stack from '../../../lib/Stack';
import Button from '../../../lib/Button';
import Inline from '../../../lib/Inline';
import Box from '../../../lib/Box';
import Text from '../../../lib/Text';
import {AuthContext} from '../../../context/auth';
import {useContext} from 'react';
import {formatDate} from '../../../features/format';
import {useNavigate} from 'react-router-dom';
import {fetchDashboard, updateJobStatus} from '../../../api/reports';
import {CircleDownMajor, CircleUpMajor} from '@shopify/polaris-icons';
import {color} from 'd3';

const ShopifyData = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState('');
  const [jobId, setJobId] = useState('');
  const [orgId, setOrgId] = useState('');
  const [estTime, setEstTime] = useState('');
  const [isFetching, setIsFetching] = useState(true);
  const [updateJobTriggered, setUpdateJobTriggered] = useState(false);
  const {updateOrg} = useContext(AuthContext);

  useEffect(() => {
    setLoading(true);
    const fetch = () => {
      fetchDashboard().then(res => {
        if (res.status) {
          if (res.data === 'completed') {
            updateOrg(res.org);
            navigate('/dashboard');
            return;
          }
          setJobId(res.data.jobId);
          setOrgId(res.data.orgId);
          if (res.data) {
            setData(res.data);
          } else {
            setData([]);
          }
        } else {
          setData([]);
        }
        setLoading(false);
      });
    };
    fetch();
    const interval = setInterval(() => {
      fetch(); // fetch data every 1 minute
    }, 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  const convertEstTime = time => {
    if (!time) {
      return null;
    }
    time = Number(time);
    if (time <= 60) {
      return time > 1 ? `${Math.round(time)} minutes` : '';
    } else {
      let hours = Math.floor(time / 60);
      let remMins = Math.round(time - hours * 60);
      return remMins ? `${hours} hours ${remMins} minutes` : `${hours} hours`;
    }
  };
  useEffect(() => {
    if (data && Object.keys(data.result).length) {
      let arr = [];
      let result = data.result;
      for (let key in result) {
        if ((result[key].chCount * 100) / result[key].expectedCount < 98) {
          arr.push(result[key]);
        }
      }
      if (arr.length === 0 && updateJobTriggered === false) {
        updateJobStatus({params: {jobId, orgId}});
        setIsFetching(false);
        setUpdateJobTriggered(true);
      }
      setEstTime(convertEstTime(data.estTime));
    }
  }, [data]);

  return (
    <Card sectioned>
      {/* <Stack vertical> */}

      {!!data && (
        <Card.Section>
          <Box>
            <Text variant="bodyMd" fontWeight="bold" alignment="center">
              {`Fetching data from ${formatDate({
                value: new Date(data.from),
                removeTime: true,
              })} to ${formatDate({value: new Date(data.to), removeTime: true})}`}
            </Text>
          </Box>
        </Card.Section>
      )}
      {!!data &&
        Object.keys(data.result).map((x, i) => (
          <Card.Section key={i}>
            <Stack vertical="true">
              <Box>
                <Text variant="" fontWeight="bold">
                  {x[0].toUpperCase() + x.slice(1)}
                </Text>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div style={{flex: '0.90', marginRight: '1rem'}}>
                    <ProgressBar
                      color="success"
                      progress={(data.result[x].chCount * 100) / data.result[x].expectedCount}
                      size="small"
                    />
                  </div>
                  <div style={{flex: '0.1'}}>
                    {((data.result[x].chCount * 100) / data.result[x].expectedCount).toFixed(2)}%
                  </div>
                </div>
                <Text variant="headingXs" fontWeight="" color="subdued" as="h6">
                  {`${data.result[x].chCount} completed ${
                    data.result[x].expectedCount ? ` out of ${data.result[x].expectedCount}` : ''
                  }`}
                </Text>
              </Box>
            </Stack>
          </Card.Section>
        ))}
      <Card.Section>
        <Box>
          <Text variant="bodyMd" alignment="center">
            {isFetching
              ? `Estimated sync time is approximately ${
                  estTime ? ` ${estTime}` : `few minutes to go.`
                }`
              : `Processing your data, this will take a few minutes.`}
          </Text>
        </Box>
      </Card.Section>
      {/* </Stack> */}
    </Card>
  );
};

export default ShopifyData;
