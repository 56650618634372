import React, {useEffect} from 'react';
import {Button, Popover, Text, OptionList, Scrollable} from '@shopify/polaris';
import {useState, useCallback} from 'react';
const MultiSelectComponent = ({options, dispatch, type, value, label, everyHour}) => {
  const [popoverActive, setPopoverActive] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const togglePopoverActive = useCallback(
    () => setPopoverActive(popoverActive => !popoverActive),
    []
  );

  const activator = (
    <Button
      fullWidth
      textAlign="left"
      disclosure={popoverActive ? 'up' : 'down'}
      onClick={togglePopoverActive}
    >
      <Text color="subdued">
        {selectedOptions.length ? (
          <>
            {selectedOptions.join(', ')}{' '}
            {type === 'hour' ? 'hours' : type === 'specificDaysOfMonth' ? '' : ''}
          </>
        ) : (
          label
        )}
      </Text>
    </Button>
  );

  useEffect(() => {
    if (everyHour && everyHour === 'everyHour') {
      let v = ['everyHour'];
      setSelected([...v]);
    } else {
      setSelected([...value]);
    }
  }, [value]);
  useEffect(() => {
    let data = options.filter(a => selected.includes(a.value));
    data = data.map(a => a.label);
    setSelectedOptions([...data]);
  }, [selected]);

  return (
    <div>
      <Popover
        active={popoverActive}
        activator={activator}
        autofocusTarget="first-node"
        onClose={togglePopoverActive}
        preferredPosition="below"
        fullWidth
      >
        <Popover.Pane>
          <Scrollable style={{height: '300px'}}>
            <OptionList
              onChange={e => {
                setSelected(e);
                if (type === 'hour') {
                  let flag = false;
                  e.forEach(s => {
                    if (s === 'everyHour') {
                      flag = true;
                    }
                  });
                  if (flag) {
                    dispatch({type: 'everyHour', value: e});
                  } else {
                    dispatch({type: 'specificHour', value: e});
                  }
                } else {
                  dispatch({type, value: e});
                }
              }}
              options={options}
              selected={selected}
              allowMultiple
            />
          </Scrollable>
        </Popover.Pane>
      </Popover>
    </div>
  );
};

export default MultiSelectComponent;
