import {useRef, useState, useEffect, useLayoutEffect} from 'react';
import DefinationPopover from './definationPopover';
import {ItemsPopover} from './itemsPopover';
import {Text} from '@shopify/polaris';

const TableColumnValue = ({value, col, data}) => {
  const spanRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const [popoverActive, setPopoverActive] = useState(false);

  useEffect(() => {
    const spanElement = spanRef.current;
    spanElement?.parentNode?.parentNode.classList.add('cell');
    const parentElement = spanElement?.parentNode?.parentNode?.parentNode;
    if (spanElement && parentElement) {
      const maxSpanWidth = parentElement.offsetWidth;
      const usedSpanWidth = spanElement.getBoundingClientRect().width;

      if (usedSpanWidth > maxSpanWidth) {
        setIsOverflowing(true);
      }
    }
  }, [value]);
  return (
    <>
      {col.value !== 'items' ? (
        <DefinationPopover
          togglePopoverActive={() => {
            setPopoverActive(!popoverActive);
          }}
          popoverActive={popoverActive}
          activator={
            <Text variant="bodyMd" as="span">
              <span
                ref={spanRef}
                onMouseEnter={() => {
                  if (isOverflowing) {
                    setPopoverActive(true);
                  }
                }}
                onMouseLeave={() => {
                  setPopoverActive(false);
                }}
              >
                {value}
              </span>
            </Text>
          }
          text={
            <Text variant="bodyMd" as="span">
              {data[col.value]}
            </Text>
          }
        />
      ) : (
        <ItemsPopover items={value} />
      )}
    </>
  );
};

export default TableColumnValue;
