import {useState, useEffect} from 'react';
import config from '../config';

/** call api with debouncing behavior
 * @param {function} apiFunction function that perform api call that function should returning object having status and data from api
 * @param {array} dependencies  dependencies for call api function
 * @returns data having data retrieved from api response
 * @returns state of api call Loading, Success or Error
 */
const useApiCall = (apiFunction, dependencies, delay) => {
  const [data, setData] = useState({data: [], state: 'Loading'});
  const fetchData = async () => {
    try {
      const response = await apiFunction();
      const {status, ...allFields} = response;
      if (response.status) {
        setData({state: 'Success', ...allFields});
      } else {
        setData({data: [], state: 'Error', error: response.message});
      }
    } catch (error) {
      setData({data: [], state: 'Error', error: error.message});
    }
  };
  useEffect(() => {
    let isApiCalled = false;
    setData({data: [], state: 'Loading'});
    const apiTimeout = setTimeout(() => {
      if (!isApiCalled) {
        fetchData();
      }
    }, delay || config.DEBOUNCING_DELAY);

    return () => {
      isApiCalled = true;
      clearTimeout(apiTimeout);
    };
  }, [...dependencies]);

  return data;
};

export default useApiCall;
