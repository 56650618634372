import React from 'react';
import {SkeletonDisplayText} from '@shopify/polaris';
import Stack from '../../../../lib/Stack';
import Text from '../../../../lib/Text';
import Inline from '../../../../lib/Inline';
import Box from '../../../../lib/Box';
const ProductIndicator = ({values}) => {
  const boxWidth = values.length === 5 ? '18%' : '23%';
  return (
    <>
      <Box padding={'2'}>
        <Inline align="space-between" blockAlign="start">
          <Box width={boxWidth}>
            <Stack vertical spacing="tight">
              <Text color="subdued" variant="bodyMd">
                {values[0].label}
              </Text>
              {values[0].state === 'Loading' ? (
                <SkeletonDisplayText />
              ) : values[0].state === 'Success' ? (
                <Text variant="headingLg">{values[0].val}</Text>
              ) : values[0].state === 'Error' ? (
                <Text>Not able to load data</Text>
              ) : (
                <Text variant="headingLg">-</Text>
              )}
            </Stack>
          </Box>
          <Box
            width={boxWidth}
            borderInlineStartWidth="2"
            borderInlineStart="divider"
            paddingInlineStart={'3'}
          >
            <Stack vertical spacing="tight">
              <Text color="subdued" variant="bodyMd">
                {values[1].label}
              </Text>
              {values[1].state === 'Loading' ? (
                <SkeletonDisplayText />
              ) : values[1].state === 'Success' ? (
                <Text variant="headingLg">{values[1].val}</Text>
              ) : values[1].state === 'Error' ? (
                <Text>Not able to load data</Text>
              ) : (
                <Text variant="headingLg">-</Text>
              )}
            </Stack>
          </Box>
          <Box
            width={boxWidth}
            borderInlineStartWidth="2"
            borderInlineStart="divider"
            paddingInlineStart={'3'}
          >
            <Stack vertical spacing="tight">
              <Text color="subdued" variant="bodyMd">
                {values[2].label}
              </Text>
              {values[2].state === 'Loading' ? (
                <SkeletonDisplayText />
              ) : values[2].state === 'Success' ? (
                <Text variant="headingLg">{values[2].val}</Text>
              ) : values[2].state === 'Error' ? (
                <Text>Not able to load data</Text>
              ) : (
                <Text variant="headingLg">-</Text>
              )}
            </Stack>
          </Box>
          <Box
            width={boxWidth}
            borderInlineStartWidth="2"
            borderInlineStart="divider"
            paddingInlineStart={'3'}
          >
            <Stack vertical spacing="tight">
              <Text color="subdued" variant="bodyMd">
                {values[3].label}
              </Text>
              {values[3].state === 'Loading' ? (
                <SkeletonDisplayText />
              ) : values[3].state === 'Success' ? (
                <Text variant="headingLg">{values[3].val}</Text>
              ) : values[3].state === 'Error' ? (
                <Text>Not able to load data</Text>
              ) : (
                <Text variant="headingLg">-</Text>
              )}
            </Stack>
          </Box>
          {values[4] && (
            <Box
              width={boxWidth}
              borderInlineStartWidth="2"
              borderInlineStart="divider"
              paddingInlineStart={'3'}
            >
              <Stack vertical spacing="tight">
                <Text color="subdued" variant="bodyMd">
                  {values[4].label}
                </Text>
                {values[4].state === 'Loading' ? (
                  <SkeletonDisplayText />
                ) : values[4].state === 'Success' ? (
                  <Text variant="headingLg">{values[4].val}</Text>
                ) : values[4].state === 'Error' ? (
                  <Text>Not able to load data</Text>
                ) : (
                  <Text variant="headingLg">-</Text>
                )}
              </Stack>
            </Box>
          )}
        </Inline>
      </Box>
    </>
  );
};

export default ProductIndicator;
