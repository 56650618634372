import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import DefaultTable from '../../../../components/defaultIndexTable';
import {allOrdersColumns} from '../../columns';
import {ErrorBoundary} from '../../../../components/ErrorBoundary';
import useApiCall from '../../../../hooks/useApiCall';
import apiToCall from '../../../../features/apiToCall';
import Card from '../../../../lib/Card';

const Orders = () => {
  const [sortBy, setSortBy] = useState('date');
  const [sortOrder, setSortOrder] = useState('desc');
  const [page, setPage] = useState(1);
  const [queryValue, setQueryValue] = useState('');

  const params = useParams();

  const fetchOrders = async () => {
    let _params = {
      customerId: params.customerId,
      sortBy,
      sortOrder,
      page,
      search: queryValue,
      type: 'table',
    };
    let response = await apiToCall['orders'].api({params: _params});
    return response.data;
  };

  const ordersApiDependencies = [sortBy, sortOrder, page, queryValue];

  const orders = useApiCall(fetchOrders, ordersApiDependencies);

  return (
    <>
      <ErrorBoundary
        fallback={
          <Card>
            <Card.Section>
              <div>An error has occured</div>
            </Card.Section>
          </Card>
        }
      >
        <DefaultTable
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortExceptions={[]}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          select={false}
          page={page}
          setPage={setPage}
          resourceName={{
            singular: 'order',
            plural: 'orders',
          }}
          columns={allOrdersColumns}
          selectedFilters={[]}
          totalRows={orders.data.length ? orders.data[0].totalRows : 0}
          tableData={orders.data}
          state={orders.state}
          param={{name: 'orders'}}
          isSearchEnabled
          queryValue={queryValue}
          setQueryValue={setQueryValue}
          queryPlaceholder="Search by order name"
        />
      </ErrorBoundary>
    </>
  );
};

export default Orders;
