import {Modal} from '@shopify/polaris';
import React from 'react';

const HtmlPreviewModal = ({isOpen, toggle, htmlContent, title}) => {
  const activator = <div></div>;

  return (
    <Modal
      large
      activator={activator}
      open={isOpen}
      onClose={toggle}
      title={title ? title : 'Data'}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: toggle,
        },
      ]}
    >
      <Modal.Section>
        <iframe
          style={{height: '80vh', pointerEvents: 'none'}}
          srcDoc={htmlContent}
          title="Email Preview"
          width="100%"
          height="500px"
        ></iframe>
      </Modal.Section>
    </Modal>
  );
};

export default HtmlPreviewModal;
