import {Frame} from '@shopify/polaris';
import React, {useCallback, useState} from 'react';
import SettingsPage from './page';
import Navigation from '../../components/navigation';
import {useSaveChangesStore} from '../../app/store';
import SaveBar from '../../components/saveBar';
import DefaultTopBar from '../../components/topBar';
// import {useSearchParams} from 'react-router-dom';

const Settings = () => {
  const isUnchanged = useSaveChangesStore(state => state.isUnchanged);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const toggleMobileNavigationActive = useCallback(
    () => setMobileNavigationActive(mobileNavigationActive => !mobileNavigationActive),
    []
  );

  return (
    <>
      <Frame
        logo={{
          width: 124,
          contextualSaveBarSource: '/images/logo.svg',
          topBarSource: '/images/logo.svg',
        }}
        showMobileNavigation={mobileNavigationActive}
        onNavigationDismiss={toggleMobileNavigationActive}
        navigation={<Navigation />}
        topBar={
          isUnchanged ? (
            <SaveBar />
          ) : (
            <DefaultTopBar
              mobileNavigationActive={mobileNavigationActive}
              setMobileNavigationActive={setMobileNavigationActive}
            />
          )
        }
      >
        <SettingsPage />
      </Frame>
    </>
  );
};

export default Settings;
