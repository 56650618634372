import React, {useState} from 'react';
import DatesToAction from '../../pages/admin/Organizations/Components/DateToAction';
import {Modal} from '@shopify/polaris';
import ConfirmationModal from './ConfirmationModal';
import {formatDate} from '../../features/format';

const FetchOrganizationModal = ({isOpen, fetchRecords, orgId, toggle}) => {
  const [openConfirmModal, setOpenConfirmModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const activator = <div></div>;

  return (
    <>
      <Modal activator={activator} open={isOpen} onClose={toggle} title="Fetch Organization data">
        <Modal.Section>
          <DatesToAction
            orgId={orgId}
            title="Refetch historic data"
            onAction={params => {
              setOpenConfirmModal(params);
            }}
            allButtonRequried
            loading={loading}
          />
        </Modal.Section>
      </Modal>
      {Boolean(openConfirmModal) && (
        <ConfirmationModal
          isOpen={Boolean(openConfirmModal)}
          text={`Are you sure you want to fetch ${
            openConfirmModal.allTime
              ? 'All data'
              : `data from ${formatDate({
                  value: new Date(openConfirmModal.startDate),
                  removeTime: true,
                })} to ${formatDate({
                  value: new Date(openConfirmModal.endDate),
                  removeTime: true,
                })} `
          } `}
          toggle={() => setOpenConfirmModal(!openConfirmModal)}
          onProceed={async () => {
            setLoading(true);
            await fetchRecords({...openConfirmModal});
            setLoading(false);
            toggle();
          }}
        />
      )}
    </>
  );
};

export default FetchOrganizationModal;
