import React, {useRef, useState} from 'react';
import Page from '../../../../components/page';
import DefaultIndexTable from '../../../../components/defaultIndexTable';
import Inline from '../../../../lib/Inline';
import {formatDate} from '../../../../features/format';
import {useParams, useLocation} from 'react-router-dom';
import {toClickHouseDate} from '../../../../features/convertDates';
import useApiCall from '../../../../hooks/useApiCall';
import {getOrgsDiffrence} from '../../../../api/admin/compareData';
const diffColumns = [
  {title: 'Id', value: 'id'},
  {title: 'Created at', value: 'created_at', type: 'date'},
];
// const diffData = {data: [], state: 'Success'};

const Diffrences = () => {
  const params = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sinceIds = useRef([]);
  const [page, setPage] = useState(1);
  const fetchDiffData = async () => {
    let sdate = decodeURIComponent(searchParams.get('startDate'));
    let edate = decodeURIComponent(searchParams.get('endDate'));
    let diffType = searchParams.get('diffType');
    let type = searchParams.get('type');
    let sinceId = sinceIds.current.length ? sinceIds.current[sinceIds.current.length - 1] : '';
    let res = await getOrgsDiffrence({
      orgId: params.organizationId,
      startDate: sdate,
      endDate: edate,
      sinceId,
      diffType,
      type,
    });
    return res;
  };

  const diffData = useApiCall(fetchDiffData, [location, params, page]);

  return (
    <>
      <Page title="Diffrences">
        <DefaultIndexTable
          disableSort
          select={false}
          page={page}
          setPage={val => {
            if (page < val) {
              sinceIds.current.push(diffData?.data[diffData?.data?.length - 1]?.id);
            } else if (page > val) {
              sinceIds.current.pop();
            }
            setPage(val);
          }}
          resourceName={{
            singular: 'metrics',
            plural: 'metrics',
          }}
          columns={diffColumns}
          selectedFilters={[]}
          totalRows={diffData.data.length > 0 ? searchParams.get('diffCount') : 0}
          tableData={diffData.data.length > 0 ? diffData.data : []}
          state={diffData.state}
          param={{}}
          valueTypes={{
            date: (data, col) => {
              return (
                <Inline align="start">
                  {formatDate({
                    value: new Date(data[col.value]),
                    removeTime: false,
                    seconds: true,
                  })}
                </Inline>
              );
            },
          }}
        />
      </Page>
    </>
  );
};

export default Diffrences;
