import {TextField, Icon, DataTable, Link, Tooltip} from '@shopify/polaris';
import Card from '../../../lib/Card';
import Inline from '../../../lib/Inline';
import React, {useCallback, useEffect, useState} from 'react';
import Page from '../../../components/page';
import Select from './components/select';
import {useNavigate} from 'react-router-dom';
import {RequestApi} from '../../../api/requestReports';
import {formatDate} from '../../../features/format';
import getDataTableLoading from '../../../features/dataTableLoadingRows';
import ColdStart from '../../coldStart';
import {track} from '../../../features/track';
import InputWithSearch from '../../../components/InputWithSearch';
import {tooltipText} from '../dashboard/tooltipText';
import Box from '../../../lib/Box';
import Button from '../../../lib/Button';
const MetricsPage = () => {
  const [popoverActive, setPopoverActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [search, setSearch] = useState('');
  const [sortOrder, setSortOrder] = useState(1);
  const [sortBy, setSortBy] = useState('reportName');
  const togglePopoverActive = useCallback(
    () => setPopoverActive(popoverActive => !popoverActive),
    []
  );
  const activator = (
    <Button textAlign="left" fullWidth onClick={togglePopoverActive} disclosure>
      {selectedCategory}
    </Button>
  );
  const [error, setError] = useState(false);

  useEffect(() => {
    track('Page View', {
      name: 'Metrics',
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    RequestApi.requestedReports({
      categorize: false,
      type: 'pre-built',
      category: selectedCategory,
      search,
      sortOrder,
      sortBy,
    })
      .then(response => {
        if (!response.status) {
          setError(true);
        } else {
          let reports = response.data.map(report => [
            <Tooltip content={tooltipText[report.slag]}>
              <Link
                url={`/metrics/${report.slag}`}
                removeUnderline
                onClick={event => {
                  event.preventDefault();
                  navigate(`/metrics/${report.slag}`);
                }}
                key="emerald-silk-gown"
              >
                <span style={{cursor: 'pointer'}}>{report.reportName}</span>
              </Link>
            </Tooltip>,
            <>{report.category}</>,
            <>{report.lastViewed && formatDate({value: new Date(report.lastViewed)})}</>,
            <>{report.lastViewedBy}</>,
          ]);

          setRows([...reports]);
        }
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        setError(true);
      });
  }, [selectedCategory, search, sortBy, sortOrder]);

  return (
    <>
      <Page title="Metrics" subtitle="View, export and schedule key performance metrics">
        <Box width="100%" paddingBlockEnd={'4'}>
          {error ? (
            <ColdStart page="page8" />
          ) : (
            <Card>
              <Box paddingBlockStart={'3'}>
                <Inline align="space-between">
                  <Box width="75%" padding={'3'}>
                    <InputWithSearch queryValue={search} setQueryValue={setSearch} />
                  </Box>
                  <Box width="21%">
                    <Inline align="end">
                      <Box width="90%" paddingInlineEnd={'3'}>
                        <Select
                          options={[
                            {
                              content: 'All',
                              active: selectedCategory === 'All',
                              onAction: () => {
                                setSelectedCategory('All');
                                togglePopoverActive();
                              },
                            },
                            {
                              content: 'Customers',
                              active: selectedCategory === 'Customers',

                              onAction: () => {
                                setSelectedCategory('Customers');
                                togglePopoverActive();
                              },
                            },
                            {
                              content: 'Orders',
                              active: selectedCategory === 'Orders',
                              onAction: () => {
                                setSelectedCategory('Orders');
                                togglePopoverActive();
                              },
                            },
                          ]}
                          popoverActive={popoverActive}
                          togglePopoverActive={togglePopoverActive}
                          activator={activator}
                        />
                      </Box>
                    </Inline>
                  </Box>
                </Inline>
              </Box>
              <Box paddingBlockStart={'1'}>
                <DataTable
                  sortable={[true, false, true, false, false]}
                  columnContentTypes={['text', 'text', 'text', 'text']}
                  headings={['Reports', 'Category', 'Last viewed', 'Last Viewed By']}
                  rows={loading ? getDataTableLoading(4) : rows}
                  defaultSortDirection="ascending"
                  initialSortColumnIndex={0}
                  onSort={(e, i) => {
                    let order;
                    let column;
                    if (e === 0) {
                      column = 'reportName';
                    }
                    if (e === 2) {
                      column = 'lastViewed';
                    }
                    if (i === 'ascending') {
                      order = -1;
                    } else {
                      order = 1;
                    }
                    setSortOrder(order);
                    setSortBy(column);
                  }}
                />
              </Box>
            </Card>
          )}
        </Box>
      </Page>
    </>
  );
};

export default MetricsPage;
