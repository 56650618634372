import React, {useEffect, useState} from 'react';

import {Card, Box, IndexTable, Link, EmptySearchResult, Text, Tooltip} from '@shopify/polaris';
import Inline from '../lib/Inline';
import {Link as RouterLink} from 'react-router-dom';
import {formatAmount, formatDecimalNumber, formatNumber, formatDate} from '../features/format';
import apiToCall from '../features/apiToCall';
import ColumnHeading from './ColumnHeading';
import {verifyInventory} from '../features/helper';
import TableColumnValue from './TableColumnValue';

const VerticalIndexTable = ({
  valueTypes = {},
  columns,
  tableData,
  onRowClick,
  resourceName,
  select,
  sortOrder,
  sortBy,
  durationFilter,
  state,
  param,
  setSelected,
  isSearchEnabled,
  headings,
}) => {
  const [colHeading, setColHeading] = useState([{title: ''}]);
  const [headingData, setHeadingData] = useState([]);
  isSearchEnabled =
    (param &&
      param.name &&
      (param.name === 'segments' || !apiToCall[param.name].isSearchDisabled)) ||
    isSearchEnabled;

  const valueType = {
    image: (data, col) => {
      return (
        <Box>
          <img
            alt={'Not found'}
            width={'60px'}
            height={'60px'}
            src={
              data[col.value] ||
              'https://user-images.githubusercontent.com/133949320/244652984-236bc15c-cc91-4ac0-ba33-1333c0d3ae5c.png'
            }
          />
        </Box>
      );
    },
    amount: (data, col) => {
      return <Inline align="start">{formatAmount({value: data[col.value]})}</Inline>;
    },
    decimal: (data, col) => {
      return <Inline align="end">{formatDecimalNumber({value: data[col.value]})}</Inline>;
    },
    number: (data, col) => {
      return <Inline align="end">{formatNumber({value: data[col.value]})}</Inline>;
    },
    percent: (data, col) => {
      return <Inline align="end">{formatDecimalNumber({value: data[col.value]}) + ' %'}</Inline>;
    },
    date: (data, col) => {
      return data[col.value] ? (
        <Inline align="end">
          {formatDate({value: new Date(data[col.value]), removeTime: true})}
        </Inline>
      ) : (
        <Inline align="center">-</Inline>
      );
    },
    nestedDate: (data, col) => {
      const properties = col.value.split('.');
      let result = data;

      for (let prop of properties) {
        result = result[prop];
      }
      return <Inline align="end">{formatDate({value: new Date(result), removeTime: true})}</Inline>;
    },
    nestedValue: (data, col) => {
      const properties = col.value.split('.');
      let result = data;

      for (let prop of properties) {
        result = result[prop];
      }
      return <Inline align="end">{result}</Inline>;
    },
    customerID: (data, col) => {
      return !data['customerID'] ? (
        'Guest User'
      ) : (
        <RouterLink style={{textDecoration: 'none'}} to={`/customers/${data['customerID']}`}>
          {data[col.value]?.trim() ? data[col.value] : 'No name'}
        </RouterLink>
      );
    },
    checkoutURL: (data, col) => {
      return (
        <Link url={data[col.value]} external removeUnderline>
          Checkout Cart URL
        </Link>
      );
    },
    variantID: (data, col) => {
      return (
        <RouterLink style={{textDecoration: 'none'}} to={`/product/${data['variantId']}`}>
          {data[col.value]}{' '}
        </RouterLink>
      );
    },
    jobID: (data, col) => {
      return (
        <RouterLink style={{textDecoration: 'none'}} to={`/admin/jobs/${data['_id']}`}>
          {data[col.value]}{' '}
        </RouterLink>
      );
    },
    productLink: (data, col) => {
      return (
        <RouterLink
          style={{textDecoration: 'none'}}
          to={`/product/${data['variantId']}`}
          onClick={() => {
            if (setSelected) {
              setSelected(0);
            }
          }}
        >
          {data[col.value]}{' '}
        </RouterLink>
      );
    },

    // '\u00A0' is used to add a space after comma(',') because following function actually returns
    // an array which is being converted into a string by the component and the extra space in
    // javascript is being ignored, also '&nbsp' is not working here due to the conditon. Hence, we needed something
    // from JS and not HTML
    variantIDs: (data, col) => {
      let variantIDs = data.variantIDs.split(',');
      let skus = data.skus.split(',');
      let length = variantIDs.length;
      return variantIDs.map((x, i) => (
        <>
          <RouterLink style={{textDecoration: 'none'}} to={`/product/${x}`}>
            {skus[i]}
          </RouterLink>
          {i < length - 1 ? `,\u00A0` : ''}
        </>
      ));
    },
    rfmCategory: (data, col) => {
      return (
        <RouterLink
          style={{textDecoration: 'none'}}
          to={`/metrics/rfm-analysis/${data[col.value]}`}
        >
          {data[col.value]}
        </RouterLink>
      );
    },
    weeksOfInventory: (data, col) => {
      let value = formatNumber({value: data[col.value]});
      if (!verifyInventory(data)) value = 'NA';
      return <Inline align="end">{value}</Inline>;
    },

    ...valueTypes,
  };

  useEffect(() => {
    const arr = tableData.map(x => ({
      title:
        x.month === 'total'
          ? `Total`
          : new Date(x.month + '-01').toLocaleString('en-US', {month: 'long'}),
      value: x.month,
      type: 'date',
    }));
    arr.unshift({title: `${durationFilter} by month`, value: `${durationFilter}`});
    setHeadingData(arr);
  }, [tableData]);

  useEffect(() => {
    if (headingData.length) {
      setColHeading(
        headingData.map((col, i) => {
          return {
            title: (
              <ColumnHeading
                key={`colHeading-${i}`}
                col={col}
                sortBy={sortBy}
                sortOrder={sortOrder}
                durationFilter={durationFilter}
              />
            ),
            id: `colHeading-${i}`,
          };
        })
      );
    }
  }, [headingData]);

  const rowMarkup = columns.map((col, index) => (
    <IndexTable.Row
      id={col.id || col._id}
      key={index}
      position={index}
      onClick={onRowClick && onRowClick}
    >
      <IndexTable.Cell className="cell cohort-cell" key={index}>
        <div
          style={
            col.value === 'grossRevenue' ||
            col.value === 'grossProfit' ||
            col.value === 'netRevenue'
              ? {
                  backgroundColor: 'rgba(105, 205, 255,0.2)',
                  borderRadius: '2px',
                  textAlign: 'start',
                  margin: '1px',
                  padding: 'var(--p-space-2) var(--p-space-4)',
                }
              : {
                  borderRadius: '2px',
                  textAlign: 'start',
                  margin: '1px',
                  padding: 'var(--p-space-2) var(--p-space-4)',
                }
          }
        >
          <Text variant="bodyMd" as="span">
            {col.title}
          </Text>
        </div>
        {/* <TableColumnValue value={col.title} col={col} key={index} data={col} /> */}
      </IndexTable.Cell>
      {tableData.map((data, i) => {
        let value = col.type ? valueType[col.type](data, col) : data[col.value];
        return (
          <IndexTable.Cell className="cell cohort-cell" key={i}>
            <div
              style={
                col.value === 'grossRevenue' ||
                col.value === 'grossProfit' ||
                col.value === 'netRevenue'
                  ? {
                      backgroundColor: 'rgba(105, 205, 255,0.2)',
                      borderRadius: '2px',
                      textAlign: 'start',
                      margin: '1px',
                      padding: 'var(--p-space-2) var(--p-space-4)',
                    }
                  : {
                      borderRadius: '2px',
                      textAlign: 'start',
                      margin: '1px',
                      padding: 'var(--p-space-2) var(--p-space-4)',
                    }
              }
            >
              {col.value === 'customCost' ? (
                <Tooltip
                  content={
                    <div>
                      {Object.keys(data['customCostObj']).map((key, index) => (
                        <div style={{margin: '5px 0'}} key={index}>
                          <p>
                            <strong>{key}</strong>:{' '}
                            {formatAmount({value: data['customCostObj'][key]})}
                          </p>
                        </div>
                      ))}
                    </div>
                  }
                  preferredPosition="above"
                >
                  <Text variant="bodyMd" as="span">
                    {value}
                  </Text>
                </Tooltip>
              ) : (
                <Text variant="bodyMd" as="span">
                  {value}
                </Text>
              )}
            </div>
          </IndexTable.Cell>
        );
      })}
    </IndexTable.Row>
  ));

  return (
    <>
      <Card>
        <IndexTable
          resourceName={resourceName}
          itemCount={tableData.length}
          hasMoreItems
          headings={colHeading}
          selectable={select}
          loading={!state || state === 'Loading'}
          lastColumnSticky
          emptyState={
            <EmptySearchResult
              title={
                state && state !== 'Loading' && state === 'Error'
                  ? 'Not able to load data'
                  : state === 'Loading'
                  ? ''
                  : 'No metrics found'
              }
              description={
                state === 'Error'
                  ? 'Please try again later'
                  : !state || state === 'Loading'
                  ? ''
                  : 'Try changing the filters or search term'
              }
              withIllustration={state && state !== 'Loading' && state !== 'Error'}
            />
          }
        >
          {rowMarkup}
        </IndexTable>
      </Card>
    </>
  );
};

export default VerticalIndexTable;
