import {Text, IndexTable, EmptySearchResult} from '@shopify/polaris';
import Card from '../../../../lib/Card';
import React from 'react';
import TableColumnValue from '../../../../components/TableColumnValue';

import Box from '../../../../lib/Box';
const resourceName = {
  singular: 'product',
  plural: 'products',
};
const MostCard = ({title, data, state}) => {
  const rowMarkup = data.map(({variantId, productType, skuTitle, quantity}, index) => (
    <IndexTable.Row id={variantId} key={variantId} position={index}>
      <IndexTable.Cell className="mostCell" key={index}>
        <TableColumnValue
          value={
            <Text variant="bodyMd" fontWeight="bold" as="span">
              {' '}
              {productType}{' '}
            </Text>
          }
          col={{title: 'Product', value: 'productType'}}
          key={index}
          data={data[index]}
        />
      </IndexTable.Cell>
      <IndexTable.Cell className="cell" key={index}>
        <TableColumnValue
          value={skuTitle}
          col={{title: 'Product', value: 'skuTitle'}}
          key={index}
          data={data[index]}
        />
      </IndexTable.Cell>
      <IndexTable.Cell>{quantity}</IndexTable.Cell>
    </IndexTable.Row>
  ));
  return (
    <>
      <Card>
        <Card.Section>
          <Text variant="headingMd">{title}</Text>
        </Card.Section>
        <Box borderBlockStart="divider" borderBlockStartWidth="1">
          <IndexTable
            resourceName={resourceName}
            itemCount={data.length}
            headings={[{title: 'Category'}, {title: 'Product'}, {title: 'Order count'}]}
            selectable={false}
            loading={state === 'Loading'}
            emptyState={
              <EmptySearchResult
                title={
                  state && state !== 'Loading' && state === 'Error'
                    ? 'Not able to load data'
                    : 'No products found'
                }
                description={
                  state === 'Error'
                    ? 'Please try again later'
                    : !state || state === 'Loading'
                    ? ''
                    : ''
                }
                withIllustration={state && state !== 'Loading' && state !== 'Error'}
              />
            }
          >
            {rowMarkup}
          </IndexTable>
        </Box>
      </Card>
    </>
  );
};

export default MostCard;
