import React, {useMemo, useState} from 'react';
import {replaceVariables} from './Modals/utils/functions';
import {TextField} from '@shopify/polaris';
import MultiSelect from './multiSelect';
import Card from '../lib/Card';
import Box from '../lib/Box';
import Inline from '../lib/Inline';
const variables = [
  {label: 'customerName', value: '{{customerName}}'},
  {label: 'orderName', value: '{{orderName}}'},
  {label: 'firstProductTitle', value: '{{productTitle}}'},
  {label: 'productTitles', value: '{{productTitles}}'},
  {label: 'productImages', value: '{{productImages}}'},
  {label: 'orderDate', value: '{{orderDate}}'},
];
const WhatsappTemplatePreviewEdit = ({template, editMode, setTemplate}) => {
  const [_template, _setTemplate] = useState(template);
  const [textAreaPosition, setTextAreaPosition] = useState();

  const templateString = useMemo(() => {
    return replaceVariables(template.body);
  }, [template]);

  const handleVariableSelect = selectedOption => {
    const newText =
      template.body.substring(0, textAreaPosition) +
      selectedOption +
      template.body.substring(textAreaPosition);
    setTemplate({...template, body: newText});
  };

  function handleFocus(event) {
    const textarea = event.target;
    const cursorPosition = textarea.selectionStart;
    setTextAreaPosition(cursorPosition);
  }
  return (
    <>
      <Card.Section>
        <Box paddingBlockEnd={'3'}>
          {editMode && (
            <Inline align="end">
              <MultiSelect
                options={variables}
                selected={''}
                disableMultiple
                setSelected={e => {
                  handleVariableSelect(e);
                }}
                title="Insert variables"
                setChecked={() => {}}
              />
            </Inline>
          )}
        </Box>

        <TextField
          focused
          disabled={!editMode}
          onBlur={e => {
            e.target.focus();
          }}
          multiline={4}
          value={editMode ? template.body : templateString}
          onChange={e => {
            setTemplate({...template, body: e});
          }}
          onFocus={e => {
            handleFocus(e);
          }}
        />
      </Card.Section>
    </>
  );
};

export default WhatsappTemplatePreviewEdit;
