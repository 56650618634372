import {DatePicker, Popover, Box, TextField, Icon} from '@shopify/polaris';
import React, {useEffect} from 'react';
import {useCallback} from 'react';
import {useState} from 'react';
import {CalendarMajor} from '@shopify/polaris-icons';
const dateToText = d => {
  function z(n) {
    return (n < 10 ? '0' : '') + n;
  }
  let year = d.getFullYear();
  let month = d.getMonth() + 1;
  let day = d.getDate();
  return `${year}-${z(month)}-${z(day)}`;
};
const DefaultDatePicker = ({dispatch, type, indexes, value}) => {
  const [{month, year}, setDate] = useState({month: 0, year: 2022});
  const [selectedDates, setSelectedDates] = useState({
    start: new Date(value),
    end: new Date(value),
  });
  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(
    () => setPopoverActive(popoverActive => !popoverActive),
    []
  );
  const activator = (
    <TextField
      prefix={<Icon source={CalendarMajor} />}
      placeholder="Select Date"
      value={value}
      onFocus={() => {
        setPopoverActive(true);
      }}
      onChange={e => {
        dispatch({type, indexes, value: e});
      }}
    />
  );

  useEffect(() => {
    if (value) {
      setSelectedDates({
        start: new Date(value),
        end: new Date(value),
      });
      let month = new Date(value).getMonth();
      let year = new Date(value).getFullYear();
      setDate({month, year});
    } else {
      setSelectedDates({
        start: new Date(),
        end: new Date(),
      });
      let month = new Date().getMonth();
      let year = new Date().getFullYear();
      setDate({month, year});
    }
  }, [value]);
  const handleMonthChange = useCallback((month, year) => setDate({month, year}), []);
  return (
    <>
      <div>
        <Popover
          active={popoverActive}
          activator={activator}
          autofocusTarget="first-node"
          preferredAlignment="left"
          onClose={togglePopoverActive}
          fluidContent
        >
          <div style={{maxWidth: '20rem'}}>
            <Popover.Pane>
              <Box padding={'8'}>
                <DatePicker
                  month={month}
                  year={year}
                  onChange={e => {
                    dispatch({type, indexes, value: dateToText(e.start)});
                    setSelectedDates(e);
                    togglePopoverActive();
                  }}
                  onMonthChange={handleMonthChange}
                  selected={selectedDates}
                />
              </Box>
            </Popover.Pane>
          </div>
        </Popover>
      </div>
    </>
  );
};

export default DefaultDatePicker;
