import {Tooltip} from '@shopify/polaris';
import React, {useEffect, useState} from 'react';
import apiToCall from '../../../features/apiToCall';
import {formatAmount, formatNumber} from '../../../features/format';
import {Link as RouterLink} from 'react-router-dom';
import DefinationPopover from '../../../components/definationPopover';
import {generateInfoText, generateSKUText} from '../../../features/generateText';
import Inline from '../../../lib/Inline';
import Box from '../../../lib/Box';
import Stack from '../../../lib/Stack';
import Text from '../../../lib/Text';
const DashboardTable = ({tabData, param, symbol}) => {
  const [data, setData] = useState([]);
  const [infoPopoverActive, setInfoPopoverActive] = useState(null);

  useEffect(() => {
    setData(tabData);
  }, [tabData]);
  return (
    <>
      <Stack.Item>
        {data.map((value, i) => {
          let key = apiToCall[param.name].isSnapshotSpecial
            ? apiToCall[param.name].snapshotXAxis
            : apiToCall[param.name].xAxisValue;
          let xAxisValue = value[key];
          if (key === 'skuTitle') {
            xAxisValue = value['variantId'] ? (
              <div className="customCell">
                <Tooltip content={value[key]} preferredPosition="above" hoverDelay={100}>
                  <RouterLink
                    style={{textDecoration: 'none'}}
                    to={`/product/${value['variantId']}`}
                  >
                    {value[key]}
                  </RouterLink>
                </Tooltip>
              </div>
            ) : (
              generateSKUText(value)
            );
          }
          return (
            <Box key={i} paddingBlockEnd={'2'}>
              <Inline align="space-between" wrap={false}>
                <div className="customCell">
                  {value.subTitle ? (
                    <>
                      <Stack vertical spacing="extraTight">
                        <Text>{xAxisValue}</Text>
                        <Text color="subdued">{value.subTitle}</Text>
                      </Stack>
                    </>
                  ) : apiToCall[param.name].needDashboardInfoText ? (
                    <DefinationPopover
                      text={generateInfoText(xAxisValue)}
                      togglePopoverActive={() => {
                        setInfoPopoverActive(null);
                      }}
                      popoverActive={infoPopoverActive === i}
                      activator={
                        <div
                          onMouseEnter={() => {
                            setInfoPopoverActive(i);
                          }}
                          onMouseLeave={() => {
                            setInfoPopoverActive(null);
                          }}
                        >
                          <div style={{cursor: 'help', borderBottom: '2px dotted #c4cdd5'}}>
                            {xAxisValue}
                          </div>
                        </div>
                      }
                    />
                  ) : (
                    <Text>{xAxisValue}</Text>
                  )}
                </div>

                <Text>
                  {symbol
                    ? formatAmount({
                        value:
                          +value[
                            apiToCall[param.name].isSnapshotSpecial
                              ? apiToCall[param.name].snapshotYAxis
                              : apiToCall[param.name].yAxisValue
                          ],
                      })
                    : formatNumber({
                        value:
                          +value[
                            apiToCall[param.name].isSnapshotSpecial
                              ? apiToCall[param.name].snapshotYAxis
                              : apiToCall[param.name].yAxisValue
                          ],
                      })}
                </Text>
              </Inline>
            </Box>
          );
        })}
      </Stack.Item>
    </>
  );
};

export default DashboardTable;
