import {Spinner, Tabs} from '@shopify/polaris';
import Box from '../../../lib/Box';
import {saveAs} from 'file-saver';
import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {customerProfileData} from '../../../api/customerProfile';
import Page from '../../../components/page';
import Orders from './orders';
import Overview from './overview';
import TimeLine from './timeline';
import {track} from '../../../features/track';
import apiToCall from '../../../features/apiToCall';
import Alert from '../../../components/alert';
import {getUserDetails} from '../../../features/helper';
import DownloadModal from '../../../components/Modals/downloadModal';
const CustomerProfile = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [selected, setSelected] = useState(0);
  const [profileData, setProfileData] = useState({data: {}, state: 'Loading'});
  const [downloadState, setDownloadState] = useState('Success');
  const [showAlert, setShowAlert] = useState(false);
  const [openDownloadModal, setOpenDownloadModal] = useState(false);

  const handleTabChange = useCallback(selectedTabIndex => setSelected(selectedTabIndex), []);
  const tabs = [
    {
      id: 'overview',
      content: 'Overview',
      accessibilityLabel: 'overview',
      panelID: 'overview-content-1',
    },
    {
      id: 'timeline',
      content: 'Timeline',
      accessibilityLabel: 'timeline',
      panelID: 'timeline-content-1',
    },
    {
      id: 'orders',
      content: 'Orders',
      accessibilityLabel: 'orders',
      panelID: 'orders-content-1',
    },
  ];
  useEffect(() => {
    track('Page View', {
      name: 'Customer Profile',
    });
    setProfileData({state: 'Loading', data: {}});
    customerProfileData({customerId: params.customerId}).then(res => {
      setProfileData({data: res.data[0], state: 'Success'});
    });
  }, []);

  const handleDownload = async email => {
    setDownloadState('Loading');
    setShowAlert('');
    let response = await apiToCall['orders'].api({
      params: {
        customerId: params.customerId,
        type: 'download',
        email,
      },
    });
    if (response.data?.data) {
      setShowAlert(`Orders data will be mailed to you shortly on ${email}`);
      setDownloadState('Success');
    }
  };

  return (
    <>
      <Page
        title={
          profileData.state === 'Success' ? (
            profileData.data.firstName || profileData.data.lastName ? (
              `${profileData.data.firstName || ''} ${profileData.data.lastName || ''}`
            ) : (
              'No name'
            )
          ) : (
            <Spinner size="small" />
          )
        }
        breadcrumbs={[
          {
            content: 'customer',
            onAction: () => {
              navigate(-1);
            },
          },
        ]}
        subtitle={`${profileData?.data?.city ? profileData.data.city + ' ' : ''} ${
          profileData?.data?.province ? profileData.data.province + ',' : ''
        } ${profileData?.data?.country ? profileData.data.country : ''} ${
          profileData?.data?.lifetime ? '• Customer for ' + profileData.data.lifetime : ''
        }`}
        secondaryActions={
          tabs[selected].id === 'orders' && [
            {
              content: downloadState === 'Loading' ? <Spinner size="small" /> : 'Download',
              onAction: () => {
                setOpenDownloadModal(true);
              },
            },
          ]
        }
      >
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          <Box paddingBlockStart={'8'}>
            {tabs[selected].id === 'overview' ? (
              <Overview />
            ) : tabs[selected].id === 'timeline' ? (
              <TimeLine />
            ) : tabs[selected].id === 'orders' ? (
              <Orders />
            ) : (
              <></>
            )}
          </Box>
        </Tabs>
        {showAlert && (
          <Alert
            contents={showAlert}
            init={true}
            error={showAlert === 'Something Went Wrong'}
            duration={1000}
          />
        )}
      </Page>
      <DownloadModal
        isOpen={openDownloadModal}
        toggle={() => setOpenDownloadModal(!openDownloadModal)}
        handleDownload={handleDownload}
      />
    </>
  );
};

export default CustomerProfile;
