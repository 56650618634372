import React from 'react';
import {Box as PolarisBox} from '@shopify/polaris';

const Box = React.forwardRef(({children, ...props}, ref) => {
  return (
    <PolarisBox {...props} ref={ref}>
      {children}
    </PolarisBox>
  );
});

export default Box;
