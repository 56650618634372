import {SkeletonBodyText} from '@shopify/polaris';
import React from 'react';
import {formatNumber} from '../../../../features/format';
import Inline from '../../../../lib/Inline';
import Box from '../../../../lib/Box';
import Stack from '../../../../lib/Stack';
import Text from '../../../../lib/Text';

const FrequentlyBaughtTogether = ({data, state}) => {
  return (
    <>
      <Box>
        <Box paddingBlockEnd={'5'}>
          <Text variant="headingMd">Frequently Bought Together</Text>
        </Box>
        {state === 'Loading' ? (
          <>
            <Box paddingBlockEnd={'5'}>
              <SkeletonBodyText />
            </Box>
            <Box paddingBlockEnd={'5'}>
              <SkeletonBodyText />
            </Box>
            <Box paddingBlockEnd={'5'}>
              <SkeletonBodyText />
            </Box>
            <Box paddingBlockEnd={'5'}>
              <SkeletonBodyText />
            </Box>
            <Box paddingBlockEnd={'5'}>
              <SkeletonBodyText />
            </Box>
          </>
        ) : state === 'Success' ? (
          <>
            {data.length ? (
              <>
                {' '}
                {data.map(p => {
                  return (
                    <Box paddingBlockEnd={'5'}>
                      <Inline>
                        <img
                          alt={'Not found'}
                          width={'90px'}
                          src={
                            p['productImage'] ||
                            'https://user-images.githubusercontent.com/133949320/244652984-236bc15c-cc91-4ac0-ba33-1333c0d3ae5c.png'
                          }
                        />
                        <Stack vertical spacing="extraTight">
                          <Text>{p.title}</Text>
                          <Text color="subdued"> {p.sku}</Text>
                          <Text color="subdued">
                            {p.quantity
                              ? `Bought together ${formatNumber({value: p.quantity})} times`
                              : ''}
                          </Text>
                        </Stack>
                      </Inline>
                    </Box>
                  );
                })}
              </>
            ) : (
              <Box paddingBlockEnd={'5'}>
                <Text>No data found</Text>
              </Box>
            )}
          </>
        ) : (
          <Box paddingBlockEnd={'5'}>
            <Text>Not able to load data. please try again later</Text>
          </Box>
        )}
      </Box>
    </>
  );
};
export default FrequentlyBaughtTogether;
