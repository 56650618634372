import {SkeletonBodyText} from '@shopify/polaris';

const getDataTableLoading = noOfCols => {
  const loadingRows = [];

  for (let i = 0; i < 10; i++) {
    let row = [];
    for (let j = 0; j < noOfCols; j++) {
      row.push(<SkeletonBodyText />);
    }
    loadingRows.push(row);
  }
  return loadingRows;
};

export default getDataTableLoading;
