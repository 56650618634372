import axios from './axios';
import config from '../config';

const {BASE_URL} = config;
export async function getCsv(params) {
  try {
    const response = await axios.post(`${BASE_URL}/csv/convert`, params);
    return response;
  } catch (err) {
    return err.response.data;
  }
}
