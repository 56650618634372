import React from 'react';
import {Button as PolarisButton} from '@shopify/polaris';

const Button = React.forwardRef(({children, ...props}, ref) => {
  return (
    <PolarisButton ref={ref} {...props}>
      {children}
    </PolarisButton>
  );
});

export default Button;
