import axios from './axios';
import config from '../config';

const {BASE_URL} = config;

export async function getConnectionTemplate(params) {
  try {
    const response = await axios.get(`${BASE_URL}/connections/get-templates`, params);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function getConnections(params) {
  try {
    const response = await axios.get(`${BASE_URL}/connections/get-connections`, params);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}

export async function deleteConnection(id) {
  try {
    const response = await axios.delete(`${BASE_URL}/connections/delete-connection/${id}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function updateConnection(id, data) {
  try {
    const response = await axios.put(`${BASE_URL}/connections/update-connection/${id}`, data);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function addSegmentConnection(data) {
  try {
    const response = await axios.post(`${BASE_URL}/connections/add-segment-connection`, data);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function updateSegmentConnection(data) {
  try {
    const response = await axios.put(`${BASE_URL}/connections/update-segment-connection/`, data);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function createConnection(data) {
  try {
    const response = await axios.post(`${BASE_URL}/connections/create-connection`, data);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}

export async function connectionAuthInitiate(type) {
  try {
    const response = await axios.get(`${BASE_URL}/connections/authorize/${type}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}

export async function integrate(data) {
  try {
    const response = await axios.post(`${BASE_URL}/integrate/create-integration`, data);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function getTemplateDetail(type) {
  try {
    const response = await axios.get(`${BASE_URL}/integrate/get-template-detail?type=${type}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function getExportStatus(params) {
  const response = await axios.get(`${BASE_URL}/connections/export-status`, params);
  return response.data;
}
