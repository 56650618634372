import React, {useCallback, useState} from 'react';
import {ErrorBoundary} from '@sentry/react';
import {
  convertToOrgTimezone,
  dateToNormalString,
  toClickHouseDate,
} from '../../features/convertDates';
import useApiCall from '../../hooks/useApiCall';
import Card from '../../lib/Card';
import DefaultIndexTable from '../../components/defaultIndexTable';
import useSearchParamStoreSync from '../../hooks/useSearchParamsStoreSync';
import {useDateRangeStore} from '../../app/store';
import Button from '../../lib/Button';
import Box from '../../lib/Box';
import DateRangeComponent from '../../components/dateRangeComponent';
import Stack from '../../lib/Stack';
import MyPage from '../../components/page';
import Inline from '../../lib/Inline';
import Text from '../../lib/Text';
import DownloadModal from '../../components/Modals/downloadModal';
import Alert from '../../components/alert';
const dateRangeValueToLable = {
  today: 'Today',
  yesterday: 'Yesterday',
  lastSevenDays: 'Last 7 Days',
  lastThirtyDays: 'Last 30 Days',
  lastNinetyDays: 'Last 90 Days',
  lastOneYear: 'Last 1 Year',
};

const RenderData = ({
  sortBy,
  sortOrder,
  page,
  queryValue,
  tabMap,
  selectedTabId,
  setSortBy,
  setPage,
  setQueryValue,
  setSortOrder,
}) => {
  const [downloadState, setDownloadState] = useState('Success');
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [showAlert, setShowAlert] = useState('');
  const handleDownload = async email => {
    let params = {
      sortBy,
      sortOrder,
      search: queryValue,
      page,
      type: 'download',
      email,
    };
    if (tabMap[selectedTabId].dateRequired) {
      let startDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.start, 'start'));
      let endDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.end, 'end'));
      params = {...params, startDate, endDate};
    }

    setDownloadState({state: 'Loading'});
    try {
      await tabMap[selectedTabId].api({params});
      setShowAlert(`Data will be mailed to you shortly on ${email}`);
      setDownloadState({state: 'Success'});
    } catch (e) {
      setDownloadState({state: 'Error'});
    }
  };

  const {
    selectedDateRange,
    setSelectedDateRange,
    selectedDateRangeDates,
    setSelectedDateRangeDates,
  } = useDateRangeStore(state => state);
  useSearchParamStoreSync(
    ['selectedDateRangeDates'],
    [
      {
        label: 'tab',
        value: selectedTabId,
      },
    ]
  );
  const [dateRangePopoverActive, setDateRangePopoverActive] = useState(false);
  const getDateRangeDates = (dates, selectedDateRange) => {
    setSelectedDateRangeDates(dates);
    setSelectedDateRange(selectedDateRange);
  };
  const dateRangeTogglePopoverActive = useCallback(
    () => setDateRangePopoverActive(dateRangePopoverActive => !dateRangePopoverActive),
    []
  );

  const dateRangeActivator = (
    <div style={{width: '100%'}}>
      <Button onClick={dateRangeTogglePopoverActive} disclosure>
        {selectedDateRange
          ? dateRangeValueToLable[selectedDateRange]
          : dateToNormalString(selectedDateRangeDates)}
      </Button>
    </div>
  );
  const fetchData = async () => {
    // setLoading(false)
    let params = {
      sortBy,
      sortOrder,
      search: queryValue,
      page,
      type: 'table',
    };
    if (tabMap[selectedTabId].dateRequired) {
      let startDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.start, 'start'));
      let endDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.end, 'end'));
      params = {...params, startDate, endDate};
    }

    const response = await tabMap[selectedTabId].api({params});
    return response.data;
  };
  const apiDependencies = [
    selectedDateRangeDates,
    page,
    queryValue,
    sortBy,
    sortOrder,
    selectedTabId,
  ];
  const data = useApiCall(fetchData, apiDependencies, 0);
  // content: downloadState === 'Loading' ? <Spinner size="small" /> : 'Download',
  return (
    <Stack vertical>
      {tabMap[selectedTabId].text && (
        <Inline align="center">
          <Text color="subdued" variant="bodyMd">
            {tabMap[selectedTabId].text}
          </Text>
        </Inline>
      )}
      <Inline
        blockAlign="baseline"
        align={tabMap[selectedTabId].dateRequired ? 'space-between' : 'end'}
      >
        {tabMap[selectedTabId].dateRequired && (
          <Box>
            <DateRangeComponent
              currentDates={selectedDateRangeDates}
              activator={dateRangeActivator}
              togglePopoverActive={dateRangeTogglePopoverActive}
              popoverActive={dateRangePopoverActive}
              getDates={getDateRangeDates}
              currentDateOption={selectedDateRange}
            />
          </Box>
        )}
        <Button plain onClick={() => setOpenDownloadModal(true)}>
          <p style={{color: 'black'}}>Download</p>
        </Button>
      </Inline>

      <ErrorBoundary
        fallback={
          <Card>
            <Card.Section>
              <div>An error has occured</div>
            </Card.Section>
          </Card>
        }
      >
        <DefaultIndexTable
          isSearchEnabled={tabMap[selectedTabId].searchRequired}
          disableSort={!tabMap[selectedTabId].sortRequired}
          select={false}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          queryValue={queryValue}
          setQueryValue={setQueryValue}
          page={page}
          setPage={setPage}
          resourceName={{
            singular: 'metrics',
            plural: 'metrics',
          }}
          columns={tabMap[selectedTabId].columns}
          totalRows={data.data.length ? +data.data[0].totalRows : 0}
          tableData={data.data.length > 0 ? data.data : []}
          durationFilter={'daily'}
          // param={{name: tabs[selected].id}}
          state={data.state}
          queryPlaceholder="Filter by SKU, title, category, or brand"
          selectedFilters={[]}
        />
      </ErrorBoundary>
      {showAlert && (
        <Alert
          contents={showAlert}
          init={true}
          error={showAlert === 'Something Went Wrong'}
          duration={10000}
        />
      )}
      <DownloadModal
        isOpen={openDownloadModal}
        toggle={() => setOpenDownloadModal(!openDownloadModal)}
        handleDownload={handleDownload}
      />
    </Stack>
  );
};

export default RenderData;
