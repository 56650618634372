import {Text} from '@shopify/polaris';
import React from 'react';
import MyPage from '../page';

const PageNotFound = () => {
  return (
    <MyPage>
      <Text variant="heading2xl" as="h3">
        Page not found
      </Text>
    </MyPage>
  );
};

export default PageNotFound;
