import {Route} from 'react-router-dom';
import React from 'react';
import SignupPage from '../../pages/userManagement/signUp';
import SigninPage from '../../pages/userManagement/signIn';
import VerifyPage from '../../pages/userManagement/verify';
import ForgotPage from '../../pages/userManagement/forgot';
import ResetPage from '../../pages/userManagement/reset';
import {Navigate} from 'react-router-dom';
import PublicRoute from '../public/PublicRoute';
import {AutoLogin} from '../../pages/userManagement/autoLogin';
// import Admin from "../../pages/admin";
const AuthRoutes = (
  <>
    <Route path="/" element={<Navigate to="/auth/signin" />}></Route>
    <Route
      path="/auth/signup/:orgId"
      element={
        // <PublicRoute>
        <SignupPage />
        // </PublicRoute>
      }
    />
    {/* <Route
      path="/auth/signup"
      element={
        <PublicRoute>
          <SignupPage />
        </PublicRoute>
      }
    /> */}
    <Route
      path="/auth/signin"
      element={
        <PublicRoute>
          <SigninPage />
        </PublicRoute>
      }
    />
    <Route
      path="/auth/verify"
      element={
        <PublicRoute>
          <VerifyPage />
        </PublicRoute>
      }
    />
    <Route
      path="/auth/reset/request"
      element={
        <PublicRoute>
          <ForgotPage />
        </PublicRoute>
      }
    />
    <Route
      path="/auth/reset/:token"
      element={
        <PublicRoute>
          <ResetPage />
        </PublicRoute>
      }
    />
    <Route
      path="/auth/login/:accessToken"
      element={
        <PublicRoute>
          <AutoLogin />
        </PublicRoute>
      }
    />
  </>
);

export default AuthRoutes;
