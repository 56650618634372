import axios from '../axios';
import config from '../../config';

const {BASE_URL} = config;

export async function createCharge(option) {
  try {
    const response = await axios.post(`${BASE_URL}/billing/create-charge`, option);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
