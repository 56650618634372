import React, {useCallback, useEffect, useState} from 'react';
import Page from '../../components/page';
import {useNavigate, useSearchParams} from 'react-router-dom';
import DateRangeComponent from '../../components/dateRangeComponent';
import {
  Box,
  Button,
  Card,
  Inline,
  OptionList,
  Popover,
  RangeSlider,
  Spinner,
  Text,
} from '@shopify/polaris';
import {
  thirtyDaysBefore,
  todayDate,
  yesterDayDate,
  ninetyDaysBefore,
  sevenDaysBefore,
  dateToNormalString,
  toClickHouseDate,
  oneYearBefore,
} from '../../features/convertDates';
import ProductSelect from './components/ProductSelect';
import {getProductJourney} from '../../api/productJourney';
import {ErrorBoundary} from '../../components/ErrorBoundary';
import ExcludeVariants from './components/ExcludeVariants';
import ProductJourneyChart from './components/ProductJourneyChart';
import {getProductCategoryList, getVariantList} from '../../api/filterLists';
import {getTransformedProductTitle} from '../../features/getTransformedProductTitle';
import FilterDropDown from '../../components/FilterDropdown';

const dateRangeObj = {
  yesterday: yesterDayDate,
  today: todayDate,
  lastThirtyDays: thirtyDaysBefore,
  lastSevenDays: sevenDaysBefore,
  lastNinetyDays: ninetyDaysBefore,
  lastOneYear: oneYearBefore,
};

const dateRangeValueToLable = {
  today: 'Today',
  yesterday: 'Yesterday',
  lastSevenDays: 'Last 7 Days',
  lastThirtyDays: 'Last 30 Days',
  lastNinetyDays: 'Last 90 Days',
  lastOneYear: 'Last 1 Year',
};

const ProductJourney = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [dateRangePopoverActive, setDateRangePopoverActive] = useState(false);
  const [productList, setProductList] = useState([]);
  const [productCategoryList, setProductCategoryList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(
    searchParams.get('variantId')
      ? {
          variantId: searchParams.get('variantId'),
          sku: searchParams.get('sku'),
          title: searchParams.get('title'),
          variantTitle: searchParams.get('variantTitle'),
        }
      : null
  );
  const [journeyType, setJourneyType] = useState(
    searchParams.get('journeyType') || 'Product variant'
  );
  const [productType, setProductType] = useState(searchParams.get('productType') || null);
  const [search, setSearch] = useState('');
  const [selectedVariants, setSelectedVariants] = useState(
    searchParams.get('excludedVariants') ? searchParams.get('excludedVariants').split(',') : []
  );
  const [rangeValue, setRangeValue] = useState(searchParams.get('productsCount') || 5);
  const [finalRangeValue, setFinalRangeValue] = useState(rangeValue);
  // const [rangeTooltipValue, setRangeTooltipValue] = useState(rangeValue);

  let timeoutId;
  const handleRangeSliderChange = useCallback(value => {
    // setRangeTooltipValue('');
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setFinalRangeValue(value);
      // setRangeTooltipValue(value);
    }, 500);

    return setRangeValue(value);
  }, []);

  const [selectedDateRange, setSelectedDateRange] = useState(
    searchParams.get('selectedDateRange')
      ? searchParams.get('selectedDateRange') === 'undefined' ||
        searchParams.get('selectedDateRange') === 'null'
        ? null
        : searchParams.get('selectedDateRange')
      : searchParams.get('startDate')
      ? null
      : 'lastThirtyDays'
  );

  const [selectedDateRangeDates, setSelectedDateRangeDates] = useState(
    searchParams.get('startDate') && searchParams.get('endDate')
      ? {start: new Date(searchParams.get('startDate')), end: new Date(searchParams.get('endDate'))}
      : selectedDateRange
      ? {
          start: dateRangeObj[selectedDateRange]()[0],
          end: dateRangeObj[selectedDateRange]()[1],
        }
      : {
          start: new Date(),
          end: new Date(),
        }
  );
  const [productJourneyData, setProductJourneyData] = useState([]);

  const [dataStatus, setDataStatus] = useState(null);

  const getDateRangeDates = (dates, selectedDateRange) => {
    setSelectedDateRangeDates(dates);
    setSelectedDateRange(selectedDateRange);
  };

  const dateRangeTogglePopoverActive = useCallback(
    () => setDateRangePopoverActive(dateRangePopoverActive => !dateRangePopoverActive),
    []
  );

  const dateRangeActivator = (
    <div style={{width: '100%'}}>
      <Button onClick={dateRangeTogglePopoverActive} disclosure>
        {selectedDateRange
          ? dateRangeValueToLable[selectedDateRange]
          : dateToNormalString(selectedDateRangeDates)}
      </Button>
    </div>
  );

  useEffect(() => {
    let params = {
      startDate: toClickHouseDate(selectedDateRangeDates.start, 'start'),
      endDate: toClickHouseDate(selectedDateRangeDates.end, 'end'),
    };
    if (search !== '') {
      params.search = search;
    }
    if (selectedVariants.length > 0) {
      params.variantIds = selectedVariants.join(',');
    }
    if (journeyType === 'Product variant') {
      getVariantList({params})
        .then(res => {
          setProductList(res.data.result);
          if (!selectedProduct && res.data.result.length > 0) {
            setSelectedProduct(res.data.result[0]);
            setProductType(null);
          }
        })
        .catch(err => console.log(err));
    } else if (journeyType === 'Product category') {
      setSelectedProduct(null);
      getProductCategoryList({params})
        .then(res => {
          setProductCategoryList(res.data.result);
          if (!productType && res.data.result.length > 0) {
            setProductType(res.data.result[0].productCategory);
          }
        })
        .catch(err => console.log(err));
    }
    let flag = false;
    for (let key in params) {
      if (searchParams.get(key) !== params[key]) {
        flag = true;
      }
    }
    if (flag) {
      setSearchParams({...params});
    }
  }, [selectedDateRangeDates, search, selectedVariants, journeyType]);

  useEffect(() => {
    setProductJourneyData([]);
    setDataStatus(null);
    if (selectedProduct || productType) {
      let params = {
        startDate: toClickHouseDate(selectedDateRangeDates.start, 'start'),
        endDate: toClickHouseDate(selectedDateRangeDates.end, 'end'),
        journeyType,
        productsCount: rangeValue || 5,
        orderType: 'net',
      };
      if (journeyType === 'Product category') {
        params.productType = productType;
      } else if (journeyType === 'Product variant') {
        params.variantId = selectedProduct.variantId;
      }
      if (selectedVariants.length > 0) {
        params.excludedVariants = selectedVariants.join(',');
      }
      let flag = false;
      for (let key in params) {
        if (searchParams.get(key) !== params[key]) {
          flag = true;
        }
      }
      if (flag) {
        setSearchParams({
          ...params,
          title: selectedProduct?.title,
          variantTitle: selectedProduct?.variantTitle,
        });
      }
      getProductJourney({params})
        .then(res => {
          setDataStatus(true);
          setProductJourneyData(res.data || []);
        })
        .catch(err => {
          setDataStatus(false);
          console.log(err);
        });
    }
  }, [selectedProduct, selectedDateRangeDates, finalRangeValue, selectedVariants, productType]);

  return (
    <>
      <Page
        breadcrumbs={[
          {
            content: 'Back-button',
            onAction: () => {
              navigate(-1);
            },
          },
        ]}
        title={'Product Journey'}
      >
        <Box paddingBlockEnd={'4'}>
          <Inline blockAlign="start">
            <DateRangeComponent
              currentDates={selectedDateRangeDates}
              activator={dateRangeActivator}
              togglePopoverActive={dateRangeTogglePopoverActive}
              popoverActive={dateRangePopoverActive}
              getDates={getDateRangeDates}
              currentDateOption={selectedDateRange}
            />
            <FilterDropDown
              itemList={[{journeyType: 'Product variant'}, {journeyType: 'Product category'}]}
              setSelectedItem={setJourneyType}
              // setQuery
              // query
              itemType={'journeyType'}
              label={'Select journey type'}
              setIsOpen={() => {}}
              selectedItem={journeyType}
              isSearchEnabled={false}
            />

            {journeyType === 'Product variant' ? (
              <ProductSelect
                productList={productList.filter(
                  ({variantId}) => !selectedVariants.includes(variantId)
                )}
                setSelectedProduct={setSelectedProduct}
                query={search}
                setQuery={setSearch}
              />
            ) : (
              <FilterDropDown
                itemList={productCategoryList}
                setSelectedItem={setProductType}
                setQuery={setSearch}
                query={search}
                itemType={'productCategory'}
                label={'Select category'}
                setIsOpen={() => {}}
                selectedItem={productType}
                isSearchEnabled={true}
              />
            )}
            <ExcludeVariants
              selectedVariants={selectedVariants}
              setSelectedVariants={setSelectedVariants}
              productList={productList}
              query={search}
              setQuery={setSearch}
            />
            {/* <Tooltip content={rangeTooltipValue} dismissOnMouseOut preferredPosition="above"> */}
            <RangeSlider
              label="# Products to show"
              value={rangeValue}
              onChange={handleRangeSliderChange}
              output
              max={10}
              min={1}
            />
            {/* </Tooltip> */}
          </Inline>
        </Box>
        <Box paddingBlockEnd={'4'}>
          {selectedProduct || productType ? (
            <Card>
              <Card.Section>
                <ErrorBoundary
                  fallback={
                    <Card>
                      <Card.Section>
                        <div>An error has occured</div>
                      </Card.Section>
                    </Card>
                  }
                >
                  <Text variant="headingMd" as="h6">
                    {journeyType === 'Product variant'
                      ? getTransformedProductTitle({
                          sku: selectedProduct?.sku,
                          title: selectedProduct?.title,
                          variantTitle: selectedProduct?.variantTitle,
                        })
                      : productType}
                  </Text>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingTop: '10px',
                    }}
                  >
                    {dataStatus !== null ? (
                      <>
                        {productJourneyData.length > 0 ? (
                          <ProductJourneyChart
                            productJourneyData={productJourneyData}
                            setProductJourneyData={setProductJourneyData}
                            rangeValue={rangeValue}
                          />
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              height: '65vh',
                            }}
                          >
                            <Text variant="headingMd" as="h6">
                              Unable to load!
                            </Text>
                          </div>
                        )}
                      </>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '65vh',
                        }}
                      >
                        <Spinner />
                      </div>
                    )}
                  </div>
                </ErrorBoundary>
              </Card.Section>
            </Card>
          ) : (
            <Card>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingTop: '10px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '65vh',
                  }}
                >
                  <Text variant="headingMd" as="h6">
                    Data not found
                  </Text>
                </div>
              </div>
            </Card>
          )}
        </Box>
      </Page>
    </>
  );
};

export default ProductJourney;
