import {Box, Card, Spinner, Button} from '@shopify/polaris';
import Inline from '../../../lib/Inline';
import {
  thirtyDaysBefore,
  todayDate,
  yesterDayDate,
  ninetyDaysBefore,
  sevenDaysBefore,
  dateToNormalString,
  previousPeriod,
  previousYear,
  toClickHouseDate,
  convertToOrgTimezone,
  oneYearBefore,
} from '../../../features/convertDates';
import ColdStart from '../../coldStart';

import React, {useContext} from 'react';
import {useEffect} from 'react';
import {useState, useCallback} from 'react';
import Page from '../../../components/page';
import DateRangeComponent from '../../../components/dateRangeComponent';
import DefaultTable from '../../../components/defaultIndexTable';
import {ErrorBoundary} from '../../../components/ErrorBoundary';
import {track} from '../../../features/track';
import useApiCall from '../../../hooks/useApiCall';
import DownloadModal from '../../../components/Modals/downloadModal';
import Alert from '../../../components/alert';
import {npsProductColumns} from '../columns';
import {productNps} from '../../../api/reports';
import DateCompareComponent from '../../../components/dateCompareComponent';
import DefaultLineGraph from '../../../components/graphs/defaultLineGraph';
import SelectComponent from '../../../components/selectComponent';
import SelectRFMComponent from '../../../components/selectRfmComponent';
import DefaultSelect from '../../../components/defaultSelect';
import {useStoreInfo} from '../../../app/store';
import {getStore} from '../../../api/store';
import {LineChart} from '@shopify/polaris-viz';
import {LocalStorageService} from '../../../service/localStorage';
import {AuthContext} from '../../../context/auth';

const dateRangeObj = {
  yesterday: yesterDayDate,
  today: todayDate,
  lastThirtyDays: thirtyDaysBefore,
  lastSevenDays: sevenDaysBefore,
  lastNinetyDays: ninetyDaysBefore,
  lastOneYear: oneYearBefore,
};
const dateCompareObj = {
  previousPeriod: previousPeriod,
  previousYear: previousYear,
  noComparison: () => {
    return ['', ''];
  },
};
const dateRangeValueToLable = {
  today: 'Today',
  yesterday: 'Yesterday',
  lastSevenDays: 'Last 7 Days',
  lastThirtyDays: 'Last 30 Days',
  lastNinetyDays: 'Last 90 Days',
  lastOneYear: 'Last 1 Year',
};
const dateCompareValueToLable = {
  previousPeriod: 'Previous Period',
  previousYear: 'Previous Year',
  noComparison: 'No Comparison',
};
const userDetails = LocalStorageService.getUserOrg();
const timeZone = userDetails?.iana_timezone;

const NPSDashboard = () => {
  const [sortOrder, setSortOrder] = useState('desc');
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [popoverActive, setPopoverActive] = useState(false);
  const [showAlert, setShowAlert] = useState('');
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState('date');
  const [download, setDownload] = useState({state: 'Success'});
  const [durationFilter, setDurationFilter] = useState('daily');
  const [selectedCompare, setSelectedCompare] = useState('previousPeriod');
  const [dateRangePopoverActive, setDateRangePopoverActive] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState('lastThirtyDays');
  const [rfmSegment, setRfmSegment] = useState('All Customers');
  const [selectedSourceName, setSelectedSourceName] = useState('All Sources');
  const {sourceNames, setSourceNames} = useStoreInfo(state => state);
  const {isNpsEnabled} = useContext(AuthContext);
  const npsEnabled = isNpsEnabled();

  const [selectedDateRangeDates, setSelectedDateRangeDates] = useState({
    start: new Date(),
    end: new Date(),
  });

  const [selectedCompareDates, setSelectedCompareDates] = useState({
    start: new Date(),
    end: new Date(),
  });
  useEffect(() => {
    if (selectedDateRange) {
      setSelectedDateRangeDates({
        start: dateRangeObj[selectedDateRange]()[0],
        end: dateRangeObj[selectedDateRange]()[1],
      });
    }
    track('Page View', {
      name: 'NPS',
    });
  }, []);

  const getDateRangeDates = (dates, selectedDateRange) => {
    setSelectedDateRangeDates(dates);
    setSelectedDateRange(selectedDateRange);
  };
  const getDates = (dates, selectedCompar) => {
    setSelectedCompareDates(dates);
    setSelectedCompare(selectedCompar[0]);
  };
  const dateRangeTogglePopoverActive = useCallback(
    () => setDateRangePopoverActive(dateRangePopoverActive => !dateRangePopoverActive),
    []
  );

  const togglePopoverActive = useCallback(
    () => setPopoverActive(popoverActive => !popoverActive),
    []
  );
  const dateRangeActivator = (
    <div style={{width: '100%'}}>
      <Button onClick={dateRangeTogglePopoverActive} disclosure>
        {selectedDateRange
          ? dateRangeValueToLable[selectedDateRange]
          : dateToNormalString(selectedDateRangeDates)}
      </Button>
    </div>
  );
  const dateCompareActivator = (
    <div style={{width: '100%'}}>
      <Button onClick={togglePopoverActive} disclosure>
        Compare:{' '}
        {selectedCompare
          ? dateCompareValueToLable[selectedCompare]
          : dateToNormalString(selectedCompareDates)}
      </Button>
    </div>
  );

  useEffect(() => {
    if (!sourceNames.length) {
      getStore().then(res => {
        setSourceNames(res.data.data.sourceName);
      });
    }

    // compare Dates auto change on selected date changed
    if (selectedCompare) {
      let [sdate, edate] = dateCompareObj[selectedCompare](selectedDateRangeDates);
      setSelectedCompareDates({start: new Date(sdate), end: new Date(edate)});
    }
  }, [selectedDateRangeDates]);

  const fetchGraphData = async () => {
    let startDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.start, 'start'));
    let endDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.end, 'end'));
    let params = {};
    if (selectedCompareDates.start !== 'Invalid Date') {
      let compareStartDate = convertToOrgTimezone(
        toClickHouseDate(selectedCompareDates.start, 'start')
      );
      let compareEndDate = convertToOrgTimezone(toClickHouseDate(selectedCompareDates.end, 'end'));
      params = {...params, compareStartDate, compareEndDate};
    }
    params = {
      ...params,
      startDate,
      endDate,
      type: 'graph',
      durationFilter,
      rfmSegment,
      sourceName: selectedSourceName,
    };
    let res = await productNps({params});
    return res.data;
  };

  const fetchTableData = async () => {
    let startDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.start, 'start'));
    let endDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.end, 'end'));
    let params = {
      startDate,
      endDate,
      page,
      sortBy,
      sortOrder,
      type: 'table',
      durationFilter,
      rfmSegment,
      sourceName: selectedSourceName,
    };
    let res = await productNps({params});
    return res.data;
  };

  const handleDownload = async email => {
    setShowAlert('');
    let startDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.start, 'start'));
    let endDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.end, 'end'));
    setDownload({state: 'Loading'});
    const params = {
      type: 'download',
      startDate,
      endDate,
      sortBy,
      sortOrder,
      page,
      email,
    };
    setDownload({state: 'Loading'});
    try {
      await productNps({params});
      setShowAlert(`NPS data will be mailed to you shortly on ${email}`);
      setDownload({state: 'Success'});
    } catch (e) {
      setDownload({state: 'Error'});
    }
  };
  const tableApiDependencies = [
    selectedDateRangeDates,
    page,
    sortBy,
    sortOrder,
    durationFilter,
    rfmSegment,
    selectedSourceName,
  ];

  const graphApiDependencies = [
    selectedDateRangeDates,
    durationFilter,
    selectedCompareDates,
    rfmSegment,
    selectedSourceName,
  ];

  const tableData = useApiCall(fetchTableData, tableApiDependencies);
  const graphData = useApiCall(fetchGraphData, graphApiDependencies);

  return (
    <>
      {!npsEnabled ? (
        <ColdStart page={'page9'} />
      ) : (
        <>
          <Page
            secondaryActions={[
              {
                content: download.state === 'Loading' ? <Spinner size="small" /> : 'Download',
                destructive: false,
                onAction: () => {
                  setOpenDownloadModal(true);
                },
              },
            ]}
            title={'NPS'}
          >
            <Box paddingBlockEnd={'4'}>
              <Inline>
                <DateRangeComponent
                  currentDates={selectedDateRangeDates}
                  activator={dateRangeActivator}
                  togglePopoverActive={dateRangeTogglePopoverActive}
                  popoverActive={dateRangePopoverActive}
                  getDates={getDateRangeDates}
                  currentDateOption={selectedDateRange}
                />
                <DateCompareComponent
                  currentDates={selectedCompareDates}
                  activator={dateCompareActivator}
                  togglePopoverActive={togglePopoverActive}
                  popoverActive={popoverActive}
                  selectedCompare={selectedCompare}
                  getDates={getDates}
                  selectedDateRangeDates={selectedDateRangeDates}
                />
                <SelectComponent
                  setDurationFilter={setDurationFilter}
                  durationFilter={durationFilter}
                  setPage={setPage}
                />
                <SelectRFMComponent
                  setRfmSegment={setRfmSegment}
                  rfmSegment={rfmSegment}
                  setPage={setPage}
                />
                <DefaultSelect
                  value={selectedSourceName}
                  setValue={setSelectedSourceName}
                  list={sourceNames}
                  setPage={setPage}
                />
              </Inline>
            </Box>
            <Box paddingBlockEnd={'4'}>
              <Card>
                <Card.Section>
                  <ErrorBoundary fallback={<LineChart data={[]} state="Error" theme="Light" />}>
                    <DefaultLineGraph
                      param={{name: 'nps-product'}}
                      data={graphData.data || []}
                      compareStartDate={
                        selectedCompareDates.start !== 'Invalid Date'
                          ? toClickHouseDate(selectedCompareDates.start, 'start')
                          : null
                      }
                      compareEndDate={
                        selectedCompareDates.start !== 'Invalid Date'
                          ? toClickHouseDate(selectedCompareDates.end, 'end')
                          : null
                      }
                      startDate={toClickHouseDate(selectedDateRangeDates.start, 'start')}
                      endDate={toClickHouseDate(selectedDateRangeDates.end, 'end')}
                      durationFilter={durationFilter}
                      state={graphData.state}
                      xAxisOptions={{
                        labelFormatter: function formatLinearXAxisLabel(value) {
                          if (value.length > 11) {
                            value = value + ':00:00';
                          }

                          let options = {};
                          if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/gm.test(value)) {
                            options = {
                              month: 'short',
                              day: 'numeric',
                              timeZone: timeZone,
                            };
                          }
                          if (/^[0-9]{4}-[0-9]{2}$/gm.test(value)) {
                            options = {
                              year: 'numeric',
                              month: 'short',
                              timeZone: timeZone,
                            };
                          }

                          if (value.includes('T')) {
                            options = {
                              hour: 'numeric',
                              minute: 'numeric',
                              timeZone: timeZone,
                            };
                          }
                          return new Intl.DateTimeFormat('en-US', options).format(new Date(value));
                        },
                      }}
                    />
                  </ErrorBoundary>
                </Card.Section>
              </Card>
            </Box>
            <Box paddingBlockEnd={'4'}>
              <ErrorBoundary
                fallback={
                  <Card>
                    <Card.Section>
                      <div>An error has occured</div>
                    </Card.Section>
                  </Card>
                }
              >
                <DefaultTable
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                  sortExceptions={[]}
                  sortOrder={sortOrder}
                  setSortOrder={setSortOrder}
                  select={false}
                  page={page}
                  setPage={setPage}
                  resourceName={{
                    singular: 'nps',
                    plural: 'nps',
                  }}
                  columns={npsProductColumns}
                  selectedFilters={[]}
                  totalRows={tableData.data.length ? tableData.data[0].totalRows : 0}
                  tableData={tableData.data}
                  durationFilter={durationFilter}
                  setSelectedDateRangeDates={null}
                  state={tableData.state}
                  param={{}}
                />
              </ErrorBoundary>
            </Box>
          </Page>
          {showAlert && (
            <Alert
              contents={showAlert}
              init={true}
              error={showAlert === 'Something Went Wrong'}
              duration={10000}
            />
          )}
          <DownloadModal
            isOpen={openDownloadModal}
            toggle={() => setOpenDownloadModal(!openDownloadModal)}
            handleDownload={handleDownload}
          />
        </>
      )}
    </>
  );
};

export default NPSDashboard;
