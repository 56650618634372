import React, {useEffect} from 'react';
import {Popover, OptionList, Tag} from '@shopify/polaris';
import {useState, useCallback} from 'react';
import './index.css';
import Button from '../../../lib/Button';
import Stack from '../../../lib/Stack';
import Box from '../../../lib/Box';
import Text from '../../../lib/Text';
const ValueOption = ({valueOptions, dispatch, indexes, value, user}) => {
  const [popoverActive, setPopoverActive] = useState(false);
  const [selected, setSelected] = useState([]);
  const [name, setName] = useState('');

  const togglePopoverActive = useCallback(
    () => setPopoverActive(popoverActive => !popoverActive),
    []
  );

  const activator = (
    <Button
      textAlign="left"
      disclosure={popoverActive ? 'up' : 'down'}
      onClick={togglePopoverActive}
      fullWidth
      disabled={user === 'Retainwise'}
    >
      <Text color="subdued">{selected.length ? name : 'Value'}</Text>
    </Button>
  );
  useEffect(() => {
    if (value === '') {
      setSelected([]);
    } else {
      setSelected([value]);
    }
  }, [value]);
  useEffect(() => {
    let n = '';
    valueOptions.forEach(a => {
      if (a.value === selected[0]) {
        n = a.label;
      }
    });

    setName(n);
  }, [selected]);
  return (
    <>
      <Popover
        active={popoverActive}
        activator={activator}
        autofocusTarget="first-node"
        onClose={togglePopoverActive}
        preferredPosition="below"
        fullWidth
      >
        <Popover.Pane>
          <OptionList
            onChange={e => {
              setSelected(e);
              dispatch({type: 'value', indexes: indexes, value: e[0]});
              togglePopoverActive();
            }}
            options={valueOptions}
            selected={selected}
          />
        </Popover.Pane>
      </Popover>
    </>
  );
};

const MultiTextValue = ({dispatch, indexes, value, operator}) => {
  const [tags, setTags] = useState([]);
  const [textFieldValue, setTextFieldValue] = useState('');
  const removeTag = useCallback(
    tag => () => {
      setTags(previousTags => previousTags.filter(previousTag => previousTag !== tag));
    },
    []
  );

  useEffect(() => {
    if (tags.length) {
      dispatch({type: 'value', indexes, value: tags.join(',')});
    } else {
      dispatch({type: 'value', indexes, value: ''});
    }
  }, [tags]);

  const verticalContentMarkup =
    tags.length > 0 ? (
      <Stack spacing="extraTight" alignment="center">
        {tags.map(option => (
          <Tag key={option} onRemove={removeTag(option)}>
            {option}
          </Tag>
        ))}
      </Stack>
    ) : null;
  useEffect(() => {
    if (value) {
      let v = value.split(',');
      setTags([...v]);
    } else {
      setTags([]);
    }
  }, [operator]);

  return (
    <Box>
      <Box className="verticleBox">
        {verticalContentMarkup}
        <input
          placeholder="Enter Value"
          value={textFieldValue}
          onChange={e => {
            setTextFieldValue(e.target.value);
          }}
          className={'inputVerticle'}
          onBlur={() => {
            if (textFieldValue !== '' && tags.includes(textFieldValue)) {
              setTextFieldValue('');
            } else if (textFieldValue !== '') {
              setTags([...tags, textFieldValue]);
              setTextFieldValue('');
            }
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              if (textFieldValue !== '' && tags.includes(textFieldValue)) {
                setTextFieldValue('');
              } else if (textFieldValue !== '') {
                setTags([...tags, textFieldValue]);
                setTextFieldValue('');
              }
            }
          }}
        />
      </Box>
    </Box>
  );
};

export {ValueOption, MultiTextValue};
