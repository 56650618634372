import React from 'react';
import {createContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {LocalStorageService} from '../service/localStorage';
export const AuthContext = createContext();
export const AuthProvider = props => {
  const navigate = useNavigate();

  const isFetchCompleted = () => {
    let accountDetail = LocalStorageService.getAccountDetails();
    return accountDetail?.user?.org?.isFirstFetchCompleted;
  };
  const isNpsEnabled = () => {
    const userDetails = LocalStorageService.getAccountDetails();
    return userDetails.user?.org?.reportSettings?.nps?.product;
  };
  const signin = accountDetails => {
    LocalStorageService.setAccountDetails(accountDetails);
    navigate('/');
  };
  const adminOrganizationSignIn = accountDetails => {
    LocalStorageService.setAdminOrgSignIn(accountDetails);
    navigate('/');
  };
  const adminOrganizationSignOut = () => {
    LocalStorageService.removeAdminOrgSignIn();
    navigate('/');
  };
  const updateUserDetails = accountDetails => {
    LocalStorageService.setAccountDetails(accountDetails);
  };
  const signout = () => {
    LocalStorageService.clear();
    navigate('/');
  };
  const isSignedIn = () => {
    const userDetails = LocalStorageService.getAccountDetails();

    if (userDetails) {
      return true;
    } else {
      return false;
    }
  };
  const getAccount = () => {
    const accountDetails = LocalStorageService.getAccountDetails();
    return accountDetails;
  };

  const updateOrg = org => {
    let accountDetail = LocalStorageService.getAccountDetails();
    Object.keys(accountDetail.user.org).forEach(key => {
      if (key !== 'id') {
        accountDetail.user.org[key] = org[key] ? org[key] : accountDetail.user.org[key];
      }
    });
    accountDetail.user.org.isFirstFetchCompleted = org?.isFirstFetchCompleted || false;
    LocalStorageService.setAccountDetails(accountDetail);
  };

  return (
    <AuthContext.Provider
      value={{
        signin,
        signout,
        isSignedIn,
        getAccount,
        updateUserDetails,
        adminOrganizationSignIn,
        adminOrganizationSignOut,
        updateOrg,
        isFetchCompleted,
        isNpsEnabled,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
