import React, {useEffect, useState} from 'react';
import Page from '../../../components/page';
import {Box, Card, Spinner} from '@shopify/polaris';
import DefaultTable from '../../../components/defaultIndexTable';
import apiToCall from '../../../features/apiToCall';
import {ErrorBoundary} from '../../../components/ErrorBoundary';
import {track} from '../../../features/track';
import useApiCall from '../../../hooks/useApiCall';
import config from '../../../config';
import {saveAs} from 'file-saver';

const ProductCost = () => {
  const param = {name: 'productCost'};
  const [sortOrder, setSortOrder] = useState(apiToCall[param.name].sortOrder);
  const [queryValue, setQueryValue] = useState('');
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState(apiToCall[param.name].sortBy);
  const [download, setDownload] = useState({state: 'Success'});

  useEffect(() => {
    track('Page View', {
      name: 'ProductCost',
    });
  }, []);

  const fetchProductCostsData = async () => {
    let params = {
      page,
      search: queryValue,
      sortBy,
      sortOrder,
      type: 'table',
    };
    let response = await apiToCall[param.name].api({params});
    return response.data;
  };

  const productCostApiDependencies = [page, queryValue, sortBy, sortOrder];

  const productCostData = useApiCall(
    fetchProductCostsData,
    productCostApiDependencies,
    config.SEARCH_DELAY
  );

  const handleDownload = () => {
    setDownload({state: 'Loading'});
    const params = {
      type: 'download',
      search: queryValue,
      sortBy,
      sortOrder,
      page,
    };
    apiToCall[param.name]
      .api({params})
      .then(response => {
        if (response.data.length) {
          saveAs(
            new Blob([response.data], {
              type: response.headers['content-type'],
            }),
            response.headers.filename
          );
        }
        setDownload({state: 'Success'});
      })
      .catch(err => {
        setDownload({state: 'Error'});
      });
  };
  return (
    <>
      <Page
        secondaryActions={[
          {
            content: download.state === 'Loading' ? <Spinner size="small" /> : 'Download',
            destructive: false,
            onAction: handleDownload,
          },
        ]}
        title={'Product listing'}
      >
        <Box paddingBlockStart={'4'}>
          <ErrorBoundary
            fallback={
              <Card>
                <Card.Section>
                  <div>An error has occured</div>
                </Card.Section>
              </Card>
            }
          >
            <DefaultTable
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortExceptions={[...apiToCall[param.name].sortExecptions]}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              select={false}
              queryValue={queryValue}
              setQueryValue={setQueryValue}
              page={page}
              setPage={setPage}
              resourceName={{
                singular: 'metrics',
                plural: 'metrics',
              }}
              columns={apiToCall[param.name].columns}
              selectedFilters={[]}
              totalRows={productCostData.data.length > 0 ? productCostData.data[0].totalRows : 0}
              tableData={productCostData.data.length > 0 ? productCostData.data : []}
              state={productCostData.state}
              param={param}
              queryPlaceholder="Search by product name, SKU, variant..."
            />
          </ErrorBoundary>
        </Box>
      </Page>
    </>
  );
};

export {ProductCost};
