import {Box, Card, Button, DataTable} from '@shopify/polaris';
import Inline from '../../../lib/Inline';
import React, {useCallback, useState, useEffect} from 'react';
import Page from '../../../components/page';
import Select from '../../metrics/metricsPage/components/select';
import {useNavigate} from 'react-router-dom';
import {formatDate, formatNumber} from '../../../features/format';
import getDataTableLoading from '../../../features/dataTableLoadingRows';
import ColdStart from '../../coldStart';
import {track} from '../../../features/track';
import useApiCall from '../../../hooks/useApiCall';
import InputWithSearch from '../../../components/InputWithSearch';
import {LocalStorageService} from '../../../service/localStorage';
import Heading from './Heading';
import {getProductGroupList} from '../../../api/productGroups';

const ActiveProductGroups = () => {
  const [typePopoverActive, setTypePopoverActive] = useState(false);
  const [type, setType] = useState('Type');
  const [searchValue, setSearchValue] = useState('');
  const navigate = useNavigate();
  const [sortedRows, setSortedRows] = useState([]);
  const typeTogglePopoverActive = useCallback(
    () => setTypePopoverActive(typePopoverActive => !typePopoverActive),
    []
  );
  const userOrgDetails = LocalStorageService.getUserOrg();
  const currentPlan = userOrgDetails.planName;
  const trialPeriodStatus = userOrgDetails.trialPeriodStatus;
  const typeActivator = (
    <Button textAlign="left" fullWidth onClick={typeTogglePopoverActive} disclosure>
      {type}
    </Button>
  );
  useEffect(() => {
    track('Page View', {
      name: 'Product groups',
    });
  }, []);
  const formatRows = data => {
    return data.map(item => {
      return [
        <Heading item={item} currentPlan={currentPlan} trialPeriodStatus={trialPeriodStatus} />,
        item.user !== 'retainwise' ? formatDate({value: new Date(item.u)}) : null,
        item.user !== 'retainwise'
          ? formatNumber({value: item.productCount, notation: 'compact'})
          : null,
        item.productGroupType,
        item.user !== 'retainwise' ? item.user : null,
      ];
    });
  };

  const fetchProductGroups = async () => {
    let response = await getProductGroupList({params: {type, search: searchValue}});
    return response.data;
  };
  const apiDependencies = [type, searchValue, navigate];
  const rows = useApiCall(fetchProductGroups, apiDependencies);

  useEffect(() => {
    setSortedRows(formatRows(rows.data));
  }, [rows]);

  const indexToColumn = (row, index) => {
    switch (index) {
      case 0:
        return row.segmentDetail.name.toLowerCase();
      case 1:
        return row.user !== 'retainwise' ? row.u : -1;
      case 2:
        return row.user !== 'retainwise' ? row.productCount : -1;
      default:
        return null;
    }
  };

  function sortRow(index, direction) {
    return [...rows.data].sort((rowA, rowB) => {
      return direction === 'descending'
        ? indexToColumn(rowB, index) > indexToColumn(rowA, index)
          ? 1
          : -1
        : indexToColumn(rowA, index) > indexToColumn(rowB, index)
        ? 1
        : -1;
    });
  }
  const handleSort = useCallback(
    (index, direction) => setSortedRows(formatRows(sortRow(index, direction))),
    [rows]
  );

  return (
    <>
      <Page
        title="Product Groups"
        subtitle="Create and view  product groups"
        primaryAction={{
          content: 'New group',
          onAction: () => {
            if (
              trialPeriodStatus === 'active' ||
              (trialPeriodStatus !== 'active' && currentPlan !== 'Free')
            ) {
              navigate('/product-group-new');
            } else {
              navigate('/pricing');
            }
          },
        }}
      >
        <Box width="100%" paddingBlockEnd={'4'}>
          {rows.state === 'Error' ? (
            <ColdStart page="page8" />
          ) : (
            <Card>
              <Box paddingBlockStart={'3'}>
                <Inline align="space-between">
                  <Box width="78%" padding={'3'}>
                    <InputWithSearch queryValue={searchValue} setQueryValue={setSearchValue} />
                  </Box>
                  <Box width="20%" paddingInlineEnd={'3'}>
                    <Select
                      options={[
                        {
                          content: 'All',
                          onAction: () => {
                            setType('All');
                          },
                        },
                        {
                          content: 'Pre-built',
                          onAction: () => {
                            setType('Pre-built');
                          },
                        },
                        {
                          content: 'Custom',
                          onAction: () => {
                            setType('Custom');
                          },
                        },
                      ]}
                      popoverActive={typePopoverActive}
                      togglePopoverActive={typeTogglePopoverActive}
                      activator={typeActivator}
                    />
                  </Box>
                </Inline>
              </Box>
              <Box paddingBlockStart={'1'}>
                <DataTable
                  sortable={[true, true, true, true, true]}
                  columnContentTypes={['text', 'text', 'text', 'text', 'text']}
                  headings={['Product groups', 'Last updated', 'Product count', 'Type', 'Owner']}
                  rows={rows.state === 'Loading' ? getDataTableLoading(5) : sortedRows}
                  defaultSortDirection="ascending"
                  initialSortColumnIndex={1}
                  onSort={handleSort}
                />
              </Box>
            </Card>
          )}
        </Box>
      </Page>
    </>
  );
};

export default ActiveProductGroups;
