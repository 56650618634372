import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import translations from '@shopify/polaris/locales/en.json';
import {Routes} from 'react-router-dom';
import AuthRoutes from './auth';
import DashboardRoutes from './dashboard';
import {AuthProvider} from '../context/auth';
import PageNotFound from '../components/page-not-found';
import {Route} from 'react-router-dom';
// import ProfileRoutes from './profile';
import {AppProvider} from '@shopify/polaris';
import NpsRoutes from './npsSurvey';

function AppRouter() {
  return (
    <>
      <BrowserRouter>
        <AuthProvider>
          <AppProvider i18n={translations}>
            <Routes>
              {AuthRoutes}
              {DashboardRoutes}
              {NpsRoutes}
              {/* {ProfileRoutes} */}
              <Route path="*" element={<PageNotFound />}></Route>
            </Routes>
          </AppProvider>
        </AuthProvider>
      </BrowserRouter>
    </>
  );
}

export default AppRouter;
