/**
 * relables m kesys in ltv data according to the duration filter w if weekly q if quarterly
 * @param {*} data
 * @param {*} durationFilter
 * @returns updated data
 */

function relableMonthKeys(data, durationFilter) {
  let initial = durationFilter === 'weekly' ? 'w' : 'q';
  const responseData = Array.from(data);
  const updatedData = responseData.map(item => {
    const updatedItem = {...item}; // Create a copy of the object
    for (let key in updatedItem) {
      if (key.startsWith('m')) {
        const newKey = initial + key.slice(1); // Replace 'm' with 'w'/'q' in the key
        updatedItem[newKey] = updatedItem[key];
        delete updatedItem[key]; // Remove the old key
      }
    }
    return updatedItem;
  });
  return updatedData;
}

export {relableMonthKeys};
