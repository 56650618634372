import {
  Card,
  Box,
  TextField,
  Text,
  Button,
  Icon,
  IndexTable,
  Layout,
  Loading,
  Filters,
} from '@shopify/polaris';
import Inline from '../../../lib/Inline';
import React, {useEffect} from 'react';
import {useReducer} from 'react';
import {useState} from 'react';
import Page from '../../../components/page';
import Property from '../../segments/components/property';
import {CancelMajor} from '@shopify/polaris-icons';
import Operator from '../../segments/components/operator';
import {groups, options} from './options';
import {MultiTextValue} from '../../segments/components/value';
import {usePreventNavigateStore, useSaveChangesStore} from '../../../app/store';
import {useNavigate, useSearchParams} from 'react-router-dom';
import useUnsavedChangesWarning from '../../../hooks/useUnsavedChangesWarning';
import {useRef} from 'react';
import {LeavePageModal} from '../../../components/modal';
import Alert from '../../../components/alert';
import config from '../../../config';
import {ErrorBoundary} from '../../../components/ErrorBoundary';
import {track} from '../../../features/track';
import FilterDropDown from '../../../components/FilterDropdown';
import {
  getProductCategoryList,
  getVariantList,
  getProductList,
  getProductTagList,
} from '../../../api/filterLists';
import FilterDropDownMultiSelect from '../../../components/FilterDropDownMultiSelect';
import {
  createProductGroup,
  getProductGroupList,
  getProductGroupVariantList,
  updateProductGroup,
} from '../../../api/productGroups';

const init = {
  name: '',
  description: '',
  productGroups: {
    type: 'group',
    operator: 'and',
    members: [
      {
        type: 'group',
        operator: 'or',
        members: [
          {
            type: 'rule',
            property: '',
            operator: '',
            value: null,
            timeSelector: '',
            days: '',
            date1: '',
            date2: '',
          },
        ],
      },
    ],
  },
  excludedManually: '',
};
const reducer = (state, action) => {
  switch (action.type) {
    case 'name':
      return {...state, name: action.value};
    case 'description':
      return {...state, description: action.value};
    case 'property':
      state.productGroups.members[action.indexes[0]].members[action.indexes[1]].property =
        action.value;
      state.productGroups.members[action.indexes[0]].members[action.indexes[1]].operator = '';
      state.productGroups.members[action.indexes[0]].members[action.indexes[1]].value = null;
      state.productGroups.members[action.indexes[0]].members[action.indexes[1]].timeSelector = '';
      state.productGroups.members[action.indexes[0]].members[action.indexes[1]].days = '';
      state.productGroups.members[action.indexes[0]].members[action.indexes[1]].date1 = '';
      return {...state, productGroups: {...state.productGroups}};
    case 'operator':
      state.productGroups.members[action.indexes[0]].members[action.indexes[1]].operator =
        action.value;
      state.productGroups.members[action.indexes[0]].members[action.indexes[1]].value = null;
      state.productGroups.members[action.indexes[0]].members[action.indexes[1]].timeSelector = '';
      state.productGroups.members[action.indexes[0]].members[action.indexes[1]].days = '';
      state.productGroups.members[action.indexes[0]].members[action.indexes[1]].date1 = '';
      return {...state, productGroups: {...state.productGroups}};
    case 'value':
      state.productGroups.members[action.indexes[0]].members[action.indexes[1]].value =
        action.value;
      state.productGroups.members[action.indexes[0]].members[action.indexes[1]].timeSelector = '';
      state.productGroups.members[action.indexes[0]].members[action.indexes[1]].days = '';
      state.productGroups.members[action.indexes[0]].members[action.indexes[1]].date1 = '';
      return {...state, productGroups: {...state.productGroups}};
    case 'or':
      state.productGroups.members[action.value].members.push({
        type: 'rule',
        property: '',
        operator: '',
        value: null,
        timeSelector: '',
        days: '',
        date1: '',
        date2: '',
      });
      return {...state, productGroups: {...state.productGroups}};
    case 'and':
      state.productGroups.members.splice(action.value + 1, 0, {
        type: 'group',
        operator: 'or',
        members: [
          {
            type: 'rule',
            property: '',
            operator: '',
            value: null,
            timeSelector: '',
            days: '',
            date1: '',
            date2: '',
          },
        ],
      });
      return {
        ...state,
        productGroups: {...state.productGroups},
      };
    case 'delete':
      if (state.productGroups.members[action.parentValue].members.length === 1) {
        state.productGroups.members.splice(action.parentValue, 1);
      } else {
        state.productGroups.members[action.parentValue].members.splice(action.value, 1);
      }

      return {...state, productGroups: {...state.productGroups}};
    case 'update':
      return {...action.value};
    case 'excludedManually':
      return {...state, excludedManually: action.value};
    default:
      throw new Error();
  }
};
const Values = ({parentIndex, index, dispatch, prop, options, state, seg}) => {
  const [singleTextError, setSingleTextError] = useState('');
  const [itemList, setItemList] = useState([]);
  const [query, setQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const listMap = {
    variants: getVariantList,
    productCategory: getProductCategoryList,
    products: getProductList,
    productTags: getProductTagList,
  };
  useEffect(() => {
    const params = {listName: options[prop.property]?.value[prop.operator].itemType};
    if (query) {
      params.search = query;
    }
    if (isOpen) {
      listMap[options[prop.property].value[prop.operator].itemType]({params})
        .then(res => {
          setItemList(res.data.result);
        })
        .catch(err => console.log(err));
    }
  }, [query, isOpen]);
  const targetElement = useRef();
  useEffect(() => {
    targetElement.current.scrollIntoView({
      behavior: 'smooth',
    });
  }, []);
  return (
    <Box key={`${parentIndex}-${index}`} ref={targetElement}>
      {index ? (
        <Box paddingBlockEnd={'4'}>
          <Text alignment="center" variant="headingMd" color="subdued">
            OR
          </Text>
        </Box>
      ) : (
        <></>
      )}
      <Box paddingBlockEnd={'8'}>
        <Inline align="space-between" blockAlign="start">
          <Box width="90%">
            <Inline blockAlign="start">
              <Box width="17%">
                <Property
                  dispatch={dispatch}
                  value={prop.property}
                  indexes={[parentIndex, index]}
                  options={groups}
                />
              </Box>
              {!(
                prop.property === '' || ['firstOrderDate', 'lastOrderDate'].includes(prop.property)
              ) && (
                <Box width="17%">
                  <Operator
                    value={prop.operator}
                    indexes={[parentIndex, index]}
                    dispatch={dispatch}
                    operatorOptions={options[prop.property].operator}
                  />
                </Box>
              )}
              {prop.operator !== '' && options[prop.property].value[prop.operator].required && (
                <Box width="17%">
                  {options[prop.property].value[prop.operator].type === 'filterDropdown' && (
                    <FilterDropDown
                      itemList={itemList.length > 0 ? itemList : []}
                      selectedItem={prop.value || null}
                      setSelectedItem={value => {
                        dispatch({
                          type: 'value',
                          indexes: [parentIndex, index],
                          value: value,
                        });
                      }}
                      setQuery={setQuery}
                      query={query}
                      itemType={options[prop.property].value[prop.operator].itemType}
                      label={options[prop.property].value[prop.operator].label}
                      setIsOpen={setIsOpen}
                    />
                  )}
                  {options[prop.property].value[prop.operator].type ===
                    'filterDropdownMultiSelect' && (
                    <>
                      <FilterDropDownMultiSelect
                        itemList={itemList.length > 0 ? itemList : []}
                        selectedItems={
                          prop.value
                            ? prop.value.split(',').filter(x => x !== undefined && x !== null)
                            : []
                        }
                        setSelectedItems={values => {
                          dispatch({
                            type: 'value',
                            indexes: [parentIndex, index],
                            value: values.join(','),
                          });
                        }}
                        setQuery={setQuery}
                        query={query}
                        itemType={options[prop.property].value[prop.operator].itemType}
                        label={options[prop.property].value[prop.operator].label}
                        setIsOpen={setIsOpen}
                      />
                    </>
                  )}
                  {options[prop.property].value[prop.operator].type === 'singleText' && (
                    <TextField
                      placeholder="Enter Value"
                      value={prop.value}
                      error={singleTextError}
                      onChange={e => {
                        if (e.trim().length < 1) {
                          setSingleTextError('Invalid value');
                        } else {
                          setSingleTextError('');
                        }
                        dispatch({
                          type: 'value',
                          indexes: [parentIndex, index],
                          value: e,
                        });
                      }}
                      autoComplete="off"
                    />
                  )}
                  {options[prop.property].value[prop.operator].type === 'multiText' && (
                    <MultiTextValue
                      dispatch={dispatch}
                      indexes={[parentIndex, index]}
                      value={prop.value}
                      operator={options[prop.property].value[prop.operator]}
                    />
                  )}
                </Box>
              )}
            </Inline>
          </Box>

          {state.productGroups.members.length > 1 || seg.members.length > 1 ? (
            <Box>
              <Button
                onClick={() => {
                  dispatch({
                    type: 'delete',
                    parentValue: parentIndex,
                    value: index,
                  });
                }}
                destructive
                outline
              >
                <Icon source={CancelMajor} />
              </Button>
            </Box>
          ) : (
            <></>
          )}
        </Inline>
      </Box>
    </Box>
  );
};
const NewProductGroup = ({params, edit}) => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, init);
  const isUnchanged = useSaveChangesStore(state => state.setIsUnchanged);
  const setOnSubmit = useSaveChangesStore(state => state.setOnSubmit);
  const setOnDiscard = useSaveChangesStore(state => state.setOnDiscard);
  const setProtect = usePreventNavigateStore(state => state.setProtect);
  const setButtonEnabled = useSaveChangesStore(state => state.setButtonEnabled);
  // eslint-disable-next-line no-unused-vars
  const [prompt, setDirty, setPristine] = useUnsavedChangesWarning();
  const [lengthError, setLengthError] = useState(false);
  const [toBeUpdated, setToBeUpdated] = useState(false);
  const [error, setError] = useState('');
  const scollToRef = useRef();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    track('Page View', {
      name: edit ? 'Edit Product Group' : 'New Product Group',
    });
  }, []);
  const onSave = () => {
    if (toBeUpdated) {
      updateProductGroup({_id: params.id, state}).then(res => {
        if (res.status === 500) {
          setError('Something Went Wrong');
        } else {
          navigate('/product-groups');
        }
      });
    } else {
      createProductGroup(state).then(res => {
        if (res.status === 500) {
          setError('Something Went Wrong');
        } else {
          navigate('/product-groups');
        }
      });
    }
    isUnchanged(false);
    setProtect(false);
    setButtonEnabled(false);
  };

  const discard = () => {
    navigate('/product-groups');
  };
  useEffect(() => {
    setTimeout(() => {
      setError('');
    }, config.ALERT_TIMEOUT);
  }, [error]);

  const protect = usePreventNavigateStore(state => state.protect);
  const [openLeavePageModal, setOpenLeavePageModal] = useState(false);
  const [toNavigate, setToNavigate] = useState('');
  useEffect(() => {
    isUnchanged(true);
    setOnDiscard(discard);
    setProtect(true);
    setDirty();

    if (params.id) {
      getProductGroupList({params: {_id: params.id}}).then(res => {
        dispatch({type: 'update', value: res.data.data.productGroupDetail});
        setToBeUpdated(true);
      });
    }
  }, []);
  useEffect(() => {
    if (+searchParams.get('stage') === 2) {
      setOnSubmit(onSave);
    }
    if (
      state.name &&
      state.productGroups.members[0].members[0].property &&
      state.productGroups.members
        .map(m =>
          m.members.map(x =>
            x.value?.trim() ||
            (x.timeSelector && (x.days || x.date1)) ||
            x.timeSelector === 'allTime'
              ? true
              : false
          )
        )
        .flat()
        .filter(x => x === '0' || x === '' || !x).length === 0 &&
      state.productGroups.members
        .map(m =>
          m.members.map(x =>
            (x.timeSelector && (x.days || x.date1)) ||
            x.timeSelector === 'allTime' ||
            x.timeSelector === ''
              ? true
              : false
          )
        )
        .flat()
        .filter(x => !x).length === 0
    ) {
      setButtonEnabled(true);
    } else {
      setButtonEnabled(false);
    }
  }, [state]);

  useEffect(() => {
    if (+searchParams.get('stage') === 3) {
      setOnSubmit(onSave);
    }
  }, [searchParams, state]);

  const [selectedVariants, setSelectedVariants] = useState([]);
  const [excludedVariants, setExcludedVariants] = useState([]);

  useEffect(() => {
    if (searchParams.get('stage') === '1') {
      setSelectedVariants([]);
      setExcludedVariants([]);
    }
    if (searchParams.get('stage') === '2' && selectedVariants.length === 0) {
      getProductGroupVariantList({productGroupDetail: state, search: '', page: 1})
        .then(res => {
          let variants = res.data.data.map(v => ({
            id: v.variantId,
            sku: v.sku,
            title: v.title,
            variantTitle: v.variantTitle,
            selected: state.excludedManually.split(',').includes(v.variantId) ? false : true,
          }));
          setSelectedVariants(variants);
          if (state.excludedManually) {
            setExcludedVariants(
              variants
                .filter(v => state.excludedManually.split(',').includes(v.id))
                .map(v => ({...v, selected: true}))
            );
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [searchParams]);

  useEffect(() => {
    if (selectedVariants.length > 0) {
      dispatch({type: 'excludedManually', value: excludedVariants.map(v => v.id).join(',')});
    }
  }, [excludedVariants]);

  return (
    <>
      {+searchParams.get('stage') === 1 ? (
        <Page
          breadcrumbs={[
            {
              content: 'Product Group',
              onAction: () => {
                if (protect) {
                  setOpenLeavePageModal(true);
                  setToNavigate('/product-groups');
                } else {
                  navigate(-1);
                }
              },
            },
          ]}
          title={edit ? 'Edit Product Group' : 'New Product Group'}
        >
          <Card>
            <Card.Section>
              <Box paddingBlockEnd={'4'}>
                <Inline blockAlign="start" align="space-between">
                  <Box width="100%">
                    <TextField
                      error={lengthError && 'Max length of 150 characters reached'}
                      value={state.name}
                      onChange={e => {
                        if (e.length < 150) {
                          dispatch({type: 'name', value: e});
                          setLengthError(false);
                        } else if (e.length === 150) {
                          dispatch({type: 'name', value: e});
                          setLengthError(true);
                        }
                      }}
                      label="Product group name"
                      placeholder="Product group name"
                      fullWidth
                      autoComplete="off"
                    />
                  </Box>
                </Inline>
              </Box>
            </Card.Section>
          </Card>
          <Box paddingBlockStart={'5'} paddingBlockEnd={'5'}>
            <Text variant="headingMd">Product Group Criteria</Text>
          </Box>
          {state.productGroups.members.map((seg, parentIndex) => {
            return (
              <div ref={scollToRef} key={parentIndex}>
                <Box>
                  <ErrorBoundary
                    fallback={
                      <Card>
                        <Card.Section>
                          <div>An error has occured</div>
                        </Card.Section>
                      </Card>
                    }
                  >
                    <Card>
                      <Card.Section>
                        <Box paddingBlockStart={'4'} paddingBlockEnd={'4'} width="99%">
                          {seg.members.map((prop, index) => {
                            return (
                              <Values
                                key={index}
                                parentIndex={parentIndex}
                                index={index}
                                state={state}
                                dispatch={dispatch}
                                prop={prop}
                                options={options}
                                seg={seg}
                              />
                            );
                          })}
                          <Box width="100%">
                            <Inline align="end" blockAlign="end">
                              <Button
                                onClick={() => {
                                  dispatch({type: 'or', value: parentIndex});
                                }}
                              >
                                +OR
                              </Button>
                            </Inline>
                          </Box>
                        </Box>
                      </Card.Section>
                    </Card>
                    <Box paddingBlockStart={'8'} paddingBlockEnd={'8'}>
                      <Button
                        primary
                        onClick={() => {
                          dispatch({type: 'and', value: parentIndex});
                        }}
                      >
                        +AND
                      </Button>
                    </Box>
                  </ErrorBoundary>
                </Box>
              </div>
            );
          })}
          {openLeavePageModal && (
            <LeavePageModal
              currentState={openLeavePageModal}
              toNavigate={toNavigate}
              onClose={setOpenLeavePageModal}
            />
          )}
          {error && <Alert contents={error} init={true} error={error === 'Something Went Wrong'} />}
        </Page>
      ) : +searchParams.get('stage') === 2 ? (
        selectedVariants.length > 0 ? (
          <Stage2NewProductGroup
            selectedVariants={selectedVariants}
            setSelectedVariants={setSelectedVariants}
            excludedVariants={excludedVariants}
            setExcludedVariants={setExcludedVariants}
            state={state}
          />
        ) : (
          <Loading />
        )
      ) : (
        <Page title={'Not Found'}></Page>
      )}
    </>
  );
};

export default NewProductGroup;

function Stage2NewProductGroup({
  selectedVariants,
  setSelectedVariants,
  excludedVariants,
  setExcludedVariants,
  state,
}) {
  const [selectedSearch, setSelectedSearch] = useState('');
  const [selected, setSelected] = useState([...selectedVariants]);

  useEffect(() => {
    if (selectedSearch) {
      setSelected(
        selectedVariants.filter(
          v =>
            v.title.toLowerCase().includes(selectedSearch.toLowerCase()) ||
            v.sku.toLowerCase().includes(selectedSearch.toLowerCase()) ||
            v.variantTitle.toLowerCase().includes(selectedSearch.toLowerCase())
        )
      );
    } else {
      setSelected(selectedVariants);
    }
  }, [selectedSearch]);

  const selectedVariantsTableName = {
    singular: 'variant',
    plural: 'variants',
  };
  const excludedVariantsTableName = {
    singular: 'excluded variant',
    plural: 'excluded variants',
  };

  const handleSelectionChange = (action, isSelected, id) => {
    let variants = [...selectedVariants];
    if (action === 'single') {
      variants.forEach(v => {
        if (v.id === id) {
          v.selected = isSelected;
        }
      });
      selected.forEach(v => {
        if (v.id === id) {
          v.selected = isSelected;
        }
      });
    } else if (action === 'page') {
      variants.forEach(v => {
        v.selected = isSelected;
      });
      selected.forEach(v => {
        v.selected = isSelected;
      });
    }
    setExcludedVariants(
      variants.filter(v => v.selected === false).map(v => ({...v, selected: true}))
    );
    setSelectedVariants(variants);
  };
  const handleExlusionChange = (action, isSelected, id) => {
    if (action === 'single') {
      setSelectedVariants(
        selectedVariants.map(v => {
          if (v.id === id) {
            return {...v, selected: true};
          } else return v;
        })
      );
      setSelected(
        selected.map(v => {
          if (v.id === id) {
            return {...v, selected: true};
          } else return v;
        })
      );
      setExcludedVariants(excludedVariants.filter(v => v.id !== id));
    } else if (action === 'page') {
      let included = excludedVariants.map(v => v.id);

      setSelectedVariants(
        selectedVariants.map(v => {
          if (included.includes(v.id)) {
            return {...v, selected: true};
          } else {
            return v;
          }
        })
      );
      setSelected(
        selected.map(v => {
          if (included.includes(v.id)) {
            return {...v, selected: true};
          } else {
            return v;
          }
        })
      );
      setExcludedVariants([]);
    }
  };

  const selectedVariantRowMarkup = selected.map(
    ({sku, title, variantTitle, id, selected}, index) => (
      <IndexTable.Row id={id} key={index} selected={selected} position={index}>
        <IndexTable.Cell>{title}</IndexTable.Cell>
        <IndexTable.Cell>{variantTitle}</IndexTable.Cell>
        <IndexTable.Cell>{sku}</IndexTable.Cell>
      </IndexTable.Row>
    )
  );
  const excludedVariantRowMarkup = excludedVariants.map(
    ({sku, title, variantTitle, id, selected}, index) => (
      <IndexTable.Row id={id} key={index} selected={selected} position={index}>
        <IndexTable.Cell>{title}</IndexTable.Cell>
        <IndexTable.Cell>{variantTitle}</IndexTable.Cell>
        <IndexTable.Cell>{sku}</IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  return (
    <Page title={'Product Group - ' + state.name}>
      <Layout>
        <Layout.Section oneHalf>
          <Card>
            <Box padding={'4'}>
              <Inline align="space-between">
                <Text fontWeight="bold">Selected Variants</Text>
                <Text color="subdued">
                  {selectedVariants.filter(v => v.selected).length} variants
                </Text>
              </Inline>
            </Box>
            {selectedVariants.length > 5 && (
              <Box paddingInlineStart={'4'} paddingInlineEnd={'4'} paddingBlockEnd={'2'}>
                <Filters
                  queryValue={selectedSearch}
                  filters={[]}
                  appliedFilters={[]}
                  onQueryChange={e => {
                    setSelectedSearch(e);
                  }}
                  onQueryClear={() => {
                    setSelectedSearch('');
                  }}
                  queryPlaceholder={'Filter items'}
                />
              </Box>
            )}
            <IndexTable
              resourceName={selectedVariantsTableName}
              itemCount={selectedVariants.length}
              selectedItemsCount={selectedVariants.filter(v => v.selected).length}
              onSelectionChange={handleSelectionChange}
              headings={[{title: 'Title'}, {title: 'Variant Title'}, {title: 'SKU'}]}
            >
              {selectedVariantRowMarkup}
            </IndexTable>
          </Card>
        </Layout.Section>
        <Layout.Section oneHalf>
          <Card>
            <Box padding={'4'}>
              <Inline align="space-between">
                <Text fontWeight="bold">Excluded Variants</Text>
                <Text color="subdued">{excludedVariants.length} variants</Text>
              </Inline>
            </Box>
            <IndexTable
              resourceName={excludedVariantsTableName}
              itemCount={excludedVariants.length || 1}
              selectedItemsCount={excludedVariants.length}
              onSelectionChange={handleExlusionChange}
              headings={[{title: 'Title'}, {title: 'Variant Title'}, {title: 'SKU'}]}
            >
              {excludedVariantRowMarkup}
            </IndexTable>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
