import {Grid, Spinner} from '@shopify/polaris';
import Inline from '../../../../lib/Inline';
import {formatNumber, toTitleCase} from '../../../../features/format';
import {CircleDisableMinor} from '@shopify/polaris-icons';
import Card from '../../../../lib/Card';
import Stack from '../../../../lib/Stack';
import Text from '../../../../lib/Text';
import Icon from '../../../../lib/Icon';
const Stats = ({stats, state, totalCount}) => {
  let requiredStats = ['completed', 'pending', 'running', 'failed'];
  return (
    <Grid>
      {requiredStats.map(key => {
        return (
          <Grid.Cell columnSpan={{xs: 3, sm: 2, md: 2, lg: 3, xl: 3}}>
            <Card sectioned>
              <Inline align="center">
                <Stack vertical spacing="tight" alignment="center">
                  <Text variant="bodyMd" color="subdued">
                    {toTitleCase(key)}
                  </Text>
                  <Text variant="headingLg">
                    {state === 'Success' ? (
                      formatNumber({value: stats[key] || 0})
                    ) : state === 'Loading' ? (
                      <Spinner size="small" />
                    ) : (
                      <Icon source={CircleDisableMinor} color="base" />
                    )}
                  </Text>{' '}
                  <Text color="subdued">{state === 'Success' && `Out of ${totalCount}`}</Text>
                </Stack>
              </Inline>
            </Card>
          </Grid.Cell>
        );
      })}
    </Grid>
  );
};

export default Stats;
