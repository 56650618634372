import {Frame, Navigation} from '@shopify/polaris';
import React, {useCallback, useState} from 'react';
import MyNavigation from '../../../components/navigation';
import DefaultTopBar from '../../../components/topBar';
import {routeMap} from '../routeMap';
import {AdminNavigation} from '../../../components/Admin/navigation';
import {AdminTopbar} from '../../../components/Admin/topbar';
import CustomHeader from '../../../components/customHeader';

export const FrameWrapper = ({id}) => {
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const toggleMobileNavigationActive = useCallback(
    () => setMobileNavigationActive(mobileNavigationActive => !mobileNavigationActive),
    []
  );
  return routeMap[id].frameRequired === true ? (
    <Frame
      logo={{
        width: 124,
        topBarSource: '/images/logo.svg',
        url: '/',
      }}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      navigation={<MyNavigation />}
      topBar={
        <DefaultTopBar
          mobileNavigationActive={mobileNavigationActive}
          setMobileNavigationActive={setMobileNavigationActive}
        />
      }
    >
      <CustomHeader />
      {routeMap[id].component}
    </Frame>
  ) : routeMap[id].adminFrameRequired === true ? (
    <Frame
      logo={{
        width: 124,
        topBarSource: '/images/logo.svg',
        url: '/',
      }}
      showMobileNavigation={mobileNavigationActive}
      navigation={<AdminNavigation />}
      topBar={
        <AdminTopbar
          mobileNavigationActive={mobileNavigationActive}
          setMobileNavigationActive={setMobileNavigationActive}
        />
      }
    >
      {routeMap[id].component}
    </Frame>
  ) : id === 'dash' ? (
    <Frame
      logo={{
        width: 124,
        topBarSource: '/images/logo.svg',
        url: '/',
      }}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      topBar={
        <DefaultTopBar
          mobileNavigationActive={mobileNavigationActive}
          setMobileNavigationActive={setMobileNavigationActive}
          disableNavigation
        />
      }
      navigation={mobileNavigationActive && <Navigation />}
    >
      {routeMap[id].component}
    </Frame>
  ) : (
    routeMap[id].component
  );
};
