import React, {useEffect} from 'react';
import {Popover, TextField, Scrollable, DatePicker, OptionList} from '@shopify/polaris';
import {ArrowRightMinor} from '@shopify/polaris-icons';
import {useState, useCallback} from 'react';
import {previousPeriod, previousYear} from '../features/convertDates';
import config from '../config';
import Button from '../lib/Button';
import Box from '../lib/Box';
import Inline from '../lib/Inline';
import Icon from '../lib/Icon';

const dateCompareObj = {
  previousPeriod: previousPeriod,
  previousYear: previousYear,
  noComparison: () => {
    return ['', ''];
  },
};
const dateOptions = [
  {label: 'No comparison', value: 'noComparison', active: false},
  {label: 'Previous period', value: 'previousPeriod', active: false},
  {label: 'Previous year', value: 'previousYear', active: false},
];
// const quarterOptions = [
//   {label: '3rd Quarter 2022', value: 'today', active: false},
//   {label: '2nd Quarter 2022', value: 'yesterday', active: false},
//   {label: '1st Quarter 2022', value: 'lastSevenDays', active: false},
// ];

const dateToText = d => {
  function z(n) {
    return (n < 10 ? '0' : '') + n;
  }
  d = new Date(d);
  let year = d.getFullYear();
  let month = d.getMonth() + 1;
  let day = d.getDate();
  return `${year}-${z(month)}-${z(day)}`;
};
const today = new Date();
today.setHours(23, 59, 59, 999);
const DateCompareComponent = ({
  getDates,
  currentDates,
  activator,
  togglePopoverActive,
  popoverActive,
  selectedCompare,
  selectedDateRangeDates,
}) => {
  const [selectedDates, setSelectedDates] = useState(currentDates);
  //   const [popoverActive, setPopoverActive] = useState(false);
  // const [tagValue, setTagValue] = useState('');
  const [dateOptionSelected, setDateOptionSelected] = useState([selectedCompare]);
  const [startDateTextInput, setStartDateTextInput] = useState(
    dateOptionSelected.length && dateOptionSelected[0] === 'noComparison'
      ? ''
      : dateToText(selectedDates.start)
  );
  const [endDateTextInput, setEndDateTextInput] = useState(
    dateOptionSelected.length && dateOptionSelected[0] === 'noComparison'
      ? ''
      : dateToText(selectedDates.end)
  );

  // const [quarterOpen, setQuarterOpen] = useState(false);

  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const handleMonthChange = useCallback((month, year) => setDate({month, year}), []);
  const [{month, year}, setDate] = useState({
    month: new Date(currentDates.start).getMonth(),
    year: new Date(currentDates.start).getFullYear(),
  });

  // const handleTagValueChange = useCallback(value => setTagValue(value), []);

  useEffect(() => {
    setStartDateTextInput(
      dateOptionSelected.length && dateOptionSelected[0] === 'noComparison'
        ? ''
        : dateToText(selectedDates.start)
    );
    setEndDateTextInput(
      dateOptionSelected.length && dateOptionSelected[0] === 'noComparison'
        ? ''
        : dateToText(selectedDates.end)
    );
    if (!(dateOptionSelected.length && dateOptionSelected[0] === 'noComparison')) {
      setDate({
        month: new Date(selectedDates.start).getMonth(),
        year: new Date(selectedDates.start).getFullYear(),
      });
    } else {
      setDate({
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      });
    }
  }, [selectedDates]);
  useEffect(() => {
    if (dateOptionSelected.length) {
      if (dateOptionSelected[0]) {
        let [sdate, edate] = dateCompareObj[dateOptionSelected](selectedDateRangeDates);

        setSelectedDates({start: sdate, end: edate});
        if (dateOptionSelected[0] !== 'noComparison') {
          setDate({
            year: sdate.getFullYear(),
            month: sdate.getMonth(),
          });
        }
      }
    }
  }, [dateOptionSelected, selectedDateRangeDates]);

  useEffect(() => {
    if (selectedCompare) {
      setDateOptionSelected([selectedCompare]);
    } else {
      setDateOptionSelected([]);
    }
    if (selectedCompare) {
      let [sdate, edate] = dateCompareObj[selectedCompare](selectedDateRangeDates);

      setSelectedDates({start: sdate, end: edate});
      if (selectedCompare !== 'noComparison') {
        setDate({
          year: sdate.getFullYear(),
          month: sdate.getMonth(),
        });
      }
    }
    // else {
    //   setSelectedDates(currentDates);
    // }
  }, [popoverActive]);

  return (
    <Popover
      active={popoverActive}
      activator={activator}
      onClose={togglePopoverActive}
      ariaHaspopup={false}
      preferredAlignment="left"
      fluidContent={true}
    >
      <Box style={{maxWidth: '50rem'}}>
        <Popover.Pane fixed>
          <Box padding={'3'} paddingBlockEnd={'0'}>
            <Inline blockAlign="start">
              <Box width="25%">
                <Scrollable shadow style={{height: '33vh'}} horizontal={false} focusable>
                  <OptionList
                    selected={dateOptionSelected}
                    onChange={e => {
                      setUnsavedChanges(true);

                      setDateOptionSelected(e);
                    }}
                    options={dateOptions}
                  />
                  {/* <Stack vertical>
                    <Button                                 will be required later 
                      fullWidth
                      textAlign="left"
                      disclosure={quarterOpen ? "up" : "down"}
                      onClick={() => {
                        setQuarterOpen(!quarterOpen);
                      }}
                    >
                      Quaters
                    </Button>
                    <Collapsible
                      open={quarterOpen}
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                    >
                      <OptionList selected={[]} options={quarterOptions} />
                    </Collapsible>
                  </Stack> */}
                </Scrollable>
              </Box>
              <Box width="70%" padding={'2'}>
                <Inline gap={'2'} blockAlign="start">
                  <TextField
                    onChange={e => {
                      setDateOptionSelected([]);
                      setUnsavedChanges(true);
                      setStartDateTextInput(e);
                    }}
                    onBlur={e => {
                      setSelectedDates({
                        ...selectedDates,
                        start: new Date(e.target.value),
                      });
                    }}
                    value={startDateTextInput}
                    fullWidth
                    type="text"
                    placeholder="YYYY-MM-DD"
                  />
                  <Icon source={ArrowRightMinor} color="base" />
                  <TextField
                    onChange={e => {
                      setDateOptionSelected([]);
                      setUnsavedChanges(true);
                      setEndDateTextInput(e);
                    }}
                    onBlur={e => {
                      setSelectedDates({
                        ...selectedDates,
                        end: new Date(e.target.value),
                      });
                    }}
                    value={endDateTextInput}
                    type="text"
                    placeholder="YYYY-MM-DD"
                  />
                </Inline>
                <Box padding={'2'} paddingBlockStart={'5'}>
                  <DatePicker
                    selected={
                      selectedDates.start ? selectedDates : {start: new Date(''), end: new Date('')}
                    }
                    multiMonth
                    month={month}
                    year={year}
                    weekStartsOn={config.START_OF_WEEK}
                    onChange={e => {
                      if (e.start === 'Invalid Date') {
                        e.start = e.end;
                      }

                      setDateOptionSelected([]);
                      e.end.setHours(23, 59, 59);
                      e.start.setHours(0, 0, 0);
                      setSelectedDates(e);
                      setUnsavedChanges(true);
                    }}
                    onMonthChange={handleMonthChange}
                    allowRange
                    disableDatesAfter={today}
                  />
                </Box>
              </Box>
            </Inline>
          </Box>
        </Popover.Pane>

        <Popover.Pane fixed>
          <Box padding={'3'}>
            <Inline align="end">
              <Button
                onClick={() => {
                  togglePopoverActive();
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={!unsavedChanges}
                onClick={() => {
                  getDates(selectedDates, dateOptionSelected);
                  togglePopoverActive();
                }}
                primary
              >
                Apply
              </Button>
            </Inline>
          </Box>
        </Popover.Pane>
      </Box>
    </Popover>
  );
};

export default DateCompareComponent;
