import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/App';
import '@shopify/polaris/build/esm/styles.css';
import {PolarisVizProvider} from '@shopify/polaris-viz';
import '@shopify/polaris-viz/build/esm/styles.css';
import './index.css';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import mixpanel from 'mixpanel-browser';
import {LocalStorageService} from './service/localStorage';
import {PostHogProvider} from 'posthog-js/react';

const posthogOptions = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const userDetails = LocalStorageService.getUserProfile();
const email = userDetails ? userDetails.email : 'NA';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN || '';
mixpanel.init(mixpanelToken, {
  debug: true,
  // api_host: 'http://localhost:8000/rest',
});
mixpanel.set_config({persistence: 'localStorage'});
// Set this to a unique identifier for the user performing the event.
// eg: their ID in your database or their email address.
mixpanel.identify(email);

root.render(
  // <React.StrictMode>
  <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={posthogOptions}>
    <PolarisVizProvider>
      <App />
    </PolarisVizProvider>
  </PostHogProvider>
  // </React.StrictMode>
);
