import {useState} from 'react';
import {generateInfoText} from '../../../features/generateText';
import DefinationPopover from '../../../components/definationPopover';
import {CircleInformationMajor} from '@shopify/polaris-icons';
import {Link, Icon} from '@shopify/polaris';
import {useNavigate} from 'react-router-dom';
import Inline from '../../../lib/Inline';

const SegmentHeading = ({item, trialPeriodStatus, currentPlan}) => {
  const [infoPopoverActive, setInfoPopoverActive] = useState(false);
  const navigate = useNavigate();

  let url;
  if (item.segmentType === 'Pre-built') {
    url = `/segment/${item._id}`;
  } else if (item.segmentType !== 'Pre-built') {
    if (trialPeriodStatus === 'active') {
      url = `/segment/${item._id}`;
    } else if (currentPlan !== 'Free') {
      url = `/segment/${item._id}`;
    } else {
      url = `/pricing`;
    }
  }
  const linkToSegment = (
    <Link
      url={url}
      removeUnderline
      onClick={event => {
        event.preventDefault();
        navigate(url);
      }}
      key="emerald-silk-gown"
    >
      {item.segmentDetail.name}
    </Link>
  );

  return (
    <>
      <Inline key={item._id}>
        {generateInfoText(item.segmentDetail.name) ? (
          <DefinationPopover
            text={generateInfoText(item.segmentDetail.name)}
            togglePopoverActive={() => {
              setInfoPopoverActive(false);
            }}
            popoverActive={infoPopoverActive}
            activator={
              <span
                style={{cursor: 'pointer'}}
                onMouseEnter={() => {
                  setInfoPopoverActive(true);
                }}
                onMouseLeave={() => {
                  setInfoPopoverActive(false);
                }}
              >
                {linkToSegment}
              </span>
            }
          />
        ) : (
          <>{linkToSegment}</>
        )}
      </Inline>
    </>
  );
};

export default SegmentHeading;
