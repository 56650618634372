const daysOfWeekInit = [
  {label: 'Sunday', value: '0', checked: false},
  {label: 'Monday', value: '1', checked: false},
  {label: 'Tuesday', value: '2', checked: false},
  {label: 'Wednesday', value: '3', checked: false},

  {label: 'Thursday', value: '4', checked: false},
  {label: 'Friday', value: '5', checked: false},
  {label: 'Saturday', value: '6', checked: false},
];

const daysOfMonthInit = [
  {label: 1, value: '1', checked: false},
  {label: 2, value: '2', checked: false},
  {label: 3, value: '3', checked: false},
  {label: 4, value: '4', checked: false},
  {label: 5, value: '5', checked: false},
  {label: 6, value: '6', checked: false},
  {label: 7, value: '7', checked: false},
  {label: 8, value: '8', checked: false},
  {label: 9, value: '9', checked: false},
  {label: 10, value: '10', checked: false},
  {label: 11, value: '11', checked: false},
  {label: 12, value: '12', checked: false},
  {label: 13, value: '13', checked: false},
  {label: 14, value: '14', checked: false},
  {label: 15, value: '15', checked: false},
  {label: 16, value: '16', checked: false},
  {label: 17, value: '17', checked: false},
  {label: 18, value: '18', checked: false},
  {label: 19, value: '19', checked: false},
  {label: 20, value: '20', checked: false},
  {label: 21, value: '21', checked: false},
  {label: 22, value: '22', checked: false},
  {label: 23, value: '23', checked: false},
  {label: 24, value: '24', checked: false},
  {label: 25, value: '25', checked: false},
  {label: 26, value: '26', checked: false},
  {label: 27, value: '27', checked: false},
  {label: 28, value: '28', checked: false},
  {label: 29, value: '29', checked: false},
  {label: 30, value: '30', checked: false},
  {label: 31, value: '31', checked: false},
];

const specificHourInit = [
  {label: 'Every hour', value: 'everyHour', checked: false},
  {label: '12 AM', value: '00', checked: false},
  {label: '01 AM', value: '01', checked: false},
  {label: '02 AM', value: '02', checked: false},
  {label: '03 AM', value: '03', checked: false},
  {label: '04 AM', value: '04', checked: false},
  {label: '05 AM', value: '05', checked: false},
  {label: '06 AM', value: '06', checked: false},
  {label: '07 AM', value: '07', checked: false},
  {label: '08 AM', value: '08', checked: false},
  {label: '09 AM', value: '09', checked: false},
  {label: '10 AM', value: '10', checked: false},
  {label: '11 AM', value: '11', checked: false},
  {label: '12 PM', value: '12', checked: false},
  {label: '01 PM', value: '13', checked: false},
  {label: '02 PM', value: '14', checked: false},
  {label: '03 PM', value: '15', checked: false},
  {label: '04 PM', value: '16', checked: false},
  {label: '05 PM', value: '17', checked: false},
  {label: '06 PM', value: '18', checked: false},
  {label: '07 PM', value: '19', checked: false},
  {label: '08 PM', value: '20', checked: false},
  {label: '09 PM', value: '21', checked: false},
  {label: '10 PM', value: '22', checked: false},
  {label: '11 PM', value: '23', checked: false},
];

export {daysOfWeekInit, daysOfMonthInit, specificHourInit};
