// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".coldStart_container__HwASZ {\n  display: flex;\n  align-items: center;\n  /* min-height: 92vh; */\n}\n", "",{"version":3,"sources":["webpack://./src/pages/coldStart/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;AACxB","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  /* min-height: 92vh; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "coldStart_container__HwASZ"
};
export default ___CSS_LOADER_EXPORT___;
