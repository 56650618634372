import axios from './axios';
import config from '../config';

const {BASE_URL} = config;

export async function getOrg() {
  try {
    const response = await axios.get(`${BASE_URL}/org`);
    return response.data;
  } catch (err) {
    return err;
  }
}
