import {Frame} from '@shopify/polaris';
import React from 'react';
import NewSegment from './newSegment';
import MyNavigation from '../../components/navigation';
import SaveBar from '../../components/saveBar';
import {useSaveChangesStore} from '../../app/store';
import {useParams} from 'react-router-dom';
const Segment = ({edit}) => {
  const isUnchanged = useSaveChangesStore(state => state.isUnchanged);
  const params = useParams();
  return (
    <>
      <Frame
        logo={{
          width: 124,
          contextualSaveBarSource: '/images/logo.svg',
        }}
        topBar={isUnchanged ? <SaveBar /> : null}
        navigation={<MyNavigation />}
      >
        <NewSegment params={params} edit={edit} />
      </Frame>
    </>
  );
};

export default Segment;
