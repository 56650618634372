import React, {useCallback, useEffect, useState} from 'react';

import {TextField, Tooltip} from '@shopify/polaris';
import {CancelMajor} from '@shopify/polaris-icons';
import ExcludeSelect from '../components/select';
import {
  usePreventNavigateStore,
  useSaveChangesStore,
  setButtonEnabled,
} from '../../../../app/store';
import {
  clearExcludedSettings,
  getReportsSetting,
  saveReportsSetting,
} from '../../../../api/reports/settings';
import {ClearModal} from '../../../../components/modal';
import Button from '../../../../lib/Button';
import Box from '../../../../lib/Box';
import Card from '../../../../lib/Card';
import Inline from '../../../../lib/Inline';
import Icon from '../../../../lib/Icon';
import Text from '../../../../lib/Text';
import TextContainer from '../../../../lib/TextContainer';
const ReportSettings = () => {
  const [enable, setEnable] = useState(false);
  const isUnchanged = useSaveChangesStore(state => state.setIsUnchanged);
  const setProtect = usePreventNavigateStore(state => state.setProtect);
  const setOnDiscard = useSaveChangesStore(state => state.setOnDiscard);
  const [value, setValue] = useState('no');
  const [accessValue, setAccessValue] = useState('accessno');
  const [maxAbandonedQuantityPerCheckout, setMaxAbandonedQuantityPerCheckout] = useState();
  const setOnSubmit = useSaveChangesStore(state => state.setOnSubmit);
  const setButtonEnabled = useSaveChangesStore(state => state.setButtonEnabled);
  const [clearModal, setClearModal] = useState(false);
  const [showExludeOptions, setShowExludeOptions] = useState(false);
  const [isMaxAbandonedQuantityChanged, setIsMaxAbandonedQuantityChanged] = useState(false);

  // const [deduction, setDeduction] = useState({
  //   refunds: false,
  //   tax: false,
  //   shipping: false,
  //   discounts: false,
  // });
  const [excludeData, setExcludeData] = useState([{selectedField: '', value: ''}]);
  let data = {sku: []}; //variantId: [], customerId: []
  const onSave = () => {
    saveReportsSetting({data, maxAbandonedQuantityPerCheckout});
    setEnable(!enable);
    isUnchanged(false);
    setProtect(false);
  };
  // const handleChange = useCallback(
  //   (_checked, newValue) => setValue(newValue),
  //   []
  // );
  // const handleAccessChange = useCallback(
  //   (_checked, newValue) => setAccessValue(newValue),
  //   []
  // );

  useEffect(() => {
    setOnSubmit(onSave);
    if (
      excludeData.map(x => x.selectedField).filter(x => x).length ===
        excludeData.map(x => x.value).filter(x => x).length &&
      excludeData[0].selectedField &&
      enable
    ) {
      excludeData.forEach(x => data[x.selectedField].push(x.value));
      setButtonEnabled(true);
    } else if (isMaxAbandonedQuantityChanged) {
      setButtonEnabled(true);
    } else {
      setButtonEnabled(false);
    }
  }, [excludeData, maxAbandonedQuantityPerCheckout]);

  useEffect(() => {
    getReportsSetting().then(result => {
      let maxAbandonedQuantity = result.data.data.maxAbandonedQuantityPerCheckout;
      if (maxAbandonedQuantity !== null || maxAbandonedQuantity !== undefined) {
        setMaxAbandonedQuantityPerCheckout(maxAbandonedQuantity);
      } else {
        setMaxAbandonedQuantityPerCheckout(20);
      }
      let settings = result.data.data.excludeData;
      let keys = Object.keys(settings);
      let selectedData = [];
      let data = [];
      keys.forEach(key => {
        if (settings[key].length) {
          selectedData.push(key);
        }
        settings[key].forEach(value => {
          data.push({selectedField: key, value});
        });
      });
      data.length && setExcludeData(data);
      setShowExludeOptions(true);
    });
  }, []);
  const handleClear = () => {
    clearExcludedSettings();
    setExcludeData([{selectedField: '', value: ''}]);
    setEnable(false);
    isUnchanged(false);
  };
  const onDiscard = () => {
    setEnable(false);
  };

  return (
    <>
      <Box>
        <Box paddingBlockEnd={'8'}>
          <Inline blockAlign="start" align="space-between">
            <TextContainer spacing="tight">
              <Text variant="headingMd">Exclude Data</Text>
              <Text color="subdued">
                Exclude data from reports calculations as per your business needs.
              </Text>
            </TextContainer>
            {!enable && (
              <Box paddingInlineEnd={'3'}>
                <Button
                  primary
                  onClick={() => {
                    setEnable(true);
                    isUnchanged(true);
                    setProtect(true);
                    setOnDiscard(onDiscard);
                  }}
                >
                  Edit
                </Button>
              </Box>
            )}
          </Inline>
        </Box>
        <Box>
          <Card>
            <Card.Section>
              <Box maxWidth="10rem" minWidth="10rem" paddingBlockStart={'4'} paddingBlockEnd={'6'}>
                <TextField
                  value={maxAbandonedQuantityPerCheckout}
                  onChange={val => {
                    setMaxAbandonedQuantityPerCheckout(val);
                    setIsMaxAbandonedQuantityChanged(true);
                  }}
                  disabled={!enable}
                  label={
                    <Tooltip
                      active
                      content={`Abandoned cart orders with quantities greater than ${maxAbandonedQuantityPerCheckout} have been ignored.`}
                    >
                      <Text>Max abandoned quantity</Text>
                    </Tooltip>
                  }
                  type="number"
                />
              </Box>
              {/* <Box paddingBlockStart={"4"} paddingBlockEnd={"6"}>
                <Stack vertical spacing="tight">
                  <Text>
                    Is your product pricing inclusive of tax in Shopify?
                  </Text>
                  <Inline>
                    <Box>
                      <Stack spacing="extraTight" alignment="center">
                        <RadioButton
                          disabled={!enable}
                          checked={value === "no"}
                          id="no"
                          name="tax"
                          onChange={handleChange}
                        />
                        <Text color={!enable && "subdued"}>No</Text>
                      </Stack>
                    </Box>
                    <Box>
                      <Stack spacing="extraTight" alignment="center">
                        <RadioButton
                          disabled={!enable}
                          id="yes"
                          name="tax"
                          checked={value === "yes"}
                          onChange={handleChange}
                        />
                        <Text color={!enable && "subdued"}>Yes</Text>
                      </Stack>
                    </Box>
                  </Inline>
                </Stack>
              </Box> */}

              {/* <Box paddingBlockStart={"4"} paddingBlockEnd={"6"}>
                <Stack vertical spacing="tight">
                  <Text>
                    What should be deducted from Gross sales to get Net sales?
                  </Text>
                  <Stack spacing="loose">
                    <Box>
                      <Stack spacing="extraTight" alignment="center">
                        <Checkbox
                          disabled={!enable}
                          checked={deduction.refunds}
                          onChange={() => {
                            setDeduction({
                              ...deduction,
                              refunds: !deduction.refunds,
                            });
                          }}
                        />
                        <Text color={!enable && "subdued"}>Refunds</Text>
                      </Stack>
                    </Box>
                    <Box>
                      <Stack spacing="extraTight" alignment="center">
                        <Checkbox
                          disabled={!enable}
                          checked={deduction.tax}
                          onChange={() => {
                            setDeduction({
                              ...deduction,
                              tax: !deduction.tax,
                            });
                          }}
                        />
                        <Text color={!enable && "subdued"}>Tax</Text>
                      </Stack>
                    </Box>
                    <Box>
                      <Stack spacing="extraTight" alignment="center">
                        <Checkbox
                          disabled={!enable}
                          checked={deduction.shipping}
                          onChange={() => {
                            setDeduction({
                              ...deduction,
                              shipping: !deduction.shipping,
                            });
                          }}
                        />
                        <Text color={!enable && "subdued"}>Shipping</Text>
                      </Stack>
                    </Box>
                    <Box>
                      <Stack spacing="extraTight" alignment="center">
                        <Checkbox
                          disabled={!enable}
                          checked={deduction.discounts}
                          onChange={() => {
                            setDeduction({
                              ...deduction,
                              discounts: !deduction.discounts,
                            });
                          }}
                        />
                        <Text color={!enable && "subdued"}>Discounts</Text>
                      </Stack>
                    </Box>
                  </Stack>
                </Stack>
              </Box>
              <Box paddingBlockStart={"4"} paddingBlockEnd={"6"}>
                <Stack vertical spacing="tight">
                  <Text>Anonymize Customer Data for non view only access?</Text>
                  <Inline>
                    <Box>
                      <Stack spacing="extraTight" alignment="center">
                        <RadioButton
                          disabled={!enable}
                          checked={accessValue === "accessno"}
                          id="accessno"
                          name="access"
                          onChange={handleAccessChange}
                        />
                        <Text color={!enable && "subdued"}>No</Text>
                      </Stack>
                    </Box>
                    <Box>
                      <Stack spacing="extraTight" alignment="center">
                        <RadioButton
                          disabled={!enable}
                          id="accessyes"
                          name="access"
                          checked={accessValue === "accessyes"}
                          onChange={handleAccessChange}
                        />
                        <Text color={!enable && "subdued"}>Yes</Text>
                      </Stack>
                    </Box>
                  </Inline>
                </Stack>
              </Box> */}

              <Box paddingBlockStart={'4'} paddingBlockEnd={'8'}>
                <Inline align="space-between">
                  {/* <Text>Exclude Data</Text> */}
                  {enable && excludeData[0].value && (
                    <Button plain destructive onClick={() => setClearModal(true)}>
                      Clear All
                    </Button>
                  )}
                </Inline>
                {!excludeData.length && !enable ? (
                  <>
                    <Box paddingBlockStart={'4'}>
                      <Text color="subdued">No Data exclude rule set</Text>
                    </Box>
                  </>
                ) : (
                  <>
                    {excludeData.map((data, index) => {
                      return (
                        <Box paddingBlockStart={'4'}>
                          {clearModal && (
                            <ClearModal
                              currentState={clearModal}
                              clearContent={'excluded data'}
                              onClear={handleClear}
                              onClose={() => setClearModal(false)}
                            />
                          )}
                          <Inline>
                            <Box width="10%" minWidth="10rem">
                              {showExludeOptions && (
                                <ExcludeSelect
                                  disabled={!enable}
                                  value={data.selectedField}
                                  handle={val => {
                                    excludeData[index].selectedField = val;
                                    setExcludeData([...excludeData]);
                                  }}
                                />
                              )}
                            </Box>
                            <Box maxWidth="10rem" minWidth="10rem">
                              <TextField
                                disabled={!enable}
                                value={data.value}
                                placeholder="Value"
                                error={data.error && 'Max length of 150 characters reached'}
                                onChange={e => {
                                  if (e.length < 150) {
                                    excludeData[index].value = e;
                                    excludeData[index].error = false;

                                    setExcludeData([...excludeData]);
                                  } else if (e.length === 150) {
                                    excludeData[index].value = e;
                                    excludeData[index].error = true;

                                    setExcludeData([...excludeData]);
                                  }
                                }}
                              />
                            </Box>
                            {enable && excludeData.length > 1 && (
                              <Box>
                                <Button
                                  onClick={() => {
                                    excludeData.splice(index, 1);
                                    setExcludeData([...excludeData]);
                                  }}
                                  destructive
                                  outline
                                >
                                  <Icon source={CancelMajor} />
                                </Button>
                              </Box>
                            )}
                          </Inline>
                        </Box>
                      );
                    })}
                  </>
                )}
                {enable && (
                  <Box paddingBlockStart={'6'}>
                    <Button
                      onClick={() => {
                        setExcludeData([...excludeData, {selectedField: '', value: ''}]);
                      }}
                    >
                      Add new rule
                    </Button>
                  </Box>
                )}
              </Box>
            </Card.Section>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default ReportSettings;
