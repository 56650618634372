import {TopBar, Icon, Text} from '@shopify/polaris';
import React, {useState, useCallback, useEffect, useContext} from 'react';
import {ProfileMajor, ExitMajor, SettingsMajor} from '@shopify/polaris-icons';
import {LocalStorageService} from './../service/localStorage/index';
import {useNavigate} from 'react-router-dom';
import {clearAll} from '../features/clearData';
import {AuthContext} from '../context/auth';
import {useSnapshotStore} from '../app/store';
const DefaultTopBar = ({
  mobileNavigationActive,
  setMobileNavigationActive,
  disableNavigation = false,
}) => {
  const {resetAll} = useSnapshotStore(state => state);
  const {adminOrganizationSignOut} = useContext(AuthContext);
  const navigate = useNavigate();
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isAdminUserMenuOpen, setIsAdminUserMenuOpen] = useState(false);
  const [orgName, setOrgName] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const toggleIsUserMenuOpen = useCallback(
    () => setIsUserMenuOpen(isUserMenuOpen => !isUserMenuOpen),
    []
  );
  const toggleMobileNavigationActive = useCallback(
    () => setMobileNavigationActive(mobileNavigationActive => !mobileNavigationActive),
    []
  );
  const toggleIsAdminUserMenuOpen = useCallback(
    () => setIsAdminUserMenuOpen(isAdminUserMenuOpen => !isAdminUserMenuOpen),
    []
  );

  const secondaryMenuMarkup = isAdmin ? (
    <TopBar.UserMenu
      name="Admin"
      initials="A"
      open={isAdminUserMenuOpen}
      onToggle={toggleIsAdminUserMenuOpen}
      actions={[
        {
          items: [
            {
              content: 'Back to admin page',
              icon: ExitMajor,
              onAction: () => {
                adminOrganizationSignOut();
                navigate('/admin/organizations');
              },
            },
          ],
        },
      ]}
    />
  ) : null;

  const items = disableNavigation
    ? []
    : [
        {
          content: 'Profile',
          icon: ProfileMajor,
          onAction: () => {
            navigate('/profile');
          },
        },
        {
          content: 'Settings',
          icon: SettingsMajor,
          onAction: () => {
            navigate('/settings');
          },
        },
        {
          content: 'Logout',
          icon: ExitMajor,
          onAction: () => {
            clearAll();
            resetAll();
            navigate('/');
          },
        },
      ];

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={[
        {
          items,
        },
      ]}
      name={orgName}
      initials={orgName && orgName[0].toUpperCase()}
      open={isUserMenuOpen}
      onToggle={toggleIsUserMenuOpen}
    />
  );
  useEffect(() => {
    let details = LocalStorageService.getUserOrg();
    let adminDetails = LocalStorageService.getAdminDetails();
    if (adminDetails) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
    setOrgName(details?.name);
  }, []);
  return (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      secondaryMenu={secondaryMenuMarkup}
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );
};

export default DefaultTopBar;
