import {TextField, Button, Modal, Text, Inline, Checkbox} from '@shopify/polaris';
import {useState, useCallback} from 'react';
import {LocalStorageService} from '../../service/localStorage';
import {getCurrency} from '../../pages/cost/transaction/currencySymbol';
import MultiSelect from '../multiSelect';

const FixedCostModal = ({isOpen, handleSave, toggle}) => {
  const currency = LocalStorageService.getUserOrg().currency;

  const [expenseTitle, setExpenseTitle] = useState('');
  const [expense, setExpense] = useState('');
  const [expenseCategory, setExpenseCategory] = useState('');
  const [recurrenceNumber, setRecurrenceNumber] = useState('');
  const [recurrencePeriod, setRecurrencePeriod] = useState('days');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const recurrencePeriodOptions = [
    {label: 'Days', value: 'days'},
    {label: 'Months', value: 'months'},
    {label: 'Years', value: 'years'},
  ];
  const [checked, setChecked] = useState(false);
  const [checkedAd, setCheckedAd] = useState(false);

  const activator = <div></div>;
  const handleChange = useCallback(newChecked => setChecked(newChecked), []);
  const handleChangeAd = useCallback(newChecked => setCheckedAd(newChecked), []);
  const setDefaultState = () => {
    setChecked(false);
    setCheckedAd(false);
    setEndDate('');
    setExpense('');
    setStartDate('');
    setExpenseCategory('');
    setExpenseTitle('');
    setRecurrenceNumber('');
    setRecurrencePeriod('');
  };

  return (
    <>
      <Modal activator={activator} open={isOpen} onClose={toggle} title="Add new fixed expense">
        <Modal.Section>
          <Inline>
            <TextField
              value={expenseTitle}
              onChange={value => setExpenseTitle(value)}
              label="Title"
            />
            <TextField
              value={expense}
              onChange={value => setExpense(value)}
              label="Cost"
              prefix={getCurrency(currency)}
            />
            <TextField
              value={expenseCategory}
              onChange={value => setExpenseCategory(value)}
              label="Category"
              placeholder="Type in category..."
            />
          </Inline>
        </Modal.Section>
        <Modal.Section>
          <Inline>
            <Checkbox checked={checked} onChange={handleChange} label="Recurring every" />
            <TextField
              value={recurrenceNumber}
              onChange={value => setRecurrenceNumber(value)}
              placeholder="Number"
            />
            <MultiSelect
              selected={recurrencePeriod}
              setSelected={setRecurrencePeriod}
              options={recurrencePeriodOptions}
              title={recurrencePeriod}
              disableMultiple
            ></MultiSelect>
          </Inline>
        </Modal.Section>
        <Modal.Section>
          <Inline>
            <TextField
              value={startDate}
              onChange={value => setStartDate(value)}
              label="Starting"
              placeholder="YYYY-MM-DD"
            />
            <TextField
              value={endDate}
              onChange={value => setEndDate(value)}
              label="Ending (optional)"
              placeholder="YYYY-MM-DD"
            />
          </Inline>
        </Modal.Section>
        <Modal.Section>
          <Checkbox checked={checkedAd} onChange={handleChangeAd} label="Ad Spend" />
          <Text as="p" color="subdued">
            This will be included in total ad spend, and will affect all metrics that includes
            blended ads in their calculations.
          </Text>
        </Modal.Section>
        <Modal.Section>
          <Inline align="end">
            <Button
              onClick={async () => {
                await handleSave({
                  expense,
                  expenseCategory,
                  expenseTitle,
                  isRecurring: checked,
                  recurrenceNumber,
                  recurrencePeriod,
                  startDate,
                  endDate,
                  isAdSpend: checkedAd,
                });
                toggle();
                setDefaultState();
              }}
              primary
            >
              Save
            </Button>
          </Inline>
        </Modal.Section>
      </Modal>
    </>
  );
};

export default FixedCostModal;
