import React from 'react';
import {Popover, Text} from '@shopify/polaris';
import Box from '../lib/Box';
import Card from '../lib/Card';
const DefinationPopover = ({
  popoverActive,
  activator,
  togglePopoverActive,
  text,
  maxWidth = '18rem',
}) => {
  return (
    <>
      <Popover
        active={popoverActive}
        activator={activator}
        autofocusTarget="first-node"
        onClose={togglePopoverActive}
        preferredPosition={'above'}
        preferredAlignment={'left'}
      >
        <Card>
          <Box maxWidth={maxWidth} padding={'4'}>
            <Text as={'p'} alignment="justify">
              {text}
            </Text>
          </Box>
        </Card>
      </Popover>
    </>
  );
};

export default DefinationPopover;
