import React, {useState, useCallback} from 'react';
import Toast from '../lib/Toast';
const Alert = ({contents, init, error, duration}) => {
  const [active, setActive] = useState(init);
  const toggleActive = useCallback(() => setActive(active => !active), []);
  const toastMarkup = active ? (
    <Toast error={error} content={contents} onDismiss={toggleActive} duration={duration || 5000} />
  ) : null;

  return <>{toastMarkup}</>;
};

export default Alert;
