import {Popover, OptionList} from '@shopify/polaris';
import {useState, useCallback} from 'react';
import Button from '../lib/Button';

const SelectRFMComponent = ({rfmSegment, setRfmSegment, setPage, setQueryValue = () => {}}) => {
  const [popoverActive, setPopoverActive] = useState(false);
  const togglePopoverActive = useCallback(
    () => setPopoverActive(popoverActive => !popoverActive),
    []
  );

  const buttonLabelSelector = {
    'All Customers': 'All Customers',
    Royals: 'Royals',
    Loyals: 'Loyals',
    Aspirants: 'Aspirants',
    'In-Betweeners': 'In-Betweeners',
    'Blue-Moons': 'Blue-Moons',
    Newcomers: 'Newcomers',
    'At-Risk': 'At-Risk',
    'Window-Shoppers': 'Window-Shoppers',
  };
  const activator = (
    <Button onClick={togglePopoverActive} disclosure>
      {buttonLabelSelector[rfmSegment]}
    </Button>
  );
  return (
    <Popover active={popoverActive} activator={activator} onClose={togglePopoverActive}>
      <OptionList
        selected={rfmSegment}
        options={[
          {label: 'All Customers', value: 'All Customers', active: false},
          {label: 'Royals', value: 'Royals', active: false},
          {label: 'Loyals', value: 'Loyals', active: false},
          {label: 'Aspirants', value: 'Aspirants', active: false},
          {label: 'In-Betweeners', value: 'In-Betweeners', active: false},
          {label: 'Blue-Moons', value: 'Blue-Moons', active: false},
          {label: 'Newcomers', value: 'Newcomers', active: false},
          {label: 'At-Risk', value: 'At-Risk', active: false},
          {label: 'Window-Shoppers', value: 'Window-Shoppers', active: false},
        ]}
        onChange={value => {
          setRfmSegment(value[0]);
          setPage(1);
          setQueryValue(null);
          togglePopoverActive();
        }}
      />
    </Popover>
  );
};
export default SelectRFMComponent;
