import React, {useCallback, useEffect, useState} from 'react';
import Page from '../../components/page';
import {Badge, Icon, Layout, Tabs, Text} from '@shopify/polaris';
import {CircleTickMinor} from '@shopify/polaris-icons';
import {createCharge} from '../../api/pricing';
import {useNavigate} from 'react-router-dom';
import {LocalStorageService} from './../../service/localStorage';
import {Link} from 'react-router-dom';
import Button from '../../lib/Button';
import Box from '../../lib/Box';
import Card from '../../lib/Card';
// import {redirect} from 'react-router-dom';

const Pricing = () => {
  const [selected, setSelected] = useState(0);
  const [currentPlan, setCurrentPlan] = useState('');
  const [planText, setPlanText] = useState('');
  const navigate = useNavigate();
  const handleTabChange = useCallback(selectedTabIndex => setSelected(selectedTabIndex), []);
  const tabs = [
    {
      id: 'monthly',
      content: 'Monthly',
    },
    // {
    //   id: 'annually',
    //   content: 'Annual',
    // },
  ];
  const userOrgDetails = LocalStorageService.getUserOrg();

  useEffect(() => {
    if (userOrgDetails.planName === 'Free' && userOrgDetails.trialPeriodStatus === 'active') {
      setCurrentPlan('Business');
    } else {
      setCurrentPlan(userOrgDetails.planName);
    }
  });

  useEffect(() => {
    if (currentPlan === 'Business' && userOrgDetails.trialPeriodStatus === 'active') {
      setPlanText(
        `You are currently on a free trial of our ${currentPlan} plan. To continue accessing these advanced features, consider upgrading to a paid plan before the trial period ends.`
      );
    } else if (currentPlan === 'Business') {
      setPlanText(
        `You are currently on ${currentPlan} plan. Enjoy the seamless experience and take advantage of exclusive functionalities.`
      );
    } else {
      setPlanText(
        `You are currently on ${currentPlan} plan. To unlock all the features please upgrade your plan.`
      );
    }
  }, [currentPlan]);

  const plans = [
    {
      name: 'Free',
      desc: 'Basic',
      annualPrice: '$0/year',
      monthlyPrice: '$0/month',
      benefits: [
        'Detailed metrics and analytics',
        'Product profile',
        'Customer profiles',
        'Customer segments',
        'Chat and email support',
        'Custom integrations',
      ],
    },
    {
      name: 'Business',
      desc: 'Ideal for early stage companies',
      annualPrice: '$490/year',
      monthlyPrice: '$49/month',
      benefits: [
        'Detailed metrics and analytics',
        'Product profile',
        'Customer profiles',
        'LTV cohorts',
        'Chat and email support',
        'Customer segments',
        'Custom segments',
        'Custom integrations',
      ],
    },
    // {
    //   name: 'Business',
    //   desc: 'Ideal for growth stage companies',
    //   annualPrice: '$990/year',
    //   monthlyPrice: '$99/month',
    //   benefits: [
    //     'Everything in Basic plan',
    //     'Product profile',
    //     'Customer profiles',
    //     'Unlimited active signals',
    //     'Chat and email support',
    //   ],
    //   badge: 'Most Popular',
    // },
    // {
    //   name: 'Plus',
    //   desc: 'Ideal for mature businesses',
    //   annualPrice: '$1990/year',
    //   monthlyPrice: '$199/month',
    //   benefits: [
    //     'Everything in Business plan',
    //     'Customer segments',
    //     'Export segments',
    //     'Custom integrations',
    //     'Priority chat and email support',
    //   ],
    // },
  ];
  let planNames = {
    '00': 'free',
    '01': 'basicMonthly',
    // 11: 'basicAnnual',
    // 20: 'businessMonthly',
    // 21: 'businessAnnual',
    // 30: 'plusMonthly',
    // 31: 'plusAnnual',
  };
  const handleClick = (tabIndex, planIndex) => {
    createCharge({planName: planNames[`${tabIndex}${planIndex}`]}).then(res => {
      // window.open(res.data);
      window.location.href = res.data;
    });
  };

  const handleClickFree = () => {
    navigate('/');
  };

  return (
    <Page title={'Pricing plans'}>
      <Card>
        <Card.Section>
          <Text>
            {planText}
            <Link to="/dashboard">Go back</Link>
          </Text>
        </Card.Section>
      </Card>
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
        <Box paddingBlockStart={'4'}>
          <Layout>
            {plans.map((plan, i) => (
              <Layout.Section key={i} oneThird>
                <Card title={plan.name}>
                  <div style={{height: '90vh'}}>
                    <Card.Section>
                      <Text>{plan.desc}</Text>
                    </Card.Section>
                    <Card.Section>
                      <div style={{height: '15vh'}}>
                        {plan.name === currentPlan && <Badge status="info">Current Plan</Badge>}
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                          <div>
                            <Text variant="heading3xl" as="h1">
                              {tabs[selected].id === 'monthly'
                                ? plan.monthlyPrice
                                : plan.annualPrice}
                            </Text>
                            <Text>
                              {plan.name !== 'Free'
                                ? tabs[selected].id === 'monthly'
                                  ? 'Billed monthly'
                                  : 'Billed annually'
                                : 'No billing required'}
                            </Text>
                          </div>
                          <div>
                            {plan.name !== 'Free' && plan.name !== currentPlan && (
                              <Button primary onClick={() => handleClick(selected, i)}>
                                <Text variant="headingMd" as="h4">
                                  Get started
                                </Text>
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </Card.Section>
                    <Card.Section>
                      {plan.benefits.map((benefit, i) => (
                        <div
                          key={i}
                          style={{
                            display: 'flex',
                            direction: 'row',
                            marginBottom: '1rem',
                          }}
                        >
                          <Icon source={CircleTickMinor} color="primary" />
                          {'\u00A0' + benefit}
                        </div>
                      ))}
                    </Card.Section>
                  </div>
                </Card>
              </Layout.Section>
            ))}
          </Layout>
        </Box>
      </Tabs>
    </Page>
  );
};

export {Pricing};
