import React from 'react';
import {Text, Page, TextContainer} from '@shopify/polaris';
import {useNavigate} from 'react-router-dom';
import Button from '../../../../lib/Button';
import Box from '../../../../lib/Box';
import Inline from '../../../../lib/Inline';

const ResetPasswordMessage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Inline align="center">
        <Box width="50%" paddingBlockStart={'8'} paddingBlockEnd={'4'}>
          <TextContainer spacing="loose">
            <Text variant="heading3xl" as="h2" alignment="center" fontWeight="semibold">
              Password changed!
            </Text>
            <Text alignment="center" variant="headingXl" fontWeight="regular" as="h2">
              Your password has been changed successfully
            </Text>
            <Button
              onClick={() => {
                navigate('/');
              }}
              primary
              fullWidth
            >
              Continue to Login
            </Button>
          </TextContainer>
        </Box>
      </Inline>
    </>
  );
};
export default ResetPasswordMessage;
