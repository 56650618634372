import React, {useEffect, useContext} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {AuthAPI} from '../../../api/auth';
import {AuthContext} from '../../../context/auth';
import {Frame, Loading} from '@shopify/polaris';
import {clearAll} from '../../../features/clearData';

const AutoLogin = () => {
  const params = useParams();
  const navigate = useNavigate();
  const accessToken = params.accessToken;
  const {signin} = useContext(AuthContext);

  useEffect(() => {
    clearAll();
    AuthAPI.userInfo({params: {accessToken}}).then(response => {
      signin(response.data);
      navigate('/');
    });
  }, []);

  return (
    <Frame>
      <Loading />
    </Frame>
  );
};

export {AutoLogin};
