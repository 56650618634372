import {Popover} from '@shopify/polaris';
import React, {useCallback, useEffect, useState} from 'react';
import {OptionList} from '@shopify/polaris';
import Button from '../lib/Button';

const DefaultSelect = ({value, setValue, list, setPage, setQueryValue = () => {}}) => {
  const [popoverActive, setPopoverActive] = useState(false);
  const togglePopoverActive = useCallback(
    () => setPopoverActive(popoverActive => !popoverActive),
    []
  );
  const [options, setOptions] = useState([]);
  useEffect(() => {
    let opts = [{value: 'All Sources', label: 'All Sources'}];

    list.forEach(a => {
      opts.push({value: a, label: a});
    });

    setOptions([...opts]);
  }, [list]);

  const activator = (
    <Button onClick={togglePopoverActive} disclosure>
      {value}
    </Button>
  );
  return (
    <Popover active={popoverActive} activator={activator} onClose={togglePopoverActive}>
      <OptionList
        selected={value}
        options={options}
        onChange={val => {
          setValue(val[0]);
          setPage(1);
          setQueryValue('');
          togglePopoverActive();
        }}
      />
    </Popover>
  );
};

export default DefaultSelect;
