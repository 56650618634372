import React, {useEffect, useState} from 'react';
import Inline from '../../../lib/Inline';
import Stack from '../../../lib/Stack';
import Card from '../../../lib/Card';
import Text from '../../../lib/Text';
import {Layout, RadioButton, TextField} from '@shopify/polaris';
import Button from '../../../lib/Button';
import {
  getNpsTemplate,
  sendTestEmailTemplate,
  sendTestWhatsappMessage,
} from '../../../api/reports/settings';
import {CreateConnectionModal} from '../../../components/modal';
import Box from '../../../lib/Box';

const NpsData = ({nps, setNps, twilio, setTwilio, setShowAlert, isTwilioConnected, onConnect}) => {
  const [template, setTemplate] = useState();
  const [email, setEmail] = useState('');
  const [loading, setLoaading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [whatsappTemplate, setWhatsappTemplate] = useState({body: ''});
  const [countryCode, setCountryCode] = useState('+1');
  const [mobileNumber, setMobileNumber] = useState('');

  useEffect(() => {
    getEmailTemplate();
    getWhatsappTemplate();
  }, []);
  const getEmailTemplate = async () => {
    let template = await getNpsTemplate({type: 'email'});
    if (template.data) {
      setTemplate(template.data);
    }
  };
  const getWhatsappTemplate = async () => {
    let template = await getNpsTemplate({type: 'whatsapp'});
    if (template.data) {
      setWhatsappTemplate(template.data);
    }
  };
  const sendTestEmail = async email => {
    setShowAlert('');
    setLoaading(true);
    await sendTestEmailTemplate({email, template: template.body, subject: template.subject});
    setLoaading(false);
    setShowAlert('Test email sent successfully');
  };
  const sendTestMessage = async number => {
    setShowAlert('');
    setLoaading(true);
    console.log(whatsappTemplate);
    await sendTestWhatsappMessage({number, template: whatsappTemplate.body});
    setShowAlert('Test message sent successfully');
    setLoaading(false);
  };

  return (
    <Card sectioned full>
      <Stack vertical alignment="baseline">
        <Layout>
          <Layout.Section>
            <Inline align="space-between">
              <Text variant="headingMd">NPS </Text>
            </Inline>
          </Layout.Section>

          <Layout.Section>
            <Stack vertical alignment="baseline">
              <Text variant="headingSm">Do you want to collect NPS?</Text>

              <Stack>
                <RadioButton
                  label="Yes"
                  checked={nps === true}
                  id="nps_enable"
                  name="nps"
                  onChange={() => setNps(true)}
                />
                <RadioButton
                  label="Skip"
                  id="nps_skip"
                  name="nps"
                  checked={nps === false}
                  onChange={() => setNps(false)}
                />
              </Stack>
              {nps && (
                <Stack vertical alignment="baseline">
                  <Inline blockAlign="end">
                    <TextField
                      value={email}
                      onChange={setEmail}
                      label={<Text>Send test email</Text>}
                      placeholder="Email"
                    />
                    <Button disabled={loading} onClick={() => sendTestEmail(email)} primary>
                      Send
                    </Button>
                  </Inline>
                  <Text variant="bodySm" color="subdued">
                    Note: You can edit NPS template in settings
                  </Text>
                </Stack>
              )}
            </Stack>
          </Layout.Section>
          {nps && (
            <Layout.Section>
              <Stack vertical alignment="baseline">
                <Text variant="headingSm">
                  {' '}
                  Do you want to connect Twilio Whatsapp to send NPS?
                </Text>

                <Stack>
                  <RadioButton
                    label="Yes"
                    checked={twilio === true}
                    id="twilio_enable"
                    name="twilio"
                    onChange={() => setTwilio(true)}
                  />
                  <RadioButton
                    label="Skip"
                    id="twilio_skip"
                    name="twilio"
                    checked={twilio === false}
                    onChange={() => setTwilio(false)}
                  />
                </Stack>
                {twilio && !isTwilioConnected && (
                  <Inline>
                    <Text color="warning" variant="headingSm">
                      Connection to twilio required !
                    </Text>
                    <Button onClick={() => setOpenModal(true)} primary plain>
                      Connect
                    </Button>
                  </Inline>
                )}
                {twilio && isTwilioConnected && (
                  <Stack vertical alignment="baseline">
                    <Inline blockAlign="end">
                      <TextField
                        connectedLeft={
                          <Box maxWidth="5rem">
                            <TextField
                              maxLength={4}
                              onChange={setCountryCode}
                              value={countryCode}
                            />
                          </Box>
                        }
                        value={mobileNumber}
                        onChange={setMobileNumber}
                        label="Whatsapp number"
                      />
                      <Button
                        disabled={loading}
                        onClick={() => {
                          sendTestMessage(countryCode.toString() + mobileNumber.toString());
                        }}
                        primary
                      >
                        Send
                      </Button>
                    </Inline>
                    <Text variant="bodySm" color="subdued">
                      Note: You can edit NPS whatsapp template in settings
                    </Text>
                  </Stack>
                )}
              </Stack>
            </Layout.Section>
          )}
        </Layout>
      </Stack>
      {openModal && (
        <CreateConnectionModal
          currentState={openModal}
          onClose={setOpenModal}
          onConnect={onConnect}
          type={'twilio'}
        />
      )}
    </Card>
  );
};

export default NpsData;
