import React, {useMemo} from 'react';
import {Text, Tag, Badge, SkeletonDisplayText} from '@shopify/polaris';
import Inline from '../../../../lib/Inline';
import EconomicIndicator from '../components/economicIndicator';
// import DynamicLineGraph from '../../../../components/graphs/dynamicLineGraph';
import DefaultLineGraph from '../../../../components/graphs/defaultLineGraph';
import DefaultBarGraph from '../../../../components/graphs/defaultBarGraph';
import Box from '../../../../lib/Box';
import MostCard from '../components/mostCard';
import Card from '../../../../lib/Card';
import {useEffect} from 'react';
import {
  channelsOfCustomer,
  customerEconomics,
  customerProfileData,
  // firstAndLastOrder,
  // grossMargin as customerGrossMargin,
  ltvGraph,
  mostAbandonedProducts,
  mostPurchasedCategoryByCustomer,
  mostPurchasedProducts,
  mostUsedCouponsByCustomer,
  ordersGraph,
  productNpsGraph,
} from '../../../../api/customerProfile';
import {useParams} from 'react-router-dom';
import {useState} from 'react';
import {
  formatAmount,
  formatNumber,
  formatDecimalNumber,
  formatDate,
  formatLinearXAxisLabel,
} from '../../../../features/format';
import {ErrorBoundary} from '../../../../components/ErrorBoundary';
import {LocalStorageService} from '../../../../service/localStorage';
import useApiCall from '../../../../hooks/useApiCall';

const arrayToMap = arr => {
  const map = new Map();
  arr.forEach(item => {
    map.set(item.key, item.value);
  });
  return map;
};

const formatMonthYear = string => {
  let obj = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'July',
    8: 'Aug',
    9: 'Sept',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
  };
  if (!string) return '';
  if (string.split('-').length !== 2) return '';
  let [year, month] = string.split('-');

  return obj[+month] + ' ' + year;
};

const generateMonthlyData = (signup, data) => {
  const signupDate = new Date(signup);
  const dataMap = arrayToMap(data);
  const currentDate = new Date();
  const months = [];
  let currentDateIter = new Date(signupDate);

  while (currentDateIter <= currentDate) {
    const monthYear = currentDateIter.toISOString().slice(0, 7); // Format: YYYY-MM
    months.push(monthYear);
    currentDateIter.setMonth(currentDateIter.getMonth() + 1);
  }
  const result = [];
  for (const month of months) {
    if (dataMap.has(month)) {
      result.push({key: month, value: dataMap.get(month)});
    } else {
      result.push({key: month, value: 0});
    }
  }
  return result;
};

const Overview = () => {
  const [npsEnabled, setNpsEnabled] = useState(false);
  const params = useParams();
  useEffect(() => {
    const userDetails = LocalStorageService.getUserOrg();
    if (userDetails?.reportSettings?.nps?.product === true) {
      setNpsEnabled(true);
    }
  }, []);

  const fetchProfileData = async () => {
    let response = await customerProfileData({customerId: params.customerId});
    return response;
  };
  const fetchMostCouponsByCustomer = async () => {
    let response = await mostUsedCouponsByCustomer({customerId: params.customerId});
    return response;
  };
  const fetchMostPurchasedCategories = async () => {
    let response = await mostPurchasedCategoryByCustomer({customerId: params.customerId});
    return response;
  };

  const fetchOrdersGraphData = async () => {
    let response = await ordersGraph({customerId: params.customerId});
    return response;
  };

  const fetchProductNpsGraphData = async () => {
    if (npsEnabled === true) {
      let response = await productNpsGraph({customerId: params.customerId});
      return response;
    }
  };

  const fetchEconomicsData = async () => {
    let response = await customerEconomics({customerId: params.customerId});
    return response;
  };

  const fetchChannels = async () => {
    let response = await channelsOfCustomer({customerId: params.customerId});
    return response;
  };
  const fetchLtvGraphData = async () => {
    let response = await ltvGraph({customerId: params.customerId});
    return response;
  };
  const fetchMostPurchasedProducts = async () => {
    let response = await mostPurchasedProducts({customerId: params.customerId});
    return response;
  };
  const fetchMostAbandonedProducts = async () => {
    let response = await mostAbandonedProducts({customerId: params.customerId});
    return response;
  };

  // calling API's
  const _profileData = useApiCall(fetchProfileData, []);
  const profileData = useMemo(() => {
    return {data: (_profileData.data = _profileData.data[0] || {}), state: _profileData.state};
  }, [_profileData]);
  const coupons = useApiCall(fetchMostCouponsByCustomer, []);
  const categories = useApiCall(fetchMostPurchasedCategories, []);
  const _ordersGraphData = useApiCall(fetchOrdersGraphData, []);
  const productNpsGraphData = useApiCall(fetchProductNpsGraphData, [npsEnabled]);

  const _economics = useApiCall(fetchEconomicsData, []);
  const economics = useMemo(() => {
    return {data: (_economics.data = _economics.data[0] || {}), state: _economics.state};
  }, [_economics]);
  const channels = useApiCall(fetchChannels, []);
  const _ltvGraphData = useApiCall(fetchLtvGraphData, []);

  const ltvGraphData = useMemo(() => {
    if (_ltvGraphData.state == 'Success' && profileData.state === 'Success') {
      return {
        state: 'Success',
        data: generateMonthlyData(
          profileData?.signup || _ltvGraphData?.data[0]?.key,
          _ltvGraphData.data
        ),
      };
    }
    if (_ltvGraphData.state == 'Loading' && profileData.state === 'Loading') {
      return {state: 'Loading', data: []};
    }
    return _ltvGraphData;
  }, [_ltvGraphData, profileData]);
  const ordersGraphData = useMemo(() => {
    if (_ordersGraphData.state == 'Success' && profileData.state === 'Success') {
      return {
        state: 'Success',
        data: generateMonthlyData(
          profileData?.signup || _ordersGraphData?.data[0]?.key,
          _ordersGraphData.data
        ),
      };
    }
    if (_ordersGraphData.state == 'Loading' && profileData.state === 'Loading') {
      return {state: 'Loading', data: []};
    }
    return _ordersGraphData;
  }, [_ordersGraphData, profileData]);
  const purchasedProducts = useApiCall(fetchMostPurchasedProducts, []);
  const abandonedProducts = useApiCall(fetchMostAbandonedProducts, []);

  return (
    <>
      <Box paddingBlockEnd={'5'}>
        <Inline blockAlign="start">
          <Box width="49%">
            <Card>
              <Card.Section>
                <Text variant="headingMd">Customer Profile</Text>
              </Card.Section>
              <Card.Section>
                <Inline align="space-between">
                  <Text>Customer</Text>
                  <ErrorBoundary
                    fallback={
                      <Card>
                        <Card.Section>
                          <div>An error has occured</div>
                        </Card.Section>
                      </Card>
                    }
                  >
                    <Box width="60%">
                      {profileData.state === 'Loading' ? (
                        <SkeletonDisplayText />
                      ) : profileData.state === 'Error' ? (
                        <Text>Not able to load data. please try again later</Text>
                      ) : (
                        <Text>
                          {profileData?.data.firstName || profileData?.data.lastName
                            ? `${profileData?.data.firstName || ''} ${
                                profileData?.data.lastName || ''
                              }`
                            : 'No name'}
                        </Text>
                      )}
                    </Box>
                  </ErrorBoundary>
                </Inline>
              </Card.Section>
              <Card.Section>
                <Inline align="space-between">
                  <Text>Email</Text>
                  <ErrorBoundary
                    fallback={
                      <Card>
                        <Card.Section>
                          <div>An error has occured</div>
                        </Card.Section>
                      </Card>
                    }
                  >
                    <Box width="60%">
                      {profileData.state === 'Loading' ? (
                        <SkeletonDisplayText />
                      ) : profileData.state === 'Error' ? (
                        <Text>Not able to load data. please try again later</Text>
                      ) : (
                        <Text>{profileData?.data.email ? profileData?.data.email : '-'}</Text>
                      )}
                    </Box>
                  </ErrorBoundary>
                </Inline>
              </Card.Section>
              <Card.Section>
                <Inline align="space-between">
                  <Text>Mobile</Text>
                  <ErrorBoundary
                    fallback={
                      <Card>
                        <Card.Section>
                          <div>An error has occured</div>
                        </Card.Section>
                      </Card>
                    }
                  >
                    <Box width="60%">
                      {profileData.state === 'Loading' ? (
                        <SkeletonDisplayText />
                      ) : profileData.state === 'Error' ? (
                        <Text>Not able to load data. please try again later</Text>
                      ) : (
                        <Text>{profileData?.data.phone ? profileData?.data.phone : '-'}</Text>
                      )}
                    </Box>
                  </ErrorBoundary>
                </Inline>
              </Card.Section>
              <Card.Section>
                <Inline align="space-between">
                  <Text>Default Address </Text>
                  <ErrorBoundary
                    fallback={
                      <Card>
                        <Card.Section>
                          <div>An error has occured</div>
                        </Card.Section>
                      </Card>
                    }
                  >
                    <Box width="60%">
                      {profileData.state === 'Loading' ? (
                        <SkeletonDisplayText />
                      ) : profileData.state === 'Error' ? (
                        <Text>Not able to load data. please try again later</Text>
                      ) : (
                        <Text>
                          {profileData.data.address1 ? profileData.data.address1 + ' , ' : ''}
                          {profileData.data.city ? profileData.data.city + ' , ' : ''}
                          {profileData.data.province ? profileData.data.province + '-' : ''}
                          {profileData.data.zip}
                        </Text>
                      )}
                    </Box>
                  </ErrorBoundary>
                </Inline>
              </Card.Section>
              <Card.Section>
                <Inline align="space-between">
                  <Text>RFM Status</Text>
                  <ErrorBoundary
                    fallback={
                      <Card>
                        <Card.Section>
                          <div>An error has occured</div>
                        </Card.Section>
                      </Card>
                    }
                  >
                    <Box width="60%">
                      {profileData.state === 'Loading' ? (
                        <SkeletonDisplayText />
                      ) : profileData.state === 'Error' ? (
                        <Text>Not able to load data. please try again later</Text>
                      ) : (
                        <Badge
                          status={
                            profileData?.data._rfmCategory === 'No-Show' ||
                            profileData?.data._rfmCategory === 'At-Risk'
                              ? 'critical'
                              : profileData?.data._rfmCategory === 'In-Betweeners' ||
                                profileData?.data._rfmCategory === 'Blue-Moons'
                              ? 'warning'
                              : 'success'
                          }
                        >
                          {' '}
                          {profileData?.data._rfmCategory ? profileData?.data._rfmCategory : '-'}
                        </Badge>
                      )}
                    </Box>
                  </ErrorBoundary>
                </Inline>
              </Card.Section>
              <Card.Section>
                <Inline align="space-between">
                  <Text>RFM Score</Text>
                  <ErrorBoundary
                    fallback={
                      <Card>
                        <Card.Section>
                          <div>An error has occured</div>
                        </Card.Section>
                      </Card>
                    }
                  >
                    <Box width="60%">
                      {profileData.state === 'Loading' ? (
                        <SkeletonDisplayText />
                      ) : profileData.state === 'Error' ? (
                        <Text>Not able to load data. please try again later</Text>
                      ) : (
                        <Text>
                          {profileData?.data._rfmScore ? profileData?.data._rfmScore : '-'}
                        </Text>
                      )}
                    </Box>
                  </ErrorBoundary>
                </Inline>
              </Card.Section>
              <Card.Section>
                <Inline align="space-between">
                  <Text>Accepts Marketing</Text>
                  <ErrorBoundary
                    fallback={
                      <Card>
                        <Card.Section>
                          <div>An error has occured</div>
                        </Card.Section>
                      </Card>
                    }
                  >
                    <Box width="60%">
                      {profileData.state === 'Loading' ? (
                        <SkeletonDisplayText />
                      ) : profileData.state === 'Error' ? (
                        <Text>Not able to load data. please try again later</Text>
                      ) : (
                        <Text>{profileData?.data.acceptsMarketing ? 'Yes' : 'No'}</Text>
                      )}
                    </Box>
                  </ErrorBoundary>
                </Inline>
              </Card.Section>
              {/* <Card.Section>
                <Inline align="space-between">
                  <Text>Location IDs</Text>
                  <Box width="60%">
                    <Text>-</Text>
                  </Box>
                </Inline>
              </Card.Section> */}
              <Card.Section>
                <Box>
                  <Inline align="space-between">
                    <Text>Most Used Coupons </Text>
                  </Inline>
                </Box>
                <ErrorBoundary
                  fallback={
                    <Card>
                      <Card.Section>
                        <div>An error has occured</div>
                      </Card.Section>
                    </Card>
                  }
                >
                  <Box paddingBlockStart={'3'}>
                    {coupons.state === 'Loading' ? (
                      <SkeletonDisplayText />
                    ) : coupons.state === 'Error' ? (
                      <Text>Not able to load data. please try again later</Text>
                    ) : (
                      <Inline>
                        {coupons.data.length === 0
                          ? '-'
                          : coupons.data.map(c => {
                              return <Tag>{c.code}</Tag>;
                            })}
                      </Inline>
                    )}
                  </Box>
                </ErrorBoundary>
              </Card.Section>
              <Card.Section>
                <Box>
                  <Inline align="space-between">
                    <Text>Highest Purchased Categories</Text>
                  </Inline>
                </Box>
                <ErrorBoundary
                  fallback={
                    <Card>
                      <Card.Section>
                        <div>An error has occured</div>
                      </Card.Section>
                    </Card>
                  }
                >
                  <Box paddingBlockStart={'3'}>
                    {categories.state === 'Loading' ? (
                      <SkeletonDisplayText />
                    ) : categories.state === 'Error' ? (
                      <Text>Not able to load data. please try again later</Text>
                    ) : (
                      <Inline>
                        {categories.data.length === 0
                          ? '-'
                          : categories.data.map(c => {
                              return <Tag>{c.category}</Tag>;
                            })}
                      </Inline>
                    )}
                  </Box>
                </ErrorBoundary>
              </Card.Section>
              {/* <Card.Section>
                <Box>
                  <Inline align="space-between">
                    <Text>Segments </Text>
                  </Inline>
                </Box>
                <Box paddingBlockStart={'3'}>
                  <Inline>
                    <Tag>Basic Tag</Tag>
                    <Tag>Basic Tag</Tag>
                    <Tag>Basic Tag</Tag>
                    <Tag>Basic Tag</Tag>
                    <Tag>Basic Tag</Tag>
                    <Tag>Basic Tag</Tag>
                    <Tag>Basic Tag</Tag>
                    <Tag>Basic Tag</Tag>
                    <Tag>Basic Tag</Tag>
                  </Inline>
                </Box>
              </Card.Section> */}
              <Card.Section>
                <Box>
                  <Inline align="space-between">
                    <Text>Tags </Text>
                  </Inline>
                </Box>
                <ErrorBoundary
                  fallback={
                    <Card>
                      <Card.Section>
                        <div>An error has occured</div>
                      </Card.Section>
                    </Card>
                  }
                >
                  <Box paddingBlockStart={'3'}>
                    {profileData.state === 'Loading' ? (
                      <SkeletonDisplayText />
                    ) : profileData.state === 'Error' ? (
                      <Text>Not able to load data. please try again later</Text>
                    ) : (
                      <Inline>
                        {profileData?.data.tags?.length === 0 ? (
                          <>-</>
                        ) : (
                          profileData?.data.tags?.split(',').map(tag => {
                            return <Tag>{tag}</Tag>;
                          })
                        )}
                      </Inline>
                    )}
                  </Box>
                </ErrorBoundary>
              </Card.Section>
              <Card.Section>
                <Box>
                  <Inline align="space-between">
                    <Text>Channels </Text>
                  </Inline>
                </Box>
                <ErrorBoundary
                  fallback={
                    <Card>
                      <Card.Section>
                        <div>An error has occured</div>
                      </Card.Section>
                    </Card>
                  }
                >
                  <Box paddingBlockStart={'3'}>
                    {channels.state === 'Loading' ? (
                      <SkeletonDisplayText />
                    ) : channels.state === 'Error' ? (
                      <Text>Not able to load data. please try again later</Text>
                    ) : (
                      <Inline>
                        {channels?.data.length === 0 ? (
                          <>-</>
                        ) : (
                          channels?.data.map(channel => {
                            return <Tag>{channel.sourceName}</Tag>;
                          })
                        )}
                      </Inline>
                    )}
                  </Box>
                </ErrorBoundary>
              </Card.Section>
            </Card>
          </Box>
          <Box width="49%">
            <Card>
              <Card.Section>
                <Text variant="headingMd">Customer Metrics</Text>
              </Card.Section>
              <Card.Section>
                <ErrorBoundary
                  fallback={
                    <Card>
                      <Card.Section>
                        <div>An error has occured</div>
                      </Card.Section>
                    </Card>
                  }
                >
                  <EconomicIndicator
                    values={[
                      {
                        label: 'Lifetime value',
                        value: `${
                          economics.data.netRevenue
                            ? formatAmount({value: economics.data.netRevenue})
                            : '-'
                        }`,
                        loading: economics.state === 'Loading',
                        error: economics.state === 'Error',
                      },
                      {
                        label: 'Net orders',
                        value: `${
                          economics.data.netOrders
                            ? formatNumber({value: economics.data.netOrders})
                            : '-'
                        }`,
                        loading: economics.state === 'Loading',
                        error: economics.state === 'Error',
                      },
                      {
                        label: 'Average order value',
                        value: `${
                          economics.data.avgOrderPrice
                            ? formatAmount({
                                value: economics.data.avgOrderPrice
                                  ? economics.data.avgOrderPrice
                                  : '0',
                              })
                            : '-'
                        }`,
                        loading: economics.state === 'Loading',
                        error: economics.state === 'Error',
                      },
                    ]}
                  />
                </ErrorBoundary>
              </Card.Section>
              <Card.Section>
                <ErrorBoundary
                  fallback={
                    <Card>
                      <Card.Section>
                        <div>An error has occured</div>
                      </Card.Section>
                    </Card>
                  }
                >
                  <EconomicIndicator
                    values={[
                      {
                        label: 'Items purchased',
                        value: `${
                          economics.data.totalItems !== undefined
                            ? formatNumber({value: economics.data.totalItems})
                            : '-'
                        }`,
                        loading: economics.state === 'Loading',
                        error: economics.state === 'Error',
                      },
                      {
                        label: 'Lifetime',
                        value: `${profileData ? profileData?.data.lifetime : '-'}`,
                        loading: profileData.state === 'Loading',
                        error: profileData.state === 'Error',
                      },
                      {
                        label: 'Return rate',
                        value:
                          economics.data.returnRate !== undefined
                            ? formatDecimalNumber({value: economics.data.returnRate}) + '%'
                            : '-',
                        loading: economics.state === 'Loading',
                        error: economics.state === 'Error',
                      },
                    ]}
                  />
                </ErrorBoundary>
              </Card.Section>
              <Card.Section>
                <ErrorBoundary
                  fallback={
                    <Card>
                      <Card.Section>
                        <div>An error has occured</div>
                      </Card.Section>
                    </Card>
                  }
                >
                  <EconomicIndicator
                    values={[
                      {
                        label: 'Gross margin',
                        value: `${
                          economics.data.grossMargin !== undefined
                            ? formatAmount({
                                value: economics.data.grossMargin
                                  ? economics.data.grossMargin
                                  : '0',
                              })
                            : '-'
                        }`,
                        loading: economics.state === 'Loading',
                        error: economics.state === 'Error',
                      },
                      {
                        label: 'Gross revenue',
                        value: `${
                          economics.data.grossRevenue !== undefined
                            ? formatAmount({
                                value: economics.data.grossRevenue,
                              })
                            : '-'
                        }`,
                        loading: economics.state === 'Loading',
                        error: economics.state === 'Error',
                      },
                      {
                        label: 'Net revenue',
                        value: `${
                          economics.data.netRevenue !== undefined
                            ? formatAmount({
                                value: economics.data.netRevenue,
                              })
                            : '-'
                        }`,
                        loading: economics.state === 'Loading',
                        error: economics.state === 'Error',
                      },
                    ]}
                  />
                </ErrorBoundary>
              </Card.Section>
              <Card.Section>
                <ErrorBoundary
                  fallback={
                    <Card>
                      <Card.Section>
                        <div>An error has occured</div>
                      </Card.Section>
                    </Card>
                  }
                >
                  <EconomicIndicator
                    values={[
                      {
                        label: 'Average days b/w orders',
                        value: `${
                          economics.data.averageDaysBetweenOrders !== undefined
                            ? formatDecimalNumber({value: economics.data.averageDaysBetweenOrders})
                            : '-'
                        }`,
                        loading: economics.state === 'Loading',
                        error: economics.state === 'Error',
                      },
                      {
                        label: 'Highest order value',
                        value: `${
                          economics.data.highestOrderValue !== undefined
                            ? formatAmount({
                                value: economics.data.highestOrderValue,
                              })
                            : '-'
                        }`,
                        loading: economics.state === 'Loading',
                        error: economics.state === 'Error',
                      },
                      {label: 'Churn Risk', value: '-'},
                    ]}
                  />
                </ErrorBoundary>
              </Card.Section>
              <Card.Section>
                <ErrorBoundary
                  fallback={
                    <Card>
                      <Card.Section>
                        <div>An error has occured</div>
                      </Card.Section>
                    </Card>
                  }
                >
                  <EconomicIndicator
                    values={[
                      {
                        label: 'Date of first purchase',
                        value: ` ${
                          economics.data._firstOrderAt
                            ? formatDate({
                                value: new Date(economics.data._firstOrderAt),
                                removeTime: true,
                              })
                            : '-'
                        }`,
                        loading: economics.state === 'Loading',
                        error: economics.state === 'Error',
                      },
                      {
                        label: 'Date of last purchase',
                        value: ` ${
                          economics.data._lastOrderAt
                            ? formatDate({
                                value: new Date(economics.data._lastOrderAt),
                                removeTime: true,
                              })
                            : '-'
                        }`,
                        loading: economics.state === 'Loading',
                        error: economics.state === 'Error',
                      },
                      {
                        label: 'Lifetime discount',
                        value: ` ${
                          economics.data.totalDiscount !== undefined
                            ? formatAmount({
                                value: economics.data.totalDiscount,
                              })
                            : '-'
                        }`,
                        loading: economics.state === 'Loading',
                        error: economics.state === 'Error',
                      },
                    ]}
                  />
                </ErrorBoundary>
              </Card.Section>
            </Card>
            <Card>
              <Card.Section>
                <Box paddingInlineStart={'4'}>
                  <Text variant="headingMd">Revenue</Text>
                </Box>
                <Box paddingBlockStart={'4'}>
                  <ErrorBoundary
                    fallback={
                      <Card>
                        <Card.Section>
                          <div>An error has occured</div>
                        </Card.Section>
                      </Card>
                    }
                  >
                    <DefaultBarGraph
                      height={250}
                      data={ltvGraphData.data}
                      state={ltvGraphData.state}
                      xAxisValue="key"
                      yAxisValue="value"
                      name={'Revenue'}
                      yAxisOptions={{
                        labelFormatter: function formatLinearYAxisLabel(value) {
                          return formatAmount({value, notation: 'compact'});
                        },
                      }}
                      xAxisOptions={{
                        labelFormatter: value => formatMonthYear(value),
                      }}
                      tooltipOptions={{
                        titleFormatter: value => formatMonthYear(value),
                        keyFormatter: value => {
                          return `${value}`;
                        },
                        valueFormatter: value => {
                          return formatAmount({value, notation: 'compact'});
                        },
                      }}
                    />
                  </ErrorBoundary>
                </Box>
              </Card.Section>
            </Card>
          </Box>
        </Inline>
      </Box>
      <Box paddingBlockEnd={'5'}>
        <Inline blockAlign="start">
          <Box width="49%">
            <Card>
              <Card.Section>
                <Box paddingInlineStart={'4'}>
                  <Text variant="headingMd">Orders</Text>
                </Box>
                <Box paddingBlockStart={'4'}>
                  <ErrorBoundary
                    fallback={
                      <Card>
                        <Card.Section>
                          <div>An error has occured</div>
                        </Card.Section>
                      </Card>
                    }
                  >
                    <DefaultBarGraph
                      height={250}
                      data={ordersGraphData.data}
                      state={ordersGraphData.state}
                      isDynamic={true}
                      xAxisValue="key"
                      yAxisValue="value"
                      name={'Orders'}
                      yAxisOptions={{
                        labelFormatter: function formatLinearYAxisLabel(value) {
                          return formatNumber({value, notation: 'compact'});
                        },
                      }}
                      xAxisOptions={{
                        labelFormatter: value => formatMonthYear(value),
                      }}
                      tooltipOptions={{
                        titleFormatter: value => formatMonthYear(value),
                        keyFormatter: value => {
                          return `${value}`;
                        },
                        valueFormatter: value => {
                          return formatNumber({value, notation: 'compact'});
                        },
                      }}
                    />
                  </ErrorBoundary>
                </Box>
              </Card.Section>
            </Card>
          </Box>
          {!!npsEnabled && (
            <Box width="49%">
              <Card>
                <Card.Section>
                  <Box paddingInlineStart={'4'}>
                    <Text variant="headingMd">Product NPS</Text>
                  </Box>
                  <Box paddingBlockStart={'4'}>
                    <ErrorBoundary
                      fallback={
                        <Card>
                          <Card.Section>
                            <div>An error has occured</div>
                          </Card.Section>
                        </Card>
                      }
                    >
                      <DefaultLineGraph
                        param={{name: 'product-nps'}}
                        height={250}
                        data={productNpsGraphData.data.length ? [productNpsGraphData.data, []] : []}
                        startDate={
                          productNpsGraphData.data.length
                            ? new Date(productNpsGraphData.data[0].key)
                            : null
                        }
                        endDate={productNpsGraphData.data.length ? new Date() : null}
                        compareStartDate={null}
                        compareEndDate={null}
                        durationFilter={'monthly'}
                        state={productNpsGraphData.state}
                        isDynamic={true}
                        tooltipOptions={{
                          titleFormatter: value => formatMonthYear(value),
                          keyFormatter: value => {
                            return `${value}`;
                          },
                          valueFormatter: value => {
                            return formatNumber({value, notation: 'compact'});
                          },
                        }}
                        xAxisOptions={{
                          labelFormatter: value => formatMonthYear(value),
                        }}
                      />
                    </ErrorBoundary>
                  </Box>
                </Card.Section>
              </Card>
            </Box>
          )}
        </Inline>
      </Box>
      <Box paddingBlockEnd={'5'}>
        <Inline blockAlign="start">
          <Box width="49%">
            <ErrorBoundary
              fallback={
                <Card>
                  <Card.Section>
                    <div>An error has occured</div>
                  </Card.Section>
                </Card>
              }
            >
              <MostCard
                data={purchasedProducts.data}
                state={purchasedProducts.state}
                title={'Most Purchased Products '}
              />
            </ErrorBoundary>
          </Box>
          <Box width="49%">
            <ErrorBoundary
              fallback={
                <Card>
                  <Card.Section>
                    <div>An error has occured</div>
                  </Card.Section>
                </Card>
              }
            >
              <MostCard
                data={abandonedProducts.data}
                state={abandonedProducts.state}
                title={'Most Abandoned Products '}
              />
            </ErrorBoundary>
          </Box>
        </Inline>
      </Box>
    </>
  );
};

export default Overview;
