import axios from './axios';
import config from '../config';

const {BASE_URL} = config;

export async function customers(params) {
  const response = await axios.get(`${BASE_URL}/reports/customers`, params);
  return response;
}
export async function customerProfileData({customerId}) {
  const response = await axios.get(`${BASE_URL}/reports/customer/profile?customerId=${customerId}`);
  return response.data;
}

export async function mostUsedCouponsByCustomer({customerId}) {
  const response = await axios.get(
    `${BASE_URL}/reports/customer/most-coupons?customerId=${customerId}`
  );
  return response.data;
}
export async function mostPurchasedCategoryByCustomer({customerId}) {
  const response = await axios.get(
    `${BASE_URL}/reports/customer/most-category?customerId=${customerId}`
  );
  return response.data;
}
export async function ordersGraph({customerId}) {
  const response = await axios.get(
    `${BASE_URL}/reports/customer/orders-graph?customerId=${customerId}`
  );
  return response.data;
}
export async function productNpsGraph({customerId}) {
  const response = await axios.get(
    `${BASE_URL}/reports/customer/product-nps-graph?customerId=${customerId}`
  );
  return response.data;
}
export async function ltvGraph({customerId}) {
  const response = await axios.get(
    `${BASE_URL}/reports/customer/ltv-graph?customerId=${customerId}`
  );
  return response.data;
}
export async function customerEconomics({customerId}) {
  const response = await axios.get(
    `${BASE_URL}/reports/customer/economics?customerId=${customerId}`
  );
  return response.data;
}

export async function mostPurchasedProducts({customerId}) {
  const response = await axios.get(
    `${BASE_URL}/reports/customer/most-purchased-products?customerId=${customerId}`
  );
  return response.data;
}
export async function mostAbandonedProducts({customerId}) {
  const response = await axios.get(
    `${BASE_URL}/reports/customer/most-abandoned-products?customerId=${customerId}`
  );
  return response.data;
}
export async function channelsOfCustomer({customerId}) {
  const response = await axios.get(
    `${BASE_URL}/reports/customer/channels?customerId=${customerId}`
  );
  return response.data;
}
export async function timelineOfCustomer({customerId}) {
  const response = await axios.get(
    `${BASE_URL}/reports/customer/timeline?customerId=${customerId}`
  );
  return response.data;
}
