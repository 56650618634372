let tenToHundred = [];
let oneToHundred = [];
for (let i = 1; i <= 100; i++) {
  if (i >= 10) {
    tenToHundred.push({label: i + '%', value: i + ''});
  }
  oneToHundred.push({label: i + '%', value: i + ''});
}

const options = {
  acceptsMarketing: {
    name: 'Accepts marketing',
    operator: [{label: 'is exactly', value: 'isExactly'}],
    timeSelector: {
      required: true,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
    value: {
      isExactly: {
        required: true,
        type: 'option',
        options: [
          {label: 'yes', value: 'true'},
          {label: 'no', value: 'false'},
        ],
      },
    },
  },
  addedToCart: {
    name: 'Added to cart',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
      {label: 'is less than or equal to', value: 'isLessThanOrEqualTo'},
      {label: 'is greater than or equal to', value: 'isGreaterThanOrEqualTo'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'number',
      },
      isLessThan: {
        required: true,
        type: 'number',
      },
      isGreaterThan: {
        required: true,
        type: 'number',
      },
      isLessThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isGreaterThanOrEqualTo: {
        required: true,
        type: 'number',
      },
    },
    timeSelector: {
      required: true,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  aov: {
    name: 'AOV',

    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
      {label: 'is less than or equal to', value: 'isLessThanOrEqualTo'},
      {label: 'is greater than or equal to', value: 'isGreaterThanOrEqualTo'},
      {label: 'is the top', value: 'isTheTop'},
      {label: 'is the bottom', value: 'isTheBottom'},
    ],
    timeSelector: {required: false},
    value: {
      atLeastOnce: {
        required: false,
      },
      zeroTimes: {
        required: false,
      },
      equals: {
        required: true,
        type: 'number',
      },
      isLessThan: {
        required: true,
        type: 'number',
      },
      isGreaterThan: {
        required: true,
        type: 'number',
      },
      isLessThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isGreaterThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isAtLeast: {
        required: true,
        type: 'number',
      },
      isAtMost: {
        required: true,
        type: 'number',
      },
      isTheTop: {
        required: true,
        type: 'option',
        options: tenToHundred,
      },
      isTheBottom: {
        required: true,
        type: 'option',
        options: tenToHundred,
      },

      // adding
      isExactly: {
        required: true,
        type: 'number',
      },
      isNotExactly: {
        required: true,
        type: 'number',
      },
      isOneOf: {
        required: true,
        type: 'multiText',
      },
      isNoneOf: {
        required: true,
        type: 'multiText',
      },
    },
  },
  averageDaysBetweenOrders: {
    name: 'Average days between orders',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
      {label: 'is less than or equal to', value: 'isLessThanOrEqualTo'},
      {label: 'is greater than or equal to', value: 'isGreaterThanOrEqualTo'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'number',
      },
      isNotExactly: {
        required: true,
        type: 'number',
      },
      isOneOf: {
        required: true,
        type: 'multiText',
      },
      isNoneOf: {
        required: true,
        type: 'multiText',
      },
      isLessThan: {
        required: true,
        type: 'number',
      },
      isGreaterThan: {
        required: true,
        type: 'number',
      },
      isLessThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isGreaterThanOrEqualTo: {
        required: true,
        type: 'number',
      },
    },
    timeSelector: {
      required: true,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  billingCity: {
    name: 'Billing address - City',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
      // {label: 'contains', value: 'contains'},
      // {label: "does'nt contains", value: 'doesntContain'},
      // {label: 'starts with', value: 'startsWith'},
      // {label: 'ends with', value: 'endsWith'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'filterDropdown',
        itemType: 'billingCity',
        label: 'Select billing city',
      },
      isNotExactly: {
        required: true,
        type: 'filterDropdown',
        itemType: 'billingCity',
        label: 'Select billing city',
      },
      isOneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'billingCity',
        label: 'Select billing cities',
      },
      isNoneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'billingCity',
        label: 'Select billing cities',
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  billingCountry: {
    name: 'Billing address - Country',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
      // {label: 'contains', value: 'contains'},
      // {label: "does'nt contains", value: 'doesntContain'},
      // {label: 'starts with', value: 'startsWith'},
      // {label: 'ends with', value: 'endsWith'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'filterDropdown',
        itemType: 'billingCountry',
        label: 'Select billing country',
      },
      isNotExactly: {
        required: true,
        type: 'filterDropdown',
        itemType: 'billingCountry',
        label: 'Select billing country',
      },
      isOneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'billingCountry',
        label: 'Select billing countries',
      },
      isNoneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'billingCountry',
        label: 'Select billing countries',
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  billingZip: {
    name: 'Billing Address - Pincode/Zip',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'starts with', value: 'startsWith'},
    ],
    value: {
      startsWith: {
        required: true,
        type: 'singleText',
      },
      isExactly: {
        required: true,
        type: 'singleText',
      },
      isNotExactly: {
        required: true,
        type: 'singleText',
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  billingState: {
    name: 'Billing address - Province/State',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
      // {label: 'contains', value: 'contains'},
      // {label: "does'nt contains", value: 'doesntContain'},
      // {label: 'starts with', value: 'startsWith'},
      // {label: 'ends with', value: 'endsWith'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'filterDropdown',
        itemType: 'billingProvince',
        label: 'Select billing province/state',
      },
      isNotExactly: {
        required: true,
        type: 'filterDropdown',
        itemType: 'billingProvince',
        label: 'Select billing province/state',
      },
      isOneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'billingProvince',
        label: 'Select billing provinces/states',
      },
      isNoneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'billingProvince',
        label: 'Select billing provinces/states',
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  cancelledOrderValue: {
    name: 'Cancelled order value',
    operator: [
      {label: 'equals', value: 'equals'},
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
      {label: 'is less than or equal to', value: 'isLessThanOrEqualTo'},
      {label: 'is greater than or equal to', value: 'isGreaterThanOrEqualTo'},
    ],

    timeSelector: {
      required: true,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
    value: {
      isExactly: {
        required: true,
        type: 'option',
        options: [
          {label: 'yes', value: 'yes'},
          {label: 'no', value: 'no'},
        ],
      },
      atLeastOnce: {
        required: false,
      },
      zeroTimes: {
        required: false,
      },
      equals: {
        required: true,
        type: 'number',
      },
      isLessThan: {
        required: true,
        type: 'number',
      },
      isGreaterThan: {
        required: true,
        type: 'number',
      },
      isLessThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isGreaterThanOrEqualTo: {
        required: true,
        type: 'number',
      },
    },
  },
  cancelledOrders: {
    name: 'Cancelled orders',
    operator: [
      {label: 'at least once', value: 'atLeastOnce'},
      {label: 'zero times', value: 'zeroTimes'},
      {label: 'equals', value: 'equals'},
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
      {label: 'is less than or equal to', value: 'isLessThanOrEqualTo'},
      {label: 'is greater than or equal to', value: 'isGreaterThanOrEqualTo'},
    ],
    value: {
      atLeastOnce: {
        required: false,
      },
      zeroTimes: {
        required: false,
      },
      equals: {
        required: true,
        type: 'number',
      },
      isLessThan: {
        required: true,
        type: 'number',
      },
      isGreaterThan: {
        required: true,
        type: 'number',
      },
      isLessThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isGreaterThanOrEqualTo: {
        required: true,
        type: 'number',
      },
    },
    timeSelector: {
      required: true,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  category: {
    name: 'Product category',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
      // {label: 'contains', value: 'contains'},
      // {label: "does'nt contains", value: 'doesntContain'},
      // {label: 'starts with', value: 'startsWith'},
      // {label: 'ends with', value: 'endsWith'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'filterDropdown',
        itemType: 'productCategory',
        label: 'Select product category',
      },
      isNotExactly: {
        required: true,
        type: 'filterDropdown',
        itemType: 'productCategory',
        label: 'Select product category',
      },
      isOneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'productCategory',
        label: 'Select product categories',
      },
      isNoneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'productCategory',
        label: 'Select product categories',
      },
      // contains: {
      //   required: true,
      //   type: 'singleText',
      // },
      // doesntContain: {
      //   required: true,
      //   type: 'singleText',
      // },
      // startsWith: {
      //   required: true,
      //   type: 'singleText',
      // },
      // endsWith: {
      //   required: true,
      //   type: 'singleText',
      // },
    },
    timeSelector: {
      required: true,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  categoryOfFirstPurchase: {
    name: 'Product category of first purchase',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'filterDropdown',
        itemType: 'productCategory',
        label: 'Select product category',
      },
      isNotExactly: {
        required: true,
        type: 'filterDropdown',
        itemType: 'productCategory',
        label: 'Select product category',
      },
      isOneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'productCategory',
        label: 'Select product categories',
      },
      isNoneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'productCategory',
        label: 'Select product categories',
      },
    },
    timeSelector: {
      required: true,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  channel: {
    name: 'Channel',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'singleText',
      },
      isNotExactly: {
        required: true,
        type: 'singleText',
      },
      isOneOf: {
        required: true,
        type: 'multiText',
      },
      isNoneOf: {
        required: true,
        type: 'multiText',
      },
    },
    timeSelector: {
      required: true,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  channelOfFirstPurchase: {
    name: 'Channel of first purchase',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'singleText',
      },
      isNotExactly: {
        required: true,
        type: 'singleText',
      },
      isOneOf: {
        required: true,
        type: 'multiText',
      },
      isNoneOf: {
        required: true,
        type: 'multiText',
      },
    },
    timeSelector: {
      required: true,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  // checkoutStarted: {
  //   name: 'Checkout started',
  //   operator: [
  //     {label: 'is exactly', value: 'isExactly'},
  //     {label: 'is less than', value: 'isLessThan'},
  //     {label: 'is greater than', value: 'isGreaterThan'},
  //     {label: 'is less than or equal to', value: 'isLessThanOrEqualTo'},
  //     {label: 'is greater than or equal to', value: 'isGreaterThanOrEqualTo'},
  //   ],
  //   value: {
  //     isExactly: {
  //       required: true,
  //       type: 'number',
  //     },
  //     isLessThan: {
  //       required: true,
  //       type: 'number',
  //     },
  //     isGreaterThan: {
  //       required: true,
  //       type: 'number',
  //     },
  //     isLessThanOrEqualTo: {
  //       required: true,
  //       type: 'number',
  //     },
  //     isGreaterThanOrEqualTo: {
  //       required: true,
  //       type: 'number',
  //     },
  //   },
  //   timeSelector: {
  //     required: true,
  //     options: [
  //       {label: 'all time', value: 'allTime'},
  //       {label: 'within last', value: 'withinLast'},
  //       {label: 'not within', value: 'notWithin'},
  //       {label: 'before date', value: 'beforeDate'},
  //       {label: 'after date', value: 'afterDate'},
  //       {label: 'between dates', value: 'betweenDates'},
  //     ],
  //   },
  // },
  checkoutStartedValue: {
    name: 'Checkout started value',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
      {label: 'is less than or equal to', value: 'isLessThanOrEqualTo'},
      {label: 'is greater than or equal to', value: 'isGreaterThanOrEqualTo'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'number',
      },
      isLessThan: {
        required: true,
        type: 'number',
      },
      isGreaterThan: {
        required: true,
        type: 'number',
      },
      isLessThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isGreaterThanOrEqualTo: {
        required: true,
        type: 'number',
      },
    },
    timeSelector: {
      required: true,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  currency: {
    name: 'Currency',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'singleText',
      },
      isNotExactly: {
        required: true,
        type: 'singleText',
      },
      isOneOf: {
        required: true,
        type: 'multiText',
      },
      isNoneOf: {
        required: true,
        type: 'multiText',
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  // customerId: {
  //   name: 'Customer ID',
  //   operator: [
  //     {label: 'is exactly', value: 'isExactly'},
  //     {label: 'is not exactly', value: 'isNotExactly'},
  //     {label: 'is one of', value: 'isOneOf'},
  //     {label: 'is none of', value: 'isNoneOf'},
  //   ],
  //   value: {
  //     isExactly: {
  //       required: true,
  //       type: 'singleText',
  //     },
  //     isNotExactly: {
  //       required: true,
  //       type: 'singleText',
  //     },
  //     isOneOf: {
  //       required: true,
  //       type: 'multiText',
  //     },
  //     isNoneOf: {
  //       required: true,
  //       type: 'multiText',
  //     },
  //   },
  //   timeSelector: {
  //     required: false,
  //     options: [
  //       {label: 'all time', value: 'allTime'},
  //       {label: 'within last', value: 'withinLast'},
  //       {label: 'not within', value: 'notWithin'},
  //       {label: 'before date', value: 'beforeDate'},
  //       {label: 'after date', value: 'afterDate'},
  //       {label: 'between dates', value: 'betweenDates'},
  //     ],
  //   },
  // },
  customerTag: {
    name: 'Customer Tag',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
      {label: 'contains', value: 'contains'},
      {label: "does'nt contains", value: 'doesntContain'},
      {label: 'starts with', value: 'startsWith'},
      {label: 'ends with', value: 'endsWith'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'singleText',
      },
      isNotExactly: {
        required: true,
        type: 'singleText',
      },
      isOneOf: {
        required: true,
        type: 'multiText',
      },
      isNoneOf: {
        required: true,
        type: 'multiText',
      },
      contains: {
        required: true,
        type: 'singleText',
      },
      doesntContain: {
        required: true,
        type: 'singleText',
      },
      startsWith: {
        required: true,
        type: 'singleText',
      },
      endsWith: {
        required: true,
        type: 'singleText',
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  deliveredOrder: {
    name: 'Delivered Order',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
      {label: 'is less than or equal to', value: 'isLessThanOrEqualTo'},
      {label: 'is greater than or equal to', value: 'isGreaterThanOrEqualTo'},
      {label: 'is at least', value: 'isAtLeast'},
      {label: 'is at most', value: 'isAtMost'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'number',
      },
      isLessThan: {
        required: true,
        type: 'number',
      },
      isGreaterThan: {
        required: true,
        type: 'number',
      },
      isLessThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isGreaterThanOrEqualTo: {
        required: true,
        type: 'number',
      },
    },
    timeSelector: {
      required: true,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  defaultCity: {
    name: 'Default address - City',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
      // {label: 'contains', value: 'contains'},
      // {label: "does'nt contains", value: 'doesntContain'},
      // {label: 'starts with', value: 'startsWith'},
      // {label: 'ends with', value: 'endsWith'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'filterDropdown',
        itemType: 'defaultCity',
        label: 'Select default city',
      },
      isNotExactly: {
        required: true,
        type: 'filterDropdown',
        itemType: 'defaultCity',
        label: 'Select default city',
      },
      isOneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'defaultCity',
        label: 'Select default cities',
      },
      isNoneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'defaultCity',
        label: 'Select default cities',
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  defaultCountry: {
    name: 'Default address - Country',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
      // {label: 'contains', value: 'contains'},
      // {label: "does'nt contains", value: 'doesntContain'},
      // {label: 'starts with', value: 'startsWith'},
      // {label: 'ends with', value: 'endsWith'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'filterDropdown',
        itemType: 'defaultCountry',
        label: 'Select default country',
      },
      isNotExactly: {
        required: true,
        type: 'filterDropdown',
        itemType: 'defaultCountry',
        label: 'Select default country',
      },
      isOneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'defaultCountry',
        label: 'Select default countries',
      },
      isNoneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'defaultCountry',
        label: 'Select default countries',
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  defaultZip: {
    name: 'Default Address - Pincode/Zip',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'starts with', value: 'startsWith'},
    ],
    value: {
      startsWith: {
        required: true,
        type: 'singleText',
      },
      isExactly: {
        required: true,
        type: 'singleText',
      },
      isNotExactly: {
        required: true,
        type: 'singleText',
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  defaultState: {
    name: 'Default address - Province/State',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
      // {label: 'contains', value: 'contains'},
      // {label: "does'nt contains", value: 'doesntContain'},
      // {label: 'starts with', value: 'startsWith'},
      // {label: 'ends with', value: 'endsWith'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'filterDropdown',
        itemType: 'defaultProvince',
        label: 'Select default province/state',
      },
      isNotExactly: {
        required: true,
        type: 'filterDropdown',
        itemType: 'defaultProvince',
        label: 'Select default province/state',
      },
      isOneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'defaultProvince',
        label: 'Select default provinces/states',
      },
      isNoneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'defaultProvince',
        label: 'Select default provinces/states',
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  discountValue: {
    name: 'Discount Value',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
      {label: 'is less than or equal to', value: 'isLessThanOrEqualTo'},
      {label: 'is greater than or equal to', value: 'isGreaterThanOrEqualTo'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'number',
      },
      isLessThan: {
        required: true,
        type: 'number',
      },
      isGreaterThan: {
        required: true,
        type: 'number',
      },
      isLessThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isGreaterThanOrEqualTo: {
        required: true,
        type: 'number',
      },
    },
    timeSelector: {
      required: true,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  discountCode: {
    name: 'Discount code',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
      // {label: 'contains', value: 'contains'},
      // {label: "does'nt contains", value: 'doesntContain'},
      // {label: 'starts with', value: 'startsWith'},
      // {label: 'ends with', value: 'endsWith'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'filterDropdown',
        itemType: 'discountCodes',
        label: 'Select discount code',
      },
      isNotExactly: {
        required: true,
        type: 'filterDropdown',
        itemType: 'discountCodes',
        label: 'Select discount code',
      },
      isOneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'discountCodes',
        label: 'Select discount codes',
      },
      isNoneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'discountCodes',
        label: 'Select discount codes',
      },
      // isExactly: {
      //   required: true,
      //   type: 'singleText',
      // },
      // isNotExactly: {
      //   required: true,
      //   type: 'singleText',
      // },
      // isOneOf: {
      //   required: true,
      //   type: 'multiText',
      // },
      // isNoneOf: {
      //   required: true,
      //   type: 'multiText',
      // },
      // contains: {
      //   required: true,
      //   type: 'singleText',
      // },
      // doesntContain: {
      //   required: true,
      //   type: 'singleText',
      // },
      // startsWith: {
      //   required: true,
      //   type: 'singleText',
      // },
      // endsWith: {
      //   required: true,
      //   type: 'singleText',
      // },
    },
    timeSelector: {
      required: true,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  discountCodeOfFirstPurchase: {
    name: 'Discount code of first purchase',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
      // {label: 'contains', value: 'contains'},
      // {label: "does'nt contains", value: 'doesntContain'},
      // {label: 'starts with', value: 'startsWith'},
      // {label: 'ends with', value: 'endsWith'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'filterDropdown',
        itemType: 'discountCodes',
        label: 'Select discount code',
      },
      isNotExactly: {
        required: true,
        type: 'filterDropdown',
        itemType: 'discountCodes',
        label: 'Select discount code',
      },
      isOneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'discountCodes',
        label: 'Select discount codes',
      },
      isNoneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'discountCodes',
        label: 'Select discount codes',
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  discountCodesReedemedCount: {
    name: 'Discount codes reedemed count',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
      {label: 'is less than or equal to', value: 'isLessThanOrEqualTo'},
      {label: 'is greater than or equal to', value: 'isGreaterThanOrEqualTo'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'number',
      },
      isNotExactly: {
        required: true,
        type: 'number',
      },
      isOneOf: {
        required: true,
        type: 'multiText',
      },
      isNoneOf: {
        required: true,
        type: 'multiText',
      },
      isLessThan: {
        required: true,
        type: 'number',
      },
      isGreaterThan: {
        required: true,
        type: 'number',
      },
      isLessThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isGreaterThanOrEqualTo: {
        required: true,
        type: 'number',
      },
    },
    timeSelector: {
      required: true,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  email: {
    name: 'Email',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'starts with', value: 'startsWith'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'singleText',
      },
      startsWith: {
        required: true,
        type: 'singleText',
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  firstName: {
    name: 'First name',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'contains', value: 'contains'},
      {label: "does'nt contains", value: 'doesntContain'},
      {label: 'starts with', value: 'startsWith'},
      {label: 'ends with', value: 'endsWith'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'singleText',
      },
      contains: {
        required: true,
        type: 'singleText',
      },
      doesntContain: {
        required: true,
        type: 'singleText',
      },
      startsWith: {
        required: true,
        type: 'singleText',
      },
      endsWith: {
        required: true,
        type: 'singleText',
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  firstOrderDate: {
    name: 'First order date',
    timeSelector: {
      required: true,
      options: [
        {label: 'is exactly', value: 'isExactly'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  firstOrderValue: {
    name: 'First order value',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
      {label: 'is less than or equal to', value: 'isLessThanOrEqualTo'},
      {label: 'is greater than or equal to', value: 'isGreaterThanOrEqualTo'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'number',
      },
      isNotExactly: {
        required: true,
        type: 'number',
      },
      isOneOf: {
        required: true,
        type: 'multiText',
      },
      isNoneOf: {
        required: true,
        type: 'multiText',
      },
      isLessThan: {
        required: true,
        type: 'number',
      },
      isGreaterThan: {
        required: true,
        type: 'number',
      },
      isLessThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isGreaterThanOrEqualTo: {
        required: true,
        type: 'number',
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  firstPurchasedVariant: {
    name: 'First purchased variant',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'filterDropdown',
        itemType: 'variants',
        label: 'Select product',
      },
      isNotExactly: {
        required: true,
        type: 'filterDropdown',
        itemType: 'variants',
        label: 'Select product',
      },
      isOneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'variants',
        label: 'Select products',
      },
      isNoneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'variants',
        label: 'Select products',
      },
    },
    timeSelector: {
      required: true,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  fulfillmentCreated: {
    name: 'Fulfillment Created',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
      {label: 'is less than or equal to', value: 'isLessThanOrEqualTo'},
      {label: 'is greater than or equal to', value: 'isGreaterThanOrEqualTo'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'number',
      },
      isLessThan: {
        required: true,
        type: 'number',
      },
      isGreaterThan: {
        required: true,
        type: 'number',
      },
      isLessThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isGreaterThanOrEqualTo: {
        required: true,
        type: 'number',
      },
    },
    timeSelector: {
      required: true,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  grossMargin: {
    name: 'Gross Margin',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
      {label: 'is less than or equal to', value: 'isLessThanOrEqualTo'},
      {label: 'is greater than or equal to', value: 'isGreaterThanOrEqualTo'},
      {label: 'is the best', value: 'isTheBest'},
      {label: 'is the worst', value: 'isTheWorst'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'singleText',
      },
      isNotExactly: {
        required: true,
        type: 'singleText',
      },
      isLessThan: {
        required: true,
        type: 'number',
      },
      isGreaterThan: {
        required: true,
        type: 'number',
      },
      isLessThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isGreaterThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isTheBest: {
        required: true,
        type: 'option',
        options: tenToHundred,
      },
      isTheWorst: {
        required: true,
        type: 'option',
        options: tenToHundred,
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  grossRevenue: {
    name: 'Gross Revenue',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
      {label: 'is less than or equal to', value: 'isLessThanOrEqualTo'},
      {label: 'is greater than or equal to', value: 'isGreaterThanOrEqualTo'},
      {label: 'is the best', value: 'isTheBest'},
      {label: 'is the worst', value: 'isTheWorst'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'number',
      },
      isNotExactly: {
        required: true,
        type: 'singleText',
      },
      isLessThan: {
        required: true,
        type: 'number',
      },
      isGreaterThan: {
        required: true,
        type: 'number',
      },
      isLessThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isGreaterThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isTheBest: {
        required: true,
        type: 'option',
        options: tenToHundred,
      },
      isTheWorst: {
        required: true,
        type: 'option',
        options: tenToHundred,
      },
    },
    timeSelector: {
      required: true,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  purchased: {
    name: 'Purchased',
    operator: [{label: 'equal to', value: 'equalTo'}],
    value: {
      equalTo: {
        required: true,
        type: 'option',
        options: [
          {label: 'yes', value: 'true'},
          {label: 'no', value: 'false'},
        ],
      },
    },
    timeSelector: {
      required: true,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  lastName: {
    name: 'Last name',
    operator: [
      {label: 'contains', value: 'contains'},
      {label: "does'nt contains", value: 'doesntContain'},
      {label: 'starts with', value: 'startsWith'},
      {label: 'ends with', value: 'endsWith'},
    ],
    value: {
      contains: {
        required: true,
        type: 'singleText',
      },
      doesntContain: {
        required: true,
        type: 'singleText',
      },
      startsWith: {
        required: true,
        type: 'singleText',
      },
      endsWith: {
        required: true,
        type: 'singleText',
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  lastOrderDate: {
    name: 'Last order date',
    timeSelector: {
      required: true,
      options: [
        {label: 'is exactly', value: 'isExactly'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  lastOrderValue: {
    name: 'Last order value',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
      {label: 'is less than or equal to', value: 'isLessThanOrEqualTo'},
      {label: 'is greater than or equal to', value: 'isGreaterThanOrEqualTo'},
      {label: 'is the best', value: 'isTheBest'},
      {label: 'is the worst', value: 'isTheWorst'},
    ],
    value: {
      isLessThan: {
        required: true,
        type: 'number',
      },
      isGreaterThan: {
        required: true,
        type: 'number',
      },
      isLessThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isGreaterThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isTheBest: {
        required: true,
        type: 'option',
        options: tenToHundred,
      },
      isTheWorst: {
        required: true,
        type: 'option',
        options: tenToHundred,
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  lifetime: {
    name: 'Lifetime (in months)',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
      {label: 'is less than or equal to', value: 'isLessThanOrEqualTo'},
      {label: 'is greater than or equal to', value: 'isGreaterThanOrEqualTo'},
      {label: 'is the best', value: 'isTheBest'},
      {label: 'is the worst', value: 'isTheWorst'},
    ],
    value: {
      isLessThan: {
        required: true,
        type: 'number',
      },
      isGreaterThan: {
        required: true,
        type: 'number',
      },
      isLessThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isGreaterThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isTheBest: {
        required: true,
        type: 'option',
        options: tenToHundred,
      },
      isTheWorst: {
        required: true,
        type: 'option',
        options: tenToHundred,
      },
      isExactly: {
        required: true,
        type: 'number',
      },
      isNotExactly: {
        required: true,
        type: 'number',
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  ltv: {
    name: 'LTV',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
      {label: 'is less than or equal to', value: 'isLessThanOrEqualTo'},
      {label: 'is greater than or equal to', value: 'isGreaterThanOrEqualTo'},
    ],
    value: {
      isLessThan: {
        required: true,
        type: 'number',
      },
      isGreaterThan: {
        required: true,
        type: 'number',
      },
      isLessThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isGreaterThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isExactly: {
        required: true,
        type: 'number',
      },
      isNotExactly: {
        required: true,
        type: 'number',
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  ltvOneYear: {
    name: 'LTV 1 year',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
      {label: 'is less than or equal to', value: 'isLessThanOrEqualTo'},
      {label: 'is greater than or equal to', value: 'isGreaterThanOrEqualTo'},
    ],
    value: {
      isLessThan: {
        required: true,
        type: 'number',
      },
      isGreaterThan: {
        required: true,
        type: 'number',
      },
      isLessThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isGreaterThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isExactly: {
        required: true,
        type: 'number',
      },
      isNotExactly: {
        required: true,
        type: 'number',
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  ltvThirtyDays: {
    name: 'LTV 30 days',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
      {label: 'is less than or equal to', value: 'isLessThanOrEqualTo'},
      {label: 'is greater than or equal to', value: 'isGreaterThanOrEqualTo'},
    ],
    value: {
      isLessThan: {
        required: true,
        type: 'number',
      },
      isGreaterThan: {
        required: true,
        type: 'number',
      },
      isLessThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isGreaterThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isExactly: {
        required: true,
        type: 'number',
      },
      isNotExactly: {
        required: true,
        type: 'number',
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  ltvFiveYears: {
    name: 'LTV 5 years',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
      {label: 'is less than or equal to', value: 'isLessThanOrEqualTo'},
      {label: 'is greater than or equal to', value: 'isGreaterThanOrEqualTo'},
    ],
    value: {
      isLessThan: {
        required: true,
        type: 'number',
      },
      isGreaterThan: {
        required: true,
        type: 'number',
      },
      isLessThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isGreaterThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isExactly: {
        required: true,
        type: 'number',
      },
      isNotExactly: {
        required: true,
        type: 'number',
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  ltvNinetyDays: {
    name: 'LTV 90 days',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
      {label: 'is less than or equal to', value: 'isLessThanOrEqualTo'},
      {label: 'is greater than or equal to', value: 'isGreaterThanOrEqualTo'},
    ],
    value: {
      isLessThan: {
        required: true,
        type: 'number',
      },
      isGreaterThan: {
        required: true,
        type: 'number',
      },
      isLessThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isGreaterThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isExactly: {
        required: true,
        type: 'number',
      },
      isNotExactly: {
        required: true,
        type: 'number',
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  orderedProductValue: {
    name: 'Ordered product value',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
      {label: 'is less than or equal to', value: 'isLessThanOrEqualTo'},
      {label: 'is greater than or equal to', value: 'isGreaterThanOrEqualTo'},
      {label: 'is the top', value: 'isTheTop'},
      {label: 'is the bottom', value: 'isTheBottom'},
    ],
    timeSelector: {required: false},
    value: {
      isLessThan: {
        required: true,
        type: 'number',
      },
      isGreaterThan: {
        required: true,
        type: 'number',
      },
      isLessThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isGreaterThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isTheTop: {
        required: true,
        type: 'option',
        options: tenToHundred,
      },
      isTheBottom: {
        required: true,
        type: 'option',
        options: tenToHundred,
      },
      isExactly: {
        required: true,
        type: 'number',
      },
      isNotExactly: {
        required: true,
        type: 'number',
      },
      isOneOf: {
        required: true,
        type: 'multiText',
      },
      isNoneOf: {
        required: true,
        type: 'multiText',
      },
    },
  },
  orderTag: {
    name: 'Order tag',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
      {label: 'contains', value: 'contains'},
      {label: "does'nt contains", value: 'doesntContain'},
      {label: 'starts with', value: 'startsWith'},
      {label: 'ends with', value: 'endsWith'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'singleText',
      },
      isNotExactly: {
        required: true,
        type: 'singleText',
      },
      isOneOf: {
        required: true,
        type: 'multiText',
      },
      isNoneOf: {
        required: true,
        type: 'multiText',
      },
      contains: {
        required: true,
        type: 'singleText',
      },
      doesntContain: {
        required: true,
        type: 'singleText',
      },
      startsWith: {
        required: true,
        type: 'singleText',
      },
      endsWith: {
        required: true,
        type: 'singleText',
      },
    },
    timeSelector: {
      required: true,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  orderTagOfFirstPurchase: {
    name: 'Order tag of first purchase',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
      {label: 'contains', value: 'contains'},
      {label: "does'nt contains", value: 'doesntContain'},
      {label: 'starts with', value: 'startsWith'},
      {label: 'ends with', value: 'endsWith'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'singleText',
      },
      isNotExactly: {
        required: true,
        type: 'singleText',
      },
      isOneOf: {
        required: true,
        type: 'multiText',
      },
      isNoneOf: {
        required: true,
        type: 'multiText',
      },
      contains: {
        required: true,
        type: 'singleText',
      },
      doesntContain: {
        required: true,
        type: 'singleText',
      },
      startsWith: {
        required: true,
        type: 'singleText',
      },
      endsWith: {
        required: true,
        type: 'singleText',
      },
    },
    timeSelector: {
      required: true,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  phone: {
    name: 'Phone',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'starts with', value: 'startsWith'},
    ],
    value: {
      startsWith: {
        required: true,
        type: 'singleText',
      },
      isExactly: {
        required: true,
        type: 'singleText',
      },
      isNotExactly: {
        required: true,
        type: 'singleText',
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  placedReturnedRate: {
    name: 'Placed returned rate',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
      {label: 'is less than or equal to', value: 'isLessThanOrEqualTo'},
      {label: 'is greater than or equal to', value: 'isGreaterThanOrEqualTo'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'option',
        options: oneToHundred,
      },
      isLessThan: {
        required: true,
        type: 'option',
        options: oneToHundred,
      },
      isGreaterThan: {
        required: true,
        type: 'option',
        options: oneToHundred,
      },
      isLessThanOrEqualTo: {
        required: true,
        type: 'option',
        options: oneToHundred,
      },
      isGreaterThanOrEqualTo: {
        required: true,
        type: 'option',
        options: oneToHundred,
      },
    },
    timeSelector: {
      required: true,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  productReturnPercentage: {
    name: 'Product return percentage',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
      {label: 'is less than or equal to', value: 'isLessThanOrEqualTo'},
      {label: 'is greater than or equal to', value: 'isGreaterThanOrEqualTo'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'option',
        options: oneToHundred,
      },
      isLessThan: {
        required: true,
        type: 'option',
        options: oneToHundred,
      },
      isGreaterThan: {
        required: true,
        type: 'option',
        options: oneToHundred,
      },
      isLessThanOrEqualTo: {
        required: true,
        type: 'option',
        options: oneToHundred,
      },
      isGreaterThanOrEqualTo: {
        required: true,
        type: 'option',
        options: oneToHundred,
      },
    },
    timeSelector: {
      required: true,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  purchasedVariant: {
    name: 'Purchased variant',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'filterDropdown',
        itemType: 'variants',
        label: 'Select product',
      },
      isNotExactly: {
        required: true,
        type: 'filterDropdown',
        itemType: 'variants',
        label: 'Select product',
      },
      isOneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'variants',
        label: 'Select products',
      },
      isNoneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'variants',
        label: 'Select products',
      },
    },
    timeSelector: {
      required: true,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  RFMSegments: {
    name: 'RFM Segements',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'option',
        options: [
          {label: 'Royals', value: 'Royals'},
          {label: 'Loyals', value: 'Loyals'},
          {label: 'Aspirants', value: 'Aspirants'},
          {label: 'In-Betweeners', value: 'In-Betweeners'},
          {label: 'Blue-Moons', value: 'Blue-Moons'},
          {label: 'Newcomers', value: 'Newcomers'},
          {label: 'At-Risk', value: 'At-Risk'},
          {label: 'No-Show', value: 'No-Show'},
        ],
      },
      isNotExactly: {
        required: true,
        type: 'option',
        options: [
          {label: 'Royals', value: 'Royals'},
          {label: 'Loyals', value: 'Loyals'},
          {label: 'Aspirants', value: 'Aspirants'},
          {label: 'In-betweeners', value: 'In-betweeners'},
          {label: 'Blue-Moons', value: 'Blue-Moons'},
          {label: 'Newcomers', value: 'Newcomers'},
          {label: 'At-risk', value: 'At-Risk'},
          {label: 'No-Show', value: 'No-Show'},
        ],
      },
      isOneOf: {
        required: true,
        type: 'multiText',
      },
      isNoneOf: {
        required: true,
        type: 'multiText',
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  RFMScore: {
    name: 'RFM Score',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'singleText',
      },
      isNotExactly: {
        required: true,
        type: 'singleText',
      },
      isOneOf: {
        required: true,
        type: 'multiText',
      },
      isNoneOf: {
        required: true,
        type: 'multiText',
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  RFMRecencyScore: {
    name: 'RFM Recency Score',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'option',
        options: [
          {label: '1', value: '1'},
          {label: '2', value: '2'},
          {label: '3', value: '3'},
          {label: '4', value: '4'},
          {label: '5', value: '5'},
        ],
      },
      isNotExactly: {
        required: true,
        type: 'option',
        options: [
          {label: '1', value: '1'},
          {label: '2', value: '2'},
          {label: '3', value: '3'},
          {label: '4', value: '4'},
          {label: '5', value: '5'},
        ],
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  RFMFrequencyScore: {
    name: 'RFM Frequency Score',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'option',
        options: [
          {label: '1', value: '1'},
          {label: '2', value: '2'},
          {label: '3', value: '3'},
          {label: '4', value: '4'},
          {label: '5', value: '5'},
        ],
      },
      isNotExactly: {
        required: true,
        type: 'option',
        options: [
          {label: '1', value: '1'},
          {label: '2', value: '2'},
          {label: '3', value: '3'},
          {label: '4', value: '4'},
          {label: '5', value: '5'},
        ],
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  RFMMonetaryScore: {
    name: 'RFM Monetary Score',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'option',
        options: [
          {label: '1', value: '1'},
          {label: '2', value: '2'},
          {label: '3', value: '3'},
          {label: '4', value: '4'},
          {label: '5', value: '5'},
        ],
      },
      isNotExactly: {
        required: true,
        type: 'option',
        options: [
          {label: '1', value: '1'},
          {label: '2', value: '2'},
          {label: '3', value: '3'},
          {label: '4', value: '4'},
          {label: '5', value: '5'},
        ],
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  shippingCity: {
    name: 'Shipping address - City',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
      // {label: 'contains', value: 'contains'},
      // {label: "does'nt contains", value: 'doesntContain'},
      // {label: 'starts with', value: 'startsWith'},
      // {label: 'ends with', value: 'endsWith'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'filterDropdown',
        itemType: 'shippingCity',
        label: 'Select shipping city',
      },
      isNotExactly: {
        required: true,
        type: 'filterDropdown',
        itemType: 'shippingCity',
        label: 'Select shipping city',
      },
      isOneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'shippingCity',
        label: 'Select shipping cities',
      },
      isNoneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'shippingCity',
        label: 'Select shipping cities',
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  shippingCountry: {
    name: 'Shipping address - Country',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
      // {label: 'contains', value: 'contains'},
      // {label: "does'nt contains", value: 'doesntContain'},
      // {label: 'starts with', value: 'startsWith'},
      // {label: 'ends with', value: 'endsWith'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'filterDropdown',
        itemType: 'shippingCountry',
        label: 'Select shipping country',
      },
      isNotExactly: {
        required: true,
        type: 'filterDropdown',
        itemType: 'shippingCountry',
        label: 'Select shipping country',
      },
      isOneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'shippingCountry',
        label: 'Select shipping countries',
      },
      isNoneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'shippingCountry',
        label: 'Select shipping countries',
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  shippingZip: {
    name: 'Shipping Address - Pincode/Zip',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'starts with', value: 'startsWith'},
    ],
    value: {
      startsWith: {
        required: true,
        type: 'singleText',
      },
      isExactly: {
        required: true,
        type: 'singleText',
      },
      isNotExactly: {
        required: true,
        type: 'singleText',
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  shippingState: {
    name: 'Shipping address - Province/State',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'filterDropdown',
        itemType: 'shippingProvince',
        label: 'Select shipping province/states',
      },
      isNotExactly: {
        required: true,
        type: 'filterDropdown',
        itemType: 'shippingProvince',
        label: 'Select shipping province/states',
      },
      isOneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'shippingProvince',
        label: 'Select shipping provinces/states',
      },
      isNoneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'shippingProvince',
        label: 'Select shipping provinces/states',
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  signedUp: {
    name: 'Signed up',
    operator: [{label: 'equals', value: 'equalTo'}],
    value: {
      equalTo: {
        required: true,
        type: 'option',
        options: [{label: 'yes', value: 'true'}],
      },
    },
    timeSelector: {
      required: true,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  paymentMethod: {
    name: 'Payment method',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'singleText',
      },
      isNotExactly: {
        required: true,
        type: 'singleText',
      },
      isOneOf: {
        required: true,
        type: 'multiText',
      },
      isNoneOf: {
        required: true,
        type: 'multiText',
      },
    },
    timeSelector: {
      required: true,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  totalItems: {
    name: 'Total Items',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is not exactly', value: 'isNotExactly'},
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
      {label: 'is less than or equal to', value: 'isLessThanOrEqualTo'},
      {label: 'is greater than or equal to', value: 'isGreaterThanOrEqualTo'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'singleText',
      },
      isNotExactly: {
        required: true,
        type: 'singleText',
      },
      isLessThan: {
        required: true,
        type: 'number',
      },
      isGreaterThan: {
        required: true,
        type: 'number',
      },
      isLessThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isGreaterThanOrEqualTo: {
        required: true,
        type: 'number',
      },
    },
    timeSelector: {
      required: false,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
  totalOrders: {
    name: 'Total Orders',
    operator: [
      {label: 'is exactly', value: 'isExactly'},
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
      {label: 'is less than or equal to', value: 'isLessThanOrEqualTo'},
      {label: 'is greater than or equal to', value: 'isGreaterThanOrEqualTo'},
    ],
    value: {
      isExactly: {
        required: true,
        type: 'number',
      },
      isLessThan: {
        required: true,
        type: 'number',
      },
      isGreaterThan: {
        required: true,
        type: 'number',
      },
      isLessThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isGreaterThanOrEqualTo: {
        required: true,
        type: 'number',
      },
      isOneOf: {
        required: true,
        type: 'multiText',
      },
      isNoneOf: {
        required: true,
        type: 'multiText',
      },
    },
    timeSelector: {
      required: true,
      options: [
        {label: 'all time', value: 'allTime'},
        {label: 'within last', value: 'withinLast'},
        {label: 'not within', value: 'notWithin'},
        {label: 'before date', value: 'beforeDate'},
        {label: 'after date', value: 'afterDate'},
        {label: 'between dates', value: 'betweenDates'},
      ],
    },
  },
};

// const valueOptions = {
//   isExactly: {
//     required: true,
//     type: 'option',
//     options: [
//       {label: 'yes', value: 'yes'},
//       {label: 'no', value: 'no'},
//     ],
//   },
//   atLeastOnce: {
//     required: false,
//   },
//   zeroTimes: {
//     required: false,
//   },
//   equals: {
//     required: true,
//     type: 'number',
//   },
//   isLessThan: {
//     required: true,
//     type: 'number',
//   },
//   isGreaterThan: {
//     required: true,
//     type: 'number',
//   },
//   isLessThanOrEqualTo: {
//     required: true,
//     type: 'number',
//   },
//   isGreaterThanOrEqualTo: {
//     required: true,
//     type: 'number',
//   },
//   isAtLeast: {
//     required: true,
//     type: 'number',
//   },
//   isAtMost: {
//     required: true,
//     type: 'number',
//   },
//   isTheTop: {
//     required: true,
//     type: 'option',
//     options: tenToHundred,
//   },
//   isTheBottom: {
//     required: true,
//     type: 'option',
//     options: tenToHundred,
//   },
// };

const timeSelection = {
  allTime: {
    required: false,
  },
  withinLast: {
    required: true,
    type: 'days',
  },
  notWithin: {
    required: true,
    type: 'days',
  },
  beforeDate: {
    required: true,
    type: 'singleDatePicker',
  },
  afterDate: {
    required: true,
    type: 'singleDatePicker',
  },
  betweenDates: {
    required: true,
    type: 'multiDatePicker',
  },
  isExactly: {
    required: true,
    type: 'singleDatePicker',
  },
};

const segments = [];
let i = 0;
for (let key in options) {
  segments.push({label: options[key].name, id: key, value: i});
  i++;
}

export {options, timeSelection, segments};
