import {TextField, Modal, TextContainer} from '@shopify/polaris';
import {useState, useCallback} from 'react';
import {LocalStorageService} from '../../service/localStorage';
import {emailValidator} from '../../features/validations';
import Inline from '../../lib/Inline';
import Button from '../../lib/Button';

const DownloadModal = ({isOpen, handleDownload, toggle}) => {
  const [loading, setLoading] = useState(false);
  let details = LocalStorageService.getUserProfile();
  let _email = details?.email;
  const [email, setEmail] = useState(_email);
  const updateEmail = useCallback(e => {
    setIsValidEmail(true);
    setEmail(e);
  });
  const [isValidEmail, setIsValidEmail] = useState(true);

  const activator = <div></div>;

  return (
    <>
      <Modal activator={activator} open={isOpen} onClose={toggle} title="Download">
        <Modal.Section>
          <TextContainer>
            <TextField
              value={email}
              error={!isValidEmail && 'Email id is not valid'}
              onChange={updateEmail}
              label="Email id to recieve report"
            />
            <Inline align="end">
              <Button
                disabled={loading}
                onClick={async () => {
                  if (!emailValidator(email)) {
                    setIsValidEmail(false);
                  } else {
                    setLoading(true);
                    await handleDownload(email);
                    setLoading(false);
                    toggle();
                  }
                }}
                primary
              >
                Download
              </Button>
            </Inline>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </>
  );
};

export default DownloadModal;
