import React, {useEffect, useState} from 'react';
import Page from '../../../components/page';
import {Box, Layout, TextField, Button, Text, Inline} from '@shopify/polaris';
import {track} from '../../../features/track';
import {getCost, updateCost} from '../../../api/cost';
import Alert from '../../../components/alert';
import {getCurrency} from '../transaction/currencySymbol';
import {LocalStorageService} from '../../../service/localStorage';

const HandlingCost = () => {
  const [costPerOrder, setCostPerOrder] = useState('');
  const [costPerItem, setCostPerItem] = useState('');
  const [showAlert, setShowAlert] = useState('');
  const currency = LocalStorageService.getUserOrg().currency;

  useEffect(() => {
    getCost().then(response => {
      if (response.status === 200) {
        setCostPerOrder(response.data.data?.handlingCost_valuePerOrder);
        setCostPerItem(response.data.data?.handlingCost_valuePerItem);
      } else {
        setCostPerOrder(0);
        setCostPerItem(0);
      }
    });
  }, []);

  const handleSaveChanges = () => {
    // Add logic to save changes in valueType
    const params = {
      handlingCost_valueType: 'flat',
      handlingCost_valuePerOrder: costPerOrder,
      handlingCost_valuePerItem: costPerItem,
    };
    updateCost(params)
      .then(() => {
        setShowAlert('Settings updated successfully.');
      })
      .catch(err => {
        setShowAlert('Error updating the settings. Please try again later.');
      });
  };

  useEffect(() => {
    track('Page View', {
      name: 'HandlingCost',
    });
  }, []);

  return (
    <>
      <Page title={'Packaging costs'}>
        <Box paddingBlockStart={'4'}>
          <Layout>
            <Layout.Section>
              <Text as="p" color="subdued">
                Packaging costs include the outer cover for your product, any protective wrapping as
                well as any marketing material and inserts. These are calculated for every item in
                an order.
              </Text>
            </Layout.Section>
            {/* <Layout.Section>
              <Inline>
                <Box width="10%">
                  <Text variant="bodyXs" as="p" color="subdued">
                    Cost per order
                  </Text>
                </Box>
                <Box width="20%">
                  <TextField
                    type="number"
                    placeholder="Enter value"
                    suffix={getCurrency(currency)}
                    value={costPerOrder}
                    onChange={value => setCostPerOrder(value)}
                  />
                </Box>
                <Text variant="bodyXs" as="p">
                  e.g. packing cost per order is 0.5{getCurrency(currency)}
                </Text>
              </Inline>
            </Layout.Section> */}
            <Layout.Section>
              <Inline>
                <Box width="10%">
                  <Text variant="bodyXs" as="p" color="subdued">
                    Cost per item
                  </Text>
                </Box>
                <Box width="20%">
                  <TextField
                    type="number"
                    placeholder="Enter value"
                    suffix={getCurrency(currency)}
                    value={costPerItem}
                    onChange={value => setCostPerItem(value)}
                  />
                </Box>
                <Text variant="bodyXs" as="p">
                  e.g. picking cost per item is 0.3{getCurrency(currency)}
                </Text>
              </Inline>
            </Layout.Section>
            <Layout.Section>
              <Button primary onClick={handleSaveChanges}>
                Save changes
              </Button>
            </Layout.Section>
          </Layout>
        </Box>
        {showAlert && (
          <Alert
            contents={showAlert}
            init={true}
            error={showAlert === 'Something Went Wrong'}
            duration={10000}
          />
        )}
      </Page>
    </>
  );
};

export {HandlingCost};
