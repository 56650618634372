import axios from '../axios';
import config from '../../config';
const {BASE_URL} = config;

export class AdminApi {
  static async statusTracking(orgs) {
    try {
      const response = await axios.get(`${BASE_URL}/admin/status-tracking?orgId=${orgs}`);
      return response.data;
    } catch (err) {
      return err;
    }
  }
  static async getOrgs(params) {
    try {
      const response = await axios.get(`${BASE_URL}/admin/organizations`, {params});
      return response.data;
    } catch (err) {
      return err;
    }
  }

  static async getOrgsMongoStats({orgId}) {
    try {
      const response = await axios.get(`${BASE_URL}/admin/organizations/${orgId}/stats/mongo`);
      return response.data;
    } catch (err) {
      return err;
    }
  }
  static async getOrgsMongoRawStats({orgId}) {
    try {
      const response = await axios.get(`${BASE_URL}/admin/organizations/${orgId}/stats/mongo-raw`);
      return response.data;
    } catch (err) {
      return err;
    }
  }
  static async getOrgsClickhouseStats({orgId, signal}) {
    try {
      const response = await axios.get(
        `${BASE_URL}/admin/organizations/${orgId}/stats/clickhouse`,
        {signal}
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }
  static async getOrgsCustomStats({orgId}) {
    try {
      const response = await axios.get(`${BASE_URL}/admin/organizations/${orgId}/stats/custom`);
      return response.data;
    } catch (err) {
      return err;
    }
  }
  static async getOrgsPendingSync({orgId}) {
    try {
      const response = await axios.get(
        `${BASE_URL}/admin/organizations/${orgId}/stats/pending-sync`
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }
  static async getOrgsExpected({orgId, signal}) {
    try {
      const response = await axios.get(`${BASE_URL}/admin/organizations/${orgId}/expected`, {
        signal,
      });
      return response.data;
    } catch (err) {
      return err;
    }
  }
  static async clearOrgData({orgId}) {
    try {
      const response = await axios.delete(`${BASE_URL}/admin/organizations/${orgId}/clear`);
      return response.data;
    } catch (err) {
      return err;
    }
  }
  static async signInOrganization({orgId}) {
    try {
      const response = await axios.post(`${BASE_URL}/admin/organizations/${orgId}/signIn`);
      return response.data;
    } catch (err) {
      return err;
    }
  }
  static async deleteOrgWebhooks({orgId}) {
    try {
      const response = await axios.delete(`${BASE_URL}/admin/organizations/${orgId}/webhooks`);
      return response.data;
    } catch (err) {
      return err;
    }
  }
  static async createOrgWebhooks({orgId}) {
    try {
      const response = await axios.post(`${BASE_URL}/admin/organizations/${orgId}/webhooks`);
      return response.data;
    } catch (err) {
      return err;
    }
  }
  static async reCalculateAggregateFields({orgId}) {
    try {
      const response = await axios.get(
        `${BASE_URL}/admin/transform?collections=customTransform&orgs=${orgId}&customTransformMethods=resetAndUpdateCustomKeys`
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }
  static async getJobs(params) {
    try {
      const response = await axios.get(`${BASE_URL}/admin/jobs`, {
        params,
      });
      return response.data;
    } catch (err) {
      return err;
    }
  }
  static async getAsyncJobs(params) {
    try {
      const response = await axios.get(`${BASE_URL}/admin/async-jobs`, {
        params,
      });
      return response.data;
    } catch (err) {
      return err;
    }
  }

  static async getSplits(params) {
    try {
      const response = await axios.get(`${BASE_URL}/admin/jobs/${params.jobId}/splits`, {
        params,
      });
      return response.data;
    } catch (err) {
      return err;
    }
  }
  static async getSplitsCount(params) {
    try {
      const response = await axios.get(`${BASE_URL}/admin/jobs/${params.jobId}/splits/count`, {
        params,
      });
      return response.data;
    } catch (err) {
      return err;
    }
  }
  static async getSplitErrors(params) {
    try {
      const response = await axios.get(`${BASE_URL}/admin/splits/${params.splitId}/errors`, {
        params,
      });
      return response.data;
    } catch (err) {
      return err;
    }
  }

  static async pauseJob({jobId}) {
    try {
      const response = await axios.put(`${BASE_URL}/admin/jobs/${jobId}/pause`);
      return response.data;
    } catch (err) {
      return err;
    }
  }

  static async resumeJob({jobId}) {
    try {
      const response = await axios.put(`${BASE_URL}/admin/jobs/${jobId}/resume`);
      return response.data;
    } catch (err) {
      return err;
    }
  }
  static async retryFailedJob(params) {
    try {
      const response = await axios.put(`${BASE_URL}/admin/jobs/${params.jobId}/retry`);
      return response.data;
    } catch (err) {
      return err;
    }
  }
  static async retryFailedSplit({splitId}) {
    try {
      const response = await axios.put(`${BASE_URL}/admin/splits/${splitId}/retry`);
      return response.data;
    } catch (err) {
      return err;
    }
  }
  static async getJobErrors(params) {
    try {
      const response = await axios.get(`${BASE_URL}/admin/jobs/${params.jobId}/errors`, {
        params,
      });
      return response.data;
    } catch (err) {
      return err;
    }
  }
  static async fetchRecords(body) {
    try {
      const response = await axios.post(
        `${BASE_URL}/admin/organizations/${body.orgId}/fetchRecords`,
        body
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }
  static async triggerDailyFetch({orgId}) {
    try {
      const response = await axios.post(`${BASE_URL}/admin/organizations/${orgId}/dailyFetch`);
      return response.data;
    } catch (err) {
      return err;
    }
  }
  static async getWebhookLogs(params) {
    try {
      const response = await axios.get(`${BASE_URL}/admin/webhook-logs`, {params});
      return response.data;
    } catch (err) {
      return err;
    }
  }
  static async getWebhooks(params) {
    try {
      const response = await axios.get(`${BASE_URL}/admin/webhooks`, {params});
      return response.data;
    } catch (err) {
      return err;
    }
  }

  static async getOrgsRunningWebhooks({orgId}) {
    try {
      const response = await axios.get(`${BASE_URL}/admin/organizations/${orgId}/webhooks`);
      return response.data;
    } catch (err) {
      return err;
    }
  }
  static async getSummaryLogs(params) {
    try {
      const response = await axios.get(`${BASE_URL}/admin/summary-logs`, {params});
      return response.data;
    } catch (err) {
      return err;
    }
  }
}
