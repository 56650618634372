import axios from 'axios';
import config from '../config';
const {BASE_URL} = config;

export class AuthAPI {
  static async signup(signupInput) {
    const response = await axios.post(`${BASE_URL}/user/create`, signupInput);
    return response.data;
  }

  static async signin(signinInput) {
    const response = await axios.post(`${BASE_URL}/user/login`, signinInput);
    return response.data;
  }

  static async verify(verifyInput) {
    const response = await axios.post(`${BASE_URL}/user/verify_otp`, verifyInput);
    return response.data;
  }

  static async resetPasswordRequest(resetPasswordRequestInput) {
    const response = await axios.post(
      `${BASE_URL}/user/forgot_password`,
      resetPasswordRequestInput
    );
    return response.data;
  }

  static async resetPassword(resetPasswordInput) {
    const response = await axios.post(
      `${BASE_URL}/user/reset_password/${resetPasswordInput.token}`,
      resetPasswordInput
    );
    return response.data;
  }
  static async resendCode(resendCodeEmail) {
    const response = await axios.post(`${BASE_URL}/user/resend`, resendCodeEmail);
    return response.data;
  }

  static async userInfo(params) {
    const response = await axios.get(`${BASE_URL}/user/user-info`, params);
    return response.data;
  }
}
