import {TextField, Filters, OptionList, labelID} from '@shopify/polaris';
import React, {useState, useCallback, useEffect} from 'react';
import {
  getDiscountCodes,
  getProductSkus,
  getOrderTags,
  getCustomerTags,
} from '../../api/ltvFilters';
import {getVariantList, getdiscountCodeList} from '../../api/filterLists';
import {getTransformedProductTitle} from '../../features/getTransformedProductTitle';
import Button from '../../lib/Button';

function BasicFilter(props) {
  const [selectedFilters, setSelectedFilters] = useState({});
  const [filtersApplied, setFiltersApplied] = useState({});
  const [skuList, setSkuList] = useState([]);
  const [discountList, setDiscountList] = useState([]);
  const [orderTagList, setOrderTagList] = useState([]);
  const [customerTagList, setCustomerTagList] = useState([]);
  const [productLabel, setProductLabel] = useState({});

  useEffect(() => {
    props.handleFiltersUpdate(filtersApplied);
  }, [filtersApplied]);
  useEffect(() => {
    getVariantList({params: {search: selectedFilters['productPurchased']}}).then(response => {
      let data = response.data.result.map(x => ({
        value: x.variantId,
        label: getTransformedProductTitle({
          sku: x.sku,
          title: x.title,
          variantTitle: x.variantTitle,
        }),
      }));
      setSkuList(data);
    });
  }, [selectedFilters['productPurchased']]);

  useEffect(() => {
    getdiscountCodeList({params: {search: selectedFilters['discount']}}).then(response => {
      setDiscountList(
        response.data.result.map(x => ({value: x.discountCode, label: x.discountCode}))
      );
    });
  }, [selectedFilters['discount']]);

  useEffect(() => {
    getOrderTags(selectedFilters['orderTags']).then(response => {
      setOrderTagList(response.data);
    });
  }, [selectedFilters['orderTags']]);

  useEffect(() => {
    getCustomerTags(selectedFilters['customerTags']).then(response => {
      setCustomerTagList(response.data);
    });
  }, [selectedFilters['customerTags']]);

  const setFilters = (key, value) => {
    setSelectedFilters(previousValue => {
      const updatedFilters = {...previousValue};
      updatedFilters[key] = value === undefined ? '' : value;
      return updatedFilters;
    });
  };

  const setAppliedFilters = (key, value) => {
    setFiltersApplied(previousValue => {
      const updatedFilters = {...previousValue};
      updatedFilters[key] = value === undefined ? '' : value;
      return updatedFilters;
    });
  };

  const changeHandler = key => value => {
    setFilters(key, value);
  };

  const deleteHandler = key => {
    setFilters(key, '');
    setAppliedFilters(key, '');
    if (key === 'productPurchased') {
      setSkuList([]);
    }
    if (key === 'discount') {
      setDiscountList([]);
    }
  };

  const handleFiltersClearAll = useCallback(() => {
    setSelectedFilters({});
    setFiltersApplied({});
  }, []);

  const handleSaveButtonClick = () => {
    setFiltersApplied(selectedFilters);
  };

  const handleClick = event => {
    const enterKeyPressed = event.keyCode === 13;
    if (enterKeyPressed) {
      handleSaveButtonClick();
    }
  };

  const changeListFilter = (key, list) => selectedOptions => {
    const selectedValue = selectedOptions[0];
    setSelectedFilters(previousValue => {
      const updatedFilters = {...previousValue};
      updatedFilters[key] = selectedValue === undefined ? '' : selectedValue;
      return updatedFilters;
    });
    if (key === 'productPurchased') {
      const label = list.find(x => x.value === selectedValue);
      const a = {[selectedValue]: label.label};
      setProductLabel(a);
    }
    setAppliedFilters(key, selectedValue);
  };

  const filters = [
    {
      key: 'productPurchased',
      label: 'Product purchased',
      filter: (
        <div onKeyDown={handleClick}>
          <TextField
            placeholder="Search by SKU or product name"
            value={
              productLabel[selectedFilters['productPurchased']] ||
              selectedFilters['productPurchased']
            }
            onChange={changeHandler('productPurchased')}
            autoComplete="off"
          />
          {skuList && skuList.length > 0 && (
            <OptionList
              onChange={changeListFilter('productPurchased', skuList)}
              options={skuList}
              selected={''}
            />
          )}
        </div>
      ),
      shortcut: true,
    },
    {
      key: 'discount',
      label: 'Discount code',
      filter: (
        <div onKeyDown={handleClick}>
          <TextField
            placeholder="Search by discount code"
            value={selectedFilters['discount']}
            onChange={changeHandler('discount')}
            autoComplete="off"
          />
          {discountList && discountList.length > 0 && (
            <OptionList
              onChange={changeListFilter('discount')}
              options={discountList}
              selected={''}
            />
          )}
        </div>
      ),
      shortcut: true,
    },
    {
      key: 'orderTags',
      label: 'Order tags',
      filter: (
        <div onKeyDown={handleClick}>
          <TextField
            placeholder="Search by order tags"
            value={selectedFilters['orderTags']}
            onChange={changeHandler('orderTags')}
            autoComplete="off"
          />
          {orderTagList && orderTagList.length > 0 && (
            <OptionList
              onChange={changeListFilter('orderTags')}
              options={orderTagList}
              selected={''}
            />
          )}
        </div>
      ),
      shortcut: true,
    },
    {
      key: 'customerTags',
      label: 'Customer tags',
      filter: (
        <div onKeyDown={handleClick}>
          <TextField
            placeholder="Search by customer tags"
            value={selectedFilters['customerTags']}
            onChange={changeHandler('customerTags')}
            autoComplete="off"
          />
          {customerTagList && customerTagList.length > 0 && (
            <OptionList
              onChange={changeListFilter('customerTags')}
              options={customerTagList}
              selected={''}
            />
          )}
        </div>
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  Object.keys(filtersApplied).forEach(key => {
    if (filtersApplied[key]) {
      appliedFilters.push({
        key,
        label: disambiguateLabel(key, filtersApplied[key]),
        onRemove: () => deleteHandler(key),
      });
    }
  });
  return (
    <Filters
      filters={filters}
      appliedFilters={appliedFilters}
      onClearAll={handleFiltersClearAll}
      hideQueryField
    >
      <Button onClick={handleSaveButtonClick} size="micro" primarySuccess outline>
        Save Filters
      </Button>
    </Filters>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case 'productPurchased':
        return `Product: ${productLabel[value]}`;
      case 'discount':
        return `Discount code: ${value}`;
      case 'orderTags':
        return `Order is tagged with ${value}`;
      case 'customerTags':
        return `Customer is tagged with ${value}`;
      default:
        return value;
    }
  }
}

export default BasicFilter;
