import axios from './axios';
import config from '../config';

const {BASE_URL} = config;

export async function getProductList(params) {
  try {
    const response = await axios.get(`${BASE_URL}/reports/product-list`, params);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function getVariantList(params) {
  try {
    const response = await axios.get(`${BASE_URL}/reports/variant-list`, params);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function getProductTagList(params) {
  try {
    const response = await axios.get(`${BASE_URL}/reports/product-tag-list`, params);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function getProductCategoryList(params) {
  try {
    const response = await axios.get(`${BASE_URL}/reports/product-categories`, params);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function getdiscountCodeList(params) {
  try {
    const response = await axios.get(`${BASE_URL}/reports/discount-code-list`, params);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function getLocationList(params) {
  try {
    const response = await axios.get(`${BASE_URL}/reports/location-list`, params);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
