import React, {useEffect, useReducer, useState} from 'react';
import {Text, Inline, Box, Grid, Frame, Link, Loading} from '@shopify/polaris';
import ResetPasswordInput from './resetInput';
import ResetPasswordMessage from './message';
import {passwordValidator} from '../../../features/validations';
import {useNavigate, useParams} from 'react-router-dom';
import {AuthAPI} from '../../../api/auth';
import Alert from '../../../components/alert';
import {useLoadingStore} from '../../../app/store';
import * as Sentry from '@sentry/react';
import {track} from '../../../features/track';

const initialState = {
  password: '',
  confirmPassword: '',
};
const reducer = (state, action) => {
  switch (action.type) {
    case 'password':
      return {...state, password: action.value};
    case 'confirmPassword':
      return {...state, confirmPassword: action.value};

    default:
      throw new Error();
  }
};
const ResetPassword = () => {
  const loading = useLoadingStore(state => state.loading);

  const [state, dispatch] = useReducer(reducer, initialState);
  const [alertError, setAlertError] = useState('');
  const [error, setError] = useState('');
  const [resetDone, setResetDone] = useState(false);
  let {token} = useParams();
  const setLoading = useLoadingStore(state => state.setLoading);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    track('Page View', {
      name: 'Reset Password',
    });
  }, []);

  const handleSubmit = async () => {
    setError('');

    if (!passwordValidator(state.password)) {
      setError('Min 8 characters with letters, numbers and special characters');
    } else if (state.password !== state.confirmPassword) {
      setError('Passwords does not match');
    } else {
      const resetPasswordInput = {
        password: state.password,
        token: token,
      };
      setLoading(true);
      try {
        const response = await AuthAPI.resetPassword(resetPasswordInput);
        if (response.status) {
          setLoading(false);
          setAlertError('');
          setResetDone(true);
        } else {
          setLoading(false);

          setAlertError(response.message);
        }
      } catch (err) {
        setLoading(false);
        setAlertError('Something went wrong');
        Sentry.captureException(err);
      }
    }
  };

  useEffect(() => {
    if (state.password && state.confirmPassword) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [state]);

  return (
    <Frame>
      <div className="bg1">
        {loading && <Loading />}
        <Grid>
          <Grid.Cell columnSpan={{xs: 6, sm: 6, md: 6, lg: 6, xl: 6}}>
            <Box minHeight="100vh" background="surface">
              <Inline align="space-between" blockAlign="center">
                <Box padding="8">
                  <img alt="#" width={'180px'} src="/images/logo.svg" />
                </Box>
                <Box padding="8" paddingBlockStart={'10'}>
                  <Inline>
                    <Text alignment="end" fontWeight="semibold">
                      <Link
                        url={'/auth/signin'}
                        onClick={event => {
                          event.preventDefault();
                          navigate('/auth/signin');
                        }}
                      >
                        Already have an retainwise account? Sign in
                      </Link>
                    </Text>
                  </Inline>
                </Box>
              </Inline>

              <>
                <Box paddingBlockStart={'12'}>
                  {resetDone ? (
                    <ResetPasswordMessage />
                  ) : (
                    <>
                      <Text variant="heading3xl" as="h2" alignment="center">
                        Reset Password
                      </Text>
                      <ResetPasswordInput
                        dispatch={dispatch}
                        state={state}
                        handleSubmit={handleSubmit}
                        error={error}
                        disabled={buttonDisabled}
                      />
                    </>
                  )}
                  {alertError && <Alert contents={alertError} init={true} error={true} />}
                </Box>
              </>
            </Box>
          </Grid.Cell>
          <Grid.Cell columnSpan={{xs: 5, sm: 2, md: 2, lg: 5, xl: 6}}>
            <Box minHeight="90vh"></Box>
          </Grid.Cell>
        </Grid>
      </div>
    </Frame>
  );
};

export default ResetPassword;
