import {Spinner} from '@shopify/polaris';
import Card from '../../../../lib/Card';
import Inline from '../../../../lib/Inline';
import React, {useCallback, useEffect, useState} from 'react';
import DefinationPopover from '../../../../components/definationPopover';
import DefaultLineGraph from '../../../../components/graphs/defaultLineGraph';
import {CircleDownMajor, CircleUpMajor} from '@shopify/polaris-icons';
import apiToCall from '../../../../features/apiToCall';
import {convertToOrgTimezone, toClickHouseDate} from '../../../../features/convertDates';
import {Link} from 'react-router-dom';
import DashboardTable from '../dashboardTable';
import CohortTable from '../cohortTable';
import {
  formatAmount,
  formatDate,
  formatDecimalNumber,
  formatNumber,
} from '../../../../features/format';
import {tooltipText} from '../tooltipText';
import useApiCall from '../../../../hooks/useApiCall';
import Box from '../../../../lib/Box';
import Stack from '../../../../lib/Stack';
import Icon from '../../../../lib/Icon';
import Text from '../../../../lib/Text';

const generateItemsPerOrderTotal = data => {
  let totalItems = 0;
  let totalOrders = 0;
  data.forEach(item => {
    totalItems += item.averageItemsPerOrder * +item.numberOfOrders;
    totalOrders += +item.numberOfOrders;
  });
  return totalItems / totalOrders;
};

const generateStats = async (recievedData, slag, setData, dataObj) => {
  if (apiToCall[slag].isSnapshotSpecial) {
    if (
      recievedData.data.length ||
      recievedData.state === 'Error' ||
      recievedData.state === 'Loading'
    ) {
      recievedData.blank = false;
    } else {
      recievedData.blank = true;
    }
    if (recievedData.summary) {
      recievedData.total = recievedData.summary;
    }
  } else if (slag === 'customer-cohort' || slag === 'revenue-cohort') {
    recievedData.blank = false;
  } else if (apiToCall[slag].graph === 'line') {
    if (recievedData.data.length) {
      if (!recievedData.data[0].length && recievedData.state !== 'Error') {
        recievedData.blank = true;
      } else {
        recievedData.blank = false;
      }

      let sum = 0; // seleted data
      let csum = 0; // compare data
      recievedData.data[0].forEach(a => {
        sum += +a[apiToCall[slag].yAxisValue]; //selected data
      });
      recievedData.data[1].forEach(a => {
        csum += +a[apiToCall[slag].yAxisValue]; //compare data
      });

      if (slag === 'items-per-order') {
        sum = generateItemsPerOrderTotal(recievedData.data[0]);
        csum = generateItemsPerOrderTotal(recievedData.data[1]);
      }
      if (
        slag === 'avg-order-value' ||
        slag === 'first-order-value-of-repeat-customers' ||
        slag === 'returns'
      ) {
        sum = sum / recievedData.data[0].length;
        csum = csum / recievedData.data[1].length;
      }
      if (sum >= csum) {
        if (slag === 'returns') {
          recievedData.indicatorColor = 'critical';
        } else {
          recievedData.indicatorColor = 'success';
        }

        let increase = sum - csum;
        let p = (increase / csum) * 100;
        if (csum === 0) {
          p = 0;
        }
        recievedData.indicatorPercentage = p.toFixed(2);
      } else {
        if (slag === 'returns') {
          recievedData.indicatorColor = 'success';
        } else {
          recievedData.indicatorColor = 'critical';
        }
        let decrease = csum - sum;
        let p;
        if (sum === 0) {
          p = 0;
        } else {
          p = (decrease / csum) * 100;
        }
        recievedData.indicatorPercentage = p.toFixed(2);
      }
      recievedData.total = sum;
    } else {
      if (recievedData.state === 'Success') {
        recievedData.blank = true;
      } else {
        recievedData.blank = false;
      }
    }
  } else {
    if (
      recievedData.data.length ||
      recievedData.state === 'Error' ||
      recievedData.state === 'Loading'
    ) {
      recievedData.blank = false;
    } else {
      recievedData.blank = true;
    }
  }
  setData(recievedData);
};

const DashboardCard = ({
  selectedDates,
  selectedCompareDates,
  durationFilter,
  slag,
  dataObj,
  setData,
  error,
}) => {
  const [headerPopoverActive, setHeaderPopoverActive] = useState(false);
  const [midPopoverActive, setMidPopoverActive] = useState(false);
  const headerTogglePopoverActive = useCallback(
    () => setHeaderPopoverActive(headerPopoverActive => !headerPopoverActive),
    []
  );
  const midToggelPopoverActive = useCallback(
    () => setMidPopoverActive(midPopoverActive => !midPopoverActive),
    []
  );
  const activatorHeader = (
    <Box>
      <Text variant="bodyMd">
        <span
          onMouseEnter={headerTogglePopoverActive}
          onMouseLeave={() => {
            setHeaderPopoverActive(false);
          }}
          as="span"
          style={{borderBottom: '2px dotted #c4cdd5'}}
        >
          {apiToCall[slag].isSnapshotSpecial
            ? apiToCall[slag].titleSnapshot
            : apiToCall[slag].title}
        </span>
      </Text>
    </Box>
  );
  const activatorMid = (
    <Box paddingBlockEnd={'1'}>
      <Text variant="bodySm">
        <span
          onMouseEnter={midToggelPopoverActive}
          onMouseLeave={() => {
            setMidPopoverActive(false);
          }}
          style={{borderBottom: '2px dotted #c4cdd5'}}
        >
          {apiToCall[slag].taskText}
        </span>
      </Text>
    </Box>
  );
  const activatorCohort = (
    <Box paddingBlockEnd={'2'}>
      <Text variant="headingSm">
        <span
          onMouseEnter={midToggelPopoverActive}
          onMouseLeave={() => {
            setMidPopoverActive(false);
          }}
          style={{borderBottom: '2px dotted #c4cdd5'}}
        >
          Last 12 months
        </span>
      </Text>
    </Box>
  );

  const fetchData = async () => {
    // if (!initRender) {
    if (slag === 'customer-cohort' || slag === 'revenue-cohort') {
      let currDate = new Date();
      currDate.setDate(1);
      let end = currDate;
      let start = new Date(currDate);

      start.setMonth(start.getMonth() - 11); //11 beacuse, we are including both side of date range

      let params = {
        startDate: convertToOrgTimezone(toClickHouseDate(start, 'start')), //yearBefore,
        endDate: convertToOrgTimezone(toClickHouseDate(end)), //today,

        reportType: slag === 'customer-cohort' ? 'userCohort' : 'revenueCohort',
      };
      let response = await apiToCall[slag].api({params});
      return response.data;
    } else {
      let params = {};
      if (selectedCompareDates.start !== 'Invalid Date') {
        let compareStartDate = convertToOrgTimezone(
          toClickHouseDate(selectedCompareDates.start, 'start')
        );
        let compareEndDate = convertToOrgTimezone(
          toClickHouseDate(selectedCompareDates.end, 'end')
        );
        params = {compareEndDate, compareStartDate};
      }
      params = {
        ...params,
        startDate: convertToOrgTimezone(toClickHouseDate(selectedDates.start, 'start')),
        endDate: convertToOrgTimezone(toClickHouseDate(selectedDates.end, 'end')),
        type: 'graph',
        durationFilter,
        rfmSegment: 'All Customers',
      };
      if (apiToCall[slag].isSnapshotSpecial) {
        if (typeof apiToCall[slag].snapshotSpecialApi === 'function') {
          let response = await apiToCall[slag].snapshotSpecialApi({params});
          return response.data;
        } else {
          let promises = [];
          promises.push(apiToCall[slag].snapshotSpecialApi['data']({params}));
          promises.push(apiToCall[slag].snapshotSpecialApi['summary']({params}));
          let [data, summary] = await Promise.all(promises);
          if (data.data.status && summary.data.status) {
            return {status: true, data: data.data.data, summary: summary.data?.data[0]?.value};
          }
        }
      } else {
        let response = await apiToCall[slag].api({params});
        return response.data;
      }
    }
    // } else {
    //   return {status: true};
    // }
  };

  const dataApiDependencies = [selectedDates, selectedCompareDates, durationFilter];

  const recievedData = useApiCall(fetchData, dataApiDependencies);
  recievedData.selectedDates = selectedDates;
  useEffect(() => {
    generateStats(recievedData, slag, setData, dataObj);
  }, [recievedData]);

  return (
    <>
      <Card>
        <Box padding={'4'}>
          <Stack vertical spacing="tight">
            <Stack.Item>
              <Inline align="space-between">
                <DefinationPopover
                  togglePopoverActive={headerTogglePopoverActive}
                  popoverActive={tooltipText[slag] ? headerPopoverActive : false}
                  activator={activatorHeader}
                  text={tooltipText[slag]}
                />

                <Link
                  className="linkStyle"
                  to={slag === 'customers' || slag === 'products' ? `/${slag}` : `/metrics/${slag}`}
                >
                  View report
                </Link>
              </Inline>
            </Stack.Item>
            {dataObj.blank ? (
              <Stack.Item>
                <Inline align="center">
                  <Text color="subdued">There was no data found for this date range.</Text>
                </Inline>
              </Stack.Item>
            ) : error ? (
              <Stack.Item>
                <Inline align="center">
                  <Text color="subdued">Some error occured</Text>
                </Inline>
              </Stack.Item>
            ) : dataObj.state === 'Error' ? (
              <Stack.Item>
                <Inline align="center">
                  <Text color="subdued">Not able to load data. Please try again later</Text>
                </Inline>
              </Stack.Item>
            ) : (
              <Stack.Item>
                <Stack vertical>
                  {apiToCall[slag].graph === 'line' &&
                    slag !== 'customer-cohort' &&
                    slag !== 'revenue-cohort' &&
                    (typeof apiToCall[slag].snapshotSpecialApi === 'object' ||
                      !apiToCall[slag].isSnapshotSpecial) && (
                      <Stack.Item>
                        <Inline align="space-between">
                          {dataObj.state === 'Success' ? (
                            <Text variant="headingLg">
                              {apiToCall[slag].symbol || apiToCall[slag].summarySymbol
                                ? formatAmount({value: dataObj.total})
                                : slag === 'items-per-order' || slag === 'returns'
                                ? formatDecimalNumber({value: dataObj.total || 0})
                                : formatNumber({value: dataObj.total})}
                              {slag === 'returns' ? '%' : ''}
                            </Text>
                          ) : (
                            ''
                          )}
                          {!!selectedCompareDates.start && (
                            <>
                              {dataObj.state === 'Success' && !apiToCall[slag].isSnapshotSpecial ? (
                                <Inline align="end" gap="1">
                                  <Icon
                                    color={dataObj.indicatorColor}
                                    source={
                                      (dataObj.indicatorColor === 'success' &&
                                        slag !== 'returns') ||
                                      (dataObj.indicatorColor !== 'success' && slag === 'returns')
                                        ? CircleUpMajor
                                        : CircleDownMajor
                                    }
                                  />

                                  <Text color={dataObj.indicatorColor} variant="headingMd">
                                    {formatNumber({value: dataObj.indicatorPercentage})}%
                                  </Text>
                                </Inline>
                              ) : (
                                ''
                              )}
                            </>
                          )}
                        </Inline>
                      </Stack.Item>
                    )}

                  {apiToCall[slag].graph === 'line' &&
                    slag !== 'customer-cohort' &&
                    slag !== 'revenue-cohort' &&
                    !apiToCall[slag].isSnapshotSpecial && (
                      <Stack.Item>
                        <DefinationPopover
                          togglePopoverActive={midToggelPopoverActive}
                          popoverActive={midPopoverActive}
                          activator={activatorMid}
                          text={`This chart shows total ${
                            apiToCall[slag].taskText
                          } from ${formatDate({
                            value: new Date(selectedDates.start),
                            removeTime: true,
                          })} to ${formatDate({
                            value: new Date(selectedDates.end),
                            removeTime: true,
                          })} ${
                            selectedCompareDates.start
                              ? `compared to ${formatDate({
                                  value: new Date(selectedCompareDates.start),
                                  removeTime: true,
                                })} to ${formatDate({
                                  value: new Date(selectedCompareDates.end),
                                  removeTime: true,
                                })}`
                              : ''
                          }.`}
                        />
                      </Stack.Item>
                    )}

                  <Stack.Item>
                    {slag === 'customer-cohort' || slag === 'revenue-cohort' ? (
                      <>
                        {dataObj.state === 'Loading' ? (
                          <Spinner size="small" />
                        ) : (
                          <>
                            <CohortTable data={dataObj.data} />
                            <Box paddingBlockStart={'4'}>
                              <Inline align="space-between" blockAlign="start">
                                <DefinationPopover
                                  togglePopoverActive={midToggelPopoverActive}
                                  popoverActive={midPopoverActive}
                                  activator={activatorCohort}
                                  text={`Changing the time period will not affect this chart. Cohort analysis requires a minimum time period.
                                `}
                                />
                                <Text>
                                  {new Date().toLocaleString('default', {
                                    month: 'long',
                                  })}
                                  ,{new Date().getFullYear() - 1} -{' '}
                                  {new Date().toLocaleString('default', {
                                    month: 'long',
                                  })}
                                  ,{new Date().getFullYear()}
                                </Text>
                              </Inline>
                            </Box>
                          </>
                        )}
                      </>
                    ) : apiToCall[slag].isSnapshotSpecial ? (
                      <>
                        {dataObj.state === 'Loading' ? (
                          <>
                            <Spinner size="small" />
                          </>
                        ) : (
                          <DashboardTable
                            tabData={dataObj.data}
                            param={{name: slag}}
                            symbol={apiToCall[slag].symbol}
                          />
                        )}
                      </>
                    ) : apiToCall[slag].graph === 'line' ? (
                      <>
                        <DefaultLineGraph
                          param={{name: slag}}
                          height={200}
                          data={dataObj.data.length ? dataObj.data : []}
                          startDate={toClickHouseDate(selectedDates.start, 'start')}
                          endDate={toClickHouseDate(selectedDates.end, 'end')}
                          compareStartDate={
                            selectedCompareDates.start !== 'Invalid Date'
                              ? toClickHouseDate(selectedCompareDates.start, 'start')
                              : null
                          }
                          compareEndDate={
                            selectedCompareDates.start !== 'Invalid Date'
                              ? toClickHouseDate(selectedCompareDates.end, 'end')
                              : null
                          }
                          symbol={apiToCall[slag].symbol}
                          durationFilter={durationFilter}
                          state={dataObj.state}
                        />
                      </>
                    ) : (
                      <>
                        {dataObj.state === 'Loading' ? (
                          <Spinner size="small" />
                        ) : (
                          <DashboardTable
                            tabData={dataObj.data}
                            param={{name: slag}}
                            symbol={apiToCall[slag].symbol}
                          />
                        )}
                      </>
                    )}
                  </Stack.Item>
                </Stack>
              </Stack.Item>
            )}
          </Stack>
        </Box>
      </Card>
    </>
  );
};

export default DashboardCard;
