import React, {useEffect} from 'react';
import {
  Modal,
  TextField,
  Checkbox,
  Scrollable,
  FormLayout,
  Select,
  Spinner,
  SkeletonBodyText,
  Layout,
  Link,
  Listbox,
} from '@shopify/polaris';
import Box from '../lib/Box';
import Stack from '../lib/Stack';
import Icon from '../lib/Icon';
import Card from '../lib/Card';
import Text from '../lib/Text';
import TextContainer from '../lib/TextContainer';

import Inline from '../lib/Inline';
import {SearchMajor} from '@shopify/polaris-icons';
import {useState, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {usePreventNavigateStore, useSaveChangesStore} from '../app/store';
import {emailValidator} from '../features/validations';
import {
  addSegmentConnection,
  getConnections,
  getExportStatus,
  updateSegmentConnection,
} from '../api/connection';
import ExportListItem from '../pages/settings/page/connections/exportListItem';
import {ErrorBoundary} from './ErrorBoundary';
import Toggle from './Toggle';
import {formatDate} from '../features/format';
import ExportSyncStatus from './ExportSyncStatus';
import Button from '../lib/Button';

const DeleteModal = ({currentState, deleteContent, onDelete, onClose, extraContent}) => {
  const [active, setActive] = useState(currentState);
  const handleChange = useCallback(() => {
    onClose();
    setActive(!active);
  }, [active]);

  const handleDelete = async () => {
    await onDelete();
    onClose();
    setActive(!active);
  };

  const deleteTitle = `Delete ${deleteContent}`;
  const activator = <div></div>;
  return (
    <>
      <Modal
        activator={activator}
        open={active}
        onClose={handleChange}
        title={deleteTitle}
        primaryAction={{
          content: 'Delete',
          onAction: handleDelete,
          destructive: true,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleChange,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <Text>Do you really want to delete this {deleteContent} ?</Text>
            <Text color="critical" variant="headingMd">
              {extraContent}
            </Text>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </>
  );
};

// Leave Page

const LeavePageModal = ({currentState, toNavigate, onClose}) => {
  const setProtect = usePreventNavigateStore(state => state.setProtect);
  const isUnchanged = useSaveChangesStore(state => state.setIsUnchanged);

  const [active, setActive] = useState(currentState);
  const navigate = useNavigate();
  const handleChange = useCallback(() => {
    onClose(false);
    setActive(!active);
  }, [active]);

  const handleDelete = () => {
    onClose(false);
    setProtect(false);
    isUnchanged(false);
    navigate(toNavigate);
    setActive(!active);
  };

  const deleteTitle = `Leave page with unsaved changes?`;
  const activator = <div></div>;
  return (
    <>
      <Modal
        activator={activator}
        open={active}
        onClose={handleChange}
        title={deleteTitle}
        primaryAction={{
          content: 'Leave Page',
          onAction: handleDelete,
          destructive: true,
        }}
        secondaryActions={[
          {
            content: 'Stay',
            onAction: handleChange,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <Text>Leaving this page will delete all unsaved changes.</Text>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </>
  );
};

const DiscardChangesModal = ({currentState, onClose}) => {
  const setProtect = usePreventNavigateStore(state => state.setProtect);
  const isUnchanged = useSaveChangesStore(state => state.setIsUnchanged);
  const [active, setActive] = useState(currentState);
  const onDiscard = useSaveChangesStore(state => state.onDiscard);
  const handleChange = useCallback(() => {
    onClose(false);
    setActive(!active);
  }, [active]);

  const handleDelete = () => {
    setProtect(false);
    isUnchanged(false);
    onDiscard();
    onClose(false);
    setActive(!active);
  };

  const deleteTitle = `Discard all unsaved changes ?`;
  const activator = <div></div>;
  return (
    <>
      <Modal
        activator={activator}
        open={active}
        onClose={handleChange}
        title={deleteTitle}
        primaryAction={{
          content: 'Discard changes',
          onAction: handleDelete,
          destructive: true,
        }}
        secondaryActions={[
          {
            content: 'Continue editing',
            onAction: handleChange,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <Text>
              If you discard changes, you'll delete any edits you made since you last saved.
            </Text>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </>
  );
};

const AddReportModal = ({
  currentState,
  onClose,
  data,
  addReportsToSnapshot,
  snapshotData,
  state,
}) => {
  const [reports, setReports] = useState([]);
  const [active, setActive] = useState(currentState);
  const [currentReportState, setCurrentReportState] = useState('Loading');
  const handleChange = useCallback(() => {
    onClose(false);
    setActive(!active);
  }, [active]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleCheckBox = (category, index) => {
    reports.forEach(r => {
      if (r._id === category) {
        r.reports[index].selected = !r.reports[index].selected;
      }
    });
    setReports([...reports]);
  };

  const [isEmptyState, setIsEmptyState] = useState(false);

  const [search, setSearch] = useState('');

  const handleDelete = async () => {
    let d = [];
    reports.forEach(r => {
      r.reports.forEach(t => {
        if (t.selected && !t.disabled) {
          d.push({slag: t.slag});
        }
      });
    });
    await addReportsToSnapshot(d);
    onClose(false);
    setActive(!active);
  };

  useEffect(() => {
    let filteredReports = data.map(r => {
      return {
        ...r,
        reports: r.reports.filter(t => {
          return t.reportName.toLowerCase().includes(search);
        }),
      };
    });
    let flag = false;
    filteredReports.forEach(r => {
      if (r.reports.length) {
        flag = true;
      }
    });
    setIsEmptyState(!flag);
    setReports([...filteredReports]);
  }, [search]);

  useEffect(() => {
    let c1 = snapshotData.c1.map(a => a.slag);
    let c2 = snapshotData.c2.map(a => a.slag);
    let c3 = snapshotData.c3.map(a => a.slag);
    data.forEach(category => {
      category.reports.forEach(report => {
        if (c1.includes(report.slag) || c2.includes(report.slag) || c3.includes(report.slag)) {
          report.selected = true;
          report.disabled = true;
        } else {
          report.selected = false;
          report.disabled = false;
        }
      });
    });
    setReports([...data]);
    setCurrentReportState(state);
  }, [data, snapshotData, state]);

  useEffect(() => {
    let flag = true;
    reports.forEach(r => {
      r.reports.forEach(a => {
        if (a.selected === true && !a.disabled) {
          flag = false;
        }
      });
    });

    setButtonDisabled(flag);
  }, [reports]);
  const activator = <div></div>;
  return (
    <>
      <Modal
        activator={activator}
        open={active}
        onClose={handleChange}
        title={'Add reports to dashboard'}
        primaryAction={{
          content: 'Add',
          onAction: handleDelete,
          disabled: buttonDisabled,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleChange,
          },
        ]}
      >
        <Modal.Section>
          <Stack vertical>
            <Stack.Item>
              <TextField
                onChange={setSearch}
                value={search}
                prefix={<Icon source={SearchMajor} />}
                placeholder="Search across reports"
              />
            </Stack.Item>
            <Stack.Item>
              <Scrollable shadow style={{height: '400px'}}>
                {currentReportState === 'Loading' ? (
                  <SkeletonBodyText />
                ) : currentReportState === 'Error' ? (
                  <Text alignment="center" variant="headingMd">
                    Not able to load data. please try again
                  </Text>
                ) : !isEmptyState ? (
                  reports.map(r => {
                    return (
                      <>
                        {r.reports && r.reports.length ? (
                          <Box key={r._id} paddingBlockStart={'3'} paddingBlockEnd={'3'}>
                            <Stack vertical>
                              <Stack.Item>
                                <Text variant="headingLg">{r._id && r._id}</Text>
                              </Stack.Item>
                              <Stack.Item>
                                <Stack vertical>
                                  {r.reports.map((t, index) => {
                                    if (t.slag !== 'orders') {
                                      return (
                                        <Checkbox
                                          key={t.slag}
                                          onChange={() => {
                                            handleCheckBox(r._id, index);
                                          }}
                                          checked={t.selected}
                                          label={t.reportName}
                                          disabled={t.disabled}
                                        />
                                      );
                                    }
                                    return <></>;
                                  })}
                                </Stack>
                              </Stack.Item>
                            </Stack>
                          </Box>
                        ) : (
                          <></>
                        )}
                      </>
                    );
                  })
                ) : (
                  <Text alignment="center" variant="headingMd">
                    No report found
                  </Text>
                )}
              </Scrollable>
            </Stack.Item>
          </Stack>
        </Modal.Section>
      </Modal>
    </>
  );
};
const ExportConfirmModal = ({
  currentState,
  confirmContent,
  onConfirm,
  onClose,
  type,
  setConnectionId,
}) => {
  const [active, setActive] = useState(currentState);
  const handleChange = useCallback(() => {
    onClose();
    setActive(!active);
    if (type === 'confirm') {
      setConnectionId('');
    }
  }, [active]);

  const handleConfirm = async () => {
    await onConfirm();
    onClose();
    setActive(!active);
  };

  const title = `Confirm Action`;
  const activator = <div></div>;
  return (
    <>
      <Modal
        activator={activator}
        open={active}
        onClose={handleChange}
        title={title}
        primaryAction={{
          content: 'Confirm',
          onAction: handleConfirm,
          destructive: true,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleChange,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            {type === 'confirm' ? (
              <Text>Do you really want to export this {confirmContent} ?</Text>
            ) : (
              <Text>This action will pause customer export for this segment. Are you sure ?</Text>
            )}
          </TextContainer>
        </Modal.Section>
      </Modal>
    </>
  );
};
const ExportModal = ({currentState, onClose, segmentId, totalCustomers}) => {
  const activeExportConnections = ['klaviyo', 'mailchimp'];
  const [active, setActive] = useState(currentState);
  const [connections, setConnections] = useState([]);
  const [confirmModalState, setConfirmModalState] = useState(false);
  const [connectionId, setConnectionId] = useState('');
  const [confirmType, setConfirmType] = useState('');
  const [connectionStatus, setConnectionStatus] = useState(false);
  const [exportStatus, setExportStatus] = useState([]);
  // let interval;
  const handleChange = useCallback(() => {
    onClose(false);
    setActive(!active);
    // if (interval) {
    //   clearInterval(interval);
    // }
  }, [active]);

  const handleConfirmation = async () => {
    setConfirmModalState(false);
    if (confirmType === 'confirm') {
      let data = {segmentId, connectionId};

      addSegmentConnection(data).then(res => {
        if (res.status === true) {
          // console.log(res.data);
          //show toast
        }
      });
    } else {
      updateSegmentConnection({segmentId, connectionId, data: {isActive: false}});
      setConnections(
        connections.map(connection => {
          if (connection._id === connectionId) {
            connection.isAdded = false;
          }
          return connection;
        })
      );
      setConnectionId('');
    }
  };
  const handleConfirmModalClose = () => {
    setConfirmModalState(false);
  };
  useEffect(() => {
    let params = {params: {id: segmentId}};
    getConnections(params).then(res => {
      setConnectionStatus(res.status);
      if (res.status === true) {
        setConnections(
          res.data.filter(connection => activeExportConnections.includes(connection.type))
        );
      }
    });
  }, []);

  useEffect(() => {
    if (connections.length > 0) {
      const fetch = (connection, interval, count = 0) => {
        getExportStatus({
          params: {connectionId: connection._id, segmentId},
        })
          .then(res => {
            setExportStatus(prev => {
              console.log(res.data);
              console.log(count);
              if (count > 2 && !res.data) {
                clearInterval(interval);
                return [...prev];
              }
              let dataMap = prev.reduce((prev, curr, index) => {
                return {...prev, [curr._id]: index};
              }, {});
              console.log(dataMap, dataMap[res.data?._id]);
              let statusData = [...prev];
              if (res.data) {
                if (dataMap[res.data._id] !== undefined) {
                  statusData[dataMap[res.data._id]] = res.data;
                } else {
                  statusData = [...statusData, res.data];
                }
              }
              return statusData;
            });
            if (
              res.data?.totalCustomers === Number(totalCustomers) &&
              res.data?.syncedCustomers === res.data?.validCustomers &&
              interval
            ) {
              clearInterval(interval);
            }
          })
          .catch(err => {
            if (interval) {
              clearInterval(interval);
            }
          });
      };
      for (let connection of connections) {
        if (connection.isAdded) {
          fetch(connection);
          let count = 0;
          let interval = setInterval(() => {
            fetch(connection, interval, count++); // fetch data every 10sec
          }, 10 * 1000);
          // return clearInterval(interval);
        }
      }
    }
  }, [connections]);
  console.log(exportStatus);
  const title = `Export to connections`;
  const activator = <div></div>;

  return !confirmModalState ? (
    <>
      <ErrorBoundary
        fallback={
          <Card>
            <Card.Section>
              <div>An error has occured</div>
            </Card.Section>
          </Card>
        }
      >
        <Modal
          activator={activator}
          open={active}
          onClose={handleChange}
          title={title}
          footer={
            <Box>
              Go to{' '}
              <Link removeUnderline={true} url="../settings">
                Settings
              </Link>{' '}
              to add new connection.
            </Box>
          }
        >
          <Modal.Section>
            {connectionStatus ? (
              connections.length ? (
                <Layout>
                  {connections.map((connection, i) => (
                    <Layout.Section key={i}>
                      <Card>
                        <div
                          style={{
                            minHeight: '3.25rem',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'column',
                          }}
                        >
                          <div style={{padding: '0.5rem 0 0 0.5rem'}}>
                            <ExportListItem
                              data={connection}
                              setConnectionId={setConnectionId}
                              setConfirmModalState={setConfirmModalState}
                              connectionId={connectionId}
                              setConfirmType={setConfirmType}
                            />
                          </div>
                          {exportStatus.length > 0 && exportStatus[i] && connection.isAdded ? (
                            <div style={{padding: '0.5rem'}}>
                              <ExportSyncStatus
                                totalCustomers={exportStatus[i].totalCustomers}
                                customersWithoutContactInfo={
                                  exportStatus[i].totalCustomers - exportStatus[i].validCustomers
                                }
                                validCustomers={exportStatus[i].validCustomers}
                                failedCustomers={exportStatus[i].failedCustomers}
                                failedLogs={exportStatus[i].failedLogs}
                                syncedCustomers={exportStatus[i].syncedCustomers}
                              />
                            </div>
                          ) : (
                            // :
                            // connection.isAdded || connectionId === connection._id ? (
                            //   <div
                            //     style={{
                            //       height: '0.75rem',
                            //       width: '100%',
                            //       animation: 'blinking 1.5s infinite',
                            //       cursor: 'pointer',
                            //       borderRadius: '0 0 0.5rem 0.5rem',
                            //     }}
                            //     onClick={() => {
                            //       setCheckingStatusIndex(i);
                            //       if (interval) {
                            //         clearInterval(interval);
                            //       }
                            //     }}
                            //   ></div>
                            // )
                            ''
                          )}
                        </div>
                      </Card>
                    </Layout.Section>
                  ))}
                </Layout>
              ) : (
                <Layout>
                  <Layout.Section>
                    <Text fontWeight="semibold">You dont have any active connections.</Text>
                  </Layout.Section>
                </Layout>
              )
            ) : (
              <Layout>
                <Layout.Section>
                  <SkeletonBodyText lines={2} />
                </Layout.Section>
                <Layout.Section>
                  <SkeletonBodyText lines={2} />
                </Layout.Section>
              </Layout>
            )}
          </Modal.Section>
        </Modal>
      </ErrorBoundary>
    </>
  ) : (
    <ExportConfirmModal
      currentState={confirmModalState}
      confirmContent={'segment'}
      onConfirm={handleConfirmation}
      onClose={handleConfirmModalClose}
      type={confirmType}
      setConnectionId={setConnectionId}
    />
  );
};

const connectionName = {
  // klaviyo: 'Klaviyo',
  twilio: 'Twilio',
  // facebookAds: 'Facebook Ads',
};

const CreateConnectionModal = ({currentState, onClose, type, onConnect, data}) => {
  const [active, setActive] = useState(currentState);
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [title, setTitle] = useState(data?.title ? data.title : '');
  const [apiKey, setApiKey] = useState(data?.apiKey ? data.apiKey : '');
  const [accountSID, setAccountSID] = useState(data?.accountSID || '');
  const [keySecret, setKeySecret] = useState(data?.keySecret ? data.keySecret : '');
  const [senderNumber, setSenderNumber] = useState(data?.senderNumber ? data.senderNumber : '');
  const [openInstructions, setOpenInstructions] = useState(false);
  // const [accountId, setAccountId] = useState(data?.accountId ? data?.accountId : '');
  // const [showApiKey, setShowApiKey] = useState(false);

  // const togglePasswordVisibility = () => {
  //   setShowApiKey(!showApiKey);
  // };
  const oAuthEnabledConnectionTypes = ['mailchimp', 'facebookAds', 'klaviyo'];

  const handleChange = useCallback(() => {
    onClose(false);
    setActive(!active);
  }, [active]);
  const connectionType = connectionName[type];
  const handleConnect = async () => {
    setLoading(true);
    let res = await onConnect({apiKey, title, type, keySecret, senderNumber, accountSID});
    if (res.status) {
      setLoading(false);
      onClose(false);
      setActive(!active);
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (type === 'twilio') {
      if (title && apiKey && accountSID && keySecret && senderNumber) {
        setButtonDisabled(false);
      } else {
        setButtonDisabled(true);
      }
    } else if (title && apiKey) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
    // setButtonDisabled(!(title && apiKey && (type !== 'facebookAds' || accountId)));
  }, [title, apiKey, senderNumber, keySecret, accountSID]);

  const modalTitle = `${data ? 'Edit' : 'Create'} ${connectionType} connection`;
  const activator = <div></div>;
  const KlaviyoApiKeyInstructions = () => {
    return (
      <Modal.Section>
        <Layout>
          <Layout.Section>
            <Card title="Get Klaviyo API Key">
              <Card.Section>
                To integrate Klaviyo with Retainwise, you need to generate a private API key. Follow
                the steps below:
              </Card.Section>

              <Card.Section>
                <strong>Step 1:</strong> Visit the Klaviyo API Key creation page by clicking{' '}
                <Link url={'https://www.klaviyo.com/create-private-api-key'} external>
                  here
                </Link>
                .
              </Card.Section>

              <Card.Section>
                <strong>Step 2:</strong> In the "Private API Key Name" field, enter "Retainwise".
              </Card.Section>
              <Card.Section>
                <strong>Step 3:</strong> For the "API Key Scope," select{' '}
                <strong>"Grant applications full access for every API scope"</strong> to allow
                Retainwise to add profiles, create lists, and perform other necessary actions.
                <em>
                  Note: We require Full scope access to manage profiles and lists. Read-only scope
                  does not provide the necessary permissions.
                </em>
              </Card.Section>
              <Card.Section>
                <strong>Step 4:</strong> Click the "Create" button to generate the API key.
              </Card.Section>

              <Card.Section>
                <strong>Important:</strong> Copy the generated API key. You'll need to provide this
                key when configuring Klaviyo integration in Retainwise.
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout>
      </Modal.Section>
    );
  };

  const TwilioApiKeyInstructions = () => {
    return (
      <Modal.Section>
        <Layout>
          <Layout.Section>
            <Card title="Get Twilio API Key">
              <Card.Section>
                To integrate Twilio with Retainwise. Follow the steps below:
              </Card.Section>

              <Card.Section>
                <strong>Step 1:</strong> Sign-in to your Twilio account and copy Account SID by
                clicking{' '}
                <Link
                  url={'https://console.twilio.com/us1/account/manage-account/general-settings'}
                  external
                >
                  here
                </Link>
                .
              </Card.Section>
              <Card.Section>
                <strong>Step 2:</strong> Now create an API key by clicking{' '}
                <Link
                  url={'https://console.twilio.com/us1/account/keys-credentials/api-keys'}
                  external
                >
                  here
                </Link>
                .
              </Card.Section>

              <Card.Section>
                <strong>Step 3:</strong> Keep the region as default and click on "Create API key"
                button.
              </Card.Section>
              <Card.Section>
                <strong>Step 4:</strong> Name this API key as retainwise and keep the key type as
                standard.
              </Card.Section>
              <Card.Section>
                <strong>Step 5:</strong> Click on "Create" button to create this API key.
              </Card.Section>
              <Card.Section>
                <strong>Important:</strong> Copy both SID and key Secret. You'll need to provide
                these keys when configuring Twilio integration in Retainwise.
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout>
      </Modal.Section>
    );
  };

  const instructionMap = {
    klaviyo: <KlaviyoApiKeyInstructions />,
    twilio: <TwilioApiKeyInstructions />,
  };
  return (
    <>
      <Modal
        activator={activator}
        open={active}
        onClose={handleChange}
        title={modalTitle}
        primaryAction={{
          content: loading ? <Spinner size="small" /> : `${data ? 'Update' : 'Connect'}`,
          onAction: handleConnect,
          disabled: buttonDisabled,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleChange,
          },
        ]}
        footer={
          !oAuthEnabledConnectionTypes.includes(type) ? (
            <Layout>
              <Layout.Section>
                <Button plain onClick={() => setOpenInstructions(true)}>
                  <Text color="subdued">{`How to get API key?`}</Text>
                </Button>
              </Layout.Section>
            </Layout>
          ) : (
            ''
          )
        }
      >
        {openInstructions && type && instructionMap[type]}
        <Modal.Section>
          <FormLayout>
            <TextField
              value={title}
              onChange={setTitle}
              label={`Name`}
              placeholder={`A helpful name to label this connection`}
            />

            {!oAuthEnabledConnectionTypes.includes(type) ? (
              type === 'klaviyo' ? (
                <TextField
                  value={apiKey} //{showApiKey ? apiKey : '*'.repeat(apiKey.length)}
                  onChange={setApiKey}
                  label={`${connectionType} API key`}
                  placeholder={`${connectionType} API key`}
                />
              ) : type === 'twilio' ? (
                <div style={{marginTop: '30px'}}>
                  <FormLayout>
                    <TextField
                      value={accountSID} //{showApiKey ? apiKey : '*'.repeat(apiKey.length)}
                      onChange={setAccountSID}
                      label={`${connectionType} Account SID`}
                      placeholder={`${connectionType} Account SID`}
                    />
                    <TextField
                      value={apiKey} //{showApiKey ? apiKey : '*'.repeat(apiKey.length)}
                      onChange={setApiKey}
                      label={`${connectionType} API key`}
                      placeholder={`${connectionType} Api key`}
                    />
                    <TextField
                      value={keySecret} //{showApiKey ? apiKey : '*'.repeat(apiKey.length)}
                      onChange={setKeySecret}
                      label={`${connectionType} Key secret`}
                      placeholder={`${connectionType} Key secret`}
                    />
                    <TextField
                      value={senderNumber} //{showApiKey ? apiKey : '*'.repeat(apiKey.length)}
                      onChange={setSenderNumber}
                      label={`${connectionType} registered whatsapp phone number`}
                      placeholder={`${connectionType} registered whatsapp phone number`}
                    />
                  </FormLayout>
                </div>
              ) : (
                ''
              )
            ) : (
              ''
            )}
          </FormLayout>
        </Modal.Section>
      </Modal>
    </>
  );
};

const ChooseFacebookAdAccountsModal = ({currentState, onClose, connection, onUpdate}) => {
  const [active, setActive] = useState(currentState);
  const [title, setTitle] = useState(connection.title);
  const activator = <div></div>;
  const handleChange = useCallback(() => {
    onClose(false);
    setActive(!active);
  }, [active]);
  const handleAdd = async () => {
    let updatedConnection = {...connection};
    updatedConnection.data.adAccounts.forEach(adAccount => {
      if (selected.includes(adAccount.id)) {
        adAccount.isSelected = true;
      } else {
        adAccount.isSelected = false;
      }
    });
    updatedConnection.title = title;
    onUpdate(updatedConnection);
    onClose(false);
  };
  const [selected, setSelected] = useState(
    connection.data.adAccounts.filter(x => x.isSelected).map(x => x.id)
  );

  const handleChoice = value => {
    if (!selected.includes(value)) {
      setSelected([...selected, value]);
    } else {
      setSelected(selected.filter(x => x !== value));
    }
  };
  const modalTitle = `Update facebook ads connection`;
  const choices =
    connection?.data.adAccounts.map(x => ({
      label: x.name,
      value: x.id,
      helpText: <Box>{x.id}</Box>,
      id: x.id,
      timezone: x.timezone_name,
      createdAt: x.created_time,
    })) || [];

  return (
    <Modal
      activator={activator}
      open={active}
      onClose={handleChange}
      title={modalTitle}
      primaryAction={{
        content: 'Update',
        onAction: handleAdd,
        disabled: false,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: handleChange,
        },
      ]}
    >
      <Modal.Section>
        <Layout>
          <Layout.Section>
            <FormLayout>
              <TextField
                value={title}
                onChange={setTitle}
                label={`Name`}
                placeholder={`A helpful name to label this connection`}
              />
            </FormLayout>
          </Layout.Section>
          <Layout.Section>
            <Text>Choose ad account/s to connect</Text>
          </Layout.Section>
          {choices.map((choice, i) => (
            <Layout.Section key={i}>
              <Inline blockAlign="start">
                <Toggle
                  checked={selected.includes(choice.value)}
                  onChange={() => handleChoice(choice.value)}
                  icons={{unchecked: null}}
                />

                <Listbox>
                  <Listbox.Option value="UniqueValue-1">
                    <Inline>
                      <Stack vertical={true} spacing="none">
                        <Text fontWeight="semibold">ID</Text>
                        <Text fontWeight="semibold">Name</Text>
                        <Text fontWeight="semibold">Created at</Text>
                        <Text fontWeight="semibold">Timezone</Text>
                      </Stack>
                      <Stack vertical={true} spacing="none">
                        <Text>{choice.id}</Text>
                        <Text>{choice.label}</Text>
                        <Text>{formatDate({value: new Date(choice.createdAt)})}</Text>
                        <Text>{choice.timezone}</Text>
                      </Stack>
                    </Inline>
                  </Listbox.Option>
                </Listbox>
              </Inline>
            </Layout.Section>
          ))}
        </Layout>
      </Modal.Section>
    </Modal>
  );
};
const AddTeamMemberModal = ({currentState, onClose}) => {
  const [active, setActive] = useState(currentState);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [lengthError, setLengthError] = useState({});
  const handleChange = useCallback(() => {
    onClose(false);
    setActive(!active);
  }, [active]);
  const [selected, setSelected] = useState('member');
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const handleSelectChange = useCallback(value => setSelected(value), []);

  const options = [
    {label: 'Member - View only access', value: 'member'},
    {label: 'Admin', value: 'admin'},
  ];

  const deleteTitle = `Invite team member`;
  const activator = <div></div>;
  const handleSave = () => {
    if (!emailValidator(email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
      onClose(false);
      setActive(!active);
    }
  };
  useEffect(() => {
    if (name && email) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [email, name]);
  return (
    <>
      <Modal
        activator={activator}
        open={active}
        onClose={handleChange}
        title={deleteTitle}
        primaryAction={{
          content: 'Add',
          onAction: handleSave,
          disabled: buttonDisabled,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleChange,
          },
        ]}
      >
        <Modal.Section>
          <Inline>
            <Box width="100%">
              <FormLayout>
                <TextField
                  value={name}
                  onChange={e => {
                    if (e.length < 150) {
                      setName(e);
                      lengthError.name = false;
                      setLengthError(lengthError);
                    } else if (e.length === 150) {
                      setName(e);
                      lengthError.name = true;
                      setLengthError(lengthError);
                    }
                  }}
                  error={lengthError.name && 'Max length of 150 characters reached'}
                  label="Name"
                  placeholder="Team member name"
                />
                <TextField
                  value={email}
                  onChange={e => {
                    if (e.length < 150) {
                      setEmail(e);
                      lengthError.email = false;
                      setLengthError(lengthError);
                    } else if (e.length === 150) {
                      setEmail(e);
                      lengthError.email = true;
                      setLengthError(lengthError);
                    }
                  }}
                  label="Email"
                  placeholder="example@example.com"
                  error={
                    emailError
                      ? 'Enter a valid email'
                      : lengthError.email
                      ? 'Max length of 150 characters reached'
                      : ''
                  }
                />
                <Select
                  label="Role"
                  options={options}
                  onChange={handleSelectChange}
                  value={selected}
                />
              </FormLayout>
            </Box>
          </Inline>
        </Modal.Section>
      </Modal>
    </>
  );
};

const ClearModal = ({currentState, clearContent, onClear, onClose}) => {
  const [active, setActive] = useState(currentState);
  const handleChange = useCallback(() => {
    onClose();
    setActive(!active);
  }, [active]);

  const handleClear = async () => {
    await onClear();
    onClose();
    setActive(!active);
  };

  const clearTitle = `Clear ${clearContent}`;
  const activator = <div></div>;
  return (
    <>
      <Modal
        activator={activator}
        open={active}
        onClose={handleChange}
        title={clearTitle}
        primaryAction={{
          content: 'Clear',
          onAction: handleClear,
          destructive: true,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleChange,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <Text>Are you really want to clear {clearContent.toLowerCase()} ?</Text>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </>
  );
};

export {
  DeleteModal,
  LeavePageModal,
  DiscardChangesModal,
  AddReportModal,
  ExportModal,
  CreateConnectionModal,
  AddTeamMemberModal,
  ClearModal,
  ExportConfirmModal,
  ChooseFacebookAdAccountsModal,
};
