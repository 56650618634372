import React, {useState} from 'react';
import DefaultTable from '../../../../components/defaultIndexTable';
import {useParams} from 'react-router-dom';
import {ErrorBoundary} from '../../../../components/ErrorBoundary';
import apiToCall from '../../../../features/apiToCall';
import useApiCall from '../../../../hooks/useApiCall';
import Card from '../../../../lib/Card';
const Customers = () => {
  const [sortBy, setSortBy] = useState('LTV');
  const [sortOrder, setSortOrder] = useState('desc');
  const [queryValue, setQueryValue] = useState('');
  const [page, setPage] = useState(1);

  const params = useParams();
  const fetchCustomers = async () => {
    let _params = {
      variantId: params.variantId,
      sortBy,
      sortOrder,
      page,
      search: queryValue,
      type: 'table',
    };
    let res = await apiToCall['customers'].api({params: _params});
    return res.data;
  };
  const customersApiDependencies = [page, sortBy, sortOrder, queryValue];
  const customers = useApiCall(fetchCustomers, customersApiDependencies);
  return (
    <>
      <ErrorBoundary
        fallback={
          <Card>
            <Card.Section>
              <div>An error has occured</div>
            </Card.Section>
          </Card>
        }
      >
        <DefaultTable
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortExceptions={[...apiToCall['customers'].sortExecptions]}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          select={false}
          queryValue={queryValue}
          setQueryValue={setQueryValue}
          page={page}
          setPage={setPage}
          resourceName={{
            singular: 'metrics',
            plural: 'metrics',
          }}
          columns={apiToCall['customers'].columns}
          selectedFilters={[]}
          totalRows={customers.data.length > 1 ? +customers.data[0].totalCount : 0}
          tableData={customers.data.length > 1 ? customers.data[1] : []}
          state={customers.state}
          param={{name: 'customers'}}
          queryPlaceholder="Filter name , email or mobile number of customer"
        />
      </ErrorBoundary>
    </>
  );
};

export default Customers;
