import React, {useRef} from 'react';
import JoditEditor from 'jodit-react';
import './style.css';
const RichTextEditor = ({placeholder, template, setTemplate}) => {
  const editorRef = useRef(null);
  const config = {
    readonly: false,
    iframe: true,
    editHTMLDocumentMode: true,
    placeholder: placeholder || 'Start typings...',

    extraButtons: [
      {
        name: 'Insert variable',
        list: {
          customerName: '{{customerName}}',
          orderName: '{{orderName}}',
          firstProductTitle: '{{productTitle}}',
          productTitles: '{{productTitles}}',
          productImages: '{{productImages}}',
          orderDate: '{{orderDate}}',
        },
        childTemplate: (editor, key, value) => {
          return `<span>${key}</span>`;
        },
        exec: function (editor, t, {control}) {
          editor.selection.insertHTML(control.text);
        },
      },
    ],
    removeButtons: [
      'source',
      'media',
      'preview',
      'about',
      'print',
      'find',
      'speechRecognize',
      'video',
      'image',
      'file',
      'superscript',
      'subscript',
    ],
  };

  return (
    <JoditEditor
      ref={editorRef}
      value={template}
      config={config}
      tabIndex={1} // tabIndex of textarea
      onBlur={newContent => {
        setTemplate(newContent);
      }} // preferred to use only this option to update the content for performance reasons
    />
  );
};
export default RichTextEditor;
