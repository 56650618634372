import {Button, Popover, OptionList} from '@shopify/polaris';
import {useState, useCallback} from 'react';
import {LocalStorageService} from '../service/localStorage';
const YearSelectComponent = ({setDurationFilter, durationFilter, setPage, setQueryValue}) => {
  const [popoverActive, setPopoverActive] = useState(false);
  const togglePopoverActive = useCallback(
    () => setPopoverActive(popoverActive => !popoverActive),
    []
  );
  const userDetails = LocalStorageService.getUserOrg();
  const startYear = userDetails.storeCreatedAt;

  const getYearOptions = () => {
    const startDate = new Date(startYear);
    const currentYear = new Date().getFullYear();
    const years = [];

    for (let year = startDate.getFullYear(); year <= currentYear; year++) {
      years.push({label: year.toString(), value: year.toString(), active: false});
    }

    return years;
  };

  const activator = (
    <Button onClick={togglePopoverActive} disclosure>
      {durationFilter}
    </Button>
  );
  return (
    <Popover active={popoverActive} activator={activator} onClose={togglePopoverActive}>
      <OptionList
        selected={durationFilter}
        options={getYearOptions()}
        onChange={value => {
          setDurationFilter(value[0]);
          if (setPage) {
            setPage(1);
            setQueryValue(null);
          }

          togglePopoverActive();
        }}
      />
    </Popover>
  );
};
export default YearSelectComponent;
