import {TextField, Text, Icon} from '@shopify/polaris';
import Box from '../../../lib/Box';
import Inline from '../../../lib/Inline';
import React, {useEffect} from 'react';
import {useReducer} from 'react';
import {useState} from 'react';
import Page from '../../../components/page';
import Property from '../components/property';
import {CancelMajor} from '@shopify/polaris-icons';
import Operator from '../components/operator';
import {options, segments, timeSelection} from './options';
import {MultiTextValue, ValueOption} from '../components/value';
import DefaultDatePicker from '../components/defaultDatePicker';
import TimeSelector from '../components/timeSelector';
import {usePreventNavigateStore, useSaveChangesStore} from '../../../app/store';
import {useNavigate} from 'react-router-dom';
import useUnsavedChangesWarning from '../../../hooks/useUnsavedChangesWarning';
import DefaultMultiDatePicker from '../components/defaultMultDatePicker';
import {useRef} from 'react';
import {LeavePageModal} from '../../../components/modal';
import {createSegment, getSegmentList, updateSegment} from '../../../api/segments';
import Alert from '../../../components/alert';
import config from '../../../config';
import {ErrorBoundary} from '../../../components/ErrorBoundary';
import {track} from '../../../features/track';
import FilterDropDown from '../../../components/FilterDropdown';
import {
  getLocationList,
  getProductCategoryList,
  getVariantList,
  getdiscountCodeList,
} from '../../../api/filterLists';
import FilterDropDownMultiSelect from '../../../components/FilterDropDownMultiSelect';

import Card from '../../../lib/Card';
import Button from '../../../lib/Button';
const init = {
  name: '',
  description: '',
  segments: {
    type: 'group',
    operator: 'and',
    members: [
      {
        type: 'group',
        operator: 'or',
        members: [
          {
            type: 'rule',
            property: '',
            operator: '',
            value: null,
            timeSelector: '',
            days: '',
            date1: '',
            date2: '',
          },
        ],
      },
    ],
  },
};
const reducer = (state, action) => {
  switch (action.type) {
    case 'name':
      return {...state, name: action.value};
    case 'description':
      return {...state, description: action.value};
    case 'property':
      state.segments.members[action.indexes[0]].members[action.indexes[1]].property = action.value;
      state.segments.members[action.indexes[0]].members[action.indexes[1]].operator = '';
      state.segments.members[action.indexes[0]].members[action.indexes[1]].value = null;
      state.segments.members[action.indexes[0]].members[action.indexes[1]].timeSelector = '';
      state.segments.members[action.indexes[0]].members[action.indexes[1]].days = '';
      state.segments.members[action.indexes[0]].members[action.indexes[1]].date1 = '';
      return {...state, segments: {...state.segments}};
    case 'operator':
      state.segments.members[action.indexes[0]].members[action.indexes[1]].operator = action.value;
      state.segments.members[action.indexes[0]].members[action.indexes[1]].value = null;
      state.segments.members[action.indexes[0]].members[action.indexes[1]].timeSelector = '';
      state.segments.members[action.indexes[0]].members[action.indexes[1]].days = '';
      state.segments.members[action.indexes[0]].members[action.indexes[1]].date1 = '';
      return {...state, segments: {...state.segments}};
    case 'value':
      state.segments.members[action.indexes[0]].members[action.indexes[1]].value = action.value;
      state.segments.members[action.indexes[0]].members[action.indexes[1]].timeSelector = '';
      state.segments.members[action.indexes[0]].members[action.indexes[1]].days = '';
      state.segments.members[action.indexes[0]].members[action.indexes[1]].date1 = '';
      return {...state, segments: {...state.segments}};
    case 'timeSelector':
      state.segments.members[action.indexes[0]].members[action.indexes[1]].timeSelector =
        action.value;
      state.segments.members[action.indexes[0]].members[action.indexes[1]].days = '';
      state.segments.members[action.indexes[0]].members[action.indexes[1]].date1 = '';
      state.segments.members[action.indexes[0]].members[action.indexes[1]].date2 = '';
      return {...state, segments: {...state.segments}};
    case 'days':
      state.segments.members[action.indexes[0]].members[action.indexes[1]].days = action.value;
      return {...state, segments: {...state.segments}};
    case 'date1':
      state.segments.members[action.indexes[0]].members[action.indexes[1]].date1 = action.value;
      return {...state, segments: {...state.segments}};
    case 'date2':
      state.segments.members[action.indexes[0]].members[action.indexes[1]].date2 = action.value;
      return {...state, segments: {...state.segments}};
    case 'or':
      state.segments.members[action.value].members.push({
        type: 'rule',
        property: '',
        operator: '',
        value: null,
        timeSelector: '',
        days: '',
        date1: '',
        date2: '',
      });
      return {...state, segments: {...state.segments}};
    case 'and':
      state.segments.members.splice(action.value + 1, 0, {
        type: 'group',
        operator: 'or',
        members: [
          {
            type: 'rule',
            property: '',
            operator: '',
            value: null,
            timeSelector: '',
            days: '',
            date1: '',
            date2: '',
          },
        ],
      });
      return {
        ...state,
        segments: {...state.segments},
      };
    case 'delete':
      if (state.segments.members[action.parentValue].members.length === 1) {
        state.segments.members.splice(action.parentValue, 1);
      } else {
        state.segments.members[action.parentValue].members.splice(action.value, 1);
      }

      return {...state, segments: {...state.segments}};
    case 'update':
      return {...action.value};
    default:
      throw new Error();
  }
};
const Values = ({parentIndex, index, dispatch, prop, options, state, seg}) => {
  const [numericError, setNumericError] = useState('');
  const [singleTextError, setSingleTextError] = useState('');
  const [itemList, setItemList] = useState([]);
  const [query, setQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const listMap = {
    products: getVariantList,
    productCategory: getProductCategoryList,
    discountCodes: getdiscountCodeList,
    billingCity: getLocationList,
    billingProvince: getLocationList,
    billingCountry: getLocationList,
    defaultCity: getLocationList,
    defaultProvince: getLocationList,
    defaultCountry: getLocationList,
    shippingCity: getLocationList,
    shippingProvince: getLocationList,
    shippingCountry: getLocationList,
  };
  useEffect(() => {
    const params = {listName: options[prop.property]?.value[prop.operator].itemType};
    if (query) {
      params.search = query;
    }
    if (isOpen) {
      listMap[options[prop.property].value[prop.operator].itemType]({params})
        .then(res => {
          setItemList(res.data.result);
        })
        .catch(err => console.log(err));
    }
  }, [query, isOpen]);
  const targetElement = useRef();
  useEffect(() => {
    targetElement.current.scrollIntoView({
      behavior: 'smooth',
    });
  }, []);
  return (
    <Box key={`${parentIndex}-${index}`} ref={targetElement}>
      {index ? (
        <Box paddingBlockEnd={'4'}>
          <Text alignment="center" variant="headingMd" color="subdued">
            OR
          </Text>
        </Box>
      ) : (
        <></>
      )}
      <Box paddingBlockEnd={'8'}>
        <Inline align="space-between" blockAlign="start">
          <Box width="90%">
            <Inline blockAlign="start">
              <Box width="17%">
                <Property
                  dispatch={dispatch}
                  value={prop.property}
                  indexes={[parentIndex, index]}
                  options={segments}
                />
              </Box>
              {!(
                prop.property === '' || ['firstOrderDate', 'lastOrderDate'].includes(prop.property)
              ) && (
                <Box width="17%">
                  <Operator
                    value={prop.operator}
                    indexes={[parentIndex, index]}
                    dispatch={dispatch}
                    operatorOptions={options[prop.property].operator}
                  />
                </Box>
              )}
              {prop.operator !== '' && options[prop.property].value[prop.operator].required && (
                <Box width="17%">
                  {options[prop.property].value[prop.operator].type === 'option' && (
                    <ValueOption
                      dispatch={dispatch}
                      value={prop.value}
                      indexes={[parentIndex, index]}
                      valueOptions={options[prop.property].value[prop.operator].options}
                    />
                  )}
                  {options[prop.property].value[prop.operator].type === 'number' && (
                    <TextField
                      type="number"
                      placeholder="Enter Value"
                      value={prop.value}
                      min={1}
                      inputMode="numeric"
                      error={numericError}
                      onChange={e => {
                        if (e < 1) {
                          setNumericError('Value must be greater than 0');
                        } else {
                          setNumericError('');
                        }
                        dispatch({
                          type: 'value',
                          indexes: [parentIndex, index],
                          value: e,
                        });
                      }}
                      autoComplete="off"
                    />
                  )}
                  {options[prop.property].value[prop.operator].type === 'multiText' && (
                    <MultiTextValue
                      dispatch={dispatch}
                      indexes={[parentIndex, index]}
                      value={prop.value}
                      operator={options[prop.property].value[prop.operator]}
                    />
                  )}
                  {options[prop.property].value[prop.operator].type === 'datePicker' && (
                    <DefaultDatePicker
                      dispatch={dispatch}
                      indexes={[parentIndex, index]}
                      value={prop.value}
                      type="value"
                    />
                  )}
                  {options[prop.property].value[prop.operator].type === 'mutiDatePicker' && (
                    <DefaultMultiDatePicker
                      dispatch={dispatch}
                      indexes={[parentIndex, index]}
                      value={prop.value}
                      type="value"
                    />
                  )}
                  {options[prop.property].value[prop.operator].type === 'singleText' && (
                    <TextField
                      placeholder="Enter Value"
                      value={prop.value}
                      error={singleTextError}
                      onChange={e => {
                        if (e.trim().length < 1) {
                          setSingleTextError('Invalid value');
                        } else {
                          setSingleTextError('');
                        }
                        dispatch({
                          type: 'value',
                          indexes: [parentIndex, index],
                          value: e,
                        });
                      }}
                      autoComplete="off"
                    />
                  )}
                  {options[prop.property].value[prop.operator].type === 'filterDropdown' && (
                    <FilterDropDown
                      itemList={itemList.length > 0 ? itemList : []}
                      selectedItem={prop.value || null}
                      setSelectedItem={value => {
                        dispatch({
                          type: 'value',
                          indexes: [parentIndex, index],
                          value: value,
                        });
                      }}
                      setQuery={setQuery}
                      query={query}
                      itemType={options[prop.property].value[prop.operator].itemType}
                      label={options[prop.property].value[prop.operator].label}
                      setIsOpen={setIsOpen}
                    />
                  )}
                  {options[prop.property].value[prop.operator].type ===
                    'filterDropdownMultiSelect' && (
                    <>
                      <FilterDropDownMultiSelect
                        itemList={itemList.length > 0 ? itemList : []}
                        selectedItems={
                          prop.value
                            ? prop.value.split(',').filter(x => x !== undefined && x !== null)
                            : []
                        }
                        setSelectedItems={values => {
                          dispatch({
                            type: 'value',
                            indexes: [parentIndex, index],
                            value: values.join(','),
                          });
                        }}
                        setQuery={setQuery}
                        query={query}
                        itemType={options[prop.property].value[prop.operator].itemType}
                        label={options[prop.property].value[prop.operator].label}
                        setIsOpen={setIsOpen}
                        from={'newSegments'}
                      />
                    </>
                  )}
                </Box>
              )}

              {((prop.value !== null && prop.value !== undefined) ||
                ['firstOrderDate', 'lastOrderDate'].includes(prop.property)) &&
                options[prop.property].timeSelector.required && (
                  <Box width="17%">
                    <TimeSelector
                      dispatch={dispatch}
                      value={prop.timeSelector}
                      indexes={[parentIndex, index]}
                      timeSelectorOptions={options[prop.property].timeSelector.options}
                    />
                  </Box>
                )}

              {prop.timeSelector !== '' && timeSelection[prop.timeSelector].required && (
                <>
                  {prop.timeSelector !== '' && timeSelection[prop.timeSelector].type === 'days' && (
                    <Box width="17%">
                      <Inline>
                        <Box width="60%">
                          <TextField
                            type="text"
                            value={prop.days}
                            onChange={e => {
                              dispatch({
                                type: 'days',
                                indexes: [parentIndex, index],
                                value: e,
                              });
                            }}
                            autoComplete="off"
                          />
                        </Box>
                        <Box>
                          <Text color="subdued">Days</Text>
                        </Box>
                      </Inline>
                    </Box>
                  )}
                  {timeSelection[prop.timeSelector].type === 'singleDatePicker' && (
                    <Box width="17%">
                      <DefaultDatePicker
                        dispatch={dispatch}
                        indexes={[parentIndex, index]}
                        value={prop.date1}
                        type="date1"
                      />
                    </Box>
                  )}
                  {timeSelection[prop.timeSelector].type === 'multiDatePicker' && (
                    <Box width="17%">
                      <DefaultMultiDatePicker
                        dispatch={dispatch}
                        indexes={[parentIndex, index]}
                        value={prop.date1}
                        type="date1"
                      />
                    </Box>
                  )}
                </>
              )}
            </Inline>
          </Box>

          {state.segments.members.length > 1 || seg.members.length > 1 ? (
            <Box>
              <Button
                onClick={() => {
                  dispatch({
                    type: 'delete',
                    parentValue: parentIndex,
                    value: index,
                  });
                }}
                destructive
                outline
              >
                <Icon source={CancelMajor} />
              </Button>
            </Box>
          ) : (
            <></>
          )}
        </Inline>
      </Box>
    </Box>
  );
};
const NewSegment = ({params, edit}) => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, init);
  const isUnchanged = useSaveChangesStore(state => state.setIsUnchanged);
  const setOnSubmit = useSaveChangesStore(state => state.setOnSubmit);
  const setOnDiscard = useSaveChangesStore(state => state.setOnDiscard);
  const setProtect = usePreventNavigateStore(state => state.setProtect);
  const setButtonEnabled = useSaveChangesStore(state => state.setButtonEnabled);
  // eslint-disable-next-line no-unused-vars
  const [prompt, setDirty, setPristine] = useUnsavedChangesWarning();
  const [lengthError, setLengthError] = useState(false);
  const [toBeUpdated, setToBeUpdated] = useState(false);
  const [error, setError] = useState('');
  const scollToRef = useRef();
  useEffect(() => {
    track('Page View', {
      name: edit ? 'Edit Segment' : 'New Segment',
    });
  }, []);
  const onSave = () => {
    if (toBeUpdated) {
      updateSegment({_id: params.id, state}).then(res => {
        if (res.status === 500) {
          setError('Something Went Wrong');
        } else {
          navigate('/segments');
        }
      });
    } else {
      createSegment(state).then(res => {
        if (res.status === 500) {
          setError('Something Went Wrong');
        } else {
          navigate('/segments');
        }
      });
    }
    isUnchanged(false);
    setProtect(false);
    setButtonEnabled(false);
  };

  const discard = () => {
    navigate('/segments');
  };
  useEffect(() => {
    setTimeout(() => {
      setError('');
    }, config.ALERT_TIMEOUT);
  }, [error]);

  const protect = usePreventNavigateStore(state => state.protect);
  const [openLeavePageModal, setOpenLeavePageModal] = useState(false);
  const [toNavigate, setToNavigate] = useState('');
  useEffect(() => {
    isUnchanged(true);
    setOnDiscard(discard);
    setProtect(true);
    setDirty();

    if (params.id) {
      getSegmentList({params: {_id: params.id}}).then(res => {
        dispatch({type: 'update', value: res.data.data.segmentDetail});
        setToBeUpdated(true);
      });
    }
    // setPristine();
  }, []);
  useEffect(() => {
    setOnSubmit(onSave);
    if (
      state.name &&
      state.segments.members[0].members[0].property &&
      state.segments.members
        .map(m =>
          m.members.map(x =>
            x.value?.trim() ||
            (x.timeSelector && (x.days || x.date1)) ||
            x.timeSelector === 'allTime'
              ? true
              : false
          )
        )
        .flat()
        .filter(x => x === '0' || x === '' || !x).length === 0 &&
      state.segments.members
        .map(m =>
          m.members.map(x =>
            (x.timeSelector && (x.days || x.date1)) ||
            x.timeSelector === 'allTime' ||
            x.timeSelector === ''
              ? true
              : false
          )
        )
        .flat()
        .filter(x => !x).length === 0
    ) {
      setButtonEnabled(true);
    } else {
      setButtonEnabled(false);
    }
  }, [state]);

  return (
    <>
      <Page
        breadcrumbs={[
          {
            content: 'Products',
            onAction: () => {
              if (protect) {
                setOpenLeavePageModal(true);
                setToNavigate('/segments');
              } else {
                navigate(-1);
              }
            },
          },
        ]}
        title={edit ? 'Edit Segment' : 'New Segment'}
      >
        <Card>
          <Card.Section>
            <Box paddingBlockEnd={'4'}>
              <Inline blockAlign="start" align="space-between">
                <Box width="100%">
                  <TextField
                    error={lengthError && 'Max length of 150 characters reached'}
                    value={state.name}
                    onChange={e => {
                      if (e.length < 150) {
                        dispatch({type: 'name', value: e});
                        setLengthError(false);
                      } else if (e.length === 150) {
                        dispatch({type: 'name', value: e});
                        setLengthError(true);
                      }
                    }}
                    label="Segment name"
                    placeholder="Segment name"
                    fullWidth
                    autoComplete="off"
                  />
                </Box>
              </Inline>
            </Box>
          </Card.Section>
        </Card>
        <Box paddingBlockStart={'5'} paddingBlockEnd={'5'}>
          <Text variant="headingMd">Segment Criteria</Text>
        </Box>
        {state.segments.members.map((seg, parentIndex) => {
          return (
            <div ref={scollToRef} key={parentIndex}>
              <Box>
                <ErrorBoundary
                  fallback={
                    <Card>
                      <Card.Section>
                        <div>An error has occured</div>
                      </Card.Section>
                    </Card>
                  }
                >
                  <Card>
                    <Card.Section>
                      <Box paddingBlockStart={'4'} paddingBlockEnd={'4'} width="99%">
                        {seg.members.map((prop, index) => {
                          return (
                            <Values
                              key={index}
                              parentIndex={parentIndex}
                              index={index}
                              state={state}
                              dispatch={dispatch}
                              prop={prop}
                              options={options}
                              seg={seg}
                            />
                          );
                        })}
                        <Box width="100%">
                          <Inline align="end" blockAlign="end">
                            <Button
                              onClick={() => {
                                dispatch({type: 'or', value: parentIndex});
                              }}
                            >
                              +OR
                            </Button>
                          </Inline>
                        </Box>
                      </Box>
                    </Card.Section>
                  </Card>
                  <Box paddingBlockStart={'8'} paddingBlockEnd={'8'}>
                    <Button
                      primary
                      onClick={() => {
                        dispatch({type: 'and', value: parentIndex});
                      }}
                    >
                      +AND
                    </Button>
                  </Box>
                </ErrorBoundary>
              </Box>
            </div>
          );
        })}
        {openLeavePageModal && (
          <LeavePageModal
            currentState={openLeavePageModal}
            toNavigate={toNavigate}
            onClose={setOpenLeavePageModal}
          />
        )}
        {error && <Alert contents={error} init={true} error={error === 'Something Went Wrong'} />}
      </Page>
    </>
  );
};

export default NewSegment;
