import {Modal} from '@shopify/polaris';
import React from 'react';
import JsonViewer from '../JsonViewer';

const JsonViewerModal = ({isOpen, toggle, data, title}) => {
  const activator = <div></div>;

  return (
    <Modal
      large
      activator={activator}
      open={isOpen}
      onClose={toggle}
      title={title ? title : 'Data'}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: toggle,
        },
      ]}
    >
      <Modal.Section>
        <JsonViewer data={data} />
      </Modal.Section>
    </Modal>
  );
};

export default JsonViewerModal;
