import React from 'react';
import {Text as PolarisText} from '@shopify/polaris';

const Text = React.forwardRef(({children, ...props}, ref) => {
  return (
    <PolarisText ref={ref} {...props}>
      {children}
    </PolarisText>
  );
});

export default Text;
