import React from 'react';
import {Frame} from '@shopify/polaris';
import NewSignal from './newSignal';
import MyNavigation from '../../components/navigation';
import SaveBar from '../../components/saveBar';
import {useSaveChangesStore} from '../../app/store';
import {useParams} from 'react-router-dom';
function Signal({edit}) {
  const isUnchanged = useSaveChangesStore(state => state.isUnchanged);
  const params = useParams();
  return (
    <>
      <Frame
        logo={{
          width: 124,
          contextualSaveBarSource: '/images/logo.svg',
        }}
        navigation={<MyNavigation />}
        topBar={isUnchanged ? <SaveBar /> : null}
      >
        <NewSignal params={params} />
      </Frame>
    </>
  );
}
export default Signal;
