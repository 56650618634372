import React from 'react';
import {TextContainer as PolarisTextContainer} from '@shopify/polaris';

const TextContainer = React.forwardRef(({children, ...props}, ref) => {
  return (
    <PolarisTextContainer ref={ref} {...props}>
      {children}
    </PolarisTextContainer>
  );
});

export default TextContainer;
