import {Card, DataTable, Text} from '@shopify/polaris';
import {useEffect, useState} from 'react';

const CohortTable = ({data}) => {
  const [rows, setRows] = useState([]);
  const [headings, setHeadings] = useState([]);

  useEffect(() => {
    let allRows = [];
    let h = [];
    if (data.length) {
      for (let key in data[0]) {
        if (key === 'date') {
          h.push('Month');
        } else if (key !== 'totalRows') {
          h.push(key.toUpperCase());
        }
      }
    }
    data.map(d => {
      let row = [];
      for (let key in d) {
        if (key === 'date') {
          let n =
            new Date(d[key]).toLocaleString('default', {
              month: 'short',
            }) +
            ' ' +
            new Date(d[key]).getFullYear();
          row.push(n);
        } else if (key !== 'totalRows') {
          row.push(d[key]);
        }
      }
      allRows.push(row);
    });
    setHeadings([...h]);
    setRows([...allRows]);
  }, [data]);

  return (
    <Card>
      <DataTable
        columnContentTypes={[
          'text',
          'text',
          'text',
          'text',
          'text',
          'text',
          'text',
          'text',
          'text',
          'text',
          'text',
          'text',
          'text',
          'text',
        ]}
        headings={headings}
        rows={rows}
        stickyHeader
        fixedFirstColumns={1}
        hideScrollIndicator
        increasedTableDensity
        firstColumnMinWidth="120px"
      />
    </Card>
  );
};

export default CohortTable;
