import React from 'react';
import {Inline as PolarisInline} from '@shopify/polaris';

const Inline = React.forwardRef(({children, ...props}, ref) => {
  return (
    <PolarisInline ref={ref} {...props}>
      {children}
    </PolarisInline>
  );
});

export default Inline;
