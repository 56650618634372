import React, {useEffect} from 'react';
import {Popover, ActionList, Text, ChoiceList, OptionList} from '@shopify/polaris';
import {useState, useCallback} from 'react';
import Button from '../../../lib/Button';
const Operator = ({operatorOptions, dispatch, indexes, value, user}) => {
  const [popoverActive, setPopoverActive] = useState(false);
  const [selected, setSelected] = useState([]);
  const [name, setName] = useState('');

  const togglePopoverActive = useCallback(
    () => setPopoverActive(popoverActive => !popoverActive),
    []
  );

  const activator = (
    <Button
      fullWidth
      textAlign="left"
      disclosure={popoverActive ? 'up' : 'down'}
      onClick={togglePopoverActive}
      disabled={user === 'Retainwise'}
    >
      <Text color="subdued">{selected.length ? name : 'Operator'}</Text>
    </Button>
  );

  useEffect(() => {
    if (value === '') {
      setSelected([]);
    } else {
      setSelected([value]);
    }
  }, [value]);
  useEffect(() => {
    let n = '';
    operatorOptions.map(a => {
      if (a.value === selected[0]) {
        n = a.label;
      }
    });

    setName(n);
  }, [selected]);

  return (
    <div>
      <Popover
        active={popoverActive}
        activator={activator}
        autofocusTarget="first-node"
        onClose={togglePopoverActive}
        preferredPosition="below"
        fullWidth
      >
        <Popover.Pane>
          <OptionList
            onChange={e => {
              console.log(e);
              setSelected(e);
              dispatch({type: 'operator', indexes: indexes, value: e[0]});
              if (e[0] === 'any') {
                dispatch({type: 'value', indexes: indexes, value: '*'});
              }
              togglePopoverActive();
            }}
            options={operatorOptions}
            selected={selected}
          />
        </Popover.Pane>
      </Popover>
    </div>
  );
};

export default Operator;
