import React from 'react';
import {ProgressBar, Loading} from '@shopify/polaris';
import Box from '../../../lib/Box';
import {useState} from 'react';
import {useEffect} from 'react';
import {AdminApi} from '../../../api/admin';
import Page from '../../../components/page';
import Card from '../../../lib/Card';
import Stack from '../../../lib/Stack';
import Text from '../../../lib/Text';
import {updateJobStatus} from '../../../api/reports';

import {formatDate, toTitleCase} from '../../../features/format';
import {useParams} from 'react-router-dom';

const StatusTracking = () => {
  // const [jobTypes, setJobTypes] = useState(['historic']);
  // const [dateRange, setDateRange] = useState('lastNinetyDays');
  // const [page, setPage] = useState(0);
  // const [popoverActive, setPopoverActive] = useState(false);
  const [data, setData] = useState('');
  const [estTime, setEstTime] = useState('');
  const [state, setState] = useState('Loading');

  const params = useParams();
  const orgId = params?.organizationId?.split('+')[0];
  const orgName = toTitleCase(params?.organizationId?.split('+')[1]);

  // const [selectedDates, setSelectedDates] = useState(
  //   dateRange
  //     ? {
  //         start: new Date(dateRangeObj[dateRange]()[0]),
  //         end: new Date(dateRangeObj[dateRange]()[1]),
  //       }
  //     : {
  //         start: new Date(),
  //         end: new Date(),
  //       }
  // );
  // const getDates = (dates, dateOption) => {
  //   setSelectedDates(dates);
  //   setDateRange(dateOption);
  // };
  useEffect(() => {
    // let sdate = selectedDates.start.toISOString();
    // let edate = selectedDates.end.toISOString();
    const fetch = async () => {
      setState('Loading');
      if (orgId) {
        try {
          let res = await AdminApi.statusTracking(orgId);
          if (res.status) {
            setState('Success');
            setData(res.data || []);
          } else {
            setState('Error');
          }
        } catch (e) {
          setState('Error');
        }
      }
    };

    fetch();
    const interval = setInterval(() => {
      fetch(); // fetch data every 10 minute
    }, 60 * 1000);

    return () => clearInterval(interval);
  }, [orgId]);

  const convertEstTime = time => {
    if (!time) {
      return null;
    }
    time = Number(time);
    if (time <= 60) {
      return `${Math.round(time)} minutes`;
    } else {
      let hours = Math.floor(time / 60);
      let remMins = Math.round(time - hours * 60);
      return remMins ? `${hours} hours ${remMins} minutes` : `${hours} hours`;
    }
  };
  useEffect(() => {
    if (data && Object.keys(data?.result || {}).length) {
      let arr = [];
      let result = data.result;
      for (let key in result) {
        if ((result[key].chCount * 100) / result[key].expectedCount < 98) {
          arr.push(result[key]);
        }
      }
      if (arr.length === 0) {
        updateJobStatus({params: {jobId: data.jobId, orgId: data.orgId}});
        // setIsFetching(false);
      }
      setEstTime(convertEstTime(data.estTime));
    }
  }, [data]);

  // const togglePopoverActive = useCallback(
  //   () => setPopoverActive(popoverActive => !popoverActive),
  //   []
  // );
  // const activator = (
  //   <div style={{width: '100%'}}>
  //     <Button onClick={togglePopoverActive} disclosure>
  //       {dateRange ? dateRangeValueToLable[dateRange] : dateToNormalString(selectedDates)}
  //     </Button>
  //   </div>
  // );
  return (
    <>
      {!!state === 'Loading' && <Loading />}
      <Page title={`Status Tracking for ${orgName}`}>
        <Card>
          <Card.Section></Card.Section>
          {!!data && Object.keys(data?.result || {}).length > 0 && state === 'Success' ? (
            <>
              <Card.Section>
                <Stack vertical="true" alignment="center" align="center">
                  <Box>
                    <Text variant="bodyMd" fontWeight="bold">
                      Organization: {orgName}
                    </Text>
                  </Box>
                  <Box>
                    <Text variant="bodyMd" fontWeight="bold">
                      Job Id: {data.jobID}
                    </Text>
                  </Box>
                  <Box>
                    <Text variant="bodyMd" fontWeight="bold">
                      Job Type: {data.type.toUpperCase()}
                    </Text>
                  </Box>
                  <Box>
                    <Text variant="bodyMd" fontWeight="bold">
                      Status: {data.status.toUpperCase()}
                    </Text>
                  </Box>
                  <Box>
                    {formatDate({value: new Date(data.from), removeTime: true}) +
                      ' - ' +
                      formatDate({value: new Date(data.to), removeTime: true})}
                  </Box>
                  {Object.keys(data.result).map((x, i) => (
                    <div key={i}>
                      <Text variant="" fontWeight="bold">
                        {x[0].toUpperCase() + x.slice(1)}
                      </Text>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <div style={{flex: '1', marginRight: '1rem', width: '20rem'}}>
                          <ProgressBar
                            color="success"
                            progress={(data.result[x].chCount * 100) / data.result[x].expectedCount}
                            size="small"
                          />
                        </div>
                        <div style={{flex: '0.1'}}>
                          {((data.result[x].chCount * 100) / data.result[x].expectedCount).toFixed(
                            2
                          )}
                          %
                        </div>
                      </div>
                      <Text variant="headingXs" fontWeight="" color="subdued" as="h6">
                        {`${data.result[x].chCount} completed${
                          data.result[x].expectedCount
                            ? ` out of ${data.result[x].expectedCount}`
                            : ''
                        }`}
                      </Text>
                    </div>
                  ))}
                </Stack>
              </Card.Section>
              <Card.Section>
                <Box>
                  <Text variant="bodyMd" fontWeight="bold" alignment="center">
                    {`Data Sync in progress, ${
                      estTime
                        ? `estimated time to complete - ${estTime}`
                        : `please check back after some time.`
                    } `}
                  </Text>
                </Box>
              </Card.Section>
            </>
          ) : state === 'Success' ? (
            <Card.Section>
              <Box>
                <Text variant="bodyMd" fontWeight="bold" alignment="center">
                  {data === 'completed'
                    ? 'Historic job is completed'
                    : 'No historic job available for tracking'}
                </Text>
              </Box>
            </Card.Section>
          ) : (
            state === 'Error' && (
              <Card.Section>
                <Box>
                  <Text variant="bodyMd" fontWeight="bold" alignment="center">
                    Not able to load data
                  </Text>
                </Box>
              </Card.Section>
            )
          )}
          {}
        </Card>
      </Page>
    </>
  );
};
export {StatusTracking};
