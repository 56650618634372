import React, {useState} from 'react';
import {Form, FormLayout, TextField, Spinner} from '@shopify/polaris';
import Button from '../../../../lib/Button';
import Inline from '../../../../lib/Inline';
import Box from '../../../../lib/Box';
import {useLoadingStore} from '../../../../app/store';
const ForgotInput = ({email, setEmail, handleSubmit, disabled, emailError}) => {
  const [lengthError, setLengthError] = useState(false);
  const loading = useLoadingStore(state => state.loading);

  return (
    <>
      <Inline align="center">
        <Box className="form-container" paddingBlockStart={'8'} paddingBlockEnd={'4'} width="50%">
          <Form onSubmit={handleSubmit}>
            <FormLayout>
              <TextField
                placeholder="Your registered email"
                type="text"
                label="Email"
                error={
                  emailError
                    ? 'Enter valid email'
                    : lengthError
                    ? 'Max length of 150 characters reached'
                    : ''
                }
                onChange={e => {
                  if (e.length < 150) {
                    setLengthError(false);
                    setEmail(e);
                  } else if (e.length === 150) {
                    setLengthError(true);
                    setEmail(e);
                  }
                }}
                value={email}
                autoComplete="email"
              />

              <Box paddingBlockStart={'4'}>
                <Button disabled={disabled} primary fullWidth submit>
                  {loading ? <Spinner size="small" /> : 'Reset Password'}
                </Button>
              </Box>
            </FormLayout>
          </Form>
        </Box>
      </Inline>
    </>
  );
};
export default ForgotInput;
