import React from 'react';
import {Badge} from '@shopify/polaris';
import Stack from '../../../../lib/Stack';
import Card from '../../../../lib/Card';
import Inline from '../../../../lib/Inline';
import TextContainer from '../../../../lib/TextContainer';
import Text from '../../../../lib/Text';
const AlertHistoryCard = ({data}) => {
  const badge =
    data.status == 'success' ? (
      <Badge status="success">Alert completed</Badge>
    ) : data.status === 'error' ? (
      <Badge status="critical">Alert failed</Badge>
    ) : (
      <Badge status="warning">Alert in progress</Badge>
    );
  return (
    <>
      <Card title={data.reportName} actions={[{content: badge}]} sectioned>
        <Inline align="space-between" blockAlign="start">
          <TextContainer spacing="tight">
            {data.type == 'alert' && (
              <Text color="subdued" as="p" variant="bodyMd">
                Last alert as scheduled by {data.recipients.join('')}
              </Text>
            )}
            <Text color="subdued" as="p" variant="bodyMd">
              {data.time}
            </Text>
          </TextContainer>
          <Stack vertical spacing="extra-tight">
            {/* {data.links.map(link => {
              return (
                <Link external url={`${link}`}>
                  {link.split('/')[link.split('/').length - 1]}
                </Link>
              );
            })} */}
          </Stack>
        </Inline>
      </Card>
    </>
  );
};
export default AlertHistoryCard;
