import React, {useState} from 'react';
import {
  Icon,
  TextField,
  Listbox,
  AutoSelection,
  Scrollable,
  EmptySearchResult,
  Button,
  Text,
  Popover,
} from '@shopify/polaris';
import {SearchMinor} from '@shopify/polaris-icons';
import {getTransformedProductTitle} from '../../../features/getTransformedProductTitle';

const ProductSelect = ({productList, setSelectedProduct, setQuery, query}) => {
  const [selectedProductIndex, setSelectedProductIndex] = useState(0);
  const [pickerOpen, setPickerOpen] = useState(false);

  const handleOpenPicker = () => {
    setPickerOpen(!pickerOpen);
  };

  const handleClosePicker = () => {
    setPickerOpen(false);
    handleQueryChange('');
  };

  const handleQueryChange = query => {
    setQuery(query);
  };

  const handleQueryClear = () => {
    handleQueryChange('');
  };

  const handleSegmentSelect = productIndex => {
    setSelectedProductIndex(Number(productIndex));
    setSelectedProduct(productList[Number(productIndex)]);
    setPickerOpen(!pickerOpen);
  };

  const activator = (
    <div>
      <Button
        fullWidth
        textAlign="left"
        disclosure={pickerOpen ? 'up' : 'down'}
        onClick={handleOpenPicker}
      >
        Select variant
      </Button>
    </div>
  );

  const textFieldMarkup = (
    <div style={{padding: '12px'}}>
      <TextField
        clearButton
        labelHidden
        label="Top Products"
        placeholder="Search Product Title or SKU"
        autoComplete="off"
        value={query}
        prefix={<Icon source={SearchMinor} />}
        onChange={handleQueryChange}
        onClearButtonClick={handleQueryClear}
      />
    </div>
  );

  const productOptions = productList;
  const productItems = (
    <>
      {productOptions.length > 0 ? (
        <>
          <Listbox.Header>{!query ? 'Top 50 Products' : ''}</Listbox.Header>
          {productOptions.map(({title, variantId, sku, variantTitle}, i) => {
            const selected = productOptions[selectedProductIndex].variantId === variantId;
            const tranformedTitle = getTransformedProductTitle({sku, variantTitle, title});
            return (
              <Listbox.Option key={i} value={i} selected={selected}>
                <Listbox.TextOption selected={selected}>{tranformedTitle}</Listbox.TextOption>
              </Listbox.Option>
            );
          })}
        </>
      ) : null}
    </>
  );

  const noResultsMarkup =
    productOptions.length === 0 ? (
      <EmptySearchResult title="" description={`No products found`} />
    ) : null;

  const listboxMarkup = (
    <Listbox
      enableKeyboardControl
      autoSelection={AutoSelection.FirstSelected}
      accessibilityLabel="Search for and select a customer segment"
      // customListId={listboxId}
      onSelect={handleSegmentSelect}
    >
      {productItems}
      {noResultsMarkup}
    </Listbox>
  );

  return (
    <Popover
      active={pickerOpen}
      activator={activator}
      ariaHaspopup="listbox"
      preferredAlignment="left"
      preferredPosition="below"
      autofocusTarget="first-node"
      onClose={handleClosePicker}
      fluidContent
    >
      <Popover.Pane>
        <div
          style={{
            alignItems: 'stretch',
            borderTop: '1px solid #DFE3E8',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'stretch',
            position: 'relative',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          {textFieldMarkup}

          <Scrollable
            shadow
            style={{
              position: 'relative',
              height: '292px',
              padding: 'var(--p-space-200) 0',
              borderBottomLeftRadius: 'var(--p-border-radius-200)',
              borderBottomRightRadius: 'var(--p-border-radius-200)',
            }}
          >
            {listboxMarkup}
          </Scrollable>
        </div>
      </Popover.Pane>
    </Popover>
  );
};

export default ProductSelect;
