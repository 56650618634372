import {Text, Inline} from '@shopify/polaris';
import React from 'react';
import Toggle from '../../../../components/Toggle';
import '../../../../components/Toggle/style.css';

const ExportListItem = ({
  data,
  setConnectionId,
  setConfirmModalState,
  connectionId,
  setConfirmType,
}) => {
  const isChecked = data.isAdded || connectionId === data._id;
  const handleChange = () => {
    if (!isChecked) {
      setConfirmModalState(true);
      setConnectionId(data._id);
      setConfirmType('confirm');
    } else {
      setConfirmModalState(true);
      setConfirmType('pause');
      setConnectionId(data._id);
    }
  };

  return (
    <Inline>
      <Toggle checked={isChecked} onChange={handleChange} icons={{unchecked: null}} />
      <Text fontWeight="semibold">
        {`${data.title} (${data.type[0].toUpperCase() + data.type.slice(1).toLowerCase()})`}
      </Text>
    </Inline>
  );
};

export default ExportListItem;
