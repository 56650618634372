import mixpanel from 'mixpanel-browser';
// import {trackAPI} from '../api/track';

const track = (eventName, options) => {
  // const distinct_id = mixpanel.get_distinct_id();
  // trackAPI({
  //   eventName,
  //   options: {...options, distinct_id},
  // }); //sending mixpanel events via backend not browser

  mixpanel.track(eventName, options);
};

export {track};
