import React, {useEffect} from 'react';
import {
  Popover,
  TextField,
  Scrollable,
  DatePicker,
  Inline,
  Icon,
  OptionList,
} from '@shopify/polaris';
import {ArrowRightMinor} from '@shopify/polaris-icons';
import {useState, useCallback} from 'react';
import {
  thirtyDaysBefore,
  todayDate,
  yesterDayDate,
  ninetyDaysBefore,
  sevenDaysBefore,
  oneYearBefore,
} from '../features/convertDates';
import config from '../config';
import Button from '../lib/Button';
import Box from '../lib/Box';
const dateRangeObj = {
  yesterday: yesterDayDate,
  today: todayDate,
  lastThirtyDays: thirtyDaysBefore,
  lastSevenDays: sevenDaysBefore,
  lastNinetyDays: ninetyDaysBefore,
  lastOneYear: oneYearBefore,
};
const dateOptions = [
  {label: 'Today', value: 'today', active: false},
  {label: 'yesterday', value: 'yesterday', active: false},
  {label: 'last 7 days', value: 'lastSevenDays', active: false},
  {label: 'last 30 days', value: 'lastThirtyDays', active: false},
  {label: 'last 90 days', value: 'lastNinetyDays', active: false},
  {label: 'last 1 year', value: 'lastOneYear', active: false},
];

const dateToText = d => {
  function z(n) {
    return (n < 10 ? '0' : '') + n;
  }
  let year = d.getFullYear();
  let month = d.getMonth() + 1;
  let day = d.getDate();
  return `${year}-${z(month)}-${z(day)}`;
};
const today = new Date();
today.setHours(23, 59, 59, 999);
const DateRangeComponent = ({
  getDates,
  currentDates,
  activator,
  popoverActive,
  togglePopoverActive,
  currentDateOption,
}) => {
  const [selectedDates, setSelectedDates] = useState(currentDates);
  const [dateOptionSelected, setDateOptionSelected] = useState([currentDateOption]);
  const [startDateTextInput, setStartDateTextInput] = useState(dateToText(selectedDates.start));
  const [endDateTextInput, setEndDateTextInput] = useState(dateToText(selectedDates.end));

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const handleMonthChange = useCallback((month, year) => setDate({month, year}), []);
  const [{month, year}, setDate] = useState({
    month: new Date(currentDates.start).getMonth(),
    year: new Date(currentDates.start).getFullYear(),
  });

  useEffect(() => {
    setStartDateTextInput(dateToText(selectedDates.start));
    setEndDateTextInput(dateToText(selectedDates.end));
    setDate({
      month: new Date(selectedDates.start).getMonth(),
      year: new Date(selectedDates.start).getFullYear(),
    });
  }, [selectedDates]);
  useEffect(() => {
    setSelectedDates(currentDates);
    if (currentDateOption) {
      setDateOptionSelected([currentDateOption]);
    } else {
      setDateOptionSelected([]);
    }
  }, [popoverActive]);

  useEffect(() => {
    if (dateOptionSelected.length) {
      if (dateOptionSelected[0]) {
        let [sdate, edate] = dateRangeObj[dateOptionSelected]();
        setSelectedDates({start: new Date(sdate), end: new Date(edate)});
        setDate({
          year: new Date(sdate).getFullYear(),
          month: new Date(sdate).getMonth(),
        });
      }
    }
  }, [dateOptionSelected]);

  return (
    <Popover
      active={popoverActive}
      activator={activator}
      onClose={togglePopoverActive}
      ariaHaspopup={false}
      preferredAlignment="left"
      fluidContent={true}
    >
      <Box style={{maxWidth: '50rem'}}>
        <Popover.Pane fixed>
          <Box padding={'3'} paddingBlockEnd={'0'}>
            <Inline blockAlign="start">
              <Box width="25%">
                <Scrollable shadow style={{height: '260px'}} horizontal={false} focusable>
                  <OptionList
                    selected={dateOptionSelected}
                    onChange={e => {
                      setUnsavedChanges(true);

                      setDateOptionSelected(e);
                    }}
                    options={dateOptions}
                  />
                </Scrollable>
              </Box>
              <Box width="70%" padding={'2'}>
                {/* <Popover.Section> */}
                <Inline gap={'2'} blockAlign="start">
                  <TextField
                    onChange={e => {
                      setDateOptionSelected([]);
                      setUnsavedChanges(true);

                      setStartDateTextInput(e);
                    }}
                    onBlur={e => {
                      setSelectedDates({
                        ...selectedDates,
                        start: new Date(e.target.value),
                      });
                    }}
                    value={startDateTextInput}
                    fullWidth
                    type="text"
                    placeholder="YYYY-MM-DD"
                  />
                  <Icon source={ArrowRightMinor} color="base" />
                  <TextField
                    onChange={e => {
                      setDateOptionSelected([]);
                      setUnsavedChanges(true);

                      setEndDateTextInput(e);
                    }}
                    onBlur={e => {
                      setSelectedDates({
                        ...selectedDates,
                        end: new Date(e.target.value),
                      });
                    }}
                    value={endDateTextInput}
                    type="text"
                    placeholder="YYYY-MM-DD"
                  />
                </Inline>
                <Box padding={'2'} paddingBlockStart={'5'}>
                  <DatePicker
                    selected={selectedDates}
                    multiMonth
                    month={month}
                    year={year}
                    weekStartsOn={config.START_OF_WEEK}
                    onChange={e => {
                      setDateOptionSelected([]);

                      e.end.setHours(23, 59, 59);
                      e.start.setHours(0, 0, 0);

                      setSelectedDates(e);
                      setUnsavedChanges(true);
                    }}
                    onMonthChange={handleMonthChange}
                    allowRange
                    disableDatesAfter={today}
                  />
                </Box>
              </Box>
            </Inline>
          </Box>
        </Popover.Pane>

        <Popover.Pane fixed>
          <Box padding={'3'}>
            <Inline align="end">
              <Button
                onClick={() => {
                  togglePopoverActive();
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={!unsavedChanges}
                onClick={() => {
                  getDates(selectedDates, dateOptionSelected[0]);
                  togglePopoverActive();
                }}
                primary
              >
                Apply
              </Button>
            </Inline>
          </Box>
        </Popover.Pane>
      </Box>
    </Popover>
  );
};

export default DateRangeComponent;
