import {useNavigate} from 'react-router-dom';
import {useContext, useEffect} from 'react';
import {AuthContext} from '../../context/auth';
import {LocalStorageService} from '../../service/localStorage';
import {sendUserEvents} from '../../features/sendUserActivity';

export default function PrivateRoute(props) {
  const {isSignedIn, isFetchCompleted} = useContext(AuthContext);
  const navigate = useNavigate();
  let isAdmin = LocalStorageService.isAdminSignedIn();
  if (!isAdmin) {
    sendUserEvents();
  }
  useEffect(() => {
    if (!isSignedIn()) {
      navigate('/');
    }
    if (props.id !== 'dash' && props.id !== 'settings' && !isAdmin && !isFetchCompleted()) {
      navigate('/fetch-status');
    }
  }, [isSignedIn, navigate]);
  return props.children;
}
