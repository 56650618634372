import {Badge, Text, Tag} from '@shopify/polaris';
import Card from '../../../../lib/Card';
import React, {useEffect, useState} from 'react';
import OrderedProduct from './orderedProduct';
import {formatAmount, formatDate} from '../../../../features/format';
import Inline from '../../../../lib/Inline';
import Box from '../../../../lib/Box';
import Button from '../../../../lib/Button';
const OrderCard = ({order}) => {
  const [totalPrice, setTotalPrice] = useState('');
  const [products, setProducts] = useState([]);
  const [showMore, setShowMore] = useState(false);
  useEffect(() => {
    let products;
    if (!showMore) {
      if (order.status !== 'refunded') {
        products = order.lineItems.slice(0, 3);
        setProducts([...products]);
      } else {
        products = order.refundLineItems.slice(0, 3);
        products = products.map(p => p.lineItem);
        setProducts([...products]);
      }
    } else {
      if (order.status !== 'refunded') {
        setProducts([...order.lineItems]);
      } else {
        let p = order.refundLineItems.map(l => l.lineItem);
        setProducts([...p]);
      }
    }
    if (order.status !== 'refunded') {
      setTotalPrice(order.totalLineItemsPrice);
    } else {
      let sum = 0;
      order.refundLineItems.forEach(item => {
        sum += item.subtotal;
      });
      setTotalPrice(sum);
    }
  }, [showMore]);

  return (
    <>
      <Card>
        <Inline blockAlign="stretch">
          <Box width="55%" borderInlineEnd="divider" borderInlineEndWidth="1" padding={'5'}>
            <Box paddingBlockEnd={'3'}>
              <Inline align="space-between" blockAlign="start">
                <Box>
                  <Inline>
                    {/* <Button plain> */}
                    <Text variant="headingMd">{order.orderName}</Text>
                    {/* </Button> */}
                    <Badge status="warning">{order.status}</Badge>
                    <Badge>Fulfilled</Badge>
                  </Inline>
                </Box>
                <Box>
                  <Text variant="headingXl">{formatAmount({value: totalPrice})}</Text>
                </Box>
              </Inline>
            </Box>
            <Box paddingBlockEnd={'3'}>
              <Text variant="bodyMd" color="subdued">
                {order.orderAt ? formatDate({value: new Date(order.orderAt)}) : ''} from{' '}
                {order.sourceName}
              </Text>
            </Box>

            {products.map((a, index) => {
              return (
                <OrderedProduct
                  img={a.image}
                  quantity={a.quantity}
                  price={a.price}
                  name={a.name}
                  sku={a.sku}
                />
              );
            })}
            <Box paddingBlockStart={'5'}>
              {order.lineItems && order.lineItems.length > 3 ? (
                <Button
                  plain
                  disclosure={showMore ? 'up' : 'down'}
                  onClick={() => {
                    setShowMore(!showMore);
                  }}
                >
                  {!showMore ? 'Show' : 'Less'} all{' '}
                  {!showMore && order.lineItems && order.lineItems.length} products
                </Button>
              ) : order.refundLineItems && order.refundLineItems.length > 3 ? (
                <Button
                  plain
                  disclosure={showMore ? 'up' : 'down'}
                  onClick={() => {
                    setShowMore(!showMore);
                  }}
                >
                  {!showMore ? 'Show' : 'Less'} all{' '}
                  {!showMore && order.refundLineItems && order.refundLineItems.length} products
                </Button>
              ) : (
                <></>
              )}
            </Box>
          </Box>
          <Box width="42%">
            <Card.Section>
              <Box>
                <Inline align="space-between">
                  <Text>Coupon Used </Text>
                </Inline>
              </Box>
              <Box paddingBlockStart={'3'}>
                <Inline>
                  {order.codes.length ? (
                    order.codes.map(code => {
                      return <Tag>{code}</Tag>;
                    })
                  ) : (
                    <>-</>
                  )}
                </Inline>
              </Box>
            </Card.Section>
            <Card.Section>
              <Box>
                <Inline align="space-between">
                  <Text>Order Tags </Text>
                </Inline>
              </Box>
              <Box paddingBlockStart={'3'}>
                <Inline>
                  {order.tags && order.tags.split(',').length ? (
                    order.tags.split(',').map(code => {
                      return <Tag>{code}</Tag>;
                    })
                  ) : (
                    <>-</>
                  )}
                </Inline>
              </Box>
            </Card.Section>
            <Card.Section>
              <Box>
                <Inline align="space-between">
                  <Text>Categories </Text>
                </Inline>
              </Box>
              <Box paddingBlockStart={'3'}>
                <Inline>
                  {order.category.length ? (
                    order.category.map(c => {
                      return <Tag>{c}</Tag>;
                    })
                  ) : (
                    <>-</>
                  )}
                </Inline>
              </Box>
            </Card.Section>
            <Card.Section></Card.Section>
          </Box>
        </Inline>
      </Card>
    </>
  );
};

export default OrderCard;
