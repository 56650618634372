import React, {useState, useEffect, useCallback, useContext} from 'react';
import {Grid, Modal, TextField, Loading} from '@shopify/polaris';
import Page from '../../components/page';
import Card from '../../components/card';

import {getProfile, setPassword, updatePassword} from '../../api/profile';
import {passwordValidator} from '../../features/validations';
import {track} from '../../features/track';
import {useNavigate} from 'react-router-dom';
import {AuthContext} from '../../context/auth';
import Button from '../../lib/Button';
import Stack from '../../lib/Stack';
import Toast from '../../lib/Toast';
import Inline from '../../lib/Inline';

const Profile = () => {
  const [active, setActive] = useState(false);
  const handleModalChange = () => setActive(!active);
  let {getAccount} = useContext(AuthContext);
  const [userDetails, setUserDetails] = useState({});
  const [toast, setToast] = useState(null);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [flag, setFlag] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  let accountDetail = getAccount();

  useEffect(() => {
    track('Page View', {
      name: 'Profile',
    });
    getProfile().then(result => {
      if (result.status) {
        setUserDetails(result.data);
        setLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    if (confirmNewPassword) {
      setFlag(true);
    }
    if (confirmNewPassword === newPassword) {
      setError('');
    }
  }, [confirmNewPassword]);

  const toggleActive = () => {
    setToast(null);
  };
  const handleClose = () => {
    handleModalChange();
  };
  let response;
  const handleSave = async () => {
    if (confirmNewPassword !== newPassword) {
      setError('Passwords does not match');
      return;
    }
    if (!passwordValidator(newPassword)) {
      setError(
        'Your password needs to be at least 8 characters including words,  numbers & characters'
      );
      return;
    }
    if (userDetails.isPasswordAdded) {
      response = await updatePassword({
        newPassword,
        currentPassword,
      });
    } else {
      response = await setPassword({
        newPassword,
      });
      setUserDetails({...userDetails, isPasswordAdded: true});
    }

    handleModalChange();
    setToast(
      Object.keys(response).length ? (
        response.status ? (
          <Toast content={'Password updated!'} onDismiss={toggleActive} duration={3000} />
        ) : (
          <Toast content={response.message} error onDismiss={toggleActive} duration={3000} />
        )
      ) : null
    );
  };

  const handleClick = () => {
    navigate('/pricing');
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Page title="Profile" subtitle="View and manage your profile">
          <Card>
            <Grid>
              <Grid.Cell columnSpan={{xs: 6, sm: 6, md: 6, lg: 10, xl: 10}}>
                <Grid>
                  <Grid.Cell columnSpan={{xs: 3, sm: 3, md: 3, lg: 4, xl: 4}}>
                    <Stack vertical>
                      <Stack.Item>
                        <strong>Company Name</strong>
                      </Stack.Item>
                      <Stack.Item>
                        <strong>Email</strong>
                      </Stack.Item>
                      <Stack.Item>
                        <strong>Current Plan</strong>
                      </Stack.Item>

                      {/* <Stack.Item>
                        <strong>Password</strong>
                      </Stack.Item> */}
                      {/* <Stack.Item>
                    <strong>Role</strong>
                  </Stack.Item> */}
                    </Stack>
                  </Grid.Cell>
                  <Grid.Cell columnSpan={{xs: 3, sm: 3, md: 3, lg: 4, xl: 4}}>
                    <Stack vertical>
                      <Stack.Item>{accountDetail.user.org.name}</Stack.Item>
                      <Stack.Item>{userDetails.email}</Stack.Item>
                      {/* <Stack.Item>***********</Stack.Item> */}
                      <Stack.Item>
                        {accountDetail.user.org.planName} (
                        <Button plain onClick={handleClick} removeUnderline>
                          Upgrade Plan
                        </Button>
                        )
                      </Stack.Item>
                    </Stack>
                  </Grid.Cell>
                </Grid>
              </Grid.Cell>
              <Grid.Cell columnSpan={{xs: 6, sm: 6, md: 6, lg: 2, xl: 2}}>
                <Inline align="end">
                  <Modal
                    activator={
                      <Button plain onClick={handleModalChange} removeUnderline>
                        {userDetails.isPasswordAdded ? 'Change Password' : 'Set Password'}
                      </Button>
                    }
                    open={active}
                    onClose={handleClose}
                    title={userDetails.isPasswordAdded ? 'Change Password' : 'Set Password'}
                    primaryAction={{
                      content: 'Save',
                      onAction: handleSave,
                      disabled: !flag,
                    }}
                    secondaryActions={[
                      {
                        content: 'Cancel',
                        onAction: handleClose,
                      },
                    ]}
                  >
                    <Modal.Section>
                      <Stack vertical>
                        {userDetails.isPasswordAdded ? (
                          <Stack.Item>
                            <TextField
                              value={currentPassword}
                              onChange={newValue => setCurrentPassword(newValue)}
                              label="Current Password"
                              type="password"
                              placeholder="Your Current Password"
                            />
                          </Stack.Item>
                        ) : (
                          ''
                        )}
                        <Stack.Item>
                          <TextField
                            value={newPassword}
                            onChange={newValue => setNewPassword(newValue)}
                            label="New Password"
                            type="password"
                            placeholder="Your new password"
                            error={error}
                          />
                        </Stack.Item>
                        <Stack.Item>
                          <TextField
                            value={confirmNewPassword}
                            onChange={newValue => setConfirmNewPassword(newValue)}
                            label="Conifrm New Password"
                            type="password"
                            placeholder="Your new password"
                            error={error}
                          />
                        </Stack.Item>
                      </Stack>
                    </Modal.Section>
                  </Modal>
                </Inline>
                {toast}
              </Grid.Cell>
            </Grid>
          </Card>
        </Page>
      )}
    </>
  );
};
export default Profile;
