import {Box, EmptyState} from '@shopify/polaris';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import Card from '../../components/card';
import Page from '../../components/page';
import styles from './index.module.css';

function ColdStart(props) {
  const navigate = useNavigate();
  //temporary, values will be passed later
  let pages = {
    page1: {
      heading: 'Data sync in progres',
      action: '',
      image: 'https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png',
      content:
        'Sit back & relax, this shouldn’t take long. First time data sync takes time based on the number of products, orders etc. We will notify by email once completed & ready to use',
    },
    page2: {
      heading: 'This is where you’ll view your reports ',
      action: {content: 'Create Report'},
      image: 'https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png',
      content: 'You can create,share & export custom reports.Create a custom report to get started',
    },
    page3: {
      heading: 'This is where you’ll view & manage scheduled reports ',
      action: {content: 'Schedule Report'},
      image: 'https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png',
      content:
        'You can schedule reports directly to your email inbox. Add email report to get started',
    },
    page4: {
      heading: 'Oh No!',
      image: 'https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png',
      content: 'There is no data for this customer yet.',
    },
    page5: {
      heading: 'Oh No!',
      image: 'https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png',
      content: 'There is no data for this product yet.',
    },
    page6: {
      heading: 'This is where you’ll view & manage active alerts.',
      image: 'https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png',
      content: 'You can schedule performance metrics as alerts directly to your email inbox.',
      action: {
        content: 'Create your first alert',
        onAction: () => {
          navigate('/alert-new');
        },
      },
    },
    page7: {
      heading: 'This is where you’ll view the alerts history.',
      image: 'https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png',
      content: 'Schedule a alert to get started.',
      action: {
        content: 'Create your first alert',
        onAction: () => {
          navigate('/alert-new');
        },
      },
    },
    page8: {
      heading: 'Not able to load data.',
      image: 'images/cross.png',
      content: 'Plaease try again later',
      // action: {
      //   content: 'Create your first signal',
      //   onAction: () => {
      //     navigate('/signal-new');
      //   },
      // },
    },
    page9: {
      heading: 'Your NPS is currently disabled.',
      image: 'images/sad.png',
      content: 'Start collecting NPS for deeper insights.',
      action: {
        content: 'Enable NPS',
        onAction: () => {
          navigate('/settings?_=3');
        },
      },
    },
    page10: {
      heading: "Your Marketing Channels haven't been synced.",
      image: 'images/sad.png',
      content: 'Connect them now for deeper insights.',
      action: {
        content: 'Sync Now',
        onAction: () => {
          navigate('/settings?_=1');
        },
      },
    },
  };
  let {heading, action, image, content} = pages[props.page]; //remove pages add props later
  return (
    <Page>
      <Card>
        <div className={styles.container}>
          <EmptyState heading={heading} action={action} image={image}>
            <p>{content}</p>
          </EmptyState>
        </div>
      </Card>
    </Page>
  );
}
export default ColdStart;
