import {DataTable, Link, Tooltip} from '@shopify/polaris';
import Card from '../../../lib/Card';
import Inline from '../../../lib/Inline';
import React, {useCallback, useEffect, useState} from 'react';
// import Page from '../../../components/page';
// import Select from './components/select';
import {useNavigate} from 'react-router-dom';
import {RequestApi} from '../../../api/requestReports';
import {formatDate} from '../../../features/format';
import getDataTableLoading from '../../../features/dataTableLoadingRows';
import ColdStart from '../../coldStart';
import {track} from '../../../features/track';
import InputWithSearch from '../../../components/InputWithSearch';
import Box from '../../../lib/Box';
import Button from '../../../lib/Button';
import apiToCall from '../../../features/apiToCall';
import {getSegmentList} from '../../../api/segments';
import Select from '../../metrics/metricsPage/components/select';
// import {tooltipText} from '../../metrics/dashboard/tooltipText';

const tooltipText = {
  'daily-summary': "Your brand's key metrics",
  'pnl-statement': 'Your detailed profit and loss statement',
  'products-out-of-stock': 'All your SKUs sold within the last 90 days currently out of stock',
  'products-at-risk': 'Your top selling products at risk of going out of stock soon',
  'rfm-repeat': 'Customers to be targeted for repeat purchases',
  'rfm-at-risk': 'Quality customers at risk of losing touch with your brand',
  'dropped-sales': 'Customers who have cancelled their orders without reordeing',
  'cart-recovery': 'High value customers who need a final nudge',
};

const ActiveReports = () => {
  const [popoverActive, setPopoverActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [search, setSearch] = useState('');
  const [sortOrder, setSortOrder] = useState(1);
  const [sortBy, setSortBy] = useState('reportName');
  const togglePopoverActive = useCallback(
    () => setPopoverActive(popoverActive => !popoverActive),
    []
  );
  const [segmentList, setSegmentList] = useState([]);
  const [redirectUrlMap, setRedirectUrlMap] = useState({});
  const activator = (
    <Button textAlign="left" fullWidth onClick={togglePopoverActive} disclosure>
      {selectedCategory}
    </Button>
  );
  const [error, setError] = useState(false);

  useEffect(() => {
    track('Page View', {
      name: 'Metrics',
    });
  }, []);

  useEffect(() => {
    setLoading(true);

    if (Object.keys(redirectUrlMap).length === 0) {
      getSegmentList({params: {type: 'Pre-built'}}).then(response => {
        setSegmentList(response.data.data);
      });
    }
    if (Object.keys(redirectUrlMap).length > 0) {
      RequestApi.requestedReports({
        categorize: false,
        type: 'pre-built-active-reports',
        category: selectedCategory,
        search,
        sortOrder,
        sortBy,
      })
        .then(response => {
          console.log(response);
          if (!response.status) {
            setError(true);
          } else {
            let reports = response.data.map(report => [
              <Tooltip
                content={
                  redirectUrlMap[report.slag] ||
                  tooltipText[report.slag] ||
                  `/metrics/${report.slag}`
                }
              >
                <Link
                  url={apiToCall[report.slag].redirectUrl}
                  removeUnderline
                  onClick={event => {
                    event.preventDefault();
                    navigate(
                      redirectUrlMap[report.slag] ||
                        apiToCall[report.slag].redirectUrl ||
                        `/metrics/${report.slag}`
                    );
                  }}
                  key="emerald-silk-gown"
                >
                  <span style={{cursor: 'pointer'}}>{report.reportName}</span>
                </Link>
              </Tooltip>,
              <>{report.category}</>,
              <>{report.frequency || 'daily'}</>,
              <>{report.lastViewed && formatDate({value: new Date(report.lastViewed)})}</>,
              <>{report.lastViewedBy}</>,
            ]);
            setRows([...reports]);
          }
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          setError(true);
        });
    }
  }, [selectedCategory, search, sortBy, sortOrder, redirectUrlMap]);

  useEffect(() => {
    if (segmentList.length > 0) {
      let obj = {};
      segmentList.forEach(e => {
        if (e.name === 'Repeat/Loyal customers') {
          obj['rfm-repeat'] = `/segment/${e._id}`;
        } else if (e.name === 'At-Risk') {
          obj['rfm-at-risk'] = `/segment/${e._id}`;
        }
      });
      setRedirectUrlMap({
        ...redirectUrlMap,
        ...obj,
      });
    }
  }, [segmentList]);

  return (
    <>
      <Box width="100%" paddingBlockEnd={'4'}>
        {error ? (
          <ColdStart page="page8" />
        ) : (
          <Card>
            <Box paddingBlockStart={'3'}>
              <Inline align="space-between">
                <Box width="75%" padding={'3'}>
                  <InputWithSearch queryValue={search} setQueryValue={setSearch} />
                </Box>
                <Box width="21%">
                  <Inline align="end">
                    <Box width="90%" paddingInlineEnd={'3'}>
                      <Select
                        options={[
                          {
                            content: 'All',
                            active: selectedCategory === 'All',
                            onAction: () => {
                              setSelectedCategory('All');
                              togglePopoverActive();
                            },
                          },
                          {
                            content: 'Customers',
                            active: selectedCategory === 'Customers',

                            onAction: () => {
                              setSelectedCategory('Customers');
                              togglePopoverActive();
                            },
                          },
                          {
                            content: 'Orders',
                            active: selectedCategory === 'Orders',
                            onAction: () => {
                              setSelectedCategory('Orders');
                              togglePopoverActive();
                            },
                          },
                          {
                            content: 'Products',
                            active: selectedCategory === 'Products',
                            onAction: () => {
                              setSelectedCategory('Products');
                              togglePopoverActive();
                            },
                          },
                        ]}
                        popoverActive={popoverActive}
                        togglePopoverActive={togglePopoverActive}
                        activator={activator}
                      />
                    </Box>
                  </Inline>
                </Box>
              </Inline>
            </Box>
            <Box paddingBlockStart={'1'}>
              <DataTable
                sortable={[true, false, false, true, false]}
                columnContentTypes={['text', 'text', 'text', 'text']}
                headings={['Reports', 'Category', 'Frequency', 'Last viewed', 'Last Viewed By']}
                rows={loading ? getDataTableLoading(4) : rows}
                defaultSortDirection="ascending"
                initialSortColumnIndex={0}
                onSort={(e, i) => {
                  let order;
                  let column;
                  if (e === 0) {
                    column = 'reportName';
                  }
                  if (e === 2) {
                    column = 'lastViewed';
                  }
                  if (i === 'ascending') {
                    order = -1;
                  } else {
                    order = 1;
                  }
                  setSortOrder(order);
                  setSortBy(column);
                }}
              />
            </Box>
          </Card>
        )}
      </Box>
    </>
  );
};

export {ActiveReports};
