const ACCOUNT_DETAILS = 'ACCOUNT_DETAILS';
const RESET_TIME = 'RESET_TIME';
const ADMIN_DETAILS = 'ADMIN_DETAILS';
const ONBOARDING = 'ONBOARDING';

export class LocalStorageService {
  static setAccountDetails = accountDetails => {
    accountDetails = {
      ...accountDetails,
      sign_in_time: Date.now(),
    };
    const accountDetailsString = JSON.stringify(accountDetails);
    localStorage.setItem(ACCOUNT_DETAILS, accountDetailsString);
  };

  static removeAccountDetails = () => {
    localStorage.removeItem(ACCOUNT_DETAILS);
  };

  static getAccountDetails = () => {
    const accountDetailsString = localStorage.getItem(ACCOUNT_DETAILS);
    if (accountDetailsString) {
      const accountDetails = JSON.parse(accountDetailsString);
      return accountDetails;
    } else {
      return null;
    }
  };

  static getUserOrg = () => {
    const accountDetails = this.getAccountDetails();
    return accountDetails?.user?.org;
  };

  static getUserProfile = () => {
    const accountDetails = this.getAccountDetails();
    return accountDetails?.user?.profile;
  };

  static setResetTime = () => {
    localStorage.setItem(RESET_TIME, Date.now().toString());
  };

  static getResetTime = () => {
    return localStorage.getItem(RESET_TIME);
  };

  static clear = () => {
    localStorage.clear();
  };
  static setAdminOrgSignIn = accountDetails => {
    const adminAccountDetailsString = localStorage.getItem(ACCOUNT_DETAILS);
    if (adminAccountDetailsString) {
      localStorage.setItem(ADMIN_DETAILS, adminAccountDetailsString);
    } else {
      return null;
    }
    accountDetails = {
      ...accountDetails,
      sign_in_time: Date.now(),
    };
    const accountDetailsString = JSON.stringify(accountDetails);
    localStorage.setItem(ACCOUNT_DETAILS, accountDetailsString);
  };
  static removeAdminOrgSignIn = () => {
    const adminAccountDetailsString = localStorage.getItem(ADMIN_DETAILS);
    if (adminAccountDetailsString) {
      localStorage.setItem(ACCOUNT_DETAILS, adminAccountDetailsString);
    } else {
      return null;
    }
    localStorage.removeItem(ADMIN_DETAILS);
  };
  static getAdminDetails = () => {
    const adminAccountDetailsString = localStorage.getItem(ADMIN_DETAILS);
    if (adminAccountDetailsString) {
      const accountDetails = JSON.parse(adminAccountDetailsString);
      return accountDetails;
    } else {
      return null;
    }
  };
  static isAdminSignedIn = () => {
    let isAdmin = false;
    const accountDetailsString = localStorage.getItem(ACCOUNT_DETAILS);
    if (accountDetailsString) {
      const accountDetails = JSON.parse(accountDetailsString);
      isAdmin = accountDetails?.user?.profile?.userType === 'admin';
    } else {
      return false;
    }
    const adminAccountDetailsString = localStorage.getItem(ADMIN_DETAILS);
    if (adminAccountDetailsString) {
      isAdmin = true;
    }
    return isAdmin;
  };
  static setOnboarding = value => {
    localStorage.setItem(ONBOARDING, value);
  };
  static isOnboarding = () => {
    localStorage.getItem(ONBOARDING);
  };
}
