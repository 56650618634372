import {CaretDownMinor, CaretUpMinor, CircleMinusMajor} from '@shopify/polaris-icons';
import {LocalStorageService} from '../../../service/localStorage';
const getRevenueValues = (revenueObj, oposite) => {
  const userDetails = LocalStorageService.getUserOrg();
  const timeZone = userDetails?.iana_timezone;
  const revenue = [{}];
  let keysArr = Object.keys(revenueObj);
  let currDate = new Date().toLocaleString('en-US', {
    timeZone: timeZone,
  });
  currDate = new Date(currDate);
  let days = currDate.getDate();
  let currRevenuePerDay = revenueObj[keysArr[0]]; // / days;
  keysArr.slice(1).forEach((key, i) => {
    let value =
      currRevenuePerDay && revenueObj[key]
        ? (((currRevenuePerDay - revenueObj[key]) * 100) / revenueObj[key]).toFixed(2)
        : 'N/A';
    let valuesObj = {
      background:
        value === 'N/A' ? 'surface-warning' : value > 0 ? 'surface-success' : 'surface-critical',
      color: value === 'N/A' ? 'warning' : value > 0 ? 'success' : 'critical',
      source: value === 'N/A' ? CircleMinusMajor : value > 0 ? CaretUpMinor : CaretDownMinor,
      value: value === 'N/A' ? value : value + '%',
    };
    if (oposite) {
      valuesObj = {
        background:
          value === 'N/A' ? 'surface-warning' : value > 0 ? 'surface-critical' : 'surface-success',
        color: value === 'N/A' ? 'warning' : value > 0 ? 'critical' : 'success',
        source: value === 'N/A' ? CircleMinusMajor : value > 0 ? CaretDownMinor : CaretUpMinor,
        value: value === 'N/A' ? value : value + '%',
      };
    }
    revenue.push(valuesObj);
  });
  return revenue;
};
export {getRevenueValues};
