import React, {useState} from 'react';
import Card from '../../../../components/card';
import {Spinner, Text, TextContainer} from '@shopify/polaris';
import {useNavigate} from 'react-router-dom';
import {DeleteModal} from '../../../../components/modal';
import {SignalApi} from '../../../../api/signal';
const daysObject = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};
const ActiveSignalCard = ({signal, fetchSignals, triggerSignal, trigger}) => {
  const [openDelete, setOPenDelete] = useState(false);
  const deleteSignal = () => {
    setOPenDelete(true);
  };
  const navigate = useNavigate();
  const editSignal = () => {
    navigate(`/signal/edit/${signal._id}`);
  };
  const deleteFun = async () => {
    await SignalApi.deleteSignal(signal._id);
    fetchSignals();
  };
  return (
    <>
      <Card
        title={signal.emailSubject}
        actions={[
          {
            content: trigger.state === 'Loading' ? <Spinner size="sm" /> : 'Send Now',
            destructive: false,
            onAction: () => {
              triggerSignal(signal._id);
            },
          },
          {
            content: 'Edit',
            destructive: false,
            onAction: editSignal,
          },
          {
            content: 'Delete',
            destructive: true,
            onAction: deleteSignal,
          },
        ]}
      >
        <TextContainer spacing="tight">
          <Text color="subdued" as="p" variant="bodyMd">
            Created by {signal.email}
          </Text>
          <Text color="subdued" as="p" variant="bodyMd">
            Scheduled to be sent at {signal.minutes ? signal.minutes : '00'} min,{' '}
            {signal.schedule.hoursType === 'everyHour'
              ? 'Every hour'
              : `${signal.schedule.hours.join(' ')} hours of `}{' '}
            {signal.schedule.daysType === 'everyDay'
              ? 'Daily'
              : signal.schedule.daysType === 'daysOfWeek'
              ? `${signal.schedule.days.map(a => ' ' + daysObject[a])} of every week`
              : `${signal.schedule.days.join(' ')} days of Month`}
            ,{' '}
          </Text>
          <Text color="subdued" as="p" variant="bodyMd">
            Scheduled to {signal.recipients.join(', ')}
          </Text>
        </TextContainer>
      </Card>
      {openDelete && (
        <DeleteModal
          currentState={openDelete}
          deleteContent="signal"
          onDelete={deleteFun}
          onClose={setOPenDelete}
        />
      )}
    </>
  );
};
export default ActiveSignalCard;
