import {Popover, OptionList} from '@shopify/polaris';
import {useState, useCallback} from 'react';
import Button from '../lib/Button';

const SelectComponent = ({
  setDurationFilter,
  durationFilter,
  setPage,
  setQueryValue = () => {},
}) => {
  const [popoverActive, setPopoverActive] = useState(false);
  const togglePopoverActive = useCallback(
    () => setPopoverActive(popoverActive => !popoverActive),
    []
  );
  const buttonLabelSelector = {
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
  };
  const activator = (
    <Button onClick={togglePopoverActive} disclosure>
      {buttonLabelSelector[durationFilter]}
    </Button>
  );
  return (
    <Popover active={popoverActive} activator={activator} onClose={togglePopoverActive}>
      <OptionList
        selected={durationFilter}
        options={[
          {label: 'Daily', value: 'daily', active: false},
          {label: 'Weekly', value: 'weekly', active: false},
          {label: 'Monthly', value: 'monthly', active: false},
        ]}
        onChange={value => {
          setDurationFilter(value[0]);
          if (setPage) {
            setPage(1);
            setQueryValue(null);
          }

          togglePopoverActive();
        }}
      />
    </Popover>
  );
};
export default SelectComponent;
