import {Filters} from '@shopify/polaris';

const InputWithSearch = ({queryValue, setQueryValue}) => {
  return (
    <>
      <Filters
        queryValue={queryValue}
        filters={[]}
        appliedFilters={[]}
        onQueryChange={setQueryValue}
        onQueryClear={() => setQueryValue('')}
      />
    </>
  );
};

export default InputWithSearch;
