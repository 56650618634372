import {Route} from 'react-router-dom';
import React from 'react';
import {ProductNPS} from '../../pages/npsSurvey/product';

// import Admin from "../../pages/admin";
const NpsRoutes = (
  <>
    <Route
      path="/product-nps/:npsId"
      element={
        // <PublicRoute>
        <ProductNPS />
        // </PublicRoute>
      }
    />
    <Route
      path="/product-nps/test"
      element={
        // <PublicRoute>
        <ProductNPS fromTestEmail={true} />
        // </PublicRoute>
      }
    />
  </>
);

export default NpsRoutes;
