import React, {useEffect, useMemo, useState, useCallback} from 'react';
import {AdminApi} from '../../../api/admin';
import {adminWebhookLogsColumns} from '../../metrics/columns';
import Page from '../../../components/page';
import DefaultIndexTable from '../../../components/defaultIndexTable';
import MultiSelect from '../../../components/multiSelect';
import topicsOptions from './topicOpitions';
import JsonViewerModal from '../../../components/Modals/JsonViewerModal';
import Inline from '../../../lib/Inline';
import useApiCall from '../../../hooks/useApiCall';

import DateRangeComponent from '../../../components/dateRangeComponent';
import {
  thirtyDaysBefore,
  todayDate,
  yesterDayDate,
  ninetyDaysBefore,
  sevenDaysBefore,
  dateToNormalString,
  toClickHouseDate,
  oneYearBefore,
} from '../../../features/convertDates';
import {formatDate} from '../../../features/format';
import Box from '../../../lib/Box';
import Button from '../../../lib/Button';

const dateRangeObj = {
  yesterday: yesterDayDate,
  today: todayDate,
  lastThirtyDays: thirtyDaysBefore,
  lastSevenDays: sevenDaysBefore,
  lastNinetyDays: ninetyDaysBefore,
  lastOneYear: oneYearBefore,
};
const dateRangeValueToLable = {
  today: 'Today',
  yesterday: 'Yesterday',
  lastSevenDays: 'Last 7 Days',
  lastThirtyDays: 'Last 30 Days',
  lastNinetyDays: 'Last 90 Days',
  lastOneYear: 'Last 1 Year',
};
const Webhooks = () => {
  const [sort, setSort] = useState({by: 'c', order: 'desc'});
  const [orgsOptions, setOrgsOptions] = useState([]);
  const [orgs, setOrgs] = useState('');
  const [topic, setTopic] = useState('');
  const [dataToPreview, setDataToPreview] = useState(null);
  const [organizationIdToNameMap, setOrganizationIdToNameMap] = useState(null);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [popoverActive, setPopoverActive] = useState(false);
  const [dateRange, setDateRange] = useState('today');
  const [selectedDates, setSelectedDates] = useState(
    dateRange
      ? {
          start: new Date(dateRangeObj[dateRange]()[0]),
          end: new Date(dateRangeObj[dateRange]()[1]),
        }
      : {
          start: new Date(),
          end: new Date(),
        }
  );
  const getDates = (dates, dateOption) => {
    setSelectedDates(dates);
    setDateRange(dateOption);
  };
  const fetchWebhookLogs = async () => {
    let sdate = toClickHouseDate(selectedDates.start, 'start');
    let edate = toClickHouseDate(selectedDates.end, 'end');
    if (Boolean(organizationIdToNameMap)) {
      let res = await AdminApi.getWebhookLogs({
        sortBy: sort.by,
        sortOrder: sort.order,
        page,
        orgId: orgs,
        topic: topic,
        search,
        startDate: sdate,
        endDate: edate,
      });
      return res;
    }
  };
  const webhookLogsApiDependencies = [
    sort,
    page,
    orgs,
    topic,
    search,
    selectedDates,
    organizationIdToNameMap,
  ];
  const _webhooks = useApiCall(fetchWebhookLogs, webhookLogsApiDependencies);
  const webhooks = useMemo(() => {
    if (_webhooks.state === 'Success') {
      return {state: _webhooks.state, ..._webhooks.data};
    }
    return {data: {data: []}, state: _webhooks.state, ..._webhooks};
  }, [_webhooks]);

  const fetchOrganizations = async () => {
    try {
      let res = await AdminApi.getOrgs();
      let arr = [{label: 'All', value: ''}];
      let map = {};
      if (res?.status) {
        res.data.forEach(r => {
          arr.push({label: r.name, value: r._id});
          map[r._id] = r.name;
        });
        setOrganizationIdToNameMap({...map});
        setOrgsOptions(arr);
      } else {
        setOrgsOptions([]);
      }
    } catch (err) {
      setOrgsOptions([]);
    }
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const togglePopoverActive = useCallback(
    () => setPopoverActive(popoverActive => !popoverActive),
    []
  );
  const activator = (
    <div style={{width: '100%'}}>
      <Button onClick={togglePopoverActive} disclosure>
        {dateRange ? dateRangeValueToLable[dateRange] : dateToNormalString(selectedDates)}
      </Button>
    </div>
  );
  return (
    <>
      {' '}
      <Page title="Webhook logs">
        <DefaultIndexTable
          queryPlaceholder="Search by logId, dataId, splitId"
          filters={[
            <MultiSelect
              title="Organization"
              options={orgsOptions}
              selected={orgs}
              setSelected={setOrgs}
              disableMultiple={true}
              setPage={setPage}
            />,
            <MultiSelect
              title="Topic"
              options={topicsOptions}
              selected={topic}
              setSelected={setTopic}
              disableMultiple={true}
              setPage={setPage}
            />,
            <Box>
              <DateRangeComponent
                currentDates={selectedDates}
                getDates={getDates}
                activator={activator}
                popoverActive={popoverActive}
                togglePopoverActive={togglePopoverActive}
                currentDateOption={dateRange}
              />
            </Box>,
          ]}
          isSearchEnabled
          sortBy={sort.by}
          setSortBy={by => {
            setSort({...sort, by});
          }}
          setQueryValue={setSearch}
          queryValue={search}
          sortExceptions={[]}
          sortOrder={sort.order}
          setSortOrder={order => {
            setSort({...sort, order});
          }}
          select={false}
          page={page}
          setPage={setPage}
          resourceName={{
            singular: 'Webhook log',
            plural: 'Webhook logs',
          }}
          columns={adminWebhookLogsColumns}
          selectedFilters={[]}
          durationFilter={'daily'}
          totalRows={webhooks.data.length ? webhooks.totalRows : 0}
          tableData={webhooks.data}
          param={{}}
          state={webhooks.state}
          valueTypes={{
            data: (data, col) => {
              return data.data[col.value];
            },
            showJson: (data, col) => {
              return (
                <Button
                  key={data._id}
                  plain
                  onClick={() => {
                    setDataToPreview(data?.data);
                  }}
                >
                  Show Data
                </Button>
              );
            },
            organization: (data, col) => {
              return organizationIdToNameMap[data[col.value]];
            },
            date: (data, col) => {
              return (
                <Inline align="end">
                  {formatDate({
                    value: new Date(data[col.value]),
                    removeTime: false,
                    seconds: true,
                  })}
                </Inline>
              );
            },
          }}
        />
        {Boolean(dataToPreview) && (
          <JsonViewerModal
            isOpen={Boolean(dataToPreview)}
            toggle={() => setDataToPreview(null)}
            data={dataToPreview}
          />
        )}
      </Page>
    </>
  );
};

export default Webhooks;
