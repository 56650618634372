import axios from './axios';
import config from '../config';
import {LocalStorageService} from '../service/localStorage';
const {BASE_URL} = config;

export class SignalApi {
  static async createSignal(input) {
    try {
      const userDetails = LocalStorageService.getUserProfile();
      let email = userDetails?.email;
      input.email = email;
      const response = await axios.post(`${BASE_URL}/reports/signal`, input);
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
  static async getAllSignals() {
    try {
      const response = await axios.get(`${BASE_URL}/reports/all-signals`);
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
  static async exportHistory() {
    try {
      const response = await axios.get(`${BASE_URL}/reports/export-history`);
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
  static async getSignal(id) {
    try {
      const response = await axios.get(`${BASE_URL}/reports/signal?id=${id}`);
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
  static async deleteSignal(id) {
    try {
      const response = await axios.delete(`${BASE_URL}/reports/signal?id=${id}`);
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
  static async updateSignal(input) {
    try {
      const userDetails = LocalStorageService.getUserProfile();
      let email = userDetails?.email;
      input.email = email;
      const response = await axios.patch(`${BASE_URL}/reports/signal?id=${input._id}`, input);
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
  static async triggerSignal(id) {
    try {
      const response = await axios.get(`${BASE_URL}/reports/trigger-signal?id=${id}`);
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }
}
