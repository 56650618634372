import React, {useEffect, useState} from 'react';
import {LocalStorageService} from './../service/localStorage/index';
import {formatDate} from '../features/format';

function getLastDateOfCurrentMonth() {
  // Get the current date
  const now = new Date();
  // Set the date to the first day of the next month
  const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
  // Subtract one day to get the last day of the current month
  nextMonth.setDate(nextMonth.getDate() - 1);

  // Options for formatting the date
  const options = {year: 'numeric', month: 'short', day: 'numeric'};
  // Format the date to desired format (e.g., "Feb 29, 2024")
  const formattedDate = nextMonth.toLocaleDateString('en-US', options);

  return formattedDate;
}

// Usage
const lastDate = getLastDateOfCurrentMonth();
const CustomHeader = () => {
  const [date, setDate] = useState();
  const [trialPeriodStatus, setTrialPeriodStatus] = useState();
  useEffect(() => {
    let details = LocalStorageService.getUserOrg();
    setTrialPeriodStatus(details?.trialPeriodStatus);
    setDate(details?.trialPeriodEndsOn);
  }, []);

  const trialEndDate = date ? formatDate({value: new Date(date), removeTime: true}) : null;

  const customHeaderStyle = {
    textAlign: 'center',
    padding: '10px',
    backgroundColor: 'rgba(105, 205, 255,0.2)',
  };

  return trialEndDate && trialPeriodStatus === 'active' ? (
    <div style={customHeaderStyle}>
      You are currently on trial period till {lastDate}. For any help with trial period, billing,
      and payments, reach out to us at{' '}
      <a href="mailto:support@retainwise.io">support@retainwise.io</a>.
    </div>
  ) : (
    <></>
  );
};

export default CustomHeader;
