import React, {useEffect, useState} from 'react';
import {Text, Inline, Box, Frame, Grid, Link, Loading} from '@shopify/polaris';
import ForgotInput from './forgotInput';
import ForgotMessage from './message';
import {AuthAPI} from '../../../api/auth';
import Alert from '../../../components/alert';
import {emailValidator} from '../../../features/validations';
import {useLoadingStore} from '../../../app/store';
import {useNavigate} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import {track} from '../../../features/track';

const ForgotPassword = () => {
  const loading = useLoadingStore(state => state.loading);
  const navigate = useNavigate();

  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState('');
  const [alertError, setAlertError] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [emailError, setEmailError] = useState(false);

  useEffect(() => {
    track('Page View', {
      name: 'Forgot Password',
    });
  }, []);

  const handleSubmit = async () => {
    setAlertError('');
    if (!emailValidator(email)) {
      setEmailError(true);
    } else {
      try {
        const response = await AuthAPI.resetPasswordRequest({
          email,
        });
        if (!response.status) {
          setAlertError(response.message);
        } else {
          setAlertError('');
          setEmailSent(true);
        }
      } catch (err) {
        setAlertError('Something went wrong');
        Sentry.captureException(err);
      }
    }
  };
  useEffect(() => {
    if (email) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [email]);
  return (
    <Frame>
      <div className="bg1">
        {loading && <Loading />}
        <Grid>
          <Grid.Cell columnSpan={{xs: 6, sm: 6, md: 6, lg: 6, xl: 6}}>
            <Box minHeight="100vh" background="surface">
              <Inline align="space-between" blockAlign="center">
                <Box padding="8">
                  <img alt="#" width={'180px'} src="/images/logo.svg" />
                </Box>
                <Box padding="8" paddingBlockStart={'10'}>
                  <Inline>
                    <Text alignment="end" fontWeight="semibold">
                      <Link
                        url={'/auth/signin'}
                        onClick={event => {
                          event.preventDefault();
                          navigate('/auth/signin');
                        }}
                      >
                        Already have an retainwise account? Sign in
                      </Link>
                    </Text>
                  </Inline>
                </Box>
              </Inline>

              <>
                <Box paddingBlockStart={'12'}>
                  {emailSent ? (
                    <ForgotMessage email={email} />
                  ) : (
                    <>
                      <Text variant="heading3xl" as="h2" alignment="center">
                        Forgot Password
                      </Text>
                      <ForgotInput
                        setEmail={setEmail}
                        email={email}
                        handleSubmit={handleSubmit}
                        disabled={buttonDisabled}
                        emailError={emailError}
                      />
                    </>
                  )}
                  {alertError && <Alert contents={alertError} init={true} error={true} />}
                </Box>
              </>
            </Box>
          </Grid.Cell>
          <Grid.Cell columnSpan={{xs: 5, sm: 2, md: 2, lg: 5, xl: 6}}>
            <Box minHeight="90vh"></Box>
          </Grid.Cell>
        </Grid>
      </div>
    </Frame>
  );
};
export default ForgotPassword;
