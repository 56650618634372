import axios from '../axios';
import config from '../../config';

const {BASE_URL} = config;

export async function updatePassword(updatePassword) {
  try {
    const response = await axios.post(`${BASE_URL}/user-profile/update-password`, updatePassword);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function getProfile() {
  try {
    const response = await axios.get(`${BASE_URL}/user-profile/get-profile`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function setPassword(updatePassword) {
  try {
    const response = await axios.post(`${BASE_URL}/user-profile/set-password`, updatePassword);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
