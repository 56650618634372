import axios from './axios';
import config from '../config';

const {BASE_URL} = config;
export async function products(params) {
  try {
    const response = await axios.get(`${BASE_URL}/reports/products`, params);
    return response;
  } catch (err) {
    return err.response.data;
  }
}
export async function productSalesByCity({variantId}) {
  try {
    const response = await axios.get(
      `${BASE_URL}/reports/product/sales-by-city?variantId=${variantId}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function productSalesByCountry({variantId}) {
  try {
    const response = await axios.get(
      `${BASE_URL}/reports/product/sales-by-country?variantId=${variantId}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function productSalesByPaymentMethod({variantId}) {
  try {
    const response = await axios.get(
      `${BASE_URL}/reports/product/sales-by-payment-method?variantId=${variantId}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function productSalesByChannel({variantId}) {
  try {
    const response = await axios.get(
      `${BASE_URL}/reports/product/sales-by-channel?variantId=${variantId}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function productSalesByDay({variantId}) {
  try {
    const response = await axios.get(
      `${BASE_URL}/reports/product/sales-by-day?variantId=${variantId}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function productSalesByHour({variantId}) {
  try {
    const response = await axios.get(
      `${BASE_URL}/reports/product/sales-by-hour?variantId=${variantId}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}

export async function economicsOfProduct({variantId}) {
  try {
    const response = await axios.get(
      `${BASE_URL}/reports/product/economics?variantId=${variantId}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function frequentlyPurchasedTogetherOfProduct({variantId}) {
  try {
    const response = await axios.get(
      `${BASE_URL}/reports/product/frequently-bought-together?variantId=${variantId}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function profileDataOfProduct({variantId}) {
  try {
    const response = await axios.get(`${BASE_URL}/reports/product/profile?variantId=${variantId}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function productNpsGraph({variantId}) {
  try {
    const response = await axios.get(
      `${BASE_URL}/reports/product/product-nps-graph?variantId=${variantId}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function conversionMetricsOfProduct(params) {
  try {
    const response = await axios.get(`${BASE_URL}/reports/product/conversion-metrics`, {params});
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
