import {LocalStorageService} from '../service/localStorage';

const updatePosthogIdentity = (posthog, options) => {
  let email;
  let org;
  if (options) {
    email = options.email;
    org = options.org;
  } else {
    const userDetails = LocalStorageService.getUserProfile();
    const orgDetails = LocalStorageService.getUserOrg();
    email = userDetails?.email;
    org = orgDetails?.name;
  }
  const adminDetails = LocalStorageService.getAdminDetails();
  if (email && !adminDetails) {
    posthog.identify(email, {
      email,
    });
    posthog.group('company', org);
  }
};

export {updatePosthogIdentity};
