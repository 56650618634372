import {Page} from '@shopify/polaris';
import React from 'react';

function MyPage({children, ...props}) {
  return (
    <Page fullWidth {...props}>
      {children}
    </Page>
  );
}

export default MyPage;
