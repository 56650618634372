import create from 'zustand';
import {mountStoreDevtool} from 'simple-zustand-devtools';

import {
  thirtyDaysBefore,
  todayDate,
  yesterDayDate,
  ninetyDaysBefore,
  sevenDaysBefore,
  previousPeriod,
  previousYear,
} from '../features/convertDates';

const dateRangeObj = {
  yesterday: yesterDayDate,
  today: todayDate,
  lastThirtyDays: thirtyDaysBefore,
  lastSevenDays: sevenDaysBefore,
  lastNinetyDays: ninetyDaysBefore,
};
const dateCompareObj = {
  previousPeriod: previousPeriod,
  previousYear: previousYear,
  noComparison: () => {
    return ['', ''];
  },
};
const useAuthStore = create(set => ({
  email: '',
  orgId: '',
  isVerifying: false,
  setEmail: email => set(state => ({...state, email: email})),
  setIsVerifying: isVerify => set(state => ({...state, isVerifying: isVerify})),
  setOrgId: orgId => set(state => ({...state, orgId})),
}));

const useSaveChangesStore = create(set => ({
  isUnchanged: false,
  button: false,
  onSubmit: () => {},
  onDiscard: () => {},
  setIsUnchanged: isUnchanged => set(state => ({...state, isUnchanged: isUnchanged})),

  setOnSubmit: onSubmit => set(state => ({...state, onSubmit: onSubmit})),
  setOnDiscard: onDiscard => set(state => ({...state, onDiscard: onDiscard})),
  setButtonEnabled: value => set(state => ({...state, button: value})),
}));

const usePreventNavigateStore = create(set => ({
  protect: false,
  setProtect: p => set(state => ({...state, protect: p})),
}));

const useLoadingStore = create(set => ({
  loading: false,
  setLoading: p => set(state => ({...state, loading: p})),
}));

let initDates = {
  start: new Date(dateRangeObj['lastThirtyDays']()[0]),
  end: new Date(dateRangeObj['lastThirtyDays']()[1]),
};

const useStoreInfo = create(set => ({
  sourceNames: [],
  setSourceNames: d => set(state => ({...state, sourceNames: d})),
}));

const useSnapshotStore = create(set => ({
  data: {c1: [], c2: [], c3: []},
  state: 'Loading',
  error: null,
  widgetData: {},
  selectedDateRange: 'lastThirtyDays',
  selectedCompare: 'previousPeriod',
  durationFilter: 'daily',
  selectedDateRangeDates: {
    ...initDates,
  },
  selectedCompareDates: {
    start: new Date(dateCompareObj['previousPeriod'](initDates)[0]),
    end: new Date(dateCompareObj['previousPeriod'](initDates)[1]),
  },
  setData: d => set(state => ({...state, data: d})),
  setState: d => set(current => ({...current, state: d})),
  setError: d => set(state => ({...state, error: d})),
  setSelectedDateRange: d => set(state => ({...state, selectedDateRange: d})),
  setWidgetData: d => set(state => ({...state, widgetData: d})),
  setSelectedCompare: d => set(state => ({...state, selectedCompare: d})),
  setDurationFilter: d => set(state => ({...state, durationFilter: d})),
  setSelectedDateRangeDates: d => set(state => ({...state, selectedDateRangeDates: d})),
  setSelectedCompareDates: d => set(state => ({...state, selectedCompareDates: d})),
  resetAll: () => {
    set(state => ({
      ...state,
      data: {c1: [], c2: [], c3: []},
      state: 'Loading',
      error: null,
      widgetData: {},
      selectedDateRange: 'lastThirtyDays',
      selectedCompare: 'previousPeriod',
      durationFilter: 'daily',
      selectedDateRangeDates: {
        ...initDates,
      },
      selectedCompareDates: {
        start: new Date(dateCompareObj['previousPeriod'](initDates)[0]),
        end: new Date(dateCompareObj['previousPeriod'](initDates)[1]),
      },
    }));
  },
}));
const useDateRangeStore = create(set => ({
  selectedDateRange: 'lastThirtyDays',
  selectedCompare: 'previousPeriod',
  durationFilter: 'daily',
  selectedDateRangeDates: {
    ...initDates,
  },
  selectedCompareDates: {
    start: new Date(dateCompareObj['previousPeriod'](initDates)[0]),
    end: new Date(dateCompareObj['previousPeriod'](initDates)[1]),
  },
  set: set,
  setSelectedDateRange: d => set(state => ({...state, selectedDateRange: d})),
  setSelectedCompare: d => set(state => ({...state, selectedCompare: d})),
  setDurationFilter: d => set(state => ({...state, durationFilter: d})),
  setSelectedDateRangeDates: d => set(state => ({...state, selectedDateRangeDates: d})),
  setSelectedCompareDates: d => set(state => ({...state, selectedCompareDates: d})),
  update: d => set(state => ({...state, ...d})),
  resetAll: () => {
    set(state => ({
      ...state,
      selectedDateRange: 'lastThirtyDays',
      selectedCompare: 'previousPeriod',
      durationFilter: 'daily',
      selectedDateRangeDates: {
        ...initDates,
      },
      selectedCompareDates: {
        start: new Date(dateCompareObj['previousPeriod'](initDates)[0]),
        end: new Date(dateCompareObj['previousPeriod'](initDates)[1]),
      },
    }));
  },
}));
if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('Store', useSnapshotStore);
  mountStoreDevtool('UnsavedChanges Store', useSaveChangesStore);
  mountStoreDevtool('Date range store', useDateRangeStore);
}

export {
  useAuthStore,
  useSaveChangesStore,
  usePreventNavigateStore,
  useLoadingStore,
  useSnapshotStore,
  useStoreInfo,
  useDateRangeStore,
};
