import React, {useState, useEffect, useCallback} from 'react';
import {Popover, Button, OptionList} from '@shopify/polaris';

const YearlyQuarterSelectComponent = ({durationFilter, onSelect}) => {
  const [selectedOption, setSelectedOption] = useState('Yearly');
  const [popoverActive, setPopoverActive] = useState(false);
  const togglePopoverActive = useCallback(
    () => setPopoverActive(popoverActive => !popoverActive),
    []
  );
  // const handleSelectChange = value => {
  //   setSelectedOption(value);
  // };

  const activator = (
    <Button onClick={togglePopoverActive} disclosure>
      {selectedOption}
    </Button>
  );

  useEffect(() => {
    // Calculate startDate and endDate based on the selected option and durationFilter
    let startDate, endDate;
    if (selectedOption === 'Yearly') {
      startDate = `${durationFilter}-01-01T05:00:00`;
      endDate = `${durationFilter}-12-31T04:59:59`;
    } else {
      const quarterStartMonth = {
        Q1: 1,
        Q2: 4,
        Q3: 7,
        Q4: 10,
      }[selectedOption];

      // const daysInMonth = new Date(durationFilter, quarterStartMonth, 0).getDate();
      const quarterEndMonth = quarterStartMonth + 2;
      const lastDayOfMonth =
        quarterEndMonth === 12 ? 31 : new Date(durationFilter, quarterEndMonth, 0).getDate();
      startDate = `${durationFilter}-${quarterStartMonth.toString().padStart(2, '0')}-01T05:00:00`;
      endDate = `${durationFilter}-${quarterEndMonth.toString().padStart(2, '0')}-${lastDayOfMonth
        .toString()
        .padStart(2, '0')}T04:59:59`;
    }
    // Pass startDate and endDate to the parent component
    onSelect({startDate, endDate});
  }, [selectedOption, durationFilter]);

  const options = [
    {label: 'Yearly', value: 'Yearly'},
    {label: '1st Quarter', value: 'Q1'},
    {label: '2nd Quarter', value: 'Q2'},
    {label: '3rd Quarter', value: 'Q3'},
    {label: '4th Quarter', value: 'Q4'},
  ];

  return (
    <Popover active={popoverActive} activator={activator} onClose={togglePopoverActive}>
      <OptionList
        selected={selectedOption}
        options={options}
        onChange={value => {
          setSelectedOption(value[0]);
          togglePopoverActive();
        }}
      />
      ;
    </Popover>
  );
};

export default YearlyQuarterSelectComponent;
