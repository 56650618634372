import {TopBar} from '@shopify/polaris';
import React, {useState, useCallback} from 'react';
import {ExitMajor, ProfileMajor} from '@shopify/polaris-icons';
import {useNavigate} from 'react-router-dom';
import {clearAll} from '../../features/clearData';
const AdminTopbar = ({mobileNavigationActive, setMobileNavigationActive}) => {
  const navigate = useNavigate();
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const toggleIsUserMenuOpen = useCallback(
    () => setIsUserMenuOpen(isUserMenuOpen => !isUserMenuOpen),
    []
  );
  const toggleMobileNavigationActive = useCallback(
    () => setMobileNavigationActive(mobileNavigationActive => !mobileNavigationActive),
    []
  );

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={[
        {
          items: [
            {
              content: 'Profile',
              icon: ProfileMajor,
              onAction: () => {
                navigate('/admin/profile');
              },
            },
            {
              content: 'Logout',
              icon: ExitMajor,
              onAction: () => {
                clearAll();
                navigate('/');
              },
            },
          ],
        },
      ]}
      name={'Admin'}
      open={isUserMenuOpen}
      onToggle={toggleIsUserMenuOpen}
    />
  );
  return (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );
};

export {AdminTopbar};
