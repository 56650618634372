import React, {useState} from 'react';
import {
  Icon,
  TextField,
  Listbox,
  AutoSelection,
  Scrollable,
  EmptySearchResult,
  Button,
  Text,
  Popover,
} from '@shopify/polaris';
import {SearchMinor} from '@shopify/polaris-icons';
import {getTransformedProductTitle} from '../features/getTransformedProductTitle';
import useApiCall from '../hooks/useApiCall';
import {getVariantList} from '../api/filterLists';

const listMap = {
  products: ({title, sku, variantTitle}, i) => {
    const tranformedTitle = getTransformedProductTitle({sku, variantTitle, title});
    return (
      <Listbox.Option key={i} value={i} selected={-1}>
        <Listbox.TextOption>{tranformedTitle}</Listbox.TextOption>
      </Listbox.Option>
    );
  },
};
const placeholderMap = {
  products: 'Enter product title or SKU',
  productCategory: 'Enter product category',
  discountCodes: 'Enter discount code',
  billingCity: 'Enter billing city',
  billingProvince: 'Enter billing province/state',
  billingCountry: 'Enter billing country',
  defaultCity: 'Enter default city',
  defaultProvince: 'Enter default province/state',
  defaultCountry: 'Enter default country',
  shippingCity: 'Enter shipping city',
  shippingProvince: 'Enter shipping province/state',
  shippingCountry: 'Enter shipping country',
};

const itemTypeMap = {
  products: 'products',
  productCategory: 'product category',
  discountCodes: 'discount codes',
  billingCity: 'billing city',
  billingProvince: 'billing province/state',
  billingCountry: 'billing country',
  defaultCity: 'default city',
  defaultProvince: 'default province/state',
  defaultCountry: 'default country',
  shippingCity: 'shipping city',
  shippingProvince: 'shipping province/state',
  shippingCountry: 'shipping country',
};
const itemSelectionMap = {
  products: 'variantId',
  productCategory: 'productCategory',
  discountCodes: 'discountCode',
  billingCity: 'billingCity',
  billingProvince: 'billingProvince',
  billingCountry: 'billingCountry',
  defaultCity: 'defaultCity',
  defaultProvince: 'defaultProvince',
  defaultCountry: 'defaultCountry',
  shippingCity: 'shippingCity',
  shippingProvince: 'shippingProvince',
  shippingCountry: 'shippingCountry',
  journeyType: 'journeyType',
};
const FilterDropDown = ({
  itemList,
  setSelectedItem,
  setQuery,
  query,
  itemType,
  label,
  setIsOpen,
  selectedItem,
  isSearchEnabled = true,
  isActivatorNeeded = true,
}) => {
  const [pickerOpen, setPickerOpen] = useState(false);
  const [selected, setSelected] = useState(selectedItem);
  const getVariant = async () => {
    if (selected && itemType === 'products') {
      let variantData = await getVariantList({params: {variantIds: selected}});
      let result = variantData;
      return {status: true, data: result.data.result[0]};
    }
    return {status: true, data: {}};
  };
  let variantData = useApiCall(getVariant, [selected]).data;

  const handleOpenPicker = () => {
    const flag = pickerOpen;
    setPickerOpen(!flag);
    setIsOpen(!flag);
  };

  const handleClosePicker = () => {
    setPickerOpen(false);
    setIsOpen(false);
    handleQueryChange('');
  };

  const handleQueryChange = query => {
    setQuery(query);
  };

  const handleQueryClear = () => {
    handleQueryChange('');
  };

  const handleSegmentSelect = itemIndex => {
    setSelectedItem(itemList[Number(itemIndex)][itemSelectionMap[itemType]] || '');
    setSelected(itemList[Number(itemIndex)][itemSelectionMap[itemType]] || '');
    setPickerOpen(false);
    setIsOpen(false);
  };

  const buttonLabel =
    selected === null || selected === undefined
      ? label
      : selected === ''
      ? 'Empty (not set)'
      : itemType === 'products'
      ? getTransformedProductTitle(variantData)
      : selected;

  const activator = (
    <div>
      <Button
        fullWidth
        textAlign="left"
        disclosure={pickerOpen ? 'up' : 'down'}
        onClick={handleOpenPicker}
      >
        <Text>{buttonLabel}</Text>
      </Button>
    </div>
  );

  const textFieldMarkup = (
    <div style={{padding: '12px'}}>
      <TextField
        clearButton
        labelHidden
        placeholder={placeholderMap[itemType]}
        autoComplete="off"
        value={query}
        prefix={<Icon source={SearchMinor} />}
        onChange={handleQueryChange}
        onClearButtonClick={handleQueryClear}
      />
    </div>
  );

  const ItemOptions = itemList;
  const items = (
    <>
      {ItemOptions.length > 0 ? (
        <>
          {itemType === 'products' ? (
            <Listbox.Header>{!query ? 'Top 50 Products' : ''}</Listbox.Header>
          ) : (
            <Listbox.Header>{''}</Listbox.Header>
          )}
          {ItemOptions.map(
            listMap[itemType]
              ? listMap[itemType]
              : (x, i) => {
                  return (
                    <Listbox.Option key={i} value={i} selected={-1}>
                      <Listbox.TextOption>{x[itemSelectionMap[itemType]]}</Listbox.TextOption>
                    </Listbox.Option>
                  );
                }
          )}
        </>
      ) : null}
    </>
  );

  const noResultsMarkup =
    ItemOptions.length === 0 ? (
      <EmptySearchResult title="" description={`No ${itemTypeMap[itemType]} found`} />
    ) : null;

  const listboxMarkup = (
    <Listbox
      enableKeyboardControl
      autoSelection={AutoSelection.FirstSelected}
      // accessibilityLabel="Search for and select a customer segment"
      onSelect={handleSegmentSelect}
    >
      {items}
      {noResultsMarkup}
    </Listbox>
  );

  const ContentMarkup = () => (
    <Popover.Pane>
      <div
        style={{
          alignItems: 'stretch',
          borderTop: '1px solid #DFE3E8',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'stretch',
          position: 'relative',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        {isSearchEnabled ? textFieldMarkup : ''}

        <Scrollable
          shadow
          style={{
            position: 'relative',
            maxHeight: '300px',
            minHeight: '100px',
            minWidth: '200px',
            padding: 'var(--p-space-200) 0',
            borderBottomLeftRadius: 'var(--p-border-radius-200)',
            borderBottomRightRadius: 'var(--p-border-radius-2git check00)',
          }}
        >
          {listboxMarkup}
        </Scrollable>
      </div>
    </Popover.Pane>
  );

  return isActivatorNeeded ? (
    <Popover
      active={pickerOpen}
      activator={activator}
      ariaHaspopup="listbox"
      preferredAlignment="left"
      preferredPosition="below"
      autofocusTarget="first-node"
      onClose={handleClosePicker}
      fluidContent
    >
      <ContentMarkup />
    </Popover>
  ) : (
    <ContentMarkup />
  );
};

export default FilterDropDown;
