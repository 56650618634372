/**
 * Generates a transformed product title based on the provided SKU, title, and variant title.
 *
 * @param {Object} params - Parameters for transforming the product title.
 * @param {string} params.sku - SKU of the product.
 * @param {string} params.title - Main title of the product.
 * @param {string} params.variantTitle - Title specific to the product variant.
 * @returns {string} Transformed product title, including SKU, title, and variant title (if applicable).
 */
const getTransformedProductTitle = ({sku, title, variantTitle}) => {
  let flag = ['', null, undefined, 'Default Title', title, 'null', 'undefined'].includes(
    variantTitle
  );
  let hyphn1 = sku && !title && flag ? '' : ' - ';
  let hyphn2 = !sku && !title && !flag ? '' : ' - ';
  return `${sku ? sku + hyphn1 : ''}${title || ''}${flag ? '' : hyphn2 + variantTitle}`;
};
export {getTransformedProductTitle};
