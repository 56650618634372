import axios from '../axios';
import config from '../../config';

const {BASE_URL} = config;

export async function getProductGroupList(params) {
  const response = await axios.get(`${BASE_URL}/reports/product-group-list`, params);
  return response;
}
export async function updateProductGroup({_id, state}) {
  const response = await axios.put(`${BASE_URL}/reports/product-group/${_id}`, state);
  return response;
}
export async function createProductGroup(options) {
  const response = await axios.post(`${BASE_URL}/reports/product-group`, options);
  return response;
}
export async function getProductGroupReport(params) {
  const response = await axios.get(`${BASE_URL}/reports/product-group-report`, params);
  return response;
}
export async function deleteProductGroup(id) {
  const response = await axios.delete(`${BASE_URL}/reports/product-group/${id.toString()}`);
  return response;
}
export async function getProductGroupVariantList(options) {
  const response = await axios.post(`${BASE_URL}/reports/product-group-variants`, options);
  return response;
}
