import {Modal} from '@shopify/polaris';
import Box from '../../lib/Box';
import Card from '../../lib/Card';
import {useEffect, useState} from 'react';
import {AdminApi} from '../../api/admin';
import {formatDate} from '../../features/format';
import Collapse from '../Collapse';
import Inline from '../../lib/Inline';
import Button from '../../lib/Button';
import Stack from '../../lib/Stack';
import Text from '../../lib/Text';
import TextContainer from '../../lib/TextContainer';

const fetchJobErrors = async (jobId, page) => {
  return await AdminApi.getJobErrors({jobId, page});
};
const fetchSplitErrors = async (splitId, page) => {
  return await AdminApi.getSplitErrors({splitId, page});
};
const typeToApi = {
  job: fetchJobErrors,
  split: fetchSplitErrors,
};
const JobErrorsModal = ({isOpen, toggle, data, type = 'job'}) => {
  const [errors, setErrors] = useState([]);
  const [status, setStatus] = useState('loading');
  const [page, setPage] = useState(1);
  const [openStack, setOpenStack] = useState(null);
  const [buttonVisibility, setButtonVisibility] = useState(true);

  const fetchErrors = async () => {
    setStatus('loading');
    let res = await typeToApi[type](data._id, page);
    if (res.status) {
      if (res.data?.length === 50) {
        setButtonVisibility(true);
      } else {
        setButtonVisibility(false);
      }
      setErrors([...errors, ...res.data]);
      setStatus('success');
    } else {
      setStatus('failed');
    }
  };
  useEffect(() => {
    fetchErrors();
  }, [page]);

  const activator = <div></div>;
  return (
    <>
      <Modal
        large
        activator={activator}
        open={isOpen}
        onClose={toggle}
        title={'Errors'}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: toggle,
          },
        ]}
      >
        <Modal.Section>
          {status === 'success' ? (
            <>
              <Card>
                {Boolean(errors.length) ? (
                  <>
                    {errors.map((err, index) => {
                      return (
                        <Card.Section key={err._id}>
                          <Inline>
                            <Stack vertical>
                              <Text>{err.from}</Text>
                              {Boolean(type === 'job') && <Text>{'Split id: ' + err.splitId}</Text>}
                              <Text as="p" color="subdued" variant="bodySm">
                                {formatDate({value: new Date(err.c)})}
                              </Text>
                            </Stack>
                            <Box paddingInlineStart="8">
                              <TextContainer spacing="tight">
                                <Text>{err.error?.message || err.error}</Text>
                                <Collapse
                                  isOpen={openStack === index}
                                  text={err.error?.stack || err.error}
                                  toggle={() => {
                                    setOpenStack(openStack === null ? index : null);
                                  }}
                                  buttonText="Show error stack"
                                />
                              </TextContainer>
                            </Box>
                          </Inline>
                        </Card.Section>
                      );
                    })}
                    {buttonVisibility && (
                      <Box padding={'4'}>
                        <Inline align="end">
                          <Button
                            plain
                            onClick={() => {
                              setPage(page + 1);
                            }}
                          >
                            <Text variant="headingMd">Load more</Text>
                          </Button>
                        </Inline>
                      </Box>
                    )}
                  </>
                ) : (
                  <Card.Section>No errors found</Card.Section>
                )}
              </Card>
            </>
          ) : status === 'failed' ? (
            <>
              <Card>
                <Card.Section>Something went wrong</Card.Section>
              </Card>
            </>
          ) : (
            <></>
          )}
        </Modal.Section>
      </Modal>
    </>
  );
};

export default JobErrorsModal;
