import {LineChart} from '@shopify/polaris-viz';
import React, {useEffect, useState} from 'react';
import apiToCall from '../../features/apiToCall';
import {formatAmount, formatDate, formatNumber} from '../../features/format';
import moment from 'moment-timezone';
import {Text} from '@shopify/polaris';
import config from '../../config';

const DefaultLineGraph = ({
  height,
  data,
  startDate,
  endDate,
  compareStartDate,
  compareEndDate,
  param,
  symbol,
  durationFilter,
  state,
  isDynamic,
  xAxisOptions = null,
  yAxisOptions = null,
  tooltipOptions = null,
}) => {
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const getDateString = (date1, date2) => {
    return (
      formatDate({value: new Date(date1), removeTime: true}) +
      ' - ' +
      formatDate({value: new Date(date2), removeTime: true})
    );
  };

  /**
   * Generates an array of objects have key as date and value as null for xAxis to have all dates within the user selected date range.
   * @param{String} startDate - startDate selected by user
   * @param{String} endDate - endDate selected by user
   * @param{String} durationFilter - values can only be ["daily", "weekly", "monthly]
   * @returns {{key: String, value: null}[]} data - ex: [{key: "2022-05-01", value:null}]
   */
  const generateXAxisData = (startDate, endDate, durationFilter) => {
    let data = [];
    let currDate = startDate;
    currDate = moment(currDate);
    endDate = moment(endDate);
    let dateFormatStr = durationFilter === 'monthly' ? 'YYYY-MM' : 'YYYY-MM-DD';
    if (durationFilter === 'weekly') {
      currDate = currDate.startOf('isoWeek');
    }
    if (durationFilter === 'monthly') {
      currDate = currDate.startOf('month');
    }

    let durationObj = {daily: 'days', weekly: 'weeks', monthly: 'months'};
    while (currDate < endDate) {
      data.push({
        key: currDate.format(dateFormatStr),
        value: 0,
      });
      currDate = currDate.add(1, durationObj[durationFilter]);
    }
    return data;
  };

  /**
   * Generate a data map having keys as date and value as report's y axis column values.
   * @param {Object[]} data - contains graphs response from api
   * @return {Object} - contains key as x axis dates and value as y axis column values
   */
  const getDataMap = data => {
    let hashMap = {};
    data = data || [];
    data.forEach(obj => {
      let key = obj[apiToCall[param.name].xAxisValue];
      let value = obj[apiToCall[param.name].yAxisValue];
      hashMap[key] = value;
    });
    return hashMap;
  };
  useEffect(() => {
    setData1([]);
    setData2([]);
  }, [durationFilter]);

  useEffect(() => {
    if (isDynamic && state === 'Success') {
      let d1 = generateXAxisData(startDate, endDate, durationFilter);
      let dataMap1 = getDataMap(data[0]);
      d1.forEach(obj => (obj.value = dataMap1[obj.key] || 0));
      setData1([...d1]);
    } else if (!isDynamic) {
      let d1 = generateXAxisData(startDate, endDate, durationFilter);
      let d2 = generateXAxisData(compareStartDate, compareEndDate, durationFilter);
      let dataMap1 = getDataMap(data[0]);
      let dataMap2 = getDataMap(data[1]);
      d1.forEach(obj => (obj.value = dataMap1[obj.key] || 0));
      d2.forEach(obj => (obj.value = dataMap2[obj.key] || 0));

      //dropping all rows of compare date range period if more than current date range period
      d2 = d2.slice(0, d1.length);
      setData1([...d1]);
      setData2([...d2]);
    }
  }, [data]);
  return (
    <>
      {state === 'Success' && data.length > 1 && !data[0].length && !data[1].length ? (
        <Text>No data found</Text>
      ) : (
        <div
          style={{
            height: height ? height : 400,
          }}
        >
          <LineChart
            state={state ? state : 'Loading'}
            tooltipOptions={
              tooltipOptions ||
              apiToCall[param.name].tooltipOptions || {
                titleFormatter: value => {
                  return `${formatDate({
                    value: new Date(value),
                    removeTime: true,
                    removeMonth: durationFilter === 'monthly',
                  })}`;
                },
                keyFormatter: value => {
                  return `${value}`;
                },
                valueFormatter: value => {
                  return symbol
                    ? formatAmount({value, notation: 'compact'})
                    : formatNumber({value, notation: 'compact'});
                },
              }
            }
            data={
              isDynamic
                ? [
                    {
                      data: data.length ? data1 : [],
                      name: getDateString(startDate, endDate),
                      color: config.GRAPH_COLOR,
                    },
                  ]
                : [
                    {
                      data: data.length ? data1 : [],
                      name: getDateString(startDate, endDate),
                      color: config.GRAPH_COLOR,
                    },
                    {
                      data: data.length ? data2 : [],
                      isComparison: true,
                      name: compareStartDate
                        ? getDateString(compareStartDate, compareEndDate)
                        : 'No comparison',
                    },
                  ]
            }
            theme="Light"
            xAxisOptions={xAxisOptions || apiToCall[param.name].xAxisOptions}
            yAxisOptions={yAxisOptions || apiToCall[param.name].yAxisOptions}
            emptyStateText={'Data is Loading'}
          />
        </div>
      )}
    </>
  );
};

export default DefaultLineGraph;
