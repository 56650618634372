import React, {useEffect, useReducer, useState} from 'react';
import {
  Layout,
  FormLayout,
  TextField,
  Select,
  Checkbox,
  Tag,
  SkeletonBodyText,
} from '@shopify/polaris';
import Stack from '../../../lib/Stack';
import Text from '../../../lib/Text';
import Card from '../../../lib/Card';
import Box from '../../../lib/Box';
import Inline from '../../../lib/Inline';
import Page from '../../../components/page';
import {daysOfMonthInit, daysOfWeekInit, specificHourInit} from './scheduleCheckbox';
import {usePreventNavigateStore, useSaveChangesStore} from '../../../app/store';
import {emailValidator} from '../../../features/validations';
import {RequestApi} from '../../../api/requestReports';
import {SignalApi} from '../../../api/signal';
import {useNavigate} from 'react-router-dom';
import useUnsavedChangesWarning from '../../../hooks/useUnsavedChangesWarning';
import SelectComponent from './select';
import MultiSelectComponent from './multiSelect';
import {getSegmentList} from '../../../api/segments';
import Alert from '../../../components/alert';
import {ErrorBoundary} from '../../../components/ErrorBoundary';
import {track} from '../../../features/track';

const dateRangeInit = [
  {label: 'Today', value: 'today'},
  {label: 'Yesterday', value: 'yesterday'},
  {label: 'Last 7 days', value: 'lastSevenDays'},
  {label: 'Last 30 days', value: 'lastThrityDays'},
  {label: 'Last 90 days', value: 'lastNinetyDays'},
  {label: 'Last 365 days', value: 'lastThreeSixtyFiveDays'},
  {label: 'Last Month', value: 'lastMonth'},
  {label: 'Last Year', value: 'lastYear'},
  {label: 'Week To Date', value: 'weekToDate'},
  {label: 'Month To Date', value: 'monthToDate'},
  {label: 'Year To Date', value: 'yearToDate'},
  {label: 'All Time', value: 'allTime'},
];

const initialState = {
  emailSubject: '',
  dateRange: 'today',
  recipients: [],
  schedule: {
    daysType: '',
    days: [],
    hoursType: '',
    hours: [],
    mins: '0',
  },

  reports: [],
  segments: [],
};

const removeFromArray = (array, item) => {
  let filtered = array.filter(e => e !== item);
  return filtered;
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'emailSubject':
      return {...state, emailSubject: action.value};
    case 'dateRange':
      return {...state, dateRange: action.value};
    case 'recipients':
      return {...state, recipients: [...state.recipients, action.value]};
    case 'removeFromRecipients':
      return {
        ...state,
        recipients: removeFromArray(state.recipients, action.value),
      };
    case 'daysType':
      return {
        ...state,
        schedule: {
          ...state.schedule,
          days: [],
          daysType: action.value,
          hoursType: '',
          hours: [],
        },
      };
    case 'everyDay':
      return {
        ...state,
        schedule: {
          ...state.schedule,
          days: [],
          daysType: 'everyDay',
        },
      };
    case 'specificDaysOfWeek':
      if (state.schedule.daysType === 'daysOfWeek') {
        return {
          ...state,
          schedule: {
            ...state.schedule,

            days: [...action.value],
            daysType: 'daysOfWeek',
          },
        };
      } else {
        return {
          ...state,
          schedule: {
            ...state.schedule,

            days: [action.value],
            daysType: 'daysOfWeek',
          },
        };
      }

    case 'removeFromSpecificDaysOfWeek':
      return {
        ...state,
        schedule: {
          ...state.schedule,

          ...state.schedule,
          days: removeFromArray(state.schedule.days, action.value),
        },
      };
    case 'specificDaysOfMonth':
      if (state.schedule.daysType === 'daysOfMonth') {
        return {
          ...state,
          schedule: {
            ...state.schedule,

            days: [...action.value],
            daysType: 'daysOfMonth',
          },
        };
      } else {
        return {
          ...state,
          schedule: {
            ...state.schedule,

            days: [action.value],
            daysType: 'daysOfMonth',
            hoursType: '',
            hours: [],
          },
        };
      }
    case 'removeFromSpecificDaysOfMonth':
      return {
        ...state,
        schedule: {
          ...state.schedule,
          days: removeFromArray(state.schedule.days, action.value),
        },
      };
    case 'everyHour':
      return {
        ...state,
        schedule: {
          ...state.schedule,
          hours: [],
          hoursType: 'everyHour',
        },
      };
    case 'specificHour':
      return {
        ...state,
        schedule: {
          ...state.schedule,

          hours: [...action.value],
          hoursType: 'specificHour',
        },
      };
    case 'removeFromSpecificHour':
      return {
        ...state,
        schedule: {
          ...state.schedule,
          hours: removeFromArray(state.schedule.hours, action.value),
        },
      };
    case 'minutes':
      return {...state, schedule: {...state.schedule, mins: action.value}};
    case 'reports':
      return {
        ...state,
        reports: [...state.reports, action.value],
      };
    case 'removeFromReports':
      return {
        ...state,
        reports: removeFromArray(state.reports, action.value),
      };
    case 'segments':
      return {
        ...state,
        segments: [...state.segments, action.value],
      };
    case 'removeFromSegments':
      return {
        ...state,
        segments: removeFromArray(state.segments, action.value),
      };
    case 'updateState':
      let nstate = action.value;
      return {
        ...nstate,
      };
    default:
      throw new Error();
  }
};

const NewSignal = ({params}) => {
  const isUnchanged = useSaveChangesStore(state => state.setIsUnchanged);
  const setOnSubmit = useSaveChangesStore(state => state.setOnSubmit);
  const setOnDiscard = useSaveChangesStore(state => state.setOnDiscard);
  const setProtect = usePreventNavigateStore(state => state.setProtect);
  const setButtonEnabled = useSaveChangesStore(state => state.setButtonEnabled);
  const navigate = useNavigate();
  const discard = () => {
    navigate('/active-signals');
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const [segments, setSegments] = useState({preBuilt: [], custom: []});
  // eslint-disable-next-line no-unused-vars
  const [prompt, setDirty, setPristine] = useUnsavedChangesWarning();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [lengthError, setLengthError] = useState({});
  const [reports, setReports] = useState([]);
  const [toBeUpdated, setToBeUpdated] = useState(false);
  const [showAlert, setShowAlert] = useState('');
  const [reportState, setReportState] = useState('Loading');
  const [segmentState, setSegmentState] = useState('Loading');

  useEffect(() => {
    const options = {name: 'New signal'};
    if (params.reportId) {
      options.reportId = params.reportId;
    }
    track('Page View', options);
  }, []);

  const onSave = () => {
    if (toBeUpdated) {
      SignalApi.updateSignal(state).then(res => {
        if (!res.status) {
          setShowAlert('Something Went Wrong');
        } else {
          setProtect(false);
          navigate('/active-signals');
        }
      });
    } else {
      SignalApi.createSignal(state).then(res => {
        if (!res.status) {
          setShowAlert('Something Went Wrong');
        } else {
          setProtect(false);
          navigate('/active-signals');
        }
      });
    }
  };
  const handleCheckBox = id => {
    reports.forEach(report => {
      report.reports.forEach(reqReport => {
        if (reqReport._id === id) {
          reqReport.isChecked = !reqReport.isChecked;
          if (reqReport.isChecked) {
            dispatch({type: 'reports', value: reqReport._id});
          } else {
            dispatch({type: 'removeFromReports', value: reqReport._id});
          }
        }
      });
    });

    for (let key in segments) {
      segments[key].forEach(segment => {
        if (segment._id === id) {
          segment.isChecked = !segment.isChecked;
          if (segment.isChecked) {
            dispatch({type: 'segments', value: segment._id});
          } else {
            dispatch({type: 'removeFromSegments', value: segment._id});
          }
        }
      });
    }
    setReports([...reports]);
    setSegments({...segments});
  };
  useEffect(() => {
    setReportState('Loading');
    RequestApi.requestedReports({categorize: true})
      .then(response => {
        if (response.status) {
          setReports(response.data);
          if (params.reportId) {
            response.data.forEach(res => {
              res.reports.forEach(report => {
                if (report.slag === params.reportId) {
                  report.isChecked = true;
                  dispatch({type: 'reports', value: report._id});
                }
              });
            });
            setReports([...response.data]);
          }
          setReportState('Success');
        } else {
          setReportState('Error');
        }
      })
      .catch(err => {
        setReportState('Error');
      });

    if (params.id) {
      SignalApi.getSignal(params.id).then(res => {
        let signal = res;
        // setSchedule(res.schedule.daysType);

        // setScheduleSpecificHour(res.schedule.hoursType);
        // fix it later
        setSegmentState('Loading');

        getSegmentList({params: {type: 'All'}})
          .then(res => {
            if (res.data.status) {
              let custom = [];
              let prebuilt = [];
              if (res.data?.data?.length) {
                res.data.data.forEach(segment => {
                  if (signal.segments.includes(segment._id)) {
                    segment.isChecked = true;
                  }
                  if (segment.segmentType === 'Custom') {
                    custom.push(segment);
                  } else {
                    prebuilt.push(segment);
                  }
                });
              }
              let obj = {preBuilt: prebuilt, custom: custom};
              setSegments({...obj});
              setSegmentState('Success');
            } else {
              setSegmentState('Error');
            }
          })
          .catch(err => {
            setSegmentState('Error');
          });
        setReportState('Loading');

        RequestApi.requestedReports({categorize: true})
          .then(response => {
            if (response.status) {
              response.data.forEach(res => {
                res.reports.forEach(report => {
                  if (signal.reports.includes(report._id)) {
                    report.isChecked = true;
                  }
                });
              });
              setReports([...response.data]);
              setReportState('Success');
            } else {
              setReportState('Error');
            }
          })
          .catch(err => {
            setReportState('Error');
          });

        dispatch({type: 'updateState', value: res});
        setToBeUpdated(true);
      });
    }
    setSegmentState('Loading');

    getSegmentList({params: {type: 'All'}})
      .then(res => {
        if (res.data.status) {
          let custom = [];
          let prebuilt = [];
          if (res.data?.data?.length) {
            res.data.data.forEach(segment => {
              if (params.reportId) {
                if (segment._id === params.reportId) {
                  segment.isChecked = true;
                  dispatch({type: 'segments', value: segment._id});
                }
              }
              if (segment.segmentType === 'Custom') {
                custom.push(segment);
              } else {
                prebuilt.push(segment);
              }
            });
          }
          setSegments({preBuilt: prebuilt, custom: custom});
          setSegmentState('Success');
        } else {
          setSegmentState('Error');
        }
      })
      .catch(err => {
        setSegmentState('Error');
      });

    setPristine();
  }, []);

  useEffect(() => {
    isUnchanged(true);
    setOnDiscard(discard);
    setOnSubmit(onSave);
    setProtect(true);
    if (
      state.emailSubject &&
      state.recipients.length &&
      (state.reports.length || state.segments.length) &&
      (state.schedule.daysType === 'everyDay' || state.schedule.days.length) &&
      (state.schedule.hoursType === 'everyHour' || state.schedule.hours.length)
    ) {
      setButtonEnabled(true);
    } else {
      setButtonEnabled(false);
    }
    if (
      state.emailSubject ||
      state.recipients.length ||
      state.reports.length ||
      state.segments.length
    ) {
      setDirty();
    }
    reports.forEach(report => {
      report.reports.forEach(requestedReport => {
        if (state.reports.includes(requestedReport._id)) {
          requestedReport.isChecked = true;
        }
      });
    });
  }, [state]);

  return (
    <>
      <Page title="New Signal">
        <Box paddingBlockEnd={'4'}>
          <Layout>
            <Layout.Section>
              <Card sectioned>
                <FormLayout>
                  <FormLayout.Group condensed>
                    <TextField
                      placeholder={'Daily Report'}
                      onChange={e => {
                        if (e.length < 150) {
                          dispatch({type: 'emailSubject', value: e});
                          lengthError.sub = false;
                          setLengthError(lengthError);
                        } else if (e.length === 150) {
                          dispatch({type: 'emailSubject', value: e});
                          lengthError.sub = true;
                          setLengthError(lengthError);
                        }
                      }}
                      label="Email Subject"
                      type="text"
                      autoComplete="email"
                      error={lengthError.sub && 'Max length of 150 characters reached'}
                      value={state.emailSubject}
                    />
                    <Select
                      label="Date range"
                      options={dateRangeInit}
                      onChange={e => {
                        dispatch({type: 'dateRange', value: e});
                      }}
                      value={state.dateRange}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Card>
              <Card title="Recipients" sectioned>
                <FormLayout>
                  <TextField
                    placeholder={'example@example.com'}
                    onChange={e => {
                      if (e.length < 150) {
                        setEmail(e);
                        lengthError.email = false;
                        setLengthError(lengthError);
                      } else if (e.length === 150) {
                        setEmail(e);
                        lengthError.email = true;
                        setLengthError(lengthError);
                      }
                    }}
                    value={email}
                    label="Email"
                    type="email"
                    autoComplete="email"
                    onBlur={e => {
                      if (state.recipients.includes(email)) {
                        setEmailError('This email id already exists');
                      } else if (emailValidator(email)) {
                        setEmailError('');
                        setEmail('');
                        dispatch({type: 'recipients', value: email});
                      } else {
                        setEmailError('Please enter a valid email address');
                      }
                    }}
                    error={
                      emailError
                        ? emailError
                        : lengthError.email
                        ? 'Max length of 150 characters reached'
                        : ''
                    }
                  />
                  <Inline>
                    {state.recipients.map(recipient => {
                      return (
                        <Tag
                          key={recipient}
                          onRemove={() => {
                            dispatch({
                              type: 'removeFromRecipients',
                              value: recipient,
                            });
                          }}
                        >
                          {recipient}
                        </Tag>
                      );
                    })}
                  </Inline>
                </FormLayout>
              </Card>
              <Card title="Schedule" sectioned>
                <Box>
                  <Inline>
                    <Box width="15%">
                      <ErrorBoundary
                        fallback={
                          <Card>
                            <Card.Section>
                              <div>An error has occured</div>
                            </Card.Section>
                          </Card>
                        }
                      >
                        <SelectComponent
                          label="Frequency"
                          value={state.schedule.daysType}
                          dispatch={dispatch}
                          type="daysType"
                          options={[
                            {label: 'Daily', value: 'everyDay'},
                            {label: 'Weekly', value: 'daysOfWeek'},
                            {label: 'Monthly', value: 'daysOfMonth'},
                          ]}
                        />
                      </ErrorBoundary>
                    </Box>
                    {state.schedule.daysType === 'daysOfWeek' && (
                      <Box width="15%">
                        <ErrorBoundary
                          fallback={
                            <Card>
                              <Card.Section>
                                <div>An error has occured</div>
                              </Card.Section>
                            </Card>
                          }
                        >
                          <MultiSelectComponent
                            label="Days of the week"
                            value={state.schedule.days}
                            dispatch={dispatch}
                            type="specificDaysOfWeek"
                            options={daysOfWeekInit}
                          />
                        </ErrorBoundary>
                      </Box>
                    )}
                    {state.schedule.daysType === 'daysOfMonth' && (
                      <Box width="15%">
                        <ErrorBoundary
                          fallback={
                            <Card>
                              <Card.Section>
                                <div>An error has occured</div>
                              </Card.Section>
                            </Card>
                          }
                        >
                          <MultiSelectComponent
                            label="Days of the month"
                            value={state.schedule.days}
                            dispatch={dispatch}
                            type="specificDaysOfMonth"
                            options={daysOfMonthInit}
                          />
                        </ErrorBoundary>
                      </Box>
                    )}
                    {state.schedule.daysType === 'everyDay' || state.schedule.days.length ? (
                      <Box width="15%">
                        <ErrorBoundary
                          fallback={
                            <Card>
                              <Card.Section>
                                <div>An error has occured</div>
                              </Card.Section>
                            </Card>
                          }
                        >
                          <MultiSelectComponent
                            label="Time"
                            value={state.schedule.hours}
                            dispatch={dispatch}
                            type="hour"
                            options={specificHourInit}
                            everyHour={state.schedule.hoursType}
                          />
                        </ErrorBoundary>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Inline>
                </Box>
              </Card>
              <ErrorBoundary
                fallback={
                  <Card>
                    <Card.Section>
                      <div>An error has occured</div>
                    </Card.Section>
                  </Card>
                }
              >
                <Card title="Reports" sectioned>
                  {reportState === 'Error' ? (
                    <Box paddingBlockStart={'24'} paddingBlockEnd={'24'}>
                      <Text alignment="center" color="subdued">
                        Not able to load data. Please try again later
                      </Text>
                    </Box>
                  ) : reportState === 'Loading' ? (
                    <SkeletonBodyText />
                  ) : reports.length ? (
                    <>
                      {reports.map(report => {
                        return (
                          <Card.Subsection key={report._id}>
                            <FormLayout>
                              <Text variant="bodyMd" as="p" fontWeight="medium">
                                {report._id} Reports
                              </Text>
                              <FormLayout.Group condensed>
                                <Stack vertical>
                                  {report.reports.map(requestedReport => {
                                    return (
                                      <Checkbox
                                        label={requestedReport.reportName}
                                        checked={requestedReport.isChecked}
                                        onChange={() => {
                                          handleCheckBox(requestedReport._id);
                                        }}
                                        key={requestedReport._id}
                                      />
                                    );
                                  })}
                                </Stack>
                              </FormLayout.Group>
                            </FormLayout>
                          </Card.Subsection>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <Box paddingBlockStart={'24'} paddingBlockEnd={'24'}>
                        <Text alignment="center" color="subdued">
                          Unfortunately, there are no reports that are available.
                        </Text>
                      </Box>
                    </>
                  )}
                </Card>
              </ErrorBoundary>
              <ErrorBoundary
                fallback={
                  <Card>
                    <Card.Section>
                      <div>An error has occured</div>
                    </Card.Section>
                  </Card>
                }
              >
                <Card title="Segments" sectioned>
                  {segmentState === 'Error' ? (
                    <Box paddingBlockStart={'24'} paddingBlockEnd={'24'}>
                      <Text alignment="center" color="subdued">
                        Not able to load data. Please try again later
                      </Text>
                    </Box>
                  ) : segmentState === 'Loading' ? (
                    <SkeletonBodyText />
                  ) : segments.custom.length || segments.preBuilt.length ? (
                    <>
                      {segments.preBuilt.length && (
                        <>
                          <Card.Subsection>
                            <FormLayout>
                              <Text variant="bodyMd" as="p" fontWeight="medium">
                                Pre-Built
                              </Text>
                              <FormLayout.Group condensed>
                                <Stack vertical>
                                  {segments.preBuilt.map(segment => {
                                    return (
                                      <Checkbox
                                        label={segment.name}
                                        checked={segment.isChecked}
                                        onChange={() => {
                                          handleCheckBox(segment._id);
                                        }}
                                        key={segment._id}
                                      />
                                    );
                                  })}
                                </Stack>
                              </FormLayout.Group>
                            </FormLayout>
                          </Card.Subsection>
                        </>
                      )}
                      {segments.custom.length && (
                        <>
                          <Card.Subsection>
                            <FormLayout>
                              <Text variant="bodyMd" as="p" fontWeight="medium">
                                Custom
                              </Text>
                              <FormLayout.Group condensed>
                                <Stack vertical>
                                  {segments.custom.map(segment => {
                                    return (
                                      <Checkbox
                                        label={segment.name}
                                        checked={segment.isChecked}
                                        onChange={() => {
                                          handleCheckBox(segment._id);
                                        }}
                                        key={segment._id}
                                      />
                                    );
                                  })}
                                </Stack>
                              </FormLayout.Group>
                            </FormLayout>
                          </Card.Subsection>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Box paddingBlockStart={'24'} paddingBlockEnd={'24'}>
                        <Text alignment="center" color="subdued">
                          Unfortunately, there are no segments that are available.
                        </Text>
                      </Box>
                    </>
                  )}
                </Card>
              </ErrorBoundary>
            </Layout.Section>
          </Layout>
        </Box>
        {showAlert ? (
          <Alert contents={showAlert} init={true} error={showAlert === 'Something Went Wrong'} />
        ) : (
          <></>
        )}
      </Page>
    </>
  );
};
export default NewSignal;
