import axios from './axios';
import config from '../config';

const {BASE_URL} = config;

export async function trackAPI(options) {
  try {
    const response = await axios.post(`${BASE_URL}/track`, options);
    return response;
  } catch (err) {
    return err;
  }
}
export async function sendUserActivity(options) {
  try {
    const response = await axios.post(`${BASE_URL}/user/log-user-activity`, options);
    return response;
  } catch (err) {
    return err;
  }
}
