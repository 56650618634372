import React from 'react';
import {SkeletonDisplayText} from '@shopify/polaris';
import Stack from '../../../../lib/Stack';
import Text from '../../../../lib/Text';
import Box from '../../../../lib/Box';
import Inline from '../../../../lib/Inline';
const EconomicIndicator = ({values}) => {
  return (
    <>
      <Box padding={'2'}>
        <Inline align="space-between" blockAlign="start">
          <Box width="30%">
            <Stack vertical spacing="tight">
              <Text color="subdued" variant="bodyMd">
                {values[0].label}
              </Text>
              {values[0].loading ? (
                <SkeletonDisplayText />
              ) : values[0].error ? (
                <Text>Not able to load data</Text>
              ) : (
                <Text variant="headingLg">{values[0].value}</Text>
              )}
            </Stack>
          </Box>
          <Box
            width="30%"
            borderInlineStartWidth="2"
            borderInlineStart="divider"
            paddingInlineStart={'3'}
          >
            <Stack vertical spacing="tight">
              <Text color="subdued" variant="bodyMd">
                {values[1].label}
              </Text>
              {values[1].loading ? (
                <SkeletonDisplayText />
              ) : values[1].error ? (
                <Text>Not able to load data</Text>
              ) : (
                <Text variant="headingLg">{values[1].value}</Text>
              )}
            </Stack>
          </Box>
          <Box
            width="30%"
            borderInlineStartWidth="2"
            borderInlineStart="divider"
            paddingInlineStart={'3'}
          >
            <Stack vertical spacing="tight">
              <Text color="subdued" variant="bodyMd">
                {values[2].label}
              </Text>
              {values[2].loading ? (
                <SkeletonDisplayText />
              ) : values[1].error ? (
                <Text>Not able to load data</Text>
              ) : (
                <Text variant="headingLg">{values[2].value}</Text>
              )}
            </Stack>
          </Box>
        </Inline>
      </Box>
    </>
  );
};

export default EconomicIndicator;
