import axios from './axios';
import config from '../config';

const {BASE_URL} = config;
export async function risingStars(params) {
  try {
    const response = await axios.get(`${BASE_URL}/product-module/rising-stars`, params);
    return response;
  } catch (err) {
    return err.response.data;
  }
}

export async function fixNow(params) {
  try {
    const response = await axios.get(`${BASE_URL}/product-module/fix-now`, params);
    return response;
  } catch (err) {
    return err.response.data;
  }
}
export async function profitMargin(params) {
  try {
    const response = await axios.get(`${BASE_URL}/product-module/profit-margin`, params);
    return response;
  } catch (err) {
    return err.response.data;
  }
}
export async function atRisk(params) {
  try {
    const response = await axios.get(`${BASE_URL}/product-module/at-risk`, params);
    return response;
  } catch (err) {
    return err.response.data;
  }
}
export async function outOfStock(params) {
  try {
    const response = await axios.get(`${BASE_URL}/product-module/out-of-stock`, params);
    return response;
  } catch (err) {
    return err.response.data;
  }
}
