import {Columns} from '@shopify/polaris';
import React from 'react';
import DashboardCard from '../dashboardCard';
import {ErrorBoundary} from '../../../../components/ErrorBoundary';
import {useSnapshotStore} from '../../../../app/store';
import Box from '../../../../lib/Box';
import Stack from '../../../../lib/Stack';
const NonDraggableDashboard = ({selectedDates, selectedCompareDates, durationFilter}) => {
  const {data, widgetData, setWidgetData} = useSnapshotStore(state => state);
  return (
    <>
      <Box paddingBlockEnd={'4'}>
        <Columns columns={{xs: 1, sm: 1, md: 1, lg: 2, xl: 3}}>
          {Object.entries(data).map(([columnId, column], index) => {
            return (
              <Box key={index}>
                <Stack vertical>
                  {column.map((col, i) => (
                    <ErrorBoundary
                      key={i}
                      fallback={
                        <DashboardCard
                          slag={col.slag}
                          selectedDates={selectedDates}
                          selectedCompareDates={selectedCompareDates}
                          durationFilter={durationFilter}
                          data={{data: [], state: 'Error'}}
                          setData={data => {
                            widgetData[col.slag] = data;
                            setWidgetData({...widgetData});
                          }}
                          error={true}
                        />
                      }
                    >
                      <DashboardCard
                        slag={col.slag}
                        selectedDates={selectedDates}
                        selectedCompareDates={selectedCompareDates}
                        durationFilter={durationFilter}
                        dataObj={widgetData[col.slag] ? widgetData[col.slag] : {data: []}}
                        setData={data => {
                          widgetData[col.slag] = data;
                          setWidgetData({...widgetData});
                        }}
                      />
                    </ErrorBoundary>
                  ))}
                </Stack>
              </Box>
            );
          })}
        </Columns>
      </Box>
    </>
  );
};

export default NonDraggableDashboard;
