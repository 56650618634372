import React, {useEffect, useState} from 'react';
import Page from '../../../components/page';
import {Box, Button, Card} from '@shopify/polaris';
import DefaultTable from '../../../components/defaultIndexTable';
import apiToCall from '../../../features/apiToCall';
import {ErrorBoundary} from '../../../components/ErrorBoundary';
import {track} from '../../../features/track';
import useApiCall from '../../../hooks/useApiCall';
import config from '../../../config';
import FixedCostModal from '../../../components/Modals/FixedCostModal';
import {updateFixedCost} from '../../../api/cost';
import {PlusMinor} from '@shopify/polaris-icons';

const FixedCost = () => {
  const param = {name: 'fixedCost'};
  const [queryValue, setQueryValue] = useState('');
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [dataUpdated, setDataUpdated] = useState(false);

  useEffect(() => {
    track('Page View', {
      name: 'FixedCost',
    });
  }, []);

  const fetchFixedCostsData = async () => {
    let response = await apiToCall[param.name].api();
    return response.data;
  };

  const fixedCostApiDependencies = [dataUpdated];

  const fixedCostData = useApiCall(
    fetchFixedCostsData,
    fixedCostApiDependencies,
    config.SEARCH_DELAY
  );

  const handleSave = async ({
    expense,
    expenseCategory,
    expenseTitle,
    isRecurring,
    recurrenceNumber,
    recurrencePeriod,
    startDate,
    endDate,
    isAdSpend,
  }) => {
    // setShowAlert('');
    // let startDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.start, 'start'));
    // let endDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.end, 'end'));
    const data = {
      category: expenseCategory,
      valueType: 'flat',
      value: expense * 100,
      title: expenseTitle,
      isRecurring,
      recurrence_frequency: recurrenceNumber,
      recurrence_period: recurrencePeriod,
      startDate,
      endDate,
      isAdSpend,
    };
    const params = {
      data,
    };
    // setDownload({state: 'Loading'});
    try {
      let response = await updateFixedCost(params);
      if (response.status === 200) {
        setDataUpdated(true);
      }
      //   setShowAlert(`Orders will be mailed to you shortly on ${email}`);
      //   setDownload({state: 'Success'});
    } catch (e) {
      //   setDownload({state: 'Error'});
      console.error(e);
    }
  };

  return (
    <>
      <Page
        secondaryActions={
          <Button icon={PlusMinor} primary onClick={() => setOpenDownloadModal(true)}>
            Add Custom Cost
          </Button>
        }
        title={'Custom fixed costs'}
        subtitle={'Please specify any other costs to be included for profitability computation'}
      >
        <Box paddingBlockStart={'4'}>
          <ErrorBoundary
            fallback={
              <Card>
                <Card.Section>
                  <div>An error has occured</div>
                </Card.Section>
              </Card>
            }
          >
            <DefaultTable
              disableSort={true}
              select={false}
              queryValue={queryValue}
              setQueryValue={setQueryValue}
              resourceName={{
                singular: 'metrics',
                plural: 'metrics',
              }}
              columns={apiToCall[param.name].columns}
              totalRows={fixedCostData.data.length > 0 ? fixedCostData.data.length : 0}
              tableData={fixedCostData.data.length > 0 ? fixedCostData.data : []}
              state={fixedCostData.state}
              selectedFilters={[]}
              param={param}
              page={1}
              queryPlaceholder="Search by product name, SKU, variant..."
              isFixedCostReport={true}
            />
          </ErrorBoundary>
        </Box>
      </Page>
      <FixedCostModal
        isOpen={openDownloadModal}
        toggle={() => setOpenDownloadModal(!openDownloadModal)}
        handleSave={handleSave}
      />
    </>
  );
};

export {FixedCost};
