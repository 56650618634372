import axios from './axios';
import config from '../config';

const {BASE_URL} = config;
export async function getSnapshot() {
  try {
    const response = await axios.get(`${BASE_URL}/snapshot/get-snapshot`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
export async function updateSnapshot(data) {
  try {
    const response = await axios.put(`${BASE_URL}/snapshot/update-snapshot`, data);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
