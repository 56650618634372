import {Loading} from '@shopify/polaris';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {SignalApi} from '../../../api/signal';
import Alert from '../../../components/alert';
import Page from '../../../components/page';
import ColdStart from '../../coldStart';
import ActiveSignalCard from './activeSignalCard';
import config from '../../../config';
import {track} from '../../../features/track';
const ActiveSignal = () => {
  const [signals, setSignals] = useState({data: [], state: 'Loading'});
  const navigate = useNavigate();
  const [trigger, setTrigger] = useState({state: 'Success'});
  const [showAlert, setShowAlert] = useState('');
  const handleAction = () => {
    navigate('/signal-new');
  };
  const fetchSignals = async () => {
    setShowAlert('');
    try {
      let response = await SignalApi.getAllSignals();
      if (response.status) {
        setSignals({state: 'Success', data: response.data});
      } else {
        setSignals({state: 'Error', data: [], error: response.message});
      }
    } catch (err) {
      setSignals({state: 'Error', data: [], error: err.message});
    }
  };
  const triggerSignal = async signalId => {
    setShowAlert('');
    setTrigger({state: 'Loading'});
    try {
      let response = await SignalApi.triggerSignal(signalId);
      if (response.status) {
        setTrigger({state: 'Success'});
        setShowAlert('You will receive email shortly');
      } else {
        setTrigger({state: 'Success'});
        setShowAlert('Something Went Wrong');
      }
    } catch (err) {
      setTrigger({state: 'Success'});
      setShowAlert('Something Went Wrong');
    }
  };
  useEffect(() => {
    track('Page View', {
      name: 'Active Signals',
    });
    fetchSignals();
  }, []);
  return (
    <>
      <Page
        title="Active Signals"
        primaryAction={{
          content: 'New Signal',
          onAction: handleAction,
        }}
        secondaryActions={[
          {
            content: 'Signal history',
            onAction: () => {
              navigate('/signal-history');
            },
          },
        ]}
        subtitle="View and manage active signals"
      >
        {signals.state === 'Loading' && <Loading />}
        {signals.state === 'Error' ? (
          <ColdStart page="page8" />
        ) : signals.state === 'Success' && !signals.data.length ? (
          <>
            <ColdStart page={'page6'} />
          </>
        ) : (
          <>
            {signals.data.map(signal => {
              return (
                <ActiveSignalCard
                  key={signal._id}
                  signal={signal}
                  fetchSignals={fetchSignals}
                  triggerSignal={triggerSignal}
                  trigger={trigger}
                />
              );
            })}
          </>
        )}
        {showAlert ? (
          <Alert contents={showAlert} init={true} error={showAlert === 'Something Went Wrong'} />
        ) : (
          <></>
        )}
      </Page>
    </>
  );
};

export default ActiveSignal;
