import {
  Text,
  Popover,
  ActionList,
  ChoiceList,
  Spinner,
  Icon,
  TextContainer,
} from '@shopify/polaris';
import Box from '../../../lib/Box';
import Card from '../../../lib/Card';
import React, {useCallback, useState} from 'react';
import {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {deleteSegment, getSegmentList, getSegmentReport} from '../../../api/segments';
import DefaultTable from '../../../components/defaultIndexTable';
import {DeleteModal, ExportModal} from '../../../components/modal';
import MyPage from '../../../components/page';
import {formatDate} from '../../../features/format';
import {segmentColumns} from '../../metrics/columns';
import {saveAs} from 'file-saver';
import Alert from '../../../components/alert';
import config from '../../../config';
import {ErrorBoundary} from '../../../components/ErrorBoundary';
import {track} from '../../../features/track';
import {LocalStorageService} from '../../../service/localStorage';
import useApiCall from '../../../hooks/useApiCall';
import {CircleInformationMajor} from '@shopify/polaris-icons';
import MultiSelect from '../../../components/multiSelect';
import DefinationPopover from '../../../components/definationPopover';
import {generateInfoText} from '../../../features/generateText';
import DownloadModal from '../../../components/Modals/downloadModal';
import Button from '../../../lib/Button';
const SegmentPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [popoverActive, setPopoverActive] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [accountStatus, setAccountStatus] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [title, setTitle] = useState('');
  const [owner, setOwner] = useState('');
  const [segmentType, setSegmentType] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortBy, setSortBy] = useState('netRevenue');
  const [queryValue, setQueryValue] = useState('');
  const [page, setPage] = useState(1);
  const [showAlert, setShowAlert] = useState('');
  const [download, setDownload] = useState({state: 'Success'});
  const [infoPopoverActive, setInfoPopoverActive] = useState(false);
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  useEffect(() => {
    track('Page View', {
      name: 'Segment',
    });
    getSegmentList({params: {_id: params.id}}).then(res => {
      setTitle(res.data.data.segmentDetail.name);
      setOwner(res.data.data.user);
      setSegmentType(res.data.data.segmentType);
      setCreatedAt(formatDate({value: new Date(res.data.data.c)}));
    });
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setShowAlert('');
    }, config.ALERT_TIMEOUT);
  }, [showAlert]);

  const fetchSegmentData = async () => {
    let _params = {
      _id: params.id,
      page,
      sortBy,
      sortOrder,
      search: queryValue,
      activity: accountStatus,
    };
    let response = await getSegmentReport({params: _params});
    return response.data;
  };

  const segmentDataApiDependencies = [
    page,
    sortBy,
    sortOrder,
    queryValue,
    accountStatus,
    params.id,
  ];

  const tableData = useApiCall(fetchSegmentData, segmentDataApiDependencies, config.SEARCH_DELAY);

  const togglePopoverActive = useCallback(
    () => setPopoverActive(popoverActive => !popoverActive),
    []
  );
  const activator = (
    <Button
      onClick={() => {
        setPopoverActive(!popoverActive);
      }}
      outline
    >
      <Box paddingBlockEnd={'1'}>
        <Text variant="headingMd">...</Text>
      </Box>
    </Button>
  );
  const handleDownload = async email => {
    setDownload({state: 'Loading'});
    try {
      let response = await getSegmentReport({
        params: {_id: params.id, download: true, sortBy, sortOrder, email},
      });
      if (response.status === 500) {
        setShowAlert('Something Went Wrong');
        setDownload({state: 'Error'});
      } else {
        setShowAlert(`Segment will be mailed to you shortly on ${email}`);
        setDownload({state: 'Success'});
      }
    } catch (e) {
      setShowAlert('Something Went Wrong');
      setDownload({state: 'Error'});
    }
  };
  return (
    <>
      <MyPage
        breadcrumbs={[
          {
            content: 'segments',
            onAction: () => {
              navigate(-1);
            },
          },
        ]}
        title={title || 'Segment'}
        subtitle={
          tableData.state === 'Success' ? (
            (tableData?.data[0]?.totalRows || 0) + ' customers'
          ) : tableData.state === 'Loading' ? (
            <Spinner size="small" />
          ) : (
            'Not able to load data'
          )
        }
        primaryAction={
          owner !== 'retainwise' &&
          segmentType !== 'Pre-built' && (
            <Popover
              active={popoverActive}
              activator={activator}
              autofocusTarget="first-node"
              onClose={togglePopoverActive}
              preferredAlignment="left"
            >
              <div style={{minWidth: '7rem'}}>
                <ActionList
                  actionRole="menuitem"
                  items={[
                    {
                      content: 'Edit',
                      onAction: () => navigate(`/segment-edit/${params.id}`),
                    },
                    {
                      content: 'Delete',
                      destructive: true,
                      onAction: () => {
                        setOpenDeleteModal(true);
                      },
                    },
                  ]}
                />
              </div>
            </Popover>
          )
        }
        secondaryActions={[
          // {
          //   content: 'Create signal',
          //   onAction: () => {
          //     navigate(`/signal-new/${params.id}`);
          //   },
          // },
          {
            content: download.state === 'Loading' ? <Spinner size="small" /> : 'Download',
            destructive: false,
            onAction: () => {
              setOpenDownloadModal(true);
            },
          },
          {
            content: 'Export',
            onAction: () => {
              setOpenExportModal(true);
            },
          },
          {
            content:
              segmentType === 'Pre-built' && generateInfoText(title) ? (
                <DefinationPopover
                  text={generateInfoText(title)}
                  togglePopoverActive={() => {
                    setInfoPopoverActive(false);
                  }}
                  popoverActive={infoPopoverActive}
                  activator={
                    <div
                      onMouseEnter={() => {
                        setInfoPopoverActive(true);
                      }}
                      onMouseLeave={() => {
                        setInfoPopoverActive(false);
                      }}
                    >
                      <Icon source={CircleInformationMajor} />
                    </div>
                  }
                />
              ) : (
                ''
              ),
          },
        ]}
      >
        {openExportModal && (
          <ExportModal
            currentState={openExportModal}
            onClose={setOpenExportModal}
            segmentId={params.id}
            totalCustomers={tableData?.data[0]?.totalRows || 0}
          />
        )}
        <Box>
          <ErrorBoundary
            fallback={
              <Card>
                <Card.Section>
                  <div>An error has occured</div>
                </Card.Section>
              </Card>
            }
          >
            <DefaultTable
              resourceName={{
                singular: 'customer',
                plural: 'customers',
              }}
              filters={[
                <MultiSelect
                  title="All customers"
                  options={[
                    {label: 'All customers', value: '', key: 1},
                    {label: 'Active this year', value: 'active', key: 2},
                    {
                      label: 'Inactive this year',
                      value: 'inactive',
                      key: 3,
                    },
                  ]}
                  selected={accountStatus}
                  setSelected={setAccountStatus}
                  disableMultiple={true}
                  setPage={setPage}
                />,
              ]}
              selectedFilters={[]}
              sortChoices={[
                {label: 'Last update', value: 'enabled'},
                {label: 'Amount spent', value: 'not invited'},
                {label: 'Total orders', value: 'invited'},
                {label: 'Last order date', value: 'invited'},
                {label: 'First order date', value: 'invited'},
                {
                  label: 'Date added as customer',
                  value: 'invited',
                },
              ]}
              select={false}
              tableData={tableData.data}
              columns={segmentColumns}
              sortExceptions={['customerID']}
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              setSelectedDateRangeDates={() => {}}
              queryValue={queryValue}
              setQueryValue={setQueryValue}
              totalRows={tableData.data.length ? tableData.data[0].totalRows : 0}
              setPage={setPage}
              page={page}
              state={tableData.state}
              param={{name: 'segments'}}
            />
          </ErrorBoundary>
        </Box>
        {openDeleteModal && (
          <DeleteModal
            currentState={openDeleteModal}
            deleteContent="Segment"
            onDelete={() => {
              deleteSegment(params.id);
              setOpenDeleteModal(false);
              navigate('/segments');
            }}
            onClose={() => {
              setOpenDeleteModal(false);
            }}
          />
        )}
        {showAlert && (
          <Alert
            contents={showAlert}
            init={true}
            error={showAlert === 'Something Went Wrong'}
            duration={10000}
          />
        )}
      </MyPage>
      <DownloadModal
        isOpen={openDownloadModal}
        toggle={() => setOpenDownloadModal(!openDownloadModal)}
        handleDownload={handleDownload}
      />
    </>
  );
};

export default SegmentPage;
