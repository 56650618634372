import axios from './axios';
import config from '../config';

const {BASE_URL} = config;

export async function getOnboarding(params) {
  const response = await axios.get(`${BASE_URL}/onboarding`, params);
  return response?.data;
}

export async function createOrUpdateOnboarding(body) {
  const response = await axios.post(`${BASE_URL}/onboarding`, body);
  return response?.data;
}
