import {LocalStorageService} from '../service/localStorage';

const toISOLocal = (d, type) => {
  // return d.toISOString();

  function z(n) {
    return (n < 10 ? '0' : '') + n;
  }
  // const lastday = (y, m) => {
  //   return new Date(y, m + 1, 0).getDate();
  // };
  let converted;
  if (type === 'curr') {
    converted = `${d.getFullYear()}-${z(d.getMonth() + 1)}-${z(d.getDate())}T00:00:00`;
  } else if (type === 'next') {
    // let last = lastday(d.getFullYear(), d.getMonth());
    let day = d.getDate();
    let month = d.getMonth() + 1;
    let year = d.getFullYear();

    if (month === 13) {
      year++;
      month = 1;
    }
    converted = `${year}-${z(month)}-${z(day)}T23:59:59`;
  }
  if (type === 'start') {
    return `${d.getFullYear()}-${z(d.getMonth() + 1)}-01T00:00:00`;
  }
  if (type === 'end') {
    return `${d.getFullYear()}-${z(d.getMonth() + 1)}-${z(d.getDate())}T${z(d.getHours())}:${z(
      d.getMinutes()
    )}:${z(d.getSeconds())}`;
  }
  // if (type === 'today') {
  //   return `${d.getFullYear()}-${z(d.getMonth() + 1)}-${z(d.getDate())}T00:00:00`;
  // }
  // if (type === 'tonight') {
  //   return `${d.getFullYear()}-${z(d.getMonth() + 1)}-${z(d.getDate())}T23:59:59`;
  // }
  if (type === 'prevYear') {
    return `${d.getFullYear() - 1}-${z(d.getMonth() + 1)}-${z(d.getDate())}T00:00:00`;
  }
  // if (type === 'lastMonth') {
  //   return `${d.getFullYear()}-${z(d.getMonth())}-01T00:00:00`;
  // }
  // if (type === 'lastYear') {
  //   return `${d.getFullYear() - 1}-01-01T00:00:00`;
  // }
  // if (type === 'yearToDay') {
  //   return `${d.getFullYear()}-01-01T00:00:00`;
  // }
  // if (type === 'weekToDay') {
  //   let day = z(d.getDate()) - d.getDay();
  //   return `${d.getFullYear()}-${z(d.getMonth() + 1)}-${z(day + 1)}T00:00:00`;
  // }
  return converted;
};

const thirtyDaysBefore = () => {
  let date = new Date();
  date.setDate(date.getDate() - 30 + 1);
  // date = date.toISOString();
  let endDate = new Date();
  endDate.setDate(endDate.getDate());
  // endDate = endDate.toISOString();
  return [date, endDate];
};
const sevenDaysBefore = () => {
  let date = new Date();
  date.setDate(date.getDate() - 7 + 1);

  // date = date.toISOString();

  let endDate = new Date();
  endDate.setDate(endDate.getDate());
  // endDate = endDate.toISOString();
  return [date, endDate];
};
const ninetyDaysBefore = () => {
  let date = new Date();
  date.setDate(date.getDate() - 90 + 1);

  // date = date.toISOString();

  let endDate = new Date();
  endDate.setDate(endDate.getDate());
  // endDate = endDate.toISOString();
  return [date, endDate];
};

const oneYearBefore = () => {
  let date = new Date();
  date.setDate(date.getDate() - 365 + 1);

  // date = date.toISOString();

  let endDate = new Date();
  endDate.setDate(endDate.getDate());
  return [date, endDate];
};
// const threeSixtyFiveDaysBefore = () => {
//   let date = new Date();
//   date.setDate(date.getDate() - 365);

//   date = toISOLocal(date, 'curr');

//   let endDate = new Date();
//   endDate.setDate(endDate.getDate() - 1);
//   endDate = toISOLocal(endDate, 'tonight');
//   return [date, endDate];
// };
const todayDate = () => {
  let date = new Date();
  // date = date.toISOString();
  return [date, date];
};
const yesterDayDate = () => {
  let date = new Date();
  date.setDate(date.getDate() - 1);
  // date = date.toISOString();
  return [date, date];
};
// const lastMonth = () => {
//   let date = new Date();
//   date = toISOLocal(date, 'lastMonth');
//   let endDate = new Date();
//   endDate = toISOLocal(endDate, 'start');

//   return [date, endDate];
// };
// const lastYear = () => {
//   let date = new Date();

//   let endDate = `${date.getFullYear()}-12-31T23:59:59`;
//   date = toISOLocal(date, 'lastYear');
//   return [date, endDate];
// };

// const yearToDay = () => {
//   let date = new Date();
//   date = toISOLocal(date, 'yearToDay');
//   let endDate = new Date();
//   endDate = toISOLocal(endDate, 'end');

//   return [date, endDate];
// };

// const monthToDay = () => {
//   let date = new Date();
//   date = toISOLocal(date, 'start');
//   let endDate = new Date();
//   endDate = toISOLocal(endDate, 'end');

//   return [date, endDate];
// };
// const weekToDay = () => {
//   let date = new Date();
//   date = toISOLocal(date, 'weekToDay');
//   let endDate = new Date();
//   endDate = toISOLocal(endDate, 'end');

//   return [date, endDate];
// };

const dateToNormalString = dates => {
  let d = {start: '', end: ''};
  d.start = dates.start.toString();
  d.end = dates.end.toString();

  let startArray = d.start.split(' ');
  let startMonth = startArray[1];
  let startDay = startArray[2];
  let startYear = startArray[3];
  let endArray = d.end.split(' ');
  let endMonth = endArray[1];
  let endDay = endArray[2];
  let endYear = endArray[3];
  if (startYear === endYear) {
    return `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${endYear}`;
  } else {
    return `${startMonth} ${startDay}, ${startYear} - ${endMonth} ${endDay}, ${endYear}`;
  }
};
const previousPeriod = dates => {
  let endTime = new Date(dates.end);
  let days = endTime - dates.start.getTime();
  days = days / (1000 * 60 * 60 * 24);
  days = Math.ceil(days);
  let date = new Date(`${dates.start}`);
  date.setDate(date.getDate() - days - 1);
  date = toISOLocal(date, 'end');
  let endDate = new Date(`${dates.start}`);
  endDate.setDate(endDate.getDate() - 1);
  endDate = toISOLocal(endDate, 'end');
  return [new Date(date), new Date(endDate)];
};

const previousYear = dates => {
  let sdate = new Date(`${dates.start}`);
  sdate = new Date(toISOLocal(sdate, 'prevYear'));
  let edate = new Date(`${dates.end}`);
  edate = new Date(toISOLocal(edate, 'prevYear'));
  return [sdate, edate];
};
const toClickHouseDate = (date, type) => {
  if (!date) {
    return date;
  }
  let year = date.toLocaleString('default', {year: 'numeric'});
  let month = date.toLocaleString('default', {month: '2-digit'});
  let day = date.toLocaleString('default', {day: '2-digit'});
  let formattedDate = year + '-' + month + '-' + day;
  return type === 'start' ? formattedDate + ' 00:00:00' : formattedDate + ' 23:59:59';
};

const toShortDate = date => {
  // Get year, month, and day part from the date
  let year = date.toLocaleString('default', {year: 'numeric'});
  let month = date.toLocaleString('default', {month: '2-digit'});
  let day = date.toLocaleString('default', {day: '2-digit'});

  // Generate yyyy-mm-dd date string
  let formattedDate = year + '-' + month + '-' + day;
  return formattedDate;
};

const convertToOrgTimezone = date => {
  if (!date) {
    return date;
  }
  let user = LocalStorageService.getAccountDetails().user;
  let timezone;
  if (user.profile?.userType === 'admin') {
    timezone = '(GMT+05:30) Asia/Calcutta';
  } else {
    let org = LocalStorageService.getUserOrg();
    timezone = org.timezone;
  }

  let timezoneOffset = '';
  for (let ch of timezone) {
    if (ch.match(/[0-9\-+:]/)) {
      timezoneOffset += ch;
    }
  }
  //converting date-time to ISO and then in required format before sending it in API call
  date = new Date(date.split(' ').join('T') + timezoneOffset)
    .toISOString()
    .replace('.000Z', '')
    .replace('T', ' ');
  return date;
};
/**calcDate() : Calculates the difference between two dates
 * @param  {Date} date1 : "First Date in the format MM-DD-YYYY"
 * @param  {Date} date2 : "Second Date in the format MM-DD-YYYY"
 * @return {String} calculated date
 */

const calcDate = (date1, date2) => {
  //new date instance
  const dt_date1 = new Date(date1);
  const dt_date2 = new Date(date2);

  //Get the Timestamp
  const date1_time_stamp = dt_date1.getTime();
  const date2_time_stamp = dt_date2.getTime();

  let calc;

  //Check which timestamp is greater
  if (date1_time_stamp > date2_time_stamp) {
    calc = new Date(date1_time_stamp - date2_time_stamp);
  } else {
    calc = new Date(date2_time_stamp - date1_time_stamp);
  }
  //Retrieve the date, month and year
  const calcFormatTmp = calc.getDate() + '-' + (calc.getMonth() + 1) + '-' + calc.getFullYear();
  //Convert to an array and store
  const calcFormat = calcFormatTmp.split('-');
  //Subtract each member of our array from the default date
  const days_passed = Number(Math.abs(calcFormat[0]) - 1);
  const months_passed = Number(Math.abs(calcFormat[1]) - 1);
  const years_passed = Number(Math.abs(calcFormat[2]) - 1970);

  //Set up custom text
  const yrsTxt = [' year', ' years'];
  const mnthsTxt = [' month', ' months'];
  const daysTxt = [' day', ' days'];

  //Convert to days and sum together
  const total_days = years_passed * 365 + months_passed * 30.417 + days_passed;

  //display result with custom text
  const result =
    (years_passed == 1
      ? years_passed + '' + yrsTxt[0] + `${months_passed > 0 ? ', ' : ''}`
      : years_passed > 1
      ? years_passed + '' + yrsTxt[1] + `${months_passed > 0 ? ', ' : ''}`
      : '') +
    (months_passed == 1
      ? months_passed + '' + mnthsTxt[0] + `${days_passed > 0 ? ', ' : ''}`
      : months_passed > 1
      ? months_passed + '' + mnthsTxt[1] + `${days_passed > 0 ? ', ' : ''}`
      : '') +
    (days_passed == 1
      ? days_passed + '' + daysTxt[0]
      : days_passed != 1
      ? days_passed + '' + daysTxt[1]
      : '');
  //return the result
  return result.trim();
};

/**daysBetweenTwoDates() : Calculates the days between two dates
 * @param  {Date} d1 : First Date
 * @param  {Date} d2 : Second Date
 * @return {Number} rounded of days between two dates
 */
const daysBetweenTwoDates = (d1, d2) => {
  const date1 = new Date(d1);
  const date2 = new Date(d2);
  const timeDifference = date2 - date1;
  return Math.round(timeDifference / (1000 * 60 * 60 * 24));
};

/**daysToYearsMonthsDays() : Format days to Y-M-D format
 * @param  {Number} days : days
 * @return {String} formated string into Y-M-D format
 */
const daysToYearsMonthsDays = days => {
  if (!days) return '0 days';
  const years = Math.floor(days / 365);
  const remainingDaysAfterYears = days % 365;
  const months = Math.floor(remainingDaysAfterYears / 30);
  const remainingDays = Math.floor(remainingDaysAfterYears % 30);

  const result = [];

  if (years > 0) {
    result.push(`${years} year${years > 1 ? 's' : ''}`);
  }

  if (months > 0) {
    result.push(`${months} month${months > 1 ? 's' : ''}`);
  }

  if (remainingDays > 0) {
    result.push(`${remainingDays} day${remainingDays > 1 ? 's' : ''}`);
  }

  return result.join(', ');
};

export {
  // toISOLocal,
  thirtyDaysBefore,
  todayDate,
  yesterDayDate,
  ninetyDaysBefore,
  sevenDaysBefore,
  dateToNormalString,
  previousPeriod,
  previousYear,
  toClickHouseDate,
  toShortDate,
  convertToOrgTimezone,
  oneYearBefore,
  calcDate,
  daysBetweenTwoDates,
  daysToYearsMonthsDays,
};
