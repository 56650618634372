import {Tabs, Box} from '@shopify/polaris';
import React, {useCallback, useState} from 'react';
import Page from '../../../components/page';
import {useNavigate, useSearchParams} from 'react-router-dom';
import ActiveAlert from '../ActiveAlerts';
import {ActiveReports} from '../activeReports';

const AlertsAndReportPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const tab = searchParams.get('_') ? Number(searchParams.get('_')) - 1 : 0;
  const [selected, setSelected] = useState(tab || 0);
  const handleTabChange = useCallback(selectedTabIndex => {
    setSelected(selectedTabIndex);
    setSearchParams({_: selectedTabIndex + 1});
  }, []);

  const handleAction = () => {
    navigate('/alert-new');
  };

  const tabs = [
    {
      id: 'activeAlerts',
      content: 'Active Alerts',
      accessibilityLabel: 'active-alerts',
      panelID: 'active-alerts-content-1',
      component: <ActiveAlert />,
    },
    {
      id: 'activeReports',
      content: 'Active Reports',
      accessibilityLabel: 'active-reports',
      panelID: 'active-reports-content-1',
      component: <ActiveReports />,
    },
  ];
  return (
    <>
      <Page
        title="Alerts and Reports"
        subtitle="View and manage your alerts and reports"
        primaryAction={
          tab === 1 ? (
            <></>
          ) : (
            {
              content: 'New alert',
              onAction: handleAction,
            }
          )
        }
        // secondaryActions={
        //   tab === 1 ? (
        //     <></>
        //   ) : (
        //     [
        //       {
        //         content: 'Alert history',
        //         onAction: () => {
        //           navigate('/alert-history');
        //         },
        //       },
        //     ]
        //   )
        // }
      >
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          <Box paddingBlockStart={'6'} paddingInlineStart={'3'}>
            {tabs[selected].component}
          </Box>
        </Tabs>
      </Page>
    </>
  );
};

export default AlertsAndReportPage;
