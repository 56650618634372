import React from 'react';
import Box from '../../../lib/Box';
import Card from '../../../lib/Card';
import Inline from '../../../lib/Inline';
import {useState, useCallback, useEffect} from 'react';
import {AdminApi} from '../../../api/admin';
import Page from '../../../components/page';
import MultiSelect from '../../../components/multiSelect';
import DefaultTable from '../../../components/defaultIndexTable';
import {ErrorBoundary} from '../../../components/ErrorBoundary';
import {adminAsyncJobsColumns} from '../../metrics/columns';
import {
  thirtyDaysBefore,
  todayDate,
  yesterDayDate,
  ninetyDaysBefore,
  sevenDaysBefore,
  dateToNormalString,
  toClickHouseDate,
  oneYearBefore,
} from '../../../features/convertDates';
import DateRangeComponent from '../../../components/dateRangeComponent';
import {formatDate, toTitleCase} from '../../../features/format';
import JsonViewerModal from '../../../components/Modals/JsonViewerModal';
import {sourceOptions, statusOptions, typeOptions} from './options';
import Button from '../../../lib/Button';

const dateRangeObj = {
  yesterday: yesterDayDate,
  today: todayDate,
  lastThirtyDays: thirtyDaysBefore,
  lastSevenDays: sevenDaysBefore,
  lastNinetyDays: ninetyDaysBefore,
  lastOneYear: oneYearBefore,
};
const dateRangeValueToLable = {
  today: 'Today',
  yesterday: 'Yesterday',
  lastSevenDays: 'Last 7 Days',
  lastThirtyDays: 'Last 30 Days',
  lastNinetyDays: 'Last 90 Days',
  lastOneYear: 'Last 1 Year',
};

const AsyncJobs = () => {
  const [orgId, setOrgId] = useState('');
  const [dateRange, setDateRange] = useState('lastSevenDays');
  const [popoverActive, setPopoverActive] = useState(false);
  const [jobs, setJobs] = useState({data: [], state: 'Loading', totalRows: 0});
  const [orgsOptions, setOrgsOptions] = useState([]);
  const [sortBy, setSortBy] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('desc');
  const [queryValue, setQueryValue] = useState('');
  const [status, setStatus] = useState('');
  const [type, setType] = useState([]);
  const [source, setSource] = useState([]);
  const [page, setPage] = useState(1);
  const [organizationIdToNameMap, setOrganizationIdToNameMap] = useState(null);
  const [dataToPreview, setDataToPreview] = useState(null);

  const [selectedDates, setSelectedDates] = useState(
    dateRange
      ? {
          start: new Date(dateRangeObj[dateRange]()[0]),
          end: new Date(dateRangeObj[dateRange]()[1]),
        }
      : {
          start: new Date(),
          end: new Date(),
        }
  );
  const getDates = (dates, dateOption) => {
    setSelectedDates(dates);
    setDateRange(dateOption);
  };

  const fetchAsyncJobs = async () => {
    try {
      setJobs({data: [], state: 'Loading', totalRows: null});

      let sdate = toClickHouseDate(selectedDates.start, 'start');
      let edate = toClickHouseDate(selectedDates.end, 'end');
      let res = await AdminApi.getAsyncJobs({
        orgId,
        sortBy,
        sortOrder,
        sdate,
        edate,
        page,
        status,
        type,
        search: queryValue,
        source,
      });
      if (res.status) {
        res.data.jobs.forEach(job => {
          job.organizationName = organizationIdToNameMap[job.orgId] || 'Not set';
        });
        setJobs({data: res.data.jobs, state: 'Success', totalRows: res.data.totalRows});
      } else {
        setJobs({data: [], state: 'Error', totalRows: 0});
      }
    } catch (err) {
      setJobs({data: [], state: 'Error', totalRows: 0});
    }
  };

  const fetchOrganizations = async () => {
    try {
      let res = await AdminApi.getOrgs();
      let arr = [{label: 'All', value: ''}];
      let map = {};
      res.data.forEach(r => {
        arr.push({label: r.name, value: r._id});
        map[r._id] = r.name;
      });
      setOrganizationIdToNameMap({...map});
      setOrgsOptions(arr);
    } catch (err) {
      setOrgsOptions([]);
    }
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  useEffect(() => {
    if (Boolean(organizationIdToNameMap)) {
      fetchAsyncJobs();
    }
  }, [
    orgId,
    selectedDates,
    dateRange,
    organizationIdToNameMap,
    status,
    type,
    sortBy,
    sortOrder,
    queryValue,
    page,
    source,
  ]);

  const togglePopoverActive = useCallback(
    () => setPopoverActive(popoverActive => !popoverActive),
    []
  );
  const activator = (
    <div style={{width: '100%'}}>
      <Button onClick={togglePopoverActive} disclosure>
        {dateRange ? dateRangeValueToLable[dateRange] : dateToNormalString(selectedDates)}
      </Button>
    </div>
  );

  return (
    <>
      <Page title="Async jobs">
        <ErrorBoundary
          fallback={
            <Card>
              <Card.Section>
                <div>An error has occured</div>
              </Card.Section>
            </Card>
          }
        >
          {!!jobs.data && (
            <DefaultTable
              isSearchEnabled
              queryPlaceholder="search by async job id"
              filters={[
                <MultiSelect
                  title="Organization"
                  options={orgsOptions}
                  selected={orgId}
                  setSelected={setOrgId}
                  disableMultiple={true}
                  setPage={setPage}
                />,
                <MultiSelect
                  title="Type"
                  options={typeOptions}
                  selected={type}
                  setSelected={setType}
                  setPage={setPage}
                />,
                <MultiSelect
                  title="Source"
                  options={sourceOptions}
                  selected={source}
                  setSelected={setSource}
                  setPage={setPage}
                />,
                <MultiSelect
                  title="Status"
                  options={statusOptions}
                  selected={status}
                  setSelected={setStatus}
                  disableMultiple={true}
                  setPage={setPage}
                />,

                <Box>
                  <DateRangeComponent
                    currentDates={selectedDates}
                    getDates={getDates}
                    activator={activator}
                    popoverActive={popoverActive}
                    togglePopoverActive={togglePopoverActive}
                    currentDateOption={dateRange}
                  />
                </Box>,
              ]}
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortExceptions={[]}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              select={false}
              queryValue={queryValue}
              setQueryValue={setQueryValue}
              page={page}
              setPage={setPage}
              resourceName={{
                singular: 'async job',
                plural: 'async jobs',
              }}
              columns={adminAsyncJobsColumns}
              selectedFilters={[]}
              durationFilter={'daily'}
              totalRows={jobs.data.length ? jobs.totalRows : 0}
              tableData={jobs.data}
              param={{}}
              valueTypes={{
                showJson: (data, col) => {
                  return (
                    <Button
                      key={data._id}
                      plain
                      onClick={() => {
                        setDataToPreview(data[col.value]);
                      }}
                    >
                      {col.title}
                    </Button>
                  );
                },
                date: (data, col) => {
                  return (
                    <Inline align="end">
                      {formatDate({value: new Date(data[col.value]), removeTime: false})}
                    </Inline>
                  );
                },
                orgId: (data, col) => {
                  return toTitleCase(organizationIdToNameMap[data[col.value]]) || '';
                },
              }}
              state={jobs.state}
            />
          )}
        </ErrorBoundary>

        {Boolean(dataToPreview) && (
          <JsonViewerModal
            isOpen={Boolean(dataToPreview)}
            toggle={() => setDataToPreview(null)}
            data={dataToPreview}
          />
        )}
      </Page>
    </>
  );
};

export default AsyncJobs;
