import {useState} from 'react';
import {generateInfoText} from '../../../features/generateText';
import DefinationPopover from '../../../components/definationPopover';
import {CircleInformationMajor} from '@shopify/polaris-icons';
import {Link, Icon} from '@shopify/polaris';
import {useNavigate} from 'react-router-dom';
import Inline from '../../../lib/Inline';

const Heading = ({item, trialPeriodStatus, currentPlan}) => {
  const [infoPopoverActive, setInfoPopoverActive] = useState(false);
  const navigate = useNavigate();

  let url;
  if (item.productGroupType === 'Pre-built') {
    url = `/product-group/${item._id}`;
  } else if (item.productGroupType !== 'Pre-built') {
    if (trialPeriodStatus === 'active') {
      url = `/product-group/${item._id}`;
    } else if (currentPlan !== 'Free') {
      url = `/product-group/${item._id}`;
    } else {
      url = `/pricing`;
    }
  }
  const linkToProductGroup = (
    <Link
      url={url}
      removeUnderline
      onClick={event => {
        event.preventDefault();
        navigate(url);
      }}
      key="emerald-silk-gown"
    >
      {item.productGroupDetail.name}
    </Link>
  );

  return (
    <>
      <Inline key={item._id}>
        {generateInfoText(item.productGroupDetail.name) ? (
          <DefinationPopover
            text={generateInfoText(item.productGroupDetail.name)}
            togglePopoverActive={() => {
              setInfoPopoverActive(false);
            }}
            popoverActive={infoPopoverActive}
            activator={
              <span
                style={{cursor: 'pointer'}}
                onMouseEnter={() => {
                  setInfoPopoverActive(true);
                }}
                onMouseLeave={() => {
                  setInfoPopoverActive(false);
                }}
              >
                {linkToProductGroup}
              </span>
            }
          />
        ) : (
          <>{linkToProductGroup}</>
        )}
      </Inline>
    </>
  );
};

export default Heading;
