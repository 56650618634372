let tenToHundred = [];
let oneToHundred = [];
for (let i = 1; i <= 100; i++) {
  if (i >= 10) {
    tenToHundred.push({label: i + '%', value: i + ''});
  }
  oneToHundred.push({label: i + '%', value: i + ''});
}

const options = {
  sku: {
    name: 'SKU',
    operator: [
      {label: 'contains any', value: 'containsAny'},
      {label: 'contains all', value: 'containsAll'},
      {label: "doesn't contain any", value: 'doesntContainAny'},
      {label: "doesn't contain all", value: 'doesntContainAll'},
    ],
    timeSelector: {required: false},
    value: {
      containsAny: {
        required: true,
        type: 'multiText',
      },
      containsAll: {
        required: true,
        type: 'multiText',
      },
      doesntContainAny: {
        required: true,
        type: 'multiText',
      },
      doesntContainAll: {
        required: true,
        type: 'multiText',
      },
    },
  },
  variantTitle: {
    name: 'Variant title',
    operator: [
      {label: 'contains any', value: 'containsAny'},
      {label: 'contains all', value: 'containsAll'},
      {label: "doesn't contain any", value: 'doesntContainAny'},
      {label: "doesn't contain all", value: 'doesntContainAll'},
    ],
    timeSelector: {required: false},
    value: {
      containsAny: {
        required: true,
        type: 'multiText',
      },
      containsAll: {
        required: true,
        type: 'multiText',
      },
      doesntContainAny: {
        required: true,
        type: 'multiText',
      },
      doesntContainAll: {
        required: true,
        type: 'multiText',
      },
    },
  },
  variantId: {
    name: 'Variant',
    operator: [
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
    ],
    timeSelector: {required: false},
    value: {
      isOneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'variants',
        label: 'Select variant',
      },
      isNoneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'variants',
        label: 'Select variant',
      },
    },
  },
  productTitle: {
    name: 'Product title',
    operator: [
      {label: 'contains any', value: 'containsAny'},
      {label: 'contains all', value: 'containsAll'},
      {label: "doesn't contain any", value: 'doesntContainAny'},
      {label: "doesn't contain all", value: 'doesntContainAll'},
    ],
    timeSelector: {required: false},
    value: {
      containsAny: {
        required: true,
        type: 'multiText',
      },
      containsAll: {
        required: true,
        type: 'multiText',
      },
      doesntContainAny: {
        required: true,
        type: 'multiText',
      },
      doesntContainAll: {
        required: true,
        type: 'multiText',
      },
    },
  },
  productId: {
    name: 'Product',
    operator: [
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
    ],
    timeSelector: {required: false},
    value: {
      isOneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'products',
        label: 'Select product',
      },
      isNoneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'products',
        label: 'Select product',
      },
    },
  },
  tags: {
    name: 'Product tags',
    operator: [
      {label: 'contains any', value: 'containsAny'},
      {label: 'contains all', value: 'containsAll'},
      {label: 'does not contains any', value: 'doesNotContainAny'},
      {label: 'does not contains all', value: 'doesNotContainAll'},
    ],
    timeSelector: {required: false},
    value: {
      containsAny: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'productTags',
        label: 'Select product',
      },
      containsAll: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'productTags',
        label: 'Select product',
      },
      doesNotContainAny: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'productTags',
        label: 'Select product',
      },
      doesNotContainAll: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'productTags',
        label: 'Select product',
      },
    },
  },
  productType: {
    name: 'Product category',
    operator: [
      {label: 'is one of', value: 'isOneOf'},
      {label: 'is none of', value: 'isNoneOf'},
      {label: 'is exactly', value: 'isExactly'},
      {label: 'contains any', value: 'containsAny'},
      {label: 'contains all', value: 'containsAll'},
      {label: 'does not contains any', value: 'doesNotContainAny'},
      {label: 'does not contains all', value: 'doesNotContainAll'},
    ],
    timeSelector: {required: false},
    value: {
      isExactly: {
        required: true,
        type: 'filterDropdown',
        itemType: 'productCategory',
        label: 'Select category',
      },
      isOneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'productCategory',
        label: 'Select category',
      },
      isNoneOf: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'productCategory',
        label: 'Select category',
      },
      containsAny: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'productTags',
        label: 'Select product',
      },
      containsAll: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'productTags',
        label: 'Select product',
      },
      doesNotContainAny: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'productTags',
        label: 'Select product',
      },
      doesNotContainAll: {
        required: true,
        type: 'filterDropdownMultiSelect',
        itemType: 'productTags',
        label: 'Select product',
      },
    },
  },
};

const groups = [];
let i = 0;
for (let key in options) {
  groups.push({label: options[key].name, id: key, value: i});
  i++;
}

export {options, groups};
