import React, {useState} from 'react';
import {Text, Page, TextContainer, Link, Box} from '@shopify/polaris';
import {AuthAPI} from '../../../../api/auth';
import Alert from '../../../../components/alert';
const ForgotMessage = ({email}) => {
  const [success, setSuccess] = useState('');
  const [successAlert, setSuccessAlert] = useState(false);
  const [error, setError] = useState('');
  const [errorAlert, setErrorAlert] = useState(false);
  const resend = async () => {
    setSuccess('');
    setSuccessAlert(false);
    setError('');
    setErrorAlert(false);
    const response = await AuthAPI.resetPasswordRequest({
      email,
    });
    if (!response.status) {
      setError(response.message);
      setErrorAlert(true);
    } else {
      setSuccess('Check your email');
      setSuccessAlert(true);
    }
  };

  return (
    <>
      <Page narrowWidth>
        <Box paddingBlockStart={'24'}>
          <TextContainer spacing="loose">
            <Text variant="headingXl" as="h3" alignment="center">
              Check your email for instructions to reset your password.
            </Text>
            <Text alignment="center" as="p">
              If you haven’t received an email in 5 minutes, check your spam or{' '}
              <Link onClick={resend}>resend</Link>.
            </Text>
          </TextContainer>
        </Box>
        {errorAlert && <Alert contents={error} init={true} error={true} />}
        {successAlert && <Alert contents={success} init={true} error={false} />}
      </Page>
    </>
  );
};
export default ForgotMessage;
