import {Spinner, Icon} from '@shopify/polaris';
import Box from '../../../lib/Box';
import Page from '../../../components/page';
import React, {useState} from 'react';
import {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import DefaultTable from '../../../components/defaultIndexTable';
import {rfmCategoryAnalysis} from '../../../api/reports';
import {saveAs} from 'file-saver';
import {rfmCategoryColumns} from '../columns';
import {ErrorBoundary} from '../../../components/ErrorBoundary';
import {track} from '../../../features/track';
import {generateInfoText} from '../../../features/generateText';
import DefinationPopover from '../../../components/definationPopover';
import {CircleInformationMajor} from '@shopify/polaris-icons';
import Card from '../../../lib/Card';

const RfmCategory = () => {
  const [sortBy, setSortBy] = useState('AOV');
  const [sortOrder, setSortOrder] = useState('desc');
  const [queryValue, setQueryValue] = useState('');
  const [download, setDownload] = useState({state: 'Success'});
  const [page, setPage] = useState(1);
  const params = useParams();
  const [orders, setOrders] = useState({data: []});
  const [infoPopoverActive, setInfoPopoverActive] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    track('Page View', {
      name: 'RFM Category',
    });
  }, []);

  useEffect(() => {
    setOrders({data: [], state: 'Loading'});
    rfmCategoryAnalysis({
      category: params.category,
      sortBy,
      sortOrder,
      page,
      type: 'table',
    })
      .then(res => {
        setOrders({data: res.data.data, state: 'Success'});
      })
      .catch(err => {
        setOrders({data: [], state: 'Error'});
      });
  }, [sortBy, sortOrder, page]);

  const handleDownload = () => {
    setDownload({state: 'Loading'});
    rfmCategoryAnalysis({
      category: params.category,
      sortBy,
      sortOrder,
      page,
      type: 'download',
    })
      .then(response => {
        setDownload({state: 'Success'});
        if (response.data.length) {
          saveAs(
            new Blob([response.data], {
              type: response.headers['content-type'],
            }),
            response.headers.filename
          );
        }
      })
      .catch(err => {
        // console.log(err);
        setDownload({state: 'Error'});
      });
  };

  return (
    <>
      <Page
        breadcrumbs={[
          {
            content: 'Settings',
            onAction: () => {
              navigate(-1);
            },
          },
        ]}
        secondaryActions={[
          {
            content: download.state === 'Loading' ? <Spinner size="small" /> : 'Download',
            destructive: false,
            onAction: handleDownload,
          },
          {
            content: generateInfoText(params.category) ? (
              <DefinationPopover
                text={generateInfoText(params.category)}
                togglePopoverActive={() => {
                  setInfoPopoverActive(false);
                }}
                popoverActive={infoPopoverActive}
                activator={
                  <div
                    onMouseEnter={() => {
                      setInfoPopoverActive(true);
                    }}
                    onMouseLeave={() => {
                      setInfoPopoverActive(false);
                    }}
                  >
                    <Icon source={CircleInformationMajor} />
                  </div>
                }
              />
            ) : (
              ''
            ),
          },
        ]}
        title={params.category}
      >
        <Box paddingBlockEnd={'4'}>
          <ErrorBoundary
            fallback={
              <Card>
                <Card.Section>
                  <div>An error has occured</div>
                </Card.Section>
              </Card>
            }
          >
            <DefaultTable
              setSelectedDateRangeDates={{}}
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortExceptions={[]}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              select={false}
              queryValue={queryValue}
              setQueryValue={setQueryValue}
              page={page}
              setPage={setPage}
              resourceName={{
                singular: 'Customer',
                plural: 'Customers',
              }}
              columns={rfmCategoryColumns}
              selectedFilters={[]}
              totalRows={orders.data.length ? orders.data[0].totalRows : 0}
              tableData={orders.data}
              durationFilter={'daily'}
              state={orders.state}
            />
          </ErrorBoundary>
        </Box>
      </Page>
    </>
  );
};
export default RfmCategory;
