import React, {useEffect, useState} from 'react';
import {Tooltip as PolarisTooltip} from '@shopify/polaris';
import {sankey, sankeyLinkHorizontal, sankeyJustify} from 'd3-sankey';
import {getTransformedProductTitle} from '../../../features/getTransformedProductTitle';

const MIN_VALUE = 1;
const MAX_VALUE = 10;

const Tooltip = ({x, y, text}) => {
  return (
    <foreignObject x={x} y={y} width="1" height="1">
      <PolarisTooltip content={text} preferPlace="top" active={text} dismissOnMouseOut={false}>
        <div style={{width: '100%', height: '100%'}}></div>
      </PolarisTooltip>
    </foreignObject>
  );
};

const ProductJourneyChart = ({
  rangeValue,
  productJourneyData,
  setProductJourneyData,
  initialWidth = 900,
  initialHeight = 600,
}) => {
  const [width, setWidth] = useState(initialWidth);
  const [height, setHeight] = useState(initialHeight);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth * 0.5);
      setHeight(window.innerHeight * 0.7);
    };

    // Initial setup
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // const [moddedData, setModdedData] = useState([]);
  const nodeMap = {};
  let i = 0;

  productJourneyData.forEach(obj => {
    obj.reformedValue = obj.value
      ? MIN_VALUE + (obj.value * (MAX_VALUE - MIN_VALUE)) / 100
      : obj.value;
  });

  const nodes = productJourneyData
    .map(obj => {
      const arr = [];
      const sourceRank = obj.orderRank - 1;
      const targetRank = obj.orderRank;
      const sourceName = obj.sourceProductType
        ? obj.sourceProductType
        : getTransformedProductTitle({
            sku: obj.sourceSKU,
            title: width < initialWidth && obj.sourceSKU ? null : obj.sourceTitle,
            variantTitle: width < initialWidth ? null : obj.sourceVariantTitle,
          });
      const targetName = obj.targetProductType
        ? obj.targetProductType
        : getTransformedProductTitle({
            sku: obj.targetSKU,
            title: width < initialWidth && obj.targetSKU ? null : obj.targetTitle,
            variantTitle: width < initialWidth ? null : obj.targetVariantTitle,
          });

      if (nodeMap[`${obj.source}_${sourceRank}`] === undefined) {
        arr.push({
          node: i,
          name: sourceName,
          sku: obj.sourceProductType ? obj.sourceProductType : obj.sourceSKU,
        });
        nodeMap[`${obj.source}_${sourceRank}`] = i;
        ++i;
      }
      if (nodeMap[`${obj.target}_${targetRank}`] === undefined) {
        arr.push({
          node: i,
          name: targetName,
          sku: obj.targetProductType ? obj.targetProductType : obj.targetSKU,
        });
        nodeMap[`${obj.target}_${targetRank}`] = i;
        ++i;
      }

      return arr;
    })
    .flat();

  const links = productJourneyData.map(obj => {
    const sourceRank = obj.orderRank - 1;
    const targetRank = obj.orderRank;
    return {
      source: nodeMap[`${obj.source}_${sourceRank}`],
      target: nodeMap[`${obj.target}_${targetRank}`],
      value: obj.reformedValue,
      targetRank: obj.orderRank,
      intrinsicValue: obj.value,
    };
  });
  const data = {nodes: [...nodes], links: [...links]};

  const MARGIN_Y = 25;
  const MARGIN_X = 5;

  const sankeyGenerator = sankey()
    .nodeWidth(10)
    .nodePadding(35)
    .extent([
      [MARGIN_X, MARGIN_Y],
      [width - MARGIN_X, height - MARGIN_Y],
    ])
    .nodeId(node => node.node)
    .nodeAlign(sankeyJustify);

  const {nodes: sankeyNodes, links: sankeyLinks} = sankeyGenerator(data);

  // let moddedDataMap = {};
  // moddedData.forEach(obj => {
  //   moddedDataMap[`${obj.source}_${obj.target}_${obj.orderRank}`] = obj.previousValue;
  // });

  const Node = ({node}) => {
    // const [isClicked, setIsClicked] = useState(false);
    const nodeColor = 'blue';

    // const handleNodeClick = node => {
    //   setIsClicked(!isClicked);

    //   let modifiedData = [];
    //   let targetLinks = node.targetLinks;
    //   targetLinks.forEach(link => {
    //     modifiedData.push({
    //       source: link.source.sku || link.source.name,
    //       target: link.target.sku || link.target.name,
    //       orderRank: link.targetRank,
    //     });
    //   });

    //   let indexesToModifyData = [];
    //   modifiedData.forEach(obj => {
    //     let source = obj.source;
    //     let target = obj.target;
    //     let orderRank = obj.orderRank;
    //     productJourneyData.forEach((dataObj, i) => {
    //       if (
    //         dataObj.orderRank === orderRank &&
    //         (dataObj.sourceSKU === source || dataObj.source === source) &&
    //         (dataObj.targetSKU === target || dataObj.target === target)
    //       ) {
    //         indexesToModifyData.push({index: i, source, target, orderRank});
    //       }
    //     });
    //   });
    //   let data = [...productJourneyData];
    //   let arr = [];
    //   indexesToModifyData.forEach(({index, source, target, orderRank}) => {
    //     let currentValue = data[index].value;
    //     data[index] = {...data[index], value: 1};
    //     arr.push({index, previousValue: currentValue, source, target, orderRank});
    //   });
    //   setModdedData([...moddedData, ...arr]);
    //   setProductJourneyData(data);
    // };

    return (
      <g key={node.index}>
        <rect
          height={node.y1 - node.y0}
          width={sankeyGenerator.nodeWidth()}
          x={node.x0}
          y={node.y0}
          fill={nodeColor}
          fillOpacity={1}
          rx={0.9}
          // style={{cursor: node.name === 'Others' ? 'pointer' : 'default'}}
          // onClick={() => handleNodeClick(node)}
        />
      </g>
    );
  };

  const Link = ({link}) => {
    const [tooltipContent, setTooltipContent] = useState(null);
    const linkGenerator = sankeyLinkHorizontal();
    const path = linkGenerator(link);
    const tooltipX = (link.source.x0 + link.target.x1) / 2;
    const tooltipY = (link.y0 + link.y1) / 2;
    const tooltipText = `${link.source.sku || link.target.name} to ${
      link.target.sku || link.target.name
    } : ${
      // moddedDataMap[
      //   `${link.source.sku || link.source.name}_${link.target.sku || link.target.name}_${
      //     link.targetRank
      //   }`
      // ] ||
      link.intrinsicValue
    }%`;

    return (
      <g>
        {/* <div> */}
        <path
          d={path}
          stroke={
            link.target.name === 'Others' ? '#e94646' : link.targetRank === 2 ? '#9b9512' : 'gray'
          }
          fill="none"
          strokeOpacity={0.5}
          strokeWidth={link.width}
          onMouseEnter={() => setTooltipContent(tooltipText)}
          onMouseLeave={() => setTooltipContent(null)}
        />
        {/* </div> */}
        {tooltipContent && <Tooltip x={tooltipX} y={tooltipY} text={tooltipContent} />}
      </g>
    );
  };

  const allNodes = sankeyNodes.map(node => <Node key={node.index} node={node} />);

  const allLinks = sankeyLinks.map((link, i) => <Link key={i} link={link} />);

  const allLabels = nodes.map((node, i) => {
    const labelWidth = 200;
    const labelContent = node.name;

    return (
      <foreignObject
        key={i}
        x={node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6 - labelWidth}
        y={(node.y1 + node.y0) / 2 - 24}
        width={labelWidth}
        height={55}
        textAnchor={node.x0 < width / 2 ? 'start' : 'end'}
        fontSize={12}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: node.node > +rangeValue + 1 ? 'right' : 'left',
            alignItems: 'center',
            height: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'pre-wrap',
          }}
        >
          {labelContent}
        </div>
      </foreignObject>
    );
  });

  return (
    <div>
      <svg width={width} height={height}>
        {allLinks}
        {allNodes}
        {allLabels}
      </svg>
    </div>
  );
};

export default ProductJourneyChart;
