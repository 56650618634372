let tenToHundred = [];
let oneToHundred = [];
let rollingAverage = [
  {label: '1 day', value: '1'},
  {label: '7 days', value: '7'},
  {label: '14 days', value: '14'},
  {label: '30 days', value: '30'},
  {label: '60 days', value: '60'},
  {label: '90 days', value: '90'},
];

const threshHoldOpts = [
  {label: 'Flat', value: 'flat'},
  {label: 'Rolling average', value: 'rollingAverage'},
];
for (let i = 1; i <= 100; i++) {
  if (i >= 10) {
    tenToHundred.push({label: i + '%', value: i + ''});
  }
  oneToHundred.push({label: i + '%', value: i + ''});
}

const options = {
  adSpend: {
    name: 'Ad spend',
    operator: [
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
    ],
    by: {
      required: true,
      options: oneToHundred,
    },
    threshold: {
      isLessThan: {
        required: true,
        type: 'option',
        options: threshHoldOpts,
      },
      isGreaterThan: {
        required: true,
        type: 'option',
        options: threshHoldOpts,
      },
    },
    value: {
      flat: {
        required: true,
        type: 'number',
      },
      rollingAverage: {
        required: true,
        type: 'option',
        options: rollingAverage,
      },
    },
  },
  // adSpendByChannel: {
  //   name: 'Ad spend by channel',
  //   operator: [
  //     {label: 'is less than', value: 'isLessThan'},
  //     {label: 'is greater than', value: 'isGreaterThan'},
  //   ],
  //   by: {
  //     required: true,
  //     options: oneToHundred,
  //   },
  //   threshold: {
  //     isLessThan: {
  //       required: true,
  //       type: 'option',
  //       options: threshHoldOpts,
  //     },
  //     isGreaterThan: {
  //       required: true,
  //       type: 'option',
  //       options: threshHoldOpts,
  //     },
  //   },
  //   value: {
  //     flat: {
  //       required: true,
  //       type: 'number',
  //     },
  //     rollingAverage: {
  //       required: true,
  //       type: 'option',
  //       options: rollingAverage,
  //     },
  //   },
  // },
  // acc: {
  //   name: 'ACC',
  //   operator: [
  //     {label: 'is less than', value: 'isLessThan'},
  //     {label: 'is greater than', value: 'isGreaterThan'},
  //   ],
  //   by: {
  //     required: true,
  //     options: oneToHundred,
  //   },
  //   threshold: {
  //     isLessThan: {
  //       required: true,
  //       type: 'option',
  //       options: threshHoldOpts,
  //     },
  //     isGreaterThan: {
  //       required: true,
  //       type: 'option',
  //       options: threshHoldOpts,
  //     },
  //   },
  //   value: {
  //     flat: {
  //       required: true,
  //       type: 'number',
  //     },
  //     rollingAverage: {
  //       required: true,
  //       type: 'option',
  //       options: rollingAverage,
  //     },
  //   },
  // },
  aov: {
    name: 'AOV',
    operator: [
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
    ],
    by: {
      required: true,
      options: oneToHundred,
    },
    threshold: {
      isLessThan: {
        required: true,
        type: 'option',
        options: threshHoldOpts,
      },
      isGreaterThan: {
        required: true,
        type: 'option',
        options: threshHoldOpts,
      },
    },
    value: {
      flat: {
        required: true,
        type: 'number',
      },
      rollingAverage: {
        required: true,
        type: 'option',
        options: rollingAverage,
      },
    },
  },
  cancellation: {
    name: 'Cancellations',
    operator: [
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
    ],
    by: {
      required: true,
      options: oneToHundred,
    },
    threshold: {
      isLessThan: {
        required: true,
        type: 'option',
        options: threshHoldOpts,
      },
      isGreaterThan: {
        required: true,
        type: 'option',
        options: threshHoldOpts,
      },
    },
    value: {
      flat: {
        required: true,
        type: 'number',
      },
      rollingAverage: {
        required: true,
        type: 'option',
        options: rollingAverage,
      },
    },
  },
  conversion: {
    name: 'Conversion',
    operator: [
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
    ],
    by: {
      required: true,
      options: oneToHundred,
    },
    threshold: {
      isLessThan: {
        required: true,
        type: 'option',
        options: threshHoldOpts,
      },
      isGreaterThan: {
        required: true,
        type: 'option',
        options: threshHoldOpts,
      },
    },
    value: {
      flat: {
        required: true,
        type: 'number',
      },
      rollingAverage: {
        required: true,
        type: 'option',
        options: rollingAverage,
      },
    },
  },
  discount: {
    name: 'Discount',
    operator: [
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
    ],
    by: {
      required: true,
      options: oneToHundred,
    },
    threshold: {
      isLessThan: {
        required: true,
        type: 'option',
        options: threshHoldOpts,
      },
      isGreaterThan: {
        required: true,
        type: 'option',
        options: threshHoldOpts,
      },
    },
    value: {
      flat: {
        required: true,
        type: 'number',
      },
      rollingAverage: {
        required: true,
        type: 'option',
        options: rollingAverage,
      },
    },
  },
  // numberOfUnitsLeft: {
  //   name: 'Number of Units left',
  //   operator: [
  //     {label: 'is less than', value: 'isLessThan'},
  //     {label: 'is greater than', value: 'isGreaterThan'},
  //   ],
  //   by: {
  //     required: false,
  //   },
  //   threshold: {
  //     isLessThan: {
  //       required: true,
  //       type: 'option',
  //       options: [{label: 'Flat', value: 'flat'}],
  //     },
  //     isGreaterThan: {
  //       required: true,
  //       type: 'option',
  //       options: [{label: 'Flat', value: 'flat'}],
  //     },
  //   },
  //   value: {
  //     flat: {
  //       required: true,
  //       type: 'number',
  //     },
  //   },
  // },
  // outOfStock: {
  //   name: 'Out of stock',
  //   operator: [
  //     {label: 'Any', value: 'any'},
  //     {label: 'Selected', value: 'isOneOf'},
  //   ],
  //   by: {
  //     required: false,
  //   },
  //   value: {
  //     isOneOf: {
  //       required: true,
  //       type: 'filterDropdownMultiSelect',
  //       itemType: 'variants',
  //       label: 'Select products',
  //     },
  //     any: {
  //       required: false,
  //       // required: true,
  //     },
  //   },
  // },
  return: {
    name: 'Returns',
    operator: [
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
    ],
    by: {
      required: true,
      options: oneToHundred,
    },
    threshold: {
      isLessThan: {
        required: true,
        type: 'option',
        options: threshHoldOpts,
      },
      isGreaterThan: {
        required: true,
        type: 'option',
        options: threshHoldOpts,
      },
    },
    value: {
      flat: {
        required: true,
        type: 'number',
      },
      rollingAverage: {
        required: true,
        type: 'option',
        options: rollingAverage,
      },
    },
  },
  sales: {
    name: 'Sales',
    operator: [
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
    ],
    by: {
      required: true,
      options: oneToHundred,
    },
    threshold: {
      isLessThan: {
        required: true,
        type: 'option',
        options: threshHoldOpts,
      },
      isGreaterThan: {
        required: true,
        type: 'option',
        options: threshHoldOpts,
      },
    },
    value: {
      flat: {
        required: true,
        type: 'number',
      },
      rollingAverage: {
        required: true,
        type: 'option',
        options: rollingAverage,
      },
    },
  },
  salesByChannel: {
    name: 'Sales by channel',
    operator: [
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
    ],
    by: {
      required: true,
      options: oneToHundred,
    },
    threshold: {
      isLessThan: {
        required: true,
        type: 'option',
        options: threshHoldOpts,
      },
      isGreaterThan: {
        required: true,
        type: 'option',
        options: threshHoldOpts,
      },
    },
    value: {
      flat: {
        required: true,
        type: 'number',
      },
      rollingAverage: {
        required: true,
        type: 'option',
        options: rollingAverage,
      },
    },
  },
  traffic: {
    name: 'Traffic',
    operator: [
      {label: 'is less than', value: 'isLessThan'},
      {label: 'is greater than', value: 'isGreaterThan'},
    ],
    by: {
      required: true,
      options: oneToHundred,
    },
    threshold: {
      isLessThan: {
        required: true,
        type: 'option',
        options: threshHoldOpts,
      },
      isGreaterThan: {
        required: true,
        type: 'option',
        options: threshHoldOpts,
      },
    },
    value: {
      flat: {
        required: true,
        type: 'number',
      },
      rollingAverage: {
        required: true,
        type: 'option',
        options: rollingAverage,
      },
    },
  },
};

const groups = [];
let i = 0;
for (let key in options) {
  groups.push({label: options[key].name, id: key, value: i});
  i++;
}

export {options, groups};
