import React, {useEffect, useContext, useState} from 'react';

import {
  Text,
  Page,
  TextContainer,
  Link,
  FormLayout,
  Frame,
  Grid,
  Loading,
  Spinner,
} from '@shopify/polaris';
import Box from '../../../lib/Box';
import Inline from '../../../lib/Inline';
import VerifyInput from './verifyInput';
import {useNavigate} from 'react-router-dom';
import {AuthContext} from '../../../context/auth';
import {AuthAPI} from '../../../api/auth';
import {useTimer} from 'use-timer';
import Alert from '../../../components/alert';
import {useAuthStore, useLoadingStore} from '../../../app/store';
import * as Sentry from '@sentry/react';
import {track} from '../../../features/track';
import Button from '../../../lib/Button';

const VerifyEmail = () => {
  const loading = useLoadingStore(state => state.loading);
  const email = useAuthStore(state => state.email);
  const orgId = useAuthStore(state => state.orgId);
  const isVerifying = useAuthStore(state => state.isVerifying);
  const {signin} = useContext(AuthContext);
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [inputError, setInputError] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const setLoading = useLoadingStore(state => state.setLoading);

  const {time, start, status} = useTimer({
    initialTime: 60,
    timerType: 'DECREMENTAL',
    endTime: 0,
  });

  useEffect(() => {
    track('Page View', {
      name: 'Verify OTP',
    });
  }, []);

  useEffect(() => {
    if (!isVerifying) {
      navigate('/');
    }
    start();
  }, [navigate, isVerifying]);
  const resendCode = async () => {
    const resendCodeEmail = {
      email,
    };
    try {
      const response = await AuthAPI.resendCode(resendCodeEmail);
      if (response.status) {
        setSuccess('The OTP has been resent to your email. Do check spam!');
      } else {
        setError(response.message);
      }
    } catch (err) {
      setError('Something went wrong');
      Sentry.captureException(err);
    }
  };
  const handleSubmit = async () => {
    setInputError(false);

    const verifyInput = {
      otp: Number(otp),
      email,
      orgId,
    };
    setLoading(true);
    const response = await AuthAPI.verify(verifyInput);
    if (response.status) {
      setLoading(false);
      signin(response.data);
      setSuccess('Done');
    } else {
      setLoading(false);
      setError(response.message);
      setInputError(true);
    }
  };
  useEffect(() => {
    if (otp.length === 6) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [otp]);

  return (
    <Frame>
      <div className="bg1">
        {loading && <Loading />}
        <Grid>
          <Grid.Cell columnSpan={{xs: 6, sm: 6, md: 6, lg: 6, xl: 6}}>
            <Box minHeight="100vh" background="surface">
              <Inline align="space-between" blockAlign="center">
                <Box padding="8">
                  <img alt="#" width={'180px'} src="/images/logo.svg" />
                </Box>
              </Inline>

              <>
                <Box paddingBlockStart={'12'}>
                  <Text variant="heading3xl" as="h2" alignment="center">
                    Verify your email
                  </Text>
                  <Inline align="center">
                    <Box
                      className="form-container"
                      width="50%"
                      paddingBlockStart={'8'}
                      paddingBlockEnd={'4'}
                    >
                      <FormLayout>
                        <TextContainer spacing="loose">
                          <Text variant="bodyLg" as="p" alignment="center">
                            We've sent a 6-digit code to {email}{' '}
                            <Link
                              url={'/auth/signup'}
                              onClick={event => {
                                event.preventDefault();
                                navigate('/auth/signup');
                              }}
                            >
                              Change
                            </Link>
                          </Text>
                        </TextContainer>
                        <VerifyInput otp={otp} setOtp={setOtp} error={inputError} />
                        <Page narrowWidth>
                          <Button
                            disabled={buttonDisabled}
                            primary
                            fullWidth
                            onClick={handleSubmit}
                          >
                            {loading ? <Spinner size="small" /> : 'Verify'}
                          </Button>
                        </Page>
                        <TextContainer spacing="tight">
                          <Text variant="bodysm" as="p" alignment="center">
                            Didn't receive a code? Check your spam folder.
                          </Text>
                          <Text variant="bodysm" as="p" alignment="center">
                            {status === 'RUNNING' ? (
                              `Resend code in ${time} seconds`
                            ) : (
                              <Link onClick={resendCode}>Resend Code</Link>
                            )}
                          </Text>
                        </TextContainer>
                      </FormLayout>
                    </Box>
                  </Inline>
                </Box>
                {error && <Alert contents={error} init={true} error={true} />}

                {success && <Alert contents={success} init={true} error={false} />}
              </>
            </Box>
          </Grid.Cell>
          <Grid.Cell columnSpan={{xs: 5, sm: 2, md: 2, lg: 5, xl: 6}}>
            <Box minHeight="90vh"></Box>
          </Grid.Cell>
        </Grid>
      </div>
    </Frame>
  );
};
export default VerifyEmail;
