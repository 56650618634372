import axios from './axios';
import config from '../config';

const {BASE_URL} = config;

export async function getProductSkus(search) {
  try {
    const response = await axios.get(`${BASE_URL}/reports/product-filter?search=${search}`);
    return response.data;
  } catch (err) {
    return err;
  }
}

export async function getDiscountCodes(search) {
  try {
    const response = await axios.get(`${BASE_URL}/reports/discount-filter?search=${search}`);
    return response.data;
  } catch (err) {
    return err;
  }
}

export async function getOrderTags(search) {
  try {
    const response = await axios.get(`${BASE_URL}/reports/orderTag-filter?search=${search}`);
    return response.data;
  } catch (err) {
    return err;
  }
}

export async function getCustomerTags(search) {
  try {
    const response = await axios.get(`${BASE_URL}/reports/customerTag-filter?search=${search}`);
    return response.data;
  } catch (err) {
    return err;
  }
}

export async function getProductGroups() {
  try {
    const response = await axios.get(`${BASE_URL}/reports/productGroups`);
    return response.data;
  } catch (err) {
    return err;
  }
}
