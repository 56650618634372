const productData = [
  {
    orderName: '#TEST123',
    imageSrc:
      'https://cdn.shopify.com/s/files/1/0765/1318/2018/products/Main_d624f226-0a89-4fe1-b333-0d1548b43c06.jpg?v=1684388678',
    title: 'Test product',
    orderedAt: '2024-02-01',
  },
];
const customerName = 'Test-user';

const replaceVariables = string => {
  string = string.replaceAll(
    '{{productImages}}',
    productData
      .map(product => {
        return `<img src="${product.imageSrc}"alt="Product image" class="product-img">`;
      })
      .join('')
  );
  string = string.replaceAll(
    '{{productTitles}}',
    productData
      .map(product => {
        return product.title;
      })
      .join(' , ')
  );
  string = string.replaceAll('{{customerName}}', customerName);
  string = string.replaceAll('{{productTitle}}', productData[0].title);
  string = string.replaceAll('{{orderName}}', productData[0].orderName);
  string = string.replaceAll('{{orderDate}}', productData[0].orderedAt);
  string = string.replaceAll('{{brandName}}', 'Retainwise');
  return string;
};
export {replaceVariables};
