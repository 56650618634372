import {useState, useEffect} from 'react';
import {usePreventNavigateStore, useSaveChangesStore} from '../app/store';

const useUnsavedChangesWarning = () => {
  const [isDirty, setDirty] = useState(false);
  const setProtect = usePreventNavigateStore(state => state.setProtect);
  const setIsUnchanged = useSaveChangesStore(state => state.setIsUnchanged);
  useEffect(() => {
    const handlePopstate = e => {
      setDirty(false);
      setProtect(false);
      setIsUnchanged(false);
    };
    //Detecting browser closing
    window.onbeforeunload =
      isDirty &&
      (e => {
        e.preventDefault();
        return true;
      });

    window.addEventListener('popstate', handlePopstate);
    return () => {
      window.onbeforeunload = null;

      window.addEventListener('popstate', handlePopstate);
    };
  }, [isDirty]);

  useEffect(() => {}, [isDirty]);
  return [isDirty, () => setDirty(true), () => setDirty(false)];
};

export default useUnsavedChangesWarning;
