import axios from 'axios';
import {LocalStorageService} from '../../service/localStorage';
import {useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  function (config) {
    const userDetails = LocalStorageService.getAccountDetails();
    config.headers['access-token'] = userDetails.access_token;

    config.validateStatus = function (status) {
      if (status === 401) {
        LocalStorageService.removeAccountDetails();
        window.location.href = '/';
        return false;
      }
      return true;
    };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const AxiosInterceptor = ({children}) => {
  const navigate = useNavigate();
  const [isSet, setIsSet] = useState(false);

  useEffect(() => {
    const resInterceptor = response => {
      if (response.status === 403) {
        navigate('/pricing');
        return;
      }
      return response;
    };

    const errInterceptor = error => {
      return Promise.reject(error);
    };

    const interceptor = axiosInstance.interceptors.response.use(resInterceptor, errInterceptor);

    setIsSet(true);

    return () => axiosInstance.interceptors.response.eject(interceptor);
  }, []);
  return isSet && children;
};

export default axiosInstance;
export {AxiosInterceptor};
