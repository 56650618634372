import React, {useEffect, useState} from 'react';
import {Box, Inline, Loading, Text, TextContainer} from '@shopify/polaris';
import ConnectionCard from './card';
import {CreateConnectionModal, ChooseFacebookAdAccountsModal} from '../../../../components/modal';
import {
  connectionAuthInitiate,
  createConnection,
  deleteConnection,
  getConnectionTemplate,
  updateConnection,
} from '../../../../api/connection';
import {useNavigate, useSearchParams} from 'react-router-dom';
import Alert from '../../../../components/alert';
import {LocalStorageService} from '../../../../service/localStorage';

const manualConnections = ['twilio'];
const oAuthEnabledConnectionTypes = ['mailchimp', 'facebookAds', 'klaviyo'];

const Connections = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openUpdateFacebookAdAccountsModal, setOpenUpdateFacebookAdAccountsModal] = useState(false);
  const [connections, setConnections] = useState([]);
  const [searchParams] = useSearchParams();
  const [showAlert, setShowAlert] = useState('');
  const [alertType, setAlertType] = useState('');
  const [updatedStatus, setUpdatedStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState('');
  const UpdateConnectionModal = CreateConnectionModal;
  const [connectionData, setConnectionData] = useState({title: '', apiKey: ''});

  // const [newFacebookConnection, setConnectionData] = useState(null);
  useEffect(() => {
    if (searchParams.get('type') && searchParams.get('accessToken')) {
      const queryParamsObject = Object.fromEntries(searchParams);
      onConnect({
        ...queryParamsObject,
        title: '',
      });
    }
  }, []);

  useEffect(() => {
    let params = {params: {type: 'connected'}};
    getConnectionTemplate(params).then(res => {
      setConnections(res.data);
      setLoading(false);
    });
  }, [updatedStatus]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => setShowAlert(''), 6000);
    }
  }, [showAlert]);

  const onConnect = async params => {
    let result = await createConnection(params);
    if (result.status === true) {
      setShowAlert('Connection added successfully!');
      setAlertType('success');
      setConnections([]);
      setUpdatedStatus(!updatedStatus);
      if (params.type === 'facebookAds') {
        setConnectionData(result.data);
        setOpenUpdateFacebookAdAccountsModal(true);
      }
    } else {
      setShowAlert(`${result?.message ? result.message + '. ' : ''} Please try again!`);
      setAlertType('error');
    }
    return result;
  };
  const onUpdate = async params => {
    let id = params._id || connectionData.id;
    let result = await updateConnection(id, params);
    if (result.status === true) {
      setShowAlert('Connection updated successfully!');
      setAlertType('success');
      if (LocalStorageService.isOnboarding) {
        navigate('/fetch-status');
      }
      setConnections([]);
      setUpdatedStatus(!updatedStatus);
    } else {
      setShowAlert(`${result?.message ? result.message + '. ' : ''} Please try again!`);
      setAlertType('error');
    }
    return result;
  };

  const handleAddConnection = type => {
    if (manualConnections.includes(type)) {
      setOpenModal(true);
    } else if (oAuthEnabledConnectionTypes.includes(type)) {
      connectionAuthInitiate(type).then(res => {
        window.location.href = res;
      });
    }
  };
  const onEditConnection = id => {
    let connectionDetail = connections.filter(x => x._id === id)[0];
    if (connectionDetail.type === 'facebookAds') {
      setOpenUpdateFacebookAdAccountsModal(true);
      setConnectionData(connectionDetail);
    } else {
      setOpenUpdateModal(true);
      setConnectionData({
        title: connectionDetail.title,
        apiKey: connectionDetail.data.apiKey,
        id: connectionDetail._id,
      });
    }
  };
  const onDeleteConnection = id => {
    setLoading(true);
    deleteConnection(id).then(() => {
      setConnections([]);
      setUpdatedStatus(!updatedStatus);
    });
  };

  return (
    <>
      {loading && <Loading />}
      <Box>
        <Inline align="space-between">
          <Box paddingBlockEnd={'8'}>
            <TextContainer spacing="tight">
              <Text variant="headingMd">Integrate and automate your workflows</Text>
              <Text color="subdued">
                Have your insights where your team needs them by exporting it to the platform of
                your choice.
              </Text>
            </TextContainer>
          </Box>
        </Inline>

        <Box paddingBlockStart={'6'}>
          <Inline blockAlign="start" gap={'8'}>
            {connections.map((template, i) => {
              return (
                <Box key={i}>
                  <ConnectionCard
                    data={template}
                    onEdit={onEditConnection}
                    onDelete={onDeleteConnection}
                    handleAddConnection={handleAddConnection}
                    setType={setType}
                  />
                </Box>
              );
            })}
          </Inline>
        </Box>
      </Box>
      {openModal && (
        <CreateConnectionModal
          currentState={openModal}
          onClose={setOpenModal}
          onConnect={onConnect}
          type={type}
        />
      )}
      {openUpdateModal && (
        <UpdateConnectionModal
          currentState={openUpdateModal}
          onClose={setOpenUpdateModal}
          onConnect={onUpdate}
          data={connectionData}
          type={type}
        />
      )}

      {openUpdateFacebookAdAccountsModal && (
        <ChooseFacebookAdAccountsModal
          currentState={openUpdateFacebookAdAccountsModal}
          onClose={setOpenUpdateFacebookAdAccountsModal}
          connection={connectionData}
          onUpdate={onUpdate}
        />
      )}
      {showAlert && (
        <Alert contents={showAlert} init={true} error={alertType === 'error' ? true : false} />
      )}
    </>
  );
};

export default Connections;
