import {Modal} from '@shopify/polaris';
import React from 'react';

const ConfirmationModal = ({isOpen, toggle, onProceed, text, danger}) => {
  const activator = <div></div>;

  return (
    <Modal
      activator={activator}
      open={isOpen}
      onClose={toggle}
      title="Confirm ?"
      primaryAction={{
        content: 'Proceed',
        destructive: danger,
        onAction: () => {
          onProceed();
          toggle();
        },
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: toggle,
        },
      ]}
    >
      <Modal.Section>
        <p>{text}</p>
      </Modal.Section>
    </Modal>
  );
};

export default ConfirmationModal;
