import axios from '../axios';
import config from '../../config';

const {BASE_URL} = config;

export async function createSegment(options) {
  const response = await axios.post(`${BASE_URL}/reports/create-segment`, options);
  return response;
}
export async function getSegmentReport(params) {
  const response = await axios.get(`${BASE_URL}/reports/get-segment-report`, params);
  return response;
}
export async function getSegmentList(params) {
  const response = await axios.get(`${BASE_URL}/reports/get-segment-list`, params);
  return response;
}
export async function updateSegment({_id, state}) {
  const response = await axios.put(`${BASE_URL}/reports/update-segment/${_id}`, state);
  return response;
}
export async function deleteSegment(options) {
  const response = await axios.delete(
    `${BASE_URL}/reports/delete-segment/${options.toString()}`,
    options
  );
  return response;
}
