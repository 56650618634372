import React, {useState, useCallback, useMemo} from 'react';
import {Popover, OptionList} from '@shopify/polaris';
import Button from '../lib/Button';

const MultiSelect = ({
  title,
  options,
  selected,
  setSelected,
  disableMultiple,
  setChecked,
  setPage = () => {},
}) => {
  const togglePopoverActive = useCallback(
    () => setPopoverActive(popoverActive => !popoverActive),
    []
  );
  const [allSelected, setAllSelected] = useState(false);
  const valueToLabelMap = useMemo(() => {
    let _map = {};
    for (let option of options) {
      _map[option.value] = option.label;
    }
    return _map;
  }, [options]);

  const label = useMemo(() => {
    if (Boolean(selected) && disableMultiple) {
      return valueToLabelMap[selected];
    }
    return title;
  }, [selected, valueToLabelMap]);
  const orgActivator = (
    <>
      <Button onClick={togglePopoverActive} disclosure>
        {label}
      </Button>
    </>
  );
  const [popoverActive, setPopoverActive] = useState(false);
  return (
    <>
      <Popover active={popoverActive} activator={orgActivator} onClose={togglePopoverActive}>
        <OptionList
          onChange={e => {
            if (e.includes('all') && !allSelected) {
              let s = options.map(d => d.value);
              setSelected([...s]);
              setAllSelected(true);
            } else if (e.includes('all') && allSelected && e.length !== options.length) {
              let i = e.indexOf('all');
              e.splice(i, 1);
              setSelected([...e]);
              setAllSelected(false);
            } else if (!e.includes('all') && allSelected) {
              setSelected([...[]]);
              setAllSelected(false);
            } else {
              if (disableMultiple) {
                togglePopoverActive();
                setSelected(e[0]);
                setChecked(false);
              } else {
                setSelected(e);
              }
            }
            setPage(1);
          }}
          options={options}
          selected={disableMultiple ? [selected] : selected}
          allowMultiple={!disableMultiple}
        />
      </Popover>
    </>
  );
};

export default MultiSelect;
