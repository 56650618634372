const typeOptions = [
  {label: 'All', value: 'all'},
  {label: 'chPopulation', value: 'chPopulation'},
  {label: 'orders', value: 'orders'},
  {label: 'updateJobStatus', value: 'updateJobStatus'},
  {label: 'updateCompletionRate', value: 'updateCompletionRate'},
  {label: 'fetchEstTime', value: 'fetchEstTime'},
  {label: 'dailyFetch', value: 'dailyFetch'},
  {label: 'RFM', value: 'rfm'},
  {label: 'updateCustomKeys', value: 'updateCustomKeys'},
];
const sourceOptions = [
  {label: 'All', value: 'all'},
  {label: 'Cron', value: 'cron'},
  {label: 'Api', value: 'api'},
];
const statusOptions = [
  {label: 'All', value: ''},
  {label: 'In-progress', value: 'in-progress'},
  {label: 'Failed', value: 'failed'},
  {label: 'Paused', value: 'paused'},
  {label: 'Completed', value: 'completed'},
];

export {typeOptions, sourceOptions, statusOptions};
