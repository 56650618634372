import {Button, Text, Box, Popover, ActionList, Card, Spinner, Icon} from '@shopify/polaris';
import React, {useCallback, useState} from 'react';
import {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import DefaultTable from '../../../components/defaultIndexTable';
import {DeleteModal} from '../../../components/modal';
import MyPage from '../../../components/page';
import {productGroupColumns} from '../../metrics/columns';
import Alert from '../../../components/alert';
import config from '../../../config';
import {ErrorBoundary} from '../../../components/ErrorBoundary';
import {track} from '../../../features/track';
import useApiCall from '../../../hooks/useApiCall';
import {CircleInformationMajor} from '@shopify/polaris-icons';
import DefinationPopover from '../../../components/definationPopover';
import {generateInfoText} from '../../../features/generateText';
import DownloadModal from '../../../components/Modals/downloadModal';
import {
  deleteProductGroup,
  getProductGroupList,
  getProductGroupReport,
} from '../../../api/productGroups';

const ProductGroupPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [popoverActive, setPopoverActive] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [title, setTitle] = useState('');
  const [owner, setOwner] = useState('');
  const [productGroupType, setProductGroupType] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortBy, setSortBy] = useState('title');
  const [queryValue, setQueryValue] = useState('');
  const [page, setPage] = useState(1);
  const [showAlert, setShowAlert] = useState('');
  const [download, setDownload] = useState({state: 'Success'});
  const [infoPopoverActive, setInfoPopoverActive] = useState(false);
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  useEffect(() => {
    track('Page View', {
      name: 'Product Group',
    });
    getProductGroupList({params: {_id: params.id}}).then(res => {
      setTitle(res.data.data.productGroupDetail.name);
      setOwner(res.data.data.user);
      setProductGroupType(res.data.data.productGroupType);
    });
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setShowAlert('');
    }, config.ALERT_TIMEOUT);
  }, [showAlert]);

  const fetchData = async () => {
    let _params = {
      _id: params.id,
      page,
      sortBy,
      sortOrder,
      search: queryValue,
      activity: '',
    };
    let response = await getProductGroupReport({params: _params});
    return response.data;
  };

  const dataApiDependencies = [page, sortBy, sortOrder, queryValue, params.id];

  const tableData = useApiCall(fetchData, dataApiDependencies, config.SEARCH_DELAY);

  const togglePopoverActive = useCallback(
    () => setPopoverActive(popoverActive => !popoverActive),
    []
  );
  const activator = (
    <Button
      onClick={() => {
        setPopoverActive(!popoverActive);
      }}
      outline
    >
      <Box paddingBlockEnd={'1'}>
        <Text variant="headingMd">...</Text>
      </Box>
    </Button>
  );
  const handleDownload = async email => {
    setDownload({state: 'Loading'});
    try {
      let response = await getProductGroupReport({
        params: {_id: params.id, download: true, sortBy, sortOrder, email},
      });
      if (response.status === 500) {
        setShowAlert('Something Went Wrong');
        setDownload({state: 'Error'});
      } else {
        setShowAlert(`Product group will be mailed to you shortly on ${email}`);
        setDownload({state: 'Success'});
      }
    } catch (e) {
      setShowAlert('Something Went Wrong');
      setDownload({state: 'Error'});
    }
  };
  return (
    <>
      <MyPage
        breadcrumbs={[
          {
            content: 'product-groups',
            onAction: () => {
              navigate(-1);
            },
          },
        ]}
        title={title || 'Product Group'}
        subtitle={
          tableData.state === 'Success' ? (
            (tableData?.data[0]?.totalRows || 0) + ' variants'
          ) : tableData.state === 'Loading' ? (
            <Spinner size="small" />
          ) : (
            'Not able to load data'
          )
        }
        primaryAction={
          owner !== 'retainwise' &&
          productGroupType !== 'Pre-built' && (
            <Popover
              active={popoverActive}
              activator={activator}
              autofocusTarget="first-node"
              onClose={togglePopoverActive}
              preferredAlignment="left"
            >
              <div style={{minWidth: '7rem'}}>
                <ActionList
                  actionRole="menuitem"
                  items={[
                    {
                      content: 'Edit',
                      onAction: () => navigate(`/product-group-edit/${params.id}`),
                    },
                    {
                      content: 'Delete',
                      destructive: true,
                      onAction: () => {
                        setOpenDeleteModal(true);
                      },
                    },
                  ]}
                />
              </div>
            </Popover>
          )
        }
        secondaryActions={[
          // {
          //   content: 'Create signal',
          //   onAction: () => {
          //     navigate(`/signal-new/${params.id}`);
          //   },
          // },
          {
            content: download.state === 'Loading' ? <Spinner size="small" /> : 'Download',
            destructive: false,
            onAction: () => {
              setOpenDownloadModal(true);
            },
          },

          {
            content:
              productGroupType === 'Pre-built' && generateInfoText(title) ? (
                <DefinationPopover
                  text={generateInfoText(title)}
                  togglePopoverActive={() => {
                    setInfoPopoverActive(false);
                  }}
                  popoverActive={infoPopoverActive}
                  activator={
                    <div
                      onMouseEnter={() => {
                        setInfoPopoverActive(true);
                      }}
                      onMouseLeave={() => {
                        setInfoPopoverActive(false);
                      }}
                    >
                      <Icon source={CircleInformationMajor} />
                    </div>
                  }
                />
              ) : (
                ''
              ),
          },
        ]}
      >
        <Box>
          <ErrorBoundary
            fallback={
              <Card>
                <Card.Section>
                  <div>An error has occured</div>
                </Card.Section>
              </Card>
            }
          >
            <DefaultTable
              resourceName={{
                singular: 'variant',
                plural: 'variants',
              }}
              filters={[]}
              selectedFilters={[]}
              select={false}
              tableData={tableData.data}
              columns={productGroupColumns}
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              setSelectedDateRangeDates={() => {}}
              queryValue={queryValue}
              setQueryValue={setQueryValue}
              totalRows={tableData.data.length ? tableData.data[0].totalRows : 0}
              setPage={setPage}
              page={page}
              state={tableData.state}
              param={{}}
            />
          </ErrorBoundary>
        </Box>
        {openDeleteModal && (
          <DeleteModal
            currentState={openDeleteModal}
            deleteContent="Product group"
            onDelete={() => {
              deleteProductGroup(params.id);
              setOpenDeleteModal(false);
              navigate('/product-groups');
            }}
            onClose={() => {
              setOpenDeleteModal(false);
            }}
          />
        )}
        {showAlert && (
          <Alert
            contents={showAlert}
            init={true}
            error={showAlert === 'Something Went Wrong'}
            duration={10000}
          />
        )}
      </MyPage>
      <DownloadModal
        isOpen={openDownloadModal}
        toggle={() => setOpenDownloadModal(!openDownloadModal)}
        handleDownload={handleDownload}
      />
    </>
  );
};

export default ProductGroupPage;
