import React, {useEffect, useReducer, useState} from 'react';
import {useParams} from 'react-router-dom';
import {
  Text,
  Form,
  FormLayout,
  TextField,
  Frame,
  Grid,
  Link,
  Loading,
  Spinner,
} from '@shopify/polaris';
import {AuthAPI} from '../../../api/auth';
import {useNavigate} from 'react-router-dom';
import Alert from '../../../components/alert';
import {emailValidator, passwordValidator} from '../../../features/validations';
import {useAuthStore, useLoadingStore} from '../../../app/store';
import './index.css';
import * as Sentry from '@sentry/react';
import {clearAll} from '../../../features/clearData';
import {track} from '../../../features/track';
import Button from '../../../lib/Button';
import Box from '../../../lib/Box';
import Inline from '../../../lib/Inline';

const initialState = {
  org: '',
  email: '',
  password: '',
  referralCode: '',
  orgId: '',
};
const reducer = (state, action) => {
  switch (action.type) {
    case 'email':
      return {...state, email: action.value};
    case 'password':
      return {...state, password: action.value};
    case 'org':
      return {...state, org: action.value};
    case 'orgId':
      return {...state, orgId: action.value};
    default:
      throw new Error();
  }
};
const SignUp = () => {
  const params = useParams();
  const loading = useLoadingStore(state => state.loading);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [error, setError] = useState({});
  const [alertError, setAlertError] = useState('');
  const setAuthStoreEmail = useAuthStore(state => state.setEmail);
  const setAuthStoreOrgId = useAuthStore(state => state.setOrgId);
  const setAuthStoreIsVerifying = useAuthStore(state => state.setIsVerifying);
  const navigate = useNavigate();
  const [disabledButton, setDisabledButton] = useState(true);

  const setLoading = useLoadingStore(state => state.setLoading);

  useEffect(() => {
    clearAll();
    dispatch({type: 'orgId', value: params.orgId});
    track('Page View', {
      name: 'Signup',
    });
  }, []);

  const handleSubmit = async () => {
    if (!emailValidator(state.email)) {
      error.notValidEmail = true;
      setError({...error});
    } else if (!passwordValidator(state.password)) {
      error.notValidPass = true;
      setError({...error});
    } else {
      setLoading(true);
      try {
        const response = await AuthAPI.signup(state);
        if (response.status) {
          setAuthStoreEmail(state.email);
          setAuthStoreOrgId(state.orgId);
          setAuthStoreIsVerifying(true);
          setAlertError('');
          setLoading(false);
          navigate('/auth/verify');
        } else {
          setLoading(false);
          setAlertError(response.message);
        }
      } catch (err) {
        setLoading(false);
        setAlertError('Something went wrong');
        Sentry.captureException(err);
      }
    }
  };

  useEffect(() => {
    if (state.email && state.password && state.org) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [state]);

  return (
    <Frame>
      <div className="bg1">
        {loading && <Loading />}
        <Grid>
          <Grid.Cell columnSpan={{xs: 6, sm: 6, md: 6, lg: 6, xl: 6}}>
            <Box minHeight="100vh" background="surface">
              <Inline align="space-between" blockAlign="center">
                <Box padding="8">
                  <img alt="#" width={'180px'} src="/images/logo.svg" />
                </Box>
                <Box padding="8" paddingBlockStart={'10'}>
                  <Inline>
                    <Text alignment="end" fontWeight="semibold">
                      <Link
                        url={'/auth/signin'}
                        onClick={event => {
                          event.preventDefault();
                          navigate('/auth/signin');
                        }}
                      >
                        Already have an retainwise account? Sign in
                      </Link>
                    </Text>
                  </Inline>
                </Box>
              </Inline>

              <>
                <Box paddingBlockStart={'12'}>
                  <Text variant="heading3xl" as="h2" alignment="center">
                    Get started
                  </Text>
                  <Inline align="center">
                    <Box
                      className="form-container"
                      width="50%"
                      paddingBlockStart={'8'}
                      paddingBlockEnd={'4'}
                    >
                      <Form onSubmit={handleSubmit}>
                        <FormLayout>
                          <TextField
                            type="text"
                            label="Company name"
                            placeholder="Company name"
                            error={
                              error.org
                                ? 'Company name should not be empty'
                                : error.orgLength
                                ? 'Max length of 150 characters reached'
                                : ''
                            }
                            value={state.org}
                            onChange={e => {
                              if (e.length < 150) {
                                dispatch({type: 'org', value: e});
                                error.orgLength = false;
                                setError(error);
                              } else if (e.length === 150) {
                                dispatch({type: 'org', value: e});
                                error.orgLength = true;
                                setError(error);
                              }
                            }}
                          />
                          <Box>
                            <TextField
                              type="text"
                              label="Email"
                              placeholder="Email"
                              error={
                                error.email
                                  ? 'Email should not be empty'
                                  : error.notValidEmail
                                  ? 'Enter a valid email'
                                  : error.emailLength
                                  ? 'Max length of 150 characters reached'
                                  : ''
                              }
                              value={state.email}
                              onChange={e => {
                                if (e.length < 150) {
                                  dispatch({type: 'email', value: e});
                                  error.emailLength = false;
                                  setError(error);
                                } else if (e.length === 150) {
                                  dispatch({type: 'email', value: e});
                                  error.emailLength = true;
                                  setError(error);
                                }
                              }}
                              // autoComplete="email"
                            />
                          </Box>

                          <TextField
                            placeholder="Password"
                            helpText={
                              error.notValidPass
                                ? ''
                                : 'Min 8 characters with letters, numbers and special characters'
                            }
                            type="password"
                            value={state.password}
                            error={
                              error.password
                                ? 'Password  should not be empty'
                                : error.notValidPass
                                ? 'Min 8 characters with letters, numbers and special characters'
                                : error.passLength
                                ? 'Max length of 150 characters reached'
                                : ''
                            }
                            label="Password"
                            onChange={e => {
                              if (e.length < 150) {
                                dispatch({type: 'password', value: e});
                                error.passLength = false;
                                setError(error);
                              } else if (e.length === 150) {
                                dispatch({type: 'password', value: e});
                                error.passLength = true;
                                setError(error);
                              }
                            }}
                          />

                          <Box paddingBlockStart={'4'}>
                            <Button disabled={disabledButton} fullWidth submit primary>
                              {loading ? <Spinner size="small" /> : 'Sign up'}
                            </Button>
                          </Box>
                        </FormLayout>
                      </Form>
                      {alertError && <Alert contents={alertError} init={true} error={true} />}
                    </Box>
                  </Inline>
                </Box>
              </>

              <Inline align="space-around">
                <Box width="70%">
                  <Text alignment="center">
                    By creating an account you agree to the retainwise’s{' '}
                  </Text>
                  <Text alignment="center">
                    <Link external url="https://www.retainwise.io/privacy-policy" removeUnderline>
                      Privacy Policy
                    </Link>{' '}
                    &{' '}
                    <Link external url="https://www.retainwise.io/terms-of-service" removeUnderline>
                      Terms of Service{' '}
                    </Link>
                  </Text>
                </Box>
              </Inline>
            </Box>
          </Grid.Cell>
          <Grid.Cell columnSpan={{xs: 5, sm: 2, md: 2, lg: 5, xl: 6}}>
            <Box minHeight="90vh"></Box>
          </Grid.Cell>
        </Grid>
      </div>
    </Frame>
  );
};

export default SignUp;
