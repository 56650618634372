import {SkeletonBodyText} from '@shopify/polaris';
import Stack from '../../../../lib/Stack';
import Text from '../../../../lib/Text';
import Button from '../../../../lib/Button';
import Card from '../../../../lib/Card';
import Box from '../../../../lib/Box';
import Inline from '../../../../lib/Inline';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {
  economicsOfProduct,
  frequentlyPurchasedTogetherOfProduct,
  productSalesByChannel,
  productSalesByCity,
  productSalesByCountry,
  productSalesByDay,
  productSalesByHour,
  productSalesByPaymentMethod,
  productNpsGraph,
  conversionMetricsOfProduct,
} from '../../../../api/productProfile';
import DefaultBarGraph from '../../../../components/graphs/defaultBarGraph';
import FrequentlyBaughtTogether from '../components/frequentlyBaughtTogether';
import ProductIndicator from '../components/productIndicator';
import {formatAmount, formatDecimalNumber, formatNumber} from '../../../../features/format';
import {formatDate} from '../../../../features/format';
import {ErrorBoundary} from '../../../../components/ErrorBoundary';
import useApiCall from '../../../../hooks/useApiCall';
import {generateVariantTitle} from '../../../../features/generateText';
import {
  dateToNormalString,
  daysToYearsMonthsDays,
  ninetyDaysBefore,
  oneYearBefore,
  sevenDaysBefore,
  thirtyDaysBefore,
  toClickHouseDate,
  todayDate,
  yesterDayDate,
} from '../../../../features/convertDates';
import {verifyInventory} from '../../../../features/helper';
import DefaultLineGraph from '../../../../components/graphs/defaultLineGraph';
import {LocalStorageService} from '../../../../service/localStorage';
import DateRangeComponent from '../../../../components/dateRangeComponent';

const Overview = () => {
  const [npsEnabled, setNpsEnabled] = useState(false);

  const dateRangeValueToLable = {
    today: 'Today',
    yesterday: 'Yesterday',
    lastSevenDays: 'Last 7 Days',
    lastThirtyDays: 'Last 30 Days',
    lastNinetyDays: 'Last 90 Days',
    lastOneYear: 'Last 1 Year',
  };
  const dateRangeObj = {
    yesterday: yesterDayDate,
    today: todayDate,
    lastThirtyDays: thirtyDaysBefore,
    lastSevenDays: sevenDaysBefore,
    lastNinetyDays: ninetyDaysBefore,
    lastOneYear: oneYearBefore,
  };
  const [selectedDateRange, setSelectedDateRange] = useState('lastThirtyDays');
  const [selectedDateRangeDates, setSelectedDateRangeDates] = useState(
    selectedDateRange
      ? {
          start: dateRangeObj[selectedDateRange]()[0],
          end: dateRangeObj[selectedDateRange]()[1],
        }
      : {
          start: new Date(),
          end: new Date(),
        }
  );
  const [dateRangePopoverActive, setDateRangePopoverActive] = useState(false);

  const dateRangeTogglePopoverActive = useCallback(
    () => setDateRangePopoverActive(dateRangePopoverActive => !dateRangePopoverActive),
    []
  );

  const dateRangeActivator = (
    <div style={{width: '100%'}}>
      <Button onClick={dateRangeTogglePopoverActive} disclosure>
        {selectedDateRange
          ? dateRangeValueToLable[selectedDateRange]
          : dateToNormalString(selectedDateRangeDates)}
      </Button>
    </div>
  );

  const getDateRangeDates = (dates, selectedDateRange) => {
    console.log(dates, selectedDateRange);
    setSelectedDateRangeDates(dates);
    setSelectedDateRange(selectedDateRange);
  };

  const param = useParams();
  useEffect(() => {
    const userDetails = LocalStorageService.getUserOrg();
    if (userDetails?.reportSettings?.nps?.product === true) {
      setNpsEnabled(true);
    }
  }, []);
  const fetchSalesByCity = async () => {
    let response = await productSalesByCity({variantId: param.variantId});
    return response;
  };
  const fetchSalesByCountry = async () => {
    let response = await productSalesByCountry({variantId: param.variantId});
    return response;
  };
  const fetchSalesByChannels = async () => {
    let response = await productSalesByChannel({variantId: param.variantId});
    return response;
  };
  const fetchSalesByPaymentMethod = async () => {
    let response = await productSalesByPaymentMethod({variantId: param.variantId});
    return response;
  };
  const fetchSalesByDay = async () => {
    let response = await productSalesByDay({variantId: param.variantId});
    return response;
  };
  const fetchSalesByHour = async () => {
    let response = await productSalesByHour({variantId: param.variantId});
    return response;
  };
  const fetchProductNpsGraphData = async () => {
    if (npsEnabled === true) {
      let response = await productNpsGraph({variantId: param.variantId});
      return response;
    }
  };
  const fetchEconomics = async () => {
    let response = await economicsOfProduct({variantId: param.variantId});
    return response;
  };
  const fetchPurchaseTogether = async () => {
    let response = await frequentlyPurchasedTogetherOfProduct({variantId: param.variantId});
    return response;
  };
  const fetchConversionMetrics = async () => {
    let response = await conversionMetricsOfProduct({
      variantId: param.variantId,
      startDate: toClickHouseDate(selectedDateRangeDates.start, 'start'),
      endDate: toClickHouseDate(selectedDateRangeDates.end, 'end'),
    });
    return response;
  };
  const salesByCity = useApiCall(fetchSalesByCity, []);
  const salesByCountry = useApiCall(fetchSalesByCountry, []);
  const salesByPaymentMethod = useApiCall(fetchSalesByPaymentMethod, []);
  const productNpsGraphData = useApiCall(fetchProductNpsGraphData, [npsEnabled]);
  const salesByChannels = useApiCall(fetchSalesByChannels, []);
  const salesByDay = useApiCall(fetchSalesByDay, []);
  const salesByHour = useApiCall(fetchSalesByHour, []);
  const _economics = useApiCall(fetchEconomics, []);
  const conversionMetrics = useApiCall(fetchConversionMetrics, [selectedDateRangeDates]);
  const economics = useMemo(() => {
    return {data: (_economics.data = _economics.data[0] || {}), state: _economics.state};
  }, [_economics]);
  const purchasedTogether = useApiCall(fetchPurchaseTogether, []);

  return (
    <>
      <Box>
        <Inline blockAlign="start" align="space-between">
          <Box width="34%">
            <Card>
              <Card.Section>
                <Box paddingBlockEnd={'5'}>
                  {economics.state === 'Loading' ? (
                    <>
                      <SkeletonBodyText />
                      <SkeletonBodyText />
                    </>
                  ) : economics.state === 'Success' ? (
                    <Inline align="center">
                      <Stack vertical alignment="center" spacing="extraTight">
                        {economics.data['productImage'] ? (
                          <img
                            alt={'Not found'}
                            width={'200rem'}
                            src={economics.data['productImage']}
                          />
                        ) : (
                          <></>
                        )}
                        <Text alignment="center" variant="headingMd">
                          {generateVariantTitle(economics.data)}{' '}
                        </Text>
                        <Text alignment="center" color="subdued">
                          {economics.data['sku']}
                        </Text>
                      </Stack>
                    </Inline>
                  ) : (
                    <Text>Not able to load data. please try again later</Text>
                  )}
                </Box>
                <Box paddingInlineStart={'3'} paddingBlockStart={'5'}>
                  <ErrorBoundary
                    fallback={
                      <Card>
                        <Card.Section>
                          <div>An error has occured</div>
                        </Card.Section>
                      </Card>
                    }
                  >
                    <FrequentlyBaughtTogether
                      data={purchasedTogether.data}
                      state={purchasedTogether.state}
                    />
                  </ErrorBoundary>
                </Box>
              </Card.Section>
            </Card>
            <Box paddingBlockStart={'5'}>
              <Box width="100%">
                <Card>
                  <Card.Section>
                    <Box paddingInlineStart={'4'}>
                      <Text variant="headingMd">Sales by day</Text>
                    </Box>
                    <Box paddingBlockStart={'4'}>
                      <ErrorBoundary
                        fallback={
                          <Card>
                            <Card.Section>
                              <div>An error has occured</div>
                            </Card.Section>
                          </Card>
                        }
                      >
                        <DefaultBarGraph
                          height={250}
                          data={salesByDay.data}
                          name={'Net Sales'}
                          xAxisValue="day"
                          yAxisValue={'quantity'}
                          state={salesByDay.state}
                        />
                      </ErrorBoundary>
                    </Box>
                  </Card.Section>
                </Card>
              </Box>
            </Box>
            <Box paddingBlockStart={'5'}>
              <Box width="100%">
                <Card>
                  <Card.Section>
                    <Box paddingInlineStart={'4'}>
                      <Text variant="headingMd">Sales by top countries</Text>
                    </Box>
                    <Box paddingBlockStart={'4'}>
                      <ErrorBoundary
                        fallback={
                          <Card>
                            <Card.Section>
                              <div>An error has occured</div>
                            </Card.Section>
                          </Card>
                        }
                      >
                        <DefaultBarGraph
                          height={250}
                          data={salesByCountry.data}
                          name={'Net Sales'}
                          xAxisValue="country"
                          yAxisValue={'quantity'}
                          state={salesByCountry.state}
                        />
                      </ErrorBoundary>
                    </Box>
                  </Card.Section>
                </Card>
              </Box>
            </Box>
            {!!npsEnabled && (
              <Box width="100%">
                <Card>
                  <Card.Section>
                    <Box paddingInlineStart={'4'}>
                      <Text variant="headingMd">Product NPS</Text>
                    </Box>
                    <Box paddingBlockStart={'4'}>
                      <ErrorBoundary
                        fallback={
                          <Card>
                            <Card.Section>
                              <div>An error has occured</div>
                            </Card.Section>
                          </Card>
                        }
                      >
                        <DefaultLineGraph
                          param={{name: 'product-nps'}}
                          height={250}
                          data={
                            productNpsGraphData.data.length ? [productNpsGraphData.data, []] : []
                          }
                          startDate={
                            productNpsGraphData.data.length
                              ? new Date(productNpsGraphData.data[0].key)
                              : null
                          }
                          endDate={productNpsGraphData.data.length ? new Date() : null}
                          compareStartDate={null}
                          compareEndDate={null}
                          durationFilter={'monthly'}
                          state={productNpsGraphData.state}
                          isDynamic={true}
                        />
                      </ErrorBoundary>
                    </Box>
                  </Card.Section>
                </Card>
              </Box>
            )}
          </Box>
          <Box width="64%">
            <Card>
              <Card.Section>
                <ProductIndicator
                  values={[
                    {
                      label: 'Gross items sold',
                      val: `${
                        economics.data['grossItems'] !== undefined
                          ? formatNumber({value: economics.data['grossItems']})
                          : '-'
                      }`,
                      state: economics.state,
                    },
                    {
                      label: 'Gross revenue',
                      val: `${
                        economics.data['grossRevenue'] !== undefined
                          ? formatAmount({value: economics.data['grossRevenue']})
                          : '-'
                      }`,
                      state: economics.state,
                    },

                    {
                      label: 'Customers who purchased',
                      val: `${
                        economics.data['customers'] !== undefined
                          ? formatNumber({value: economics.data['customers']})
                          : '-'
                      }`,
                      state: economics.state,
                    },
                    {
                      label: 'Gross orders',
                      val: `${
                        economics.data['grossOrders'] !== undefined
                          ? formatNumber({value: economics.data['grossOrders']})
                          : '-'
                      }`,
                      state: economics.state,
                    },
                  ]}
                />
              </Card.Section>
              <Card.Section>
                <ProductIndicator
                  values={[
                    {
                      label: 'Net items sold',
                      val: `${
                        economics.data['netItems'] !== undefined
                          ? formatNumber({value: economics.data['netItems']})
                          : '-'
                      }`,
                      state: economics.state,
                    },
                    {
                      label: 'Net revenue',
                      val: `${
                        economics.data['netRevenue'] !== undefined
                          ? formatAmount({value: economics.data['netRevenue']})
                          : '-'
                      }`,
                      state: economics.state,
                    },

                    {
                      label: 'Net orders',
                      val: `${
                        economics.data['netOrders'] !== undefined
                          ? formatNumber({value: economics.data['netOrders']})
                          : '-'
                      }`,
                      state: economics.state,
                    },
                    {
                      label: 'Inventory left',
                      val: `${
                        economics.data['inventoryQuantity'] !== undefined
                          ? formatNumber({value: economics.data['inventoryQuantity']})
                          : '-'
                      }`,
                      state: economics.state,
                    },
                  ]}
                />
              </Card.Section>
              <Card.Section>
                <ProductIndicator
                  values={[
                    {
                      label: 'Inventory days of items left',
                      val: `${
                        economics.data['inventoryDaysLeft'] !== undefined
                          ? !verifyInventory(economics.data)
                            ? 'NA'
                            : daysToYearsMonthsDays(economics.data['inventoryDaysLeft'] || 0)
                          : '-'
                      }`,
                      state: economics.state,
                    },
                    {
                      label: 'Rate of sale',
                      val: `${
                        economics.data['rateOfSale'] !== undefined
                          ? formatDecimalNumber({value: economics.data['rateOfSale']})
                          : '0'
                      }`,
                      state: economics.state,
                    },

                    {
                      label: 'Date of first sale',
                      val: `${
                        economics.data['firstOrderDate'] !== undefined
                          ? formatDate({
                              value: new Date(economics.data['firstOrderDate']),
                              removeTime: true,
                            })
                          : '-'
                      }`,
                      state: economics.state,
                    },
                    {
                      label: 'Date of last sale',
                      val: `${
                        economics.data['lastOrderDate'] !== undefined
                          ? formatDate({
                              value: new Date(economics.data['lastOrderDate']),
                              removeTime: true,
                            })
                          : '-'
                      }`,
                      state: economics.state,
                    },
                  ]}
                />
              </Card.Section>
              <Card.Section>
                <ProductIndicator
                  values={[
                    {
                      label: 'Total discounts applied',
                      val: `${
                        economics.data['totalDiscount'] !== undefined
                          ? formatAmount({value: economics.data['totalDiscount']})
                          : '-'
                      }`,
                      state: economics.state,
                    },
                    {
                      label: 'Discount rate',
                      val: `${
                        economics.data['discountRate'] !== undefined
                          ? formatDecimalNumber({value: economics.data.discountRate}) + '%'
                          : '-'
                      }`,
                      state: economics.state,
                    },

                    {
                      label: 'Latest purchase price',
                      val: `${
                        economics.data['lastSellingPrice'] !== undefined
                          ? formatAmount({value: economics.data['lastSellingPrice']})
                          : '-'
                      }`,
                      state: economics.state,
                    },
                    {
                      label: 'Average purchase price',
                      val: `${
                        economics.data['averageSellingPrice'] !== undefined
                          ? formatAmount({value: economics.data['averageSellingPrice']})
                          : '-'
                      }`,
                      state: economics.state,
                    },
                  ]}
                />
              </Card.Section>
              <Card.Section>
                <ProductIndicator
                  values={[
                    {
                      label: 'Product return rate',
                      val: `${
                        economics.data['returnRate'] !== undefined
                          ? formatDecimalNumber({value: economics.data.returnRate}) + '%'
                          : '-'
                      }`,
                      state: economics.state,
                    },
                    {
                      label: 'Purchase frequency',
                      val: `${
                        economics.data['purchaseFrequency'] !== undefined
                          ? formatDecimalNumber({value: economics.data.purchaseFrequency})
                          : '-'
                      }`,
                      state: economics.state,
                    },

                    {
                      label: 'Latest COGS',
                      val: `${
                        economics.data['cogs'] !== undefined
                          ? formatAmount({value: economics.data['cogs']})
                          : '-'
                      }`,
                      state: economics.state,
                    },
                    {
                      label: 'Gross margin',
                      val: `${
                        economics.data['grossMargin'] !== undefined
                          ? formatAmount({value: economics.data['grossMargin']})
                          : '-'
                      }`,
                      state: economics.state,
                    },
                  ]}
                />
              </Card.Section>
              <Card.Section>
                <ProductIndicator
                  values={[
                    {
                      label: 'Returned items',
                      val: `${
                        economics.data['refundedItems'] !== undefined
                          ? formatNumber({value: economics.data['refundedItems']})
                          : '-'
                      }`,
                      state: economics.state,
                    },
                    {
                      label: 'Variants ',
                      val: `${
                        economics.data['variantsLength'] ? economics.data['variantsLength'] : '-'
                      }`,
                      state: economics.state,
                    },
                    {
                      label: 'Category ',
                      val: `${
                        economics.data['productType'] !== undefined
                          ? economics.data['productType']
                          : '-'
                      }`,
                      state: economics.state,
                    },

                    {
                      label: 'Brand',
                      val:
                        economics.data['vendor'] !== undefined ? (
                          <div className="cell">{economics.data['vendor']}</div>
                        ) : (
                          '-'
                        ),
                      state: economics.state,
                    },
                  ]}
                />
              </Card.Section>
            </Card>

            <Card>
              {economics.state === 'Loading' ? (
                <>
                  <SkeletonBodyText />
                  <SkeletonBodyText />
                </>
              ) : economics.state === 'Success' ? (
                <Card.Section>
                  <Inline blockAlign="start" align="space-between">
                    <Box paddingInlineStart={'2'}>
                      <Text variant="headingMd">Product conversion</Text>
                    </Box>
                    <DateRangeComponent
                      currentDates={selectedDateRangeDates}
                      activator={dateRangeActivator}
                      togglePopoverActive={dateRangeTogglePopoverActive}
                      popoverActive={dateRangePopoverActive}
                      getDates={getDateRangeDates}
                      currentDateOption={selectedDateRange}
                    />
                  </Inline>
                  <Box paddingBlockStart={'4'}>
                    <ErrorBoundary
                      fallback={
                        <Card>
                          <Card.Section>
                            <div>An error has occured</div>
                          </Card.Section>
                        </Card>
                      }
                    >
                      <ProductIndicator
                        values={[
                          {
                            label: 'Product views',
                            val: `${
                              conversionMetrics.data[0]?.['productViewCount'] !== undefined
                                ? formatNumber({
                                    value: conversionMetrics.data[0]['productViewCount'],
                                  })
                                : '-'
                            }`,
                            state: conversionMetrics.state,
                          },
                          {
                            label: 'Added to cart',
                            val: `${
                              conversionMetrics.data[0]?.['quantityAddedToCart'] !== undefined
                                ? formatNumber({
                                    value: conversionMetrics.data[0]['quantityAddedToCart'],
                                  })
                                : '-'
                            }`,
                            state: conversionMetrics.state,
                          },

                          {
                            label: 'Gross orders',
                            val: `${
                              conversionMetrics.data[0]?.['grossOrders'] !== undefined
                                ? formatNumber({value: conversionMetrics.data[0]['grossOrders']})
                                : '-'
                            }`,
                            state: conversionMetrics.state,
                          },
                          {
                            label: 'Net orders',
                            val: `${
                              conversionMetrics.data[0]?.['netOrders'] !== undefined
                                ? formatNumber({value: conversionMetrics.data[0]['netOrders']})
                                : '-'
                            }`,
                            state: conversionMetrics.state,
                          },
                          {
                            label: 'Conversion rate',
                            val: `${
                              conversionMetrics.data[0]?.['conversionRate'] !== undefined
                                ? formatDecimalNumber({
                                    value: conversionMetrics.data[0]['conversionRate'],
                                  }) + '%'
                                : '-'
                            }`,
                            state: conversionMetrics.state,
                          },
                        ]}
                      />
                    </ErrorBoundary>
                  </Box>
                </Card.Section>
              ) : (
                <Text>Not able to load data. please try again later</Text>
              )}
            </Card>

            <Box paddingBlockStart={'5'}>
              <Inline align="space-between">
                <Box width="48.5%">
                  <Card>
                    <Card.Section>
                      <Box paddingInlineStart={'4'}>
                        <Text variant="headingMd">Sales by hour</Text>
                      </Box>
                      <Box paddingBlockStart={'4'}>
                        <ErrorBoundary
                          fallback={
                            <Card>
                              <Card.Section>
                                <div>An error has occured</div>
                              </Card.Section>
                            </Card>
                          }
                        >
                          <DefaultBarGraph
                            height={250}
                            data={salesByHour.data}
                            name={'Net Sales'}
                            xAxisValue="hour"
                            yAxisValue={'quantity'}
                            state={salesByHour.state}
                          />
                        </ErrorBoundary>
                      </Box>
                    </Card.Section>
                  </Card>
                </Box>
                <Box width="48.5%">
                  <Card>
                    <Card.Section>
                      <Box paddingInlineStart={'4'}>
                        <Text variant="headingMd">Sales by top cities</Text>
                      </Box>
                      <Box paddingBlockStart={'4'}>
                        <ErrorBoundary
                          fallback={
                            <Card>
                              <Card.Section>
                                <div>An error has occured</div>
                              </Card.Section>
                            </Card>
                          }
                        >
                          <DefaultBarGraph
                            height={250}
                            data={salesByCity.data}
                            name={'Net Sales'}
                            xAxisValue="city"
                            yAxisValue={'quantity'}
                            state={salesByCity.state}
                          />
                        </ErrorBoundary>
                      </Box>
                    </Card.Section>
                  </Card>
                </Box>
              </Inline>
            </Box>
            <Box paddingBlockStart={'5'}>
              <Inline align="space-between">
                <Box width="48.5%">
                  <Card>
                    <Card.Section>
                      <Box paddingInlineStart={'4'}>
                        <Text variant="headingMd">Sales by top payment methods</Text>
                      </Box>
                      <Box paddingBlockStart={'4'}>
                        <ErrorBoundary
                          fallback={
                            <Card>
                              <Card.Section>
                                <div>An error has occured</div>
                              </Card.Section>
                            </Card>
                          }
                        >
                          <DefaultBarGraph
                            height={250}
                            data={salesByPaymentMethod.data}
                            name={'Net Sales'}
                            xAxisValue="gateway"
                            yAxisValue={'quantity'}
                            state={salesByPaymentMethod.state}
                          />
                        </ErrorBoundary>
                      </Box>
                    </Card.Section>
                  </Card>
                </Box>
                <Box width="48.5%">
                  <Card>
                    <Card.Section>
                      <Box paddingInlineStart={'4'}>
                        <Text variant="headingMd">Sales by top channels</Text>
                      </Box>
                      <Box paddingBlockStart={'4'}>
                        <ErrorBoundary
                          fallback={
                            <Card>
                              <Card.Section>
                                <div>An error has occured</div>
                              </Card.Section>
                            </Card>
                          }
                        >
                          <DefaultBarGraph
                            height={250}
                            data={salesByChannels.data}
                            name={'Net Sales'}
                            xAxisValue="sourceName"
                            yAxisValue={'quantity'}
                            state={salesByChannels.state}
                          />
                        </ErrorBoundary>
                      </Box>
                    </Card.Section>
                  </Card>
                </Box>
              </Inline>
            </Box>
          </Box>
        </Inline>
      </Box>
    </>
  );
};

export default Overview;
