import React from 'react';
import {Spinner, Frame} from '@shopify/polaris';
import Box from '../../../lib/Box';
import Card from '../../../lib/Card';
import Inline from '../../../lib/Inline';
import {useState, useEffect} from 'react';
import {AdminApi} from '../../../api/admin';
import Page from '../../../components/page';
import MultiSelect from '../../../components/multiSelect';
import {useNavigate, useParams} from 'react-router-dom';
import DefaultTable from '../../../components/defaultIndexTable';
import {ErrorBoundary} from '../../../components/ErrorBoundary';
import {adminSplitsColumns} from '../../metrics/columns';
import Alert from '../../../components/alert';
import DefaultActionList from '../../../components/DefaultActionList';
import JsonViewerModal from '../../../components/Modals/JsonViewerModal';
import ConfirmationModal from '../../../components/Modals/ConfirmationModal';
import JobErrorsModal from '../../../components/Modals/JobErrorsModal';
import useApiCall from '../../../hooks/useApiCall';
import SplitStats from './components/Stats';
import {formatDate} from '../../../features/format';
import Button from '../../../lib/Button';
const splitTypeOptions = [
  {value: 'checkout', label: 'Checkout'},
  {value: 'checkouts', label: 'Checkouts'},
  {value: 'customer', label: 'Customer'},
  {value: 'customers', label: 'Customers'},
  {value: 'inventory', label: 'Inventory'},
  {value: 'locations', label: 'Locations'},
  {value: 'order', label: 'Order'},
  {value: 'orders', label: 'Orders'},
  {value: 'product', label: 'Product'},
  {value: 'products', label: 'Products'},
];
const splitStatusOptions = [
  {label: 'All', value: ''},
  {label: 'Completed', value: 'completed'},
  {label: 'Failed', value: 'failed'},
  {label: 'Running', value: 'running'},
  {label: 'Pending', value: 'pending'},
];
const JobDetailed = () => {
  const navigate = useNavigate();
  const [splits, setSplits] = useState({data: [], state: 'Loading', totalRows: 0});
  const [sortBy, setSortBy] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('desc');
  const [queryValue, setQueryValue] = useState('');
  const [page, setPage] = useState(1);
  const [pause, setPause] = useState({state: 'Success'});
  const [retry, setRetry] = useState({state: 'Success'});
  const [resume, setResume] = useState({state: 'Success'});
  const [showAlert, setShowAlert] = useState('');
  const [dataToPreview, setDataToPreview] = useState(null);
  const [status, setStatus] = useState('');
  const params = useParams();
  const jobId = params.jobId;
  const [openConfirmModal, setOpenConfirmModal] = useState(null);
  const [splitsErrorModalData, setSplitsErrorModalData] = useState(null);
  const [splitType, setSplitType] = useState([]);

  const fetchSplits = async ({jobId, sortBy, sortOrder, status, page, search, splitType}) => {
    try {
      setSplits({data: [], state: 'Loading', totalRows: 0, stats: {}});
      let res = await AdminApi.getSplits({
        jobId,
        sortBy,
        sortOrder,
        status,
        page,
        search,
        splitType,
      });
      if (res.status) {
        setSplits({
          data: res.data?.splits,
          state: 'Success',
          totalRows: res.data?.totalRows,
          stats: res.data?.stats,
        });
      } else {
        setSplits({data: [], state: 'Error', totalRows: 0});
      }
    } catch (err) {
      setSplits({data: [], state: 'Error', totalRows: 0});
    }
  };

  const fetchSplitsCount = async () => {
    let res = await AdminApi.getSplitsCount({
      jobId,
    });
    return res;
  };

  const stats = useApiCall(fetchSplitsCount, []);
  useEffect(() => {
    fetchSplits({jobId, sortBy, sortOrder, status, page, search: queryValue, splitType});
  }, [sortBy, sortOrder, status, page, queryValue, splitType]);

  const handlePause = async () => {
    setPause({state: 'Loading'});
    setShowAlert('');
    try {
      let response = await AdminApi.pauseJob({jobId});
      if (response.status) {
        setPause({state: 'Success'});
        setShowAlert('Job has been paused successfully');
      } else {
        setPause({state: 'Error'});
        setShowAlert('Something Went Wrong');
      }
    } catch (err) {
      setPause({state: 'Error'});
      setShowAlert('Something Went Wrong');
    }
  };

  const retrySplit = async splitId => {
    try {
      let res = await AdminApi.retryFailedSplit({splitId});
      if (res.status) {
        fetchSplits();
        setShowAlert('Split restarted successfully');
      } else {
        setShowAlert('Something Went Wrong');
      }
    } catch (err) {
      setShowAlert('Something Went Wrong');
    }
  };

  const handleResume = async () => {
    setResume({state: 'Loading'});
    setShowAlert('');
    try {
      let response = await AdminApi.resumeJob({jobId});
      if (response.status) {
        setResume({state: 'Success'});
        setShowAlert('Job has been resumed successfully');
      } else {
        setResume({state: 'Error'});
        setShowAlert('Something Went Wrong');
      }
    } catch (err) {
      setResume({state: 'Error'});
      setShowAlert('Something Went Wrong');
    }
  };

  const handleRetry = async () => {
    setRetry({state: 'Loading'});
    setShowAlert('');
    try {
      let response = await AdminApi.retryFailedJob({jobId});
      if (response.status) {
        setRetry({state: 'Success'});
        setShowAlert('Retry has been initiated successfully');
      } else {
        setRetry({state: 'Error'});
        setShowAlert('Something Went Wrong');
      }
    } catch (err) {
      setRetry({state: 'Error'});
      setShowAlert('Something Went Wrong');
    }
  };

  return (
    <>
      <Page
        title="Splits"
        breadcrumbs={[
          {
            content: 'jobs',
            onAction: () => {
              navigate(-1);
            },
          },
        ]}
      >
        <ErrorBoundary
          fallback={
            <Card>
              <Card.Section>
                <div>An error has occured</div>
              </Card.Section>
            </Card>
          }
        >
          {!!splits.data && (
            <>
              <Box paddingBlockEnd={'6'}>
                <SplitStats
                  state={stats.state}
                  stats={stats.data}
                  totalCount={stats.data?.totalCount}
                />
              </Box>
              <DefaultTable
                isSearchEnabled
                queryPlaceholder="Search by splitId or objectId"
                filters={[
                  <MultiSelect
                    title="Split Status"
                    options={splitStatusOptions}
                    selected={status}
                    setSelected={setStatus}
                    disableMultiple={true}
                    setPage={setPage}
                  />,
                  <MultiSelect
                    title="Split Type"
                    selected={splitType}
                    setSelected={setSplitType}
                    options={splitTypeOptions}
                    setPage={setPage}
                  />,
                ]}
                actions={
                  <DefaultActionList
                    items={[
                      {
                        content: pause.state === 'Loading' ? <Spinner size="small" /> : 'Pause job',
                        destructive: false,
                        onAction: () => setOpenConfirmModal({method: handlePause, label: 'pause'}),
                      },
                      {
                        content:
                          resume.state === 'Loading' ? <Spinner size="small" /> : 'Resume job',
                        destructive: false,
                        onAction: () =>
                          setOpenConfirmModal({method: handleResume, label: 'resume'}),
                      },
                      {
                        content:
                          retry.state === 'Loading' ? (
                            <Spinner size="small" />
                          ) : (
                            'Retry failed splits'
                          ),
                        destructive: false,
                        onAction: () => setOpenConfirmModal({method: handleRetry, label: 'retry'}),
                      },
                    ]}
                  />
                }
                sortBy={sortBy}
                setSortBy={setSortBy}
                sortExceptions={[]}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                select={false}
                queryValue={queryValue}
                setQueryValue={setQueryValue}
                page={page}
                setPage={setPage}
                resourceName={{
                  singular: 'split',
                  plural: 'splits',
                }}
                columns={adminSplitsColumns}
                selectedFilters={[]}
                durationFilter={'daily'}
                totalRows={splits.data.length ? splits.totalRows : 0}
                tableData={splits.data}
                param={{}}
                state={splits.state}
                valueTypes={{
                  retrySplit: (data, col) => {
                    return (
                      <>
                        {data.status === 'failed' && (
                          <Button
                            key={data._id}
                            plain
                            onClick={() => {
                              retrySplit(data._id);
                            }}
                          >
                            Retry
                          </Button>
                        )}
                      </>
                    );
                  },
                  showJson: (data, col) => {
                    return (
                      <Button
                        key={data._id}
                        plain
                        onClick={() => {
                          setDataToPreview(data?.log);
                        }}
                      >
                        Show Logs
                      </Button>
                    );
                  },
                  showErrors: (data, col) => {
                    return (
                      <Button
                        key={data._id}
                        plain
                        onClick={() => {
                          setSplitsErrorModalData(data);
                        }}
                      >
                        Show Errors
                      </Button>
                    );
                  },
                  date: (data, col) => {
                    return (
                      <Inline align="end">
                        {formatDate({
                          value: new Date(data[col.value]),
                          removeTime: false,
                          seconds: true,
                        })}
                      </Inline>
                    );
                  },
                  nestedDate: (data, col) => {
                    const properties = col.value.split('.');
                    let result = data;

                    for (let prop of properties) {
                      result = result[prop];
                    }
                    return (
                      <Inline align="end">
                        {formatDate({value: new Date(result), removeTime: false, seconds: true})}
                      </Inline>
                    );
                  },
                }}
              />
            </>
          )}
        </ErrorBoundary>
        {showAlert && (
          <Frame>
            <Alert
              contents={showAlert}
              init={true}
              error={showAlert === 'Something Went Wrong'}
              duration={10000}
            />
          </Frame>
        )}
        {Boolean(dataToPreview) && (
          <JsonViewerModal
            isOpen={Boolean(dataToPreview)}
            toggle={() => setDataToPreview(null)}
            data={dataToPreview}
            title="Logs"
          />
        )}
        {Boolean(openConfirmModal) && (
          <ConfirmationModal
            isOpen={Boolean(openConfirmModal)}
            toggle={() => setOpenConfirmModal(null)}
            onProceed={() => {
              openConfirmModal?.method();
            }}
            text={`Are you sure you want to ${openConfirmModal?.label} the job?`}
          />
        )}
      </Page>
      {Boolean(splitsErrorModalData) && (
        <JobErrorsModal
          isOpen={Boolean(splitsErrorModalData)}
          toggle={() => setSplitsErrorModalData(null)}
          data={splitsErrorModalData}
          type="split"
        />
      )}
    </>
  );
};

export {JobDetailed};
