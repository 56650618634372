import {Jobs} from '../../pages/admin/jobs';
import {JobDetailed} from '../../pages/admin/jobDetailed';
import {AdminProfile} from '../../pages/admin/profile';
import {StatusTracking} from '../../pages/admin/statusTracking';
import {Dash} from '../../pages/fetchDashboard';
import Metrics from '../../pages/metrics';
import CustomerPage from '../../pages/metrics/customer';
import CustomerProfile from '../../pages/metrics/customerProfile';
import MetricsPage from '../../pages/metrics/metricsPage';
import {Orders} from '../../pages/metrics/orders';
import ProductPage from '../../pages/metrics/product';
import ProductProfile from '../../pages/metrics/productProfile';
import ReportPage from '../../pages/metrics/reportPage';
import RfmCategory from '../../pages/metrics/rfmCategory';
import {Pricing} from '../../pages/pricing';
import Settings from '../../pages/settings';
import Profile from '../../pages/profile';
import Segment from '../../pages/segments';
import ActiveSegments from '../../pages/segments/activeSegments';
import SegmentPage from '../../pages/segments/segmentPage';
import Signal from '../../pages/signals';
import ActiveSignal from '../../pages/signals/activeSignal';
import ExportHistory from '../../pages/signals/exportHistory';
import Organizations from '../../pages/admin/Organizations';
import OrganizationDetailed from '../../pages/admin/Organizations/Detailed';
import Webhooks from '../../pages/admin/Webhooks';
import {Ltv} from '../../pages/ltv';
import AsyncJobs from '../../pages/admin/asyncJobs';
import DiffrencesCounts from '../../pages/admin/Organizations/Diffrences/Count';
import Diffrences from '../../pages/admin/Organizations/Diffrences/Diffrences';
import {Cost} from '../../pages/cost';
import {ProfitAndLoss} from '../../pages/metrics/p&l';
import ProductJourney from '../../pages/productJourney';
import MarketingDashboard from '../../pages/marketing/dashboard';
import NPSDashboard from '../../pages/metrics/NPS';
import ActiveAlert from '../../pages/Alerts/ActiveAlerts';
import AlertHistory from '../../pages/Alerts/AlertsHistory';
import Alerts from '../../pages/Alerts';
import ActiveProductGroups from '../../pages/productGroups/activeProductGroups';
import CreateProductGroup from '../../pages/productGroups';
import ProductGroupPage from '../../pages/productGroups/productGroupPage';
import {CompareCohort} from '../../pages/ltv/compareCohort';
import SummaryLogs from '../../pages/admin/summaryLogs';
import ProductModule from '../../pages/productModule';
import AlertsAndReportPage from '../../pages/Alerts/alertsAndReports';
// import ConnectToStore from '../../pages/userManagement/store';

const routeMap = {
  // connectToStore: {
  //   path: '/store',
  //   component: <ConnectToStore />,
  //   frameRequired: false,
  // },
  metrics: {
    path: '/dashboard',
    component: <Metrics />,
    frameRequired: false,
  },
  metricsPage: {
    path: '/metrics',
    component: <MetricsPage />,
    frameRequired: true,
  },
  reportPage: {
    path: '/metrics/:name',
    component: <ReportPage />,
    frameRequired: true,
  },
  rfmCategory: {
    path: '/metrics/rfm-analysis/:category',
    component: <RfmCategory />,
    frameRequired: true,
  },
  productPage: {
    path: '/product-module',
    component: <ProductModule />,
    frameRequired: true,
  },
  customers: {
    path: '/customers',
    component: <CustomerPage />,
    frameRequired: true,
  },
  customerProfile: {
    path: '/customers/:customerId',
    component: <CustomerProfile />,
    frameRequired: true,
  },
  productProfile: {
    path: '/product/:variantId',
    component: <ProductProfile />,
    frameRequired: true,
  },
  activeSegments: {
    path: '/segments',
    component: <ActiveSegments />,
    frameRequired: true,
  },
  segmentPage: {
    path: '/segment/:id',
    component: <SegmentPage />,
    frameRequired: true,
  },
  segment: {
    path: '/segment-new',
    component: <Segment />,
    frameRequired: false,
  },
  segmentEdit: {
    path: '/segment-edit/:id',
    component: <Segment edit={true} />,
    frameRequired: false,
  },
  settings: {
    path: '/settings',
    component: <Settings />,
    frameRequired: false,
  },
  signal: {
    path: '/signal-new',
    component: <Signal />,
    frameRequired: false,
  },
  alert: {
    path: '/alert-new',
    component: <Alerts />,
    frameRequired: false,
  },
  alertReport: {
    path: '/alert-new/:reportId',
    component: <Alerts />,
    frameRequired: false,
  },
  alertEdit: {
    path: '/alert/edit/:id',
    component: <Alerts edit={true} />,
    frameRequired: false,
  },
  activeAlerts: {
    path: '/alerts-and-reports',
    component: <AlertsAndReportPage />,
    frameRequired: true,
  },
  alertsHistory: {
    path: '/alert-history',
    component: <AlertHistory />,
    frameRequired: true,
  },
  signalReport: {
    path: '/signal-new/:reportId',
    component: <Signal />,
    frameRequired: false,
  },
  SignalEdit: {
    path: '/signal/edit/:id',
    component: <Signal edit={true} />,
    frameRequired: false,
  },
  activeSignal: {
    path: '/active-signals',
    component: <ActiveSignal />,
    frameRequired: true,
  },
  exportHistory: {
    path: '/signal-history',
    component: <ExportHistory />,
    frameRequired: true,
  },
  statusTracking: {
    path: '/admin/organizations/:organizationId/status-tracking',
    component: <StatusTracking />,
    adminFrameRequired: true,
  },
  organizations: {
    path: '/admin/organizations',
    component: <Organizations />,
    adminFrameRequired: true,
  },
  organizationsDetails: {
    path: '/admin/organizations/:organizationId',
    component: <OrganizationDetailed />,
    adminFrameRequired: true,
  },
  diffrenceCounts: {
    path: '/admin/organizations/:organizationId/diffrence-counts',
    component: <DiffrencesCounts />,
    adminFrameRequired: true,
  },
  diffrences: {
    path: '/admin/organizations/:organizationId/diffrences',
    component: <Diffrences />,
    adminFrameRequired: true,
  },
  webhooks: {
    path: '/admin/webhook-logs',
    component: <Webhooks />,
    adminFrameRequired: true,
  },
  jobs: {
    path: '/admin/jobs',
    component: <Jobs />,
    adminFrameRequired: true,
  },
  jobDetails: {
    path: '/admin/jobs/:jobId',
    component: <JobDetailed />,
    adminFrameRequired: true,
  },
  asyncJobs: {
    path: '/admin/async-jobs',
    component: <AsyncJobs />,
    adminFrameRequired: true,
  },
  adminProfile: {
    path: '/admin/profile',
    component: <AdminProfile />,
    adminFrameRequired: true,
  },
  profile: {
    path: '/profile',
    component: <Profile />,
    frameRequired: true,
  },
  dash: {
    path: '/fetch-status',
    component: <Dash />,
    frameRequired: false,
    removeFooter: true,
  },
  pricing: {
    path: '/pricing',
    component: <Pricing />,
    frameRequired: true,
  },
  orders: {
    path: '/orders',
    component: <Orders />,
    frameRequired: true,
  },
  ltv: {
    path: '/ltv',
    component: <Ltv />,
    frameRequired: true,
  },
  compareCohorts: {
    path: '/compare-cohort',
    component: <CompareCohort />,
    frameRequired: true,
  },
  nps: {
    path: '/nps',
    component: <NPSDashboard />,
    frameRequired: true,
  },
  cost: {
    path: '/cost',
    component: <Cost />,
    frameRequired: true,
  },
  profitAndLoss: {
    path: '/pnl',
    component: <ProfitAndLoss />,
    frameRequired: true,
  },
  productJourney: {
    path: '/product-journey',
    component: <ProductJourney />,
    frameRequired: true,
  },
  marketingDashboard: {
    path: '/marketing-dashboard',
    component: <MarketingDashboard />,
    frameRequired: true,
  },
  activeProductGroups: {
    path: '/product-groups',
    component: <ActiveProductGroups />,
    frameRequired: true,
  },
  productGroupPage: {
    path: '/product-group/:id',
    component: <ProductGroupPage />,
    frameRequired: true,
  },
  createProductGroup: {
    path: '/product-group-new',
    component: <CreateProductGroup />,
    frameRequired: false,
  },
  productGroupEdit: {
    path: '/product-group-edit/:id',
    component: <CreateProductGroup edit={true} />,
    frameRequired: false,
  },
  summaryLogs: {
    path: '/admin/summary-logs',
    component: <SummaryLogs />,
    adminFrameRequired: true,
  },
};

export {routeMap};
