import React, {useEffect, useState} from 'react';
import {
  Listbox,
  TextField,
  Icon,
  Popover,
  AutoSelection,
  Scrollable,
  EmptySearchResult,
  Text,
} from '@shopify/polaris';
import {SearchMinor} from '@shopify/polaris-icons';
// import {segments} from '../newSegment/options';
import Button from '../../../lib/Button';

const actionValue = '__ACTION__';

const interval = 30;

const StopPropagation = ({children}) => {
  const stopEventPropagation = event => {
    event.stopPropagation();
  };

  return (
    <div onClick={stopEventPropagation} onTouchStart={stopEventPropagation}>
      {children}
    </div>
  );
};

function Property({dispatch, value, indexes, options, user}) {
  const [pickerOpen, setPickerOpen] = useState(false);
  const [showFooterAction, setShowFooterAction] = useState(options.length > 6);
  const [query, setQuery] = useState('');
  const [lazyLoading, setLazyLoading] = useState(false);
  const [willLoadMoreResults, setWillLoadMoreResults] = useState(true);
  const [visibleOptionIndex, setVisibleOptionIndex] = useState(6);
  const [activeOptionId, setActiveOptionId] = useState('');
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState('');
  const [filteredSegments, setFilteredSegments] = useState([]);
  const handleClickShowAll = () => {
    setShowFooterAction(false);
    setVisibleOptionIndex(interval);
  };

  useEffect(() => {
    if (value !== '') {
      let i = 0;
      options.forEach(s => {
        if (s.id === value) {
          i = s.value;
        }
      });
      setSelectedSegmentIndex(i);
    } else {
      setSelectedSegmentIndex('');
    }
  }, [value]);

  const handleFilterSegments = query => {
    const nextFilteredSegments = options.filter(option => {
      return option.label.toLocaleLowerCase().includes(query.toLocaleLowerCase().trim());
    });

    setFilteredSegments(nextFilteredSegments);
  };

  const handleQueryChange = query => {
    setQuery(query);

    if (query.length >= 2) handleFilterSegments(query);
  };

  const handleQueryClear = () => {
    handleQueryChange('');
  };

  const handleOpenPicker = () => {
    setPickerOpen(!pickerOpen);
  };

  const handleClosePicker = () => {
    setPickerOpen(false);
    handleQueryChange('');
  };

  const handleSegmentSelect = segmentIndex => {
    if (segmentIndex === actionValue) {
      return handleClickShowAll();
    }

    setSelectedSegmentIndex(Number(segmentIndex));
    let val = options[segmentIndex];
    dispatch({type: 'property', indexes: indexes, value: val.id});

    handleClosePicker();
  };

  const handleActiveOptionChange = (_, domId) => {
    setActiveOptionId(domId);
  };

  /* This is just to illustrate lazy loading state vs loading state. This is an example, so we aren't fetching from GraphQL. You'd use `pageInfo.hasNextPage` from your GraphQL query data instead of this fake "willLoadMoreResults" state along with setting `first` your GraphQL query's variables to your app's default max edges limit (e.g., 250). */

  const handleLazyLoadSegments = () => {
    if (willLoadMoreResults && !showFooterAction) {
      setLazyLoading(true);

      const _options = query ? filteredSegments : options;

      setTimeout(() => {
        const remainingOptionCount = _options.length - visibleOptionIndex;
        const nextVisibleOptionIndex =
          remainingOptionCount >= interval
            ? visibleOptionIndex + interval
            : visibleOptionIndex + remainingOptionCount;

        setLazyLoading(false);
        setVisibleOptionIndex(nextVisibleOptionIndex);

        if (remainingOptionCount <= interval) {
          setWillLoadMoreResults(false);
        }
      }, 1000);
    }
  };

  const listboxId = 'SearchableListboxInPopover';

  /* Your app's feature/context specific activator here */
  const activator = (
    <div>
      <Button
        fullWidth
        textAlign="left"
        disclosure={pickerOpen ? 'up' : 'down'}
        onClick={handleOpenPicker}
        disabled={user === 'Retainwise'}
      >
        <Text color="subdued">
          {selectedSegmentIndex !== '' ? options[selectedSegmentIndex].label : 'Property'}
        </Text>
      </Button>
    </div>
  );

  const textFieldMarkup = (
    <div style={{padding: '12px'}}>
      <StopPropagation>
        <TextField
          clearButton
          labelHidden
          label="Customer segments"
          placeholder="Search property"
          autoComplete="off"
          value={query}
          prefix={<Icon source={SearchMinor} />}
          ariaActiveDescendant={activeOptionId}
          ariaControls={listboxId}
          onChange={handleQueryChange}
          onClearButtonClick={handleQueryClear}
        />
      </StopPropagation>
    </div>
  );

  const segmentOptions = query ? filteredSegments : options;

  const segmentList =
    segmentOptions.length > 0
      ? segmentOptions.slice(0, visibleOptionIndex).map(({label, id, value}) => {
          const selected =
            selectedSegmentIndex !== '' ? options[selectedSegmentIndex].id === id : null;

          return (
            <Listbox.Option key={id} value={value} selected={selected}>
              <Listbox.TextOption selected={selected}>{label}</Listbox.TextOption>
            </Listbox.Option>
          );
        })
      : null;

  const showAllMarkup = showFooterAction ? (
    <Listbox.Action value={actionValue}>
      <span style={{color: 'var(--p-interactive)'}}>Show all {options.length} properties</span>
    </Listbox.Action>
  ) : null;

  const lazyLoadingMarkup = lazyLoading ? (
    <Listbox.Loading accessibilityLabel={`${query ? 'Filtering' : 'Loading'} properties`} />
  ) : null;

  const noResultsMarkup =
    segmentOptions.length === 0 ? (
      <EmptySearchResult title="" description={`No property found matching "${query}"`} />
    ) : null;

  const listboxMarkup = (
    <Listbox
      enableKeyboardControl
      autoSelection={AutoSelection.FirstSelected}
      accessibilityLabel="Search for and select a customer segment"
      customListId={listboxId}
      onSelect={handleSegmentSelect}
      onActiveOptionChange={handleActiveOptionChange}
    >
      {segmentList}
      {showAllMarkup}
      {noResultsMarkup}
      {lazyLoadingMarkup}
    </Listbox>
  );

  return (
    <div>
      <Popover
        active={pickerOpen}
        activator={activator}
        ariaHaspopup="listbox"
        preferredAlignment="left"
        preferredPosition="below"
        autofocusTarget="first-node"
        onClose={handleClosePicker}
        fluidContent
      >
        <Popover.Pane>
          <div>
            {textFieldMarkup}

            <Scrollable
              shadow
              style={{
                position: 'relative',
                width: '310px',
                height: '292px',
                padding: 'var(--p-space-2) 0',
                borderBottomLeftRadius: 'var(--p-border-radius-2)',
                borderBottomRightRadius: 'var(--p-border-radius-2)',
              }}
              onScrolledToBottom={handleLazyLoadSegments}
            >
              {listboxMarkup}
            </Scrollable>
          </div>
        </Popover.Pane>
      </Popover>
    </div>
  );
}

export default Property;
