import {Popover, ActionList} from '@shopify/polaris';
import React, {useCallback, useState} from 'react';
import {
  MobileVerticalDotsMajor,
  PlusMinor,
  StatusActiveMajor,
  TickMinor,
} from '@shopify/polaris-icons';
import {DeleteModal} from '../../../../components/modal';
import {LocalStorageService} from '../../../../service/localStorage';
import Stack from '../../../../lib/Stack';
import Card from '../../../../lib/Card';
import Box from '../../../../lib/Box';
import Text from '../../../../lib/Text';
import Inline from '../../../../lib/Inline';
import Icon from '../../../../lib/Icon';

const ConnectionCard = ({data, onEdit, onDelete, setType, handleAddConnection}) => {
  const [active, setActive] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const toggleActive = useCallback(() => setActive(active => !active), []);

  const generateExtraContentForDeleteModal = data => {
    if (data.type === 'twilio') {
      const userDetails = LocalStorageService.getAccountDetails();
      let org = userDetails?.user?.org;
      if (org?.reportSettings?.nps.isWhatsappEnabled) {
        return 'This will disable send NPS to whatsapp !';
      }
    }
    return '';
  };
  const handleEdit = useCallback(() => {
    setType(data.type);
    onEdit(data._id);
  }, []);
  const handleDelete = useCallback(() => {
    onDelete(data._id);
    setOpenDeleteModal(false);
  }, []);
  const handleAdd = () => {
    setType(data.type);
    handleAddConnection(data.type);
  };

  const activator = (
    <Box style={{cursor: 'pointer'}} onClick={toggleActive}>
      <Icon source={MobileVerticalDotsMajor} tone="base" />
    </Box>
  );
  return (
    <>
      <Card>
        <Box paddingBlockStart={'3'} paddingInlineEnd={'3'}>
          {data.data ? (
            <Inline align="space-between">
              <Box paddingInlineStart="3">
                <Icon source={TickMinor} color="success" />
              </Box>
              <Popover
                active={active}
                activator={activator}
                autofocusTarget="none"
                onClose={toggleActive}
              >
                <ActionList
                  actionRole="menuitem"
                  items={[
                    {
                      content: 'Edit',
                      onAction: handleEdit,
                    },
                    {
                      content: 'Delete',
                      onAction: () => setOpenDeleteModal(true),
                    },
                  ]}
                />
              </Popover>
            </Inline>
          ) : (
            <Inline align="end">
              <Box style={{cursor: 'pointer'}} onClick={handleAdd}>
                <Icon source={PlusMinor} color="primary" />
              </Box>
            </Inline>
          )}

          {openDeleteModal && (
            <DeleteModal
              currentState={openDeleteModal}
              deleteContent="Connection"
              onDelete={handleDelete}
              onClose={() => {
                setOpenDeleteModal(false);
              }}
              extraContent={generateExtraContentForDeleteModal(data)}
            />
          )}
        </Box>
        <Box padding={'4'} paddingBlockStart={'4'}>
          <Stack vertical alignment="center" spacing="tight">
            <div style={{textAlign: 'center'}}>
              <img
                alt=""
                width={'240px'}
                style={{maxWidth: '60%', height: '5rem', paddingBottom: '5%', objectFit: 'contain'}}
                src={data.image}
              />

              <Text color="subdued">{data.title || 'Not set'}</Text>
            </div>
          </Stack>
        </Box>
      </Card>
    </>
  );
};

export default ConnectionCard;
