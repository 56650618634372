const topicsOptions = [
  {label: 'All', value: ''},
  {label: 'checkouts/create', value: 'checkouts/create'},
  {label: 'checkouts/delete', value: 'checkouts/delete'},
  {label: 'checkouts/update', value: 'checkouts/update'},
  {label: 'customers/create', value: 'customers/create'},
  {label: 'customers/delete', value: 'customers/delete'},
  {label: 'customers/disable', value: 'customers/disable'},
  {label: 'customers/enable', value: 'customers/enable'},
  {label: 'customers/update', value: 'customers/update'},
  {label: 'orders/cancelled', value: 'orders/cancelled'},
  {label: 'orders/create', value: 'orders/create'},
  {label: 'orders/delete', value: 'orders/delete'},
  {label: 'orders/edited', value: 'orders/edited'},
  {label: 'orders/fulfilled', value: 'orders/fulfilled'},
  {label: 'orders/paid', value: 'orders/paid'},
  {label: 'products/create', value: 'products/create'},
  {label: 'products/delete', value: 'products/delete'},
  {label: 'products/update', value: 'products/update'},
];

export default topicsOptions;
