import {Box, Button, Card, Loading} from '@shopify/polaris';
import {ErrorBoundary} from '../../../components/ErrorBoundary';
import DefaultIndexTable from '../../../components/defaultIndexTable';
import Inline from '../../../lib/Inline';
import {useEffect, useState, useCallback} from 'react';
import MultiSelect from '../../../components/multiSelect';
import apiToCall from '../../../features/apiToCall';
import {useSnapshotStore} from '../../../app/store';
import DateRangeComponent from '../../../components/dateRangeComponent';
import {dateToNormalString, toShortDate} from '../../../features/convertDates';
import Page from '../../../components/page';
import {useSearchParams} from 'react-router-dom';
import config from '../../../config';
import useApiCall from '../../../hooks/useApiCall';
import {getConnectionTemplate} from '../../../api/connection';
import ColdStart from '../../coldStart';

const dateRangeValueToLable = {
  today: 'Today',
  yesterday: 'Yesterday',
  lastSevenDays: 'Last 7 Days',
  lastThirtyDays: 'Last 30 Days',
  lastNinetyDays: 'Last 90 Days',
  lastOneYear: 'Last 1 Year',
};
const insightLevelValueToLable = {
  ad: 'Ad',
  adSet: 'Ad set',
  campaign: 'Campaign',
};

const MarketingDashboard = () => {
  const {
    selectedDateRange,
    setSelectedDateRange,
    selectedDateRangeDates,
    setSelectedDateRangeDates,
  } = useSnapshotStore(state => state);
  const [searchParams, setSearchParams] = useSearchParams();
  const [marketingConnectionStatus, setMarketingConnectionStatus] = useState('loading');
  const param = {name: 'marketing-dashboard'};
  const [page, setPage] = useState(searchParams.get('page') || 1);
  const [levelSelected, setLevelSelected] = useState(
    searchParams.get('insightLevel') || 'campaign'
  );
  const [dateRangePopoverActive, setDateRangePopoverActive] = useState(false);
  const [initRender, setInitRender] = useState(true);
  const [sortOrder, setSortOrder] = useState(
    searchParams.get('sortOrder') || apiToCall[param.name].sortOrder
  );
  const [queryValue, setQueryValue] = useState('');
  const [sortBy, setSortBy] = useState(searchParams.get('sortBy') || apiToCall[param.name].sortBy);
  const [durationFilter, setDurationFilter] = useState(
    searchParams.get('durationFilter') || 'total'
  );
  const dateRangeTogglePopoverActive = useCallback(
    () => setDateRangePopoverActive(dateRangePopoverActive => !dateRangePopoverActive),
    []
  );

  const getDateRangeDates = (dates, selectedDateRange) => {
    setSelectedDateRangeDates(dates);
    setSelectedDateRange(selectedDateRange);
  };

  useEffect(() => {
    let isApiCalled = false;
    setTimeout(() => {
      if (!isApiCalled) {
        if (!initRender) {
          let params = {};

          params = {
            startDate: toShortDate(selectedDateRangeDates.start),
            endDate: toShortDate(selectedDateRangeDates.end),
            page,
            sortBy,
            sortOrder,
            insightLevel: levelSelected,
            durationFilter,
          };

          params = {...params};
          let flag = false;
          for (let key in params) {
            if (searchParams.get(key) !== params[key]) {
              flag = true;
            }
          }
          if (flag) {
            setSearchParams({...params});
          }
        } else {
          setInitRender(false);
        }
      }
    }, config.DEBOUNCING_DELAY);
    return () => {
      isApiCalled = true;
    };
  }, [selectedDateRangeDates, page, levelSelected, sortBy, sortOrder, durationFilter]);

  const fetchMarketingData = async () => {
    let startDate = toShortDate(selectedDateRangeDates.start, 'start');
    let endDate = toShortDate(selectedDateRangeDates.end, 'end');
    let params = {
      startDate,
      endDate,
      page,
      search: queryValue,
      sortBy,
      sortOrder,
      type: 'table',
      insightLevel: levelSelected,
      durationFilter,
    };
    let response = await apiToCall[param.name].api({params});
    return response.data;
  };

  const marketingDashboardApiDependencies = [
    selectedDateRangeDates,
    page,
    queryValue,
    sortBy,
    sortOrder,
    levelSelected,
    durationFilter,
  ];

  const marketingData = useApiCall(fetchMarketingData, marketingDashboardApiDependencies);

  const dateRangeActivator = (
    <div style={{width: '100%'}}>
      <Button onClick={dateRangeTogglePopoverActive} disclosure>
        {selectedDateRange
          ? dateRangeValueToLable[selectedDateRange]
          : dateToNormalString(selectedDateRangeDates)}
      </Button>
    </div>
  );

  const connectionVerify = async () => {
    setMarketingConnectionStatus('loading');
    let res = await getConnectionTemplate({params: {type: 'connected'}});
    if (res.status) {
      for (let connection of res.data) {
        if (
          (connection.type === 'facebookAds' || connection.type === 'googleAds') &&
          connection.data
        ) {
          setMarketingConnectionStatus('connected');
          return;
        }
      }
      setMarketingConnectionStatus('not-connected');
      return;
    }
  };
  useEffect(() => {
    connectionVerify();
  }, []);

  return (
    <>
      {marketingConnectionStatus === 'not-connected' ? (
        <>
          <ColdStart page={'page10'} />
        </>
      ) : marketingConnectionStatus === 'loading' ? (
        <>
          <Loading />
        </>
      ) : (
        <Page title={apiToCall['marketing-dashboard'].title}>
          <Box paddingBlockEnd={'4'} paddingBlockStart={'4'}>
            <Inline>
              <DateRangeComponent
                currentDates={selectedDateRangeDates}
                activator={dateRangeActivator}
                togglePopoverActive={dateRangeTogglePopoverActive}
                popoverActive={dateRangePopoverActive}
                getDates={getDateRangeDates}
                currentDateOption={selectedDateRange}
              />
              <MultiSelect
                title="Level"
                options={[
                  {label: 'Ad', value: 'ad'},
                  {label: 'Ad set', value: 'adSet'},
                  {label: 'Campaign', value: 'campaign'},
                ]}
                selected={levelSelected}
                setSelected={setLevelSelected}
                disableMultiple={true}
                setPage={setPage}
                setChecked={() => {}}
              />
              <MultiSelect
                title="Group by"
                options={[
                  {label: 'Total', value: 'total'},
                  {label: 'Daily', value: 'daily'},
                  {label: 'Weekly', value: 'weekly'},
                  {label: 'Monthly', value: 'monthly'},
                ]}
                selected={durationFilter}
                setSelected={setDurationFilter}
                disableMultiple={true}
                setPage={setPage}
                setChecked={() => {}}
              />
            </Inline>
          </Box>
          <Box paddingBlockEnd={'4'}>
            <ErrorBoundary
              fallback={
                <Card>
                  <Card.Section>
                    <div>An error has occured</div>
                  </Card.Section>
                </Card>
              }
            >
              <DefaultIndexTable
                select={false}
                page={page}
                setPage={setPage}
                resourceName={{
                  singular: 'metrics',
                  plural: 'metrics',
                }}
                columns={apiToCall['marketing-dashboard'].columns
                  .filter(column => {
                    if (durationFilter === 'total' && column.value === 'date') {
                      return false;
                    }
                    return true;
                  })
                  .map(column =>
                    column.value === 'insightLevelName'
                      ? {
                          ...column,
                          title: column.title.replace(
                            '{insightLevel}',
                            insightLevelValueToLable[levelSelected]
                          ),
                        }
                      : column
                  )}
                selectedFilters={[]}
                totalRows={marketingData.data.length > 0 ? marketingData.data[0].totalRows : 0}
                tableData={marketingData.data}
                setSelectedDateRangeDates={setSelectedDateRangeDates}
                state={marketingData.state}
                param={param}
                disableSort={false}
                isSearchEnabled={true}
                sortBy={sortBy}
                sortOrder={sortOrder}
                setSortBy={setSortBy}
                setSortOrder={setSortOrder}
                queryValue={queryValue}
                setQueryValue={setQueryValue}
                durationFilter={durationFilter}
              />
            </ErrorBoundary>
          </Box>
        </Page>
      )}
    </>
  );
};

export default MarketingDashboard;
