import React from 'react';
import {Card as PolarisCard} from '@shopify/polaris';

const Card = React.forwardRef(({children, ...props}, ref) => {
  return (
    <PolarisCard ref={ref} {...props}>
      {children}
    </PolarisCard>
  );
});

Card.Header = ({children, ...props}) => {
  return <PolarisCard.Header {...props}>{children}</PolarisCard.Header>;
};
Card.Section = ({children, ...props}) => {
  return <PolarisCard.Section {...props}>{children}</PolarisCard.Section>;
};

Card.Subsection = ({children, ...props}) => {
  return <PolarisCard.Subsection {...props}>{children}</PolarisCard.Subsection>;
};

export default Card;
